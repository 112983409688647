<template>
    <div class="modal" id="builderChangeDatesModal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t("Itinerary.modal.ChangeDates.change_dates")}} - {{item.name}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div v-if="!template" class="form-group" :class="{ 'has-error': dateRange === null}">
                        <!--<div class="form-group">-->
                            <label class="control-label mr-2" >{{$t("Itinerary.modal.ChangeDates.dates")}} *:</label>
                            <el-date-picker
                                    v-model="dateRange"
                                    type="daterange"
                                    :start-placeholder='$t("Itinerary.modal.ChangeDates.start_date")'
                                    :end-placeholder='$t("Itinerary.modal.ChangeDates.end_date")'
                                    :clearable="false"
                                    :picker-options="pickerOptions"
                                    :format="itData.dateFormat"
                                    :default-value="defaultDate"
                            >
                            </el-date-picker>
                            <input type="hidden" class="form-control"
                                   :class="{'is-invalid' : dateRange === null}"/>
                            <div class="invalid-feedback" v-show="dateRange === null">{{$t("Itinerary.modal.ChangeDates.select_dates")}}</div>
                        </div>
                        <div v-else="template" class="form-group" :class="{'has-error': dayRange === null}">
                            <div class="col-11 mb-5">
                                <el-slider
                                        v-model="dayRange"
                                        range
                                        :min="1"
                                        :max="this.itData.numDays"
                                        :marks="dayRangeMarks">
                                </el-slider>
                            </div>
                        </div>
                        <div class="float-right">
                            <button class="btn btn-link" @click="close">{{$t("Itinerary.modal.ChangeDates.cancel")}}</button>
                            <button class="btn btn-primary ml-2" @click="changeDates">{{$t("Itinerary.modal.ChangeDates.change_dates")}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vButton from '../../../components/VButton';
    import {formatDate, getDateMomentForDayNum, parseMoment} from "../../../date-utils";
    import moment from 'moment';
    import $ from 'jquery';
    import {showError} from "../../../utils";

    export default {
        components: {
            vButton,
        },
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            const self = this;
            return {
                dateRange: [],
                pickerOptions: {
                    disabledDate(date) {
                        const result = !moment(date).isBetween(
                            parseMoment(self.itData.startDate, self.itData.dateFormat),
                            parseMoment(self.itData.endDate, self.itData.dateFormat),
                            null,
                            "[]"
                        );
                        /*if (result)
                            console.log("#### RESULT FOR " + formatMoment(moment(date)) + " => " + result);*/
                        return result;
                    }
                },
                defaultDate: new Date(),

                dayRange: [],
                dayRangeMarks: {},

                loading: false,

            }
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function () {
                self.$emit('update:show', false);
            })
        },
        computed: {
            itData() {
                return this.$store.getters.itData;
            },
            template() {
                return this.itData.template
            },
        },
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                    if (this.template) {
                        const numDays = this.itData.numDays;
                        this.dayRangeMarks = {};
                        this.dayRange = [this.item.startDay, this.item.endDay];
                        let dayIdx = 1;
                        while (dayIdx <= numDays) {
                            this.dayRangeMarks[dayIdx] = this.$t("v2.Itinerary.defaults.DAY") + " " + dayIdx
                            dayIdx++;
                        }
                    } else {
                        this.dateRange = [
                            getDateMomentForDayNum(this.item.startDay, this.itData.startDate, this.itData.dateFormat).toDate(),
                            getDateMomentForDayNum(this.item.endDay, this.itData.startDate, this.itData.dateFormat).toDate(),
                        ];
                        this.defaultDate = parseMoment(this.itData.startDate, this.itData.dateFormat).toDate();
                    }
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
            }
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            changeDates() {
                if (this.template) {
                    this.$emit("update-days", {
                        startDay: this.dayRange[0],
                        endDay: this.dayRange[1]
                    });
                    this.close();
                } else {
                    if (this.dateRange !== null) {
                        this.$emit("update-dates", {
                            startDate: formatDate(this.dateRange[0], this.itData.dateFormat),
                            endDate: formatDate(this.dateRange[1], this.itData.dateFormat),
                        });
                        this.close();
                    } else {
                        showError(
                            this.$t("Itinerary.error.general_title"),
                            this.$t("Itinerary.modal.ChangeDates.select_dates")
                        );
                    }
                }
            }
        }
    }
</script>

<style scoped>
</style>
