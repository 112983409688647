<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <input type="text" class="name-input"
                           v-model="item.name"
                           :placeholder='$t("Itinerary.TripTourName.tooltip.tour_name")'
                           ref="name"

                    />
                <!--<input type="text" class="name-input" v-model="activeSection.name"/>-->
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="stringNotEmpty(item.description)">
                <input type="text" class="name-input"
                       v-model="item.description"
                       :placeholder='$t("Itinerary.TripTourName.tooltip.short_description")'
                       ref="description"
                />
            </div>
            <div class="col-12" v-if="stringNotEmpty(item.refNum)">
                <input type="text" class="name-input"
                       v-model="item.refNum"
                       :placeholder='$t("Itinerary.TripTourName.tooltip.reference")'
                       ref="refnumber"
                />
            </div>
        </div>
    </div>
</template>

<script>

    import {stringUtilsMixin} from "../../item/item-mixins";

    export default {
        components: {
        },
        mixins: [
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                // showButtons: false,
            }
        },
    }
</script>

<style scoped>
</style>
