<template>
    <div class="modal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t("Itinerary.modal.TransportationMap.title")}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form autocomplete="off" class="mb-2">
                        <input autocomplete="false" name="hidden" type="text" style="display:none;"/>
                        <input ref="autocomplete"
                               :placeholder='$t("Itinerary.modal.TransportationMap.type_to_search")'
                               class="form-control"
                               onfocus="value = ''"
                               type="text"/>
                    </form>
                    <div ref="map" class="map-container"></div>
                    <div class="alert alert-info">
                        {{$t("Itinerary.modal.TransportationMap.click_on_map_or_drag")}}
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-link" @click="close">{{$t("Itinerary.modal.TransportationMap.cancel")}}</button>
                    <button :disabled="markerNull" class="btn btn-primary" @click="setItemAddress">{{$t("Itinerary.modal.TransportationMap.update_location")}}</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {generateUUID, showError} from "../../../utils";
    import {autosaveMixin} from "../item/item-mixins";

    var map = null; // google map obj
    var marker = null; // google map marker
    var infoWindow = null; // google map marker info window
    var geocoder = null; // google maps geocoder
    var autocomplete = null; // google maps autocomplete
    // var places = null; // google maps places service

    export default {
        mixins: [
            autosaveMixin,
        ],
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            location: { // location obj { address: "", lat: "", lng: "" }
                type: Object,
                required: false,
            },
        },
        data() {
            return {
                address: "", // google map address

                markerNull: true,
            }
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function (e) {
                self.$emit('update:show', false);
            });
        },
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                    this.$refs.autocomplete.value="";
                    this.markerNull = true;
                    map = null; // google map obj
                    marker = null; // google map marker
                    infoWindow = null; // google map marker info window
                    geocoder = null; // google maps geocoder
                    autocomplete = null; // google maps autocomplete
                    this.setupMap();

                    /*if (!this.mapSetup) {
                        this.setupMap();
                    } else if (this.location &&
                        this.location.lat &&
                        this.location.lng
                    ) {
                        let center = {
                            lat: this.location.lat,
                            lng: this.location.lng,
                        };
                        this.address = this.location.address;
                        if (marker) {
                            marker.setMap(null);
                            // marker = null;
                        }
                        this.showItemMarker(center);
                    } else if (marker) {
                        marker.setMap(null);
                        // marker = null;
                    }*/
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
            },
        },
        computed: {
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            setupMap() {
                const self = this;
                let center = {
                    lat: 38.661,
                    lng: 4.065,
                };
                let hasPosition = false;
                if (this.location &&
                    this.location.lat &&
                    this.location.lng
                ) {
                    center.lat = this.location.lat;
                    center.lng = this.location.lng;
                    this.address = this.location.address;
                    hasPosition = true;
                }
                map = new google.maps.Map(this.$refs.map, {
                    center: center,
                    zoom: 3,
                });

                // Create the autocomplete object and associate it with the UI input control.
                // Restrict the search to the default country, and to place type "cities".
                autocomplete = new google.maps.places.Autocomplete(
                    (this.$refs.autocomplete),
                    {
                        // types: ['address'],
                        types: [],
                        fields: [
                            "formatted_address",
                            "geometry",
                        ],
                    }
                );
                autocomplete.addListener('place_changed', () => {
                    let place = autocomplete.getPlace();
                    if (place.formatted_address) {
                        self.address = place.formatted_address;
                    }
                    if (place.geometry) {
                        self.setMarkerOnLocation(place.geometry.location, false);
                        // map.panTo(place.geometry.location);
                        // map.setZoom(15);
                    }
                });

                if (hasPosition) {
                    this.showItemMarker(center);
                }

                map.addListener('click', function(e) {
                    self.setMarkerOnLocation(e.latLng, true);
                });
                // this.mapSetup = true;
            },
            setMarkerOnLocation(latLng, geocode) {
                const self = this;
                if (!infoWindow) {
                    infoWindow = new google.maps.InfoWindow({
                        content: self.address
                    });
                }

                if (!marker) {
                    marker = new google.maps.Marker({
                        position: latLng,
                        map: map,
                        draggable: true,
                    });
                    marker.addListener('click', function() {
                        if (self.address !== '')
                            infoWindow.open(map, marker);
                    });
                    marker.addListener('dragend', function(e) {
                        marker.setPosition(e.latLng);
                        self.geocodeAddress(e.latLng);
                    });
                } else {
                    marker.setPosition(latLng);
                }
                marker.setMap(map);
                map.panTo(latLng);
                map.setZoom(14);
                if (geocode) {
                    self.geocodeAddress(latLng);
                } else {
                    infoWindow.close();
                    infoWindow.setContent(self.address);
                    infoWindow.open(map, marker);
                }
                this.markerNull = marker === null;
            },
            geocodeAddress(latLng) {
                const self = this;
                if (!geocoder) {
                    geocoder = new google.maps.Geocoder();
                }
                geocoder.geocode({
                    'location': latLng
                }, function(results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            //alert(results[0].formatted_address);
                            if (results[0].formatted_address) {
                                self.address = results[0].formatted_address;
                            }
                            infoWindow.close();
                            infoWindow.setContent(self.address);
                            //infoWindow.close();
                            infoWindow.open(map, marker);
                        }
                    } else {
                        console.error("GEOCODER STATUS => " + status);
                    }
                });
            },
            showItemMarker(center) {
                const self = this;
                if (!infoWindow) {
                    infoWindow = new google.maps.InfoWindow({
                        content: self.address,
                    });
                }
                if (!marker) {
                    marker = new google.maps.Marker({
                        position: center,
                        map: map,
                        draggable: true,
                    });
                    marker.addListener('click', function () {
                        if (self.address !== '')
                            infoWindow.open(map, marker);
                    });
                    marker.addListener('dragend', function(e) {
                        marker.setPosition(e.latLng);
                        self.geocodeAddress(e.latLng);
                    });
                } else {
                    marker.setMap(map);
                    marker.setPosition(center);
                    infoWindow.close();
                    infoWindow.setContent(self.address);
                    infoWindow.open(map, marker);
                }
                map.panTo(center);
                map.setZoom(14);
                infoWindow.open(map, marker);
            },
            setItemAddress() {
                this.location.address = this.address;
                if (marker !== null) {
                    this.location.lat = marker.getPosition().lat();
                    this.location.lng = marker.getPosition().lng()
                }
                this.$emit("update:location", this.location);
                this.emitAutosave();
                this.close();
            }
        },
    }
</script>

<style scoped>
   .map-container {
       height: 400px;
       width: 100%;
   }
</style>
