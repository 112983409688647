<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3 class="text-center">
                    <input type="text" class="name-input"
                           v-model="item.title"
                    />
                </h3>
            </div>
        </div>
        <div class="row"
             v-for="(activity, index) in item.list"
             v-if="stringNotEmpty(activity.description)"
             :key="activity.id"
        >
            <div class="col-12">
                <div class="icon-left table-cell">
                    <i class="fa fa-check"></i>
                </div>
                <div class="bullet-right table-cell">
                    <h4 v-html="cleanupString(activity.description)">
                        <!--<input
                                type="text"
                                class="bullet-item-large mor-editable-field"
                                :placeholder="descriptionPlaceholder"
                                v-model="activity.description"
                                :ref="'input' + index"
                        />-->
                    </h4>
                </div>
            </div>
            <div class="col-12" v-if="stringNotEmpty(activity.description2)">
                <div style="padding-left: 30px;margin-bottom:10px;color:grey;font-style: italic;" v-html="cleanupString(activity.description2)">
                </div>
            </div>
            <div class="col-12 activity-prop" v-if="stringNotEmpty(activity.address)">
                <div class="icon-left table-cell">
                    <i class="fa fa-globe"></i>
                </div>
                <div class="table-cell">
                    {{activity.address}}
                </div>
           </div>
            <div class="col-12 activity-prop" v-if="stringNotEmpty(activity.duration)">
                <div class="icon-left table-cell" style="white-space: nowrap;">
                    <i class="fa fa-clock"></i>
                </div>
                <div class="bullet-right table-cell">
                    {{$t("Itinerary.TripActivity.tooltip.duration")}}: {{activity.duration}}
                </div>
            </div>
            <div class="col-12 activity-prop" v-if="stringNotEmpty(activity.language)">
                <div class="icon-left table-cell" style="white-space: nowrap;">
                    <i class="fa fa-language"></i>
                </div>
                <div class="bullet-right table-cell">
                    {{$t("Itinerary.TripActivity.tooltip.language")}}: {{activity.language}}
                </div>
            </div>
            <div class="col-12 activity-prop" style="white-space: nowrap;"
                 v-if="!item.included && stringNotEmpty(activity.price)"
            >
                <div class="icon-left table-cell" style="white-space: nowrap;">
                    <i class="fa fa-dollar-sign"></i>
                </div>
                <div class="bullet-right table-cell"
                     v-if="showPrice(activity)"
                >
                    {{$t("Itinerary.TripActivity.tooltip.price")}}: {{activity.price}} {{getPriceTypeLabel(activity)}}
                </div>
            </div>
            <div class="col-12 activity-prop" style="white-space: nowrap;" v-if="itData.status === 'SOLD' && stringNotEmpty(activity.contacts[0].name)">
                <div class="icon-left table-cell" style="white-space: nowrap;" v-tooltip='$t("Itinerary.TripActivity.tooltip.local_contact")'>
                    <i class="fa fa-user"></i>
                </div>
                <div class="table-cell" style="padding-left: 5px;">
                    {{activity.contacts[0].name}}
                </div>
                <div class="table-cell" style="padding-left: 5px;" v-if="stringNotEmpty(activity.contacts[0].phone)">
                    - {{activity.contacts[0].phone}}
                </div>
                <div class="table-cell" style="padding-left: 5px;" v-if="stringNotEmpty(activity.contacts[0].email)">
                     - {{activity.contacts[0].email}}
                </div>
            </div>
            <div class="col-12 activity-prop" style="white-space: nowrap;" v-if="itData.status === 'SOLD' && stringNotEmpty(activity.startTime)">
                <div class="icon-left table-cell" style="white-space: nowrap;" v-tooltip='$t("Itinerary.TripActivity.tooltip.start_time")'>
                    <i class="fa fa-clock"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 5px;">
                    {{$t("Itinerary.TripActivity.tooltip.start_time")}}: {{activity.startTime}}
                </div>
            </div>
            <div class="page-break-inside-avoid" style="width:100%;">
                <div class="row photos-row page-break-inside-avoid" v-if="activity.images.length > 1">
                    <div class="col-6 photos-col page-break-inside-avoid"
                         v-for="image in activity.images.slice(0, 2)"
                         :key="image.id">
                        <img class="img-fluid w-100 page-break-inside-avoid" :src="getImageUrl(image)">
                        <div class="mor-image-caption" v-if="stringNotEmpty(image.caption)">
                            <input type="text"
                                   v-model="image.caption"
                                   class="mor-image-caption-field"
                            />
                        </div>
                    </div>
                </div>
                <div class="row photos-row justify-content-center page-break-inside-avoid" v-if="activity.images.length === 1">
                    <div class="col-6 photos-col page-break-inside-avoid">
                        <img class="img-fluid w-100 page-break-inside-avoid" :src="getImageUrl(activity.images[0])">
                        <div class="mor-image-caption" v-if="stringNotEmpty(activity.images[0].caption)">
                            <input type="text"
                                   v-model="activity.images[0].caption"
                                   class="mor-image-caption-field"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {imageUrlMixin, stringUtilsMixin} from "../../item/item-mixins";

    export default {
        components: {
        },
        mixins: [
            imageUrlMixin,
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            itData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                // showButtons: false,
                selectedActivity: {
                    images: [],
                },

                languages: [
                    "English",  // TODO lang file
                    "Russian"
                ],
                durations: [
                    "1h",
                    "2h",
                    "3h",
                    "4h",
                    "5h",
                    "6h",
                    "7h",
                    "8h",
                    "9h",
                    "10h",
                ],
                priceTypes: [
                    {
                        value: "FREE",
                        label: this.$t("Itinerary.TripActivity.price_types.free"),
                    },
                    {
                        value: "PER_PERSON",
                        label: this.$t("Itinerary.TripActivity.price_types.per_person"),
                    },
                    {
                        value: "PER_GROUP",
                        label: this.$t("Itinerary.TripActivity.price_types.per_group"),
                    },
                ],
            }
        },
        computed: {
            descriptionPlaceholder() {
                return (this.item.included ?
                        this.$t("Itinerary.TripActivity.included_description") :
                        this.$t("Itinerary.TripActivity.optional_description")
                );
            }
        },
        methods: {
            getPriceTypeLabel(activity) {
                return this.priceTypes.find(type => {
                    return type.value === activity.priceType;
                }).label;
            },
            showPrice(activity) {
                return activity.priceType !== undefined &&
                    activity.priceType !== null &&
                    activity.priceType !== '' &&
                    activity.priceType !== 'FREE';
            },
        }
    }
</script>

<style scoped>
    .icon-left {
        width: 25px;
        text-align: center;
    }

    .activity-prop {
        padding-left: 39px;
        padding-top: 5px;
    }
</style>
