<template>
   <div>
       <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
           <div class="btn-group" role="group">
               <div class="btn-group dropleft" v-if="droppable">
                   <button class="btn btn-primary dropdown-toggle"
                       type="button"
                       data-toggle="dropdown"
                       aria-haspopup="true"
                       aria-expanded="false"
                       v-tooltip='$t("Itinerary.general.move_to_other_section")'
                   >
                       <i class="fa fa-angle-double-left"></i>
                   </button>
                   <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                       <button v-for="section in sections"
                           :key="section.id"
                           class="dropdown-item"
                           :class="{'disabled': section.id === currentSectionId}"
                           @click="moveItem(section.id)"
                       >
                           {{section.title}}
                       </button>
                   </div>
               </div>
               <button class="btn btn-danger"
                  v-if="droppable"
                   @click="$emit('delete-item', item)"
                   v-tooltip='$t("Itinerary.general.remove_item")'
               >
                   <i class="fa fa-trash"></i>
               </button>
           </div>
       </div>

       <h3>{{ $t('v2.Itinerary.defaults.ITEM_NAME_VIDEO_FILE') }}</h3>
       <div class="row">
         <div class="col">
            <el-input
               :placeholder="$t('Itinerary.TripVideoFile.title')"
               :readonly="!editable"
               v-model="item.title"
               @blur="emitAutosave"
            />
         </div>
       </div>
       <div class="row">
         <div class="col-2">
            <picture-input
               ref="pictureInput"
               accept="image/jpeg,image/png"
               size="10"
               button-class="btn btn-primary btn-sm d-print-none"
               remove-button-class="btn btn-danger btn-sm d-print-none"
               :crop="false"
               :prefill="imageUrl"
               :prefill-options="{
                  mediaType: 'image/jpeg',
               }"
               :removable="true"
               :custom-strings='customStrings'
               @change="onPictureChange"
               @remove="onPictureRemove"
            />
         </div>
           <div class="col">
               <span v-if="(typeof item.fileId === 'number')">
                   {{ item.filename }}
               </span>
               <template v-else>
                   <input
                       :disabled="!editable"
                       ref="fileInput"
                       type="file"
                       accept="video/*"
                   />
                   <button
                       class="btn btn-primary"
                       :disabled="!editable"
                       @click="uploadVideo"
                   >
                       {{ $t('Itinerary.TripVideoFile.upload') }}
                   </button>
               </template>
           </div>
       </div>
   </div>
</template>

<script>
import PictureInput from 'vue-picture-input'
import { showSuccess, handleAjaxError } from "../../../utils";
import {
   imageUrlMixin,
   autosaveMixin,
   moveItemMixin,
   itemCommonPropsMixin,
   stringUtilsMixin
} from "./item-mixins";

/**
 * Picture upload based on TripCarRental.vue
 */

export default {
   components: {
      PictureInput
   },
   mixins: [
      autosaveMixin,
      imageUrlMixin,
      moveItemMixin,
      itemCommonPropsMixin,
      stringUtilsMixin,
   ],
   props: {
      item: {
         type: Object,
         required: true,
      },
      droppable: {
            type: Boolean,
            default: true
      }
   },
   computed: {
      customStrings() {
         return {
            upload: '<h1>Bummer!</h1>', //TODO lang file
            drag: '<i class=\'fa fa-plus-circle\'></i><br/>' + this.$t("Itinerary.general.click_or_drag")
         };
      },
      imageUrl() {
         if (this.item.poster && this.item.poster.type !== null) {
            if (this.item.poster.type === "LOCAL") {
               // var quality esta na view twirl
               // eslint-disable-next-line
               return jsRoutes.controllers.ItineraryController.imageDownload(this.item.poster.id, quality).url
            }
         } else {
            return null;
            //return "/assets/images/car-icon.png";
         }
      }
   },
   methods: {
      uploadVideo() {
         const file = this.$refs.fileInput.files[0];

         if (file) {
            const form = new FormData();
            form.append('file', file);

            const url = jsRoutes.controllers.ItineraryController.uploadItineraryVideo(itinId).url;

            this.$http.post(url, form)
               .then(response => {
                  showSuccess(this.$t('Itinerary.TripVideoFile.uploadSuccess'));
                  this.item.fileId = response.data.id;
                  this.item.filename = response.data.filename;

                  this.emitAutosave();
               })
               .catch(function (error) {
                  handleAjaxError(error)
                  console.error('video file upload failed =>' + JSON.stringify(error.response));
               });
         }
      },
      onPictureChange() {
         if (this.$refs.pictureInput.image) {
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ItineraryController.imageUpload().url;

            const formData = new FormData();
            formData.append("file", this.$refs.pictureInput.file);
            formData.append('tags', ''); // required by imageUpload

            this.$http.post(url, formData)
               .then((response) => {
                  showSuccess(this.$t('Itinerary.TripVideoFile.uploadSuccess'));

                  this.item.poster = {
                     id: response.data.id,
                     type: "LOCAL"
                  };

                  this.emitAutosave();
               })
               .catch(function (error) {
                  handleAjaxError(error)
                  console.error("video file poster, something went wront.... => " + JSON.stringify(error.response));
               });
         }
      },
      onPictureRemove() {
         console.log('Picture remove.');
         //TODO remover server side
         this.item.poster = null;
         this.emitAutosave();
      },
   }
}
</script>

<style scoped>
.row + .row {
    margin-top: 1rem;
}
</style>
