<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h1>Migrate</h1>
                    <h3>Latest version: {{latestVersion}}</h3>
                </div>
                <div class="card-body">
                    <table class="table table-striped table-hover table-bordered">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Org</th>
                            <th>Update Date</th>
                            <th>Status</th>
                            <th>Version</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(trip, index) in list" :key="trip.id">
                            <td>{{trip.id}}</td>
                            <td>{{trip.name}}</td>
                            <td>x</td>
                            <td>{{formatUpdateDate(trip.updateDate)}}</td>
                            <td>{{trip.statusName}}</td>
                            <td>{{trip.tripData.version ? trip.tripData.version : "1.0"}}</td>
                            <td>
                                <v-button v-if="needsMigration(trip, index)"
                                          class="btn btn-sm btn-danger"
                                          @click="migrate(trip)"
                                          :loading="saving"
                                >
                                    Migrate
                                </v-button>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {handleAjaxError, showSuccess} from "../../utils";
    import VueBootstrapTable from "vue2-bootstrap-table2";
    import {upgradeItineraryToVersion2} from "../itinerary/itinerary-templates";
    import VButton from "../../components/VButton";
    import moment from 'moment'
    import Vue from 'vue'

    export default {
        components: {
            VueBootstrapTable,
            VButton,
        },
        // props: ["uuid"],
        data() {
            return {
                list: [],

                latestVersion: "2.0",
                saving: false
            }
        },
        mounted() {
            this.loadList();
        },
        computed: {
        },
        methods: {
            loadList: function () {
                const self = this;
                const url = jsRoutes.controllers.SuperAdminController.getItineraryList().url;

                // GET request
                axios.get(url).then(function (response) {
                    // set data
                    response.data.forEach(trip => {
                        if (!trip.version)
                            trip.version = "";
                    });
                    self.list = response.data;
                }).catch(function (error) {
                    // handle error
                    handleAjaxError(error);
                });
            },
            needsMigration(trip) {
                return !trip.tripData.version || trip.tripData.version !== this.latestVersion;
            },
            migrate(trip, index) {
                trip = upgradeItineraryToVersion2(trip);
                this.saving = true;
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.SuperAdminController.doMigrateItinerary().url;

                this.$http.post(url, trip)
                    .then(function (/*response*/) {
                        self.saving = false;
                        // Vue.set(self.list, index, trip);
                        showSuccess("Migrated '" + trip.name + "'");
                    })
                    .catch(function (error) {
                        self.saving = false;
                        // handle error
                        handleAjaxError(error);
                    });

            },
            formatUpdateDate(dateLong) {
                return moment(dateLong).toISOString();
            }
        }
    }
</script>

<style scoped>

</style>
