import { render, staticRenderFns } from "./TripPricePrint.vue?vue&type=template&id=70908ade&scoped=true&"
import script from "./TripPricePrint.vue?vue&type=script&lang=js&"
export * from "./TripPricePrint.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70908ade",
  null
  
)

export default component.exports