import { render, staticRenderFns } from "./TripNextSteps.vue?vue&type=template&id=55786f56&scoped=true&"
import script from "./TripNextSteps.vue?vue&type=script&lang=js&"
export * from "./TripNextSteps.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55786f56",
  null
  
)

export default component.exports