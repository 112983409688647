export default {
    "appname": "Morego App",
    "error.general_title": "Erro",
    "error.general_failed_loading_data": "Falha no carregamento de dados",
    "error.general_failed_upload_file": "Falha no upload do '{file}' com o erro: {msg}",
    "error.general_cancel": "Cancelar",
    "error.general_delete": "Apagar",
    "success.general_title": "Sucesso",
    "success.image_caption_updated": "Legenda da imagem atualizada",
    "root.close": "Fechar",
    "root.organization": "Nome da Criador",
    "root.required": "Este campo é obrigatório.",
    "root.organization_size": "Digite pelo menos 3 caracteres.",
    "root.organization_max": "O tamanho máximo é 255.",
    "root.firstname": "Primeiro nome",
    "root.lastname": "Último nome",
    "root.email": "Email",
    "root.email_valid": "O endereço de e-mail não é válido",
    "root.phone": "Telefone",
    "root.choose_password": "Escolha uma password",
    "root.choose_password_size": "Digite pelo menos 6 caracteres.",
    "root.choose_password_max": "O tamanho máximo é 255.",
    "root.confirm_password": "Confirme a password",
    "root.confirm_password_match": "As passwords devem corresponder.",
    "root.cancel": "Cancelar",
    "root.create_account": "Criar Conta",
    "root.something_weng_wrong": "Algo correu mal…",
    "root.invited_as_user": "Você foi convidado como usuário da organização",
    "root.already_have_account": "já tem uma conta?",
    "root.return_to_login": "Voltar ao login",
    "root.trial_period_ends": "O período de avaliação termina em",
    "root.last_subscription_period_end": "O último período da assinatura termina em",
    "root.subscription_ended": "Sua assinatura terminou em",
    "root.manage_your_subscriptions": "Faturação",
    "root.profile": "Perfil",
    "root.change_password": "Alterar Password",
    "root.profile_image": "Imagem de perfil",
    "root.upload_new_image": "Carregar nova imagem",
    "root.choose_file": "Escolher Ficheiro",
    "root.remove_image": "Remover imagem",
    "root.save": "Guardar",
    "root.profile_updated": "Perfil atualizado",
    "root.image_removed": "Imagem removida",
    "root.only_images_allowed": "Somente imagens são permitidas",
    "root.api_not_supported": "Desculpe, a API FileReader não é suportada",
    "root.file_uploaded": "Ficheiro carregado",
    "root.saved": "Guardado",
    "widgets.imageCropModal.position_and_size": "Posicione e dimensione sua nova imagem",
    "widgets.imageCropModal.save_button": "Guardar",
    "widgets.imageCropModal.close_button": "Fechar",
    "MobileAppsConfiguration.title": "Aplicação Mobile",
    "MobileAppsConfiguration.table.request_date": "Data do Pedido",
    "MobileAppsConfiguration.table.status": "Estado",
    "MobileAppsConfiguration.table.feedback": "Comentários",
    "MobileAppsConfiguration.table.no_build_request": "Nenhuma compilação solicitada ainda",
    "MobileAppsConfiguration.button.request_new_build": "Solicitar nova compilação",
    "MobileAppsConfiguration.button.cancel_build": "Cancelar compilação",
    "MobileAppsConfiguration.button.update_configuration": "Salvar configuração",
    "MobileAppsConfiguration.button.cancel": "Voltar",
    "MobileAppsConfiguration.title2": "Configuração de aplicativo móvel",
    "MobileAppsConfiguration.fields.agencyName": "Nome da Criador",
    "MobileAppsConfiguration.fields.about_agency": "Sobre a Criador",
    "MobileAppsConfiguration.fields.about_agency_required": "Este campo é obrigatório.",
    "MobileAppsConfiguration.fields.primary_color": "Cor princípal",
    "MobileAppsConfiguration.fields.primary_color_required": "Este campo é obrigatório.",
    "MobileAppsConfiguration.fields.background_color": "Cor de fundo",
    "MobileAppsConfiguration.fields.background_color_required": "Este campo é obrigatório.",
    "MobileAppsConfiguration.fields.mobile_logo": "Logotipo",
    "MobileAppsConfiguration.fields.mobile_icon": "Icon da Aplicação",
    "MobileAppsConfiguration.fields.imagesHelp": "Clique ou arraste uma imagem",
    "MobileAppsConfiguration.fields.showChatLabel": "Ativar Messenger no aplicativo móvel",
    "MobileAppsConfiguration.messages.mobile_request_title": "Solicitar nova Aplicação",
    "MobileAppsConfiguration.messages.mobile_request_question": "Solicitar nova Aplicação com as configurações atuais?",
    "MobileAppsConfiguration.messages.mobile_request_button": "Criar solicitação",
    "MobileAppsConfiguration.messages.cancel_mobile_request_title": "Cancelar solicitação",
    "MobileAppsConfiguration.messages.cancel_mobile_request_question": "Cancelar esta solicitação de compilação?",
    "MobileAppsConfiguration.messages.cancel_mobile_request_button": "Cancelar",
    "MobileAppsConfiguration.messages.configuration_updated": "Configuração atualizada",
    "AgencyConfiguration.title": "Configuração",
    "AgencyConfiguration.mustbeconfigured": "As informações da criador precisam ser configuradas!",
    "AgencyConfiguration.fields.agencyName": "Nome da Criador",
    "AgencyConfiguration.fields.agencyName_required": "Este campo é obrigatório.",
    "AgencyConfiguration.fields.agencyName_size": "O tamanho máximo é 255.",
    "AgencyConfiguration.fields.email": "Email",
    "AgencyConfiguration.fields.email_required": "Este campo é obrigatório.",
    "AgencyConfiguration.fields.email_badformat": "O endereço de e-mail não é válido",
    "AgencyConfiguration.fields.website": "Site da criador",
    "AgencyConfiguration.fields.website_badformat": "O URL não é válido.",
    "AgencyConfiguration.fields.website_size": "O tamanho máximo é 255.",
    "AgencyConfiguration.fields.phone": "Telefone",
    "AgencyConfiguration.fields.phone_required": "Este campo é obrigatório.",
    "AgencyConfiguration.fields.phone_size": "O tamanho máximo é 100.",
    "AgencyConfiguration.fields.address": "Endereço",
    "AgencyConfiguration.fields.address_required": "Este campo é obrigatório.",
    "AgencyConfiguration.fields.address_size": "O tamanho máximo é 500.",
    "AgencyConfiguration.fields.city": "Cidade",
    "AgencyConfiguration.fields.city_required": "Este campo é obrigatório.",
    "AgencyConfiguration.fields.city_size": "O tamanho máximo é 100.",
    "AgencyConfiguration.fields.postalCode": "Código postal",
    "AgencyConfiguration.fields.postalCode_required": "Este campo é obrigatório.",
    "AgencyConfiguration.fields.postalCode_size": "O tamanho máximo é 45.",
    "AgencyConfiguration.fields.country": "País",
    "AgencyConfiguration.fields.country_required": "Este campo é obrigatório.",
    "AgencyConfiguration.fields.country_size": "O tamanho máximo é 100.",
    "AgencyConfiguration.fields.vat": "NIF",
    "AgencyConfiguration.fields.vat_onlynumbers": "Apenas números.",
    "AgencyConfiguration.fields.vat_size": "O tamanho máximo é 45.",
    "AgencyConfiguration.fields.regnumb": "Número de registro",
    "AgencyConfiguration.fields.regnumb_size": "O tamanho máximo é 255.",
    "AgencyConfiguration.fields.date_format": "Formato de data a utilizar",
    "AgencyConfiguration.fields.currency": "Moeda Padrão",
    "AgencyConfiguration.fields.currency_select": "Escolha a moeda",
    "AgencyConfiguration.fields.agencyLogo": "Logotipo",
    "AgencyConfiguration.fields.agencyLogoHelp": "Clique ou arraste uma imagem",
    "AgencyConfiguration.fields.facebookLink": "Facebook",
    "AgencyConfiguration.fields.instagramLink": "Instagram",
    "AgencyConfiguration.fields.twitterLink": "Twitter",
    "AgencyConfiguration.fields.youtubeLink": "Youtube",
    "AgencyConfiguration.fields.genericMaxSize": "O tamanho máximo é {max}",
    "AgencyConfiguration.buttons.updateConfiguration": "Atualizar configuração",
    "AgencyConfiguration.buttons.backbutton": "Voltar",
    "AgencyConfiguration.messages.configuration_updated": "Configuração atualizada",
    "AgencyConfiguration.fields.description": "Descrição",
    "AgencyConfiguration.fields.inviteCode": "Código de convite",
    "AgencyConfiguration.fields.inviteCode_generate": "Gerar",
    "AgencyConfiguration.fields.inviteCode_maxLength": "O tamanho máximo é 12.",
    "AgencyConfiguration.fields.inviteCode_minLength": "O tamanho mínimo é 4",
    "AgencyConfiguration.fields.inviteCode_upperAlpha": "Somente caracteres latinos maiúsculos",
    "AgencyConfiguration.fields.inviteCode_isUnique": "Deve ser único",
    "SubscriptionManager.title": "Faturação",
    "SubscriptionManager.invoicesTitle": "Faturas",
    "SubscriptionManager.loadingInvoices": "A Carregar faturas ...",
    "SubscriptionManager.tab.subscription": "A Sua assinatura",
    "SubscriptionManager.tab.invoices": "As suas Faturas",
    "SubscriptionManager.tab.payment_methods": "Os seus métodos de pagamento",
    "SubscriptionManager.monthlyPlan": "Sua assinatura",
    "SubscriptionManager.planAmount": "Montante do plano",
    "SubscriptionManager.subscriptionTitle": "Sua assinatura",
    "SubscriptionManager.cardsTitle": "Cartões de crédito",
    "SubscriptionManager.loadingCards": "A Carregar cartões ...",
    "SubscriptionManager.availablePlansTitle": "Planos Disponíveis",
    "SubscriptionManager.confirmationTitle": "Confirmação",
    "SubscriptionManager.table.period": "Período",
    "SubscriptionManager.table.amount": "Montante",
    "SubscriptionManager.button.download": "Download",
    "SubscriptionManager.button.cancel_subscription": "Cancelar assinatura",
    "SubscriptionManager.button.subscribe": "Subscrever",
    "SubscriptionManager.button.use_this_card": "Pague agora",
    "SubscriptionManager.button.i_am_sure": "Tenho a certeza",
    "SubscriptionManager.fields.last_subscription_ends": "O último período da assinatura termina em",
    "SubscriptionManager.fields.subscription_ended": "A sua assinatura terminou em",
    "SubscriptionManager.fields.subscription_renew": "A sua assinatura será renovada automaticamente em",
    "SubscriptionManager.fields.expire_card": "Exp",
    "SubscriptionManager.fields.selected": "Selecionado",
    "SubscriptionManager.fields.payment_options": "Método de pagamento",
    "SubscriptionManager.fields.card_details": "Forneça detalhes do cartão a ser usado para cobrança",
    "SubscriptionManager.fields.ending_with": "terminando com",
    "SubscriptionManager.fields.valid_until": "Válido até",
    "SubscriptionManager.fields.important": "Importante",
    "SubscriptionManager.fields.trial_period_ended": "O período experimental terminou em",
    "SubscriptionManager.fields.trial_period_ends": "O período de avaliação termina em",
    "SubscriptionManager.fields.suspended_account_nostripe": "A sua conta está suspensa neste momento. Por favor, inscreva-se no Morego para continuar.",
    "SubscriptionManager.fields.suspended_account_updatemethod": "A sua conta está suspensa neste momento. Isso pode ser devido à falta ou pagamento ou fim da assinatura. Substitua seu método de pagamento ou inscreva-se para continuar usando o Morego.",
    "SubscriptionManager.fields.eur_cur": "EURO",
    "SubscriptionManager.fields.dol_cur": "DOLLAR",
    "SubscriptionManager.fields.gbp_cur": "GBP",
    "SubscriptionManager.button.update_card": "Actualizar utilizando este cartão",
    "SubscriptionManager.invoice.date": "Invoice Date",
    "SubscriptionManager.invoice.value": "Amount",
    "SubscriptionManager.warning.stripeproblem": "There was a problem with your Credit Card: ",
    "SubscriptionManager.warning.subscription_not_active": "Sua assinatura não está ativa. Verifique os detalhes da sua assinatura.",
    "SubscriptionManager.warning.canceled_but_still_time": "Sua assinatura foi cancelada e deixará de funcionar nesta data:",
    "SubscriptionManager.warning.account_locked_nosubs": "Sua conta agora está bloqueada porque você não tem uma assinatura. Para continuar usando o Morego, verifique a seção de Faturação.",
    "SubscriptionManager.warning.trial_ongoing": "Você tem uma assinatura de avaliação até a seguinte data:",
    "SubscriptionManager.warning.trial_finished": "Você tem uma assinatura de avaliação encerrada. Para continuar usando o Morego, vá para a seção de Faturação e Inscreva-se.",
    "SubscriptionManager.warning.generic_lock": "Você não tem uma assinatura ativa. Verifique a seção de Faturação.",
    "UserInvite.title": "Convidar usuários",
    "UserInvite.fields.email": "Email",
    "UserInvite.fields.email_required": "Este campo é obrigatório.",
    "UserInvite.fields.email_invalid": "O endereço de e-mail não é válido.",
    "UserInvite.fields.user_type": "Tipo",
    "UserInvite.fields.user_type_required": "Este campo é obrigatório.",
    "UserInvite.button.send_invite": "Enviar convite",
    "UserInvite.button.go_back": "Voltar",
    "UserInvite.messages.failed_loading_roles": "Erro ao carregar funções",
    "UserInvite.messages.invite_sent": "Convite enviado",
    "UserList.title": "Utilizador",
    "UserList.invites_title": "Convites",
    "UserList.button.invite_user": "Convidar utilizador",
    "UserList.table.name": "Nome",
    "UserList.table.email": "Email",
    "UserList.table.type": "Tipo",
    "UserList.table.sent_by": "Enviado por",
    "UserList.table.date": "Data",
    "UserList.messages.delete_user": "Apagar utilizador",
    "UserList.messages.invites_table_empty": "Lista de convites vazia",
    "UserList.messages.delete_invite": "Apagar convite",
    "ClientList.title": "Subscritores",
    "ClientList.tooltip.import_clients": "Importe os seus subscritores existentes de um ficheiro Excel",
    "ClientList.button.create_client": "Criar Subscritore",
    "ClientList.button.import_clients": "Importar Subscritores",
    "ClientList.button.edit": "Editar",
    "ClientList.button.del": "Apagar",
    "ClientList.table.name": "Nome",
    "ClientList.table.email": "Email",
    "ClientList.table.phone": "Telefone",
    "ClientList.table.actions": "Ações",
    "ClientList.messages.delete_title": "Tem a certeza?",
    "ClientList.messages.delete_make_sure_1": "Uma vez removido,",
    "ClientList.messages.delete_make_sure_2": "não será possível aceder aos seus dados.",
    "ClientList.messages.delete_title_success": "O Subscritore foi apagado",
    "ClientImport.title": "Importar Subscritores",
    "ClientImport.go_back": "Voltar",
    "ClientImport.button.create_client": "Criar Subscritore",
    "ClientImport.button.import_clients": "Importar Subscritores",
    "ClientImport.button.edit": "Editar",
    "ClientImport.button.del": "Apagar",
    "ClientImport.file_warning_part1": "IMPORTANTE: O ficheiro a ser carregado deve ter o mesmo formato que este",
    "ClientImport.file_warning_part2": "Exemplo",
    "ClientImport.processing": "A Processar…",
    "ClientImport.results": "Resultados",
    "ClientImport.dropzone_remove_file": "Remover Ficheiro",
    "ClientImport.dropzone_invalid_file_type": "Apenas .xlsx são aceites",
    "ClientModal.update_client_title": "Atualizar Subscritore",
    "ClientModal.create_client_title": "Criar Subscritore",
    "ClientModal.fields.name": "Nome",
    "ClientModal.fields.name_required": "Este campo é obrigatório.",
    "ClientModal.fields.name_size": "O tamanho máximo é 255.",
    "ClientModal.fields.email": "Email",
    "ClientModal.fields.email_required": "Este campo é obrigatório.",
    "ClientModal.fields.email_format": "O endereço de e-mail não é válido.",
    "ClientModal.fields.phone": "Telefone",
    "ClientModal.fields.phone_size": "O tamanho máximo é 100.",
    "ClientModal.button.create_client": "Criar Subscritore",
    "ClientModal.button.update_client": "Atualizar Subscritore",
    "ClientModal.messages.client_created": "Subscritore criado",
    "ClientModal.messages.client_updated": "Subscritore atualizado",
    "Itinerary.general.save_to_library": "Guarda na Biblioteca",
    "Itinerary.general.update_to_library": "Atualizar Biblioteca",
    "Itinerary.general.remove_item": "Remover item",
    "Itinerary.general.remove_line": "Remover linha",
    "Itinerary.general.add_line": "Adicionar linha",
    "Itinerary.general.click_to_edit": "Clique para editar",
    "Itinerary.general.click_or_drag": "Clique ou arraste uma imagem",
    "Itinerary.general.click_or_drag_images_to_upload": "Clique ou arraste as imagens aqui para fazer o upload",
    "Itinerary.general.click_or_drag_to_upload": "Clique ou arraste os ficheiros aqui para fazer o upload",
    "Itinerary.general.show_on_map": "Mostrar no mapa",
    "Itinerary.general.return_string": "Voltar",
    "Itinerary.general.ongoing_string": "A decorrer",
    "Itinerary.general.move_to_other_section": "Mover para outra secção",
    "Itinerary.general.autocomplete_information": "Informações de preenchimento automático",
    "Itinerary.general.image_caption": "Escreva legenda da imagem aqui",
    "Itinerary.general.add_images": "Adicione imagens",
    "Itinerary.general.add_rating": "Adicionar classificação",
    "Itinerary.general.add_item": "Adicionar Item",
    "Itinerary.general.google_rating": "Classificação do Google",
    "Itinerary.general.open_website": "Abrir site",
    "Itinerary.general.website": "Website",
    "Itinerary.general.label": "Rótulo",
    "Itinerary.general.link": "Link",
    "Itinerary.general.close": "Fechar",
    "Itinerary.general.add_from_library": "Adicionar da sua biblioteca",
    "Itinerary.general.load_from_library": "Carregar da sua biblioteca",
    "Itinerary.general.days": "dias",
    "Itinerary.general.invalid_fields_error": "Existem campos inválidos",
    "Itinerary.builder.add_cover": "Adicionar seção de capa",
    "Itinerary.builder.add_section": "Adicionar seção",
    "Itinerary.builder.add_day_section": "Adicionar dia",
    "Itinerary.builder.car_rental_return": "Devolução de carro de aluguer",
    "Itinerary.builder.car_rental_pickup": "Pick-up de carro de aluguer",
    "Itinerary.builder.car_rental": "Carro de Aluguer",
    "Itinerary.builder.checkout": "Checkout",
    "Itinerary.builder.checkin": "Checkin",
    "Itinerary.builder.flight": "Voo",
    "Itinerary.builder.click_to_change_title": "Clique para alterar o título",
    "Itinerary.builder.remove_section": "Remover secção",
    "Itinerary.builder.add_item": "Adicionar Item",
    "Itinerary.builder.reorder_items": "Reordenar itens",
    "Itinerary.builder.show_title_on_pdf": "Mostrar título no PDF",
    "Itinerary.builder.new_section": "Nova secção",
    "Itinerary.builder.new_end_section": "Nova secção final",
    "Itinerary.builder.are_you_sure": "Tem a certeza?",
    "Itinerary.builder.new_general_section": "Outra secção",
    "Itinerary.builder.new_day": "Novo dia",
    "Itinerary.builder.new_day_message": "Um novo dia foi adicionado. Por favor confira os titulos dos dias restantes e as datas do Itinerario.",
    "Itinerary.builder.remove_message": "Remover estas secções e todos os seus itens?",
    "Itinerary.builder.remove_button": "Remover",
    "Itinerary.builder.remove_item_message": "Remover este item?",
    "Itinerary.builder.item_moved": "Item movido",
    "Itinerary.builder.dates_updated": "Datas atualizadas",
    "Itinerary.builder.add_item_problem_title": "Problema…",
    "Itinerary.builder.add_item_problem_msg": "Não há secção de dia para o primeiro dia, {day}",
    "Itinerary.builder.create.create_new_itinerary": "Criar novo Activo de viagem",
    "Itinerary.builder.create.itinerary_language": "Idioma a ser usado no activo de viagem",
    "Itinerary.builder.create.select_days_keep_template_warning": "Selecione os dias para manter do template. {numDays} MAX!",
    "Itinerary.builder.create.select_days_error": "Seu activo de viagem terá {numDays} dias. Selecione no máximo {numDays} dias do template.",
    "Itinerary.builder.create.name": "Nome",
    "Itinerary.builder.create.name_required": "Este campo é obrigatório.",
    "Itinerary.builder.create.name_size": "O tamanho máximo é 255.",
    "Itinerary.builder.create.date_format": "Formato da data",
    "Itinerary.builder.create.use_itinerary_template": "Usar activo de viagem como Template (opcional)",
    "Itinerary.builder.create.open_in_new_tab_tooltip": "Abrir num novo Tab",
    "Itinerary.builder.create.select_itinerary": "Selecionar activos de viagem",
    "Itinerary.builder.create.dates": "Datas",
    "Itinerary.builder.create.start_date": "Data de início",
    "Itinerary.builder.create.end_date": "Data final",
    "Itinerary.builder.create.field_required": "Este campo é obrigatório.",
    "Itinerary.builder.create.countries": "Países",
    "Itinerary.builder.create.select_countries": "Selecione países",
    "Itinerary.builder.create.currency": "Moeda",
    "Itinerary.builder.create.currency_select": "Escolha a moeda",
    "Itinerary.builder.create.clients": "Subscritores",
    "Itinerary.builder.create.clients_select": "Selecionar subscritore(s)",
    "Itinerary.builder.create.create_client": "Criar Subscritore",
    "Itinerary.builder.create.create": "Criar Subscritore",
    "Itinerary.builder.create.cancel": "Cancelar",
    "Itinerary.builder.create.message_your_iti_will_have": "Seu activo de viagem terá",
    "Itinerary.builder.create.message_days_instead_of": "dias em vez de",
    "Itinerary.builder.create.message_you_have_days": "Tem itens nos dias do template que serão deixados de fora. Altere as datas do seu activo de viagem para que tenha pelo menos",
    "Itinerary.builder.create.message_choose_another_template": "dias ou escolha outro template.",
    "Itinerary.builder.create.message_details_will_be_lost": "Quaisquer detalhes sobre esses dias extras serão perdidos.",
    "Itinerary.builder.templatecreate.title": "Criar novo Template",
    "Itinerary.builder.templatecreate.itinerary_language": "Idioma a ser usado no activo de viagem",
    "Itinerary.builder.templatecreate.select_days_keep_template_warning": "Selecione os dias para manter do template. {numDays} MAX!",
    "Itinerary.builder.templatecreate.select_days_error": "Seu activo de viagem terá {numDays} dias. Selecione no máximo {numDays} dias do template.",
    "Itinerary.builder.templatecreate.name": "Nome",
    "Itinerary.builder.templatecreate.name_size": "O tamanho máximo é 255.",
    "Itinerary.builder.templatecreate.date_format": "Formato de data",
    "Itinerary.builder.templatecreate.use_itinerary_template": "Usar activo de viagem como Template (opcional)",
    "Itinerary.builder.templatecreate.open_in_new_tab_tooltip": "Abrir num novo Tab",
    "Itinerary.builder.templatecreate.select_itinerary": "Selecionar activos de viagem",
    "Itinerary.builder.templatecreate.field_required": "Este campo é obrigatório.",
    "Itinerary.builder.templatecreate.days": "Número de dias",
    "Itinerary.builder.templatecreate.num_days_minvalue": "O valor mínimo é 1",
    "Itinerary.builder.templatecreate.countries": "Países",
    "Itinerary.builder.templatecreate.select_countries": "Selecione países",
    "Itinerary.builder.templatecreate.currency": "Moeda",
    "Itinerary.builder.templatecreate.currency_select": "Escolha a moeda",
    "Itinerary.builder.templatecreate.create": "Criar",
    "Itinerary.builder.templatecreate.cancel": "Cancelar",
    "Itinerary.builder.templatecreate.message_your_iti_will_have": "Seu template terá",
    "Itinerary.builder.templatecreate.message_days_instead_of": "dias em vez de",
    "Itinerary.builder.templatecreate.message_you_have_days": "Tem itens de dia no Template que serão deixados de fora. Altere o número de dias do seu Template para que ele tenha pelo menos",
    "Itinerary.builder.templatecreate.message_choose_another_template": "dias ou escolha outro template.",
    "Itinerary.builder.templatecreate.message_details_will_be_lost": "Quaisquer detalhes sobre esses dias extras serão perdidos.",
    "Itinerary.builder.detail.not_visible_mobile": "Este activos de viagem não é visível no aplicativo para dispositivos móveis, pois seu estado é Esboço.",
    "Itinerary.builder.detail.itinerary": "Activo de viagem",
    "Itinerary.builder.detail.template_title": "Template de activo de viagem",
    "Itinerary.builder.detail.create_copy_btn": "Copiar",
    "Itinerary.builder.detail.create_copy_tooltip": "Copie para um novo Template ou activo de viagem",
    "Itinerary.builder.detail.copy_to_template_btn": "Copiar para um novo Template",
    "Itinerary.builder.detail.copy_to_itinerary_btn": "Copiar para o novo activo de viagem",
    "Itinerary.builder.detail.click_edit": "Clique para editar",
    "Itinerary.builder.detail.manager": "Gestor",
    "Itinerary.builder.detail.published": "Publicado",
    "Itinerary.builder.detail.published_tooltip": "Publicado na aplicação móvel",
    "Itinerary.builder.detail.notpublished": "Não publicado",
    "Itinerary.builder.detail.notpublished_tooltip": "Não publicado na aplicação móvel",

    "Itinerary.builder.detail.showInterestButton": "A Mostar Botões de Interesse",
    "Itinerary.builder.detail.showInterestButtonToolTip": "Mostra um Botão para demonstrar interesse nos itens de Actividades Opcionais na Aplicação Movel",
    "Itinerary.builder.detail.showInterestButtonNot": "Não Mostar Botões de Interesse",
    "Itinerary.builder.detail.showInterestButtonNotToolTip": "Não nostra um Botão para demonstrar interesse nos itens de Actividades Opcionais na Aplicação Movel",

    "Itinerary.builder.detail.change_manager_tooltip": "Alterar gestor da viagem",
    "Itinerary.builder.detail.status": "Estado",
    "Itinerary.builder.detail.status_change": "Alterar Estado",
    "Itinerary.builder.detail.draft": "Esboço",
    "Itinerary.builder.detail.sold": "Preparar",
    "Itinerary.builder.detail.send_by_email": "Enviar por e-mail",
    "Itinerary.builder.detail.settings": "Configurações",
    "Itinerary.builder.detail.documentation": "Documentação",
    "Itinerary.builder.detail.trip": "Viagem",
    "Itinerary.builder.detail.map": "Mapa",
    "Itinerary.builder.detail.gpxroutes": "Rotas",
    "Itinerary.builder.detail.save": "Guardar",
    "Itinerary.builder.detail.last_saved": "Guardado pela última vez",
    "Itinerary.builder.detail.undo": "Desfazer",
    "Itinerary.builder.detail.redo": "Refazer",
    "Itinerary.builder.detail.included": "Incluído",
    "Itinerary.builder.detail.optional": "Opcional",
    "Itinerary.builder.detail.name_updated": "Nome atualizado",
    "Itinerary.builder.detail.iti_updated": "Activo de viagem atualizado",
    "Itinerary.builder.detail.export_pdf": "Exportar para PDF",
    "Itinerary.builder.detail.export_pdf_full": "PDF em tamanho grande (imagens de alta qualidade)",
    "Itinerary.builder.detail.export_pdf_medium": "PDF de tamanho médio (imagens de qualidade média)",
    "Itinerary.builder.detail.export_pdf_low": "PDF de tamanho reduzido (imagens de baixa qualidade)",
    "Itinerary.builder.documents.tab_help": "Faça o download de qualquer material útil em formato pdf, jpeg ou png aqui, e eles estarão disponíveis para download pelos assinantes do seu passeio na seção \"Documentos\"",
    "Itinerary.builder.documents.upload_document": "Carregar documento",
    "Itinerary.builder.documents.filename": "Nome do ficheiro",
    "Itinerary.builder.documents.description": "Descrição",
    "Itinerary.builder.documents.filename_mandatory": "Nome do ficheiro é obrigatório",
    "Itinerary.builder.documents.filename_edited": "O ficheiro foi editado",
    "Itinerary.builder.documents.are_you_sure": "Tem a certeza?",
    "Itinerary.builder.documents.are_you_sure_delete": "Tem certeza de que deseja apagar",
    "Itinerary.builder.documents.yes": "Sim",
    "Itinerary.builder.documents.file_was_deleted": "O Ficheiro foi apagado",
    "Itinerary.builder.map.full_trip_btn": "Viagem completa",
    "Itinerary.builder.map.full_trip_tooltip": "Clique para mudar para o mapa de dia a dia",
    "Itinerary.builder.map.day_trip_btn": "Dia {day}",
    "Itinerary.builder.map.day_trip_tooltip": "Clique para mudar para o mapa completo da viagem",
    "Itinerary.builder.gpx.tab_help": "Baixe os arquivos GPX aqui para adicionar informações sobre rotas e pontos de referência no mapa do seu passeio. Para torná-lo visível para seus assinantes, marque as rotas e pontos de referência necessários nas caixas de seleção à esquerda e atribua-lhes os dias desejados do passeio",
    "Itinerary.builder.gpx.upload_file": "Carregar ficheiro GPX",
    "Itinerary.builder.gpx.routes_heading": "Rotas",
    "Itinerary.builder.gpx.waypoints_heading": "Pontos",
    "Itinerary.builder.gpx.set_day": "Definir dia",
    "Itinerary.builder.gpx.delete": "Apagar",
    "Itinerary.builder.gpx.all_label": "TODOS",
    "Itinerary.builder.gpx.none_label": "NENHUM",
    "Itinerary.builder.gpx.name_col": "Nome",
    "Itinerary.builder.gpx.day_col": "Dia",
    "Itinerary.builder.gpx.routes_are_you_sure_title": "Tem a certeza?",
    "Itinerary.builder.gpx.routes_are_you_sure_msg": "Tem certeza de que deseja excluir as rotas selecionadas?",
    "Itinerary.builder.gpx.routes_delete_success": "Rotas excluídas",
    "Itinerary.builder.gpx.waypoints_are_you_sure_title": "Tem a certeza?",
    "Itinerary.builder.gpx.waypoints_are_you_sure_msg": "Tem certeza de que deseja excluir os pontos de interesse selecionados?",
    "Itinerary.builder.gpx.waypoints_delete_success": "Pontos de interesse excluídos",
    "Itinerary.builder.gpx.day_form": "Dia",
    "Itinerary.builder.ItineraryList.itineraries": "Activos de viagem",
    "Itinerary.builder.ItineraryList.create_new": "Crie um novo",
    "Itinerary.builder.ItineraryList.name": "Nome",
    "Itinerary.builder.ItineraryList.countries": "Países",
    "Itinerary.builder.ItineraryList.days": "Dias",
    "Itinerary.builder.ItineraryList.clients": "Subscritores",
    "Itinerary.builder.ItineraryList.manager": "Gestor",
    "Itinerary.builder.ItineraryList.created": "Criado",
    "Itinerary.builder.ItineraryList.updated": "Atualizado",
    "Itinerary.builder.ItineraryList.published": "Publicado",
    "Itinerary.builder.ItineraryList.status": "Estado",
    "Itinerary.builder.ItineraryList.actions": "Ações",
    "Itinerary.builder.ItineraryList.delete_label": "Apagar",
    "Itinerary.builder.ItineraryList.message_once_removed": "Uma vez removido",
    "Itinerary.builder.ItineraryList.message_will_no_longer": "não estará mais disponível para o subscritore!",
    "Itinerary.builder.ItineraryList.are_you_sure": "Tem a certeza?",
    "Itinerary.builder.ItineraryList.itinerary_was_deleted": "O activos de viagem foi apagado",
    "Itinerary.builder.TemplateList.templates": "Templates",
    "Itinerary.builder.TemplateList.create_new": "Crie um novo",
    "Itinerary.builder.TemplateList.name": "Nome",
    "Itinerary.builder.TemplateList.countries": "Países",
    "Itinerary.builder.TemplateList.days": "Dias",
    "Itinerary.builder.TemplateList.clients": "Subscritores",
    "Itinerary.builder.TemplateList.manager": "Gestor",
    "Itinerary.builder.TemplateList.created": "Criado",
    "Itinerary.builder.TemplateList.updated": "Atualizado",
    "Itinerary.builder.TemplateList.published": "Publicado",
    "Itinerary.builder.TemplateList.status": "Estado",
    "Itinerary.builder.TemplateList.actions": "Ações",
    "Itinerary.builder.TemplateList.delete_label": "Apagar",
    "Itinerary.builder.TemplateList.message_once_removed": "Uma vez removido",
    "Itinerary.builder.TemplateList.message_will_no_longer": "não estará mais disponível para o subscritore!",
    "Itinerary.builder.TemplateList.are_you_sure": "Tem a certeza?",
    "Itinerary.builder.TemplateList.template_was_deleted": "O modelo foi apagado",
    "Itinerary.builder.settings.itinerary_language": "Idioma a ser usado no activo de viagem",
    "Itinerary.builder.settings.date_format": "Formato de data",
    "Itinerary.builder.settings.dates": "Datas",
    "Itinerary.builder.settings.start_date": "Data de início",
    "Itinerary.builder.settings.end_date": "Data final",
    "Itinerary.builder.settings.days": "Número de dias",
    "Itinerary.builder.settings.field_required": "Este campo é obrigatório.",
    "Itinerary.builder.settings.num_days_minvalue": "O valor mínimo é 1",
    "Itinerary.builder.settings.countries": "Países",
    "Itinerary.builder.settings.countries_select": "Selecione países",
    "Itinerary.builder.settings.currency": "Moeda",
    "Itinerary.builder.settings.currency_select": "Escolha a moeda",
    "Itinerary.builder.settings.cost": "Taxa",
    "Itinerary.builder.settings.duration": "Duração do passeio",
    "Itinerary.builder.settings.trip_type": "Tipo de activo de viagem",
    "Itinerary.builder.settings.clients": "Subscritores",
    "Itinerary.builder.settings.clients_select": "Selecionar subscritore(s)",
    "Itinerary.builder.settings.create_client": "Criar Subscritore",
    "Itinerary.builder.settings.message_cant_change_trip_dates": "Não é possível alterar as datas da viagem",
    "Itinerary.builder.settings.message_your_iti_will_have": "Seu activo de viagem terá",
    "Itinerary.builder.settings.message_days_instead_of": "dias em vez de",
    "Itinerary.builder.settings.message_days_will_be_left_out": "Tem itens nos dias que serão deixados de fora. Altere-os de acordo com os novos dias primeiro.",
    "Itinerary.builder.settings.message_details_may_lost": "Quaisquer detalhes sobre esses dias extras serão perdidos.",
    "Itinerary.builder.settings.message_continue": "Continuar",
    "Itinerary.builder.library.library": "Biblioteca",
    "Itinerary.builder.library.title": "Título",
    "Itinerary.builder.library.click_to_change_name": "Clique para alterar o nome do item",
    "Itinerary.builder.library.tags": "Tags",
    "Itinerary.builder.library.tag_select_add": "Selecione tags ou adicione novas",
    "Itinerary.builder.library.cancel": "Cancelar",
    "Itinerary.builder.library.save": "Guardar",
    "Itinerary.builder.library.item_update": "Item da biblioteca atualizado",
    "Itinerary.builder.library.name_address": "Nome/Endereço",
    "Itinerary.builder.library.tag_select": "Selecionar tag(s)",
    "Itinerary.builder.library.type": "Tipo",
    "Itinerary.builder.library.status": "Estado",
    "Itinerary.builder.library.active": "Ativo",
    "Itinerary.builder.library.inactive": "Inativo",
    "Itinerary.builder.library.name": "Nome",
    "Itinerary.builder.library.address": "Endereço",
    "Itinerary.builder.library.created_at": "Criado em",
    "Itinerary.builder.library.actions": "Ações",
    "Itinerary.builder.library.message_deactivate_wont_be_availabel": "Desativar (não estará mais disponível para uso)",
    "Itinerary.builder.library.item_deactivated": "Item desativado",
    "Itinerary.builder.library.accommodation": "Alojamento",
    "Itinerary.builder.library.meals": "Refeições",
    "Itinerary.builder.library.content": "Conteúdo",
    "Itinerary.builder.library.photos": "Fotos",
    "Itinerary.builder.library.sections": "Secções",
    "Itinerary.builder.library.caption_location": "Legenda/Localização",
    "Itinerary.builder.library.click_to_change_caption": "Clique para alterar a legenda",
    "Itinerary.builder.library.write_caption_here": "Escreva legenda da imagem aqui",
    "Itinerary.builder.library.click_to_change_tags": "Clique para alterar as tags",
    "Itinerary.builder.library.load_more": "Carregue mais",
    "Itinerary.chat.send": "Enviar",
    "Itinerary.chat.your_message": "A sua mensagem",
    "Itinerary.modal.UploadGpx.title": "Carregar ficheiro GPX",
    "Itinerary.modal.UploadGpx.close": "Fechar",
    "Itinerary.modal.UploadGpx.dropzone_invalid_file_type": "Somente ficheiros .gpx são aceites",
    "Itinerary.modal.ImageUpload.pixabay_free": "Pixabay fotos gratuitas de alta resolução",
    "Itinerary.modal.ImageUpload.library_photos": "Fotos da biblioteca",
    "Itinerary.modal.ImageUpload.upload": "Upload",
    "Itinerary.modal.ImageUpload.search_free_photos": "Pesquise fotos gratuitas de alta resolução",
    "Itinerary.modal.ImageUpload.photos_by": "Fotos de",
    "Itinerary.modal.ImageUpload.no_photos_found": "Nenhuma foto encontrada",
    "Itinerary.modal.ImageUpload.load_more": "Carregue mais",
    "Itinerary.modal.ImageUpload.tags": "Tags",
    "Itinerary.modal.ImageUpload.select_tags": "Selecionar tag (s)",
    "Itinerary.modal.ImageUpload.caption_location": "Legenda/Localização",
    "Itinerary.modal.ImageUpload.click_to_add": "Clique para adicionar",
    "Itinerary.modal.ImageUpload.select_tags_or_add": "Selecione tags ou adicione novas",
    "Itinerary.modal.ImageUpload.photo_already_added": "Foto já adicionada",
    "Itinerary.modal.ImageUpload.write_something_first": "Escreva algo primeiro …",
    "Itinerary.modal.ImageUpload.photo_added": "Foto adicionada",
    "Itinerary.modal.ImageUpload.unsplash": "Unsplash",
    "Itinerary.modal.ImageUpload.search_instead": "Pesquise fotos",
    "Itinerary.modal.ImageUpload.upload_instead": "Carregue as suas fotos",
    "Itinerary.modal.ImageUpload.pixabay": "Pixabay",
    "Itinerary.modal.BuilderAddItem.tags": "Tags",
    "Itinerary.modal.BuilderAddItem.select_tags": "Selecionar tag (s)",
    "Itinerary.modal.BuilderAddItem.type_to_search": "Escreva para pesquisar",
    "Itinerary.modal.BuilderAddItem.or_create_from_scratch": "Ou crie um novo a partir do zero",
    "Itinerary.modal.BuilderAddItem.generic_item": "Item genérico",
    "Itinerary.modal.BuilderAddItem.generic_item_description": "Um título, uma área de texto e uma lista de marcadores",
    "Itinerary.modal.BuilderAddItem.tour_cover": "Capa da viagem",
    "Itinerary.modal.BuilderAddItem.tour_cover_description": "O título da viagem, a descrição de uma linha e a imagem da capa. Deve ser adicionada à primeira secção/página",
    "Itinerary.modal.BuilderAddItem.tour_name": "Nome da viagem",
    "Itinerary.modal.BuilderAddItem.tour_name_description": "Um título, uma descrição de uma linha e o número de referência",
    "Itinerary.modal.BuilderAddItem.client_information": "Informação do subscritore",
    "Itinerary.modal.BuilderAddItem.client_information_description": "Número de pessoas, nomes e e-mails",
    "Itinerary.modal.BuilderAddItem.trip_highlights": "Destaques da Viagem",
    "Itinerary.modal.BuilderAddItem.trip_highlights_description": "Um título e uma lista de marcadores com os destaques da viagem",
    "Itinerary.modal.BuilderAddItem.trip_description": "Descrição da Viagem",
    "Itinerary.modal.BuilderAddItem.trip_description_description": "Uma área de título e texto",
    "Itinerary.modal.BuilderAddItem.trip_dates": "Datas da viagem",
    "Itinerary.modal.BuilderAddItem.trip_dates_description": "Um título e as datas da viagem",
    "Itinerary.modal.BuilderAddItem.organizer_details": "Detalhes do organizador",
    "Itinerary.modal.BuilderAddItem.organizer_details_description": "Os detalhes da criador e do gestor da viagem",
    "Itinerary.modal.BuilderAddItem.destination_info": "Informações sobre o destino",
    "Itinerary.modal.BuilderAddItem.destination_info_description": "Um título e uma área de texto",
    "Itinerary.modal.BuilderAddItem.know_before_you_go": "Saiba antes de ir",
    "Itinerary.modal.BuilderAddItem.know_before_you_go_description": "Duas listas de marcadores: uma com a lista de itens a levar e a outra com informações importantes a serem conhecidas antes da viagem",
    "Itinerary.modal.BuilderAddItem.description_of_day": "Descrição do dia",
    "Itinerary.modal.BuilderAddItem.description_of_day_description": "Um título e duas áreas de texto. Descreva o dia e adicione alguns detalhes históricos",
    "Itinerary.modal.BuilderAddItem.activity_included": "Atividades / Serviços (incluídos)",
    "Itinerary.modal.BuilderAddItem.activity_included_description": "Lista de atividades e serviços incluídos",
    "Itinerary.modal.BuilderAddItem.activity_optional": "Item genérico",
    "Itinerary.modal.BuilderAddItem.activity_optional_description": "Lista de atividades e serviços",
    "Itinerary.modal.BuilderAddItem.hotel": "Hotel",
    "Itinerary.modal.BuilderAddItem.hotel_description": "Um hotel / B & B / AirBnB / etc",
    "Itinerary.modal.BuilderAddItem.breakfast": "Pequeno Almoço",
    "Itinerary.modal.BuilderAddItem.breakfast_description": "Um local de pequeno almoço (restaurante / etc)",
    "Itinerary.modal.BuilderAddItem.place": "Local",
    "Itinerary.modal.BuilderAddItem.place_description": "Um local (restaurante / etc)",
    "Itinerary.modal.BuilderAddItem.lunch": "Almoço",
    "Itinerary.modal.BuilderAddItem.lunch_description": "Um local de almoço (restaurante / etc)",
    "Itinerary.modal.BuilderAddItem.dinner": "Jantar",
    "Itinerary.modal.BuilderAddItem.dinner_description": "Um local de jantar (restaurante / etc)",
    "Itinerary.modal.BuilderAddItem.flight": "Voo",
    "Itinerary.modal.BuilderAddItem.flight_description": "Detalhes do voo com local e horário de início / fim",
    "Itinerary.modal.BuilderAddItem.train": "Comboio",
    "Itinerary.modal.BuilderAddItem.train_description": "Detalhes da viagem de comboio com horários e locais de início / fim",
    "Itinerary.modal.BuilderAddItem.transfer": "Transfer",
    "Itinerary.modal.BuilderAddItem.transfer_description": "Detalhes de Transfer com locais e horários de início / fim",
    "Itinerary.modal.BuilderAddItem.carrent": "Carro de Aluguer",
    "Itinerary.modal.BuilderAddItem.carrent_description": "Detalhes de carro de aluguer com horários e locais de início / término",
    "Itinerary.modal.BuilderAddItem.price_included": "Preço (incluído)",
    "Itinerary.modal.BuilderAddItem.price_included_description": "Detalhes do preço da excursão (preço total por pessoa / grupo / suplemento individual)",
    "Itinerary.modal.BuilderAddItem.price_optional": "Preço (opcional)",
    "Itinerary.modal.BuilderAddItem.price_optional_description": "Detalhes do preço da excursão para as opções (preço total por pessoa / grupo / suplemento único)",
    "Itinerary.modal.BuilderAddItem.whatsincluded": "O que está incluído",
    "Itinerary.modal.BuilderAddItem.whatsincluded_description": "Um título, uma tabela de resumo de voos, refeições, hotéis, atividades e suas datas e outra tabela com as atividades opcionais e seus preços.",
    "Itinerary.modal.BuilderAddItem.nextstep": "Próximos passos",
    "Itinerary.modal.BuilderAddItem.nextstep_description": "O valor do depósito, quando pagar e contatos",
    "Itinerary.modal.BuilderAddItem.cancelation_policy": "Política de cancelamento",
    "Itinerary.modal.BuilderAddItem.cancelation_policy_description": "Um título, área de texto e uma tabela com as penalidades de cancelamento",
    "Itinerary.modal.BuilderAddItem.ratings": "Avaliações",
    "Itinerary.modal.BuilderAddItem.ratings_description": "Avalie por critérios de 1 a 5 estrelas",
    "Itinerary.modal.BuilderAddItem.carousel": "Galeria de imagens",
    "Itinerary.modal.BuilderAddItem.carousel_description": "Carrossel de imagens",
    "Itinerary.modal.BuilderAddItem.audio": "Arquivo de áudio",
    "Itinerary.modal.BuilderAddItem.audio_description": "Carregar arquivo de áudio",
    "Itinerary.modal.BuilderAddItem.video_file": "Arquivo de vídeo",
    "Itinerary.modal.BuilderAddItem.video_file_description": "Carregar arquivo de vídeo",
    "Itinerary.modal.BuilderAddItem.faq": "Perguntas frequentes (FAQ)",
    "Itinerary.modal.Step2CarRental.dates": "Datas",
    "Itinerary.modal.Step2CarRental.start_date": "Data de início",
    "Itinerary.modal.Step2CarRental.end_date": "Data final",
    "Itinerary.modal.Step2CarRental.please_select": "Por favor selecione as datas.",
    "Itinerary.modal.Step2CarRental.cancel": "Cancelar",
    "Itinerary.modal.Step2CarRental.add": "Adicionar",
    "Itinerary.modal.Step2Sleep.dates": "Datas",
    "Itinerary.modal.Step2Sleep.start_date": "Data de início",
    "Itinerary.modal.Step2Sleep.end_date": "Data final",
    "Itinerary.modal.Step2Sleep.please_select": "Por favor selecione as datas.",
    "Itinerary.modal.Step2Sleep.autocomplete_google": "Detalhes do preenchimento automático do Google",
    "Itinerary.modal.Step2Sleep.type_to_search": "Escreva para pesquisar",
    "Itinerary.modal.Step2Sleep.cancel": "Cancelar",
    "Itinerary.modal.Step2Sleep.add": "Adicionar",
    "Itinerary.modal.Step2Sleep.add_hotel": "Adicionar hotel",
    "Itinerary.modal.Step2Sleep.add_item": "Adicionar Item",
    "Itinerary.modal.ChangeDates.change_dates": "Alterar datas",
    "Itinerary.modal.ChangeDates.dates": "Datas",
    "Itinerary.modal.ChangeDates.start_date": "Data de início",
    "Itinerary.modal.ChangeDates.end_date": "Data final",
    "Itinerary.modal.ChangeDates.select_dates": "Por favor selecione as datas.",
    "Itinerary.modal.ChangeDates.cancel": "Cancelar",
    "Itinerary.modal.ChangeManager.title": "Altere o Gestor da Viagem",
    "Itinerary.modal.ChangeManager.manager": "Gestor",
    "Itinerary.modal.ChangeManager.save": "Guardar",
    "Itinerary.modal.ChangeManager.load_manager_list_failed": "Falha ao carregar a lista de gestores",
    "Itinerary.modal.ChangeManager.updated": "Gestor de viagem atualizado",
    "Itinerary.modal.CoverImageUpload.set_cover_photo": "Definir foto da capa",
    "Itinerary.modal.CoverImageUpload.close": "Fechar",
    "Itinerary.modal.ItemMap.location": "Localização",
    "Itinerary.modal.ItemMap.type_address_to_search_and_change": "Digite um endereço para pesquisar e alterar",
    "Itinerary.modal.ItemMap.click_drag_change_location": "Clique no mapa ou arraste o marcador para alterar a localização",
    "Itinerary.modal.ItemMap.close": "Fechar",
    "Itinerary.modal.ItemMap.cancel": "Cancelar",
    "Itinerary.modal.ItemMap.update_location": "Atualizar localização",
    "Itinerary.modal.ItinerarySendEmail.send_itinerary_by_email_pdf": "Enviar activo de viagem por email (PDF)",
    "Itinerary.modal.ItinerarySendEmail.clients": "Subscritores *",
    "Itinerary.modal.ItinerarySendEmail.clients_select": "Selecionar subscritore(s)",
    "Itinerary.modal.ItinerarySendEmail.text": "Texto *",
    "Itinerary.modal.ItinerarySendEmail.field_required": "Este campo é obrigatório",
    "Itinerary.modal.ItinerarySendEmail.send_copy_to_me": "Enviar uma cópia para mim",
    "Itinerary.modal.ItinerarySendEmail.send": "Enviar",
    "Itinerary.modal.ItinerarySendEmail.cancel": "Cancelar",
    "Itinerary.modal.ItinerarySendEmail.email_sent": "Email enviado",
    "Itinerary.modal.ItinerarySendEmail.failed_email_sent": "Falha no envio de e-mail",
    "Itinerary.modal.MealAddAlternative.add_meal_alternative": "Adicionar refeição alternativa",
    "Itinerary.modal.MealAddAlternative.add_from_library": "Adicionar da sua biblioteca",
    "Itinerary.modal.MealAddAlternative.tags": "Tags",
    "Itinerary.modal.MealAddAlternative.select_tag": "Selecionar tag (s)",
    "Itinerary.modal.MealAddAlternative.type_to_search": "Escreva para pesquisar",
    "Itinerary.modal.MealAddAlternative.create_from_scratch": "Ou crie um novo a partir do zero",
    "Itinerary.modal.PlacesAutocomplete.autocomplete_info": "Informações de preenchimento automático",
    "Itinerary.modal.PlacesAutocomplete.type_to_search": "Escreva para pesquisar",
    "Itinerary.modal.PlacesAutocomplete.cancel": "Cancelar",
    "Itinerary.modal.PlacesAutocomplete.update_info": "Atualizar informação",
    "Itinerary.modal.ReorderSectionItems.title": "Reordenar itens",
    "Itinerary.modal.ReorderSectionItems.reorder": "Arraste e solte os itens para reordená-los",
    "Itinerary.modal.ReorderSectionItems.close": "Fechar",
    "Itinerary.modal.SaveToLibrary.title": "Salvar item na biblioteca",
    "Itinerary.modal.SaveToLibrary.name": "Nome da viagem",
    "Itinerary.modal.SaveToLibrary.name_description": "Esse é o nome que o item terá na biblioteca para que você possa encontrá-lo mais tarde.",
    "Itinerary.modal.SaveToLibrary.name_required": "Este campo é obrigatório.",
    "Itinerary.modal.SaveToLibrary.name_size": "O tamanho máximo é 255.",
    "Itinerary.modal.SaveToLibrary.tags": "Tags",
    "Itinerary.modal.SaveToLibrary.tags_select": "Selecione tags ou adicione novas",
    "Itinerary.modal.SaveToLibrary.cancel": "Cancelar",
    "Itinerary.modal.SaveToLibrary.save": "Salvar na biblioteca",
    "Itinerary.modal.SaveToLibrary.update": "Actualizar na biblioteca",
    "Itinerary.modal.SaveToLibrary.saved": "Item salvo na biblioteca",
    "Itinerary.modal.SaveToLibrary.alreadyexists": "Já existe na Biblioteca, vai ser guardado por cima. Selecione a caixa seguinte para criar antes um item novo:",
    "Itinerary.modal.SleepAddAlternative.title": "Adicionar alternativa ao hotel",
    "Itinerary.modal.SleepAddAlternative.add_from_library": "Adicionar da sua biblioteca",
    "Itinerary.modal.SleepAddAlternative.tags": "Tags",
    "Itinerary.modal.SleepAddAlternative.select_tags": "Selecionar tag (s)",
    "Itinerary.modal.SleepAddAlternative.type_to_search": "Escreva para pesquisar",
    "Itinerary.modal.SleepAddAlternative.create_from_scratch": "Ou crie um novo a partir do zero",
    "Itinerary.modal.TransportationMap.title": "Localização",
    "Itinerary.modal.TransportationMap.type_to_search": "Digite um endereço para pesquisar e alterar",
    "Itinerary.modal.TransportationMap.click_on_map_or_drag": "Clique no mapa ou arraste o marcador para alterar a localização",
    "Itinerary.modal.TransportationMap.cancel": "cCancelar",
    "Itinerary.modal.TransportationMap.update_location": "Atualizar localização",
    "Itinerary.modal.UploadDocument.title": "Carregar ficheiro",
    "Itinerary.modal.UploadDocument.close": "Fechar",
    "Itinerary.TripActivity.optional_title": "Subtítulo",
    "Itinerary.TripActivity.included_title": "Título da atividade incluída",
    "Itinerary.TripActivity.optional_description": "Descrição",
    "Itinerary.TripActivity.included_description": "Descrição da atividade incluída",
    "Itinerary.TripActivity.tooltip.move_to_another_section": "Mover para outra secção",
    "Itinerary.TripActivity.tooltip.show_on_map": "Mostrar no mapa",
    "Itinerary.TripActivity.tooltip.set_on_map": "Definir endereço / localização no mapa",
    "Itinerary.TripActivity.tooltip.address": "Endereço",
    "Itinerary.TripActivity.tooltip.duration": "Duração",
    "Itinerary.TripActivity.tooltip.language": "Língua",
    "Itinerary.TripActivity.tooltip.price": "Preço",
    "Itinerary.TripActivity.tooltip.price_type": "Tipo de preço",
    "Itinerary.TripActivity.tooltip.local_contact": "Contato local",
    "Itinerary.TripActivity.tooltip.name": "Nome da viagem",
    "Itinerary.TripActivity.tooltip.phone": "Telefone",
    "Itinerary.TripActivity.tooltip.email": "Email",
    "Itinerary.TripActivity.tooltip.start_time": "Hora de início",
    "Itinerary.TripActivity.tooltip.caption_here": "Escreva legenda da imagem aqui",
    "Itinerary.TripActivity.tooltip.remove_activity": "Remover atividade",
    "Itinerary.TripActivity.button.add_images": "Adicione imagens",
    "Itinerary.TripActivity.button.add_activity": "Adicionar atividade",
    "Itinerary.TripActivity.price_types.free": "Grátis",
    "Itinerary.TripActivity.price_types.per_person": "Por Pessoa (p.p.)",
    "Itinerary.TripActivity.price_types.per_group": "Por Grupo (p.g.)",
    "Itinerary.TripActivity.messages.delete_item_title": "Tem a certeza?",
    "Itinerary.TripActivity.messages.delete_item_message": "Remover esta atividade?",
    "Itinerary.TripActivity.messages.delete_item_button": "Remover",
    "Itinerary.TripCancelationPolicy.tooltip.move_to_another_section": "Mover para outra secção",
    "Itinerary.TripCancelationPolicy.tooltip.description": "Descrição",
    "Itinerary.TripCancelationPolicy.tooltip.drag_to_reorder": "Arraste para reordenar",
    "Itinerary.TripCancelationPolicy.tooltip.days_60_30": "60 a 30 dias",
    "Itinerary.TripCarRental.tooltip.change_rental_dates": "Alterar datas de aluguer",
    "Itinerary.TripCarRental.tooltip.company": "Companhia",
    "Itinerary.TripCarRental.tooltip.pickup_location": "Local de Pickup",
    "Itinerary.TripCarRental.tooltip.pickup_time": "Hora de Pickup",
    "Itinerary.TripCarRental.tooltip.phone": "Telefone",
    "Itinerary.TripCarRental.tooltip.dropoff_location": "Local de entrega",
    "Itinerary.TripCarRental.tooltip.dropoff_time": "Hora de entrega",
    "Itinerary.TripCarRental.tooltip.reference_number": "Número de referência",
    "Itinerary.TripCarRental.tooltip.car_model": "Modelo do carro",
    "Itinerary.TripCarRental.tooltip.details": "Detalhes",
    "Itinerary.TripCarRental.tooltip.set_addres_on_map": "Definir endereço / localização no mapa",
    "Itinerary.TripClientInfo.tooltip.client_details": "Detalhes do subscritore",
    "Itinerary.TripClientInfo.tooltip.guests": "Convidados",
    "Itinerary.TripClientInfo.tooltip.number_guests": "Número de convidados",
    "Itinerary.TripClientInfo.tooltip.guest_name": "Nome do convidado",
    "Itinerary.TripClientInfo.tooltip.guest_email": "Email do convidado",
    "Itinerary.TripCompanyInfo.tooltip.organizer_details": "Detalhes do organizador",
    "Itinerary.TripCompanyInfo.tooltip.trip_manager": "Gestor da viagem",
    "Itinerary.TripCompanyInfo.tooltip.local_trip_manager": "Gestor de Viagem Local",
    "Itinerary.TripCompanyInfo.tooltip.name": "Nome da viagem",
    "Itinerary.TripCompanyInfo.tooltip.email": "Email",
    "Itinerary.TripCompanyInfo.tooltip.phone": "Telefone",
    "Itinerary.TripCompanyInfo.tooltip.emergency_contacts": "Contatos de emergência",
    "Itinerary.TripCompanyInfo.tooltip.description": "Descrição",
    "Itinerary.TripCompanyInfo.messages.manager_profile_pic": "O Gestor precisa definir sua foto no perfil (clicando no nome no canto superior direito)",
    "Itinerary.TripCompanyInfo.messages.failed_load_data": "Falha ao carregar as informações da empresa",
    "Itinerary.TripCover.tooltip.set_cover": "Definir foto da capa",
    "Itinerary.TripCover.tooltip.tour_name": "Nome / título da viagem",
    "Itinerary.TripCover.tooltip.places_to_visit": "Lugares / países a visitar",
    "Itinerary.TripDates.tooltip.trip_dates": "Datas da viagem",
    "Itinerary.TripDescription.tooltip.type_something_here": "Escreva algo aqui …",
    "Itinerary.TripDestinationInfo.tooltip.places_to_visit": "Locais de visita",
    "Itinerary.TripDestinationInfo.tooltip.type_something_here": "Escreva algo aqui …",
    "Itinerary.TripDestinationInfo.tooltip.emergency_information": "Informação de emergência",
    "Itinerary.TripDestinationInfo.tooltip.name": "Nome / título da viagem",
    "Itinerary.TripDestinationInfo.tooltip.phone": "Telefone",
    "Itinerary.TripDestinationInfo.tooltip.description": "Descrição",
    "Itinerary.TripGenericItem.tooltip.title": "Título",
    "Itinerary.TripGenericItem.tooltip.details_placeholder": "Escreva algo aqui …",
    "Itinerary.TripGenericItem.tooltip.bullet_placeholder": "Escreva algo aqui …",
    "Itinerary.TripHighlights.tooltip.trip_highlights": "Destaques da viagem",
    "Itinerary.TripKnowBeforeGo.tooltip.pack_list": "Lista de Itens a levar",
    "Itinerary.TripKnowBeforeGo.tooltip.pack_item": "Item a levar",
    "Itinerary.TripKnowBeforeGo.tooltip.important_to_know": "Importante saber antes de ir",
    "Itinerary.TripKnowBeforeGo.tooltip.describe_item_here": "Descreva o item aqui",
    "Itinerary.TripMeal.tooltip.title": "Título",
    "Itinerary.TripMeal.tooltip.name": "Nome",
    "Itinerary.TripMeal.tooltip.google_rating": "Classificação do Google",
    "Itinerary.TripMeal.tooltip.description": "Descrição",
    "Itinerary.TripMeal.tooltip.address": "Endereço",
    "Itinerary.TripMeal.tooltip.phone": "Telefone",
    "Itinerary.TripMeal.tooltip.open_web": "Abrir site",
    "Itinerary.TripMeal.tooltip.website": "Website",
    "Itinerary.TripMeal.tooltip.alternatives": "Alternativas",
    "Itinerary.TripMeal.tooltip.all_alternatives": "Adicionar alternativas",
    "Itinerary.TripMeal.alternatives.set_as_main": "Defina este como opção principal",
    "Itinerary.TripMeal.alternatives.title": "Título",
    "Itinerary.TripMeal.alternatives.name": "nome",
    "Itinerary.TripNextSteps.tooltip.deposit": "Depósito",
    "Itinerary.TripNextSteps.tooltip.when_to_be_paid": "Quando deve ser pago",
    "Itinerary.TripNextSteps.tooltip.email": "Email",
    "Itinerary.TripNextSteps.tooltip.phone": "Telefone",
    "Itinerary.TripPoi.tooltip.description_of_day": "Descrição do dia",
    "Itinerary.TripPoi.tooltip.description_of_place": "Descrição do local",
    "Itinerary.TripPlace.type.accommodation": "Alojamento",
    "Itinerary.TripPlace.type.restaurant": "Restaurante",
    "Itinerary.TripPlace.type.cafe": "Cafe",
    "Itinerary.TripPlace.type.drink": "Bebida",
    "Itinerary.TripPlace.type.sight": "Local, museu, atração",
    "Itinerary.TripPlace.type.meeting_point": "Ponto de encontro ou ponto de entrada",
    "Itinerary.TripPlace.type.starting_point": "Ponto de partida",
    "Itinerary.TripPlace.type.finish_point": "Ponto final",
    "Itinerary.TripPlace.type.stop_along_route": "Pare ao longo da rota",
    "Itinerary.TripPlace.type.other": "Outros",
    "Itinerary.TripPrice.tooltip.price_per_person": "Preço por pessoa",
    "Itinerary.TripPrice.tooltip.price_per_group": "Preço por grupo",
    "Itinerary.TripPrice.tooltip.per_person": "por pessoa",
    "Itinerary.TripPrice.tooltip.per_group": "por grupo",
    "Itinerary.TripPrice.tooltip.adult": "Adulto",
    "Itinerary.TripPrice.tooltip.child": "Criança",
    "Itinerary.TripPrice.tooltip.single_price": "Preço único",
    "Itinerary.TripPrice.tooltip.single_supplement": "suplemento individual",
    "Itinerary.TripPrice.tooltip.drag_to_reorder": "Arraste para reordenar",
    "Itinerary.TripPrice.tooltip.num_persons_placeholder": "Até x pessoas",
    "Itinerary.TripPrice.tooltip.price_per_person_placeholder": "Preço",
    "Itinerary.TripSleep.tooltip.autocomplete_hotel": "Preencher automaticamente as informações do hotel",
    "Itinerary.TripSleep.tooltip.change_dates": "Alterar datas do hotel",
    "Itinerary.TripSleep.tooltip.hotel_name": "Nome do hotel",
    "Itinerary.TripSleep.tooltip.type": "Tipo",
    "Itinerary.TripSleep.tooltip.select_hotel_type": "Selecione o tipo de hotel ou escreva novo tipo",
    "Itinerary.TripSleep.tooltip.add_hotel_type": "Adicionar novo tipo de hotel",
    "Itinerary.TripSleep.tooltip.description": "Descrição",
    "Itinerary.TripSleep.tooltip.address": "Endereço",
    "Itinerary.TripSleep.tooltip.phone": "Telefone",
    "Itinerary.TripSleep.tooltip.check_in": "Check-in",
    "Itinerary.TripSleep.tooltip.check_out": "Check-out",
    "Itinerary.TripSleep.tooltip.check_in_time": "Hora de Check-in",
    "Itinerary.TripSleep.tooltip.check_out_time": "Hora de Check-out",
    "Itinerary.TripSleep.tooltip.check_out_at": "Check-out às",
    "Itinerary.TripSleep.tooltip.meal_plan": "Plano de refeição",
    "Itinerary.TripSleep.tooltip.select_meal_plan": "Selecione o tipo de plano de refeição ou escreva novo tipo",
    "Itinerary.TripSleep.tooltip.add_meal_plan": "Adicionar novo plano de refeições",
    "Itinerary.TripSleep.tooltip.rooms": "Quartos",
    "Itinerary.TripSleep.tooltip.room": "Quarto",
    "Itinerary.TripSleep.tooltip.number_rooms": "Número de quartos",
    "Itinerary.TripSleep.tooltip.change_number_rooms": "Alterar número de quartos",
    "Itinerary.TripSleep.tooltip.select_room_type": "Selecione o tipo de quarto ou escreva novo tipo",
    "Itinerary.TripSleep.tooltip.add_room_type": "Adicionar novo tipo de quarto",
    "Itinerary.TripSleep.tooltip.alternatives": "Alternativas",
    "Itinerary.TripSleep.tooltip.add_alternatives": "Adicionar alternativas",
    "Itinerary.TripSleep.tooltip.set_main_hotel": "Definir este como Hotel principal",
    "Itinerary.TripSleep.tooltip.night": "Noite",
    "Itinerary.TripSleep.tooltip.alternative_show_on_pdf": "Mostrar em PDF",
    "Itinerary.TripSoundcloud.placeholder": "Cole o código da guia “Share -> Embed”. Como: <iframe src...>`",
    "Itinerary.TripTourName.tooltip.tour_name": "Nome do passeio",
    "Itinerary.TripTourName.tooltip.short_description": "Breve descrição do passeio",
    "Itinerary.TripTourName.tooltip.reference": "Referência",
    "Itinerary.TripTransportation.tooltip.start_location": "Localização inicial",
    "Itinerary.TripTransportation.tooltip.start_time": "Hora de início",
    "Itinerary.TripTransportation.tooltip.phone": "Telefone",
    "Itinerary.TripTransportation.tooltip.duration": "Duração",
    "Itinerary.TripTransportation.tooltip.end_location": "Localização final",
    "Itinerary.TripTransportation.tooltip.end_time": "Hora final",
    "Itinerary.TripTransportation.tooltip.distance": "Distância",
    "Itinerary.TripTransportation.tooltip.distance_units": "Unidades de distância",
    "Itinerary.TripTransportation.tooltip.set_address_on_map": "Definir endereço / localização no mapa",
    "Itinerary.TripWhatsIncluded.table.date": "Data",
    "Itinerary.TripWhatsIncluded.table.time": "Hora",
    "Itinerary.TripWhatsIncluded.table.activity": "Atividade",
    "Itinerary.TripWhatsIncluded.table.airline": "Companhia aérea",
    "Itinerary.TripWhatsIncluded.table.duration": "Duração",
    "Itinerary.TripWhatsIncluded.table.type": "Tipo",
    "Itinerary.TripWhatsIncluded.table.departure_location": "Partida / Localização",
    "Itinerary.TripWhatsIncluded.table.arrival": "Chegada",
    "Itinerary.TripWhatsIncluded.table.price": "Preço",
    "Itinerary.TripWhatsIncluded.tooltip": {},
    "Itinerary.TripCarousel.title": "Título",
    "Itinerary.TripCarousel.subtitle": "Subtítulo",
    "Itinerary.TripCarousel.image": "Imagem",
    "Itinerary.TripCarousel.tooltip.drag_to_reorder": "Arraste para reordenar",
    "Itinerary.TripAudio.upload": "Carregar",
    "Itinerary.TripAudio.uploadSuccess": "Carregar com sucesso",
    "Itinerary.TripAudio.title": "Título",
    "Itinerary.TripVideoFile.upload": "Carregar",
    "Itinerary.TripVideoFile.uploadSuccess": "Carregar com sucesso",
    "Itinerary.TripVideoFile.title": "Título",
    "Itinerary.builder.custom_tab_name": "Etiqueta de dia personalizada",
    "v2.Itinerary.Flight.add_another_flight": "Adicionar outro voo",
    "v2.Itinerary.Flight.connection_time": "Hora da conexão",
    "v2.Itinerary.Flight.airline": "Companhia aérea",
    "v2.Itinerary.Flight.start_typing_airline": "Comece a digitar companhia aérea",
    "v2.Itinerary.Flight.flight_number": "Número do voo",
    "v2.Itinerary.Flight.class_desc": "Classe",
    "v2.Itinerary.Flight.economy": "Economica",
    "v2.Itinerary.Flight.duration": "Duração",
    "v2.Itinerary.Flight.departure": "PARTIDA",
    "v2.Itinerary.Flight.type_airport_to_search": "Digite aeroporto para pesquisar",
    "v2.Itinerary.Flight.start_typing_airport": "Comece a digitar o aeroporto",
    "v2.Itinerary.Flight.time": "Hora",
    "v2.Itinerary.Flight.arrival": "CHEGADA",
    "v2.Itinerary.defaults.CLIENT_INFO_ITEM_title": "Detalhes do subscritore",
    "v2.Itinerary.defaults.ITEM_TYPE_TRIP_HIGHLIGHTS_title": "Você vai amar",
    "v2.Itinerary.defaults.ITEM_TYPE_TRIP_DESCRIPTION_title": "Descrição da Viagem",
    "v2.Itinerary.defaults.ITEM_TYPE_TRIP_DATES_title": "Datas da viagem",
    "v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_title": "Detalhes do organizador",
    "v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_trip_manager": "Gestor de Viagem",
    "v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_local_trip_manager": "Gestor da Viagem Local",
    "v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_emergency_contacts": "Contatos de emergência",
    "v2.Itinerary.defaults.ITEM_TYPE_DESTINATION_INFO_places_of_visit": "Locais de visita",
    "v2.Itinerary.defaults.ITEM_TYPE_DESTINATION_INFO_emergency_info": "Informação de emergência",
    "v2.Itinerary.defaults.ITEM_TYPE_KNOW_BEFORE_GO_packing_list_title": "Lista de Itens a levar",
    "v2.Itinerary.defaults.ITEM_TYPE_KNOW_BEFORE_GO_know_before_go_title": "Importante saber antes de ir",
    "v2.Itinerary.defaults.ITEM_TYPE_POI_title": "Descrição do dia",
    "v2.Itinerary.defaults.ITEM_TYPE_ACTIVITY_title": "Atividade / Serviço",
    "v2.Itinerary.defaults.ITEM_TYPE_ACTIVITY_title_included": "(Incluído)",
    "v2.Itinerary.defaults.ITEM_TYPE_ACTIVITY_title_optional": "(Opcional)",
    "v2.Itinerary.defaults.ITEM_TYPE_ACTIVITY_generic_title": "Artigo genérico",
    "v2.Itinerary.defaults.ITEM_TYPE_FLIGHT_title": "Vôo",
    "v2.Itinerary.defaults.ITEM_TYPE_CAR_RENTAL_title": "Aluguer de carro",
    "v2.Itinerary.defaults.ITEM_TYPE_MEAL_breakfast": "Pequeno Almoço",
    "v2.Itinerary.defaults.ITEM_TYPE_MEAL_lunch": "Almoço",
    "v2.Itinerary.defaults.ITEM_TYPE_MEAL_dinner": "Jantar",
    "v2.Itinerary.defaults.ITEM_TYPE_PRICE_title": "Preço da viagem",
    "v2.Itinerary.defaults.ITEM_TYPE_PRICE_title_included": "Incluído",
    "v2.Itinerary.defaults.ITEM_TYPE_PRICE_title_optional": "Opcional",
    "v2.Itinerary.defaults.ITEM_TYPE_PRICE_num_persons_title": "Pessoas",
    "v2.Itinerary.defaults.ITEM_TYPE_PRICE_price_per_person_title": "Preço por pessoa",
    "v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_title": "O que está incluído",
    "v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_flight_title": "Vôos",
    "v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_hotel_others_title": "Hotel / Outros",
    "v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_not_included_title": "O que não está incluído",
    "v2.Itinerary.defaults.ITEM_TYPE_NEXT_STEPS_title": "Próximos passos - Reservar esta viagem",
    "v2.Itinerary.defaults.ITEM_TYPE_CANCELATION_POLICY_title": "Política de cancelamento",
    "v2.Itinerary.defaults.ITEM_TYPE_CANCELATION_POLICY_days_title": "Dias antes da partida da viagem",
    "v2.Itinerary.defaults.ITEM_TYPE_CANCELATION_POLICY_penalty_title": "Pena de cancelamento do preço total da excursão",
    "v2.Itinerary.defaults.SECTION_TYPE_COVER_title": "Capa",
    "v2.Itinerary.defaults.SECTION_TYPE_OTHER_tour_intro_title": "Introdução da Viagem",
    "v2.Itinerary.defaults.SECTION_TYPE_OTHER_prices_and_conditions_title": "Preços e Condições",
    "v2.Itinerary.defaults.ITEM_NAME_GENERIC": "Item genérico",
    "v2.Itinerary.defaults.ITEM_NAME_COVER": "Capa da Viagem",
    "v2.Itinerary.defaults.ITEM_NAME_TOUR_NAME": "Nome da Viagem",
    "v2.Itinerary.defaults.ITEM_NAME_CLIENT_INFO": "Detalhes do subscritore",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_HIGHLIGHTS": "Você vai adorar (destaques da viagem)",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_DESCRIPTION": "Descrição da Viagem",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_DATES": "Datas da viagem",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_SOUNDCLOUD": "Faixa de áudio (SoundCloud)",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_VIDEO": "Vídeo do youtube",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_FAQ": "Perguntas frequentes (FAQ)",
    "v2.Itinerary.defaults.ITEM_NAME_COMPANY_INFO": "Detalhes do organizador",
    "v2.Itinerary.defaults.ITEM_NAME_DESTINATION_INFO": "Locais de visita",
    "v2.Itinerary.defaults.ITEM_NAME_KNOW_BEFORE_GO": "Saiba antes de ir",
    "v2.Itinerary.defaults.ITEM_NAME_POI": "Descrição do dia",
    "v2.Itinerary.defaults.ITEM_NAME_ACTIVITY": "Atividade / Serviço",
    "v2.Itinerary.defaults.ITEM_NAME_TRANSPORTATION": "Transporte",
    "v2.Itinerary.defaults.ITEM_NAME_FLIGHT": "Vôo",
    "v2.Itinerary.defaults.ITEM_NAME_CAR_RENTAL": "Aluguer de Carro",
    "v2.Itinerary.defaults.ITEM_NAME_SLEEP": "Hotel",
    "v2.Itinerary.defaults.ITEM_NAME_PLACE": "Local",
    "v2.Itinerary.defaults.ITEM_NAME_PRICE": "Preço da Viagem",
    "v2.Itinerary.defaults.ITEM_NAME_PRICE_included": "incluído",
    "v2.Itinerary.defaults.ITEM_NAME_PRICE_optional": "opcional",
    "v2.Itinerary.defaults.ITEM_NAME_WHATS_INCLUDED": "O que está incluído",
    "v2.Itinerary.defaults.ITEM_NAME_NEXT_STEPS": "Próximos passos - Reservar esta viagem",
    "v2.Itinerary.defaults.ITEM_NAME_CANCELATION_POLICY": "Política de cancelamento",
    "v2.Itinerary.defaults.ITEM_NAME_CAROUSEL": "Galeria de imagens",
    "v2.Itinerary.defaults.ITEM_NAME_AUDIO": "Arquivo de áudio",
    "v2.Itinerary.defaults.ITEM_NAME_VIDEO_FILE": "Arquivo de vídeo",
    "v2.Itinerary.defaults.DAY": "Dia",
    "mainmenu.agency": "Criador",
    "mainmenu.configuration": "Configuração",
    "mainmenu.configuration_mobile": "Aplicação móvel",
    "mainmenu.users": "Utilizadores",
    "mainmenu.billing": "Faturação",
    "mainmenu.clients": "Subscritores",
    "mainmenu.itineraries": "Activos de viagem",
    "mainmenu.templates": "Templates",
    "mainmenu.library": "Biblioteca",
    "onboarding.welcome": "Bem-vindo ao Morego!",
    "onboarding.subtitle": "Estamos muito felizes que tenha decidido juntar-se ao Morego e estamos aqui para ajudá-lo a fazer as coisas acontecerem!",
    "onboarding.config_travel_agency": "Configurar",
    "onboarding.config_profile": "Configure seus contactos e faça upload da imagem do perfil",
    "onboarding.create_users": "Crie mais utilizadores",
    "onboarding.config_mobile_app": "Configure a sua aplicação móvel e solicite a sua criação.",
    "onboarding.create_a_client": "Crie um novo subscritore",
    "onboarding.create_itinerary": "Crie o seu primeiro activo de viagem",
    "dashboard.stats.total_for_this_month": "Total para este mês",
    "dashboard.stats.last_month": "Mês passado",
    "dashboard.stats.itineraries_created": "Activos de viagem criados",
    "dashboard.stats.itineraries_sold": "Activos de viagem vendidos",
    "dashboard.stats.new_clients": "Novos Subscritores",
    "dashboard.stats.library_growth": "Crescimento da biblioteca",
    "dashboard.listings.last_modified_its": "Últimos Activos de viagem modificados",
    "dashboard.listings.last_modified_its_desc": "Os últimos Activos de viagem modificados pela Creador",
    "dashboard.listings.about_to_start_its": "Activos de viagem prestes a começar",
    "dashboard.listings.about_to_start_its_desc": "Activos de viagem que começam no próximo mês",
}
