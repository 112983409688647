<template>
    <div>
        <hr v-if="index > 0"/>
        <div class="card" v-if="index > 0">
            <div class="card-header">
                <div class="table-cell">
                    {{$t("v2.Itinerary.Flight.connection_time")}}:
                </div>
                <div class="table-cell pl-2">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           v-tooltip='$t("Itinerary.general.click_to_edit")'
                           type="text"
                           placeholder="2h 15m"
                           v-model="flight.connectionTime"
                           @blur="emitAutosave"
                    />
                </div>
            </div>
        </div>
        <hr v-if="index > 0"/>
        <div class="row">
            <div class="col-12">
                <div class="table-cell">
                    {{$t("v2.Itinerary.Flight.airline")}}:
                </div>
                <div class="airline-name table-cell pl-2" v-tooltip="'Airline'">
                    <el-select :disabled="!editable"
                               v-model="flight.airline.icao"
                               filterable
                               default-first-option
                               remote
                               :remote-method="remoteSearchAirlines"
                               :loading="loadingAirlines"
                               :placeholder='$t("v2.Itinerary.Flight.start_typing_airline")'
                               style="color:black;font-size: 1rem;"
                               no-data-text="No airlines found"
                               @change="updateAirlineData"
                    ><!-- TODO lang file -->
                        <el-option v-for="airline in airlineList"
                                   :key="airline.icao"
                                   :label="airline.name"
                                   :value="airline.icao"
                        >
                        </el-option>
                    </el-select>
                    <!--<multiselect
                            v-model="item.airline"
                            :options="airlineList"
                            placeholder="Select airline"
                            :multiple="false"
                            :close-on-select="true"
                            track-by="icao"
                            label="name"
                    ></multiselect>-->

                </div>
            </div>
            <div class="col-12">
                <div class="table-cell">
                    {{$t("v2.Itinerary.Flight.flight_number")}}:
                </div>
                <div class="table-cell pl-2">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           type="text"
                           :placeholder='$t("v2.Itinerary.Flight.flight_number")'
                           v-model="flight.flightNumber"
                           @blur="emitAutosave"
                    />
                </div>
            </div>
            <div class="col-12">
                <div class="table-cell">
                    {{$t("v2.Itinerary.Flight.class_desc")}}:
                </div>
                <div class="table-cell pl-2">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           type="text"
                           :placeholder='$t("v2.Itinerary.Flight.economy")'
                           v-model="flight.flightClass"
                           @blur="emitAutosave"
                    />
                </div>
            </div>
            <div class="col-12 mb-4">
                <div class="table-cell">
                    {{$t("v2.Itinerary.Flight.duration")}}:
                </div>
                <div class="table-cell pl-2">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           type="text"
                           placeholder="1h 30m"
                           v-model="flight.duration"
                           @blur="emitAutosave"
                    />
                </div>
            </div>
            <div class="col-6">
                <h5>{{$t("v2.Itinerary.Flight.departure")}}</h5>
                <hr/>
                <div class="city-name">
                    {{flight.startLocation.city ? flight.startLocation.city : "&nbsp;"}}
                </div>
                <!--<multiselect
                        v-model="flight.startLocation"
                        :options="airportList"
                        placeholder="Select airline"
                        :multiple="false"
                        :close-on-select="true"
                        track-by="icao"
                        label="iata"
                ></multiselect>-->
                <el-select :disabled="!editable"
                           v-model="flight.startLocation.icao"
                           v-tooltip='$t("v2.Itinerary.Flight.type_airport_to_search")'
                           filterable
                           remote
                           :remote-method="remoteSearchStartAirports"
                           :loading="loadingStartAirports"
                           default-first-option
                           :placeholder='$t("v2.Itinerary.Flight.start_typing_airport")'
                           no-data-text="No airports found"
                           style="width: 100%;color:black;font-size: 1rem;"
                           @change="updateStartAirportData"
                ><!-- TODO lang file -->
                    <el-option v-for="airport in startAirportList"
                               :key="airport.icao"
                               :label="getAirportLabel(airport)"
                               :value="airport.icao"
                    >
                    </el-option>
                </el-select>
                <!--Vnukovo International Airport (VKO)-->
                <div>
                    <div class="table-cell">
                        {{$t("v2.Itinerary.Flight.time")}}:
                    </div>
                    <div class="table-cell pl-2">
                        <input :readonly="!editable"
                               class="mor-editable-field"
                               style="font-weight: bold;"
                               type="text"
                               placeholder="08:00"
                               v-model="flight.startTime"
                               @blur="emitAutosave"
                        />
                    </div>
                </div>
            </div>
            <div class="col-6">
                <h5>{{$t("v2.Itinerary.Flight.arrival")}}</h5>
                <hr/>
                <div class="city-name">
                    {{flight.endLocation.city ? flight.endLocation.city : "&nbsp;"}}
                </div>
                <!--<multiselect
                        v-model="flight.startLocation"
                        :options="airportList"
                        placeholder="Select airline"
                        :multiple="false"
                        :close-on-select="true"
                        track-by="icao"
                        label="iata"
                ></multiselect>-->
                <el-select :disabled="!editable"
                           v-model="flight.endLocation.icao"
                           v-tooltip='$t("v2.Itinerary.Flight.type_airport_to_search")'
                           filterable
                           remote
                           :remote-method="remoteSearchEndAirports"
                           :loading="loadingEndAirports"
                           default-first-option
                           :placeholder='$t("v2.Itinerary.Flight.start_typing_airport")'
                           no-data-text="No airports found"
                           style="width: 100%;color:black;font-size: 1rem;"
                           @change="updateEndAirportData"
                ><!-- TODO lang file -->
                    <el-option v-for="airport in endAirportList"
                               :key="airport.icao"
                               :label="getAirportLabel(airport)"
                               :value="airport.icao"
                    >
                    </el-option>
                </el-select>
                <div>
                    <div class="table-cell">
                        {{$t("v2.Itinerary.Flight.time")}}:
                    </div>
                    <div class="table-cell pl-2" >
                        <input :readonly="!editable"
                               class="mor-editable-field"
                               style="font-weight: bold;"
                               type="text"
                               placeholder="08:00"
                               v-model="flight.endTime"
                               @blur="emitAutosave"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="editable && index > 0">
            <div class="col-12 text-right">
                <button style="margin-top: 10px;margin-right: 5px;"
                        class="btn btn-danger btn-sm"
                        @click="$emit('delete-flight', index)"
                        v-tooltip="'Remove this flight'"
                >
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>
        <transportation-map-modal
                :show.sync="showMapModal"
                :location.sync="mapModalLocation"
        >
        </transportation-map-modal>
    </div>
</template>

<script>

    import {autosaveMixin, itemCommonPropsMixin } from "./item-mixins";
    import TransportationMapModal from "../modal/TransportationMapModal";
    import {handleAjaxError} from "../../../utils";

    export default {
        components: {
            TransportationMapModal,
        },
        mixins: [
            autosaveMixin,
            itemCommonPropsMixin,
        ],
        props: {
            flight: {
                type: Object,
                required: true,
            },
            index: {
                type: Number,
                required: true,
            }
        },
        data() {
            return {
                showMapModal: false,
                mapModalLocation: null,

                loadingAirlines: false,
                airlineList: [],

                loadingStartAirports: false,
                startAirportList: [],

                loadingEndAirports: false,
                endAirportList: [],
            }
        },
        mounted() {
            if (this.flight.airline.icao) {
                this.remoteSearchAirlines(this.flight.airline.iata);
            }
            if (this.flight.startLocation.icao) {
                this.remoteSearchStartAirports(this.flight.startLocation.iata);
            }
            if (this.flight.endLocation.icao) {
                this.remoteSearchEndAirports(this.flight.endLocation.iata);
            }
        },
        computed: {
        },
        methods: {
            // TODO calcular duracao pelo start time e end time, usar timezone dos aeroports para fazer diferenca
            getAirportLabel(airport) {
                return airport.name + " (" + (airport.iata || airport.icao )+ ") - " + airport.city + ", " + airport.countryName;
            },
            openMapModal(itemLocation) {
                this.mapModalLocation = itemLocation;
                this.showMapModal = true;
            },
            mapBtnTooltip(location) {
                if (location.lat && location.lng && location.address) {
                    return location.address + " ("+  this.$t("Itinerary.general.show_on_map") +")";
                } else {
                    return this.$t("Itinerary.TripTransportation.tooltip.set_address_on_map");
                }
            },
            remoteSearchStartAirports(query) {
                if (query !== "") {
                    this.loadingStartAirports = true;
                    const self = this;
                    const url = jsRoutes.controllers.ItineraryController.airportListSelect(query).url;
                    this.$http.get(url)
                        .then(function (response) {
                            self.loadingStartAirports = false;
                            self.startAirportList = response.data;
                        })
                        .catch(function (error) {
                            self.loadingStartAirports = false;
                            // handle error
                            handleAjaxError(error);
                        });
                }
            },
            remoteSearchEndAirports(query) {
                if (query !== "") {
                    this.loadingEndAirports = true;
                    const self = this;
                    const url = jsRoutes.controllers.ItineraryController.airportListSelect(query).url;
                    this.$http.get(url)
                        .then(function (response) {
                            self.loadingEndAirports = false;
                            self.endAirportList = response.data;
                        })
                        .catch(function (error) {
                            self.loadingEndAirports = false;
                            // handle error
                            handleAjaxError(error);
                        });
                }
            },
            updateStartAirportData() {
                const self = this;
                if (this.flight.startLocation.icao) {
                    const airport = this.startAirportList.find(airport => {
                        return airport.icao === self.flight.startLocation.icao;
                    });
                    if (airport) {
                        this.flight.startLocation.airportName = airport.name;
                        this.flight.startLocation.city = airport.city;
                        this.flight.startLocation.iata = airport.iata;
                        this.flight.startLocation.icao = airport.icao;
                        //flight.startLocation.address = "" + airport.street1 + airport.postalCode + ;
                        this.flight.startLocation.lat = airport.lat;
                        this.flight.startLocation.lng = airport.lng;
                        this.flight.startLocation.countryName = airport.countryName;
                    }
                }
                this.emitAutosave();
            },
            updateEndAirportData() {
                const self = this;
                if (this.flight.endLocation.icao) {
                    const airport = this.endAirportList.find(airport => {
                        return airport.icao === self.flight.endLocation.icao;
                    });
                    if (airport) {
                        this.flight.endLocation.airportName = airport.name;
                        this.flight.endLocation.city = airport.city;
                        this.flight.endLocation.iata = airport.iata;
                        this.flight.endLocation.icao = airport.icao;
                        //flight.startLocation.address = "" + airport.street1 + airport.postalCode + ;
                        this.flight.endLocation.lat = airport.lat;
                        this.flight.endLocation.lng = airport.lng;
                        this.flight.endLocation.countryName = airport.countryName;
                    }
                }
                this.emitAutosave();
            },
            remoteSearchAirlines(query) {
                if (query !== "") {
                    this.loadingAirlines = true;
                    const self = this;
                    const url = jsRoutes.controllers.ItineraryController.airlineListSelect(query).url;
                    this.$http.get(url)
                        .then(function (response) {
                            self.loadingAirlines = false;
                            self.airlineList = response.data;
                        })
                        .catch(function (error) {
                            self.loadingAirlines = false;
                            // handle error
                            handleAjaxError(error);
                        });
                }
            },
            updateAirlineData() {
                const self = this;
                if (this.flight.airline.icao) {
                    const airline = this.airlineList.find(airline => {
                        return airline.icao === self.flight.airline.icao;
                    });
                    if (airline) {
                        this.flight.airline.name = airline.name;
                        this.flight.airline.iata = airline.iata;
                        this.flight.airline.phoneNumber = airline.phoneNumber;
                    }
                }
                this.emitAutosave();
            },
        }
    }
</script>

<style scoped>
    .airline-name {
        font-size: 1.4em;
        font-weight: bold;
    }

    .city-name {
        font-size: 1.2em;
        font-weight: bold;
    }
</style>
