<template>
    <div class="modal" id="builderSetItemLibrayNameModal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t("Itinerary.modal.SaveToLibrary.title")}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="col-12">
                        <form v-on:submit.prevent="saveToLibrary" novalidate>
                            <div class="form-group" :class="{ 'has-error': $v.name.$error }">
                                <label class="control-label" >{{$t("Itinerary.modal.SaveToLibrary.name")}} *:</label>
                                <input type="text" class="form-control"
                                       name="name"
                                       ref="name"
                                       :class="{'is-invalid' : $v.name.$error}"
                                       v-model.trim="$v.name.$model">
                                <small class="form-text text-muted">
                                    {{$t("Itinerary.modal.SaveToLibrary.name_description")}}
                                </small>
                                <div class="invalid-feedback" v-show="$v.name.$error && !$v.name.required">
                                    {{$t("Itinerary.modal.SaveToLibrary.name_required")}}
                                </div>
                                <div class="invalid-feedback" v-show="$v.name.$error && !$v.name.maxLength">
                                    {{$t("Itinerary.modal.SaveToLibrary.name_size")}}
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label" >{{$t("Itinerary.modal.SaveToLibrary.tags")}}:</label>
                                <el-select
                                        style="width:100%;color:black;font-size: 1rem;"
                                        v-model="item.tags"
                                        multiple
                                        filterable
                                        allow-create
                                        default-first-option
                                        :placeholder='$t("Itinerary.modal.SaveToLibrary.tags_select")'
                                >
                                    <el-option v-for="tag in tagList"
                                               :key="tag.id"
                                               :label="tag.name"
                                               :value="tag.id"
                                    ></el-option>
                                </el-select>
                            </div>
                            <div v-if="alreadyExists" class="form-group">
                                <label class="control-label">
                                    {{$t("Itinerary.modal.SaveToLibrary.alreadyexists")}}
                                </label>
                                <input class="form-control" type="checkbox" v-model="create">
                            </div>
                        </form>
                        <div class="float-right">
                            <button class="btn btn-link" @click="close">{{$t("Itinerary.modal.SaveToLibrary.cancel")}}</button>
                            <v-button class="btn btn-primary ml-2"
                                      @click.native="saveToLibrary"
                                      :loading="loading"
                            >
                                <div v-if="alreadyExists">
                                    <div v-if="create">
                                        {{$t("Itinerary.modal.SaveToLibrary.save")}}
                                    </div>
                                    <div v-if="!create">
                                        {{$t("Itinerary.modal.SaveToLibrary.update")}}
                                    </div>
                                </div>
                                <div v-if="!alreadyExists">
                                    {{$t("Itinerary.modal.SaveToLibrary.save")}}
                                </div>
                            </v-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vButton from '../../../components/VButton';
    import {handleAjaxError, showSuccess} from "../../../utils";
    import { required, maxLength } from 'vuelidate/lib/validators';
    import {autosaveMixin} from "../item/item-mixins";
    import {getItemNameOrTitle, ITEM_TYPE_CAR_RENTAL, ITEM_TYPE_SLEEP} from "../itinerary-templates";

    export default {
        components: {
            vButton,
        },
        mixins: [
            autosaveMixin,
        ],
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            item: {
                type: Object,
                required: true,
            },
            extraData: {
                type: Object,
                required: false,
                default: function () { return null },
            },
        },
        data() {
            return {
                name: null,
                // itemTags: [],

                libraryId: null,
                tagList: [],
                loading: false,

                create: false,
            }
        },
        validations: {
            name: {
                required,
                maxLength: maxLength(255)
            },
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function (e) {
                self.$emit('update:show', false);
            })
        },
        computed: {
            itData() {
                return this.$store.getters.itData;
            },
            alreadyExists() {
                return (this.item.libraryId > 0);
            },
        },
        watch: {
            show() {
                if (this.show) {
                    this.create = false;
                    $(this.$refs.themodal).modal('show');
                    this.loadTags();
                    this.name = this.item.libraryName ? this.item.libraryName : getItemNameOrTitle(this.item);
                    this.libraryId = null;
                    if (this.item.libraryId) {
                        this.loadLibraryItemDetail();
                    } else if (!this.item.tags || this.item.tags.length === 0) {
                        this.item.tags = this.itData.countries;
                    }
                    this.$refs.name.focus();
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
                this.$v.$reset();
            }
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            saveToLibrary() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.log("### INVALID");
                    return;
                }

                const self = this;
                const url = jsRoutes.controllers.LibraryController.saveLibraryItem().url;

                let dataObj = JSON.parse(JSON.stringify(this.item));
                // edge case em que item tem libraryid mas library item nao existe na db
                if (dataObj.libraryId && !this.libraryId) {
                    dataObj.libraryId = null;
                }
                dataObj.libraryName = this.name;
                dataObj.extraData = this.extraData;

                if (dataObj.type === ITEM_TYPE_SLEEP) {
                    dataObj.startDate = null;
                    dataObj.endDate = null;
                    dataObj.rooms = 1;
                    dataObj.roomTypes = [];
                    dataObj.alternatives = [];
                    dataObj.libraryName = dataObj.name;
                } else if (dataObj.type === ITEM_TYPE_CAR_RENTAL) {
                    dataObj.startDate = null;
                    dataObj.endDate = null;
                    dataObj.referenceNumber = null;
                }

                if (this.alreadyExists && this.create) {
                    dataObj.libraryId = null;
                }

                this.loading = true;
                this.$http.post(url, dataObj)
                    .then(function (response) {
                        self.loading = false;
                        self.$set(self.item, "libraryId", response.data.libraryId);
                        self.$set(self.item, "libraryName", self.name);
                        self.$emit("update:item", self.item);
                        self.close();
                        self.emitAutosave();
                        showSuccess(
                            self.$t("Itinerary.modal.SaveToLibrary.saved")
                        );
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },
            loadLibraryItemDetail() {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.loadItemDetails(this.item.libraryId).url;
                this.$http.get(url).then(function(response) {
                    self.libraryId = response.data.id;
                    self.name = response.data.name;
                    if (!self.item.tags || self.item.tags.length === 0) {
                        self.item.tags = response.data.tagList;
                        if (!self.item.tags || self.item.tags.length === 0) {
                            self.item.tags = self.itData.countries;
                        }
                    }
                }, function(error) {
                    self.itemTags = self.itData.countries;
                    handleAjaxError(error);
                });
            },
            loadTags() {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.tagListSelect().url;
                this.$http.get(url)
                    .then(function (response) {
                        self.tagList = response.data;
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            }
        }
    }
</script>

<style scoped>
</style>
