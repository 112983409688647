<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
                <button class="btn btn-danger"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
<!--
            <br/>
            <div class="text-right" style="margin-top: 5px;">
                <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                    <i class="fa fa-arrows-alt"></i>
                </button>
            </div>
-->
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <input :readonly="!editable"
                           type="text" class="name-input"
                           v-model="item.title"
                           :placeholder='$t("Itinerary.TripPoi.tooltip.description_of_day")'
                           @blur="emitAutosave"/>
                </h3>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12">
                <textarea-autosize
                        v-if="editable"
                        class="form-control body-font-size"
                        :placeholder='$t("Itinerary.TripPoi.tooltip.description_of_day")'
                        v-model="item.description"
                        :min-height="30"
                        :max-height="500"
                        @blur.native="emitAutosave"
                ></textarea-autosize>
                <span v-else v-html="cleanupString(item.description)"></span>
                <!--<textarea class="form-control" v-model="item.description" @blur="emitAutosave" placeholder="Describe points of interest"></textarea>-->
            </div>
        </div>
        <draggable class="row" style="margin-top: 10px;"
                   v-model="item.images"
                   :options="{animation:150, disabled: !editable}"
                   @change="emitAutosave"
        >
            <div class="col-lg-6 col-md-6 col-sm-12" style="margin-top:10px; margin-bottom: 10px;"
                 v-for="image in item.images"
                 :key="image.id"
            >
                <button v-if="editable" class="btn btn-danger img-delete-btn d-print-none" @click="deleteImage(image)">
                    <i class="fa fa-trash"></i>
                </button>
                <img class="img-fluid w-100" :src="getImageUrl(image)">
                <!--
                    http://www.corelangs.com/css/box/caption.html
                    https://www.hongkiat.com/blog/css3-image-captions/
                -->
                <div class="mor-image-caption">
                    <input :readonly="!editable"
                           type="text"
                           v-model="image.caption"
                           class="mor-image-caption-field"
                           :placeholder='$t("Itinerary.general.image_caption")'
                           @blur="updateImageCaption(image)"
                           @keyup.enter="updateImageCaption(image)"
                    />
                </div>
                <!--<div class="photo-attribution-component large">
                    <input type="text"
                           v-model="image.caption"
                           class="photo-attribution-component-attribution-link"
                           placeholder="Write caption here!"
                    />
                </div>-->
            </div>
        </draggable>
        <div v-if="editable" class="row d-print-none" style="margin-top: 10px;">
            <div class="col-12">
                <button style="margin-top: 10px;" class="btn btn-primary float-right" @click="showImageUpload = true">
                    {{$t("Itinerary.general.add_images")}}
                </button>
            </div>
        </div>
        <image-upload-modal
                :show.sync="showImageUpload"
                :images.sync="item.images"
                :item-tags="item.tags"
        >

        </image-upload-modal>
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
        >
        </save-to-library-modal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import ImageUploadModal from '../modal/ImageUploadModal';
    import {autosaveMixin, imageUrlMixin, itemCommonPropsMixin, moveItemMixin, stringUtilsMixin} from "./item-mixins";
    import { itineraryEventBus } from '../../../event-bus';
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";

    export default {
        components: {
            draggable,
            ImageUploadModal,
            SaveToLibraryModal,
        },
        mixins: [
            autosaveMixin,
            imageUrlMixin,
            moveItemMixin,
            itemCommonPropsMixin,
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showImageUpload: false,
                showSetNameModal: false,
            }
        },
        watch: {
            "item.images"() {
                //TODO isto emite autosaves quando se faz undo, tem de se fazer de outra forma
                // this.emitAutosave();
            }
        },
        computed: {
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library")
                    ;
            },
        },
        methods: {
        }
    }
</script>

<style scoped>
</style>
