<template>
    <div class="modal" id="builderAddItemModal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{title}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid" v-if="step === 1">
                        <div class="row" v-if="!hideLibrary">
                            <div class="col-12">
                                <h2>{{$t("Itinerary.general.add_from_library")}}</h2>
                            </div>
                        </div>
                        <div class="row" v-if="!hideLibrary">
                            <!-- hidden -->
                            <div class="col-12" v-if="false">
                                <div class="form-group">
                                    <label class="control-label" >{{$t("Itinerary.modal.BuilderAddItem.tags")}}:</label>
                                    <el-select
                                            style="width:100%;color:black;font-size: 1rem;"
                                            v-model="tags"
                                            multiple
                                            default-first-option
                                            filterable
                                            :placeholder='$t("Itinerary.modal.BuilderAddItem.select_tags")'
                                            @change="loadLibraryItems"
                                    >
                                        <el-option v-for="tag in tagList"
                                                   :key="tag.id"
                                                   :label="tag.name"
                                                   :value="tag.id"
                                        ></el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="col-12">
                                <el-select v-model="selectedItemId"
                                           default-first-option
                                           filterable
                                           autocomplete
                                           :placeholder='$t("Itinerary.modal.BuilderAddItem.type_to_search")'
                                           style="width:100%;color:black;font-size: 1rem;"
                                           ref="itemselect"
                                           @change="addLibraryItem"
                                >
                                    <el-option
                                            v-for="item in libraryList"
                                            :key="item.id"
                                            :label="getItemName(item)"
                                            :value="item.id">
                                        <i class="fa" :class="[itemClass(item)]"></i> {{getItemName(item)}}
                                    </el-option>
                                </el-select>
                                <!--<div class="list-group">
                                    <div class="list-group-item list-group-item-action"
                                         v-for="item in libraryList"
                                         :key="item.id"
                                         @click="addLibraryItem(item)"
                                    >
                                        <i class="fa fa-h-square"></i> {{item.data.name}}
                                    </div>
                                </div>-->
                            </div>
                        </div>
                        <hr v-if="!hideLibrary"/>
                        <div class="row" v-if="!hideLibrary">
                            <div class="col-12">
                                <h2>{{$t("Itinerary.modal.BuilderAddItem.or_create_from_scratch")}}</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <ul class="list-group">
                                    <li
                                        v-if="!restrict || restrict.POI"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addItem('POI')">
                                        <div class="item-icon">
                                            <i class="fa fa-sun fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.description_of_day")}}</h3>
                                        <span class="pl-2" v-if="false">{{$t("Itinerary.modal.BuilderAddItem.description_of_day_description")}}</span>
                                    </li>
                                    <li
                                        v-if="!restrict || restrict.ACTIVITY_ITEM"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addActivityItem(false)">
                                        <div class="item-icon">
                                            <i class="fa fa-building fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.activity_optional")}}</h3>
                                        <span class="pl-2" v-if="false">{{$t("Itinerary.modal.BuilderAddItem.activity_optional_description")}}</span>
                                    </li>
                                     <li
                                        v-if="!restrict || restrict.PLACE"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addItem('PLACE')">
                                        <div class="item-icon">
                                            <i class="fa fa-location-dot fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.place")}}</h3>
                                        <span class="pl-2" v-if="false">{{$t("Itinerary.modal.BuilderAddItem.place_description")}}</span>
                                    </li>
                                    <li
                                        v-if="!restrict || restrict.CAROUSEL"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addItem('CAROUSEL')">
                                        <div class="item-icon">
                                            <i class="fa fa-images fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.carousel")}}</h3>
                                        <span class="pl-2" v-if="false">{{$t("Itinerary.modal.BuilderAddItem.carousel_description")}}</span>
                                    </li>
                                    <!-- <li
                                        v-if="!restrict || restrict.DESTINATION_INFO"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addItem('DESTINATION_INFO')">
                                        <div class="item-icon">
                                            <i class="fa fa-info fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.destination_info")}}</h3>
                                        <span class="pl-2" v-if="false">{{$t("Itinerary.modal.BuilderAddItem.destination_info_description")}}</span>
                                    </li> -->
                                    <!-- <li
                                        v-if="!restrict || restrict.TRIP_HIGHLIGHTS"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addItem('TRIP_HIGHLIGHTS')">
                                        <div class="item-icon">
                                            <i class="fa fa-heart fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.trip_highlights")}}</h3>
                                        <span class="pl-2" v-if="false">{{$t("Itinerary.modal.BuilderAddItem.trip_highlights_description")}}</span>
                                    </li> -->

                                    <!-- <li
                                        v-if="!restrict || restrict.KNOW_BEFORE_GO"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addItem('KNOW_BEFORE_GO')">
                                        <div class="item-icon">
                                            <i class="fa fa-exclamation fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.know_before_you_go")}}</h3>
                                        <span class="pl-2" v-if="false">{{$t("Itinerary.modal.BuilderAddItem.know_before_you_go_description")}}</span>
                                    </li> -->

                                    <li
                                        v-if="!restrict || restrict.FLIGHT"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addFlightItem()">
                                        <div class="item-icon">
                                            <i class="fa fa-plane fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.flight")}}</h3>
                                        <span class="pl-2" v-if="false">{{$t("Itinerary.modal.BuilderAddItem.flight_description")}}</span>
                                    </li>
                                    <li
                                        v-if="!restrict || restrict.TRAIN"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addTransportationItem('TRAIN')">
                                        <div class="item-icon">
                                            <i class="fa fa-train fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.train")}}</h3>
                                        <span class="pl-2" v-if="false">{{$t("Itinerary.modal.BuilderAddItem.train_description")}}</span>
                                    </li>
                                    <li
                                        v-if="!restrict || restrict.TRANSFER"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addTransportationItem('TRANSFER')">
                                        <div class="item-icon">
                                            <i class="fa fa-shuttle-van fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.transfer")}}</h3>
                                        <span class="pl-2" v-if="false">{{$t("Itinerary.modal.BuilderAddItem.transfer_description")}}</span>
                                    </li>
                                    <li
                                        v-if="!restrict || restrict.TRIP_URL"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addItem('TRIP_URL')">
                                        <div class="item-icon">
                                            <i class="fa fa-link fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("Itinerary.general.link")}}</h3>
                                    </li>
                                    <li
                                        v-if="!restrict || restrict.TRIP_FAQ"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addItem('TRIP_FAQ')">
                                        <div class="item-icon">
                                            <i class="fa fa-question fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.faq")}}</h3>
                                    </li>
                                    <!-- <li
                                         v-if="!restrict || restrict.TRIP_RATINGS"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addItem('TRIP_RATINGS')">
                                        <div class="item-icon">
                                            <i class="fa fa-star fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.ratings")}}</h3>
                                        <span class="pl-2" v-if="false">{{$t("Itinerary.modal.BuilderAddItem.ratings_description")}}</span>
                                    </li> -->
                                    <li
                                        v-if="!restrict || restrict.TRIP_SOUNDCLOUD"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addItem('TRIP_SOUNDCLOUD')">
                                        <div class="item-icon">
                                            <i class="fa fa-soundcloud soundcloud fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("v2.Itinerary.defaults.ITEM_NAME_TRIP_SOUNDCLOUD")}}</h3>
                                    </li>
                                    <li
                                        v-if="!restrict || restrict.TRIP_VIDEO"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addItem('TRIP_VIDEO')">
                                        <div class="item-icon">
                                            <i class="fa fa-youtube fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("v2.Itinerary.defaults.ITEM_NAME_TRIP_VIDEO")}}</h3>
                                    </li>
                                    <li
                                        v-if="!restrict || restrict.AUDIO"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addItem('AUDIO')">
                                        <div class="item-icon">
                                            <i class="fa fa-file-audio fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.audio")}}</h3>
                                        <span class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.audio_description")}}</span>
                                    </li>
                                    <li
                                        v-if="!restrict || restrict.VIDE_FILE"
                                        class="list-group-item list-group-item-action d-flex align-items-center"
                                        @click="addItem('VIDEO_FILE')">
                                        <div class="item-icon">
                                            <i class="fa fa-file-video fa-3x"></i>
                                        </div>
                                        <h3 class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.video_file")}}</h3>
                                        <span class="pl-2">{{$t("Itinerary.modal.BuilderAddItem.video_file_description")}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <builder-add-item-step2-dates-map
                            v-if="step === 2 && isMeal"
                            :item="item"
                            :with-dates="false"
                            @add-item="doAddItem($event)"
                            @cancel="setStep1"
                    >
                    </builder-add-item-step2-dates-map>
                    <builder-add-item-step2-dates-map
                            v-if="step === 2 && isSleep"
                            :item="item"
                            @add-item="doAddItem($event)"
                            @cancel="setStep1"
                    >
                    </builder-add-item-step2-dates-map>
                    <builder-add-item-step2-car-rental
                            v-if="step === 2 && isCarRental"
                            :item="item"
                            @add-item="doAddItem($event)"
                            @cancel="setStep1"
                    >
                    </builder-add-item-step2-car-rental>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {generateUUID, handleAjaxError} from "../../../utils";
    import vButton from '../../../components/VButton';
    import BuilderAddItemStep2DatesMap from './BuilderAddItemStep2DatesMap';
    import BuilderAddItemStep2CarRental from './BuilderAddItemStep2CarRental';
    import {
        ITEM_TYPE_CANCELATION_POLICY,
        ITEM_TYPE_CAR_RENTAL,
        ITEM_TYPE_CLIENT_INFO,
        ITEM_TYPE_COMPANY_INFO,
        ITEM_TYPE_DESTINATION_INFO,
        ITEM_TYPE_NEXT_STEPS,
        ITEM_TYPE_POI,
        ITEM_TYPE_SLEEP,
        ITEM_TYPE_PLACE,
        ITEM_TYPE_TOUR_NAME,
        ITEM_TYPE_TRIP_DESCRIPTION,
        ITEM_TYPE_TRIP_HIGHLIGHTS,
        ITEM_TYPE_TRIP_RATINGS,
        ITEM_TYPE_TRIP_FAQ,
        ITEM_TYPE_TRIP_URL,
        ITEM_TYPE_TRIP_SOUNDCLOUD,
        ITEM_TYPE_TRIP_VIDEO,
        ITEM_TYPE_WHATS_INCLUDED,
        ACTIVITY_ITEM,
        CANCELATION_POLICY_ITEM,
        CAR_RENTAL_ITEM,
        CLIENT_INFO_ITEM,
        COMPANY_INFO_ITEM,
        DESTINATION_INFO_ITEM,
        MEAL_ITEM,
        NEXT_STEPS_ITEM,
        POI_ITEM,
        SLEEP_ITEM,
        PLACE_ITEM,
        TOUR_NAME_ITEM,
        TOUR_PRICE_ITEM,
        TRANSPORTATION_ITEM,
        TRIP_DESCRIPTION_ITEM,
        TRIP_HIGHLIGHTS_ITEM,
        TRIP_RATINGS_ITEM,
        TRIP_FAQ_ITEM,
        TRIP_URL_VALUE,
        TRIP_SOUNDCLOUD_ITEM,
        TRIP_VIDEO_ITEM,
        WHATS_INCLUDED_ITEM,
        ITEM_TYPE_MEAL,
        ITEM_TYPE_GENERIC,
        GENERIC_ITEM,
        TRIP_DATES_ITEM,
        ITEM_TYPE_TRIP_DATES,
        ITEM_TYPE_KNOW_BEFORE_GO,
        KNOW_BEFORE_GO_ITEM,
        ITEM_TYPE_COVER,
        COVER_ITEM,
        getItemNameOrTitle, FLIGHT_ITEM,
        ITEM_TYPE_CAROUSEL,
        CAROUSEL_ITEM,
        ITEM_TYPE_AUDIO,
        AUDIO_ITEM,
        ITEM_TYPE_VIDEO_FILE,
        VIDEO_FILE_ITEM,
    } from "../itinerary-templates";

    import axios from "axios";
    import $ from 'jquery'

    export default {
        components: {
            vButton,
            BuilderAddItemStep2DatesMap,
            BuilderAddItemStep2CarRental
        },
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            itData: {
                type: Object,
                required: true,
            },
            only: {
                type: Object,
                default: null
            },
            hideLibrary: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {

                loading: false,

                libraryList: [],

                selectedItemId: null,
                item: null,

                step: 1,

                tags: [],

                tagList: [],
            }
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function () {
                self.$emit('update:show', false);
            })
        },
        computed: {
            /*itData() {
                return this.$store.getters.itData;
            },*/
            title() {
                if (this.item !== null && this.item.type === ITEM_TYPE_SLEEP) {
                    return this.$t("Itinerary.modal.Step2Sleep.add_hotel");
                }
                return this.$t("Itinerary.modal.Step2Sleep.add_item");
            },
            isMeal() {
                // console.log("### MEAL? " + (this.item.type === ITEM_TYPE_MEAL));
                return this.item.type === ITEM_TYPE_MEAL || this.item.type === ITEM_TYPE_PLACE;
            },
            isSleep() {
                // console.log("### SLEEP? " + (this.item.type === ITEM_TYPE_SLEEP));
                return this.item.type === ITEM_TYPE_SLEEP;
            },
            isCarRental() {
                // console.log("### RENTAL? " + (this.item.type === ITEM_TYPE_CAR_RENTAL));
                return this.item.type === ITEM_TYPE_CAR_RENTAL;
            },
            restrict() {
                if (this.only) {
                    return this.only.reduce((result, item) => {
                        result[item] = true;
                        return result;
                    }, {})
                }
                return null;
            }

        },
        watch: {
            show() {
                if (this.show) {
                    this.setStep1();
                    $(this.$refs.themodal).modal('show');
                    this.tags = this.itData.countries;
                    this.libraryList = [];
                    this.loadTags();
                    this.loadLibraryItems();
                    //this.$refs.itemselect.focus();
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
            }
        },
        methods: {
            getItemName(item) {
                let result = item.name;
                if (!result) {
                    result = getItemNameOrTitle(item.data);
                }
                return result;
            },
            itemClass(item) {
                if (item.data.type === ITEM_TYPE_SLEEP) {
                    return "fa-h-square";
                } else if (item.data.type === ITEM_TYPE_MEAL || item.data.type === ITEM_TYPE_PLACE) {
                    return "fa-utensils";
                }
                return "";
            },
            setStep1() {
                this.step = 1;
                this.item = null;
                this.selectedItemId = null;
            },
            /*isTransportation() {
                return this.item.type === ITEM_TYPE_TRANSPORTATION;
            },*/
            /*formatDates() {
                let formattedDates = '';
                if (this.item.startDate) {
                    formattedDates = format(this.item.startDate, DATE_FORMAT)
                }
                if (this.item.endDate) {
                    formattedDates += ' - ' + format(this.item.endDate, DATE_FORMAT)
                }

                return formattedDates;
            },*/
            addActivityItem(included) {
                let item = ACTIVITY_ITEM(included);
                this.doAddItem(item);
            },
            addTourPriceItem(included) {
                let item = TOUR_PRICE_ITEM(included);
                this.doAddItem(item);
            },
            addTransportationItem(subType) {
                let item = TRANSPORTATION_ITEM(subType);
                this.doAddItem(item);
            },
            addFlightItem() {
                let item = FLIGHT_ITEM();
                this.doAddItem(item);
            },
            addItem(type) {
                let item = null;
                switch(type) {
                    case ITEM_TYPE_GENERIC:
                        item = GENERIC_ITEM();
                        break;
                    case ITEM_TYPE_COVER:
                        item = COVER_ITEM();
                        break;
                    case ITEM_TYPE_TOUR_NAME:
                        item = TOUR_NAME_ITEM();
                        break;
                    case ITEM_TYPE_CLIENT_INFO:
                        item = CLIENT_INFO_ITEM();
                        break;
                    case ITEM_TYPE_TRIP_HIGHLIGHTS:
                        item = TRIP_HIGHLIGHTS_ITEM();
                        break;
                    case ITEM_TYPE_TRIP_RATINGS:
                        item = TRIP_RATINGS_ITEM();
                        break;
                    case ITEM_TYPE_TRIP_FAQ:
                        item = TRIP_FAQ_ITEM();
                        break;
                    case ITEM_TYPE_TRIP_URL:
                        item = TRIP_URL_VALUE();
                        break;
                    case ITEM_TYPE_TRIP_SOUNDCLOUD:
                        item = TRIP_SOUNDCLOUD_ITEM();
                        break;
                    case ITEM_TYPE_TRIP_VIDEO:
                        item = TRIP_VIDEO_ITEM();
                        break;
                    case ITEM_TYPE_TRIP_DESCRIPTION:
                        item = TRIP_DESCRIPTION_ITEM();
                        break;
                    case ITEM_TYPE_COMPANY_INFO:
                        item = COMPANY_INFO_ITEM();
                        break;
                    case ITEM_TYPE_TRIP_DATES:
                        item = TRIP_DATES_ITEM();
                        break;
                    case ITEM_TYPE_DESTINATION_INFO:
                        item = DESTINATION_INFO_ITEM();
                        break;
                    case ITEM_TYPE_POI:
                        item = POI_ITEM();
                        break;
                    case ITEM_TYPE_SLEEP:
                        item = SLEEP_ITEM();
                        break;
                    case ITEM_TYPE_PLACE:
                        item = PLACE_ITEM();
                        break;
                    case ITEM_TYPE_CAR_RENTAL:
                        item = CAR_RENTAL_ITEM();
                        break;
                    case ITEM_TYPE_KNOW_BEFORE_GO:
                        item = KNOW_BEFORE_GO_ITEM();
                        break;
                    case ITEM_TYPE_WHATS_INCLUDED:
                        item = WHATS_INCLUDED_ITEM();
                        break;
                    case ITEM_TYPE_NEXT_STEPS:
                        item = NEXT_STEPS_ITEM();
                        break;
                    case ITEM_TYPE_CANCELATION_POLICY:
                        item = CANCELATION_POLICY_ITEM();
                        break;
                    case ITEM_TYPE_CAROUSEL:
                        item = CAROUSEL_ITEM();
                        break;
                    case ITEM_TYPE_AUDIO:
                        item = AUDIO_ITEM();
                        break;
                    case ITEM_TYPE_VIDEO_FILE:
                        item = VIDEO_FILE_ITEM();
                        break;
                }

                if (this.needStep2(item)) {
                    this.item = item;
                    //TODO talvez tornar isto mais inteligente com as datas, ver em que datas ja existem items do mesmo tipo e nao seleccionar essas
                    // this.item.startDate = this.itData.startDate;
                    // this.item.endDate = this.itData.endDate;
                    this.step = 2;
                } else {
                    this.doAddItem(item);
                }
            },
            addLibraryItem() {
                const self = this;
                const libraryItem = this.libraryList.find(item => {
                    return item.id === self.selectedItemId;
                });
                let item = JSON.parse(JSON.stringify(libraryItem.data));
                item.id = generateUUID();
                item.libraryId = libraryItem.id;

                if (this.needStep2(item)) {
                    this.item = item;
                    this.step = 2;
                } else {
                    this.doAddItem(item);
                }
            },
            doAddItem(item) {
                this.$emit('add-item', item);
                this.$emit('update:show', false);
            },
            loadLibraryItems() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.LibraryController.getLibraryItemListSelect().url;

                const searchData = {
                    name: null,
                    type: null,
                    status: 1,
                    tags: this.tags,
                };

                axios.post(url, searchData)
                    .then(function (response) {
                    // set data
                    self.libraryList = response.data;
                }).catch(function (error) {
                    // handle error
                    handleAjaxError(error);
                });
            },
            loadTags() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.LibraryController.tagListSelect().url;
                this.$http.get(url)
                    .then(function (response) {
                        self.tagList = response.data;
                    })
                    .catch(function (error) {
                        // self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },
            needStep2(item) {
                return item.type === ITEM_TYPE_PLACE ||
                    item.type === ITEM_TYPE_SLEEP ||
                    item.type === ITEM_TYPE_CAR_RENTAL ||
                    item.type === ITEM_TYPE_MEAL;
            },
        }
    }
</script>

<style scoped>
    .item-icon {
        width: 60px;
        min-width: 60px;
        text-align: center;
    }
/*
    .item-box {
        height:80px;
        border: 1px solid darkgray;
        border-radius: 5px;
        cursor: pointer;
    }

    .item-box:hover {
        background-color: #f8f9fa;
    }
*/

    .list-group-item-action {
        cursor: pointer;
    }
</style>
