<template>
    <div>
        <div class="row">
            <div class="col-8">
                <h3>
                    <div class="table-cell">
                        <i class="fa fa-h-square"></i>
                    </div>
                    <div class="table-cell" style="width:100%; padding-left: 10px;">
                        {{item.name}}
                    </div>
                </h3>
            </div>
        </div>
        <div class="row" v-if="showStars">
            <div class="col-12">
                <star-rating v-model="item.stars" :read-only="true" :show-rating="false" :star-size="20"></star-rating>
            </div>
        </div>
        <div class="row">
            <div class="col-12" style="padding-top: 5px;" v-if="stringNotEmpty(item.hotelType)">
                <div class="table-cell">
                    <b>{{$t("Itinerary.TripSleep.tooltip.type")}}:</b>
                </div>
                <div class="table-cell" style="width:100%;padding-left:5px;">
                    {{item.hotelType}}
                    <!--<multiselect
                            v-model="item.hotelType"
                            :options="hotelTypeOptions"
                            placeholder="Select hotel type or write new"
                            tag-placeholder="Add new hotel type"
                            :multiple="false"
                            :taggable="true"
                            :close-on-select="true"
                    ></multiselect>-->
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 5px;">
            <div class="col-12" v-if="item.googleRating !== undefined && item.googleRating !== null">
                <div class="table-cell">
                    {{$t("Itinerary.general.google_rating")}}:
                </div>
                <div class="table-cell">
                    <star-rating v-model="item.googleRating"
                                 :show-rating="true"
                                 :star-size="15"
                                 :read-only="true"
                                 :increment="0.1"
                    ></star-rating>
                </div>
            </div>
            <div class="col-12" style="padding-top: 5px;"
                 v-if="stringNotEmpty(item.description)"
                 v-html="cleanupString(item.description)"
            >
                <!--<textarea-autosize
                        style="border: 0; padding: 0;color:black;"
                        class="form-control body-font-size"
                        :placeholder='$t("Itinerary.TripSleep.tooltip.description")'
                        v-model="item.description"
                        :min-height="30"
                        :max-height="500"
                ></textarea-autosize>-->
            </div>
            <div class="col-12" style="padding-top: 5px;" v-if="stringNotEmpty(item.address)">
                <div class="table-cell">
                    <i class="fa fa-globe"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    {{item.address}}
                    <!--<input class="bullet-item mor-editable-field"
                           type="text"
                           placeholder="Address"
                           v-model="item.address"
                    />-->
                </div>
            </div>
            <div class="col-12" v-if="stringNotEmpty(item.phone)">
                <div class="table-cell">
                    <i class="fa fa-phone"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    {{item.phone}}
                    <!--<input class="bullet-item mor-editable-field"
                           type="text"
                           placeholder="Phone"
                           v-model="item.phone"
                    />-->
                </div>
            </div>
            <div class="col-12" style="padding-top: 5px;" v-if="stringNotEmpty(item.website)">
                <div class="table-cell">
                    <a :href="item.website" target="_blank"><i class="fa fa-mail-bulk"></i></a>
                </div>
                <div class="table-cell" style="padding-left: 10px;">
                    <a :href="item.website">{{item.website}}</a>
                </div>
            </div>
            <div class="col-12" v-if="stringNotEmpty(item.checkin)">
                <div class="table-cell icon-left">
                    <i class="fa fa-sign-in-alt"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    {{item.checkin}}
                </div>
            </div>
            <div class="col-12" v-if="stringNotEmpty(item.checkout)">
                <div class="table-cell icon-left">
                    <i class="fa fa-sign-out-alt"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    {{item.checkout}}
                </div>
            </div>
            <div class="col-12" style="padding-top: 5px;" v-if="stringNotEmpty(item.mealPlan)">
                <div class="table-cell" style="white-space: nowrap;">
                    {{$t("Itinerary.TripSleep.tooltip.meal_plan")}}: &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div class="table-cell" style="width:100%;">
                    {{item.mealPlan}}
                    <!--<multiselect
                            v-model="item.mealPlan"
                            :options="mealPlanOptions"
                            placeholder="Select meal plan type or write new"
                            tag-placeholder="Add new meal plan"
                            :multiple="false"
                            :taggable="true"
                            :close-on-select="true"
                    ></multiselect>-->
                </div>
            </div>
            <div class="col-12" style="padding-top: 5px; padding-bottom: 5px;" v-if="item.rooms">
                <div class="table-cell">
                    {{$t("Itinerary.TripSleep.tooltip.rooms")}}:
                </div>
                <div class="bullet-right table-cell">
                    {{item.rooms}}
                    <!--<input class="bullet-item mor-editable-field"
                           v-tooltip="'Change number of rooms'"
                           style="width:40px;"
                           type="number"
                           placeholder="Number of rooms"
                           v-model="item.rooms"
                    />-->
                </div>
            </div>
            <div class="col-12">
                <div v-for="(name, index) in item.roomTypes" v-if="stringNotEmpty(name)">
                    {{$t("Itinerary.TripSleep.tooltip.room")}} {{index+1}}: {{name}}
                </div>
            </div>
        </div>
        <div class="row photos-row" v-if="item.images.length > 1">
            <div class="col-6 photos-col"
                 v-for="image in item.images.slice(0,2)"
                 :key="image.id"
            >
                <img class="img-fluid w-100" :src="getImageUrl(image)">
                <div class="mor-image-caption" v-if="stringNotEmpty(image.caption)">
                    <input type="text"
                           v-model="image.caption"
                           class="mor-image-caption-field"
                    />
                </div>
            </div>
        </div>
        <div class="row photos-row justify-content-center page-break-inside-avoid" v-if="item.images.length === 1">
            <div class="col-6 photos-col">
                <img class="img-fluid w-100" :src="getImageUrl(item.images[0])">
                <div class="mor-image-caption" v-if="stringNotEmpty(item.images[0].caption)">
                    <input type="text"
                           v-model="item.images[0].caption"
                           class="mor-image-caption-field"
                    />
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;" v-if="item.alternatives && item.alternatives.length > 0">
            <div class="col-12">
                <h4>{{$t("Itinerary.TripSleep.tooltip.alternatives")}}</h4>
            </div>
            <div class="col-12">
                <div v-for="(alt, index) in item.alternatives"
                     :key="alt.id"
                     class="card mb-2"
                >
                    <div class="card-header" v-if="!alt.showOnPdf">
                        {{alt.name}}
                    </div>
                    <div class="card-body" v-if="alt.showOnPdf">
                        <div class="col-12">
                            <trip-sleep-alternative-print :item="alt"></trip-sleep-alternative-print>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {imageUrlMixin, stringUtilsMixin} from "../../item/item-mixins";
    import TripSleepAlternativePrint from "./TripSleepAlternativePrint";

    export default {
        components: {
            TripSleepAlternativePrint,
        },
        mixins: [
            imageUrlMixin,
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                roomOptions: [
                    "Single",
                    "Twin",
                    "Double",
                    "Suite",
                ],
                hotelTypeOptions: [
                    "Hotel",
                    "B&B",
                    "Hostel",
                    "Winery",
                    "Apartment",
                    "Private Villa",
                ],
                mealPlanOptions: [
                    "Self Catering (SC)",
                    "Breakfast",
                    "Half Board (HB)",
                    "Full Board (FB)",
                    "All Inclusive (AI)",
                ]
            }
        },
        computed: {
            showStars() {
                return this.item.hotelType &&
                    (this.item.hotelType === "Hotel" || this.item.hotelType === "B&B");
            }
        },
        mounted() {
            /*if (this.item.roomTypes !== undefined) {
                const self = this;
                this.item.roomTypes.forEach((roomType) => {
                    if (roomType !== null &&
                        roomType !== "" &&
                        !self.roomOptions.includes(roomType)
                    ) {
                        self.roomOptions.push(roomType);
                    }
                });
            }
            if (this.item.hotelType !== undefined &&
                this.item.hotelType !== null &&
                this.item.hotelType !== ""
            ) {
                if (!this.hotelTypeOptions.includes(this.item.hotelType)) {
                    this.hotelTypeOptions.push(this.item.hotelType);
                }
            }
            if (this.item.mealPlan !== undefined &&
                this.item.mealPlan !== null &&
                this.item.mealPlan !== ""
            ) {
                if (!this.mealPlanOptions.includes(this.item.mealPlan)) {
                    this.mealPlanOptions.push(this.item.mealPlan);
                }
            }*/
        },
        beforeDestroy() {
        },
        methods: {
        },
    }
</script>

<style scoped>
</style>
