<template>
    <div v-if="!loading && showChat" id="mainChatDiv" style="height:100%; overflow-y: auto; overflow-x: hidden;">
        <div v-bind:key="message.id" v-for="message in messages" class="row" style="">
            <div class="col-12" >
                <div v-if="message.fromAgencyMember">
                    <div style="width: 100%; text-align: right;">
                        {{message.fromName}}<br/>
                        {{message.messageDate}}
                    </div>
                    <div class="talk-bubble tri-right right-top" style="float: right;">
                      <div class="talktext">
                        <p><p>{{message.message}}</p></p>
                      </div>
                    </div>
                </div>
                <div v-if="!message.fromAgencyMember">
                    <div class="fromMessageTitle">
                        {{message.fromName}}<br/>
                        {{message.messageDate}}
                    </div>
                    <div class="talk-bubble tri-right left-top">
                      <div class="talktext">
                        <p>{{message.message}}</p>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="height: 100px;">
            <div class="col-12">

            </div>
        </div>
        <div class="row" style="bottom: 0px; position: fixed; padding-bottom: 10px; background-color: white !important;">
            <div class="col-12" style="background-color: white;">
                <div class="input-group mb-3">
                    <textarea class="form-control"
                              v-model="myMessage"
                              id="exampleFormControlTextarea1"
                              :placeholder='$t("Itinerary.chat.your_message")'
                              :aria-label='$t("Itinerary.chat.your_message")'
                              rows="2"
                              aria-describedby="button-addon2">

                    </textarea>
                    <div class="input-group-append">
                        <button v-on:click="sendMessage" class="btn btn-secondary" type="button" id="button-addon2">{{$t("Itinerary.chat.send")}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {chatEventBus, notifyReadChatMessages, requestMessagesForItinerary, sendChatMessage} from '../../chat';

    export default {
        name: "ItineraryChat",
        components: {},
        data() {
            return {
                loading: true,
                showChat: false,
                id: null,
                myMessage: '',
                messages: []
            }
        },
        beforeDestroy() {
            if (this.showChat) {
                chatEventBus.$off("CHAT");
                chatEventBus.$off("COMPLETE_CHAT_FOR_ITINERARY");
                chatEventBus.$off("UPDATE_CHAT");
            }
        },
        mounted() {
            let self = this;
            jsRoutes.controllers.AdministrationController.verifyShowChat().ajax({
                success : function(data) {
                    self.loading = false;
                    self.showChat = data ;
                    if (data) {
                        // console.log("SHOW CHAT IS ON!")
                        self.setupCommunications()
                    } else {
                        // console.log("SHOW CHAT IS OFF!")
                    }
                },
                error : function(error) {
                }
            });
        },
        methods: {
            setupCommunications () {
                this.id= itinId;
                
                requestMessagesForItinerary(this.id);

                var self = this;

                chatEventBus.$on("COMPLETE_CHAT_FOR_ITINERARY", (inbound) => {
                    self.$nextTick(() => {
                        if( inbound.id === self.id )
                        {
                            self.messages.length = 0;
                            for(var index in inbound.listItems) {
                                self.messages.push(inbound.listItems[index]);
                            }
                            self.$nextTick(() => {
                                self.scrollToBottom();
                            });
                        }
                    });
                });

                chatEventBus.$on("CHAT", (message) => {
                    self.$nextTick(() => {
                        if( message.itineraryId === self.id )
                        {
                            self.messages.push(message);
                            self.$nextTick(() => {
                                self.scrollToBottom();
                            });
                            notifyReadChatMessages(message.id);
                        }
                    });
                });

                chatEventBus.$on("UPDATE_CHAT", (message) => {
                    self.$nextTick(() => {
                        if( message.itineraryId === self.id )
                        {
                            self.messages.push(message);
                            self.$nextTick(() => {
                                self.scrollToBottom();
                            });
                            notifyReadChatMessages(message.id);
                        }
                    });
                });

                this.$nextTick(() => {
                    self.scrollToBottom();
                });

            },
            sendMessage () {
                if (this.myMessage.trim().length !== 0) {
                    sendChatMessage(this.myMessage, this.id);
                    this.myMessage='';
                }
            },
            scrollToBottom () {
                var objDiv = document.getElementById("mainChatDiv");
                objDiv.scrollTop = (objDiv.scrollHeight*20);
            }
        }
    }
</script>

<style scoped>

    .fromMessageTitle {
        width: 100%;
        font-size: smaller;
        font-style: italic;
    }

    .talk-bubble {
    	margin: 0px 20px 0px 20px;
      display: inline-block;
      position: relative;
    	/*width: 100%;*/
    	height: auto;
    	background-color: #81c784;
    }

    .tri-right.border.left-top:before {
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
      left: -40px;
    	right: auto;
      top: -8px;
    	bottom: auto;
    	border: 32px solid;
    	border-color: #666 transparent transparent transparent;
    }
    .tri-right.left-top:after{
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
      left: -20px;
    	right: auto;
      top: 0px;
    	bottom: auto;
    	border: 22px solid;
    	border-color: #81c784 transparent transparent transparent;
    }

    /* Right triangle, left side slightly down */
    .tri-right.border.left-in:before {
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
      left: -40px;
    	right: auto;
      top: 30px;
    	bottom: auto;
    	border: 20px solid;
    	border-color: #666 #666 transparent transparent;
    }
    .tri-right.left-in:after{
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
      left: -20px;
    	right: auto;
      top: 38px;
    	bottom: auto;
    	border: 12px solid;
    	border-color: #81c784 #81c784 transparent transparent;
    }

    /*Right triangle, placed bottom left side slightly in*/
    .tri-right.border.btm-left:before {
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
    	left: -8px;
      right: auto;
      top: auto;
    	bottom: -40px;
    	border: 32px solid;
    	border-color: transparent transparent transparent #666;
    }
    .tri-right.btm-left:after{
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
    	left: 0px;
      right: auto;
      top: auto;
    	bottom: -20px;
    	border: 22px solid;
    	border-color: transparent transparent transparent #81c784;
    }

    /*Right triangle, placed bottom left side slightly in*/
    .tri-right.border.btm-left-in:before {
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
    	left: 30px;
      right: auto;
      top: auto;
    	bottom: -40px;
    	border: 20px solid;
    	border-color: #666 transparent transparent #666;
    }
    .tri-right.btm-left-in:after{
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
    	left: 38px;
      right: auto;
      top: auto;
    	bottom: -20px;
    	border: 12px solid;
    	border-color: #81c784 transparent transparent #81c784;
    }

    /*Right triangle, placed bottom right side slightly in*/
    .tri-right.border.btm-right-in:before {
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
      left: auto;
    	right: 30px;
    	bottom: -40px;
    	border: 20px solid;
    	border-color: #666 #666 transparent transparent;
    }
    .tri-right.btm-right-in:after{
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
      left: auto;
    	right: 38px;
    	bottom: -20px;
    	border: 12px solid;
    	border-color: #81c784 #81c784 transparent transparent;
    }
    /*
    	left: -8px;
      right: auto;
      top: auto;
    	bottom: -40px;
    	border: 32px solid;
    	border-color: transparent transparent transparent #666;
    	left: 0px;
      right: auto;
      top: auto;
    	bottom: -20px;
    	border: 22px solid;
    	border-color: transparent transparent transparent lightyellow;

    /*Right triangle, placed bottom right side slightly in*/
    .tri-right.border.btm-right:before {
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
      left: auto;
    	right: -8px;
    	bottom: -40px;
    	border: 20px solid;
    	border-color: #666 #666 transparent transparent;
    }
    .tri-right.btm-right:after{
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
      left: auto;
    	right: 0px;
    	bottom: -20px;
    	border: 12px solid;
    	border-color: #81c784 #81c784 transparent transparent;
    }

    /* Right triangle, right side slightly down*/
    .tri-right.border.right-in:before {
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
      left: auto;
    	right: -40px;
      top: 30px;
    	bottom: auto;
    	border: 20px solid;
    	border-color: #666 transparent transparent #666;
    }
    .tri-right.right-in:after{
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
      left: auto;
    	right: -20px;
      top: 38px;
    	bottom: auto;
    	border: 12px solid;
    	border-color: #81c784 transparent transparent #81c784;
    }

    /* Right triangle placed top right flush. */
    .tri-right.border.right-top:before {
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
      left: auto;
    	right: -40px;
      top: -8px;
    	bottom: auto;
    	border: 32px solid;
    	border-color: #666 transparent transparent transparent;
    }
    .tri-right.right-top:after{
    	content: ' ';
    	position: absolute;
    	width: 0;
    	height: 0;
      left: auto;
    	right: -20px;
      top: 0px;
    	bottom: auto;
    	border: 20px solid;
    	border-color: #81c784 transparent transparent transparent;
    }

    /* talk bubble contents */
    .talktext{
      padding: 1em;
    	text-align: left;
      line-height: 1.5em;
    }
    .talktext p{
      /* remove webkit p margins */
      -webkit-margin-before: 0em;
      -webkit-margin-after: 0em;
    }


</style>
