<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft">
                    <button class="btn btn-primary dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                            :key="section.id"
                            class="dropdown-item"
                            :class="{'disabled': section.id === currentSectionId}"
                            @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-danger"
                    @click="$emit('delete-item', item)"
                    v-tooltip='$t("Itinerary.general.remove_item")'
                >
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>

        <h3>{{ $t('v2.Itinerary.defaults.ITEM_NAME_CAROUSEL') }}</h3>
        <div class="row">
            <div class="col-12">
                <table class="table" style="margin-top: 10px;">
                    <thead class="thead-light">
                        <th v-if="editable" style="width:30px;">
                            &nbsp;
                        </th>
                        <th>{{ $t('Itinerary.TripCarousel.title') }}</th>
                        <th>{{ $t('Itinerary.TripCarousel.subtitle') }}</th>
                        <th>{{ $t('Itinerary.TripCarousel.image') }}</th>
                        <th v-if="editable" style="width:30px;">
                            &nbsp;
                        </th>
                    </thead>
                    <draggable
                        element="tbody"
                        v-model="item.slides"
                        :options="{
                            animation:150,
                            disabled: !editable
                        }"
                        @update="emitAutosave"
                    >
                        <tr v-for="(slide, index) in item.slides" :key="slide.id">
                            <td v-if="editable">
                                <button class="btn btn-sm btn-default" v-tooltip='$t("Itinerary.TripCarousel.tooltip.drag_to_reorder")'>
                                    <i class="fa fa-bars"></i>
                                </button>
                            </td>
                            <td>
                                <input :readonly="!editable"
                                    type="text"
                                    class="name-input"
                                    :placeholder='$t("Itinerary.TripCarousel.title")'
                                    v-model="slide.title"
                                    @blur="emitAutosave"
                                    v-tooltip='$t("Itinerary.general.click_to_edit")'
                                />
                            </td>
                            <td>
                                <input :readonly="!editable"
                                    type="text"
                                    class="name-input"
                                    :placeholder='$t("Itinerary.TripCarousel.subtitle")'
                                    v-model="slide.subtitle"
                                    @blur="emitAutosave"
                                    v-tooltip='$t("Itinerary.general.click_to_edit")'
                                />
                            </td>
                            <td v-if="slide.image">
                                <img class="img-fluid w-100" :src="getImageUrl(slide.image)">
                                <div class="mor-image-caption">
                                    <input :readonly="!editable"
                                        type="text"
                                        v-model="slide.image.caption"
                                        class="mor-image-caption-field"
                                        :placeholder='$t("Itinerary.general.image_caption")'
                                        @blur="updateImageCaption(slide.image)"
                                        @keyup.enter="updateImageCaption(slide.image)"
                                    />
                                </div>
                            </td>
                            <td v-if="editable">
                                <button class="btn btn-sm btn-danger"
                                    @click="deleteSlideAtIndex(index)"
                                    v-tooltip='$t("Itinerary.general.remove_line")'
                                >
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </draggable>
                </table>
            </div>

            <div v-if="editable" class="col-12 text-right">
                <button class="btn btn-sm btn-primary" @click="addSlide">
                    {{$t("Itinerary.general.add_images")}}
                </button>
            </div>
        </div>
        <carousel-image-upload-modal
            :show.sync="showImageUpload"
            :carousel="item"
            @add-image="onAddImage($event)"
        >
        </carousel-image-upload-modal>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import { autosaveMixin, imageUrlMixin, moveItemMixin, itemCommonPropsMixin } from './item-mixins';
import CarouselImageUploadModal from '../modal/CarouselImageUploadModal';
import { CAROUSEL_ITEM_SLIDE } from '../itinerary-templates';

export default {
    components: {
        draggable,
        CarouselImageUploadModal
    },
    mixins: [
        autosaveMixin,
        imageUrlMixin,
        moveItemMixin,
        itemCommonPropsMixin
    ],
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showImageUpload: false
        }
    },
    methods: {
        addSlide() {
            this.showImageUpload = true;
        },
        onAddImage(event) {
            this.item.slides.push(CAROUSEL_ITEM_SLIDE(event.image));
            this.emitAutosave();
        },
        deleteSlideAtIndex(index) {
            this.item.slides.splice(index, 1);
            this.emitAutosave();
        },
    }
}
</script>
