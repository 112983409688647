<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <input type="text" class="name-input" v-model="item.title"/>
                </h3>
            </div>
        </div>
        <div class="row" v-if="itData.tripType !== 'GROUP_TOUR'">
            <div class="col-12" v-if="stringNotEmpty(item.adultPrice)">
                {{item.adultPrice}} {{itData.currencyCode}} {{$t("Itinerary.TripPrice.tooltip.per_person")}} ({{$t("Itinerary.TripPrice.tooltip.adult")}})
                <!--<div class="table-cell">
                    <input class="mor-editable-field"
                           type="text"
                           v-model="item.adultPrice"
                    />
                </div>
                <div class="table-cell">
                    {{itData.currencyCode}} per person (Adult)
                </div>-->
            </div>
            <div class="col-12" v-if="stringNotEmpty(item.childPrice)">
                {{item.childPrice}} {{itData.currencyCode}} {{$t("Itinerary.TripPrice.tooltip.per_person")}} ({{$t("Itinerary.TripPrice.tooltip.child")}})
                <!--<div class="table-cell">
                    <input class="mor-editable-field"
                           type="text"
                           v-model="item.childPrice"
                    />
                </div>
                <div class="table-cell">
                    {{itData.currencyCode}} per person (Child)
                </div>-->
            </div>
            <div class="col-12" v-if="stringNotEmpty(item.groupPrice)">
                {{item.groupPrice}} {{itData.currencyCode}} {{$t("Itinerary.TripPrice.tooltip.per_group")}}
                <!--<div class="table-cell">
                    <input class="mor-editable-field"
                           type="text"
                           v-model="item.groupPrice"
                    />
                </div>
                <div class="table-cell">
                    {{itData.currencyCode}} per group
                </div>-->
            </div>
            <div class="col-12" v-if="stringNotEmpty(item.singleSupplement)">
                {{item.singleSupplement}} {{itData.currencyCode}} {{$t("Itinerary.TripPrice.tooltip.single_supplement")}}
                <!--<div class="table-cell">
                    <input class="mor-editable-field"
                           type="text"
                           v-model="item.singleSupplement"
                    />
                </div>
                <div class="table-cell">
                    {{itData.currencyCode}} single supplement
                </div>-->
            </div>
        </div>
        <div class="row" v-if="itData.tripType === 'GROUP_TOUR'">
            <div class="col-12">
                <table class="table" style="margin-top: 10px;">
                    <thead class="thead-light">
                    <tr>
                        <th>
                            <input type="text"
                                   class="name-input"
                                   v-model="item.colNumPersonsTitle"
                            />
                        </th>
                        <th>
                            <input type="text"
                                   class="name-input"
                                   v-model="item.colPricePerPersonTitle"
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in item.tableLines">
                        <td>
                            <input type="text"
                                   class="name-input"
                                   v-model="line.numPersons"
                            />
                        </td>
                        <td>
                            <input type="text"
                                   class="name-input"
                                   v-model="line.price"
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row photos-row">
            <div class="col-6 photos-col"
                 v-for="image in item.images.slice(0,2)"
                 :key="image.id"
            >
                <img class="img-fluid w-100" :src="getImageUrl(image)">
                <div class="mor-image-caption" v-if="stringNotEmpty(image.caption)">
                    <input type="text"
                           v-model="image.caption"
                           class="mor-image-caption-field"
                           @blur="emitAutosave"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {imageUrlMixin, stringUtilsMixin} from "../../item/item-mixins";

    export default {
        components: {
        },
        mixins: [
            imageUrlMixin,
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            itData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
            }
        },
        watch: {
        },
        methods: {
        }
    }
</script>

<style scoped>
</style>
