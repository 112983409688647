<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft" v-if="droppable">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
                <button class="btn btn-danger"
                        v-if="droppable"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
<!--
            <br/>
            <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                <i class="fa fa-arrows-alt"></i>
            </button>
-->
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <input v-if="editable"
                           type="text"
                           class="name-input"
                           v-model="item.placesOfVisit"
                           @blur="emitAutosave"
                           :placeholder='$t("Itinerary.TripDestinationInfo.tooltip.places_to_visit")'
                           v-tooltip="'Click to edit'"
                    />
                    <span v-else>{{item.placesOfVisit ? cleanupString(item.placesOfVisit) : $t("Itinerary.TripDestinationInfo.tooltip.places_to_visit")}}</span>
                </h3>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12">
                <textarea-autosize
                        v-if="editable"
                        class="form-control body-font-size"
                        :placeholder='$t("Itinerary.TripDestinationInfo.tooltip.type_something_here")'
                        v-model="item.description"
                        :min-height="30"
                        :max-height="500"
                        @blur.native="emitAutosave"
                ></textarea-autosize>
                <span v-else v-html="cleanupString(item.description)"></span>
                <!--<textarea class="form-control" placeholder="Description"></textarea>-->
            </div>
        </div>
        <!-- <div class="row" style="margin-top: 20px;" v-if="status === 'SOLD'">
            <div class="col-12">
                <h3>
                    <input v-if="editable"
                           type="text"
                           class="name-input"
                           :placeholder='$t("Itinerary.TripDestinationInfo.tooltip.emergency_information")'
                           v-model="item.emergencyContactsTitle"
                           @blur="emitAutosave"/>
                    <span v-else>{{item.emergencyContactsTitle}}</span>
                </h3>
            </div>
            <div class="col-12">
                <div v-for="(contact, index) in item.emergencyContacts"
                     class="d-flex justify-content-start align-items-center">
                    <div class="bullet-left">
                        <i class="fa fa-circle"></i>
                    </div>
                    <div style="padding-left: 5px;min-width:250px;width:250px;">
                        <input v-if="editable"
                               class="bullet-item mor-editable-field"
                               :placeholder='$t("Itinerary.TripDestinationInfo.tooltip.name")'
                               :ref="'name' + index"
                               v-model="item.emergencyContacts[index].name"
                               @keydown.up.prevent="handleUp(index)"
                               @keydown.down.prevent="handleDown(index)"
                               @keydown.backspace="handleBackspace(contact, index, $event)"
                               @keydown.46="handleBackspace(contact, index, $event)"
                               @keydown.enter.prevent="handleEnter(index)"
                               @blur="emitAutosave"
                        />
                        <span v-else>{{item.emergencyContacts[index].name}}</span>
                    </div>
                    <div style="padding-left: 5px;min-width:200px;width:200px;">
                        <input v-if="editable"
                               class="bullet-item mor-editable-field"
                               :placeholder='$t("Itinerary.TripDestinationInfo.tooltip.phone")'
                               :ref="'phone' + index"
                               v-model="item.emergencyContacts[index].phone"
                               @keydown.up.prevent="handleUp(index)"
                               @keydown.down.prevent="handleDown(index)"
                               @keydown.backspace="handleBackspace(contact, index, $event)"
                               @keydown.46="handleBackspace(contact, index, $event)"
                               @keydown.enter.prevent="handleEnter(index)"
                               @blur="emitAutosave"
                        />
                        <span v-else>{{item.emergencyContacts[index].phone}}</span>
                    </div>
                    <div style="padding-left: 5px;width:100%;">
                        <input v-if="editable"
                               class="bullet-item mor-editable-field"
                               :placeholder='$t("Itinerary.TripDestinationInfo.tooltip.description")'
                               :ref="'desc' + index"
                               v-model="item.emergencyContacts[index].description"
                               @keydown.up.prevent="handleUp(index)"
                               @keydown.down.prevent="handleDown(index)"
                               @keydown.backspace="handleBackspace(contact, index, $event)"
                               @keydown.46="handleBackspace(contact, index, $event)"
                               @keydown.enter.prevent="handleEnter(index)"
                               @blur="emitAutosave"
                        />
                        <span v-else>{{item.emergencyContacts[index].description}}</span>
                    </div>
                </div>
            </div>
        </div> -->
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
        >
        </save-to-library-modal>
    </div>
</template>

<script>

    import {autosaveMixin, itemCommonPropsMixin, moveItemMixin, stringUtilsMixin} from "./item-mixins";
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";

    export default {
        components: {
            SaveToLibraryModal,
        },
        mixins: [
            autosaveMixin,
            moveItemMixin,
            itemCommonPropsMixin,
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            status: {
                type: String,
                required: false,
                default: function() { return "SOLD" },
            },
            droppable: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library")
                    ;
            },
        },
        data() {
            return {
                showSetNameModal: false,
            }
        },
        mounted() {
            // this.setupMap();
        },
        methods: {
            handleUp(index) {
                if (index > 0) {
                    this.emitAutosavNe();
                    this.$refs["name" + (index-1)][0].focus();
                }
            },
            handleDown(index) {
                if (index < this.item.emergencyContacts.length-1) {
                    this.emitAutosave();
                    this.$refs["name" + (index+1)][0].focus();
                }
            },
            handleBackspace(contact, index, event) {
                if ((!contact.name || contact.name.length === 0) &&
                    this.item.emergencyContacts.length > 1) {
                    // console.log("### DELETE " + index);
                    this.item.emergencyContacts.splice(index, 1);
                    this.emitAutosave();
                    if (index > 0) {
                        // console.log("### FOCUS " + (index-1));

                        this.$refs["name" + (index-1)][0].focus();
                    }
                    event.preventDefault();
                }
            },
            handleEnter(index) {
                this.emitAutosave();
                // console.log("### ENTER " + index);
                this.item.emergencyContacts.splice(index+1, 0, {
                    name: null,
                    phone: null,
                    description: null
                });
                this.$nextTick(() => {
                    this.$refs["name" + (index+1)][0].focus();
                });
            }
        }

    }
</script>

<style scoped>
</style>
