<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h1>{{$t("ClientImport.title")}}&nbsp;&nbsp;
                        <a :href="clientListUrl" class="btn btn-primary">
                            {{$t("ClientImport.go_back")}}
                        </a>
                    </h1>
                </div>
                <div class="card-body">
                    <div class="alert alert-primary" role="alert">
                        <b>{{$t("ClientImport.file_warning_part1")}}
                            <a :href="clientsTemplateFileUrl" target="_blank">{{$t("ClientImport.file_warning_part2")}}</a>
                        </b>
                    </div>
                    <div class="form-group">
                        <form :action="clientsUploadUrl" method="post" class="dropzone dz-clickable" ref="dropzone">
                        </form>
                    </div>
                    <h3 v-if="processing">
                        <span class="fa fa-spinner fa-2x fa-spin"></span> {{$t("ClientImport.processing")}}
                    </h3>
                    <h4 v-if="!processing && resultLog !== null">{{$t("ClientImport.results")}}</h4>
                    <div class="list-group" v-if="resultLog !== null && !processing" v-html="resultLog"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import "dropzone/dist/dropzone.css";
    import Dropzone from 'dropzone';
    import {calculateLang} from "../../main";

    export default {
        components: {
        },
        // props: ["uuid"],
        data() {
            return {
                processing: false,
                resultLog: null
            }
        },
        mounted() {
            this.setupDropzone();
        },
        computed: {
            clientListUrl() {
                return jsRoutes.controllers.ClientController.clients().url;
            },
            clientsTemplateFileUrl() {
                const lang = calculateLang();
                if (lang === 'en')
                    return jsRoutes.controllers.Assets.versioned('files/clients/clients-template-en.xlsx').url;
                else
                    return jsRoutes.controllers.Assets.versioned('files/clients/clients-template-ru.xlsx').url;
            },
            clientsUploadUrl() {
                return jsRoutes.controllers.ClientController.doImportClients().url;
            }
        },
        methods: {
            setupDropzone: function () {
                const self = this;
                this.dropzone = new Dropzone(this.$refs.dropzone, {
                    acceptedFiles: ".xlsx",

                    dictDefaultMessage: self.$t("Itinerary.general.click_or_drag_to_upload"),
                    dictRemoveFile: self.$t("ClientImport.dropzone_remove_file"),
                    dictInvalidFileType: self.$t("ClientImport.dropzone_invalid_file_type"),

                    paramName: "file", // The name that will be used to transfer the file
                    maxFilesize: 10, // MB
                    processing: function(file, serverResponse){
                        self.processing = true;
                    },
                    error: function(file, serverResponse){
                        self.processing = false;
                        if (!serverResponse.startsWith("<li")) {
                            serverResponse = "<li class='list-group-item list-group-item-danger'>" + serverResponse + "</li>";
                        }
                        self.resultLog = serverResponse;
                    },
                    success: function(file, serverResponse){
                        self.processing = false;
                        self.resultLog = serverResponse;
                    },
                    //MSGS
                });
                this.dropzoneSetup = true;
            },
        }
    }
</script>

<style scoped>

</style>
