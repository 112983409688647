import Vue from "vue";

export const chatEventBus = new Vue();

// window.$(document).ready(function(){
//     if(document.getElementById("chatSystem")) {
//         console.log("Loading sockets")
//         initControlWebSocket();
//     }
// })

var controlWs;
var pingIntervalId;
var buffer = [];

function initControlWebSocket() {
    var protocol = "";
    console.log("morego -Socket protocol working");
    if (window.location.protocol === "http:"){
        protocol = "ws";
    } else {
        protocol = "wss";
    }
    console.log("morego -Socket using protocol : " + protocol );
    console.log("morego -Socket Opening Socket with hostname : " + window.location.hostname );
    console.log("morego -Socket Opening Socket with port : " + window.location.port );

    var port = (window.location.port ? ':' + window.location.port: '');
    console.log("morego -Socket Opening Socket with port : " + port );

    controlWs = new WebSocket(protocol+"://"+window.location.hostname + port +"/chat/ws");
    controlWs.onopen = function () {
        console.log("morego -Socket has been opened");
        pingIntervalId = setInterval( wsImAlivePing , 20000 ); // every 20 secs notify that I'm alive
        if (buffer.length !== 0) {
            for ( var index in buffer) {
                controlWs.send( buffer[index] );
            }
            buffer.length = 0;
        }
    };
    controlWs.onmessage = handleCommunication;
    controlWs.onclose = function () {
        console.log("morego -Socket has been closed");
        wsImDeadPing();
        // TODO RECONNECT THE BASTARD !
        initControlWebSocket()
    };
    window.onbeforeunload = closeWS;
}

function wsImAlivePing() {
    controlWs.send(
        JSON.stringify({
            type: "IAMALIVEPING",
            message: ""
        })
    );
}

function wsImDeadPing() {
    clearInterval(pingIntervalId);
}

export function notifyReadChatMessages(chatMessageId) {
    chatEventBus.$emit('READ_CHAT_MESSAGE', chatMessageId);
    if (controlWs === undefined) {
        buffer.push(JSON.stringify({
                        type: "READ_CHAT_MESSAGE",
                        message: chatMessageId
                    })
        );
    } else {
        controlWs.send(
            JSON.stringify({
                type: "READ_CHAT_MESSAGE",
                message: chatMessageId
            })
        );
    }
}

export function requestMessagesForItinerary(chatMessageId) {
    if (controlWs === undefined) {
        buffer.push(JSON.stringify({
                        type: "ITINERARY_COMPLETE_CHAT",
                        message: chatMessageId
                    })
        );
    } else {
        controlWs.send(
            JSON.stringify({
                type: "ITINERARY_COMPLETE_CHAT",
                message: chatMessageId
            })
        );
    }
}

export function sendChatMessage(message, destid) {
    controlWs.send(
        JSON.stringify({
            type: "SEND_CHAT_MESSAGE",
            message: message,
            meta1: destid
        })
    );
}



















function handleCommunication(msgEvent) {
    //console.log("ws rec- " + JSON.stringify(msgEvent.data));
    var msg = JSON.parse(msgEvent.data);

    if (msg.type === 'CHAT') {
        console.log("CHAT: " + msgEvent.data);
        chatEventBus.$emit('CHAT', msg.item);
    } else if (msg.type === 'UPDATE_CHAT') {
        console.log("UPDATE_CHAT: " + msgEvent.data);
        chatEventBus.$emit('UPDATE_CHAT', msg.item);
    } else if (msg.type === 'COMPLETE_CHAT_FOR_ITINERARY') {
        console.log("COMPLETE_CHAT_FOR_ITINERARY: " + msgEvent.data);
        chatEventBus.$emit('COMPLETE_CHAT_FOR_ITINERARY', msg);
    } else
        console.log("ws rec2- " + msgEvent.data);

}


function closeWS() {
    controlWs.onclose = function () {
    }; // disable onclose handler first
    controlWs.close();
}

function generateJSONRequestMessage(op) {
    var msg = {
        type: "REQ",
        op: op
    };
    return JSON.stringify(msg);
}
