export function getPlaceTypeOptions() {
    let result = [
        {
            value: "accommodation",
            label: "Accommodation",
            icon: 'hotel'
        },
        {
            value: "restaurant",
            label: "Restaurant",
            icon: 'restaurant',
        },
        {
            value: "cafe",
            label: "Cafe",
            icon: 'local_cafe'
        },
        {
            value: "drink",
            label: "Drink",
            icon: 'local_bar'
        },
        {
            value: "sight",
            label: "Sight",
            icon: 'attractions'
        },
        {
            value: "meeting_point",
            label: "Meeting point or entry point",
            icon: 'tour'
        },
        {
            value: "starting_point",
            label: "Starting point",
            icon: 'play_circle'
        },
        {
            value: "finish_point",
            label: "Finish point",
            icon: 'stop_circle'
        },
        {
            value: "stop_along_route",
            label: "Stop along route",
            icon: 'pin_drop'
        },
        {
            value: "other",
            label: "Other point",
            icon: 'share_location'
        }
    ];
    return result;
}