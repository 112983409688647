<template>
    <div class="modal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t("Itinerary.modal.CoverImageUpload.set_cover_photo")}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link"
                               :class="{'active' : activeTab === 'pixabay'}"
                               href="#"
                               @click.stop.prevent="setActiveTab('pixabay')"
                            >{{$t("Itinerary.modal.ImageUpload.pixabay_free")}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"
                               :class="{'active' : activeTab === 'library'}"
                               href="#"
                               @click.stop.prevent="setActiveTab('library')"
                            >{{$t("Itinerary.modal.ImageUpload.library_photos")}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"
                               :class="{'active' : activeTab === 'upload'}"
                               href="#"
                               @click.stop.prevent="setActiveTab('upload')"
                            >{{$t("Itinerary.modal.ImageUpload.upload")}}</a>
                        </li>
                    </ul>
                    <div class="tab-content mt-2">
                        <div class="tab-pane fade" :class="{'show active': activeTab === 'pixabay'}" role="tabpanel" aria-labelledby="pixabay-tab">
                            <div class="d-flex">
                                <input :placeholder='$t("Itinerary.modal.ImageUpload.search_free_photos")'
                                       class="form-control"
                                       style="width:100%;"
                                       type="text"
                                       v-model="pixabay.queryText"
                                       @keyup.enter="doPixabaySearch(true)"
                                />
                                <v-button @click.native="doPixabaySearch(true)"
                                          class="btn btn-primary ml-2"
                                          style="width:40px;"
                                          :loading.sync="pixabay.loading"
                                >
                                    <i class="fa fa-search"></i>
                                </v-button>
                            </div>
                            <div class="container-fluid" style="margin-top: 10px;" v-if="pixabay.showResults && pixabay.pixabayData !== null">
                                <div class="row">
                                    <div class="col-12">
                                        <h3>
                                            {{$t("Itinerary.modal.ImageUpload.photos_by")}} <a href="https://pixabay.com?utm_source=morego&utm_medium=referral&utm_campaign=api" target="_blank">
                                            {{$t("Itinerary.modal.ImageUpload.pixabay")}}
                                        </a>
                                        </h3>
                                    </div>
                                    <div class="col-12" v-if="pixabay.pixabayData.length === 0 && pixabay.totalPages === 0">
                                        <div class="alert alert-warning">
                                            {{$t("Itinerary.modal.ImageUpload.no_photos_found")}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="pixabay.pixabayData.length > 0">
                                    <div
                                            class="col-4 pixabay-img mt-2 mb-2"
                                            v-for="photo in pixabay.pixabayData"
                                            :key="photo.id"
                                            @click="addPixabayPhoto(photo)"
                                            v-tooltip='$t("Itinerary.modal.ImageUpload.click_to_add")'
                                    >
                                        <img class="img-fluid"
                                             :src="pixabayPhotoUrl(photo)"
                                             :alt="photo.tags"/>
                                        <div class="mor-image-caption">
                                            {{photo.tags}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="pixabay.currentPage < pixabay.totalPages">
                                    <div class="col-12">
                                        <v-button class="btn btn-primary btn-block" @click.native="pixabayLoadMore" :loading.sync="pixabay.loading">
                                            {{$t("Itinerary.modal.ImageUpload.load_more")}}</v-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" :class="{'show active': activeTab === 'library'}" role="tabpanel" aria-labelledby="library-tab">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="control-label" >{{$t("Itinerary.modal.ImageUpload.tags")}}</label>
                                        <el-select
                                                style="width:100%;color:black;font-size: 1rem;"
                                                v-model="tags"
                                                multiple
                                                default-first-option
                                                filterable
                                                allow-create
                                                :placeholder='$t("Itinerary.modal.ImageUpload.select_tags_or_add")'
                                        >
                                            <el-option v-for="tag in tagList"
                                                       :key="tag.id"
                                                       :label="tag.name"
                                                       :value="tag.id"
                                            ></el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="col-5">
                                    <div class="form-group">
                                        <label class="control-label" >{{$t("Itinerary.modal.ImageUpload.caption_location")}}</label>
                                        <input :placeholder='$t("Itinerary.modal.ImageUpload.caption_location")'
                                               type="text"
                                               style="width:100%;"
                                               class="form-control"
                                               v-model.trim="library.filters.name"
                                               @keyup.enter="loadLibraryList(true)"
                                        >
                                    </div>
                                </div>
                                <div class="col-1">
                                    <v-button @click.native="loadLibraryList(true)"
                                              class="btn btn-primary ml-2"
                                              style="position:absolute;bottom:0;margin-bottom: 1rem;width:40px;"
                                              :loading="library.loading"
                                    >
                                        <i class="fa fa-search"></i>
                                    </v-button>
                                </div>
                            </div>
                            <div class="container-fluid mt-2" v-if="library.showResults && library.list !== null">
                                <div class="row">
                                    <div class="col-12" v-if="library.list.length === 0 && library.lastCount === 0">
                                        <div class="alert alert-warning">
                                            {{$t("Itinerary.modal.ImageUpload.no_photos_found")}}
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 mt-2 mb-2 library-img"
                                         v-for="image in library.list"
                                         :key="image.id"
                                         @click="addLibraryPhoto(image)"
                                         v-tooltip='$t("Itinerary.modal.ImageUpload.click_to_add")'
                                    >
                                        <img class="img-fluid" style="width:100%;" :src="libraryGetImageUrl(image)">
                                        <div class="mor-image-caption">
                                            {{image.details.caption}}
                                        </div>
                                        <!--<div class="mor-image-caption">
                                            <input type="text"
                                                   v-model="image.details.caption"
                                                   class="mor-image-caption-field"
                                                   placeholder="Write image caption here"
                                            />
                                        </div>-->
                                    </div>
                                    <div class="col-12" v-if="library.lastCount > 0 && library.list.length > 0">
                                        <v-button class="btn btn-primary btn-lg btn-block" @click.native="libraryLoadMore" :loading.sync="library.loading">
                                            {{$t("Itinerary.modal.ImageUpload.load_more")}}</v-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" :class="{'show active': activeTab === 'upload'}" role="tabpanel" aria-labelledby="library-tab">
                            <div class="form-group">
                                <label class="control-label" >{{$t("Itinerary.modal.ImageUpload.tags")}}</label>
                                <el-select
                                        style="width:100%;color:black;font-size: 1rem;"
                                        v-model="tags"
                                        multiple
                                        default-first-option
                                        filterable
                                        allow-create
                                        :placeholder='$t("Itinerary.modal.ImageUpload.select_tags_or_add")'
                                >
                                    <el-option v-for="tag in tagList"
                                               :key="tag.id"
                                               :label="tag.name"
                                               :value="tag.id"
                                    ></el-option>
                                </el-select>
                            </div>
                            <div class="form-group">
                                <form :action="imageUploadUrl" method="post" class="dropzone dz-clickable" ref="dropzone">
                                </form>
                            </div>
                        </div>
                    </div>
                    <!--<div v-show="!isUpload">
                        <div class="form-row">
                            <div class="col-10">
                                <input placeholder="Search free high-resolution photos"
                                       class="form-control"
                                       type="text"
                                       v-model="queryText"
                                       @keyup.enter="doPixabaySearch(true)"
                                />
                            </div>
                            <div class="col-2">
                                <v-button @click.native="doPixabaySearch(true)"
                                          class="btn btn-primary"
                                          :loading.sync="loading"
                                >
                                    <i class="fa fa-search"></i>
                                </v-button>
                            </div>
                        </div>
                        <div class="container-fluid" style="margin-top: 10px;" v-if="showResults && pixabayData !== null">
                            <div class="row">
                                <div class="col-12">
                                    <h3>
                                        Photos by <a href="https://pixabay.com?utm_source=morego&utm_medium=referral&utm_campaign=api" target="_blank">Pixabay</a>
                                    </h3>
                                </div>
                                <div class="col-12" v-if="pixabayData.length === 0 && totalPages === 0">
                                    <div class="alert alert-warning">
                                        No photos found
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="pixabayData.length > 0">
                                <div
                                        class="col-4 pixabay-img"
                                        v-for="photo in pixabayData"
                                        :key="photo.id"
                                        @click="addPixabayPhoto(photo)"
                                        v-tooltip="'Click to add'"
                                >
                                    <img class="img-fluid"
                                         :src="pixabayPhotoUrl(photo)"
                                         :alt="photo.tags"/>
                                    <div class="mor-image-caption">
                                        {{photo.tags}}
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="currentPage < totalPages">
                                <div class="col-12">
                                    <v-button class="btn btn-primary btn-block" @click.native="loadMore" :loading.sync="loading">Load more</v-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-horizontal" v-show="isUpload">
                        <div class="form-group">
                            <form :action="imageUploadUrl" method="post" class="dropzone dz-clickable" ref="dropzone">
                            </form>
                        </div>
                    </div>
                    <button style="margin-top: 10px;" class="mx-auto btn btn-primary" @click="isUpload = !isUpload">
                        {{ isUpload ? 'Search photos instead' : 'Upload your photos instead'}}
                    </button>-->
                </div>
                <div class="modal-footer">
                    <button class="btn btn-link" @click="close">{{$t("Itinerary.modal.CoverImageUpload.close")}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vButton from '../../../components/VButton';
    import "dropzone/dist/dropzone.css";
    import Dropzone from 'dropzone';
    import {handleAjaxError, showError, showSuccess, showWarning} from "../../../utils";
    import {autosaveMixin} from "../item/item-mixins";
    import axios from 'axios';

    export default {
        components: {
            vButton,
        },
        mixins: [
            autosaveMixin,
        ],
        props: {
            show: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                activeTab: "pixabay",

                pixabay: {
                    loading: false,

                    queryText: null,
                    perPage: 9,
                    currentPage: 1,
                    totalPages: 1,
                    showResults: false,
                    pixabayData: [],
                },

                library: {
                    filters: {
                        name: null,
                        tags: [],
                        status: 1,
                        page: 0,
                    },

                    loading: false,
                    lastCount: 99, // numero de resultados da ultima pesquisa

                    showResults: false,
                    list: [],
                },

                //isUpload: false,
                dropzoneSetup: false,

                tags: [],
                tagList: [],

                /*loading: false,
                queryText: null,

                isUpload: false,
                dropzoneSetup: false,

                perPage: 9,
                currentPage: 1,
                totalPages: 1,
                showResults: false,
                pixabayData: [],*/
            }
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function (e) {
                self.$emit('update:show', false);
            });

        },
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                    this.clearSearchData();
                    this.loadTags();
                    this.tags = this.itData.countries;

                    this.pixabayClearSearchData();
                    this.libraryClearSearchData();
                    if (!this.dropzoneSetup) {
                        this.setupDropzone();
                    } else {
                        this.dropzone.removeAllFiles();
                    }
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
            },
            isUpload() {
                if (this.isUpload) {
                    this.clearSearchData();
                }
            }
        },
        computed: {
            itData() {
                return this.$store.getters.itData;
            },
            imageUploadUrl() {
                // itinId deve ser uma variavel disponibilizada pelo play na view
                return jsRoutes.controllers.ItineraryController.imageUpload().url;
            }
        },
        methods: {
            setActiveTab(tab) {
                this.activeTab = tab;
            },
            close() {
                this.$emit('update:show', false);
            },
            setupDropzone: function () {
                const self = this;
                this.dropzone = new Dropzone(this.$refs.dropzone, {
                    paramName: "file", // The name that will be used to transfer the file
                    maxFilesize: 10, // MB
                    maxFiles: 1, // MB
                    //autoProcessQueue: false,
                    //uploadMultiple: true,
                    //maxFiles: 1,
                    //addRemoveLinks: true,
                    /*accept: function (file, done) {
                     //se done() for chamado com argumento, o ficheiro n é submetido
                     done();
                     },*/
                    init: function() {
                        this.hiddenFileInput.removeAttribute('multiple');
                        this.on("maxfilesexceeded", function(file) {
                            this.removeAllFiles();
                            this.addFile(file);
                        });
                    },
                    sending: function(file, xhr, data) {
                        data.append("tags", self.tags);
                    },
                    error: function(file, errorMessage, xhr){//if the error was due to the XMLHttpRequest the xhr object as third
                        showError(
                            self.$t("error.general_title"),
                            self.$t("error.general_failed_upload_file", {file: file.name, msg: errorMessage })
                        );
                        this.removeAllFiles();
                    },
                    success: function(file, response){
                        this.removeAllFiles();
                        self.$emit('set-image', {
                            id: response.id,
                            type: "LOCAL"
                        });
                        self.emitAutosave();
                        self.close();
                        showSuccess("Photo set");
                    },
                    /*queuecomplete: function(){
                        if (self.success) {
                            showSuccess("Sucesso", "Os ficheiros foram carregados");
                            self.cleanData();
                        }
                        //self.cleanData();
                    },*/

                    //MSGS
                    dictDefaultMessage: self.$t("Itinerary.general.click_or_drag_images_to_upload"),
                    // dictRemoveFile: "Remover ficheiro",
                    // dictMaxFilesExceeded: "Número de ficheiros excedido"
                });
                this.dropzoneSetup = true;
            },
            pixabayPhotoUrl(photo) {
                // return photo.previewURL;
                return photo.largeImageURL;
            },
            clearSearchData() {
                this.queryText = null;
                this.currentPage = 1;
                this.showResults = false;
                this.pixabayData = [];
            },
            loadMore() {
                this.currentPage++;
                this.doPixabaySearch(false);
            },
            pixabayClearSearchData() {
                this.pixabay.queryText = null;
                this.pixabay.currentPage = 1;
                this.pixabay.showResults = false;
                this.pixabay.pixabayData = [];
            },
            pixabayLoadMore() {
                this.pixabay.currentPage++;
                this.doPixabaySearch(false);
            },
            doPixabaySearch(clearResults) {
                if (this.pixabay.loading)
                    return;

                if (clearResults)
                    this.pixabay.currentPage = 1;

                const self = this;
                if (this.pixabay.queryText === null || this.pixabay.queryText.trim().length === 0) {
                    showWarning("Write something first...");
                } else {
                    this.pixabay.loading = true;
                    const url = jsRoutes.controllers.ItineraryController.doPixabaySearch().url;
                    const dataObj = {
                        query: this.pixabay.queryText,
                        page: this.pixabay.currentPage,
                        perPage: this.pixabay.perPage,
                    };
                    this.$http.post(url, dataObj)
                        .then(function (response) {
                            self.pixabay.loading = false;
                            self.pixabay.showResults = true;
                            if (clearResults) {
                                self.pixabay.pixabayData = response.data.hits;
                            } else {
                                self.pixabay.pixabayData = self.pixabay.pixabayData.concat(response.data.hits);
                            }
                            self.pixabay.totalPages = response.data.totalHits / self.pixabay.perPage;
                            //console.log("### RESPONSE => " + JSON.stringify(response));
                        })
                        .catch(function (error) {
                            self.pixabay.loading = false;
                            // handle error
                            handleAjaxError(error);
                        });
                }
            },
            addPixabayPhoto(photo) {
                const self = this;

                let tagList = photo.tags.split(",").concat(self.tags);

                const url = jsRoutes.controllers.LibraryController.pixabayImageSave().url;
                const dataObj = {
                    externalId: photo.id,
                    type: "PIXABAY",
                    pageURL: photo.pageURL,
                    caption: photo.tags,
                    tags: tagList,
                    downloadURL: photo.largeImageURL,
                };
                this.$http.post(url, dataObj)
                    .then(function (response) {
                        self.$emit('set-image', {
                            id: response.data.id,
                            externalId: photo.id,
                            type: "PIXABAY",
                        });
                        self.emitAutosave();
                        self.close();
                        showSuccess("Photo set");
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },
            libraryClearSearchData() {
                this.library.filters = {
                    name: null,
                    tags: [],
                    status: 1,
                    page: 0,
                };
                this.library.lastCount = 99;
                this.library.showResults = false;
                this.library.list = [];
            },
            libraryGetImageUrl(image) {
                // var quality esta na view twirl
                return jsRoutes.controllers.ItineraryController.imageDownload(image.id, quality).url
            },
            libraryLoadMore() {
                this.library.filters.page++;
                this.loadLibraryList(false);
            },
            loadLibraryList(clearResults) {
                if (this.library.loading)
                    return;

                if (clearResults) {
                    this.library.filters.page = 0;
                }

                this.library.filters.tags = this.tags;

                const self = this;
                this.library.loading = true;
                const url = jsRoutes.controllers.LibraryController.searchPhotos().url;

                // GET request
                axios.post(url, this.library.filters).then(function (response) {
                    self.library.loading = false;
                    self.library.showResults = true;
                    // set data
                    response.data.forEach(item => {
                        if (!item.details) {
                            item.details = {
                                caption: null,
                            }
                        }
                    });
                    if (clearResults) {
                        self.library.list = response.data;
                    } else {
                        self.library.list = self.library.list.concat(response.data);
                    }
                    self.library.lastCount = response.data.length;
                }).catch(function (error) {
                    self.library.loading = false;
                    // handle error
                    handleAjaxError(error);
                });
            },
            addLibraryPhoto(photo) {
                this.$emit('set-image', {
                    id: photo.id,
                    type: "PIXABAY",
                });
                this.emitAutosave();
                this.close();
                showSuccess("Photo set");
            },
            loadTags() {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.tagListSelect().url;
                this.$http.get(url)
                    .then(function (response) {
                        self.tagList = response.data;
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            }

        }
    }
</script>

<style scoped>
    .pixabay-img {
        padding-bottom: 15px;
        padding-top: 15px;
    }

    .pixabay-img:hover {
        cursor: pointer;
        opacity: .8;
    }

</style>
