<template>
    <div v-if="!loading && showChat">
        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="chatDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="fa fa-envelope fa-lg"></span>
                <span v-show="counter>0" class="badge badge-pill badge-danger">{{counter}}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="chatDropdown">
                <a v-bind:key="message.id" v-for="message in messages" href="#" class="dropdown-item list-group-item list-group-item-action flex-column align-items-start">
                    <div class="d-flex w-200 justify-content-between">
                      <h5 class="mb-1">{{message.itineraryName}}</h5>
                      <small>{{message.messageDate}}</small>
                    </div>
                    <p class="mb-1">{{message.message}}</p>
                    <small>{{message.fromName}}</small>
                </a>
            </div>
        </li>
    </div>
</template>
<script>
    // import axios from 'axios';
    import { chatEventBus } from '../../chat';
    // import {handleAjaxError, showCustomDialog, showError, showSuccess} from "../../utils";
    // import { required, email, maxLength, numeric, url } from 'vuelidate/lib/validators';
    // import vButton from '../../components/VButton';
    // import PictureInput from 'vue-picture-input'

    export default {
        components: {
        },
        data () {
            return {
                loading: true,
                showChat : false,
                counter: 0,
                messages: []
            }
        },
        mounted () {
            let self = this;
            jsRoutes.controllers.AdministrationController.verifyShowChat().ajax({
                success : function(data) {
                    self.loading = false;
                    self.showChat = data ;
                    if (data) {
                       // console.log("SHOW CHAT IS ON!")
                       self.setupCommunications()
                   } else {
                       // console.log("SHOW CHAT IS OFF!")
                   }
                },
                error : function(error) {
                }
            });
        },
        beforeDestroy() {
            if (this.showChat) {
                chatEventBus.$off("CHAT");
                chatEventBus.$off("READ_CHAT_MESSAGE");
            }
        },
        computed: {
            /*indexUrl() {
                return jsRoutes.controllers.Application.index().url;
            }*/
        },
        methods: {
            setupCommunications () {
                var self = this;
                chatEventBus.$on("CHAT", (message) => {
                    self.$nextTick(() => {
                        self.addMessage(message);
                    });
                });
                chatEventBus.$on("READ_CHAT_MESSAGE", (messageid) => {
                    self.$nextTick(() => {
                        for (var index in self.messages){
                            if (self.messages[index].id === messageid) {
                               self.removeMessage(index);
                            }
                        }
                    });
                });
            },
            addMessage (message) {
                this.counter = this.counter + 1;
                this.messages.push(message);
            },
            removeMessage (index) {
                this.counter = self.counter - 1;
                this.messages.splice(index, 1);
            }
        }
    }
</script>

<style scoped>

</style>