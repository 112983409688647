<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft" v-if="droppable">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{ section.title }}
                        </button>
                    </div>
                </div>
                <button class="btn btn-danger"
                        v-if="droppable"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'
                >
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    {{$t("Itinerary.modal.BuilderAddItem.ratings")}}
                </h3>
            </div>
        </div>
        <draggable class="row" style="margin-top: 10px;"
                   v-model="item.ratings"
                   :options="{animation:150, disabled: !editable}"
                   @change="emitAutosave"
        >
            <div class="col-12" style="margin-top:10px; margin-bottom: 10px;"
                 v-for="rating in item.ratings"
                 :key="rating.id"
            >   
                <div class="table-cell" v-if="editable">
                    <select
                        v-model="rating.name"
                        class="form-control"
                        @change="emitAutosave"
                        :readonly="!editable || (rating.name === 'manual' && manualOnly)"
                    >
                        <template v-for="type in types">
                            <option
                                :key="rating.id + type.name"
                                v-if="type.name === rating.name || !used[type.name]"
                                :value="type.name"
                            >{{ type.title }}</option>
                        </template>
                    </select>
                </div>
                <div class="table-cell" v-else>
                    {{ (rating.name === 'manual' ? rating.manual : (types.find(n => n.name === rating.name) || {}).title) || 'n/a' }}
                </div>
                <div class="table-cell pl-2" v-if="editable && rating.name === 'manual'">
                    <input
                        :readonly="!editable"
                        type="text"
                        placeholder="Label"
                        v-model="rating.manual"
                        @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell pl-2">
                    <v-rating
                        v-model="rating.value"
                        @input="emitAutosave"
                        :editable="editable"
                    ></v-rating>
                </div>
                <div class="table-cell pl-2" v-if="editable">
                    <div class="table-cell pr-2">
                        <span class="text-danger" @click="dropRating(rating)" style="cursor: pointer;">
                            <i class="fa fa-times"></i>
                        </span>
                    </div>
                </div>
            </div>
        </draggable>
        <div v-if="editable" class="row d-print-none" style="margin-top: 10px;">
            <div class="col-12">
                <button style="margin-top: 10px;" class="btn btn-primary" @click="addRating()">
                    {{$t("Itinerary.general.add_rating")}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import {autosaveMixin, itemCommonPropsMixin, moveItemMixin, stringUtilsMixin} from "./item-mixins";
    import { itineraryEventBus } from '../../../event-bus';
    import VRating from "../../../components/VRating.vue";
    import { TRIP_RATINGS_ITEM_VALUE } from '../itinerary-templates';



    export default {
        components: {
            draggable,
            VRating
        },
        mixins: [
            autosaveMixin,
            moveItemMixin,
            itemCommonPropsMixin,
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            droppable: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                types: [
                    { name: 'child_friendly', title: 'Child friendly' },
                    { name: 'instagrammable', title: 'Instagrammable' },
                    { name: 'manual', title: 'Manual value' }
                ]
            }
        },
        computed: {
            used() {
                const ratings = this.item.ratings;
                if (!ratings)
                    return {};
                return ratings.reduce((res, item) => {
                    if(item.name !== 'manual')
                        res[item.name] = true;
                    return res;
                }, {});
            },
            available() {
                const used = this.used;
                if (!used)
                    return [];
                return this.types.filter(item => !used[item.name])
            },
            manualOnly() {
                const available = this.available || [];
                return available.length <= 1;
            }
        },
        methods: {
            addRating() {
                this.item.ratings.push(TRIP_RATINGS_ITEM_VALUE(this.available[0].name));
                this.emitAutosave();
            },
            dropRating(rating) {
                const newItems = this.item.ratings.filter(item => item.id !== rating.id);
                this.item.ratings.splice(0);
                this.item.ratings.push(...newItems);
                this.emitAutosave();
            }
        }
    }
</script>
