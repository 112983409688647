<template>
    <div class="row">
        <div :class="{ 'col-10': chatOpen, 'col-12': !chatOpen }" class="mx-auto">
            <div class="card">
                <div class="card-header">
                    <h1 class="d-flex align-items-center">
                        <span v-if="!itData.template" style="">{{$t("Itinerary.builder.detail.itinerary")}}:</span>
                        <span v-else style="width:450px;">{{$t("Itinerary.builder.detail.template_title")}}:</span>
                        <input v-if="!itData.readOnly" type="text" class="itin-name-input ml-2"
                               v-model="nameTmp"
                               @blur="updateName"
                               @keyup.enter="updateName"
                               v-tooltip='$t("Itinerary.builder.detail.click_edit")'
                        />
                        <span v-else class="ml-2">{{itData.name}}</span>
                        <i v-show="saveLoading" class="fa fa-spin fa-spinner"></i>
                    </h1>
                    <div class="row">
                        <div class="col-6">
                            <h4 v-if="!itData.template">
                                {{$t("Itinerary.builder.detail.status")}}:
                                <div class="btn-group">
                                    <button :disabled="itData.readOnly"
                                            class="btn btn-primary dropdown-toggle"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            v-tooltip='$t("Itinerary.builder.detail.status_change")'
                                    >
                                        {{ itData.statusName == 'Sold'? $t("Itinerary.builder.detail.sold") : itData.statusName}}
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <button class="dropdown-item"
                                                :class="{'disabled': itData.status === 'DRAFT'}"
                                                @click="setStatus('DRAFT')"
                                        >
                                            {{$t("Itinerary.builder.detail.draft")}}
                                        </button>
                                        <button class="dropdown-item"
                                                :class="{'disabled': itData.status === 'SOLD'}"
                                                @click="setStatus('SOLD')"
                                        >
                                            {{$t("Itinerary.builder.detail.sold")}}
                                        </button>
                                    </div>
                                </div>
                            </h4>
                        </div><div class="col-6" style="text-align: right;">
                            <h4>
                                <i class="fa fa-link"></i> <a
                                    :href="`https://app.morego.travel/preview/${itData.id}`"
                                    target="_blank"
                                >
                                    https://app.morego.travel/preview/{{ itData.id }}
                                </a>
                            </h4>
                        </div>
                    </div>
			    <!-- <h4 v-if="!itData.template">{{itData.startDate}} - {{itData.endDate}}</h4> -->

                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                           <!-- <div class="dropdown" style="display: inline;">
                                <v-button class="btn btn-primary dropdown-toggle"
                                          type="button"
                                          id="printDropdownMenu"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                          :loading="printLoading"
                                >
                                    {{$t("Itinerary.builder.detail.export_pdf")}}
                                </v-button>
                                <div class="dropdown-menu" aria-labelledby="printDropdownMenu">
                                    <button class="dropdown-item" type="button" @click="printItinerary('FULL')">
                                        {{$t("Itinerary.builder.detail.export_pdf_full")}}
                                    </button>
                                    <button class="dropdown-item" type="button" @click="printItinerary('MEDIUM')">
                                        {{$t("Itinerary.builder.detail.export_pdf_medium")}}
                                    </button>
                                    <button class="dropdown-item" type="button" @click="printItinerary('LOW')">
                                        {{$t("Itinerary.builder.detail.export_pdf_low")}}
                                    </button>
                                </div>
                            </div> -->
                            <div class="dropdown" style="display: inline;">
                                <button class="btn btn-primary dropdown-toggle"
                                        type="button"
                                        id="copyDropdownMenu"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        v-tooltip="$t('Itinerary.builder.detail.create_copy_tooltip')"
                                >
                                    {{$t("Itinerary.builder.detail.create_copy_btn")}}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="copyDropdownMenu">
                                    <button class="dropdown-item" type="button" @click="copyToTemplate">
                                        {{$t("Itinerary.builder.detail.copy_to_template_btn")}}
                                    </button>
                                    <button class="dropdown-item" type="button" @click="copyToItinerary">
                                        {{$t("Itinerary.builder.detail.copy_to_itinerary_btn")}}
                                    </button>
                                </div>
                            </div>
                            <!--<button @click="createCopy"
                                    class="btn btn-primary"
                                    style="margin-left: 5px;"
                            >
                                <i class="fas fa-copy"></i>
                                {{$t("Itinerary.builder.detail.create_copy")}}
                            </button>-->

                            <!--<v-button @click.native="printItinerary" :loading="printLoading" class="btn btn-primary">
                                <i class="fa fa-file-pdf"></i> Export to PDF
                            </v-button>-->
                            <!--<button :disabled="itData.readOnly"
                                    @click="showEmailModal = true"
                                    class="btn btn-primary"
                                    style="margin-left: 5px;">
                                <i class="fa fa-envelope"></i>
                                {{$t("Itinerary.builder.detail.send_by_email")}}
                            </button>-->
                        </div>
                        <div class="row justify-content-end">


                            <!-- <div class="col-auto">
                                <v-button v-show="itData.tripData.showInterestButtons" class="btn btn-outline-success"
                                          :loading="publishLoading"
                                          @click="itData.tripData.showInterestButtons=false; emitAutosave();"
                                          :disabled="!isManager"
                                          v-tooltip="$t('Itinerary.builder.detail.showInterestButtonToolTip')"
                                >
                                    <i class="fa fa-check"></i> {{ $t("Itinerary.builder.detail.showInterestButton") }}
                                </v-button>
                                <v-button v-show="!itData.tripData.showInterestButtons" class="btn btn-outline-danger"
                                          :loading="publishLoading"
                                          @click="itData.tripData.showInterestButtons=true; emitAutosave();"
                                          :disabled="!isManager"
                                          v-tooltip="$t('Itinerary.builder.detail.showInterestButtonNotToolTip')"
                                >
                                    {{ $t("Itinerary.builder.detail.showInterestButtonNot") }}
                                </v-button>
                            </div> -->

                            <div class="col-auto">
                                <v-button v-if="itData.published" class="btn btn-outline-success"
                                          :loading="publishLoading"
                                          @click.native="updatePublishedStatus(false)"
                                          :disabled="!isManager"
                                          v-tooltip="$t('Itinerary.builder.detail.published_tooltip')"
                                >
                                    <i class="fa fa-check"></i> {{ $t("Itinerary.builder.detail.published") }}
                                </v-button>
                                <v-button v-else class="btn btn-outline-danger"
                                          :loading="publishLoading"
                                          @click.native="updatePublishedStatus(true)"
                                          :disabled="!isManager"
                                          v-tooltip="$t('Itinerary.builder.detail.notpublished_tooltip')"
                                >
                                    {{ $t("Itinerary.builder.detail.notpublished") }}
                                </v-button>
                            </div>

                            <div class="col-auto">
                                <button v-if="userAdmin"
                                        class="btn btn-outline-primary"
                                        v-tooltip="$t('Itinerary.builder.detail.change_manager_tooltip')"
                                        @click="showChangeManagerModal = true"
                                >
                                    <i class="fa fa-user"></i> {{$t("Itinerary.builder.detail.manager")}}:
                                    {{itData.managerName}}
                                </button>
                                <div v-else>
                                    <i class="fa fa-user"></i> {{$t("Itinerary.builder.detail.manager")}}:
                                    {{itData.managerName}}
                                </div>
                            </div>
                        </div>
                        <button v-show="showChat" type="button"
                                class="btn btn-warning btn-lg ml-2"
                                v-on:click="chatOpen=!chatOpen">
                            <i class="fa fa-comments"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body" v-if="loading">
                    <i class="fa fa-3x fa-spin fa-spinner"></i>
                </div>
                <div class="card-body" v-else>
                    <div class="alert alert-warning" v-if="!tripVisibleMobile">
                        {{$t("Itinerary.builder.detail.not_visible_mobile")}}
                    </div>
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link"
                               :class="{'active' : activeTab === 'settings'}"
                               href="#"
                               @click.stop.prevent="setActiveTab('settings')"
                            >{{$t("Itinerary.builder.detail.settings")}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"
                               :class="{'active' : activeTab === 'docs'}"
                               href="#"
                               @click.stop.prevent="setActiveTab('docs')"
                            >{{$t("Itinerary.builder.detail.documentation")}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"
                               :class="{'active' : activeTab === 'preview'}"
                               href="#"
                               @click.stop.prevent="activeTab = 'preview'"
                            >{{$t("Itinerary.builder.detail.trip")}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"
                               :class="{'active' : activeTab === 'map'}"
                               href="#"
                               @click.stop.prevent="setMapActive"
                            >{{$t("Itinerary.builder.detail.map")}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"
                               :class="{'active' : activeTab === 'gpx'}"
                               href="#"
                               @click.stop.prevent="activeTab = 'gpx'"
                            >{{$t("Itinerary.builder.detail.gpxroutes")}}</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade" :class="{'show active': activeTab === 'settings'}" role="tabpanel"
                             aria-labelledby="settings-tab">
                            <itinerary-settings :save-loading="saveLoading"
                                                @update-itinerary="updateItinerary"></itinerary-settings>

                            <!--<v-button :loading="saveLoading" @click.native="updateItinerary" class="btn btn-primary float-right"><i class="fa fa-save" aria-hidden="true"></i>&nbsp;Update</v-button>-->
                        </div>
                        <div class="tab-pane fade" :class="{'show active': activeTab === 'docs'}" role="tabpanel"
                             aria-labelledby="docs-tab">

                            <itinerary-documents></itinerary-documents>

                        </div>
                        <div class="tab-pane fade" :class="{'show active': activeTab === 'preview'}" role="tabpanel"
                             aria-labelledby="preview-tab">
                            <itinerary-builder
                                    :it-data.sync="itData"
                                    :undo-index="undoStepIndex"
                                    :history-size="historyData.length"
                                    @undo="undoStep"
                                    @redo="redoStep"
                                    @update-itinerary="updateItinerary($event)">

                            </itinerary-builder>
                            <div class="float-right" style="padding-top: 5px;">
                                <!--Last save: {{itData.updateDate}}-->
                                <v-button v-if="!itData.readOnly" :loading="saveLoading" @click.native="updateItinerary"
                                          class="btn btn-primary btn-lg">
                                    <i class="fa fa-save" aria-hidden="true"></i>&nbsp;
                                    {{$t("Itinerary.builder.detail.save")}}
                                    ({{$t("Itinerary.builder.detail.last_saved")}}
                                    {{formatedUpdateDate}})
                                </v-button>
                            </div>
                            <!--<v-button :loading="loading" @click.native="updateItinerary" class="btn btn-primary btn-lg btn-block">
                                <i class="fa fa-save" aria-hidden="true"></i>&nbsp;Save (Last saved {{itData.updateDate}})
                            </v-button>-->
                        </div>
                        <div class="tab-pane fade show" v-show="activeTab === 'map'"
                             :class="{'active': activeTab === 'map'}" role="tabpanel" aria-labelledby="map-tab">
                            <itinerary-map
                                    :show="activeTab === 'map'"
                            >
                            </itinerary-map>
                        </div>
                        <div class="tab-pane fade show" v-show="activeTab === 'gpx'"
                             :class="{'active': activeTab === 'gpx'}" role="tabpanel" aria-labelledby="gpx-tab">
                            <itinerary-gpx-routes :show="activeTab === 'gpx'">
                            </itinerary-gpx-routes>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="chatOpen" :class="{ 'col-2': chatOpen }"></div>
        <div v-show="chatOpen" class="col-2 mx-auto"
             style="position: fixed; background-color: white; top: 52.5px; bottom: 0px; right: 0px;  z-index: 100000; border-left: lightgray 1px solid; border-top: lightgray 1px solid">
            <itinerary-chat></itinerary-chat>
        </div>
        <change-manager-modal :show.sync="showChangeManagerModal" @reload="loadDetail"></change-manager-modal>
        <itinerary-send-email-modal :show.sync="showEmailModal" :it-data="itData"></itinerary-send-email-modal>
    </div>
</template>

<script>
    import moment from 'moment';

    import {itinerary_i18n} from "../../main"
    import {copyData, handleAjaxError, showSuccess} from "../../utils";
    import {maxLength, required} from 'vuelidate/lib/validators';
    import isEqual from 'lodash/isEqual';

    import vButton from '../../components/VButton';
    import ItinerarySettings from './ItinerarySettings';
    import ItineraryBuilder from './ItineraryBuilder';
    import ItineraryDocuments from './ItineraryDocuments';
    import ItineraryChat from './ItineraryChat';
    import ItineraryMap from "./ItineraryMap";
    import ItineraryGpxRoutes from "./gpx/ItineraryGpxRoutes";

    import {itineraryEventBus} from '../../event-bus';
    import ItinerarySendEmailModal from "./modal/ItinerarySendEmailModal";
    import {
        SECTION_TYPE_COVER,
        upgradeItineraryToVersion2
    } from "./itinerary-templates";
    import {formatDate, parseMoment} from "../../date-utils";
    import {mapActions} from 'vuex';
    import ChangeManagerModal from "./modal/ChangeManagerModal";
    import {imageUrlMixin, autosaveMixin, moveItemMixin, itemCommonPropsMixin, stringUtilsMixin} from "./item/item-mixins";

    export default {
        components: {
            ChangeManagerModal,
            ItinerarySendEmailModal,
            vButton,
            ItinerarySettings,
            ItineraryBuilder,
            ItineraryDocuments,
            ItineraryChat,
            ItineraryMap,
            ItineraryGpxRoutes,
        },
        mixins: [
            autosaveMixin,
        ],
        // props: ["uuid"],
        data() {
            return {
                id: null,

                userAdmin: false, // para verificar se user actual pode fazer assign do manager

                loading: true,
                publishLoading: false,

                nameTmp: "",

                historyData: [],
                hasChanges: false,

                clientList: [],

                // showClientModal: false,
                showEmailModal: false,

                startDateObj: null,
                endDateObj: null,

                saveLoading: false,
                printLoading: false,

                activeTab: "preview",

                undoStepIndex: -1,

                chatOpen: false,
                loadingChat: true,
                showChat: false,

                autosaveQueue: [],
                autosaveSeq: 0,
                autosaving: false,

                showChangeManagerModal: false,
            }
        },
        validations: {
            itData: {
                name: {
                    required,
                    maxLength: maxLength(255)
                }
            }
        },
        mounted() {
            // eslint-disable-next-line
            this.id = itinId; // itinId esta na view twirl
            this.loadDetail();
            this.loadClientList();
            itineraryEventBus.$on('autosave', () => {
                this.autosave(false);
            });
            itineraryEventBus.$on('forcesave', () => {
                this.autosave(true);
            });
            let self = this;
            // eslint-disable-next-line
            jsRoutes.controllers.AdministrationController.verifyShowChat().ajax({
                success: function (data) {
                    self.loadingChat = false;
                    self.showChat = data;
                },
                error: function (/*error*/) {
                }
            });
            window.addEventListener('beforeunload', this.beforeWindowUnload);
        },
        beforeDestroy() {
            itineraryEventBus.$off('autosave');
            itineraryEventBus.$off('forcesave');
            window.removeEventListener('beforeunload', this.beforeWindowUnload)
        },
        beforeRouteLeave(to, from, next) {
            if (this.hasChanges && window.confirm("Do you really want to leave?")) {
                next(false)
            } else {
                next()
            }
        },
        computed: {
            itData() {
                return this.$store.getters.itData;
            },
            itinerariesUrl() {
                // eslint-disable-next-line
                return jsRoutes.controllers.ItineraryController.itineraries().url;
            },
            formatedUpdateDate() {
                return formatDate(new Date(this.itData.updateDate), this.itData.dateFormat + " HH:mm:ss")
            },
            tripVisibleMobile() {
                if (this.itData.template) {
                    return true;
                }
                return this.itData.status === 'SOLD';
            },
            isManager(){
              return this.itData.loggedManager
            },

        },
        methods: {
            ...mapActions({
                setStoreItData: 'setItData',
                updatePublished: 'setPublishedStatus',
            }),
            copyToTemplate() {
                window.location.href = jsRoutes.controllers.ItineraryController.createTemplate(this.itData.id).url;
            },
            copyToItinerary() {
                window.location.href = jsRoutes.controllers.ItineraryController.createItinerary(this.itData.id).url;
            },
            setStatus(status) {
                this.itData.status = status;
                if (status === 'DRAFT') {
                    this.itData.statusName = this.$t("Itinerary.builder.detail.draft");
                } else if (status === 'SOLD') {
                    this.itData.statusName = this.$t("Itinerary.builder.detail.sold");
                }
                this.autosave(true);
            },
            setActiveTab(tab) {
                this.activeTab = tab;
            },
            setMapActive() {
                this.activeTab = 'map';
                // this.$nextTick(() => {
                //     this.setupMap();
                // });
            },
            loadClientList() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ItineraryController.clientListSelect().url;
                this.$http.get(url).then(function (response) {
                    self.clientList = response.data;
                }, function (error) {
                    handleAjaxError(error);
                });

            },
            loadDetail() {
                const self = this;
                this.loading = true;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ItineraryController.loadItineraryDetails(this.id).url;
                this.$http.get(url).then(function (response) {
                    self.loading = false;
                    if (!response.data.tripData.version) {
                        self.setStoreItData(upgradeItineraryToVersion2(response.data));
                    } else {
                        self.setStoreItData(response.data);
                    }
                    //self.itData = response.data;
                    self.userAdmin = response.data.userAdmin;
                    self.nameTmp = response.data.name;
                    self.historyData.push(copyData(self.itData.tripData));
                    self.$nextTick(() => {
                        itineraryEventBus.$emit("details-loaded");
                    });
                    // Setting Itineraty Locale for Creating new Items
                    itinerary_i18n.locale = self.itData.languageCode;
                }, function (error) {
                    self.loading = false;
                    handleAjaxError(error);
                });
            },
            autosave(force) {
                if (!this.itData.readOnly) {
                    const self = this;
                    const save = !isEqual(self.itData.tripData, self.historyData[self.historyData.length - 1]);
                    if (save || force) {
                        self.historyData.push(copyData(self.itData.tripData));
                        while (self.historyData.length > 5) {
                            //se history for maior que 5, remover adicionais
                            self.historyData.shift();
                        }
                        this.undoStepIndex = self.historyData.length - 1;
                        // this.doUpdateItinerary(false);
                        this.hasChanges = true;
                    }
                }
            },
            undoStep() {
                if (this.undoStepIndex > 0) {
                    this.undoStepIndex--;
                    this.itData.tripData = copyData(this.historyData[this.undoStepIndex]);
                    // console.log("### EMIT FORCE UPDATE");

                    itineraryEventBus.$emit("forceupdate");
                    // this.doUpdateItinerary(false);
                }
            },
            redoStep() {
                if (this.undoStepIndex < this.historyData.length) {
                    this.undoStepIndex++;
                    this.itData.tripData = copyData(this.historyData[this.undoStepIndex]);
                    itineraryEventBus.$emit("forceupdate");
                    // this.doUpdateItinerary(false);
                }
            },
            updateName: function () {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if (this.itData.name !== this.nameTmp) {
                    this.doUpdateName();
                }
            },
            doUpdateName: function () {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ItineraryController.updateItineraryName().url;

                this.saveLoading = true;
                const dataObj = {
                    id: this.itData.id,
                    name: this.nameTmp
                };
                this.$http.post(url, dataObj)
                    .then(function (/*response*/) {
                        self.saveLoading = false;
                        self.itData.name = self.nameTmp;
                        self.$v.$reset();
                        showSuccess(
                            self.$t("Itinerary.builder.detail.name_updated")
                        );
                    })
                    .catch(function (error) {
                        self.nameTmp = self.itData.name;
                        self.saveLoading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },
            updateItinerary: function () {
                // validate e' false para casos em que seccoes sao criadas automaticamente ao mudar datas, por exemplo
                this.$v.$touch();
                if (this.$v.$invalid) {
                    console.log("### INVALID");
                    return;
                }
                this.doUpdateItinerary(true);
            },
            doUpdateItinerary(showSuccessMsg) {
                this.hasChanges = false;
                this.autosaveSeq++;
                this.autosaveQueue.push({
                    showSuccess: showSuccessMsg,
                    data: copyData(this.itData),
                    seq: this.autosaveSeq
                });
                if (!this.autosaving) {
                    this.processAutosave();
                }
            },
            processAutosave() {
                //processa a queue de autosaves
                const self = this;
                this.autosaving = true;

                const queueObj = this.autosaveQueue[0];
                let showSuccessMsg = queueObj.showSuccess;
                let itData = queueObj.data;
                itData.updateUuid = this.itData.updateUuid;
                console.log("## doUpdateItinerary UUID=" + self.itData.updateUuid + " QUEUE SIZE=" + this.autosaveQueue.length);

                // eslint-disable-next-line
                const url = jsRoutes.controllers.ItineraryController.doEditItinerary().url;

                this.saveLoading = true;
                this.$http.post(url, itData)
                    .then(function (response) {
                        self.saveLoading = false;
                        self.$v.$reset();
                        self.itData.updateUuid = response.data.updateUuid;
                        self.itData.updateDate = response.data.updateDate;
                        self.autosaveQueue.splice(
                            self.autosaveQueue.findIndex(queueObjIter => {
                                return (queueObj.seq = queueObjIter.seq);
                            }),
                            1
                        );
                        if (showSuccessMsg) {
                            showSuccess(
                                self.$t("success.general_title"),
                                self.$t("Itinerary.builder.detail.iti_updated")
                            );
                        }
                        if (self.autosaveQueue.length > 0) {
                            self.processAutosave();
                        } else {
                            self.autosaving = false;
                        }
                    })
                    .catch(function (error) {
                        self.saveLoading = false;
                        // se falhar, remove o autosave da queue, senao fica em ciclo infinito
                        self.autosaveQueue.splice(
                            self.autosaveQueue.findIndex(queueObjIter => {
                                return (queueObj.seq = queueObjIter.seq);
                            }),
                            1
                        );
                        // handle error
                        handleAjaxError(error);
                        if (self.autosaveQueue.length > 0) {
                            self.processAutosave();
                        } else {
                            self.autosaving = false;
                        }
                    });
            },
            printItinerary(quality) {
                const self = this;

                const cover = this.itData.tripData.sections.find(section => {
                    return section.type === SECTION_TYPE_COVER;
                });

                const hasCover = cover !== undefined;

                // eslint-disable-next-line
                const url = jsRoutes.controllers.ItineraryController.pdfItinerary(this.id, hasCover, quality).url;

                this.printLoading = true;
                this.$http.get(url)
                    .then(function (response) {
                        self.printLoading = false;
                        // eslint-disable-next-line
                        window.location.href = jsRoutes.controllers.ItineraryController.tempPdfDownload(response.data.tempFilename, response.data.filename).url;
                        // console.log("RESPONSE => " + JSON.stringify(response.data));
                    })
                    .catch(function (error) {
                        self.printLoading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },

            updatePublishedStatus(published) {
                let self = this
                let url = jsRoutes.controllers.ItineraryController.itineraryPublishStatus().url;
                let value = {
                    id: this.itData.id,
                    published: published
                }
                this.publishLoading = true;

                this.$http.post(url, value)
                    .then(function (response) {
                        /*self.printLoading = false;
                        // eslint-disable-next-line
                        window.location.href = jsRoutes.controllers.ItineraryController.tempPdfDownload(response.data.tempFilename, response.data.filename).url;
                        // console.log("RESPONSE => " + JSON.stringify(response.data));*/
                        self.publishLoading = false;
                        self.updatePublished(published)
                    })
                    .catch(function (error) {
                        self.publishLoading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },

            /**
             * @param {Event} e
             */
            beforeWindowUnload(e) {
                if (this.hasChanges) {
                    e.preventDefault();
                    // Included for legacy support, e.g. Chrome/Edge < 119
                    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
                    e.returnValue = true;
                }
            }

        }
    }
</script>

<style scoped>
    .itin-name-input {
        position: relative;
        line-height: normal;
        transition: none 0s ease 0s;
        background: transparent !important;
        /*color:white;*/
        border: 1px solid transparent;
        border-radius: 5px;
        width: 100%;
    }

    .itin-name-input:hover {
        /*border: 1px solid #60d5ba;*/
        border: 1px solid black;
    }

    .itin-name-input:focus {
        /*border: 1px solid #60d5ba;*/
        border: 1px solid black;
        border-radius: 5px;
    }
</style>
