import { render, staticRenderFns } from "./BuilderAddItemStep2CarRental.vue?vue&type=template&id=97e262dc&scoped=true&"
import script from "./BuilderAddItemStep2CarRental.vue?vue&type=script&lang=js&"
export * from "./BuilderAddItemStep2CarRental.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97e262dc",
  null
  
)

export default component.exports