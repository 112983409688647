<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
                <button class="btn btn-danger"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <input :readonly="!editable"
                           type="text" class="name-input" v-model="item.title"
                           :placeholder='$t("Itinerary.TripGenericItem.tooltip.title")'
                           @blur="emitAutosave"/>
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div>
                    <textarea-autosize v-if="editable"
                            class="form-control body-font-size"
                            :placeholder='$t("Itinerary.TripGenericItem.tooltip.details_placeholder")'
                            v-model="item.details"
                            :min-height="30"
                            :max-height="500"
                            @blur.native="emitAutosave"
                    ></textarea-autosize>
                    <span v-else v-html="cleanupString(item.details)"></span>
                </div>
            </div>
        </div>
        <div class="row" v-if="item.bullets">
            <div class="col-12 mt-2">
                <div v-for="(bullet, index) in item.bullets">
                    <div class="bullet-left table-cell">
                        <i class="fa fa-circle"></i>
                    </div>
                    <div class="bullet-right table-cell">
                        <input  :readonly="!editable"
                                class="bullet-item mor-editable-field"
                                :placeholder='$t("Itinerary.TripGenericItem.tooltip.bullet_placeholder")'
                                :ref="'input' + index"
                                v-model="item.bullets[index]"
                                @keydown.up.prevent="handleUp(index)"
                                @keydown.down.prevent="handleDown(index)"
                                @keydown.backspace="handleBackspace(bullet, index, $event)"
                                @keydown.46="handleBackspace(bullet, index, $event)"
                                @keydown.enter.prevent="handleEnter(index)"
                                @blur="emitAutosave"
                        />
                    </div>
                </div>
            </div>
        </div>
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
        >
        </save-to-library-modal>
    </div>
</template>

<script>
    import {autosaveMixin, itemCommonPropsMixin, moveItemMixin, stringUtilsMixin} from "./item-mixins";
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";

    export default {
        name: "TripGenericItem",
        components: {
            SaveToLibraryModal,
        },
        mixins: [
            autosaveMixin,
            moveItemMixin,
            itemCommonPropsMixin,
            stringUtilsMixin
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        computed: {
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library")
                    ;
            },
        },
        data() {
            return {
                showSetNameModal: false,
            }
        },
        methods: {
            handleUp(index) {
                if (index > 0) {
                    this.emitAutosave();
                    this.$refs["input" + (index-1)][0].focus();
                }
            },
            handleDown(index) {
                if (index < this.item.bullets.length-1) {
                    this.emitAutosave();
                    this.$refs["input" + (index+1)][0].focus();
                }
            },
            handleBackspace(bullet, index, event) {
                if (bullet.length === 0 && this.item.bullets.length > 1) {
                    // console.log("### DELETE " + index);
                    this.item.bullets.splice(index, 1);
                    this.emitAutosave();
                    if (index > 0) {
                        // console.log("### FOCUS " + (index-1));

                        this.$refs["input" + (index-1)][0].focus();
                    }
                    event.preventDefault();
                }
            },
            handleEnter(index) {
                this.emitAutosave();
                // console.log("### ENTER " + index);
                this.item.bullets.splice(index+1, 0, "");
                this.$nextTick(() => {
                    this.$refs["input" + (index+1)][0].focus();
                });
            },
        }
    }
</script>

<style scoped>

</style>
