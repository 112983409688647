<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="dropdown dropleft">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-danger"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
<!--
            <br/>
            <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                <i class="fa fa-arrows-alt"></i>
            </button>
-->
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <input :readonly="!editable" type="text" class="name-input"
                           v-model="item.title"
                           :placeholder='$t("Itinerary.TripClientInfo.tooltip.client_details")'
                           @blur="emitAutosave"

                    />
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="table-cell">
                    {{$t("Itinerary.TripClientInfo.tooltip.guests")}}
                </div>
                <div class="table-cell pl-2">
                    <input :readonly="!editable"
                           class="bullet-item mor-editable-field"
                           style="width:40px;"
                           type="number"
                           :placeholder='$t("Itinerary.TripClientInfo.tooltip.number_guests")'
                           v-model="item.pax"
                           @change="updateClientsSize(true)"
                    />
                </div>
                <div class="table-cell" v-if="editable">
                    <button class="btn btn-sm btn-primary" @click="decreaseClients" :disabled="item.pax === itData.clients.length">
                        <i class="fa fa-minus"></i>
                    </button>
                    <button class="btn btn-sm btn-primary ml-1" @click="increaseClients">
                        <i class="fa fa-plus"></i>
                    </button>
                </div>
            </div>
            <div class="col-12">
                <div v-for="(client, index) in item.clients">
                    <div class="bullet-left table-cell">
                        <i class="fa fa-circle"></i>
                    </div>
                    <div class="table-cell" style="padding-left: 5px;min-width:250px;">
                        <input  :readonly="!editable"
                                class="bullet-item mor-editable-field"
                                :placeholder='$t("Itinerary.TripClientInfo.tooltip.guest_name")'
                                :ref="'name' + index"
                                v-model="item.clients[index].name"
                                @keydown.up.prevent="handleNameUp(index)"
                                @keydown.down.prevent="handleNameDown(index)"
                                @blur="emitAutosave"
                        />
                    </div>
                    <div class="table-cell" style="padding-left: 5px;min-width:300px;">
                        <input  :readonly="!editable"
                                class="bullet-item mor-editable-field"
                                :placeholder='$t("Itinerary.TripClientInfo.tooltip.guest_email")'
                                :ref="'email' + index"
                                v-model="item.clients[index].email"
                                @keydown.up.prevent="handleEmailUp(index)"
                                @keydown.down.prevent="handleEmailDown(index)"
                                @blur="emitAutosave"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { itineraryEventBus } from '../../../event-bus';
    import {autosaveMixin, itemCommonPropsMixin, moveItemMixin} from "./item-mixins";
    import {handleAjaxError} from "../../../utils";

    export default {
        components: {
        },
        mixins: [
            autosaveMixin,
            moveItemMixin,
            itemCommonPropsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                // showButtons: false,
            }
        },
        watch: {
            /*"item.pax"() {
                this.updateNamesSize();
            }*/
        },
        mounted() {
            this.updateClientsSize(false);
            const self = this;
            itineraryEventBus.$on("forceupdate", () => {
                self.$nextTick(() => {
                    self.updateClientsSize(false);
                });
            });
        },
        beforeDestroy() {
            itineraryEventBus.$off("forceupdate");
        },
        computed: {
            itData() {
                return this.$store.getters.itData;
            },
        },
        methods: {
            decreaseClients() {
                this.item.pax--;
                this.updateClientsSize(true);
            },
            increaseClients() {
                this.item.pax++;
                this.updateClientsSize(true);
            },
            handleNameUp(index) {
                if (index > 0) {
                    this.$refs["name" + (index-1)][0].focus();
                }
            },
            handleNameDown(index) {
                if (index < this.item.clients.length-1) {
                    this.$refs["name" + (index+1)][0].focus();
                }
            },
            handleEmailUp(index) {
                if (index > 0) {
                    this.$refs["email" + (index-1)][0].focus();
                }
            },
            handleEmailDown(index) {
                if (index < this.item.clients.length-1) {
                    this.$refs["email" + (index+1)][0].focus();
                }
            },
            updateClientsSize(autoSave) {
                // if (this.item.pax <= 0) {
                //     this.item.pax = 1;
                // }
                if (this.item.pax < this.itData.clients.length) {
                    this.item.pax = this.itData.clients.length;
                }
                if (!this.item.clients) {
                    this.item.clients = [];
                }

                if (this.item.clients.length === 0 && this.itData.clients.length > 0) {
                    this.fillClientDetails();
                } else {
                    // console.log("### clients: "  + this.item.clients.length);
                    // console.log("### PAX: "  + this.item.pax);
                    if (this.item.clients.length > this.item.pax) {
                        while (this.item.clients.length > this.item.pax) {
                            this.item.clients.pop();
                        }
                    } else {
                        while (this.item.clients.length < this.item.pax) {
                            this.item.clients.push({});
                        }
                    }

                    if (autoSave) {
                        this.emitAutosave();
                    }
                }
            },
            fillClientDetails() {
                this.item.pax = this.itData.clients.length + 1;

                const self = this;
                const url = jsRoutes.controllers.ClientController.getClientList().url;

                // GET request
                axios.get(url).then(function (response) {
                    // set data
                    const clientList = response.data;
                    clientList.forEach(client => {
                        if (self.itData.clients.includes(client.id)) {
                            self.item.clients.push({
                                name: client.name,
                                email: client.email,
                                phone: client.phone,
                                locked: true,
                            });
                        }
                    });
                    self.item.clients.push({});
                }).catch(function (error) {
                    // handle error
                    handleAjaxError(error);
                });

            }
        }
    }
</script>

<style scoped>
</style>
