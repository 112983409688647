<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
<!--
            <button class="btn btn-light drag-handle" style="margin-right: 5px;" v-tooltip="'Click and drag to move'">
                <i class="fa fa-arrows-alt"></i>
            </button>
-->
            <button class="btn btn-danger" @click="$emit('delete-item', item)"
                    v-tooltip='$t("Itinerary.general.remove_item")'>
                <i class="fa fa-trash"></i>
            </button>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <div class="table-cell">
                        <i class="fa fa-h-square"></i> ({{$t("Itinerary.TripSleep.tooltip.night")}})
                    </div>
                    <div class="table-cell" style="padding-left: 10px;">
                        {{parentItem.name}}
                    </div>
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
    import {getParentItemById} from "../itinerary-templates";
    import {itemCommonPropsMixin} from "./item-mixins";

    export default {
        components: {
        },
        mixins: [
            itemCommonPropsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            sections: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                showImageUpload: false,
                // showButtons: false,
            }
        },
        computed: {
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library")
                    ;
            },
            parentItem() {
                return getParentItemById(this.sections, this.item.parentId);
            }
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>
