<template>
    <div class="container-fluid">
        <div v-if="withDates && !template" class="form-group" :class="{ 'has-error': dateRange === null}">
            <!--<div class="form-group">-->
            <label class="control-label mr-2">{{$t("Itinerary.modal.Step2Sleep.dates")}} *:</label>
            <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    :start-placeholder='$t("Itinerary.modal.Step2Sleep.start_date")'
                    :end-placeholder='$t("Itinerary.modal.Step2Sleep.end_date")'
                    :clearable="false"
                    :picker-options="pickerOptions"
                    :format="itData.dateFormat"
                    :default-value="defaultDate"
            >
            </el-date-picker>
            <input type="hidden" class="form-control"
                   :class="{'is-invalid' : dateRange === null}"/>
            <div class="invalid-feedback" v-show="dateRange === null">{{$t("Itinerary.modal.Step2Sleep.please_select")}}</div>
        </div>
        <div v-else-if="withDates && template" class="form-group" :class="{'has-error': dayRange === null}">
            <div class="col-11 mb-5">
                <el-slider
                        v-model="dayRange"
                        range
                        :min="1"
                        :max="this.itData.numDays"
                        :marks="dayRangeMarks">
                </el-slider>
            </div>
        </div>
        <div class="form-group" v-if="!item.libraryId">
            <el-switch
                    v-model="useAutocomplete"
                    :active-text='$t("Itinerary.modal.Step2Sleep.autocomplete_google")'
            ></el-switch>
        </div>
        <div v-show="useAutocomplete">
            <div class="form-group">
                <input ref="autocomplete"
                       :placeholder='$t("Itinerary.modal.Step2Sleep.type_to_search")'
                       class="form-control"
                       onfocus="value = ''"
                       type="text"/>
            </div>
            <div ref="map" class="map-container"></div>

            <div ref="infowindowcontent" class="infowindow-content">
                <img v-if="placeIcon" :src="placeIcon" width="16" height="16" class="mr-2">
                <a v-if="placeWebsite" :href="placeWebsite" target="_blank">{{placeName}}</a>
                <span v-else class="title">{{placeName}}</span>
                <br>
                <span>{{placeAddress}}</span>
            </div>
        </div>
        <div class="float-right">
            <button class="btn btn-link" @click="$emit('cancel')">{{$t("Itinerary.modal.Step2Sleep.cancel")}}</button>
            <v-button class="btn btn-primary" :loading="loading" style="margin-left: 5px;" @click="doAddItem">{{$t("Itinerary.modal.Step2Sleep.add")}}</v-button>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import {
        getAdminAreaNameFromGoogleAdr,
        getCountryNameFromGoogleAdr, getLocalityNameFromGoogleAdr,
        handleAjaxError,
    } from "../../../utils";
    import vButton from '../../../components/VButton';
    import {
        getDayNumForDate,
        getFirstItemByType,
        ITEM_TYPE_SLEEP,
        ITEM_TYPE_PLACE
    } from "../itinerary-templates";

    import {formatDate, parseMoment} from "../../../date-utils";

    export default {
        components: {
            vButton,
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
            withDates: { // se isto for true, entao datas teem de vir nos parametros
                type: Boolean,
                required: false,
                default: function() { return true }
            }
        },
        data() {
            const self = this;
            return {
                dateRange: [],
                pickerOptions: {
                    disabledDate(date) {
                        const result = !moment(date).isBetween(parseMoment(self.itData.startDate, self.itData.dateFormat), parseMoment(self.itData.endDate, self.itData.dateFormat), null, "[]");
                        // if (result)
                        //     console.log("#### RESULT FOR " + formatMoment(moment(date)) + " => " + result);
                        return result;
                    }
                },
                defaultDate: new Date(),

                dayRange: [],
                dayRangeMarks: {},

                useAutocomplete: !!(self.item && self.item.type === ITEM_TYPE_PLACE),
                mapSetupDone: false,

                place: null,

                loading: false,
            }
        },
        mounted() {
            this.dateRange = null;
            if (this.withDates) {
                if (this.template) {
                    const numDays = this.itData.numDays;
                    this.dayRangeMarks = {};
                    this.dayRange = [1, numDays];
                    let dayIdx = 1;
                    while (dayIdx <= numDays) {
                        this.dayRangeMarks[dayIdx] = this.$t("v2.Itinerary.defaults.DAY") + " " + dayIdx
                        dayIdx++;
                    }
                } else {
                    this.defaultDate = parseMoment(this.itData.startDate, this.itData.dateFormat).toDate();
                }
            }
            if (this.useAutocomplete) {
                this.$nextTick(() => {
                    if (!this.mapSetupDone) {
                        this.setupMap();
                    }
                })
            }
        },
        computed: {
            itData() {
                return this.$store.getters.itData;
            },
            template() {
                return this.itData.template
            },
            title() {
                if (this.item !== null && this.item.type === ITEM_TYPE_SLEEP) {
                    return this.$t("Itinerary.modal.Step2Sleep.add_hotel");
                }
                return this.$t("Itinerary.modal.Step2Sleep.add_item");
            },
            placeIcon() {
                if (this.place && this.place.icon) {
                    return this.place.icon;
                }
                return null;
            },
            placeAddress() {
                if (this.place) {
                    return this.place.formatted_address;
                }
                return "";
            },
            placeName() {
                if (this.place) {
                    return this.place.name;
                }
                return "";
            },
            placeWebsite() {
                if (this.place) {
                    return this.place.website;
                }
                return "";
            },
        },
        watch: {
            useAutocomplete() {
                if (!this.mapSetupDone) {
                    this.$nextTick(() => {
                        this.setupMap();
                    });
                } else {
                    this.$refs.autocomplete.value = "";
                }
            }
        },
        methods: {
            setupMap() {
                const self = this;
                let center = {
                    lat: -34.397,
                    lng: 150.644,
                };
                let map = new window.google.maps.Map(this.$refs.map, {
                    center: center,
                    zoom: 11,
                });

                let infowindow = new window.google.maps.InfoWindow();
                let infowindowContent = this.$refs.infowindowcontent;
                infowindow.setContent(infowindowContent);
                let marker = new window.google.maps.Marker({
                    map: map,
                    anchorPoint: new window.google.maps.Point(0, -29)
                });
                marker.addListener('click', function() {
                    infowindow.open(map, marker);
                });

                // Create the autocomplete object and associate it with the UI input control.
                // Restrict the search to the default country, and to place type "cities".
                let autocomplete = new window.google.maps.places.Autocomplete(
                    (this.$refs.autocomplete),
                    {
                        types: ['establishment'],
                        fields: [
                            "address_components",
                            "formatted_address",
                            "international_phone_number",
                            "geometry",
                            "icon",
                            "website",
                            "rating",
                            "id",
                            "place_id",
                            "url", // este nunca e' devolvido...tem de se usar o PlacesService.getDetails
                            "name",
                            "photos",
                        ]
                    }
                );
                autocomplete.addListener('place_changed', () => {
                    infowindow.close();
                    marker.setVisible(false);

                    self.place = autocomplete.getPlace();
                    // If the place has a geometry, then present it on a map.
                    if (self.place.geometry) {
                        if (self.place.geometry.viewport) {
                            map.fitBounds(self.place.geometry.viewport);
                        } else {
                            map.setCenter(self.place.geometry.location);
                            map.setZoom(17);  // Why 17? Because it looks good.
                        }
                        marker.setPosition(self.place.geometry.location);
                        marker.setVisible(true);
                        infowindow.open(map, marker);
                    }
                });

                this.mapSetupDone = true;
            },
            doAddItem() {
                const self = this;

                if (this.item.type === ITEM_TYPE_SLEEP) {
                    const firstItinSleepItem = getFirstItemByType(this.itData.tripData.sections, ITEM_TYPE_SLEEP);
                    if (firstItinSleepItem) {
                        this.item.rooms = firstItinSleepItem.rooms;
                        for (let typeR in firstItinSleepItem.roomTypes) {
                            this.item.roomTypes.push(firstItinSleepItem.roomTypes[typeR]);
                        }
                    }
                }

                if (this.withDates) {
                    if (this.template) {
                        this.item.startDay = this.dayRange[0];
                        this.item.endDay = this.dayRange[1];
                    } else {
                        try {
                            const startDate = formatDate(this.dateRange[0], this.itData.dateFormat);
                            const endDate = formatDate(this.dateRange[1], this.itData.dateFormat);

                            const startDay = getDayNumForDate(startDate, this.itData.startDate, this.itData.dateFormat);
                            const endDay = getDayNumForDate(endDate, this.itData.startDate, this.itData.dateFormat);

                            this.item.startDay = startDay;
                            this.item.endDay = endDay;

                        } catch (e) {
                            self.dateRange = null;
                            return;
                        }
                        if (!this.item.startDay || !this.item.endDay) {
                            self.dateRange = null;
                            return;
                        }
                    }
                }
                if (this.useAutocomplete && this.place) {
                    this.item.name = this.place.name;
                    if (this.place.formatted_address) {
                        this.item.address = this.place.formatted_address;
                    }
                    if (this.place.international_phone_number) {
                        this.item.phone = this.place.international_phone_number;
                    }
                    this.item.website = this.place.website;
                    this.item.coordinates = {
                        lat: this.place.geometry.location.lat(),
                        lng: this.place.geometry.location.lng(),
                    };
                    this.item.googleId = this.place.id;
                    this.item.googlePlacesId = this.place.place_id;
                    this.item.googleRating = this.place.rating;
                    /*this.place.photos.forEach((photo) => {
                        self.item.images.push({
                            id: generateUUID(),
                            type: 'GOOGLE',
                            url: photo.getUrl(),
                        });
                    });*/
                    let tags = [].concat(this.itData.countries);

                    const country = getCountryNameFromGoogleAdr(this.place.address_components);
                    const adminArea = getAdminAreaNameFromGoogleAdr(this.place.address_components);
                    const locality = getLocalityNameFromGoogleAdr(this.place.address_components);

                    if (country && !tags.includes(country))
                        tags.push(country);

                    if (adminArea && !tags.includes(adminArea))
                        tags.push(adminArea);

                    if (locality && !tags.includes(locality))
                        tags.push(locality);

                    if (!tags.includes(this.place.name))
                        tags.push(this.place.name);

                    this.item.tags = tags;

                    if (this.place.photos && this.place.photos.length > 0) {
                        let imgUrls = [];
                        this.place.photos.forEach((photo) => {
                            imgUrls.push(photo.getUrl());
                        });

                        const saveImagesObj = {
                            caption: this.item.name,
                            tags: tags,
                            urls: imgUrls,
                        };

                        // eslint-disable-next-line
                        const url = jsRoutes.controllers.LibraryController.googleImagesSave().url;

                        this.loading = true;
                        this.$http.post(url, saveImagesObj)
                            .then(function (response) {
                                self.item.images = [];
                                response.data.forEach(img => {
                                    self.item.images.push({
                                        id: img.id,
                                        type: "LOCAL",
                                        caption: self.item.name,
                                    });
                                });

                                self.loading = false;
                                self.$emit('add-item', self.item);
                            })
                            .catch(function (error) {
                                self.loading = false;
                                // handle error
                                handleAjaxError(error);
                            });
                    } else {
                        self.$emit('add-item', self.item);
                    }
                } else {
                    this.$emit('add-item', this.item);
                }

                /*this.$emit('update:show', false);*/
            },
        }
    }
</script>

<style scoped>
    .map-container {
        height: 400px;
        width: 100%;
    }

    .infowindow-content .title {
        font-weight: bold;
    }

    .infowindow-content {
        display: none;
    }

    .map-container .infowindow-content {
        display: inline;
    }
</style>
