<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
                <button class="btn btn-danger"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
<!--
            <br/>
            <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                <i class="fa fa-arrows-alt"></i>
            </button>
-->
        </div>
        <div class="row">
            <div class="col-12">
                <h3 class="text-center">
                    <input :readonly="!editable" type="text" class="name-input" v-model="item.title" @blur="emitAutosave"/>
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="table-cell">
                    {{$t("Itinerary.TripNextSteps.tooltip.deposit")}}
                </div>
                <div class="table-cell" style="padding-left:5px; width: 70px;">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           type="number"
                           placeholder="x %"
                           v-model="item.deposit.value"
                           @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell" style="padding-left:5px;">
                    %
                </div>
                <div class="table-cell bullet-right" style="padding-left:5px;">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripNextSteps.tooltip.when_to_be_paid")'
                           v-model="item.deposit.when"
                           @blur="emitAutosave"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="table-cell">
                    {{$t("Itinerary.TripNextSteps.tooltip.email")}}:
                </div>
                <div class="table-cell bullet-right" style="padding-left:5px;">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripNextSteps.tooltip.email")'
                           v-model="item.email"
                           @blur="emitAutosave"
                    />
                </div>
            </div>
            <div class="col-12">
                <div class="table-cell">
                    {{$t("Itinerary.TripNextSteps.tooltip.phone")}}:
                </div>
                <div class="table-cell bullet-right" style="padding-left:5px;">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripNextSteps.tooltip.phone")'
                           v-model="item.phone"
                           @blur="emitAutosave"
                    />
                </div>
            </div>
        </div>
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
        >
        </save-to-library-modal>
    </div>
</template>

<script>

    import {autosaveMixin, itemCommonPropsMixin, moveItemMixin} from "./item-mixins";
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";

    export default {
        components: {
            SaveToLibraryModal,
        },
        mixins: [
            autosaveMixin,
            moveItemMixin,
            itemCommonPropsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showSetNameModal: false,
                // showButtons: false,
            }
        },
        computed: {
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library")
                    ;
            },
        },
        methods: {
        }
    }
</script>

<style scoped>
</style>
