<template>
    <div>
        <div class="row mt-2">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <form @submit.prevent="loadList" novalidate>
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="itemName" class="control-label" >
                                            {{$t("Itinerary.builder.library.caption_location")}}
                                        </label>
                                        <input type="text" class="form-control" id="itemName" name="itemName" v-model.trim="filters.name">
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label class="control-label" >{{$t("Itinerary.builder.library.tags")}}:</label>
                                        <el-select
                                                style="width:100%;"
                                                v-model="filters.tags"
                                                multiple
                                                default-first-option
                                                filterable
                                                :placeholder='$t("Itinerary.builder.library.tag_select")'
                                        >
                                            <el-option v-for="tag in tagList"
                                                       :key="tag.id"
                                                       :label="tag.name"
                                                       :value="tag.id"
                                            ></el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="form-group">
                                        <label class="control-label" for="itemStatus">{{$t("Itinerary.builder.library.status")}}</label>
                                        <select name="itemStatus" id="itemStatus" v-model="filters.status" class="form-control">
                                            <option value="1">{{$t("Itinerary.builder.library.active")}}</option>
                                            <option value="0">{{$t("Itinerary.builder.library.inactive")}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <v-button type="submit"
                                              class="btn btn-primary"
                                              style="position:absolute;bottom:0;margin-bottom: 1rem;"
                                              :loading="loading"
                                    >
                                        <i class="fa fa-search"></i>
                                    </v-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-2" style="margin-top:10px; margin-bottom: 10px;"
                                 v-for="image in list"
                                 :key="image.id">
                                <img class="img-fluid w-100" :src="getImageUrl(image)">
                                <div class="mor-image-caption"
                                     v-tooltip='$t("Itinerary.builder.library.click_to_change_caption")'
                                >
                                    <input type="text"
                                           v-model="image.details.caption"
                                           class="mor-image-caption-field"
                                           :placeholder='$t("Itinerary.builder.library.write_caption_here")'
                                           @blur="updateImageCaption(image)"
                                           @keyup.enter="updateImageCaption(image)"
                                    />
                                </div>
                                <div class="mor-image-caption" v-tooltip='$t("Itinerary.builder.library.click_to_change_tags")'>
                                    <div class="d-flex">
                                        <el-select
                                                style="width:100%;"
                                                v-model="image.tags"
                                                multiple
                                                default-first-option
                                                filterable
                                                :placeholder='$t("Itinerary.builder.library.tag_select")'
                                                @blur="updateImageTags(image)"
                                                @change="updateImageTags(image)"
                                        >
                                            <el-option v-for="tag in tagList"
                                                       :key="tag.id"
                                                       :label="tag.name"
                                                       :value="tag.id"
                                            ></el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" v-if="lastCount > 0 && list.length > 0">
                                <v-button class="btn btn-primary btn-lg btn-block" @click.native="loadMore" :loading.sync="loading">
                                    {{$t("Itinerary.builder.library.load_more")}}
                                </v-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {handleAjaxError, showCustomDialog, showSuccess} from "../../utils";
    import vButton from '../../components/VButton';
    import { itineraryEventBus } from '../../event-bus';
    import {imageUrlMixin} from "../itinerary/item/item-mixins";

    export default {
        components: {
            vButton,
        },
        mixins: [
            imageUrlMixin,
        ],
        data() {
            return {
                filters: {
                    name: null,
                    tags: null,
                    status: 1,
                    page: 0,
                },

                tagList: [],

                loading: false,
                lastCount: 99, // numero de resultados da ultma pesquisa

                list: [],
            }
        },
        mounted() {
            this.loadTags();
            this.loadList();
        },
        computed: {
        },
        methods: {
            loadTags() {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.tagListSelect().url;
                this.$http.get(url)
                    .then(function (response) {
                        self.tagList = response.data;
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },
            loadMore() {
                this.filters.page++;
                this.loadList(false);
            },
            loadList(clearResults) {
                if (this.loading)
                    return;

                if (clearResults) {
                    this.filters.page = 0;
                }

                const self = this;
                this.loading = true;
                const url = jsRoutes.controllers.LibraryController.searchPhotos().url;

                // GET request
                axios.post(url, this.filters).then(function (response) {
                    self.loading = false;
                    // set data
                    response.data.forEach(item => {
                        if (!item.details) {
                            item.details = {
                                caption: null,
                            }
                        }
                    });
                    if (clearResults) {
                        self.list = response.data;
                    } else {
                        self.list = self.list.concat(response.data);
                    }
                    self.lastCount = response.data.length;
                }).catch(function (error) {
                    self.loading = false;
                    // handle error
                    handleAjaxError(error);
                });
            },
            updateImageTags(image) {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.updateImageTags().url;

                const dataObj = {
                    id: image.id,
                    tags: image.tags,
                }
                // GET request
                axios.post(url, dataObj).then(response => {
                    //TODO verificar se caption nao foi ja actualizada por outro user, perguntar se quer fazer overwrite
                    itineraryEventBus.$emit("autosave");
                    showSuccess("Image tags updated");
                }).catch(error => {
                    // TODO mostrar erro ao user?
                    // handle error
                    //handleAjaxError(error);
                });
            }
        }
    }
</script>

<style scoped>

</style>
