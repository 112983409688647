<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
                <button class="btn btn-danger"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
<!--
            <br/>
            <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                <i class="fa fa-arrows-alt"></i>
            </button>
-->
        </div>
        <div class="row">
            <div class="col-12">
                <h3 class="text-center">
                    <div class="table-cell">
                        <i class="fa fa-plane"></i>
                    </div>
                    <div class="table-cell" style="padding-left: 10px;">
                        <input :readonly="!editable" type="text" class="name-input" v-model="item.name" @blur="emitAutosave"/>
                    </div>
                </h3>
            </div>
        </div>
        <trip-flight-individual v-for="(flight, index) in item.list" :key="flight.id"
                                :flight="flight"
                                :index="index"
                                :editable="editable"
                                @delete-flight="deleteIndividualFlight($event)"
        >

        </trip-flight-individual>
        <div v-if="editable" class="row d-print-none" style="margin-top: 10px;">
            <div class="col-12 text-right">
                <button class="btn btn-primary" @click="addIndividualFlight">{{$t("v2.Itinerary.Flight.add_another_flight")}}</button>
            </div>
        </div>
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
        >
        </save-to-library-modal>
        <transportation-map-modal
                :show.sync="showMapModal"
                :location.sync="mapModalLocation"
        >
        </transportation-map-modal>
    </div>
</template>

<script>

    import {
        INDIVIDUAL_FLIGHT,
    } from "../itinerary-templates";
    import {autosaveMixin, itemCommonPropsMixin, moveItemMixin} from "./item-mixins";
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";
    import TransportationMapModal from "../modal/TransportationMapModal";
    import {copyData, handleAjaxError} from "../../../utils";
    import TripFlightIndividual from "./TripFlightIndividual";

    export default {
        components: {
            TripFlightIndividual,
            TransportationMapModal,
            SaveToLibraryModal
        },
        mixins: [
            autosaveMixin,
            moveItemMixin,
            itemCommonPropsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showSetNameModal: false,

                showMapModal: false,
                mapModalLocation: null,

                // airlineList: [],
            }
        },
        mounted() {
            // this.loadAirlines();
            // this.loadAirports();
        },
        computed: {
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library");
            },
        },
        methods: {
            openMapModal(itemLocation) {
                this.mapModalLocation = itemLocation;
                this.showMapModal = true;
            },
            mapBtnTooltip(location) {
                if (location.lat && location.lng && location.address) {
                    return location.address + " (" + this.$t("Itinerary.general.show_on_map") + ")";
                } else {
                    return this.$t("Itinerary.TripTransportation.tooltip.set_address_on_map");
                }
            },
            loadAirlines() {
                const self = this;
                const url = jsRoutes.controllers.ItineraryController.airlineListSelect().url;
                this.$http.get(url)
                    .then(function (response) {
                        self.airlineList = response.data;
                    })
                    .catch(function (error) {
                        // handle error
                        handleAjaxError(error);
                    });

            },
            addIndividualFlight() {
                let lastFlight = this.item.list[this.item.list.length -1];
                let newFlight = INDIVIDUAL_FLIGHT();
                // copy stuff from last flight to save user from filling it
                newFlight.airline = lastFlight.airline;
                newFlight.flightClass = lastFlight.flightClass;
                newFlight.startLocation = copyData(lastFlight.endLocation);
                this.item.list.push(newFlight);
            },
            deleteIndividualFlight(index) {
                this.item.list.splice(index, 1);
                this.$emit("update:item", this.item);
                this.emitAutosave();
            },
            /*loadAirports() {
                const self = this;
                const url = jsRoutes.controllers.ItineraryController.airportListSelect().url;
                this.$http.get(url)
                    .then(function (response) {
                        self.airportList = response.data;
                    })
                    .catch(function (error) {
                        // handle error
                        handleAjaxError(error);
                    });

            },*/
        }
    }
</script>

<style scoped>
</style>
