import moment from 'moment';

export const SECTION_DATE_FORMAT = 'DD-MM-YYYY';

export function parseMoment(dateStr, format) {
    const tmp = format.replace(/d|y/g, function(l){ return l.toUpperCase() });
    return moment(dateStr,tmp);
}

export function formatMoment(moment, format) {
    const tmp = format.replace(/d|y/g, function(l){ return l.toUpperCase() });
    return moment.format(tmp);
}

export function formatDate(date, format) {
    const tmp = format.replace(/d|y/g, function(l){ return l.toUpperCase() });
    return moment(date).format(tmp);
}

export function formatToWeekDay(date) {
    return date.format("dddd");
}


export function getDateFormatOptions() {
    let result = [
        {
            value: "dd-MM-yyyy",
            label: "",
        },
        {
            value: "dd/MM/yyyy",
            label: "",
        },
        {
            value: "dd.MM.yyyy",
            label: "",
        },
        {
            value: "MM-dd-yyyy",
            label: "",
        },
        {
            value: "MM/dd/yyyy",
            label: "",
        },
        {
            value: "MM.dd.yyyy",
            label: "",
        },
        {
            value: "yyyy-MM-dd",
            label: "",
        },
        {
            value: "yyyy/MM/dd",
            label: "",
        },
        {
            value: "yyyy.MM.dd",
            label: "",
        },
    ];
    result.forEach(obj => {
        obj.label = obj.value + " (" + formatDate(new Date(), obj.value) + ")";
    });
    return result;

}


/**
 * devolve moment (obj com data) para numero de dia e data de inicio
 *
 * @param dayNum numero de dia a obter data
 * @param startDate data de inicio
 * @param dateFormat formato de data
 */
export function getDateMomentForDayNum(dayNum, startDate, dateFormat) {
    let startMoment = parseMoment(startDate, dateFormat);

    return startMoment.add(dayNum-1, 'days');

}
