<template>
    <div class="modal" id="reorderSectionItemsModal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t("Itinerary.modal.ReorderSectionItems.title")}} - {{section ? section.title : ""}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="alert alert-info">
                                    {{$t("Itinerary.modal.ReorderSectionItems.reorder")}}
                                </div>
                                <ul class="list-group" v-if="section">
                                    <draggable v-model="section.items"
                                               :options="{animation:150}"
                                               @update="emitAutosave"
                                    >
                                        <li class="list-group-item section-item"
                                            v-for="item in section.items"
                                            :key="item.id">
                                            {{itemNameOrTitle(item)}}
                                        </li>
                                    </draggable>
                                </ul>
                            </div>
                        </div>
                        <div class="float-right">
                            <button class="btn btn-link" @click="close">{{$t("Itinerary.modal.ReorderSectionItems.close")}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vButton from '../../../components/VButton';
    import draggable from 'vuedraggable'
    import { getItemNameOrTitle } from "../itinerary-templates";
    import {autosaveMixin} from "../item/item-mixins";

    export default {
        components: {
            vButton,
            draggable,
        },
        mixins: [
            autosaveMixin,
        ],
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            section: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loading: false,

            }
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function (e) {
                self.close();
            })
        },
        computed: {
        },
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
            }
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            itemNameOrTitle(item) {
                return getItemNameOrTitle(item);
            }
        }
    }
</script>

<style scoped>
    .section-item {
        cursor:move;
    }

    .section-item:hover {
        background-color: #f8f9fa;
    }
</style>
