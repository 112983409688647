<template>
    <div class="modal" id="builderAddSleepAltModal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t("Itinerary.modal.SleepAddAlternative.title")}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <h2>{{$t("Itinerary.modal.SleepAddAlternative.add_from_library")}}</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="control-label" >{{$t("Itinerary.modal.SleepAddAlternative.tags")}}:</label>
                                    <el-select
                                            style="width:100%;color:black;font-size: 1rem;"
                                            v-model="tags"
                                            multiple
                                            default-first-option
                                            filterable
                                            :placeholder='$t("Itinerary.modal.SleepAddAlternative.select_tags")'
                                            @change="loadLibraryItems"
                                    >
                                        <el-option v-for="tag in tagList"
                                                   :key="tag.id"
                                                   :label="tag.name"
                                                   :value="tag.id"
                                        ></el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="col-12">
                                <el-select v-model="selectedItemId"
                                           default-first-option
                                           filterable
                                           autocomplete
                                           :placeholder='$t("Itinerary.modal.SleepAddAlternative.type_to_search")'
                                           style="width:100%;color:black;font-size: 1rem;"
                                           ref="itemselect"
                                           @change="addLibraryItem"
                                >
                                    <el-option
                                            v-for="item in libraryList"
                                            :key="item.id"
                                            :label="getItemName(item)"
                                            :value="item.id">
                                        <i class="fa" :class="[itemClass(item)]"></i> {{getItemName(item)}}
                                    </el-option>
                                </el-select>
                                <!--<div class="list-group">
                                    <div class="list-group-item list-group-item-action"
                                         v-for="item in libraryList"
                                         :key="item.id"
                                         @click="addLibraryItem(item)"
                                    >
                                        <i class="fa fa-h-square"></i> {{item.data.name}}
                                    </div>
                                </div>-->
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-12">
                                <h2>{{$t("Itinerary.modal.SleepAddAlternative.create_from_scratch")}}</h2>
                            </div>
                            <div class="col-12">
                                <builder-add-item-step2-dates-map
                                        v-if="show"
                                        :item="item"
                                        :with-dates="false"
                                        @add-item="doAddItem($event)"
                                        @cancel="close"
                                >
                                </builder-add-item-step2-dates-map>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {generateUUID, handleAjaxError} from "../../../utils";
    import vButton from '../../../components/VButton';
    import {
        getItemNameOrTitle, ITEM_TYPE_SLEEP, SLEEP_ITEM
    } from "../itinerary-templates";

    import axios from "axios";
    import BuilderAddItemStep2DatesMap from "./BuilderAddItemStep2DatesMap";



    export default {
        components: {
            vButton,
            BuilderAddItemStep2DatesMap,
        },
        props: {
            show: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {

                loading: false,

                libraryList: [],

                selectedItemId: null,
                item: {},

                tags: [],

                tagList: [],
            }
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function (e) {
                self.$emit('update:show', false);
            })
        },
        computed: {
            title() {
                return this.$t("Itinerary.modal.SleepAddAlternative.title");
            },
            itData() {
                return this.$store.getters.itData;
            },

        },
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                    this.selectedItemId = null;
                    this.item = SLEEP_ITEM();
                    this.tags = this.itData.countries;
                    this.loadTags();
                    this.loadLibraryItems();
                    // this.$refs.itemselect.focus();
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
            }
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            getItemName(item) {
                let result = item.name;
                if (!result) {
                    result = getItemNameOrTitle(item.data);
                }
                return result;
            },
            itemClass() {
                return "fa-h-square";
            },
            addLibraryItem() {
                const self = this;
                const libraryItem = this.libraryList.find(item => {
                    return item.id === self.selectedItemId;
                });
                let item = JSON.parse(JSON.stringify(libraryItem.data));
                item.id = generateUUID();
                item.libraryId = libraryItem.id;

                //this.item = item;
                this.doAddItem(item);
            },
            doAddItem(item) {
                this.$emit('add', item);
                this.$emit('update:show', false);
            },
            loadLibraryItems() {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.getLibraryItemListSelect().url;

                const searchData = {
                    name: null,
                    type: ITEM_TYPE_SLEEP,
                    status: 1,
                    tags: this.tags,
                };

                axios.post(url, searchData)
                    .then(function (response) {
                    // set data
                    self.libraryList = response.data;
                }).catch(function (error) {
                    // handle error
                    handleAjaxError(error);
                });
            },
            loadTags() {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.tagListSelect().url;
                this.$http.get(url)
                    .then(function (response) {
                        self.tagList = response.data;
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },
        }
    }
</script>

<style scoped>
    .item-icon {
        width: 60px;
        min-width: 60px;
        text-align: center;
    }

    .list-group-item-action {
        cursor: pointer;
    }
</style>
