<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3 class="text-center">
                    <input type="text" class="name-input" v-model="item.title"/>
                </h3>
            </div>
        </div>
        <div class="row" v-if="stringNotEmpty(item.description)">
            <div class="col-12" v-html="cleanupString(item.description)">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table" style="margin-top: 10px;">
                    <thead class="thead-light">
                    <tr>
                        <th>
                            <input type="text"
                                   class="name-input"
                                   v-model="item.colDaysTitle"
                            />
                        </th>
                        <th>
                            <input type="text"
                                   class="name-input"
                                   v-model="item.colPenaltyTitle"
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(line, index) in item.tableLines">
                        <td>
                            <input type="text"
                                   class="name-input"
                                   placeholder="60 - 30 days"
                                   v-model="line.days"
                            />
                        </td>
                        <td>
                            <input type="text"
                                   class="name-input"
                                   placeholder="20%"
                                   v-model="line.penalty"
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import {stringUtilsMixin} from "../../item/item-mixins";

    export default {
        components: {
        },
        mixins: [
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
            }
        },
        methods: {
        }
    }
</script>

<style scoped>
</style>
