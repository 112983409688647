<template>
    <div class="modal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{item.name}} {{$t("Itinerary.modal.ItemMap.location")}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <iframe v-if="showPlaceMap"
                            ref="mapframe"
                            width="100"
                            height="500"
                            frameborder="0"
                            style="border:0"
                            :src="mapUrl"
                            allowfullscreen>
                    </iframe>
                    <form autocomplete="off" v-else>
                        <div>
                            <input autocomplete="false" name="hidden" type="text" style="display:none;"/>
                            <input ref="autocomplete"
                                   :placeholder='$t("Itinerary.modal.ItemMap.type_address_to_search_and_change")'
                                   class="form-control"
                                   onfocus="value = ''"
                                   type="text"/>
                            <!--<select v-model="type" class="form-control">
                                <option value="establishment">Business</option>
                                <option value="address">Address</option>
                            </select>-->
                        </div>
                        <br/>
                        <div ref="map" class="map-container"></div>
                        <div class="alert alert-info">
                            {{$t("Itinerary.modal.ItemMap.click_drag_change_location")}}
                        </div>
                    </form>
                </div>
                <div class="modal-footer" v-if="showPlaceMap">
                    <button class="btn btn-link" @click="close">{{$t("Itinerary.modal.ItemMap.close")}}</button>
                </div>
                <div class="modal-footer" v-else>
<!--
                    <button class="btn btn-danger float-left" @click="$emit('clear-location')">{{$t("Itinerary.modal.ItemMap.cancel")}}</button>
-->
                    <button class="btn btn-link" @click="close">{{$t("Itinerary.modal.ItemMap.cancel")}}</button>
                    <button :disabled="markerNull" class="btn btn-primary" @click="setItemAddress">
                        {{$t("Itinerary.modal.ItemMap.update_location")}}
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {generateUUID, showError} from "../../../utils";

    var map = null; // google map obj
    var marker = null; // google map marker
    var infoWindow = null; // google map marker info window
    var geocoder = null; // google maps geocoder
    var autocomplete = null; // google maps autocomplete
    // var places = null; // google maps places service

    export default {
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                address: "", // google map address

                markerNull: true,
                // mapSetup: false,
                // type: "establishment"
            }
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function (e) {
                self.$emit('update:show', false);
            });
        },
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                    this.markerNull = true;
                    map = null; // google map obj
                    marker = null; // google map marker
                    infoWindow = null; // google map marker info window
                    geocoder = null; // google maps geocoder
                    autocomplete = null; // google maps autocomplete
                    this.setupMap();
/*
                    if (!this.mapSetup) {
                        this.setupMap();
                    } else if (this.item.coordinates &&
                        this.item.coordinates.lat &&
                        this.item.coordinates.lng
                    ) {
                        let center = {
                            lat: this.item.coordinates.lat,
                            lng: this.item.coordinates.lng,
                        };
                        this.address = this.item.address;
                        if (marker) {
                            marker.setMap(null);
                        }
                        this.showItemMarker(center);
                    } else if (marker) {
                        marker.setMap(null);
                    }
*/
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
            },
            showPlaceMap() {
                const self = this;
                if (this.showPlaceMap) {
                    this.$nextTick(() => {
                        $(self.$refs.mapframe).css("width", "100%");
                    });
                } else {

                }
            }
        },
        computed: {
            showPlaceMap() {
                // se for google places, mostra iframe com mapa para poupar na api
                return this.show &&
                    this.item !== undefined &&
                    this.item !== null &&
                    this.item.googlePlacesId !== undefined &&
                    this.item.googlePlacesId !== null &&
                    this.item.coordinates.lat !== null &&
                    this.item.coordinates.lng !== null;
            },
            mapUrl() {
                const key = GOOGLE_API;
                return "https://www.google.com/maps/embed/v1/place?q=place_id:" + this.item.googlePlacesId +
                    "&key=" + key;
            }
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            setupMap() {
                const self = this;
                let center = {
                    lat: 38.661,
                    lng: 4.065,
                };
                let hasPosition = false;
                if (this.item.coordinates &&
                    this.item.coordinates.lat &&
                    this.item.coordinates.lng
                ) {
                    center.lat = this.item.coordinates.lat;
                    center.lng = this.item.coordinates.lng;
                    this.address = this.item.address;
                    hasPosition = true;
                }
                map = new google.maps.Map(this.$refs.map, {
                    center: center,
                    zoom: 3,
                });

                if (!this.showPlaceMap) {
                    // Create the autocomplete object and associate it with the UI input control.
                    // Restrict the search to the default country, and to place type "cities".
                    autocomplete = new google.maps.places.Autocomplete(
                        (this.$refs.autocomplete),
                        {
                            // types: ['address'],
                            types: [],
                            fields: [
                                "formatted_address",
                                "geometry",
                            ],
                        }
                    );
                    autocomplete.addListener('place_changed', () => {
                        let place = autocomplete.getPlace();
                        if (place) {
                            if (place.formatted_address) {
                                self.address = place.formatted_address;
                            }
                            if (place.geometry) {
                                self.setMarkerOnLocation(place.geometry.location, false);
                                // map.panTo(place.geometry.location);
                                // map.setZoom(15);
                            }
                        }
                    });
                }

                if (hasPosition) {
                    this.showItemMarker(center);
                }

                map.addListener('click', function(e) {
                    self.setMarkerOnLocation(e.latLng, true);
                });
                // this.mapSetup = true;
                //let myLatlng = new google.maps.LatLng(lat, lng);

                /*const myOptions = {
                    zoom: 12,
                    zoomControl: true,
                    center: myLatlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                };

                map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);

                myMarker = new google.maps.Marker({
                    position: myLatlng
                });
                myMarker.setMap(map);*/
            },
            setMarkerOnLocation(latLng, geocode) {
                const self = this;
                if (!infoWindow) {
                    infoWindow = new google.maps.InfoWindow({
                        content: self.address
                    });
                }

                if (!marker) {
                    marker = new google.maps.Marker({
                        position: latLng,
                        map: map,
                        draggable: true,
                    });
                    marker.addListener('click', function() {
                        if (self.address !== '')
                            infoWindow.open(map, marker);
                    });
                    marker.addListener('dragend', function(e) {
                        marker.setPosition(e.latLng);
                        self.geocodeAddress(e.latLng);
                    });
                } else {
                    marker.setPosition(latLng);
                }
                map.panTo(latLng);
                map.setZoom(14);
                if (geocode) {
                    self.geocodeAddress(latLng);
                } else {
                    infoWindow.close();
                    infoWindow.setContent(self.address);
                    infoWindow.open(map, marker);
                }
                this.markerNull = marker === null;
            },
            geocodeAddress(latLng) {
                const self = this;
                if (!geocoder) {
                    geocoder = new google.maps.Geocoder();
                }
                geocoder.geocode({
                    'location': latLng
                }, function(results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            //alert(results[0].formatted_address);
                            if (results[0].formatted_address) {
                                self.address = results[0].formatted_address;
                            } else {
                                self.address = "";
                            }
                            infoWindow.close();
                            infoWindow.setContent(self.address);
                            //infoWindow.close();
                            infoWindow.open(map, marker);
                        }
                    } else {
                        console.error("GEOCODER STATUS => " + status);
                    }
                });
            },
            showItemMarker(center) {
                const self = this;
                if (!infoWindow) {
                    infoWindow = new google.maps.InfoWindow({
                        content: self.address,
                    });
                }
                if (!marker) {
                    marker = new google.maps.Marker({
                        position: center,
                        map: map,
                        draggable: true,
                    });
                    this.markerNull = marker === null;
                    marker.addListener('click', function () {
                        if (self.address !== '')
                            infoWindow.open(map, marker);
                    });
                    marker.addListener('dragend', function(e) {
                        marker.setPosition(e.latLng);
                        self.geocodeAddress(e.latLng);
                    });
                } else {
                    marker.setPosition(center);
                    infoWindow.close();
                    infoWindow.setContent(self.address);
                    infoWindow.open(map, marker);
                }
                map.panTo(center);
                map.setZoom(14);
                infoWindow.open(map, marker);
            },
            setItemAddress() {
                if (marker !== null) {
                    let data = {
                        address: this.address,
                        lat: marker.getPosition().lat(),
                        lng: marker.getPosition().lng(),
                    };
                    this.$emit("update-location", data);
                }
                this.close();
            }
        },
    }
</script>

<style scoped>
   .map-container {
       height: 400px;
       width: 100%;
   }
</style>
