<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <input type="text" class="name-input" v-model="item.title"/>
                </h3>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;" v-if="stringNotEmpty(item.description)">
            <div class="col-12" v-html="cleanupString(item.description)">>
                <!--<textarea-autosize
                        class="form-control body-font-size"
                        style="border: 0;color:black;"
                        placeholder="Type something here..."
                        v-model="item.description"
                        :min-height="30"
                        :max-height="500"
                ></textarea-autosize>-->
                <!--<textarea class="form-control" placeholder="Describe the trip here"></textarea>-->
            </div>
        </div>
    </div>
</template>

<script>


    import {stringUtilsMixin} from "../../item/item-mixins";

    export default {
        components: {
        },
        mixins: [
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                // showButtons: false,
            }
        },
    }
</script>

<style scoped>
</style>
