<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3>{{$t("Itinerary.TripClientInfo.tooltip.client_details")}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="table-cell">
                    {{$t("Itinerary.TripClientInfo.tooltip.guests")}}:
                </div>
                <div class="bullet-right table-cell">
                    <input class="bullet-item mor-editable-field"
                           style="width:40px;"
                           type="number"
                           :placeholder='$t("Itinerary.TripClientInfo.tooltip.number_guests")'
                           v-model="item.pax"
                    />
                </div>
            </div>
            <div class="col-12">
                <div v-for="(client, index) in item.clients" v-if="stringNotEmpty(client.name)">
                    <div class="bullet-left table-cell">
                        <i class="fa fa-circle"></i>
                    </div>
                    <div class="table-cell" style="padding-left: 5px;">
                        <input
                                class="bullet-item mor-editable-field"
                                v-model="item.clients[index].name"
                        />
                    </div>
                    <div class="table-cell" style="padding-left: 5px;min-width:400px;">
                        <input
                                class="bullet-item mor-editable-field"
                                v-model="item.clients[index].email"
                        />
                    </div>
                </div>
            </div>
            <!--<div class="col-12">
                <div class="table-cell">
                    <i class="fa fa-mail-bulk"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    <input class="bullet-item mor-editable-field"
                           type="email"
                           placeholder="Email"
                           v-model="item.contactEmail"
                    />
                </div>
            </div>-->
        </div>

    </div>
</template>

<script>

    import {stringUtilsMixin} from "../../item/item-mixins";

    export default {
        components: {
        },
        mixins: [
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                // showButtons: false,
            }
        },
        watch: {
            /*"item.pax"() {
                this.updateNamesSize();
            }*/
        },
        mounted() {
            // this.updateNamesSize(false);
            const self = this;
        },
        beforeDestroy() {
        },
        methods: {
            /*updateNamesSize(autoSave) {
                if (autoSave) {
                    this.emitAutosave();
                }
                if (this.item.pax <= 0) {
                    this.item.pax = 1;
                }
                if (this.item.clients.length > this.item.pax) {
                    while (this.item.clients.length > this.item.pax) {
                        this.item.clients.pop();
                    }
                } else {
                    while (this.item.clients.length < this.item.pax) {
                        this.item.clients.push("");
                    }
                }
            }*/
        }
    }
</script>

<style scoped>
</style>
