<template>
    <div class="row">
        <div class="col-10 mx-auto">
            <div class="card">
                <div class="card-header">
                    <h1>Platform Administration</h1>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <a class="btn btn-primary" :href="indexUrl">Leave Platform Administration</a>
                            <a class="btn btn-primary float-right" :href="signupCompanyUrl">Signup Company</a>
                        </div>
                    </div>
                    <br/>
                    <div class="row">
                        <div class="col">
                            <h2>Stripe Configuration</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <form v-on:submit.prevent="updateStripe" id="stripeForm" novalidate>
                                <div class="form-group" :class="{ 'has-error': $v.stripe.secret.$error }">
                                    <label for="secret" class="control-label">Stripe Secret *</label>
                                    <input class="form-control" type="text" name="secret" id="secret" placeholder="SECRET STRIPE"
                                           :class="{'is-invalid' : $v.stripe.secret.$error}"
                                           v-model="$v.stripe.secret.$model"/>
                                    <span class="invalid-feedback" v-show="$v.stripe.secret.$error && !$v.stripe.secret.required">
                                        {{$t("root.required")}}</span>
                                </div>
                                <div class="form-group" :class="{ 'has-error': $v.stripe.publishable.$error }">
                                    <label for="publishable" class="control-label">Stripe Publishable *</label>
                                    <input class="form-control" type="text" name="publishable" id="publishable"
                                           placeholder="SECRET PUBLISHABLE"
                                           :class="{'is-invalid' : $v.stripe.publishable.$error}"
                                           v-model="$v.stripe.publishable.$model"/>
                                    <span class="invalid-feedback"
                                          v-show="$v.stripe.publishable.$error && !$v.stripe.publishable.required">
                                        {{$t("root.required")}}</span>
                                </div>
                                <div class="form-group">
                                    <label for="subscriptionSelect">Subscription Model To Use:</label>
                                    <select class="form-control" id="subscriptionSelect" v-model="stripe.subscriptionId" v-on:change="updatePlans">
                                        <option id=""></option>
                                        <option v-for="s in subscriptions" :value="s.productId">{{s.productName}} - {{s.productDescription}}</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label for="publishable" class="control-label">PORTUGAL VAT HASH</label>
                                    <input class="form-control" type="text" name="ptVAT" id="ptVAT"
                                           v-model="stripe.portugalVAT"/>
                                </div>
                                <div class="form-group">
                                    <label for="planEurSelect">Plan for Euros:</label>
                                    <select class="form-control" id="planEurSelect" v-model="stripe.planEuro">
                                        <option id=""></option>
                                        <option v-for="p in plans" :value="p.planId">{{p.name}} ({{p.currency}})</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="planEurSelect">FEE Plan for Euros:</label>
                                    <select class="form-control" id="planEurSelect" v-model="stripe.planEuroFee">
                                        <option id=""></option>
                                        <option v-for="p in plans" :value="p.planId">{{p.name}} ({{p.currency}})</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="planDollarSelect">Plan for Dollars:</label>
                                    <select class="form-control" id="planDollarSelect" v-model="stripe.planDollar">
                                        <option id=""></option>
                                        <option v-for="p in plans" :value="p.planId">{{p.name}} ({{p.currency}})
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="planDollarSelect">FEE Plan for Dollars:</label>
                                    <select class="form-control" id="planDollarSelect" v-model="stripe.planDollarFee">
                                        <option id=""></option>
                                        <option v-for="p in plans" :value="p.planId">{{p.name}} ({{p.currency}})
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="planBgpSelect">Plan for GBP:</label>
                                    <select class="form-control" id="planBgpSelect" v-model="stripe.planGbp">
                                        <option id=""></option>
                                        <option v-for="p in plans" :value="p.planId">{{p.name}} ({{p.currency}})
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="planBgpSelect">FEE Plan for GBP:</label>
                                    <select class="form-control" id="planBgpSelect" v-model="stripe.planGbpFee">
                                        <option id=""></option>
                                        <option v-for="p in plans" :value="p.planId">{{p.name}} ({{p.currency}})
                                        </option>
                                    </select>
                                </div>
                                <div v-if="showError" class="alert alert-danger" v-html="errorMsg"></div>
                                <div class="float-right">
                                    <v-button class="btn btn-primary" :loading="loading" :text="loadingText">
                                        Save Update
                                    </v-button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <br/>
                    <div class="row">
                        <div class="col">
                            <h2>Mobile Builds Listing</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Request Date</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Message</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="b in mobileBuilds">
                                    <th scope="row">{{b.buildId}}</th>
                                    <td>{{b.name}}</td>
                                    <td>{{b.requestdate}}</td>
                                    <td>{{b.status}}</td>
                                    <td>{{b.message}}</td>
                                    <td>
                                        <a class="btn btn-primary" :href="generateDownloadBuildConfigURL(b.orgId, b.buildId)">
                                            Download Configs
                                        </a>

                                        <button class="btn btn-primary" v-on:click="changeBuildStatus(b.buildId)">
                                            Change Status
                                        </button>

                                        <button class="btn btn-primary" v-on:click="changeBuildMessage(b.buildId)">
                                            Change Message
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import {required, email, minLength, maxLength, minValue, maxValue, numeric} from "vuelidate/lib/validators";
    import {showSuccessDialog} from "../../utils";
    import VButton from "../../components/VButton";
    import {getLanguageOptions} from "../../language-utils";
    import bootbox from 'bootbox';

    export default {
        components: {
            VButton,
        },
        data() {
            return {
                stripe: {
                    secret: '',
                    publishable: '',
                    subscriptionId: '',
                    planEuro: '',
                    planDollar: '',
                    planGbp: '',
                    planEuroFee: '',
                    planDollarFee: '',
                    planGbpFee: '',
                    portugalVAT: '',
                },
                mobileBuilds: [],
                subscriptions: [],
                plans: [],
                showError: false,
                errorMsg: '',
                loading: false,
                loadingText: 'Loading',
            }
        },
        validations: {
            stripe: {
                secret: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(255),
                },
                publishable: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(255),
                },
            },
        },
        mounted: function () {
            this.loadData();
        },
        computed: {
            indexUrl() {
                return jsRoutes.controllers.Application.index().url;
            },
            signupCompanyUrl() {
                return jsRoutes.controllers.PlatformAdministrationController.signupCompany().url;
            },
        },
        methods: {
            loadData() {
                this.loadStripeData();
                this.getSubscriptions();
                this.loadMobileBuildsData();
            },
            generateDownloadBuildConfigURL(orgId, buildId) {
                return jsRoutes.controllers.PlatformAdministrationController.downloadBuildConfigs(orgId, buildId).url;
            },
            updatePlans() {
                if (this.stripe.subscriptionId === '') {
                    this.plans = [];
                } else {
                    for (const a in this.subscriptions) {
                        if (this.subscriptions[a].productId === this.stripe.subscriptionId) {
                            this.plans = this.subscriptions[a].plans;
                        }
                    }
                }
            },
            loadStripeData() {
                const self = this;
                jsRoutes.controllers.PlatformAdministrationController.getStripeConfiguration()
                    .ajax({
                        success: function (data) {
                            self.stripe = data;
                        },
                        error: function (error) {
                            // TODO , do something with error ?  Maybe blank the Page !
                        }
                    });
            },
            loadMobileBuildsData() {
                const self = this;
                jsRoutes.controllers.PlatformAdministrationController.getMobileBuildsRequests()
                    .ajax({
                        success: function (data) {
                            self.mobileBuilds = data;
                        },
                        error: function (error) {
                            // TODO , do something with error ?  Maybe blank the Page !
                        }
                    });
            },
            updateStripe() {
                this.showError = false;
                this.errorMsg = "";
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.log("### INVALID");
                    return;
                }
                this.loading = true;
                const self = this;
                const url = jsRoutes.controllers.PlatformAdministrationController.postStripeConfiguration().url;
                axios.post(url, this.stripe)
                    .then(function (response) {
                        self.loading = false;
                        self.stripe = response.data;
                        //self.getSubscriptions();
                    })
                    .catch(function (error) {
                        console.log('------------------------ ' +error);
                        self.loading = false;
                        self.showError = true;
                        self.errorMsg = error.response;
                        if (error && error.response && error.response.status  && error.response.status === 500) {
                            self.errorMsg = self.$t("root.something_weng_wrong");
                        } else {
                            self.errorMsg = error;
                        }
                    });
                
            },
            getSubscriptions() {
                const self = this;
                jsRoutes.controllers.PlatformAdministrationController.getStripeSubscriptions()
                    .ajax({
                        success: function (data) {
                            self.subscriptions = data;
                            self.updatePlans();
                        },
                        error: function (error) {
                            // TODO , do something with error ?  Maybe blank the Page !
                        }
                    });
            },
            changeBuildStatus(buildId) {
                const self = this;

                bootbox.prompt({
                    title: "Change Build Status to:",
                    message: '<p>Please select an option below:</p>',
                    inputType: 'radio',
                    inputOptions: [
                    {
                        text: 'BUILDING',
                        value: 'BUILDING',
                    },
                    {
                        text: 'FAILED',
                        value: 'FAILED',
                    },
                    {
                        text: 'SUCCESS',
                        value: 'SUCCESS',
                    }
                    ],
                    callback: function (result) {
                        jsRoutes.controllers.PlatformAdministrationController.changeBuildStatus(buildId, result)
                            .ajax({
                                success: function (data) {
                                    self.mobileBuilds = data;
                                },
                                error: function (error) {
                                    // TODO , do something with error ?  Maybe blank the Page !
                                }
                            });
                    }
                });
            },
            changeBuildMessage(buildId) {
                const self = this;
                bootbox.prompt("New Message for Build", function(result){
                    jsRoutes.controllers.PlatformAdministrationController.changeBuildMessage(buildId, result)
                        .ajax({
                            success: function (data) {
                                self.mobileBuilds = data;
                            },
                            error: function (error) {
                                // TODO , do something with error ?  Maybe blank the Page !
                            }
                        });
                });
            },
        },
    }
</script>

<style scoped>

</style>