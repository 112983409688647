<template>
    <div v-if="parentItem">
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <button class="btn btn-danger" @click="$emit('delete-item', item)"
                    v-tooltip='$t("Itinerary.general.remove_item")'>
                <i class="fa fa-trash"></i>
            </button>
<!--
            <br/>
            <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                <i class="fa fa-arrows-alt"></i>
            </button>
-->
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <div class="table-cell">
                        <i class="fa fa-h-square"></i> ({{$t("Itinerary.TripSleep.tooltip.check_out")}})
                    </div>
                    <div class="table-cell" style="padding-left: 10px;">
                        {{parentItem ? parentItem.name : ""}}
                    </div>
                </h3>
            </div>
            <div class="col-12">
                {{$t("Itinerary.TripSleep.tooltip.check_out_at")}}: {{parentItem ? parentItem.checkout : ""}}
            </div>
        </div>
    </div>
</template>

<script>
    import {getParentItemById} from "../itinerary-templates";
    import {itemCommonPropsMixin} from "./item-mixins";

    export default {
        components: {
        },
        mixins: [
            itemCommonPropsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            sections: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                showImageUpload: false,
                // showButtons: false,
            }
        },
        computed: {
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library")
                    ;
            },
            parentItem() {
                return getParentItemById(this.sections, this.item.parentId);
            }
        },
        methods: {
            // usar isto para galeria? https://github.com/dimsemenov/photoswipe
            // ou http://ashleydw.github.io/lightbox
            // https://www.hongkiat.com/blog/responsive-lightbox-library/
            /*getImageUrl(image) {
                // var quality esta na view twirl
                return jsRoutes.controllers.ItineraryController.imageDownload(image, quality).url;
            },*/
            /*saveLibraryItem() {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.saveLibraryItem().url;

                let dataObj = JSON.parse(JSON.stringify(this.item));
                // clean the itinerary specific items
                dataObj.startDate = null;
                dataObj.endDate = null;
                dataObj.roomType = null;

                // this.loading = true;
                this.$http.post(url, dataObj)
                    .then(function (response) {
                        // self.loading = false;
                        self.item.libraryId = response.data.libraryId;
                        self.$emit("update:item", self.item);
                        showSuccess("Item saved to library");
                    })
                    .catch(function (error) {
                        // self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            }*/

        }
    }
</script>

<style scoped>
</style>
