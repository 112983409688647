<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <input type="text" class="name-input" v-model="item.title"/>
                </h3>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;" v-if="stringNotEmpty(item.details)">
            <div class="col-12" v-html="cleanupString(item.details)">
            </div>
        </div>
        <div class="row" v-if="item.bullets">
            <div class="col-12 mt-2">
                <div v-for="(bullet, index) in item.bullets" v-if="stringNotEmpty(bullet)">
                    <div class="bullet-left table-cell">
                        <i class="fa fa-circle"></i>
                    </div>
                    <div class="table-cell" style="padding-left: 5px;">
                        <input
                                class="bullet-item mor-editable-field"
                                v-model="item.bullets[index]"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {stringUtilsMixin} from "../../item/item-mixins";

    export default {
        components: {
        },
        mixins: [
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                // showButtons: false,
            }
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>
