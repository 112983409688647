<template>
    <div class="container-fluid">
        <div class="form-group" :class="{ 'has-error': dateRange === null}">
            <!--<div class="form-group">-->
            <label class="control-label mr-2">{{$t("Itinerary.modal.Step2CarRental.dates")}} *:</label>
            <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    :start-placeholder='$t("Itinerary.modal.Step2CarRental.start_date")'
                    :end-placeholder='$t("Itinerary.modal.Step2CarRental.end_date")'
                    :clearable="false"
                    :picker-options="pickerOptions"
                    :format="dateFormat"
            >
            </el-date-picker>
            <input type="hidden" class="form-control"
                   :class="{'is-invalid' : dateRange === null}"/>
            <div class="invalid-feedback" v-show="dateRange === null">{{$t("Itinerary.modal.Step2CarRental.please_select")}}</div>
        </div>
        <div class="float-right">
            <button class="btn btn-link" @click="$emit('cancel')">{{$t("Itinerary.modal.Step2CarRental.cancel")}}</button>
            <button class="btn btn-primary" style="margin-left: 5px;" @click="doAddItem">{{$t("Itinerary.modal.Step2CarRental.add")}}</button>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import vButton from '../../../components/VButton';
    import {formatDate, parseMoment} from "../../../date-utils";
    import {getDayNumForDate} from "../itinerary-templates";

    export default {
        components: {
            vButton,
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            const self = this;
            return {
                dateRange: [],
                pickerOptions: {
                    disabledDate(date) {
                        const result = !moment(date).isBetween(parseMoment(self.startDate, self.dateFormat), parseMoment(self.endDate, self.dateFormat), null, "[]");
                        // if (result)
                        //     console.log("#### RESULT FOR " + formatMoment(moment(date)) + " => " + result);
                        return result;
                    }
                },
                defaultDate: new Date(),
            }
        },
        mounted() {
            this.dateRange = null;
        },
        computed: {
            itData() {
                return this.$store.getters.itData;
            },
            startDate() {
                return this.$store.getters.itData.startDate;
            },
            endDate() {
                return this.$store.getters.itData.endDate;
            },
            dateFormat() {
                return this.$store.getters.itData.dateFormat;
            },
        },
        watch: {
        },
        methods: {
            doAddItem() {
                // this.item.startDate = formatDate(this.dateRange[0], this.dateFormat);
                // this.item.endDate = formatDate(this.dateRange[1], this.dateFormat);
                try {
                    const startDate = formatDate(this.dateRange[0], this.itData.dateFormat);
                    const endDate = formatDate(this.dateRange[1], this.itData.dateFormat);

                    const startDay = getDayNumForDate(startDate, this.itData.startDate, this.itData.dateFormat);
                    const endDay = getDayNumForDate(endDate, this.itData.startDate, this.itData.dateFormat);

                    this.item.startDay = startDay;
                    this.item.endDay = endDay;
                } catch (e) {
                    self.dateRange = null;
                    return;
                }
                if (!this.item.startDay || !this.item.endDay) {
                    self.dateRange = null;
                    return;
                }

                this.$emit('add-item', this.item);
                /*this.$emit('update:show', false);*/
            },
        }
    }
</script>

<style scoped>
</style>
