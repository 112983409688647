<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <button class="btn btn-success"
                        @click="$emit('set-main')"
                        v-tooltip='$t("Itinerary.TripMeal.alternatives.set_as_main")'
                >
                    <i class="fa fa-star"></i>
                </button>
                <button class="btn btn-primary"
                        @click="showAutocomplete = true"
                        v-tooltip='$t("Itinerary.general.autocomplete_information")'
                >
                    <i class="fa fa-play-circle"></i>
                </button>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
<!--
                <button class="btn btn-primary"
                        @click="saveLibraryItem">
                    <i class="fa fa-save"></i> {{saveToLibraryLabel}}
                </button>
-->
                <button class="btn btn-danger"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
<!--
            <br/>
            <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                <i class="fa fa-arrows-alt"></i>
            </button>
-->
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <div class="table-cell">
                        <i class="fa fa-utensils"></i>
                    </div>
                    <div class="table-cell" style="width:100%;padding-left:10px;">
                        <input :readonly="!editable"
                               type="text" class="name-input"
                               v-model="item.title"
                               :placeholder='$t("Itinerary.TripMeal.alternatives.title")'
                               @change="emitAutosave"
                               @blur="emitAutosave"
                        />
                        <input :readonly="!editable"
                               type="text" class="name-input"
                               v-model="item.name"
                               :placeholder='$t("Itinerary.TripMeal.alternatives.name")'
                               @change="emitAutosave"
                               @blur="emitAutosave"
                        />
                    </div>
                </h3>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12" v-if="item.googleRating !== undefined && item.googleRating !== null">
                <div class="table-cell">
                    {{$t("Itinerary.TripMeal.tooltip.google_rating")}}:
                </div>
                <div class="table-cell">
                    <star-rating v-model="item.googleRating"
                                 :show-rating="true"
                                 :star-size="15"
                                 :read-only="true"
                                 :increment="0.1"
                    ></star-rating>
                </div>
            </div>
            <div class="col-12" style="padding-top: 5px;">
                <textarea-autosize
                        v-if="editable"
                        class="form-control body-font-size"
                        :placeholder='$t("Itinerary.TripMeal.tooltip.description")'
                        v-model="item.description"
                        :min-height="30"
                        :max-height="500"
                        @blur.native="emitAutosave"
                ></textarea-autosize>
                <span v-else v-html="cleanupString(item.description)"></span>
                <!--<textarea class="form-control" placeholder="Description" v-model="item.description"></textarea>-->
            </div>
            <div class="col-12" style="padding-top: 5px;">
                <div v-if="editable" class="table-cell" v-tooltip='$t("Itinerary.general.show_on_map")'>
                    <a href="#" @click.prevent="showMapModal = true"><i class="fa fa-globe"></i></a>
                </div>
                <div v-else class="table-cell">
                    <i class="fa fa-globe"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;" v-tooltip='$t("Itinerary.TripMeal.tooltip.address")'>
                    <input :readonly="!editable"
                           class="bullet-item mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripMeal.tooltip.address")'
                           v-model="item.address"
                           @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell" v-if="editable">
                    <button @click.prevent="showMapModal = true"
                            v-tooltip='$t("Itinerary.general.show_on_map")'
                            class="btn btn-primary btn-sm"
                    >
                        <i class="fa fa-map"></i>
                    </button>
                </div>
                <!--<i class="fa fa-address-book"></i> {{item.address}}-->
            </div>
            <div class="col-12" v-tooltip='$t("Itinerary.TripMeal.tooltip.phone")'>
                <div class="table-cell">
                    <i class="fa fa-phone"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    <input :readonly="!editable"
                           class="bullet-item mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripMeal.tooltip.phone")'
                           v-model="item.phone"
                           @blur="emitAutosave"
                    />
                </div>
                <!--Phone: {{item.phone}}-->
            </div>
            <div class="col-12" style="padding-top: 5px;">
                <div class="table-cell" v-tooltip='$t("Itinerary.TripMeal.tooltip.open_web")'>
                    <a :href="item.website" target="_blank"><i class="fa fa-mail-bulk"></i></a>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;" v-tooltip='$t("Itinerary.TripMeal.tooltip.website")'>
                    <input :readonly="!editable"
                           class="bullet-item mor-editable-field"
                           type="url"
                           :placeholder='$t("Itinerary.TripMeal.tooltip.website")'
                           v-model="item.website"
                           @blur="emitAutosave"
                    />
                </div>
                <!--Website: {{item.website}}-->
            </div>
        </div>
        <!--<div class="row" style="margin-top: 10px;">-->
        <draggable class="row" style="margin-top: 10px;" v-model="item.images" :options="{animation:150, disabled: !editable}">
            <div class="col-lg-6 col-md-6 col-sm-12" style="margin-top:10px; margin-bottom: 10px;"
                 v-for="image in item.images"
                 :key="image.id">
                <button v-if="editable" class="btn btn-danger img-delete-btn d-print-none" @click="deleteImage(image)">
                    <i class="fa fa-trash"></i>
                </button>
                <img class="img-fluid w-100" :src="getImageUrl(image)">
                <div class="mor-image-caption">
                    <input :readonly="!editable"
                           type="text"
                           v-model="image.caption"
                           class="mor-image-caption-field"
                           :placeholder='$t("Itinerary.general.image_caption")'
                           @blur="updateImageCaption(image)"
                           @keyup.enter="updateImageCaption(image)"
                    />
                </div>
            </div>
        </draggable>
        <!--</div>-->
        <div v-if="editable" class="row d-print-none">
            <div class="col-12">
                <button style="margin-top: 10px;" class="btn btn-primary float-right" @click="showImageUpload = true">
                    {{$t("Itinerary.general.add_images")}}
                </button>
            </div>
        </div>
        <image-upload-modal
                :show.sync="showImageUpload"
                :images.sync="item.images"
                :item-tags="item.tags"
        >
        </image-upload-modal>
        <places-autocomplete-modal :show.sync="showAutocomplete" @update-data="updateItemData($event)"></places-autocomplete-modal>
        <item-map-modal :show.sync="showMapModal" :item="item" @update-location="updateItemAddress($event)"></item-map-modal>
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
        >
        </save-to-library-modal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import ImageUploadModal from '../modal/ImageUploadModal';
    import PlacesAutocompleteModal from '../modal/PlacesAutocompleteModal';
    import ItemMapModal from '../modal/ItemMapModal';
    import {copyData, handleAjaxError, showCustomDialog, showSuccess} from "../../../utils";
    import {autosaveMixin, imageUrlMixin, itemCommonPropsMixin, moveItemMixin, stringUtilsMixin} from "./item-mixins";
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";

    export default {
        components: {
            draggable,
            ImageUploadModal,
            PlacesAutocompleteModal,
            ItemMapModal,
            SaveToLibraryModal,
        },
        mixins: [
            imageUrlMixin,
            autosaveMixin,
            moveItemMixin,
            stringUtilsMixin,
            itemCommonPropsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showSetNameModal: false,
                showImageUpload: false,
                showAutocomplete: false,
                showMapModal: false,
            }
        },
        computed: {
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library")
                    ;
            }
        },
        methods: {
            updateItemData(data) {
                this.item.name = data.name;
                this.item.address = data.address;
                this.item.phone = data.phone;
                this.item.website = data.website;
                this.item.coordinates = data.coordinates;
                this.item.googleId = data.googleId;
                this.item.googlePlacesId = data.googlePlacesId;
                this.item.googleRating = data.googleRating;
                this.item.images = data.images;
                this.$emit("update:item", this.item);
                this.emitAutosave();
            },
            updateItemAddress(data) {
                this.item.address = data.address;
                this.item.coordinates.lat = data.lat;
                this.item.coordinates.lng = data.lng;
                this.$emit("update:item", this.item);
                this.emitAutosave();
            },
            /*deleteImage(image) {
                const self = this;
                showCustomDialog(
                    "Are you sure?",
                    "Remove this image?",
                    "Remove",
                    "btn-danger",
                    function() {
                        // if (image.type === 'GOOGLE') {
                        const index = self.item.images.findIndex((i) => {
                            return i.id === image.id;
                        });
                        self.item.images.splice(index, 1);
                        self.$emit("update:item", self.item);
                        /!*} else {
                            // o que fazer mais aqui?
                        }*!/
                    }
                );
            },*/
            /*saveLibraryItem() {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.saveLibraryItem().url;

                let dataObj = copyData(this.item);
                // clean the meal specific items
                dataObj.time = null;
                dataObj.alternatives = [];
                dataObj.libraryName = dataObj.name;

                // this.loading = true;
                this.$http.post(url, dataObj)
                    .then(function (response) {
                        // self.loading = false;
                        self.$set(self.item, "libraryId", response.data.libraryId);
                        self.$emit("update:item", self.item);
                        self.emitAutosave();
                        showSuccess("Item saved to library");
                    })
                    .catch(function (error) {
                        // self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },*/

        }
    }
</script>

<style scoped>

</style>
