export function getLanguageOptions() {
    let result = [
        {
            value: "en",
            label: "English",
        },
        {
            value: "ru",
            label: "Russian",
        },
        {
            value: "pt",
            label: "Português",
        },
        // {
        //     value: "pt",
        //     label: "Português",
        // }
    ];
    return result;
}
