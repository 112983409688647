<template>
    <div class="modal" id="createClientModal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{title}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body">
                                    <form v-on:submit.prevent="save" novalidate>

                                        <div class="form-group" :class="{ 'has-error': $v.clientData.name.$error }">
                                            <label for="name" class="control-label" >{{$t("ClientModal.fields.name")}} *:</label>
                                            <input type="email" class="form-control" id="name" name="name"
                                                   :class="{'is-invalid' : $v.clientData.name.$error}"
                                                   v-model.trim="$v.clientData.name.$model">
                                            <div class="invalid-feedback" v-show="$v.clientData.name.$error && !$v.clientData.name.required">{{$t("ClientModal.fields.name_required")}}</div>
                                            <div class="invalid-feedback" v-show="$v.clientData.name.$error && !$v.clientData.name.maxLength">{{$t("ClientModal.fields.name_size")}}</div>
                                        </div>

                                        <div class="form-group" :class="{ 'has-error': $v.clientData.email.$error }">
                                            <label for="email" class="control-label" >{{$t("ClientModal.fields.email")}} *</label>
                                            <input type="text" class="form-control" id="email" name="email"
                                                   :class="{'is-invalid' : $v.clientData.email.$error}"
                                                   v-model.trim="$v.clientData.email.$model">
                                            <div class="invalid-feedback" v-show="$v.clientData.email.$error && !$v.clientData.email.required">{{$t("ClientModal.fields.email_required")}}</div>
                                            <div class="invalid-feedback" v-show="$v.clientData.email.$error && !$v.clientData.email.email">{{$t("ClientModal.fields.email_format")}}</div>
                                        </div>

                                        <div class="form-group" :class="{ 'has-error': $v.clientData.phone.$error }">
                                            <label for="phone" class="control-label" >{{$t("ClientModal.fields.phone")}}</label>
                                            <input type="text" class="form-control" id="phone" name="phone"
                                                   :class="{'is-invalid' : $v.clientData.phone.$error}"
                                                   v-model.trim="$v.clientData.phone.$model">
                                            <!--<div class="invalid-feedback" v-show="$v.clientData.phone.$error && !$v.clientData.phone.required">This field is required.</div>-->
                                            <div class="invalid-feedback" v-show="$v.clientData.phone.$error && !$v.clientData.phone.maxLength">{{$t("ClientModal.fields.phone_size")}}</div>
                                        </div>

                                        <v-button v-if="clientData.id === null" type="submit" :loading="loading" class="btn btn-primary float-right"><i class="fa fa-save" aria-hidden="true"></i>&nbsp;{{$t("ClientModal.button.create_client")}}</v-button>
                                        <v-button v-if="clientData.id !== null" type="submit" :loading="loading" class="btn btn-primary float-right"><i class="fa fa-edit" aria-hidden="true"></i>&nbsp;{{$t("ClientModal.button.update_client")}}</v-button>
                                        <button type="button" class="btn btn-link float-right" data-dismiss="modal">{{$t("error.general_cancel")}}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {handleAjaxError, showCustomDialog, showError, showSuccess} from "../../utils";
    import { required, email, maxLength } from 'vuelidate/lib/validators';
    import vButton from '../../components/VButton';

    export default {
        components: {
            vButton,
        },
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            currclient: {
                type: Object,
                required: false,
            },
            readonly: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                clientData: {
                    id: null,
                    name: null,
                    email: null,
                    phone: null,
                },

                loading: false,
            }
        },
        validations: {
            clientData: {
                name: {
                    required,
                    maxLength: maxLength(255),
                },
                email: {
                    required,
                    email,
                    maxLength: maxLength(255),
                },
                phone: {
                    // required,
                    maxLength: maxLength(100),
                },
            }
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function (e) {
                self.$emit('update:show', false);
            })
        },
        computed: {
            title() {
                let self = this;
                return (this.clientData.id !== null ?
                    self.$t("ClientModal.update_client_title") :
                    self.$t("ClientModal.create_client_title") );
            }
        },
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                    if (this.currclient !== undefined && this.currclient !== null) {
                        this.clientData.id = this.currclient.id;
                        this.clientData.name = this.currclient.name;
                        this.clientData.email = this.currclient.email;
                        this.clientData.phone = this.currclient.phone;
                    } else {
                        this.clientData = {
                            id: null,
                            name: null,
                            email: null,
                            phone: null,
                        };
                    }
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
                this.$v.$reset();
            }
        },
        methods: {
            save: function () {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.log("### INVALID");
                    return;
                }
                if (this.clientData.id !== null) {
                    this.doEditClient();
                } else {
                    this.doCreateClient();
                }
            },

            doCreateClient: function () {
                const self = this;
                const url = jsRoutes.controllers.ClientController.doCreateClient().url;

                this.loading = true;
                this.$http.post(url, this.clientData)
                    .then(function (response) {
                        self.loading = false;
                        //console.log("roles -> " + JSON.stringify(data));
                        showSuccess(
                            self.$t("success.general_title"),
                            self.$t("ClientModal.messages.client_created")
                        );
                        self.$emit('update:show', false);
                        self.$emit('reload');
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },
            doEditClient: function () {
                const self = this;
                const url = jsRoutes.controllers.ClientController.doEditClient().url;

                this.loading = true;
                this.$http.post(url, this.clientData)
                    .then(function (response) {
                        self.loading = false;
                        //console.log("roles -> " + JSON.stringify(data));
                        showSuccess(
                            self.$t("success.general_title"),
                            self.$t("ClientModal.messages.client_updated")
                        );
                        self.$emit('update:show', false);
                        self.$emit('reload');
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });

            }

        }
    }
</script>

<style scoped>

</style>