<template>
    <div>
        <div v-if="!libmode" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft" v-if="droppable">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
                <button class="btn btn-danger"
                        v-if="droppable"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
<!--
            <br/>
            <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                <i class="fa fa-arrows-alt"></i>
            </button>
-->
        </div>
        <!--<div class="row" style="margin-top: 10px;">
            <div class="col-12">
                TODO EMERGENCY INFO
            </div>
        </div>-->
        <div class="row" style="margin-top: 10px;">
            <div class="col-12">
                <h3>
                    <input type="text" class="name-input"
                           v-model="item.packingListTitle"
                           :placeholder='$t("Itinerary.TripKnowBeforeGo.tooltip.pack_list")'
                           @blur="emitAutosave"
                    />
                </h3>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12">
                <div class="col-12">
                    <div v-for="(packItem, index) in item.packingList">
                        <div class="bullet-left table-cell">
                            <i class="fa fa-circle"></i>
                        </div>
                        <div class="bullet-right table-cell">
                            <input
                                    class="bullet-item mor-editable-field"
                                    :placeholder='$t("Itinerary.TripKnowBeforeGo.tooltip.pack_item")'
                                    :ref="'packinput' + index"
                                    v-model="item.packingList[index]"
                                    @keydown.up.prevent="handlePackUp(index)"
                                    @keydown.down.prevent="handlePackDown(index)"
                                    @keydown.backspace="handlePackBackspace(packItem, index, $event)"
                                    @keydown.46="handlePackBackspace(packItem, index, $event)"
                                    @keydown.enter.prevent="handlePackEnter(index)"
                                    @blur="emitAutosave"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12">
                <h3>
                    <input type="text" class="name-input"
                           v-model="item.knowBeforeGoTitle"
                           placeholder='$t("Itinerary.TripKnowBeforeGo.tooltip.important_to_know")'
                           @blur="emitAutosave"
                    />
                </h3>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12">
                <div class="col-12">
                    <div v-for="(knowItem, index) in item.knowBeforeGo">
                        <div class="bullet-left table-cell">
                            <i class="fa fa-circle"></i>
                        </div>
                        <div class="bullet-right table-cell">
                            <input
                                    class="bullet-item mor-editable-field"
                                    :placeholder='$t("Itinerary.TripKnowBeforeGo.tooltip.describe_item_here")'
                                    :ref="'knowinput' + index"
                                    v-model="item.knowBeforeGo[index]"
                                    @keydown.up.prevent="handleKnowUp(index)"
                                    @keydown.down.prevent="handleKnowDown(index)"
                                    @keydown.backspace="handleKnowBackspace(knowItem, index, $event)"
                                    @keydown.46="handleKnowBackspace(knowItem, index, $event)"
                                    @keydown.enter.prevent="handleKnowEnter(index)"
                                    @blur="emitAutosave"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="row" style="margin-top: 10px;">
            <div class="col-12">
                TODO WEATHER FORECAST
            </div>
        </div>-->
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
        >
        </save-to-library-modal>
    </div>
</template>

<script>

    import {autosaveMixin, itemCommonPropsMixin, moveItemMixin} from "./item-mixins";
    import {ITEM_TYPE_ACTIVITY, ITEM_TYPE_MEAL, ITEM_TYPE_SLEEP} from "../itinerary-templates";
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";

    export default {
        components: {
            SaveToLibraryModal,
        },
        mixins: [
            autosaveMixin,
            moveItemMixin,
            itemCommonPropsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            droppable: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                showSetNameModal: false,
            }
        },
        computed: {
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library")
                    ;
            },
        },
        mounted() {
            // this.setupMap();
        },
        methods: {
            handlePackUp(index) {
                if (index > 0) {
                    this.$refs["packinput" + (index-1)][0].focus();
                }
            },
            handlePackDown(index) {
                if (index < this.item.packingList.length-1) {
                    this.$refs["packinput" + (index+1)][0].focus();
                }
            },
            handlePackBackspace(packItem, index, event) {
                if (packItem.length === 0 && this.item.packingList.length > 1) {
                    // console.log("### DELETE " + index);
                    this.emitAutosave();
                    this.item.packingList.splice(index, 1);
                    if (index > 0) {
                        // console.log("### FOCUS " + (index-1));

                        this.$refs["packinput" + (index-1)][0].focus();
                    }
                    event.preventDefault();
                }
            },
            handlePackEnter(index) {
                // console.log("### ENTER " + index);
                this.emitAutosave();
                this.item.packingList.splice(index+1, 0, "");
                this.$nextTick(() => {
                    this.$refs["packinput" + (index+1)][0].focus();
                });
            },

            handleKnowUp(index) {
                if (index > 0) {
                    this.$refs["knowinput" + (index-1)][0].focus();
                }
            },
            handleKnowDown(index) {
                if (index < this.item.knowBeforeGo.length-1) {
                    this.$refs["knowinput" + (index+1)][0].focus();
                }
            },
            handleKnowBackspace(knowItem, index, event) {
                if (knowItem.length === 0 && this.item.knowBeforeGo.length > 1) {
                    // console.log("### DELETE " + index);
                    this.emitAutosave();
                    this.item.knowBeforeGo.splice(index, 1);
                    if (index > 0) {
                        // console.log("### FOCUS " + (index-1));

                        this.$refs["knowinput" + (index-1)][0].focus();
                    }
                    event.preventDefault();
                }
            },
            handleKnowEnter(index) {
                // console.log("### ENTER " + index);
                this.emitAutosave();
                this.item.knowBeforeGo.splice(index+1, 0, "");
                this.$nextTick(() => {
                    this.$refs["knowinput" + (index+1)][0].focus();
                });
            },
            //TODO continuar com emergency data, etc

        }

    }
</script>

<style scoped>
</style>
