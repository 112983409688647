<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.TripActivity.tooltip.move_to_another_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
                <button class="btn btn-danger"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <input :readonly="!editable"
                           :placeholder="titlePlaceholder"
                           type="text" class="name-input"
                           v-model="item.title"
                           @blur="emitAutosave"
                           v-tooltip='$t("Itinerary.general.click_to_edit")'
                    />
                </h3>
            </div>
        </div>
        <div class="row"
             style="margin-top: 10px;"
             v-for="(activity, index) in item.list"
             :key="activity.id"
        >
            <div class="col-12">
                <div class="icon-left table-cell">
                    <i class="fa fa-check"></i>
                </div>
                <div class="bullet-right table-cell">
                    <h4>
<!--
                        <input
                                type="text"
                                class="bullet-item-large mor-editable-field"
                                :placeholder="descriptionPlaceholder"
                                v-model="activity.description"
                                :ref="'input' + index"
                                @keydown.up.prevent="handleUp(index)"
                                @keydown.down.prevent="handleDown(index)"
                                @keydown.enter.prevent="handleEnter(index)"
                                @blur="emitAutosave"
                        />
-->
                        <input :readonly="!editable"
                                type="text"
                                class="bullet-item-large mor-editable-field"
                                :placeholder="subtitlePlaceholder"
                                v-model="activity.description"
                                :ref="'input' + index"
                                @blur="emitAutosave"
                        />
                    </h4>
                </div>
            </div>
            <div class="col-12">
                <div style="padding-left: 30px;color:grey;font-style: italic;">
                    <textarea-autosize v-if="editable"
                            class="form-control body-font-size"
                            :placeholder="descriptionPlaceholder"
                            v-model="activity.description2"
                            :min-height="30"
                            :max-height="500"
                            @blur.native="emitAutosave"
                    ></textarea-autosize>
                    <span v-else v-html="cleanupString(activity.description2)"></span>
                </div>
            </div>
            <div class="col-12 activity-prop">
                <div v-if="editable" class="icon-left table-cell" v-tooltip='mapBtnTooltip(activity)'>
                    <a href="#" @click.prevent="openMapModal(activity)"><i class="fa fa-globe"></i></a>
                </div>
                <div v-else class="icon-left table-cell">
                    <i class="fa fa-globe"></i>
                </div>
                <div class="table-cell bullet-right" v-tooltip='$t("Itinerary.TripActivity.tooltip.address")'>
                    <input :readonly="!editable"
                           class="bullet-item mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripActivity.tooltip.address")'
                           v-model="activity.address"
                           @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell pr-2" v-if="editable">
                    <span v-if="hasLocation(activity)" class="text-success">
                        <i class="fa fa-check"></i>
                    </span>
                    <span v-else class="text-danger">
                        <i class="fa fa-times"></i>
                    </span>
                </div>
                <div class="table-cell" v-if="editable">
                    <button @click.prevent="openMapModal(activity)"
                            v-tooltip='mapBtnTooltip(activity)'
                            class="btn btn-primary btn-sm"
                    >
                        <i class="fa fa-map"></i>
                    </button>
                </div>
           </div>
            <div class="col-12 activity-prop">
                <div class="icon-left table-cell"
                     style="white-space: nowrap;"
                     v-tooltip='$t("Itinerary.TripActivity.tooltip.duration")'
                >
                    <i class="fa fa-stopwatch"></i>
                </div>
                <div class="bullet-right table-cell">
                    <!--Duration:-->
                    <el-select :disabled="!editable"
                               v-model="activity.duration"
                               :placeholder='$t("Itinerary.TripActivity.tooltip.duration")'
                               filterable
                               default-first-option
                               allow-create
                               style="color:black;font-size: 1rem;"
                               @blur="emitAutosave"
                               @change="emitAutosave"
                    >
                        <el-option v-for="duration in durations"
                                   :key="duration"
                                   :label="duration"
                                   :value="duration"
                        ></el-option>
                    </el-select>
                </div>
            </div>
            <div class="col-12 activity-prop" v-if="item.included">
                <div class="icon-left table-cell" style="white-space: nowrap;" v-tooltip='$t("Itinerary.TripActivity.tooltip.language")'>
                    <i class="fa fa-language"></i>
                </div>
                <div class="bullet-right table-cell">
                    <!--Language:-->
                    <el-select :disabled="!editable"
                               v-model="activity.language"
                               :placeholder='$t("Itinerary.TripActivity.tooltip.language")'
                               filterable
                               default-first-option
                               allow-create
                               style="color:black;font-size: 1rem;"
                               @blur="emitAutosave"
                               @change="emitAutosave"
                    >
                        <el-option v-for="lang in languages"
                                   :key="lang"
                                   :label="lang"
                                   :value="lang"
                        ></el-option>
                    </el-select>
                </div>
            </div>
            <div class="col-12 activity-prop" style="white-space: nowrap;"
                 v-if="!item.included"
            >
                <div class="icon-left table-cell" style="white-space: nowrap;" v-tooltip='$t("Itinerary.TripActivity.tooltip.price")'>
                    <i class="fa fa-dollar-sign"></i>
                </div>
                <div class="table-cell" style="padding-left: 5px;">
                    <el-select :disabled="!editable"
                               v-model="activity.priceType"
                               :placeholder='$t("Itinerary.TripActivity.tooltip.price_type")'
                               filterable
                               default-first-option
                               style="color:black;font-size: 1rem;"
                               @blur="emitAutosave"
                               @change="emitAutosave"
                    >
                        <el-option v-for="priceType in priceTypes"
                                   :key="priceType.value"
                                   :label="priceType.label"
                                   :value="priceType.value"
                        ></el-option>
                    </el-select>
                </div>
                <div class="table-cell" style="padding-left: 5px;"
                     v-if="showPrice(activity)"
                >
                    <input  :readonly="!editable"
                            type="number"
                            class="form-control"
                            :placeholder='$t("Itinerary.TripActivity.tooltip.price")'
                            v-model="activity.price"
                            @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell" style="padding-left: 5px;"
                     v-if="showPrice(activity)"
                >
                    {{itData.currencyCode}}
                </div>
            </div>
            <div class="col-12 activity-prop" style="white-space: nowrap;" v-if="item.included && itData.status === 'SOLD'">
                <div class="icon-left table-cell" style="white-space: nowrap;" v-tooltip='$t("Itinerary.TripActivity.tooltip.local_contact")'>
                    <i class="fa fa-user"></i>
                </div>
                <div class="table-cell" style="padding-left: 5px;">
                    <input  :readonly="!editable"
                            type="text"
                            class="form-control"
                            :placeholder='$t("Itinerary.TripActivity.tooltip.name")'
                            v-model="activity.contacts[0].name"
                            @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell" style="padding-left: 5px;">
                    <input  :readonly="!editable"
                            type="text"
                            class="form-control"
                            :placeholder='$t("Itinerary.TripActivity.tooltip.phone")'
                            v-model="activity.contacts[0].phone"
                            @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell" style="padding-left: 5px;">
                    <input  :readonly="!editable"
                            type="text"
                            class="form-control"
                            :placeholder='$t("Itinerary.TripActivity.tooltip.email")'
                            v-model="activity.contacts[0].email"
                            @blur="emitAutosave"
                    />
                </div>
            </div>
            <div class="col-12 activity-prop" style="white-space: nowrap;" v-if="itData.status === 'SOLD'">
                <div class="icon-left table-cell" style="white-space: nowrap;" v-tooltip='$t("Itinerary.TripActivity.tooltip.start_time")'>
                    <i class="fa fa-clock"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 5px;">
                    <!--Price:-->
                    <el-time-select
                            :disabled="!editable"
                            v-model="activity.startTime"
                            :picker-options="{
                                start: '00:00',
                                step: '00:15',
                                end: '23:30'
                              }"
                            :placeholder='$t("Itinerary.TripActivity.tooltip.start_time")'
                            @blur="emitAutosave"
                            @change="emitAutosave"
                    >
                    </el-time-select>
                </div>
            </div>
            <div class="container-fluid">
                <draggable class="row"
                           style="margin-top: 10px;"
                           v-model="activity.images"
                           :options="{animation:150, disabled: !editable}"
                >
                    <div class="col-lg-6 col-md-6 col-sm-12" style="margin-top:10px; margin-bottom: 10px;"
                         v-for="image in activity.images"
                         :key="image.id">
                        <button v-if="editable" class="btn btn-danger btn-sm img-delete-btn d-print-none" @click="deleteImage(activity, image)">
                            <i class="fa fa-trash"></i>
                        </button>
                        <img class="img-fluid w-100" :src="getImageUrl(image)">
                        <div class="mor-image-caption">
                            <input :readonly="!editable"
                                   type="text"
                                   v-model="image.caption"
                                   class="mor-image-caption-field"
                                   :placeholder='$t("Itinerary.TripActivity.tooltip.caption_here")'
                                   @blur="updateImageCaption(image)"
                                   @keyup.enter="updateImageCaption(image)"
                            />
                        </div>
                    </div>
                </draggable>
            </div>
            <div v-if="editable" class="col-12 d-print-none text-right">
                <button style="margin-top: 10px;margin-right: 5px;"
                        class="btn btn-danger btn-sm"
                        @click="deleteIndividualActivity(activity)"
                        v-tooltip='$t("Itinerary.TripActivity.tooltip.remove_activity")'
                >
                    <i class="fa fa-trash"></i>
                </button>
                <button v-if="editable" style="margin-top: 10px;" class="btn btn-primary btn-sm" @click="openAddImages(activity)">{{$t("Itinerary.TripActivity.button.add_images")}}</button>
            </div>
            <div class="col-12">
                <hr/>
            </div>
        </div>
        <div v-if="editable" class="row d-print-none" style="margin-top: 10px;">
            <div class="col-12 text-right">
                <button class="btn btn-primary" @click="addIndividualActivity">{{$t("Itinerary.TripActivity.button.add_activity")}}</button>
            </div>
        </div>
        <activity-image-upload-modal
                :show.sync="showImageUpload"
                :activity="selectedActivity"
                @add-image="addImage($event)"
        >
        </activity-image-upload-modal>
        <item-map-modal :show.sync="showMapModal"
                        :item="selectedActivity"
                        @update-location="updateActivityAddress($event)"
        ></item-map-modal>
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
                :extra-data="extraData"
        >
        </save-to-library-modal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import ActivityImageUploadModal from '../modal/ActivityImageUploadModal';
    import ItemMapModal from '../modal/ItemMapModal';
    import {showCustomDialog, stringNotEmpty} from "../../../utils";
    import {imageUrlMixin, autosaveMixin, moveItemMixin, itemCommonPropsMixin, stringUtilsMixin} from "./item-mixins";
    import {INDIVIDUAL_ACTIVITY} from "../itinerary-templates";
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";

    export default {
        components: {
            draggable,
            ActivityImageUploadModal,
            ItemMapModal,
            SaveToLibraryModal,
        },
        mixins: [
            autosaveMixin,
            imageUrlMixin,
            moveItemMixin,
            itemCommonPropsMixin,
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            status: {
                type: String,
                required: false,
                default: function () { return "DRAFT" },
            },
            currency: {
                type: String,
                required: false,
                default: function () { return "EUR" },
            },
        },
        data() {
            return {
                showSetNameModal: false,
                showImageUpload: false,
                showMapModal: false,
                // showButtons: false,
                selectedActivity: {
                    images: [],
                },

                languages: [
                    "English",
                    "Russian"
                ],
                durations: [
                    "1h",
                    "2h",
                    "3h",
                    "4h",
                    "5h",
                    "6h",
                    "7h",
                    "8h",
                    "9h",
                    "10h",
                ],

                localCurrency: "EUR", // var local copiada da prop, para poder ser alterada
                extraData: {},
            }
        },
        watch: {
            "currency"() {
                this.localCurrency = this.currency;
            },
            "showSetNameModal"() {
                if (this.showSetNameModal) {
                    this.extraData = {
                        status: this.status,
                        currencyCode: this.itData.currencyCode,
                    };
                }
            },
        },
        computed: {
            priceTypes() {
                return [
                    {
                        value: "FREE",
                        label: this.$t("Itinerary.TripActivity.price_types.free"),
                    },
                    {
                        value: "PER_PERSON",
                        label: this.$t("Itinerary.TripActivity.price_types.per_person"),
                    },
                    {
                        value: "PER_GROUP",
                        label: this.$t("Itinerary.TripActivity.price_types.per_group"),
                    },
                ];
            },
            itData() {
                if (!this.libmode && this.$store) {
                    return this.$store.getters.itData;
                } else {
                    return {
                        status: this.status,
                        currencyCode: this.localCurrency,
                    }
                }
            },
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library");
            },
            titlePlaceholder() {
                return this.$t("Itinerary.TripGenericItem.tooltip.title");
            },
            subtitlePlaceholder() {
                return this.$t("Itinerary.TripActivity.optional_title");
            },
            descriptionPlaceholder() {
                return (this.item.included ?
                    this.$t("Itinerary.TripActivity.included_description") :
                    this.$t("Itinerary.TripActivity.optional_description")
                );
            }
        },
        mounted() {
            this.localCurrency = this.currency;
        },
        methods: {
            addIndividualActivity() {
                this.item.list.push(INDIVIDUAL_ACTIVITY());
                this.emitAutosave();
            },
            hasLocation(activity) {
                /*return stringNotEmpty(activity.address) &&
                    activity.coordinates.lat &&
                    activity.coordinates.lng;*/
                return activity.coordinates.lat &&
                    activity.coordinates.lng;
            },
            mapBtnTooltip(activity) {
                if (this.hasLocation(activity)) {
                    return this.$t("Itinerary.TripActivity.tooltip.show_on_map");
                } else {
                    return this.$t("Itinerary.TripActivity.tooltip.set_on_map");
                }
            },
            openAddImages(activity) {
                this.selectedActivity = activity;
                this.showImageUpload = true;
            },
            addImage(event) {
                event.activity.images.push(event.image);
                this.emitAutosave();
            },
            deleteImage(activity, image) {
                const self = this;
                const index = activity.images.findIndex((i) => {
                    return i.id === image.id;
                });
                activity.images.splice(index, 1);
                self.$emit("update:item", self.item);
                self.emitAutosave();

                /*showCustomDialog(
                    "Are you sure?",
                    "Remove this image?",
                    "Remove",
                    "btn-danger",
                    function() {
                        // if (image.type === 'GOOGLE') {
                        const index = activity.images.findIndex((i) => {
                            return i.id === image.id;
                        });
                        activity.images.splice(index, 1);
                        self.$emit("update:item", self.item);
                        self.emitAutosave();
                    }
                );*/
            },
            openMapModal(activity) {
                this.selectedActivity = activity;
                this.showMapModal = true;
            },
            updateActivityAddress(data) {
                this.selectedActivity.address = data.address;
                this.selectedActivity.coordinates.lat = data.lat;
                this.selectedActivity.coordinates.lng = data.lng;
                this.$emit("update:item", this.item);
                this.emitAutosave();
            },
            /*clearLocation() {
                this.selectedActivity.address = null;
                this.selectedActivity.coordinates.lat = null;
                this.selectedActivity.coordinates.lng = null;
                this.$emit("update:item", this.item);
                this.emitAutosave();
            },*/
            deleteIndividualActivity(activity) {
                const self = this;
                showCustomDialog(
                    this.$t("Itinerary.TripActivity.messages.delete_item_title"),
                    this.$t("Itinerary.TripActivity.messages.delete_item_message"),
                    this.$t("Itinerary.TripActivity.messages.delete_item_button"),
                    "btn-danger",
                    function() {
                        // if (image.type === 'GOOGLE') {
                        const index = self.item.list.findIndex((i) => {
                            return i.id === activity.id;
                        });
                        self.item.list.splice(index, 1);
                        self.$emit("update:item", self.item);
                        self.emitAutosave();
                        /*} else {
                            // o que fazer mais aqui?
                        }*/
                    }
                );
            },
            showPrice(activity) {
                return activity.priceType !== undefined &&
                    activity.priceType !== null &&
                    activity.priceType !== '' &&
                    activity.priceType !== 'FREE';
            },
            handleUp(index) {
                if (index > 0) {
                    this.$refs["input" + (index - 1)][0].focus();
                }
            },
            handleDown(index) {
                if (index < this.item.list.length - 1) {
                    this.$refs["input" + (index + 1)][0].focus();
                }
            },
            /*handleBackspace(bullet, index, event) {
                if (bullet.length === 0 && this.item.description.length > 1) {
                    // console.log("### DELETE " + index);
                    this.item.description.splice(index, 1);
                    if (index > 0) {
                        // console.log("### FOCUS " + (index-1));

                        this.$refs["input" + (index - 1)][0].focus();
                    }
                    event.preventDefault();
                }
            },*/
            handleEnter(index) {
                this.item.list.splice(index + 1, 0, INDIVIDUAL_ACTIVITY());
                this.$nextTick(() => {
                    this.$refs["input" + (index + 1)][0].focus();
                });
            },
        }
    }
</script>

<style scoped>
    .icon-left {
        width: 25px;
        text-align: center;
    }

    .activity-prop {
        padding-left: 39px;
        padding-top: 5px;
    }
</style>
