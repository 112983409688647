<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft" v-if="droppable">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
                <button class="btn btn-danger"
                        v-if="droppable"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
<!--
            <br/>
            <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                <i class="fa fa-arrows-alt"></i>
            </button>
-->
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <input :readonly="!editable" type="text" class="name-input" v-model="item.title" @blur="emitAutosave"/>
                </h3>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12">
                <textarea-autosize v-if="editable"
                        class="form-control body-font-size"
                        :placeholder='$t("Itinerary.TripDescription.tooltip.type_something_here")'
                        v-model="item.description"
                        :min-height="30"
                        :max-height="500"
                        @blur.native="emitAutosave"
                ></textarea-autosize>
                <span v-else v-html="cleanupString(item.description)"></span>
                <!--<textarea class="form-control" placeholder="Describe the trip here"></textarea>-->
            </div>
        </div>
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
        >
        </save-to-library-modal>
    </div>
</template>

<script>

    import {autosaveMixin, itemCommonPropsMixin, moveItemMixin, stringUtilsMixin} from "./item-mixins";
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";

    export default {
        components: {
            SaveToLibraryModal,
        },
        mixins: [
            autosaveMixin,
            moveItemMixin,
            itemCommonPropsMixin,
            stringUtilsMixin
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            droppable: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library")
                    ;
            },
        },
        data() {
            return {
                showSetNameModal: false,
            }
        },
    }
</script>

<style scoped>
</style>
