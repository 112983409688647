import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        itData: {
            template: true,
            name: null,
            numDays: null,
            dateFormat: null,
            startDate: null,
            endDate: null,
            updateDate: null,
            clients: null,
            published: false,
            tripData: {
                version: "",
                sections: [],
            },
        },
    },
    getters: {
        itData (state) {
            return state.itData;
        },
    },
    mutations: {
        'SET_IT_DATA'(state, itData) {
            state.itData = itData;
        },
        'UPDATE_STATUS'(state, value) {
            state.itData.published = value;
        },
    },
    actions: {
        setItData: ({commit}, itData) => {
            commit('SET_IT_DATA', itData);
        },

        setPublishedStatus: ({commit}, value) => {
            commit('UPDATE_STATUS', value);
        },


    },
})
