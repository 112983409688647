<template>
    <div class="modal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t("Itinerary.modal.ItinerarySendEmail.send_itinerary_by_email_pdf")}}:</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <form v-on:submit.prevent="sendEmail" novalidate>
                        <div class="form-group">
                            <label class="control-label">PDF Type</label>
                            <select class="form-control" v-model="quality">
                                <option value="FULL">Full size PDF (high quality images)</option>
                                <option value="MEDIUM">Medium size PDF (medium quality images)</option>
                                <option value="LOW">Reduced size PDF (low quality images)</option>
                            </select>
                            <input type="hidden" class="form-control" :class="{'is-invalid' : $v.clients.$error}"/>
                            <div class="invalid-feedback" v-show="$v.clients.$error && !$v.clients.required">
                                {{$t("Itinerary.modal.ItinerarySendEmail.field_required")}}
                            </div>
                        </div>
                        <div class="form-group"  :class="{ 'has-error': $v.clients.$error}">
                            <label class="control-label" >{{$t("Itinerary.modal.ItinerarySendEmail.clients")}}</label>
                            <el-select
                                    style="width:100%;color:black;font-size: 1rem;"
                                    v-model="clients"
                                    multiple
                                    default-first-option
                                    filterable
                                    :placeholder='$t("Itinerary.modal.ItinerarySendEmail.clients_select")'
                            >
                                <el-option v-for="client in clientList"
                                           :key="client.id"
                                           :label="client.name"
                                           :value="client.id"
                                ></el-option>
                            </el-select>
                            <input type="hidden" class="form-control" :class="{'is-invalid' : $v.clients.$error}"/>
                            <div class="invalid-feedback" v-show="$v.clients.$error && !$v.clients.required">
                                {{$t("Itinerary.modal.ItinerarySendEmail.field_required")}}
                            </div>
                        </div>
                        <div class="form-group form-check">
                            <input type="checkbox" class="form-check-input" id="ccEmail1" v-model="ccCopy">
                            <label class="form-check-label" for="ccEmail1">
                                {{$t("Itinerary.modal.ItinerarySendEmail.send_copy_to_me")}}
                            </label>
                        </div>
                        <div class="form-group" :class="{ 'has-error': $v.text.$error }">
                            <label class="control-label">{{$t("Itinerary.modal.ItinerarySendEmail.text")}}</label>
                            <textarea class="form-control"
                                      rows="4"
                                      v-model.trim="$v.text.$model"
                                      :class="{'is-invalid' : $v.text.$error}"
                            ></textarea>
                            <div class="invalid-feedback" v-show="$v.text.$error && !$v.text.required">
                                {{$t("Itinerary.modal.ItinerarySendEmail.field_required")}}.
                            </div>
                        </div>
                        <div class="form-group" style="text-align: right;">
                            <v-button type="submit" class="btn btn-primary float-right" :loading="loading">
                                <i class="fa fa-envelope"></i> {{$t("Itinerary.modal.ItinerarySendEmail.send")}}
                            </v-button>
                            <button type="button" class="btn btn-link float-right" data-dismiss="modal">{{$t("Itinerary.modal.ItinerarySendEmail.cancel")}}</button>
                        </div>
                    </form>
                </div>
            </div> <!-- /.modal-content -->
        </div> <!-- /.modal-dialog -->
    </div> <!-- /.modal -->
</template>

<script>
    import {handleAjaxError, showError, showSuccess} from "../../../utils";
    import axios from 'axios'
    import vButton from '../../../components/VButton';
    import { required } from 'vuelidate/lib/validators';
    import {SECTION_TYPE_COVER} from "../itinerary-templates";

    export default {
        components: {
            vButton,
        },
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            itData: {
                type: Object,
                required: true,
            },
        },
        validations: {
            text: {
                required,
            },
            clients: {
                required,
            },
        },
        data() {
            return {
                ccCopy: false,
                text: "",
                clients: [],
                quality: 'FULL',

                clientList: [],
                loading: false,
            }
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function (e) {
                self.$emit('update:show', false);
            });

        },
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                    this.ccCopy = false;
                    this.text = "";
                    this.clients = this.itData.clients.slice();
                    this.loadClientList();
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
            }
        },
        computed: {
        },
        methods: {
            loadClientList() {
                const self = this;
                const url = jsRoutes.controllers.ItineraryController.clientListSelect().url;
                this.$http.get(url).then(function(response) {
                    self.clientList = response.data.filter(client => {
                        return self.clients.includes(client.id);
                    });
                }, function(response) {
                    handleAjaxError(response);
                });

            },
            close() {
                this.$emit('update:show', false);
            },
            sendEmail: function () {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.log("### INVALID");
                    return;
                }
                this.doSendEmail();
            },
            doSendEmail: function () {
                this.loading = true;

                const cover = this.itData.tripData.sections.find(section => {
                    return section.type === SECTION_TYPE_COVER;
                });

                const hasCover = cover !== undefined;

                const url = jsRoutes.controllers.ItineraryController.emailItinerary().url;
                const data = {
                    id: this.itData.id,
                    clients: this.clients,
                    ccCopy: this.ccCopy,
                    text: this.text,
                    printCover: hasCover,
                    quality: this.quality,
                };

                const self = this;
                axios.post(url, data).then(function(response) {
                    self.loading = false;
                    showSuccess(
                        self.$t("success.general_title"),
                        self.$t("Itinerary.modal.ItinerarySendEmail.email_sent")
                    );
                    self.close();
                }, function(response) {
                    self.loading = false;
                    showError(
                        self.$t("error.general_title"),
                        self.$t("Itinerary.modal.ItinerarySendEmail.failed_email_sent") + ": ", response.data);
                });
            },
        }
    }
</script>

<style scoped>
</style>
