<template>

    <div class="card">

        <div class="card-header">
            <h1>{{$t("root.profile")}}</h1>
        </div>

        <div class="card-body">

            <div class="row justify-content-end mb-4">
                <div class="col-auto">
                    <a :href="changePasswordUrl" class="btn btn-primary">{{$t("root.change_password")}}</a>
                </div>
            </div>

            <div class="form-group row">
                <h2 class="col-3 col-form-label text-right">{{$t("root.profile_image")}}</h2>
                <div class="col-9">
                    <div class="d-flex align-items-start">
                        <img v-if="user.hasImageFile && !showCropper"
                             :src="dnlUrl"
                             style="min-width:200px;width:200px;height:200px;border-radius:50%;"
                        />
                        <img v-if="!user.hasImageFile"
                             src="/assets/images/male-icon.jpg"
                             style="min-width:200px;width:200px;height:200px;border-radius:50%;"
                        />
                        <div class="ml-2">
                            {{$t("root.upload_new_image")}}
                            <br/>
                            <button class="btn btn-primary" @click="chooseFile">{{$t("root.choose_file")}}</button>
                            <input type="file" class="invisible" ref="fileInput" v-on:change.prevent="setImage"/>
                            <hr/>
                            <v-button v-if="user.hasImageFile"
                                      class="btn btn-danger"
                                      :loading="loadingRemove"
                                      @click.native="removeImage"
                            >
                                {{$t("root.remove_image")}}
                            </v-button>
                        </div>
                    </div>
                </div>
            </div>


            <image-crop-modal :show.sync="showCropper"
                              :img-src="imgSrc"
                              v-on:cropped="setCropped">
            </image-crop-modal>

            <hr/>

            <form v-on:submit.prevent="editUserProfile" novalidate>
                <div class="form-group row">
                    <label for="inputFirst" class="col-3  col-form-label text-right">{{$t("root.firstname")}}:</label>
                    <div class="col-8">
                        <input type="text" class="form-control" id="inputFirst"
                               v-model="user.firstName"
                               :class="{ 'is-invalid':  $v.user.firstName.$error }"
                               :placeholder='$t("root.firstname")'>
                        <div class="invalid-feedback" v-if="!$v.user.firstName.required">{{$t("root.required")}}</div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputLast" class="col-3  col-form-label text-right">{{$t("root.lastname")}}:</label>
                    <div class="col-8">
                        <input type="text" class="form-control" id="inputLast"
                               v-model="user.lastName"
                               :class="{ 'is-invalid':  $v.user.lastName.$error }"
                               :placeholder='$t("root.lastname")'>
                        <div class="invalid-feedback" v-if="!$v.user.lastName.required">{{$t("root.required")}}</div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputEmail" class="col-3 col-form-label text-right">{{$t("root.email")}}:</label>
                    <div class="col-8">
                        <input type="text" class="form-control" id="inputEmail"
                               v-model="user.email"
                               :class="{ 'is-invalid':  $v.user.email.$error }"
                               :placeholder='$t("root.email")'>
                        <div class="invalid-feedback" v-if="!$v.user.email.required">{{$t("root.required")}}</div>
                        <div class="invalid-feedback" v-if="!$v.user.email.email">{{$t("root.email_valid")}}</div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputPhone" class="col-3 col-form-label text-right">{{$t("root.phone")}}:</label>
                    <div class="col-8">
                        <input type="text" class="form-control" id="inputPhone"
                               v-model="user.phone"
                               :class="{ 'is-invalid':  $v.user.phone.$error }"
                               :placeholder='$t("root.phone")'>
                    </div>
                </div>

                <div class="row justify-content-end">
                    <div class="col-auto">
                        <a :href="backUrl" class="btn btn-secondary-o">{{$t("root.cancel")}}</a>
                        <v-button type="submit" :loading="loading" class="btn btn-primary">{{$t("root.save")}}</v-button>
                    </div>
                </div>
            </form>
        </div>
    </div>

</template>

<script>

    import {required, email} from 'vuelidate/lib/validators'
    import {handleAjaxError, showError, showSuccess} from '../utils'

    import ImageCropModal from '../components/ImageCropModal'
    import VButton from "../components/VButton";


    export default {
        name: "UserProfile",

        components: {
            ImageCropModal,
            VButton
        },

        data() {
            return {
                changePasswordUrl: jsRoutes.controllers.Account.changePassword().url,
                backUrl: jsRoutes.controllers.Application.index().url,
                user: {
                    hasImageFile: false,
                },

                dnlUrl: jsRoutes.controllers.Account.downloadUserImage().url,

                imgSrc: '',
                showCropper: false,
                imgCropped: '',

                loading: false,
                loadingRemove: false,
            }
        },

        watch: {
            showCropper() {
                if (!this.showCropper) {
                    this.$refs.fileInput.value = ''
                }
            }
        },

        created() {
            this.loadUserData()
        },


        validations: {
            user: {
                firstName: {
                    required,
                },
                lastName: {
                    required,
                },
                email: {
                    required,
                    email
                },
                phone: {
                },
            },
        },

        methods: {

            loadUserData() {
                let url = jsRoutes.controllers.Account.loadUserProfile().url
                this.$http.get(url).then(response => {
                    this.user = response.data;
                }, error => {
                    handleAjaxError(error)
                })
            },

            editUserProfile() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    return
                }
                const self = this;
                this.loading = true;
                let url = jsRoutes.controllers.Account.editUserProfile().url
                this.$http.post(url, this.user).then(response => {
                    self.loading = false;
                    showSuccess(
                        self.$t("success.general_title"),
                        self.$t("root.profile_updated")
                    );
                }, error => {
                    self.loading = false;
                    handleAjaxError(error)
                })

            },

            removeImage() {
                const self = this;
                self.loadingRemove = true;
                let url = jsRoutes.controllers.Account.removeUserImage().url
                this.$http.post(url, this.user).then(response => {
                    self.loadingRemove = false;
                    self.user.hasImageFile = false;
                    showSuccess(
                        self.$t("success.general_title"),
                        self.$t("root.image_removed")
                    )
                }, error => {
                    self.loadingRemove = false;
                    handleAjaxError(error)
                })

            },

            chooseFile() {
                this.$refs.fileInput.click()
            },

            setImage(e) {
                // console.log('going to setImage...')
                const file = e.target.files[0]
                if (!file.type.includes('image/')) {
                    showError(
                        self.$t("error.general_title"),
                        self.$t("root.only_images_allowed")
                    )
                    return
                }
                let self = this;
                if (typeof FileReader === 'function') {
                    const reader = new FileReader()
                    reader.onload = (event) => {
                        self.showCropper = true;
                        self.imgSrc = event.target.result
                    }
                    reader.readAsDataURL(file)
                } else {
                    showError(
                        self.$t("root.api_not_supported")
                    )
                }
            },

            setCropped(e) {
                let self = this
                this.imgCropped = e
                this.showCropper = false
                let url = jsRoutes.controllers.Account.uploadUserImage().url
                this.$http.post(url, {data: this.imgCropped}).then(response => {
                    showSuccess(
                        self.$t("success.general_title"),
                        self.$t("root.file_uploaded")
                    )
                    self.user.hasImageFile = true;
                    self.dnlUrl = jsRoutes.controllers.Account.downloadUserImage().url + '?sys=' + new Date().getTime();
                }, error => {
                    handleAjaxError(error)
                })
            },


        },
    }
</script>

<style scoped>

</style>
