<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <input type="text" class="name-input" v-model="item.includedTitle"/>
                </h3>
            </div>
            <div class="col-12" style="padding-top: 10px;">
                <table class="table">
                    <thead class="thead-light">
                    <tr>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.date")}}
                        </th>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.activity")}}
                        </th>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.duration")}}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="activity in includedActivies" :key="activity.id">
                        <td>{{getActivityDate(activity)}}</td>
                        <td>{{activity.description}}</td>
                        <td>{{activity.duration}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <div class="row">
            <div class="col-12" style="padding-top: 10px;">
                <h3>
                    <input
                            type="text"
                            class="name-input"
                            v-model="item.flightsTitle"
                    />
                </h3>
            </div>
            <div class="col-12" style="padding-top: 10px;">
                <table class="table">
                    <thead class="thead-light">
                    <tr>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.date")}}
                        </th>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.airline")}}
                        </th>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.departure_location")}}
                        </th>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.time")}}
                        </th>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.arrival")}}
                        </th>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.time")}}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in flights"  :key="item.id">
                        <td>{{getFlightDate(item)}}</td>
                        <td>{{getFlightAirline(item.airline)}}</td>
                        <td>{{getFlightLocation(item.startLocation)}}</td>
                        <td>{{item.startTime}}</td>
                        <td>{{getFlightLocation(item.endLocation)}}</td>
                        <td>{{item.endTime}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-12" style="padding-top: 10px;">
                <h3>
                    <input
                            type="text"
                            class="name-input"
                            v-model="item.hotelOthersTitle"
                    />
                </h3>
            </div>
            <div class="col-12" style="padding-top: 10px;">
                <table class="table">
                    <thead class="thead-light">
                    <tr>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.date")}}
                        </th>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.type")}}
                        </th>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.departure_location")}}
                        </th>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.arrival")}}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in includedItems"  :key="item.id">
                        <td>{{getItemDates(item)}}</td>
                        <td>{{getItemType(item)}}</td>
                        <td>{{getDepartureLocation(item)}}</td>
                        <td>{{getArrivalLocation(item)}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-12">
                <h3>
                    <input type="text" class="name-input" v-model="item.notIncludedTitle"/>
                </h3>
            </div>
            <div class="col-12" style="padding-top: 10px;">
                <table class="table">
                    <thead class="thead-light">
                    <tr>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.date")}}
                        </th>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.activity")}}
                        </th>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.price")}}
                        </th>
                        <th>
                            {{$t("Itinerary.TripWhatsIncluded.table.duration")}}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="activity in notIncludedActivies" :key="activity.id">
                        <td>{{getActivityDate(activity)}}</td>
                        <td>{{activity.description}}</td>
                        <td>{{getActivityPrice(activity)}}</td>
                        <td>{{activity.duration}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </div>
</template>

<script>
    import {
        getSectionDateForActivityId,
        getSectionDateForIndividualFlightId,
        getSectionDateForItemId,
        getSectionDayNumForActivityId,
        getSectionDayNumForFlightId,
        getSectionDayNumForItemId,
        ITEM_TYPE_ACTIVITY,
        ITEM_TYPE_CAR_RENTAL,
        ITEM_TYPE_FLIGHT,
        ITEM_TYPE_MEAL,
        ITEM_TYPE_SLEEP,
        ITEM_TYPE_TRANSPORTATION
    } from "../../itinerary-templates";
    import {stringNotEmpty} from "../../../../utils";
    import {formatMoment, getDateMomentForDayNum} from "../../../../date-utils";

    export default {
        components: {
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
            itData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                // showButtons: false,
            }
        },
        mounted() {
            if (!this.item.flightsTitle) {
                this.item.flightsTitle = this.$t("v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_flight_title");
            }
            if (!this.item.hotelOthersTitle) {
                this.item.hotelOthersTitle = this.$t("v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_hotel_others_title");
            }
        },
        computed: {
            sections() {
                if (this.itData && this.itData.tripData && this.itData.tripData.sections) {
                    return this.itData.tripData.sections;
                } else {
                    return [];
                }
            },
            flights() {
                let result = [];
                this.sections.forEach(section => {
                    section.items.forEach(item => {
                        if (item.type === ITEM_TYPE_FLIGHT) {
                            if (item.list !== undefined) {
                                item.list.forEach(flight => {
                                    result.push(flight);
                                });
                            }
                        }
                    });
                });
                return result;
            },
            includedActivies() {
                let result = [];
                this.sections.forEach(section => {
                    section.items.forEach(item => {
                        if (item.type === ITEM_TYPE_ACTIVITY && item.included) {
                            if (item.list !== undefined) {
                                item.list.forEach(activity => {
                                    if (stringNotEmpty(activity.description)) {
                                        result.push(activity);
                                    }
                                });
                            }
                        }
                    });
                });
                return result;
            },
            notIncludedActivies() {
                let result = [];
                this.sections.forEach(section => {
                    section.items.forEach(item => {
                        if (item.type === ITEM_TYPE_ACTIVITY && !item.included) {
                            if (item.list !== undefined) {
                                item.list.forEach(activity => {
                                    if (stringNotEmpty(activity.description)) {
                                        result.push(activity);
                                    }
                                });
                            }
                        }
                    });
                });
                return result;
            },
            includedItems() {
                let result = [];
                this.sections.forEach(section => {
                    section.items.forEach(item => {
                        if (item.type === ITEM_TYPE_TRANSPORTATION ||
                            item.type === ITEM_TYPE_CAR_RENTAL ||
                            item.type === ITEM_TYPE_SLEEP ||
                            item.type === ITEM_TYPE_MEAL/* ||
                            (item.type === ITEM_TYPE_ACTIVITY && item.included)*/
                        ) {
                            result.push(item);
                        }
                    });
                });
                return result;
            }
        },
        methods: {
            getItemDates(item) {
                if (item.type === ITEM_TYPE_CAR_RENTAL ||
                    item.type === ITEM_TYPE_SLEEP
                ) {
                    if (this.itData.startDate) {
                        if (item.startDay && item.endDay) {
                            const startDate = formatMoment(
                                getDateMomentForDayNum(item.startDay, this.itData.startDate, this.itData.dateFormat), this.itData.dateFormat
                            );
                            const endDate = formatMoment(
                                getDateMomentForDayNum(item.endDay, this.itData.startDate, this.itData.dateFormat), this.itData.dateFormat
                            );
                            return startDate + " - " + endDate;
                        } else {
                            return item.startDate + " - " + item.endDate;
                        }
                    } else {
                        if (item.startDay && item.endDay) {
                            return this.$t("v2.Itinerary.defaults.DAY") + " " + item.startDay + " - " + item.endDay;
                        } else {
                            return "";
                        }
                    }
                } else if (item.type === ITEM_TYPE_TRANSPORTATION) {
                    if (this.itData.startDate) {
                        if (item.startDay && item.endDay) {
                            const startDate = formatMoment(
                                getDateMomentForDayNum(item.startDay, this.itData.startDate, this.itData.dateFormat), this.itData.dateFormat
                            );
                            const endDate = formatMoment(
                                getDateMomentForDayNum(item.endDay, this.itData.startDate, this.itData.dateFormat), this.itData.dateFormat
                            );
                            return startDate + " - " + endDate;
                        } else if (item.startDate !== undefined && item.startDate !== null &&
                            item.endDate !== undefined && item.endDate !== null
                        ) {
                            return item.startDate + " - " + item.endDate;
                        } else {
                            return getSectionDateForItemId(this.sections, this.itData.startDate, this.itData.dateFormat, item.id);
                        }
                    } else {
                        if (item.startDay && item.endDay) {
                            return this.$t("v2.Itinerary.defaults.DAY") + " " + item.startDay + " - " + item.endDay;
                        } else {
                            return this.$t("v2.Itinerary.defaults.DAY") + " " + getSectionDayNumForItemId(this.sections, item.id);
                        }
                    }
                } else if (item.type === ITEM_TYPE_MEAL) {
                    if (this.itData.startDate) {
                        return getSectionDateForItemId(this.sections, this.itData.startDate, this.itData.dateFormat, item.id);
                    } else {
                        return this.$t("v2.Itinerary.defaults.DAY") + " " + getSectionDayNumForItemId(this.sections, item.id);
                    }
                } else {
                    if (this.itData.startDate) {
                        return getSectionDateForItemId(this.sections, this.itData.startDate, this.itData.dateFormat, item.id);
                    } else {
                        return this.$t("v2.Itinerary.defaults.DAY") + " " + getSectionDayNumForItemId(this.sections, item.id);
                    }
                }
            },
            getActivityDate(activity) {
                if (this.itData.startDate) {
                    return getSectionDateForActivityId(this.sections, this.itData.startDate, this.itData.dateFormat, activity.id);
                } else {
                    return this.$t("v2.Itinerary.defaults.DAY") + " " + getSectionDayNumForActivityId(this.sections, activity.id);
                }
            },
            getFlightDate(flight) {
                if (this.itData.startDate) {
                    return getSectionDateForIndividualFlightId(this.sections, this.itData.startDate, this.itData.dateFormat, flight.id);
                } else {
                    return this.$t("v2.Itinerary.defaults.DAY") + " " + getSectionDayNumForFlightId(this.sections, flight.id);
                }
            },
            getActivityPrice(activity) {
                if (activity.priceType === "FREE") {
                    return this.$t("Itinerary.TripActivity.price_types.free");
                } else {
                    return activity.price;
                }
            },
            getItemType(item) {
                switch (item.type) {
                    /*case ITEM_TYPE_ACTIVITY:
                        return item.title;*/
                    case ITEM_TYPE_MEAL:
                        return item.title + " - " + item.name;
                    case ITEM_TYPE_SLEEP:
                        return item.name;
                    case ITEM_TYPE_CAR_RENTAL:
                        return item.name;
                    case ITEM_TYPE_TRANSPORTATION:
                        return item.name;
                    case ITEM_TYPE_FLIGHT:
                        return item.name;
                    default:
                        return item.title !== undefined ? item.title : item.name;
                }
            },
            getDepartureLocation(item) {
                switch (item.type) {
                    /*case ITEM_TYPE_ACTIVITY:
                        return item.address;*/
                    case ITEM_TYPE_MEAL:
                        return item.address;
                    case ITEM_TYPE_SLEEP:
                        return item.address;
                    case ITEM_TYPE_CAR_RENTAL:
                    case ITEM_TYPE_TRANSPORTATION:
                        if (item.startLocation.address !== undefined && item.startLocation.address !== null) {
                            return item.startLocation.address;
                        } else {
                            return item.startLocation.city;
                        }
                    default:
                        return "";
                }
            },
            getArrivalLocation(item) {
                switch (item.type) {
                    /*case ITEM_TYPE_ACTIVITY:
                        return "";*/
                    case ITEM_TYPE_MEAL:
                        return "";
                    case ITEM_TYPE_SLEEP:
                        return "";
                    case ITEM_TYPE_CAR_RENTAL:
                    case ITEM_TYPE_TRANSPORTATION:
                        if (item.endLocation.address !== undefined && item.endLocation.address !== null) {
                            return item.endLocation.address;
                        } else {
                            return item.endLocation.city;
                        }
                    default:
                        return "";
                }
            },
            getFlightLocation(location) {
                let result = location.city ? location.city + " " : "";
                if (location.icao) {
                    result += "(" + location.icao + ")";
                }
                return result;
            },
            getFlightAirline(airline) {
                let result = airline.name ? airline.name + " " : "";
                if (airline.icao) {
                    result += "(" + airline.icao + ")";
                }
                return result;
            },

        }
    }
</script>

<style scoped>
</style>
