<template>
    <div style="height: 1500px;position: relative;">
        <div v-if="!libmode && droppable && editable" class="d-print-none trip-item-button-bar">
            <button class="btn btn-danger" @click="$emit('delete-item', item)" v-tooltip='$t("Itinerary.general.remove_item")'><i class="fa fa-trash"></i>
            </button>
<!--
            <br/>
            <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                <i class="fa fa-arrows-alt"></i>
            </button>
-->
        </div>
        <!--<div style="position: absolute; top:0; left:0; width: 100%;">
            <img class="img-fluid" style="width:100%;height:200px;" src="/itinerary/image?id=1"/>
        </div>-->
        <div class="row">
            <div class="col-12 text-center">
                <img class="img-fluid" style="width:100%;" :src="getImageUrl(item.image)"/>
                <button v-if="editable" class="btn btn-warning"
                        style="position:absolute;top:10px;left:25px;"
                        v-tooltip='$t("Itinerary.TripCover.tooltip.set_cover")'
                        @click="showImageModal = true"
                >
                    <i class="fa fa-image"></i>
                </button>
            </div>
        </div>
        <!--<div style="position:absolute;top:0;left:0;width:300px;">
            <img class="img-fluid" :src="agencyImageUrl"/>
        </div>-->
        <div class="row align-items-center" v-show="false">
            <div class="col-12">
                <br/>
                <br/>
                <br/>
                <h1 class="text-center">
                    <input v-if="editable"
                           type="text"
                           class="name-input text-center"
                           v-model="item.title"
                           :placeholder='$t("Itinerary.TripCover.tooltip.tour_name")'
                           ref="title"
                           @keydown.down="$refs.places.focus()"
                           v-tooltip='$t("Itinerary.general.click_to_edit")'
                           @blur="emitAutosave"

                    />
                    <span v-else>
                         {{item.title}}
                    </span>
                </h1>
            </div>
            <div class="col-12 text-center" v-show="false">
                <h2 v-if="startDate && endDate">
                    {{startDate}} - {{endDate}}
                </h2>
                <h2 v-else>
                    &nbsp;
                </h2>
            </div>
            <div class="col-12" v-show="false">
                <h2 class="text-center">
                    <input v-if="editable"
                           type="text"
                           class="name-input text-center"
                           v-model="item.placesToVisit"
                           :placeholder='$t("Itinerary.TripCover.tooltip.places_to_visit")'
                           ref="places"
                           @keydown.up="$refs.title.focus()"
                           v-tooltip='$t("Itinerary.general.click_to_edit")'
                           @blur="emitAutosave"
                    />
                    <span v-else>{{item.placesToVisit}}</span>
                </h2>
            </div>
            <div class="col-12 text-center" style="margin-top:50px;" v-show="false">
                <img class="img-fluid" style="max-height: 100px;" :src="agencyImageUrl"/>
            </div>
            <div class="col-12 text-center" v-show="false">

                <a :href="agencyData.facebookLink" class="btn btn-link" v-if="agencyData.facebookLink" target="_blank">
                    <i class="fab fa-facebook-square fa-2x"></i>
                </a>

                <a :href="agencyData.youtubeLink" class="btn btn-link" v-if="agencyData.youtubeLink" target="_blank">
                    <i class="fab fa-youtube fa-2x"></i>
                </a>

                <a :href="agencyData.instagramLink" class="btn btn-link" v-if="agencyData.instagramLink" target="_blank">
                    <i class="fab fa-instagram fa-2x"></i>
                </a>

                <a :href="agencyData.twitterLink" class="btn btn-link" v-if="agencyData.twitterLink" target="_blank">
                    <i class="fab fa-twitter-square fa-2x"></i>
                </a>

            </div>
            <div class="col-12 text-center" v-show="false">
                <h4>{{agencyData.email}}</h4>
            </div>
            <div class="col-12 text-center" v-show="false">
                <h4>{{agencyData.phone}}</h4>
            </div>
        </div>
        <cover-image-upload-modal :show.sync="showImageModal" @set-image="setImage($event)"></cover-image-upload-modal>
    </div>
</template>

<script>
    import {autosaveMixin, itemCommonPropsMixin} from "./item-mixins";
    import CoverImageUploadModal from "../modal/CoverImageUploadModal";
    import {showError} from "../../../utils";

    export default {
        components: {
            CoverImageUploadModal
        },
        mixins: [
            autosaveMixin,
            itemCommonPropsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            startDate: {
                type: String,
                required: false,
            },
            endDate: {
                type: String,
                required: false,
            },
            itinId: {
                type: Number,
                required: true,
            },
            droppable: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                agencyData: {
                    imageFilename: null,
                    imageFile: null,
                    email: null,
                    phone: null,
                    address: null,
                },

                showImageModal: false,
            }
        },
        mounted() {
            this.loadAgencyData();
        },
        computed: {
            agencyImageUrl() {
                if (this.agencyData.imageFilename !== null) {
                    return jsRoutes.controllers.Application.agencyImage(this.agencyData.imageFilename).url
                } else {
                    return null;
                }
            },
        },
        methods: {
            getImageUrl(image) {
                if (image.id && image.type) {
                    if (image.type === 'GOOGLE') {
                        return image.url;
                    } else if (image.type === 'UNSPLASH') {
                        return image.data.urls.raw + "&w=800&h=600&fit=crop&crop=edges";
                    } else if (image.type === 'LOCAL' || image.type === 'PIXABAY') {
                        // var quality esta na view twirl
                        return jsRoutes.controllers.ItineraryController.imageDownload(image.id, quality).url;
                    }
                } else {
                    return "/assets/images/default-cover.jpg";
                }
            },
            loadAgencyData() {
                const self = this;
                const url = jsRoutes.controllers.ItineraryController.loadAgencyConfiguration(this.itinId).url;

                this.$http.get(url).then(function (response) {
                    //console.log("roles -> " + JSON.stringify(data));
                    self.agencyData = response.data;

                }).catch(function (response) {
                    // handle error
                    showError(
                        self.$t("error.general_title"),
                        self.$t("Itinerary.TripCompanyInfo.messages.failed_load_data")
                    );
                });
            },
            setImage(image) {
                this.item.image = image;
                this.emitAutosave();
            }
        }
    }
</script>

<style scoped>
</style>
