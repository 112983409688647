<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.TripCancelationPolicy.tooltip.move_to_another_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
                <button class="btn btn-danger"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
<!--
            <br/>
            <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                <i class="fa fa-arrows-alt"></i>
            </button>
-->
        </div>
        <div class="row">
            <div class="col-12">
                <h3 class="text-center">
                    <input :readonly="!editable" type="text" class="name-input" v-model="item.title" @blur="emitAutosave"/>
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <textarea-autosize
                        v-if="editable"
                        class="form-control body-font-size"
                        :placeholder='$t("Itinerary.TripCancelationPolicy.tooltip.description")'
                        v-model="item.description"
                        :min-height="30"
                        :max-height="500"
                        @blur.native="emitAutosave"
                ></textarea-autosize>
                <span v-else v-html="cleanupString(item.description)"></span>
                <!--<textarea class="form-control" placeholder="Description" v-model="item.description" @blur="emitAutosave"></textarea>-->
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table" style="margin-top: 10px;">
                    <thead class="thead-light">
                    <tr>
                        <th v-if="editable" style="width:30px;">
                            &nbsp;
                        </th>
                        <th>
                            <input :readonly="!editable"
                                   type="text"
                                   class="name-input"
                                   v-model="item.colDaysTitle"
                                   @blur="emitAutosave"
                                   v-tooltip='$t("Itinerary.general.click_to_edit")'
                            />
                        </th>
                        <th>
                            <input :readonly="!editable"
                                   type="text"
                                   class="name-input"
                                   v-model="item.colPenaltyTitle"
                                   @blur="emitAutosave"
                                   v-tooltip='$t("Itinerary.general.click_to_edit")'
                            />
                        </th>
                        <th v-if="editable" style="width:30px;">
                            &nbsp;
                        </th>
                    </tr>
                    </thead>
                    <!--<tbody>-->
                    <draggable :element="'tbody'" v-model="item.tableLines"
                               :options="{animation:150, disabled: !editable}"
                               @update="emitAutosave"
                    >
                    <tr v-for="(line, index) in item.tableLines">
                        <td v-if="editable">
                            <button class="btn btn-sm btn-default" v-tooltip='$t("Itinerary.TripCancelationPolicy.tooltip.drag_to_reorder")'>
                                <i class="fa fa-bars"></i>
                            </button>
                        </td>
                        <td>
                            <input :readonly="!editable"
                                   type="text"
                                   class="name-input"
                                   :placeholder='$t("Itinerary.TripCancelationPolicy.tooltip.days_60_30")'
                                   v-model="line.days"
                                   @blur="emitAutosave"
                                   v-tooltip='$t("Itinerary.general.click_to_edit")'
                            />
                        </td>
                        <td>
                            <input :readonly="!editable"
                                   type="text"
                                   class="name-input"
                                   placeholder="20%"
                                   v-model="line.penalty"
                                   @blur="emitAutosave"
                                   v-tooltip='$t("Itinerary.general.click_to_edit")'
                            />
                        </td>
                        <td v-if="editable">
                            <button class="btn btn-sm btn-danger"
                                    @click="deleteLine(index)"
                                    v-tooltip='$t("Itinerary.general.remove_line")'
                            >
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                    </draggable>
                    <!--</tbody>-->
                </table>
            </div>
            <div v-if="editable" class="col-12 text-right">
                <button class="btn btn-sm btn-primary" @click="addTableLine">
                    {{$t("Itinerary.general.add_line")}}
                </button>
            </div>
        </div>
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
        >
        </save-to-library-modal>
    </div>
</template>

<script>

    import {autosaveMixin, itemCommonPropsMixin, moveItemMixin, stringUtilsMixin} from "./item-mixins";
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";
    import draggable from 'vuedraggable'

    export default {
        components: {
            draggable,
            SaveToLibraryModal,
        },
        mixins: [
            autosaveMixin,
            moveItemMixin,
            stringUtilsMixin,
            itemCommonPropsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        computed: {
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library");
            },
        },
        data() {
            return {
                showSetNameModal: false,
            }
        },
        methods: {
            deleteLine(index) {
                this.item.tableLines.splice(index, 1);
                this.emitAutosave();
            },
            addTableLine() {
                this.item.tableLines.push(
                    {days: null, penalty: null}
                );
                this.emitAutosave();
            }
        }
    }
</script>

<style scoped>
</style>
