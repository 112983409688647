<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h1>{{$t("Itinerary.builder.ItineraryList.itineraries")}}&nbsp;&nbsp;
                        <a v-if="subscriptionGood" :href="createItineraryUrl" class="btn btn-primary">
                            <span class="fa fa-plus-circle"></span> {{$t("Itinerary.builder.ItineraryList.create_new")}}
                        </a>
                    </h1>
                </div>
                <div class="card-body">
                    <div v-if="subscriptionBad" class="row">
                        <div class="col-sm-12 col-md-12 mx-auto">
                            <div class="alert alert-danger" role="alert">
                                {{$t("SubscriptionManager.warning.generic_lock")}}
                                <a v-if="subscriptionData.isAdmin" :href="subscriptionUrl"
                                   class="btn btn-primary">
                                    {{$t("root.manage_your_subscriptions")}}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div style="font-size:0.9rem;">
                        <vue-bootstrap-table
                                :columns="cols"
                                :values="list"
                                :show-filter="true"
                                :show-column-picker="true"
                                :sortable="true"
                                :filter-case-sensitive="false"
                                :selectable="false"
                                defaultOrderColumn="name"
                                :defaultOrderDirection="true"
                        >
                            <template v-slot:name="slotProps">
                                <a :href="editUrl(slotProps.value)">{{ slotProps.value.name }}</a>
                            </template>
                            <template v-slot:countryList="slotProps">
                                <span v-html="getCountryList(slotProps.value)"></span>
                            </template>
                            <template v-slot:days="slotProps">
                                {{getDaysLabel(slotProps.value)}}
                            </template>
                            <template v-slot:clientList="slotProps">
                                <span v-html="getClientList(slotProps.value)"></span>
                            </template>
                            <template v-slot:createDate="slotProps">
                                <span v-tooltip="slotProps.value.createDate">{{getHumanDate(slotProps.value.createDateLong)}}</span>
                            </template>
                            <template v-slot:updateDate="slotProps">
                                <span v-tooltip="slotProps.value.updateDate">{{getHumanDate(slotProps.value.updateDateLong)}}</span>
                            </template>
                            <template v-slot:published="slotProps">
                                <i v-if="slotProps.value.published" class="text-success fa fa-2x fa-check-circle"></i>
                                <i v-else class="text-danger far fa-2x fa-times-circle"></i>
                            </template>
                            <template v-slot:statusName="slotProps">
                                <span v-if="slotProps.value.status === 'SOLD'" class="badge badge-success" style="font-size: medium">
                                    {{slotProps.value.statusName}}
                                </span>
                                <span v-else-if="slotProps.value.status === 'DRAFT'" class="badge badge-warning" style="font-size: medium">
                                    {{slotProps.value.statusName}}
                                </span>
                            </template>
                            <template v-slot:oper="slotProps">
                                <button v-if="slotProps.value.status !== 'SOLD'"
                                        @click.prevent="deleteItem(slotProps.value)"
                                        type="button"
                                        class="btn btn-sm btn-danger"
                                >
                                    <i class="fa fa-trash"></i> {{$t("Itinerary.builder.ItineraryList.delete_label")}}
                                </button>
                            </template>

                        </vue-bootstrap-table>
                    </div>

                    <!--<div class="table-responsive">
                        <table class="table table-bordered table-striped table-hover">

                            <thead>
                            <tr>
                                <th>{{$t("Itinerary.builder.ItineraryList.name")}}</th>
                                <th>{{$t("Itinerary.builder.ItineraryList.countries")}}</th>
                                <th>{{$t("Itinerary.builder.ItineraryList.days")}}</th>
                                <th>{{$t("Itinerary.builder.ItineraryList.clients")}}</th>
                                <th>{{$t("Itinerary.builder.ItineraryList.manager")}}</th>
                                <th style="min-width:170px;">{{$t("Itinerary.builder.ItineraryList.created")}}</th>
                                <th style="min-width:170px;">{{$t("Itinerary.builder.ItineraryList.updated")}}</th>
                                <th>{{$t("Itinerary.builder.ItineraryList.status")}}</th>
                                <th style="width:100px;"></th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr v-for="item in list">
                                <td>
                                    <a :href="editUrl(item)">{{ item.name }}</a>
                                </td>
                                <td>{{ getCountryList(item) }}</td>
                                <td>{{ item.days }}</td>
                                <td>{{ getClientList(item) }}</td>
                                <td>{{ item.managerName }}</td>
                                <td>{{ item.createDate }}</td>
                                <td>{{ item.updateDate }}</td>
                                <td>{{ item.statusName }}</td>
                                <td>
                                    <button v-if="item.status !== 'SOLD'"
                                            @click.prevent="deleteItem(item)"
                                            type="button"
                                            class="btn btn-sm btn-danger"
                                    >
                                        <i class="fa fa-trash"></i> {{$t("Itinerary.builder.ItineraryList.delete_label")}}
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'
    import {handleAjaxError, showCustomDialog, showSuccess} from "../../utils";
    import VueBootstrapTable from "vue2-bootstrap-table2";

    export default {
        components: {
            VueBootstrapTable,
        },
        // props: ["uuid"],
        data() {
            return {
                cols: [
                    {
                        title: this.$t("Itinerary.builder.ItineraryList.name"),
                        name: "name"
                    },
                    {
                        title: this.$t("Itinerary.builder.ItineraryList.countries"),
                        name: "countryList"
                    },
                    {
                        title: this.$t("Itinerary.builder.ItineraryList.days"),
                        name: "days"
                    },
                    {
                        title: this.$t("Itinerary.builder.ItineraryList.clients"),
                        name: "clientList"
                    },
                    {
                        title: this.$t("Itinerary.builder.ItineraryList.manager"),
                        name: "managerName"
                    },
                    {
                        title: this.$t("Itinerary.builder.ItineraryList.created"),
                        name: "createDate"
                    },
                    {
                        title: this.$t("Itinerary.builder.ItineraryList.updated"),
                        name: "updateDate"
                    },
                    {
                        title: this.$t("Itinerary.builder.ItineraryList.published"),
                        name: "published"
                    },
                    {
                        title: this.$t("Itinerary.builder.ItineraryList.status"),
                        name: "statusName"
                    },
                    {
                        title: this.$t("Itinerary.builder.ItineraryList.actions"),
                        name: "oper",
                        filterable: false,
                        sortable: false,
                    },
                ],
                list: [],
                subscriptionData: {
                    status: 'UNKNOWN',
                },
            }
        },
        mounted() {
            this.getSubscriptionStatus();
            this.loadList();
        },
        computed: {
            subscriptionGood() {
                if (this.subscriptionData.status === 'UNKNOWN') {
                    return false;
                }
                if (this.subscriptionData.status === 'HAD_SUBSCRIPTION_BLOCKED') {
                    return false;
                }
                if (this.subscriptionData.status === 'TRIAL_FINISHED_BLOCKED') {
                    return false;
                }
                return true;
            },
            subscriptionBad() {
                if (this.subscriptionData.status === 'HAD_SUBSCRIPTION_BLOCKED') {
                    return true;
                }
                if (this.subscriptionData.status === 'TRIAL_FINISHED_BLOCKED') {
                    return true;
                }
                return false;
            },
            subscriptionUrl() {
                return jsRoutes.controllers.SubscriptionController.subscriptionManager().url;
            },
            createItineraryUrl() {
                return jsRoutes.controllers.ItineraryController.createItinerary().url;
            }
        },
        methods: {
            getSubscriptionStatus() {
                const self = this;
                jsRoutes.controllers.SubscriptionController.getSubscriptionStatus()
                    .ajax({
                        success: function (data) {
                            self.subscriptionData = data;
                        },
                        error: function (error) {
                            // TODO , do something with error ?  Maybe blank the Page !
                        }
                    });
            },
            getClientList(item) {
                return item.clientList.map(client => {
                    return client.name;
                }).join("<br/>");
            },
            getCountryList(item) {
                return item.countryList.map(country => {
                    return country.name;
                }).join("<br/>");
            },
            getDaysLabel(item) {
                let label = item.days;
													/*
                if (item.startDate && item.endDate) {
                    label += " (" + item.startDate + " - " + item.endDate + ")";
                }
													*/
                return label;
            },
            getHumanDate(dateLong) {
                return moment(dateLong).fromNow();
            },
            editUrl(item) {
                return jsRoutes.controllers.ItineraryController.detailItinerary(item.uid).url;
            },
            deleteItem(item) {
                //console.log("deleteUser");
                const self = this;
                let msg = self.$t("Itinerary.builder.ItineraryList.message_once_removed") + ", \"" + item.name
                    + "\" "+ self.$t("Itinerary.builder.ItineraryList.message_will_no_longer");

                showCustomDialog(
                    self.$t("Itinerary.builder.ItineraryList.are_you_sure"),
                    msg,
                    self.$t("Itinerary.builder.ItineraryList.delete_label"),
                    "btn-danger", function () {
                    self.doDeleteItem(item);
                });
            },
            doDeleteItem(item) {
                const self = this;
                const url = jsRoutes.controllers.ItineraryController.deleteItinerary(item.id).url;

                this.$http.delete(url).then(function (response) {
                    // set data
                    self.list.splice(self.list.findIndex(function(i){
                        return i.id === item.id;
                    }), 1);
                    showSuccess(
                        self.$t("success.general_title"),
                        self.$t("Itinerary.builder.ItineraryList.itinerary_was_deleted")
                    );

                }).catch(function (error) {
                    // handle error
                    handleAjaxError(error);
                });
            },
            loadList: function () {
                const self = this;
                const url = jsRoutes.controllers.ItineraryController.getItineraryList().url;

                // GET request
                axios.get(url).then(function (response) {
                    // set data
                    self.list = response.data;
                }).catch(function (error) {
                    // handle error
                    handleAjaxError(error);
                });
            },
        }
    }
</script>

<style scoped>

</style>
