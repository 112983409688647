<template>
    <div>
        <div class="modal" tabindex="-1" role="dialog" ref="modalContainer">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{$t("widgets.imageCropModal.position_and_size")}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">

                        <vue-cropper
                                ref='cropper'
                                :src="imgSrc"
                                :aspect-ratio="1"
                                :guides="false"
                                :view-mode="viewMode"
                                :auto-crop="true"
                                :auto-crop-area="0.9"

                                :container-width="200"
                                :container-height="200"

                                :background="true"
                                :scalable="true"
                                :zoomable="true"

                                drag-mode="move"

                                :crop-box-movable="false"
                                :crop-box-resizable="false"

                                :zoom-on-wheel="true"
                                :zoom-on-touch="false"

                                :toggle-drag-mode-on-dblclick="false"

                                alt="Source Image"
                                :img-style="{ 'width': imageWidth + 'px', 'height': imageHeight + 'px' }">
                        </vue-cropper>

                        <div class="row mt-3 justify-content-center">
                            <div class="col-auto">
                                <button type="button" class="btn btn-primary"
                                        style="margin-right: 5px;"
                                        @click="zoomCropper(-0.1)"><i
                                        class="fas fa-search-minus"></i>
                                </button>
                                <button type="button" class="btn btn-primary" @click="zoomCropper(0.1)"><i
                                        class="fas fa-search-plus"></i>
                                </button>
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" v-on:click="hideModal">{{$t("widgets.imageCropModal.close_button")}}</button>
                        <button type="button" class="btn btn-primary" v-on:click="cropImage">{{$t("widgets.imageCropModal.save_button")}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import VueCropper from 'vue-cropperjs'

    export default {
        name: "ImageCropModal",
        components: {
            VueCropper
        },
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            imgSrc: {
                type: String,
                required: true,
            },
            viewMode: {
                type: Number,
                required: false,
                default: function() { return 2 }
            }
        },

        data() {
            return {
                imageWidth: 300,
                imageHeight: 300,
            }
        },

        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.modalContainer).modal('show')
                } else {
                    $(this.$refs.modalContainer).modal('hide')
                }
            },
            imgSrc() {
                // rebuild cropperjs with the updated source
                this.$refs.cropper.replace(this.imgSrc)
            }
        },

        methods: {

            hideModal() {
                this.$emit('update:show', false)
            },

            cropImage() {
                // get image data for post processing, e.g. upload or setting image src
                let cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
                this.$emit('cropped', cropImg)
            },

            zoomCropper(ratio) {
                // console.log('zoomCropper', ratio)
                this.$refs.cropper.relativeZoom(ratio)
            }
        }
    }

</script>

<style scoped>

</style>
