<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3>
                    {{item.title}}
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="table-cell">
                    <i class="fa fa-calendar"></i>
                </div>
                <div class="table-cell" style="padding-left: 10px;">
                    {{startDate}}
                </div>
            </div>
            <div class="col-12">
                <div class="table-cell">
                    <i class="fa fa-calendar"></i>
                </div>
                <div class="table-cell" style="padding-left: 10px;">
                    {{endDate}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        components: {
        },
        mixins: [
        ],
        props: {
            startDate: {
                type: String,
                required: true,
            },
            endDate: {
                type: String,
                required: true,
            },
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                // showButtons: false,
            }
        },
    }
</script>

<style scoped>
</style>
