export default {
    "appname": "Morego App",
    "error.general_title": "Ошибка",
    "error.general_failed_loading_data": "Ошибка при загрузке данных",
    "error.general_failed_upload_file": "Не удалось загрузить файл '{file}': {msg}",
    "error.general_cancel": "Отмена",
    "error.general_delete": "Удалить",
    "success.general_title": "Получилось",
    "success.image_caption_updated": "Подпись к изображению изменена",
    "root.close": "Закрыть",
    "root.organization": "Автор",
    "root.required": "Требуется заполнить это поле.",
    "root.organization_size": "Введите как минимум три знака.",
    "root.organization_max": "Максимум 255 знака.",
    "root.firstname": "Имя",
    "root.lastname": "Фамилия",
    "root.email": "Электронный адрес",
    "root.email_valid": "Неправильный электронный адрес",
    "root.phone": "Телефон",
    "root.choose_password": "Выберите пароль",
    "root.choose_password_size": "Введите минимум 6 знаков.",
    "root.choose_password_max": "Максимум 255 знака.",
    "root.confirm_password": "Подтвердите пароль",
    "root.confirm_password_match": "Пароль должен совпадать.",
    "root.cancel": "Отмена",
    "root.create_account": "Создайте учетную запись",
    "root.something_weng_wrong": "Что-то пошло не так…",
    "root.invited_as_user": "Вы были приглашены в качестве пользователя от автора",
    "root.already_have_account": "Вы уже регистрировались? ",
    "root.return_to_login": "Вернуться к логину",
    "root.trial_period_ends": "Пробный период заканчивается",
    "root.last_subscription_period_end": "Ваша подписка заканчивается",
    "root.subscription_ended": "Ваша подписка закончилась",
    "root.manage_your_subscriptions": "Внести изменения в подписку",
    "root.profile": "Профиль",
    "root.change_password": "Сменить пароль",
    "root.profile_image": "Фотография для профиля",
    "root.upload_new_image": "Загрузить новую фотографию",
    "root.choose_file": "Выберите файл",
    "root.remove_image": "Удалить фотографию",
    "root.save": "Сохранить",
    "root.profile_updated": "Профиль изменен",
    "root.image_removed": "Фотография удалена",
    "root.only_images_allowed": "Разрешены только файлы с изображениями",
    "root.api_not_supported": "Извините, чтение файла API не поддерживавтся",
    "root.file_uploaded": "Файл загружен",
    "root.saved": "Сохранено",
    "widgets.imageCropModal.position_and_size": "Настройте положение и размер нового изображения",
    "widgets.imageCropModal.save_button": "Сохранить",
    "widgets.imageCropModal.close_button": "Закрыть",
    "MobileAppsConfiguration.title": "Создание мобильного приложения",
    "MobileAppsConfiguration.table.request_date": "Дата запроса",
    "MobileAppsConfiguration.table.status": "Статус тура",
    "MobileAppsConfiguration.table.feedback": "Обратная связь",
    "MobileAppsConfiguration.table.no_build_request": "Пока не было запроса на создание мобильного приложения",
    "MobileAppsConfiguration.button.request_new_build": "Запросить создание мобильного приложения",
    "MobileAppsConfiguration.button.cancel_build": "Отменить запрос на создание мобильного приложения",
    "MobileAppsConfiguration.button.update_configuration": "Обновить конфигурацию",
    "MobileAppsConfiguration.button.cancel": "Отменить",
    "MobileAppsConfiguration.title2": "Настройки мобильного приложения",
    "MobileAppsConfiguration.fields.agencyName": "Наименовние автора",
    "MobileAppsConfiguration.fields.about_agency": "Об авторе",
    "MobileAppsConfiguration.fields.about_agency_required": "Необходимо заполнить это поле.",
    "MobileAppsConfiguration.fields.primary_color": "Основной цвет выделения",
    "MobileAppsConfiguration.fields.primary_color_required": "Необходимо заполнить это поле.",
    "MobileAppsConfiguration.fields.background_color": "Цвет фона",
    "MobileAppsConfiguration.fields.background_color_required": "Необходимо заполнить это поле.",
    "MobileAppsConfiguration.fields.mobile_logo": "Логотип для приложения",
    "MobileAppsConfiguration.fields.mobile_icon": "Иконка для приложения",
    "MobileAppsConfiguration.fields.imagesHelp": "Кликните или перетащите изображение",
    "MobileAppsConfiguration.fields.showChatLabel": "Включить возможность переписки в мобильном приложении",
    "MobileAppsConfiguration.messages.mobile_request_title": "Создание мобильного приложения",
    "MobileAppsConfiguration.messages.mobile_request_question": "Создать мобильное приложение с этими настройками?",
    "MobileAppsConfiguration.messages.mobile_request_button": "Запрос на создание мобильного приложения",
    "MobileAppsConfiguration.messages.cancel_mobile_request_title": "Отменить создание мобильного приложения",
    "MobileAppsConfiguration.messages.cancel_mobile_request_question": "Отменить создание мобильного приложения?",
    "MobileAppsConfiguration.messages.cancel_mobile_request_button": "Отмена создания мобильного приложения",
    "MobileAppsConfiguration.messages.configuration_updated": "Настройки обновлены",
    "AgencyConfiguration.title": "Настройки автора",
    "AgencyConfiguration.mustbeconfigured": "Информация об авторе должна быть сконфигурирована!",
    "AgencyConfiguration.fields.agencyName": "Наименование автора",
    "AgencyConfiguration.fields.agencyName_required": "Требуется заполнить это поле.",
    "AgencyConfiguration.fields.agencyName_size": "Максимум 255 знака.",
    "AgencyConfiguration.fields.email": "Электронный адрес",
    "AgencyConfiguration.fields.email_required": "Требуется заполнить это поле.",
    "AgencyConfiguration.fields.email_badformat": "Неправильный электронный адрес",
    "AgencyConfiguration.fields.website": "Сайт автора",
    "AgencyConfiguration.fields.website_badformat": "Неправильный сайт автора.",
    "AgencyConfiguration.fields.website_size": "Максимум 255 знака.",
    "AgencyConfiguration.fields.phone": "Телефон",
    "AgencyConfiguration.fields.phone_required": "Требуется заполнить это поле.",
    "AgencyConfiguration.fields.phone_size": "Максимум 100 знаков.",
    "AgencyConfiguration.fields.address": "Адрес",
    "AgencyConfiguration.fields.address_required": "Требуется заполнить это поле.",
    "AgencyConfiguration.fields.address_size": "Максимум 500 знаков.",
    "AgencyConfiguration.fields.city": "Город",
    "AgencyConfiguration.fields.city_required": "Требуется заполнить это поле.",
    "AgencyConfiguration.fields.city_size": "Максимум 100 знаков.",
    "AgencyConfiguration.fields.postalCode": "Индекс",
    "AgencyConfiguration.fields.postalCode_required": "Требуется заполнить это поле.",
    "AgencyConfiguration.fields.postalCode_size": "Максимум 45 знаков.",
    "AgencyConfiguration.fields.country": "Страна",
    "AgencyConfiguration.fields.country_required": "Требуется заполнить это поле.",
    "AgencyConfiguration.fields.country_size": "Максимум 100 знаков.",
    "AgencyConfiguration.fields.vat": "Регистрационный номер плательщика НДС",
    "AgencyConfiguration.fields.vat_onlynumbers": "Только числа.",
    "AgencyConfiguration.fields.vat_size": "Максимум 45 знаков.",
    "AgencyConfiguration.fields.regnumb": "Номер регистрации",
    "AgencyConfiguration.fields.regnumb_size": "Максимум 255 знака.",
    "AgencyConfiguration.fields.date_format": "Формат даты",
    "AgencyConfiguration.fields.currency": "Валюта",
    "AgencyConfiguration.fields.currency_select": "Выберите валюту",
    "AgencyConfiguration.fields.agencyLogo": "Логотип",
    "AgencyConfiguration.fields.agencyLogoHelp": "Кликните или перетащите изображение",
    "AgencyConfiguration.fields.facebookLink": "Facebook", // TODO translate?
    "AgencyConfiguration.fields.instagramLink": "Instagram", // TODO translate?
    "AgencyConfiguration.fields.twitterLink": "Twitter", // TODO translate?
    "AgencyConfiguration.fields.youtubeLink": "Youtube", // TODO translate?
    "AgencyConfiguration.fields.genericMaxSize": "Максимум {max} знака.",
    "AgencyConfiguration.fields.description": "Описание",
    "AgencyConfiguration.buttons.updateConfiguration": "Обновить настройки",
    "AgencyConfiguration.buttons.backbutton": "Вернуться",
    "AgencyConfiguration.messages.configuration_updated": "Настройки обновлены",
    "AgencyConfiguration.fields.inviteCode": "Код приглашения",
    "AgencyConfiguration.fields.inviteCode_generate": "Сгенерировать",
    "AgencyConfiguration.fields.inviteCode_maxLength": "Максимум 12 знаков",
    "AgencyConfiguration.fields.inviteCode_minLength": "Минимум 4 знака",
    "AgencyConfiguration.fields.inviteCode_upperAlpha": "Только заглавные латинские буквы",
    "AgencyConfiguration.fields.inviteCode_isUnique": "Должен быть уникальным",
    "SubscriptionManager.title": "Billing", // TODO translate
    "SubscriptionManager.invoicesTitle": "Счета",
    "SubscriptionManager.loadingInvoices": "Загрузка счетов…",
    "SubscriptionManager.tab.subscription": "Your Subscription", // TODO translate
    "SubscriptionManager.tab.invoices": "Your Invoices", // TODO translate
    "SubscriptionManager.tab.payment_methods": "Your Payment Methods", // TODO translate
    "SubscriptionManager.monthlyPlan": "Your Subscription", // TODO translate
    "SubscriptionManager.planAmount": "Plan Amount", // TODO translate
    "SubscriptionManager.subscriptionTitle": "Ваша подписка",
    "SubscriptionManager.cardsTitle": "Кредитные карты",
    "SubscriptionManager.loadingCards": "Загрузка карт…",
    "SubscriptionManager.availablePlansTitle": "Доступный форматы подписки",
    "SubscriptionManager.confirmationTitle": "Подтверждение",
    "SubscriptionManager.table.period": "Период",
    "SubscriptionManager.table.amount": "Сумма",
    "SubscriptionManager.button.download": "Загрузка",
    "SubscriptionManager.button.cancel_subscription": "Отменить подписку",
    "SubscriptionManager.button.subscribe": "Подписка",
    "SubscriptionManager.button.use_this_card": "Использовать эту карту",
    "SubscriptionManager.button.i_am_sure": "Я уверен",
    "SubscriptionManager.fields.last_subscription_ends": "Последний период подписки заканчивается",
    "SubscriptionManager.fields.subscription_ended": "Ваша подписка закончилась",
    "SubscriptionManager.fields.subscription_renew": "Ваша подписка обновится",
    "SubscriptionManager.fields.expire_card": "Срок действия",
    "SubscriptionManager.fields.selected": "Выбрано",
    "SubscriptionManager.fields.payment_options": "Способы оплаты",
    "SubscriptionManager.fields.card_details": "Предоставьте данные карты для оплаты",
    "SubscriptionManager.fields.ending_with": "Оканчивается на",
    "SubscriptionManager.fields.valid_until": "Срок действия до",
    "SubscriptionManager.fields.important" : "Important", // TODO
    "SubscriptionManager.fields.trial_period_ended": "Trial period ended on", // TODO
    "SubscriptionManager.fields.trial_period_ends": "Пробный период подписки заканчивается",
    "SubscriptionManager.fields.suspended_account_nostripe": "You account is suspended at this moment. Please subscribe to Morego to continue.", // TODO
    "SubscriptionManager.fields.suspended_account_updatemethod": "You account is suspended at this moment. This may be due to lack or payment or end of Subscription. Please supdate your payment method or subscribe to continue using Morego.", // TODO
    "SubscriptionManager.fields.eur_cur": "EURO", // TODO
    "SubscriptionManager.fields.dol_cur": "DOLLAR", // TODO
    "SubscriptionManager.fields.gbp_cur": "GBP", // TODO
    "SubscriptionManager.button.update_card" : "Update Using This Card", // TODO
    "SubscriptionManager.invoice.date": "Invoice Date", // TODO
    "SubscriptionManager.invoice.value": "Amount", // TODO
    "SubscriptionManager.warning.stripeproblem": "There was a problem with your Credit Card: ", // TODO
    "SubscriptionManager.warning.subscription_not_active" : "Your Subscription is not Active. Please check you Subscription details.", // TODO
    "SubscriptionManager.warning.canceled_but_still_time" : "Your subscription was canceled and will stop working at this date: ", // TODO
    "SubscriptionManager.warning.account_locked_nosubs" : "Your account is now locked because you don't have a subscription. To continue using Morego please check the Billing Section. ", // TODO
    "SubscriptionManager.warning.trial_ongoing" : "You have a trial subscription until the following date: ", // TODO
    "SubscriptionManager.warning.trial_finished" : "You have a trial subscription has ended. To continue using Morego go to the Billing section and Subscribe.", // TODO
    "SubscriptionManager.warning.generic_lock" : "You don't have an active subscription. Please check the Billing Section.", // TODO
    "UserInvite.title": "Пригласить пользователей",
    "UserInvite.fields.email": "Электронный адрес",
    "UserInvite.fields.email_required": "Требуется заполнить это поле.",
    "UserInvite.fields.email_invalid": "Неправильный электронный адрес.",
    "UserInvite.fields.user_type": "Тип пользователя",
    "UserInvite.fields.user_type_required": "Требуется заполнить это поле.",
    "UserInvite.button.send_invite": "Отправить приглашение",
    "UserInvite.button.go_back": "Вернуться",
    "UserInvite.messages.failed_loading_roles": "Ошибка при загрузке данных",
    "UserInvite.messages.invite_sent": "Приглашение отправлено",
    "UserList.title": "Пользователи",
    "UserList.invites_title": "Приглашенные",
    "UserList.button.invite_user": "Пригласить пользователей",
    "UserList.table.name": "Имя",
    "UserList.table.email": "Электронный адрес",
    "UserList.table.type": "Тип пользователя",
    "UserList.table.sent_by": "Отправлено по",
    "UserList.table.date": "Дата",
    "UserList.messages.delete_user": "Удалить пользователя",
    "UserList.messages.invites_table_empty": "Пока нет приглашенных пользователей",
    "UserList.messages.delete_invite": "Удалить приглашение",
    "ClientList.title": "Подписчики",
    "ClientList.tooltip.import_clients": "Импортируйте данные подписчиков из файла Excel",
    "ClientList.button.create_client": "Создать нового подписчика",
    "ClientList.button.import_clients": "Загрузить данные подписчиков",
    "ClientList.button.edit": "Изменить",
    "ClientList.button.del": "Удалить",
    "ClientList.table.name": "Имя",
    "ClientList.table.email": "Электронный адрес",
    "ClientList.table.phone": "Телефон",
    "ClientList.table.actions": "Действия",
    "ClientList.messages.delete_title": "Вы уверены?",
    "ClientList.messages.delete_make_sure_1": "После удаления,",
    "ClientList.messages.delete_make_sure_2": "профиль будет недоступен!",
    "ClientList.messages.delete_title_success": "Подписчик был удален",
    "ClientImport.title": "Импортировать данные подписчиков",
    "ClientImport.go_back": "Вернуться",
    "ClientImport.button.create_client": "Создать запись подписчики",
    "ClientImport.button.import_clients": "Загрузить данные подписчиков",
    "ClientImport.button.edit": "Редактировать",
    "ClientImport.button.del": "Удалить",
    "ClientImport.file_warning_part1": "ВАЖНО: файл для загрузки должен иметь тот же формат, что и шаблон",
    "ClientImport.file_warning_part2": " Шаблон",
    "ClientImport.processing": "Обработка ...",
    "ClientImport.results": "Результаты",
    "ClientImport.dropzone_remove_file": "Удалить файл",
    "ClientImport.dropzone_invalid_file_type": "Принимаются только файлы .xlsx",
    "ClientModal.update_client_title": "Обновить подписчика",
    "ClientModal.create_client_title": "Создать подписчика",
    "ClientModal.fields.name": "Имя",
    "ClientModal.fields.name_required": "Требуется заполнить это поле.",
    "ClientModal.fields.name_size": "Максимум 255 знака.",
    "ClientModal.fields.email": "Электронный адрес",
    "ClientModal.fields.email_required": "Требуется заполнить это поле.",
    "ClientModal.fields.email_format": "Неправильный электронный адрес",
    "ClientModal.fields.phone": "Телефон",
    "ClientModal.fields.phone_size": "Максимум 100 знаков.",
    "ClientModal.button.create_client": "Создать новую запись о пописчике",
    "ClientModal.button.update_client": "Обновить подписчика",
    "ClientModal.messages.client_created": "Подписчик создан",
    "ClientModal.messages.client_updated": "Подписчик обновлен",
    "Itinerary.general.save_to_library": "Сохранить в библиотеку",
    "Itinerary.general.update_to_library": "Обновить библиотеку",
    "Itinerary.general.remove_item": "Удалить запись",
    "Itinerary.general.remove_line": "Удалить строку",
    "Itinerary.general.add_line": "Добавить строку",
    "Itinerary.general.click_to_edit": "Нажмите чтобы редактировать",
    "Itinerary.general.click_or_drag": "Нажмите или перетащите изображение",
    "Itinerary.general.click_or_drag_images_to_upload": "Нажмите или перетащите изображение для загрузки",
    "Itinerary.general.click_or_drag_to_upload": "Нажмите или перетащите файлы для загрузки",
    "Itinerary.general.show_on_map": "Показать на карте",
    "Itinerary.general.return_string": "Возврат",
    "Itinerary.general.ongoing_string": "В процессе",
    "Itinerary.general.move_to_other_section": "Переместить в другую секцию",
    "Itinerary.general.autocomplete_information": "Автозаполнение",
    "Itinerary.general.image_caption": "Подпишите изображение",
    "Itinerary.general.add_images": "Добавить изображение",
    "Itinerary.general.add_rating": "Добавить рейтинг",
    "Itinerary.general.add_item": "Добавить запись",
    "Itinerary.general.google_rating": "Google рейтинг",
    "Itinerary.general.open_website": "Открыть вебсайт",
    "Itinerary.general.website": "Вебсайт",
    "Itinerary.general.label": "Название",
    "Itinerary.general.link": "Ссылка",
    "Itinerary.general.close": "Закрыть",
    "Itinerary.general.add_from_library": "Добавить из библиотеки",
    "Itinerary.general.load_from_library": "Загрузить из вашей библиотеки",
    "Itinerary.general.days": "Дни",
    "Itinerary.general.invalid_fields_error": "Есть недопустимые поля",
    "Itinerary.builder.add_cover": "Добавить раздел Обложка",
    "Itinerary.builder.add_section": "Добавить раздел",
    "Itinerary.builder.add_day_section": "Добавить день",
    "Itinerary.builder.car_rental_return": "Возврат автомобиля",
    "Itinerary.builder.car_rental_pickup": "Получение автомобиля",
    "Itinerary.builder.car_rental": "Аренда автомобиля",
    "Itinerary.builder.checkout": "Выезд",
    "Itinerary.builder.checkin": "Заезд",
    "Itinerary.builder.flight": "Перелёт",
    "Itinerary.builder.click_to_change_title": "Нажмите, чтобы поменять название",
    "Itinerary.builder.remove_section": "Убрать раздел",
    "Itinerary.builder.add_item": "Добавить запись",
    "Itinerary.builder.reorder_items": "Поменять очередность записей",
    "Itinerary.builder.show_title_on_pdf": "Показать название в PDF",
    "Itinerary.builder.new_section": "Новый раздел",
    "Itinerary.builder.new_end_section": "Новый заключительный раздел",
    "Itinerary.builder.are_you_sure": "Вы уверены?",
    "Itinerary.builder.new_general_section": "Другой раздел",
    "Itinerary.builder.new_day": "Новый день",
    "Itinerary.builder.new_day_message": "Новый День был добавлен. Пожалуйста, проверьте названия оставшихся дней и даты тура",
    "Itinerary.builder.remove_message": "Убрать секцию и все записи?",
    "Itinerary.builder.remove_button": "Убрать",
    "Itinerary.builder.remove_item_message": "Убрать эту запись?",
    "Itinerary.builder.item_moved": "Запись перемещена",
    "Itinerary.builder.dates_updated": "Даты обновлены",
    "Itinerary.builder.add_item_problem_title": "Проблема...",
    "Itinerary.builder.add_item_problem_msg": "Отсутствует дневной раздел в первый день, {day}",
    "Itinerary.builder.create.create_new_itinerary": "Создать новый тур",
    "Itinerary.builder.create.itinerary_language": "Язык, используемый в туре",
    "Itinerary.builder.create.select_days_keep_template_warning": "Выберите дни для сохранения из шаблона. {numDays} МАКС!",
    "Itinerary.builder.create.select_days_error": "Ваш тур будет иметь  {numDays} дней. Выберите не более {numDays} дней из шаблона.",
    "Itinerary.builder.create.name": "Название",
    "Itinerary.builder.create.name_required": "Требуется заполнить это поле.",
    "Itinerary.builder.create.name_size": "Максимум 255 знака.",
    "Itinerary.builder.create.date_format": "Формат даты",
    "Itinerary.builder.create.use_itinerary_template": "Использовать тур как шаблон",
    "Itinerary.builder.create.open_in_new_tab_tooltip": "TODO TRANSLATE",
    "Itinerary.builder.create.select_itinerary": "Выбрать тур",
    "Itinerary.builder.create.dates": "Даты",
    "Itinerary.builder.create.start_date": "Дата начала",
    "Itinerary.builder.create.end_date": "Дата окончания",
    "Itinerary.builder.create.field_required": "Требуется заполнить это поле.",
    "Itinerary.builder.create.countries": "Страны",
    "Itinerary.builder.create.select_countries": "Выбрать страны",
    "Itinerary.builder.create.currency": "Валюта",
    "Itinerary.builder.create.currency_select": "Выбрать валюту",
    "Itinerary.builder.create.clients": "Подписчики",
    "Itinerary.builder.create.clients_select": "Выбрать подписчики (подписчиков)",
    "Itinerary.builder.create.create_client": "Создать подписчика",
    "Itinerary.builder.create.create": "Создать",
    "Itinerary.builder.create.cancel": "Отменить",
    "Itinerary.builder.create.message_your_iti_will_have": "Ваша тур будет включать:",
    "Itinerary.builder.create.message_days_instead_of": "Дни вместо",
    "Itinerary.builder.create.message_you_have_days": "В выбранном шаблоне в некоторых днях записи будут утерянны. Измените количество дней в туре на",
    "Itinerary.builder.create.message_choose_another_template": "дней или выберите другой шаблон.",
    "Itinerary.builder.create.message_details_will_be_lost": "Все записи и детали в дополнительных днях будут утерянны.",
    "Itinerary.builder.templatecreate.title": "Создать новый шаблон",
    "Itinerary.builder.templatecreate.itinerary_language": "Язык, используемый в туре",
    "Itinerary.builder.templatecreate.select_days_keep_template_warning": "Выберите дни для сохранения из шаблона. {numDays} МАКС!",
    "Itinerary.builder.templatecreate.select_days_error": "Ваш тур будет иметь {numDays} дней. Выберите не более {numDays} дней из шаблона.",
    "Itinerary.builder.templatecreate.name": "Название",
    "Itinerary.builder.templatecreate.name_size": "Максимум 255 знака.",
    "Itinerary.builder.templatecreate.date_format": "Формат даты",
    "Itinerary.builder.templatecreate.use_itinerary_template": "Использовать тур как шаблон",
    "Itinerary.builder.templatecreate.open_in_new_tab_tooltip": "Открыть в новой вкладке",
    "Itinerary.builder.templatecreate.select_itinerary": "Выбрать тур",
    "Itinerary.builder.templatecreate.field_required": "Это поле обязательно к заполнению.",
    "Itinerary.builder.templatecreate.days": "Дни",
    "Itinerary.builder.templatecreate.num_days_minvalue": "Минимальное значение 1",
    "Itinerary.builder.templatecreate.countries": "Страны",
    "Itinerary.builder.templatecreate.select_countries": "Выбрать страны",
    "Itinerary.builder.templatecreate.currency": "Валюта",
    "Itinerary.builder.templatecreate.currency_select": "Выбрать валюту",
    "Itinerary.builder.templatecreate.create": "Создать",
    "Itinerary.builder.templatecreate.cancel": "Отменить",
    "Itinerary.builder.templatecreate.message_your_iti_will_have": "Ваш тур будет включать:",
    "Itinerary.builder.templatecreate.message_days_instead_of": "Дни вместо",
    "Itinerary.builder.templatecreate.message_you_have_days": "В выбранном шаблоне в некоторых днях записи будут утерянны. Измените количество дней в туре на",
    "Itinerary.builder.templatecreate.message_choose_another_template": "дней или выберите другой шаблон.",
    "Itinerary.builder.templatecreate.message_details_will_be_lost": "Все записи и детали в дополнительных днях будут утерянны.",
    "Itinerary.builder.detail.not_visible_mobile": "Этот тур не отображается в мобильном приложении, поскольку его статус «Черновик».",
    "Itinerary.builder.detail.itinerary": "Тур",
    "Itinerary.builder.detail.template_title": "Шаблон тура",
    "Itinerary.builder.detail.create_copy_btn": "Скопировать",
    "Itinerary.builder.detail.create_copy_tooltip": "Скопировать в новый шаблон или тур",
    "Itinerary.builder.detail.copy_to_template_btn": "Скопировать в новый шаблон",
    "Itinerary.builder.detail.copy_to_itinerary_btn": "Скопировать в новый тур",
    "Itinerary.builder.detail.click_edit": "Нажмите, чтобы изменить",
    "Itinerary.builder.detail.manager": "Менеджер",
    "Itinerary.builder.detail.published": "Published", // TODO translate
    "Itinerary.builder.detail.published_tooltip": "Published on mobile app", // TODO translate
    "Itinerary.builder.detail.notpublished": "Not published", // TODO translate
    "Itinerary.builder.detail.notpublished_tooltip": "Not published on mobile app", // TODO translate

    "Itinerary.builder.detail.showInterestButton": "Отражать кнопки для запроса",
    "Itinerary.builder.detail.showInterestButtonToolTip": "Отражать кнопки для запроса дополнительных сервисов в мобильном приложении",
    "Itinerary.builder.detail.showInterestButtonNot": "Не отражать кнопки для запроса",
    "Itinerary.builder.detail.showInterestButtonNotToolTip": "Отражать кнопки для запроса дополнительных сервисов в мобильном приложении",

    "Itinerary.builder.detail.change_manager_tooltip": "Поменять менеджера по управлению туром",
    "Itinerary.builder.detail.status": "Статус",
    "Itinerary.builder.detail.status_change": "Изменить статус",
    "Itinerary.builder.detail.draft": "Черновик",
    "Itinerary.builder.detail.sold": "Готово",
    "Itinerary.builder.detail.send_by_email": "Отправить по электронной почте",
    "Itinerary.builder.detail.settings": "Настройки",
    "Itinerary.builder.detail.documentation": "Документация",
    "Itinerary.builder.detail.trip": "Тур",
    "Itinerary.builder.detail.map": "Карта",
    "Itinerary.builder.detail.gpxroutes": "Маршруты",
    "Itinerary.builder.detail.save": "Сохранить",
    "Itinerary.builder.detail.last_saved": "Последние сохранение",
    "Itinerary.builder.detail.undo": "Отменить",
    "Itinerary.builder.detail.redo": "Сделать",
    "Itinerary.builder.detail.included": "Включено",
    "Itinerary.builder.detail.optional": "Опционально",
    "Itinerary.builder.detail.name_updated": "Название обновлено",
    "Itinerary.builder.detail.iti_updated": "Тур обновлен",
    "Itinerary.builder.detail.export_pdf": "Экспортировать в PDF",
    "Itinerary.builder.detail.export_pdf_full": "PDF полного размер (изображения высокого качества)",
    "Itinerary.builder.detail.export_pdf_medium": "PDF среднего размера (изображения среднего качества)",
    "Itinerary.builder.detail.export_pdf_low": "PDF уменьшенного размера (изображения низкого качества)",
    "Itinerary.builder.documents.tab_help": "Загрузите здесь любые полезные материалы в формате pdf, jpeg или png, и они будут доступны для скачивания подписчиками вашего тура в разделе «Документы»",
    "Itinerary.builder.documents.upload_document": "Загрузить документ",
    "Itinerary.builder.documents.filename": "Имя файла",
    "Itinerary.builder.documents.description": "Описание",
    "Itinerary.builder.documents.filename_mandatory": "Имя файла обязательно",
    "Itinerary.builder.documents.filename_edited": "Имя файла было изменено",
    "Itinerary.builder.documents.are_you_sure": "Вы уверены?",
    "Itinerary.builder.documents.are_you_sure_delete": "Вы уверены, что хотите удалить это?",
    "Itinerary.builder.documents.yes": "Да",
    "Itinerary.builder.documents.file_was_deleted": "Файл был удален",
    "Itinerary.builder.map.full_trip_btn": "Весь тур",
    "Itinerary.builder.map.full_trip_tooltip": "Нажмите, чтобы перейти в тур на каждый день",
    "Itinerary.builder.map.day_trip_btn": "День {день}",
    "Itinerary.builder.map.day_trip_tooltip": "Нажмите, чтобы перейти к полной карте поездки",
    "Itinerary.builder.gpx.tab_help": "Загрузите здесь файлы в формате GPX, чтобы нанести на карту вашего тура информацию о маршрутах и ориентирах. Чтобы ее увидели ваши подписчики, отметьте необходимые маршруты и ориентиры в чекбоксах слева и назначьте им нужные дни показа",
    "Itinerary.builder.gpx.upload_file": "Загрузить файл GPX",
    "Itinerary.builder.gpx.routes_heading": "Маршруты",
    "Itinerary.builder.gpx.waypoints_heading": "Ориентиры",
    "Itinerary.builder.gpx.set_day": "Установить день",
    "Itinerary.builder.gpx.delete": "Удалить",
    "Itinerary.builder.gpx.all_label": "ВСЕ",
    "Itinerary.builder.gpx.none_label": "НИКТО",
    "Itinerary.builder.gpx.name_col": "Название",
    "Itinerary.builder.gpx.day_col": "День",
    "Itinerary.builder.gpx.routes_are_you_sure_title": "Уверены ли вы?",
    "Itinerary.builder.gpx.routes_are_you_sure_msg": "Вы уверены, что хотите удалить выбранные маршруты?",
    "Itinerary.builder.gpx.routes_delete_success": "Маршруты удалены",
    "Itinerary.builder.gpx.waypoints_are_you_sure_title": "Уверены ли вы?",
    "Itinerary.builder.gpx.waypoints_are_you_sure_msg": "Вы уверены, что хотите удалить выбранные достопримечательности?",
    "Itinerary.builder.gpx.waypoints_delete_success": "Достопримечательности удалены",
    "Itinerary.builder.gpx.day_form": "День",
    "Itinerary.builder.ItineraryList.itineraries": "Тур",
    "Itinerary.builder.ItineraryList.create_new": "Создать новый",
    "Itinerary.builder.ItineraryList.name": "Название",
    "Itinerary.builder.ItineraryList.countries": "Страны",
    "Itinerary.builder.ItineraryList.days": "Дни",
    "Itinerary.builder.ItineraryList.clients": "Подписчики",
    "Itinerary.builder.ItineraryList.manager": "Менаджер",
    "Itinerary.builder.ItineraryList.created": "Созданно",
    "Itinerary.builder.ItineraryList.updated": "Обновлено",
    "Itinerary.builder.ItineraryList.published": "Published", //TODO translate
    "Itinerary.builder.ItineraryList.status": "Статус",
    "Itinerary.builder.ItineraryList.actions": "Действия",
    "Itinerary.builder.ItineraryList.delete_label": "Удалить",
    "Itinerary.builder.ItineraryList.message_once_removed": "После удаления",
    "Itinerary.builder.ItineraryList.message_will_no_longer": "будет недоступно для подписчиков!",
    "Itinerary.builder.ItineraryList.are_you_sure": "Вы уверены?",
    "Itinerary.builder.ItineraryList.itinerary_was_deleted": "Тур была удален",
    "Itinerary.builder.TemplateList.templates": "Шаблоны",
    "Itinerary.builder.TemplateList.create_new": "Создать новый",
    "Itinerary.builder.TemplateList.name": "Название",
    "Itinerary.builder.TemplateList.countries": "Страны",
    "Itinerary.builder.TemplateList.days": "Дни",
    "Itinerary.builder.TemplateList.clients": "Подписчики",
    "Itinerary.builder.TemplateList.manager": "Менаджер",
    "Itinerary.builder.TemplateList.created": "Созданно",
    "Itinerary.builder.TemplateList.updated": "Обновлено",
    "Itinerary.builder.TemplateList.published": "Published", //TODO translate
    "Itinerary.builder.TemplateList.status": "Статус",
    "Itinerary.builder.TemplateList.actions": "Действия",
    "Itinerary.builder.TemplateList.delete_label": "Удалить",
    "Itinerary.builder.TemplateList.message_once_removed": "После удаления",
    "Itinerary.builder.TemplateList.message_will_no_longer": "будет недоступно для подписчиков!",
    "Itinerary.builder.TemplateList.are_you_sure": "Вы уверены?",
    "Itinerary.builder.TemplateList.template_was_deleted": "Тур был удален",
    "Itinerary.builder.settings.itinerary_language": "Язык, используемый в тур",
    "Itinerary.builder.settings.date_format": "Формат даты",
    "Itinerary.builder.settings.dates": "Даты",
    "Itinerary.builder.settings.start_date": "Дата начала",
    "Itinerary.builder.settings.end_date": "Дата окончания",
    "Itinerary.builder.settings.days": "Дни",
    "Itinerary.builder.settings.field_required": "Требуется заполнить это поле.",
    "Itinerary.builder.settings.num_days_minvalue": "Минимальное значение 1",
    "Itinerary.builder.settings.countries": "Страны",
    "Itinerary.builder.settings.countries_select": "Выюрать страны",
    "Itinerary.builder.settings.currency": "Валюта",
    "Itinerary.builder.settings.currency_select": "Выбрать валюту",
    "Itinerary.builder.settings.cost": "Стоимость",
    "Itinerary.builder.settings.duration": "Продолжительность тура",
    "Itinerary.builder.settings.trip_type": "Тип тура",
    "Itinerary.builder.settings.clients": "Подписчики",
    "Itinerary.builder.settings.clients_select": "Выбрать подписчика (подписчиков)",
    "Itinerary.builder.settings.create_client": "Создать подписчика",
    "Itinerary.builder.settings.message_cant_change_trip_dates": "Невозможно изменить даты поездки",
    "Itinerary.builder.settings.message_your_iti_will_have": "Ваш тур будет включать",
    "Itinerary.builder.settings.message_days_instead_of": "дни вместо",
    "Itinerary.builder.settings.message_days_will_be_left_out": "Записи в некоторых днях записи будут утеряны. Измените их, в соответствии с новой последовательностью дней.",
    "Itinerary.builder.settings.message_details_may_lost": "Все записи и детали в этих днях будут утеряны.",
    "Itinerary.builder.settings.message_continue": "Продолжить",
    "Itinerary.builder.library.library": "Библиотека",
    "Itinerary.builder.library.title": "Название",
    "Itinerary.builder.library.click_to_change_name": "Нажмите, чтобы изменить название",
    "Itinerary.builder.library.tags": "Теги",
    "Itinerary.builder.library.tag_select_add": "Выберите теги или создайте новые",
    "Itinerary.builder.library.cancel": "Отменить",
    "Itinerary.builder.library.save": "Сохранить",
    "Itinerary.builder.library.item_update": "Добавлена запись в библиотеку",
    "Itinerary.builder.library.name_address": "Имя / Адрес",
    "Itinerary.builder.library.tag_select": "Выберите теги",
    "Itinerary.builder.library.type": "Тип",
    "Itinerary.builder.library.status": "Статус",
    "Itinerary.builder.library.active": "Активные",
    "Itinerary.builder.library.inactive": "Неактивные",
    "Itinerary.builder.library.name": "Имя",
    "Itinerary.builder.library.address": "Адрес",
    "Itinerary.builder.library.created_at": "Создано_в",
    "Itinerary.builder.library.actions": "Действия",
    "Itinerary.builder.library.message_deactivate_wont_be_availabel": "Деактивировать (будет недоступно для пользователя)",
    "Itinerary.builder.library.item_deactivated": "Запись деактивирована",
    "Itinerary.builder.library.accommodation": "Проживание",
    "Itinerary.builder.library.meals": "Питание",
    "Itinerary.builder.library.content": "Контент",
    "Itinerary.builder.library.photos": "Фотографии",
    "Itinerary.builder.library.sections": "Разделы",
    "Itinerary.builder.library.caption_location": "Подпись/место",
    "Itinerary.builder.library.click_to_change_caption": "Нажмите, чтобы изменить подпись",
    "Itinerary.builder.library.write_caption_here": "Подпишите здесь",
    "Itinerary.builder.library.click_to_change_tags": "Нажмите, чтобы поменять теги",
    "Itinerary.builder.library.load_more": "Загрузить еще",
    "Itinerary.builder.custom_tab_name": "Пользовательская метка дня",
    "Itinerary.chat.send": "Отправить",
    "Itinerary.chat.your_message": "Ваше сообщение",
    "Itinerary.modal.UploadGpx.title": "Загрузить файл GPX",
    "Itinerary.modal.UploadGpx.close": "Закрыть",
    "Itinerary.modal.UploadGpx.dropzone_invalid_file_type": "Принимаются только файлы .gpx",
    "Itinerary.modal.ImageUpload.pixabay_free": "Pixabay бесплатные фотографии в высоком разрешении",
    "Itinerary.modal.ImageUpload.library_photos": "Фотографии библиотеки",
    "Itinerary.modal.ImageUpload.upload": "Закачать",
    "Itinerary.modal.ImageUpload.search_free_photos": "Искать бесплатные фотографии в высоком разрешении",
    "Itinerary.modal.ImageUpload.photos_by": "Фотографии от",
    "Itinerary.modal.ImageUpload.no_photos_found": "Фотографий не найдено",
    "Itinerary.modal.ImageUpload.load_more": "Загрузить еще",
    "Itinerary.modal.ImageUpload.tags": "Теги",
    "Itinerary.modal.ImageUpload.select_tags": "Выбрать теги",
    "Itinerary.modal.ImageUpload.caption_location": "Подпись / место",
    "Itinerary.modal.ImageUpload.click_to_add": "Нажмите, чтобы добавить",
    "Itinerary.modal.ImageUpload.select_tags_or_add": "Выберите теги или добавьте новые",
    "Itinerary.modal.ImageUpload.photo_already_added": "Фотография уже добавлено",
    "Itinerary.modal.ImageUpload.write_something_first": "Начните писать…",
    "Itinerary.modal.ImageUpload.photo_added": "Фотография добавлена",
    "Itinerary.modal.ImageUpload.unsplash": "Unsplash",
    "Itinerary.modal.ImageUpload.search_instead": "Искать другие фотографии",
    "Itinerary.modal.ImageUpload.upload_instead": "Загрузите свои фотографии",
    "Itinerary.modal.ImageUpload.pixabay": "Pixabay",
    "Itinerary.modal.BuilderAddItem.tags": "Теги",
    "Itinerary.modal.BuilderAddItem.select_tags": "Выберите теги",
    "Itinerary.modal.BuilderAddItem.type_to_search": "Начните печатать для поиска",
    "Itinerary.modal.BuilderAddItem.or_create_from_scratch": "или создайте новые с нуля",
    "Itinerary.modal.BuilderAddItem.generic_item": "Общая запись",
    "Itinerary.modal.BuilderAddItem.generic_item_description": "Название и поле записи",
    "Itinerary.modal.BuilderAddItem.tour_cover": "Обложка тура",
    "Itinerary.modal.BuilderAddItem.tour_cover_description": "Название тура, короткое описание и фотография на обложку. Нужно добавить в первый раздел",
    "Itinerary.modal.BuilderAddItem.tour_name": "Название тура",
    "Itinerary.modal.BuilderAddItem.tour_name_description": "Название, короткое описание и порядковый номер",
    "Itinerary.modal.BuilderAddItem.client_information": "Информация о подписчике",
    "Itinerary.modal.BuilderAddItem.client_information_description": "Количество участников, имена и электронные адреса",
    "Itinerary.modal.BuilderAddItem.trip_highlights": "Самое интересное в поездке",
    "Itinerary.modal.BuilderAddItem.trip_highlights_description": "Название и список самого интересного в поездке",
    "Itinerary.modal.BuilderAddItem.trip_description": "Описание поездки",
    "Itinerary.modal.BuilderAddItem.trip_description_description": "Название и текстовое поле",
    "Itinerary.modal.BuilderAddItem.trip_dates": "Даты поездки",
    "Itinerary.modal.BuilderAddItem.trip_dates_description": "Название и даты поездки",
    "Itinerary.modal.BuilderAddItem.organizer_details": "Информация об организаторе",
    "Itinerary.modal.BuilderAddItem.organizer_details_description": "Информация об организаторе и менеджере тура",
    "Itinerary.modal.BuilderAddItem.destination_info": "Информация о направлении",
    "Itinerary.modal.BuilderAddItem.destination_info_description": "Название и поле для записи",
    "Itinerary.modal.BuilderAddItem.know_before_you_go": "Важно знать",
    "Itinerary.modal.BuilderAddItem.know_before_you_go_description": "Два списка: список вещей в поездку и список важных моментов до поездки",
    "Itinerary.modal.BuilderAddItem.description_of_day": "Описание дня",
    "Itinerary.modal.BuilderAddItem.description_of_day_description": "Название и два текстовых поля. Опишите день и добавьте важных деталей",
    "Itinerary.modal.BuilderAddItem.activity_included": "Активности / Сервисы (включенные)",
    "Itinerary.modal.BuilderAddItem.activity_included_description": "Список включенных в стоимость активностей и сервисов",
    "Itinerary.modal.BuilderAddItem.activity_optional": "Общий виджет",
    "Itinerary.modal.BuilderAddItem.activity_optional_description": "Список активностей и сервисов",
    "Itinerary.modal.BuilderAddItem.hotel": "Проживание",
    "Itinerary.modal.BuilderAddItem.hotel_description": "Отель / апартаменты / хостел и тд",
    "Itinerary.modal.BuilderAddItem.breakfast": "Завтрак",
    "Itinerary.modal.BuilderAddItem.breakfast_description": "Место для завтрака (ресторан / кафе и тд)",
    "Itinerary.modal.BuilderAddItem.place": "Место",
    "Itinerary.modal.BuilderAddItem.place_description": "Местоположение достопримечательнности/ресторана и т.д.",
    "Itinerary.modal.BuilderAddItem.lunch": "Обед",
    "Itinerary.modal.BuilderAddItem.lunch_description": "Место для обеда (ресторан / кафе и тд)",
    "Itinerary.modal.BuilderAddItem.dinner": "Ужин",
    "Itinerary.modal.BuilderAddItem.dinner_description": "Место для ужина (ресторан / кафе и тд)",
    "Itinerary.modal.BuilderAddItem.flight": "Перелет",
    "Itinerary.modal.BuilderAddItem.flight_description": "Детали перелета",
    "Itinerary.modal.BuilderAddItem.train": "Поезд",
    "Itinerary.modal.BuilderAddItem.train_description": "Детали переезда на поезде",
    "Itinerary.modal.BuilderAddItem.transfer": "Трансфер",
    "Itinerary.modal.BuilderAddItem.transfer_description": "Детали трансфера",
    "Itinerary.modal.BuilderAddItem.carrent": "Аренда автомобиля",
    "Itinerary.modal.BuilderAddItem.carrent_description": "Детали аренды автомобиля",
    "Itinerary.modal.BuilderAddItem.price_included": "Цена (включено)",
    "Itinerary.modal.BuilderAddItem.price_included_description": "Детали стоимости тура (на человека / на группу / доплаты)",
    "Itinerary.modal.BuilderAddItem.price_optional": "Цена (опционально)",
    "Itinerary.modal.BuilderAddItem.price_optional_description": "Детали стоимости тура для опций (на человека / на группу / доплаты)",
    "Itinerary.modal.BuilderAddItem.whatsincluded": "Что включено?",
    "Itinerary.modal.BuilderAddItem.whatsincluded_description": "Название, список позиций включенных в стоимость и список опциональных позиций.",
    "Itinerary.modal.BuilderAddItem.nextstep": "Следующие шаги для бронирования тура",
    "Itinerary.modal.BuilderAddItem.nextstep_description": "Размер депозита и детали оплаты",
    "Itinerary.modal.BuilderAddItem.cancelation_policy": "Условия аннуляции тура",
    "Itinerary.modal.BuilderAddItem.cancelation_policy_description": "Заголовок, табличка со штрафами при аннуляции",
    "Itinerary.modal.BuilderAddItem.ratings": "Рейтинг",
    "Itinerary.modal.BuilderAddItem.ratings_description": "Оцените по критериям от 1 до 5 звезд",
    "Itinerary.modal.BuilderAddItem.carousel": "Фотогалерея",
    "Itinerary.modal.BuilderAddItem.carousel_description": "Карусель изображений",
    "Itinerary.modal.BuilderAddItem.audio": "Аудио файл",
    "Itinerary.modal.BuilderAddItem.audio_description": "Загрузить аудио файл",
    "Itinerary.modal.BuilderAddItem.video_file": "Видео файл",
    "Itinerary.modal.BuilderAddItem.video_file_description": "Загрузить видео файл",
    "Itinerary.modal.BuilderAddItem.faq": "Часто задаваемые вопросы (FAQ)",
    "Itinerary.modal.Step2CarRental.dates": "Даты",
    "Itinerary.modal.Step2CarRental.start_date": "Дата начала",
    "Itinerary.modal.Step2CarRental.end_date": "Дата окончания",
    "Itinerary.modal.Step2CarRental.please_select": "Выберите даты.",
    "Itinerary.modal.Step2CarRental.cancel": "Отменить",
    "Itinerary.modal.Step2CarRental.add": "Добавить",
    "Itinerary.modal.Step2Sleep.dates": "Даты",
    "Itinerary.modal.Step2Sleep.start_date": "Дата начала",
    "Itinerary.modal.Step2Sleep.end_date": "Дата окончания",
    "Itinerary.modal.Step2Sleep.please_select": "Выберите даты.",
    "Itinerary.modal.Step2Sleep.autocomplete_google": "Заполнить автоматически из Google",
    "Itinerary.modal.Step2Sleep.type_to_search": "Начните печатать для поиска",
    "Itinerary.modal.Step2Sleep.cancel": "Отмена",
    "Itinerary.modal.Step2Sleep.add": "Добавить",
    "Itinerary.modal.Step2Sleep.add_hotel": "Добавить отель",
    "Itinerary.modal.Step2Sleep.add_item": "Добавить запись",
    "Itinerary.modal.ChangeDates.change_dates": "Изменить даты",
    "Itinerary.modal.ChangeDates.dates": "Даты",
    "Itinerary.modal.ChangeDates.start_date": "Даты начала",
    "Itinerary.modal.ChangeDates.end_date": "Даты окончания",
    "Itinerary.modal.ChangeDates.select_dates": "Пожалуйста, выберите даты.",
    "Itinerary.modal.ChangeDates.cancel": "Отмена",
    "Itinerary.modal.ChangeManager.title": "Поменять менеджера поездки",
    "Itinerary.modal.ChangeManager.manager": "Менаджер",
    "Itinerary.modal.ChangeManager.save": "Сохранить",
    "Itinerary.modal.ChangeManager.load_manager_list_failed": "Не удалось загрузить список",
    "Itinerary.modal.ChangeManager.updated": "Менеджер поездки изменен",
    "Itinerary.modal.CoverImageUpload.set_cover_photo": "Настроить заглавную фотографию",
    "Itinerary.modal.CoverImageUpload.close": "Закрыть",
    "Itinerary.modal.ItemMap.location": "Местоположение",
    "Itinerary.modal.ItemMap.type_address_to_search_and_change": "Внесите адрес для поиска и изменения",
    "Itinerary.modal.ItemMap.click_drag_change_location": "Нажмите на карту или перетащите метку для изменения местаположения",
    "Itinerary.modal.ItemMap.close": "Закрыть",
    "Itinerary.modal.ItemMap.cancel": "Отмена",
    "Itinerary.modal.ItemMap.update_location": "Обновить местоположение",
    "Itinerary.modal.ItinerarySendEmail.send_itinerary_by_email_pdf": "Отправить тур по электронной почте (в формате PDF)",
    "Itinerary.modal.ItinerarySendEmail.clients": "Подписчики *",
    "Itinerary.modal.ItinerarySendEmail.clients_select": "Выбрать подписчика (подписчиков)",
    "Itinerary.modal.ItinerarySendEmail.text": "Текст *",
    "Itinerary.modal.ItinerarySendEmail.field_required": "Необходимо заполнить это поле",
    "Itinerary.modal.ItinerarySendEmail.send_copy_to_me": "Отправить копию мне",
    "Itinerary.modal.ItinerarySendEmail.send": "Отправить",
    "Itinerary.modal.ItinerarySendEmail.cancel": "Отмена",
    "Itinerary.modal.ItinerarySendEmail.email_sent": "Письмо отправлено",
    "Itinerary.modal.ItinerarySendEmail.failed_email_sent": "Не получилось отправить письмо",
    "Itinerary.modal.MealAddAlternative.add_meal_alternative": "Добавить альтернативу по питанию",
    "Itinerary.modal.MealAddAlternative.add_from_library": "Добавить из библиотеки",
    "Itinerary.modal.MealAddAlternative.tags": "Теги",
    "Itinerary.modal.MealAddAlternative.select_tag": "Выбрать теги",
    "Itinerary.modal.MealAddAlternative.type_to_search": "Печатайте для поиска",
    "Itinerary.modal.MealAddAlternative.create_from_scratch": "или создайте новое с нуля",
    "Itinerary.modal.PlacesAutocomplete.autocomplete_info": "Автозаполнение",
    "Itinerary.modal.PlacesAutocomplete.type_to_search": "Печатайте для поиска",
    "Itinerary.modal.PlacesAutocomplete.cancel": "Отмена",
    "Itinerary.modal.PlacesAutocomplete.update_info": "Обновить информацию",
    "Itinerary.modal.ReorderSectionItems.title": "Поменять записи местами",
    "Itinerary.modal.ReorderSectionItems.reorder": "Перетащите записи, чтобы поменять их местами",
    "Itinerary.modal.ReorderSectionItems.close": "Закрыть",
    "Itinerary.modal.SaveToLibrary.title": "Сохранить запись в библиотеку",
    "Itinerary.modal.SaveToLibrary.name": "Название",
    "Itinerary.modal.SaveToLibrary.name_description": "Это название для записи в вашей библиотеке, чтобы позже ее найти.",
    "Itinerary.modal.SaveToLibrary.name_required": "Необходимо заполнить это поле.",
    "Itinerary.modal.SaveToLibrary.name_size": "Максимум 255 знаков.",
    "Itinerary.modal.SaveToLibrary.tags": "Теги",
    "Itinerary.modal.SaveToLibrary.tags_select": "Выберите теги или создайте новые",
    "Itinerary.modal.SaveToLibrary.cancel": "Отмена",
    "Itinerary.modal.SaveToLibrary.save": "Сохранить в библиотеку",
    "Itinerary.modal.SaveToLibrary.update": "Обновить в библиотеке",
    "Itinerary.modal.SaveToLibrary.saved": "Запись сохранена в библиотеку",
    "Itinerary.modal.SaveToLibrary.alreadyexists": "Уже существует в библиотеке, изменения будут сохранены сверху. Установите флажок для создания нового элемента библиотеки параллельно со старым:",
    "Itinerary.modal.SleepAddAlternative.title": "Добавить альтернативное проживание",
    "Itinerary.modal.SleepAddAlternative.add_from_library": "Добавить из библиотеки",
    "Itinerary.modal.SleepAddAlternative.tags": "Теги",
    "Itinerary.modal.SleepAddAlternative.select_tags": "Выбрать теги",
    'Itinerary.modal.SleepAddAlternative.type_to_search': "Печатайте для поиска",
    "Itinerary.modal.SleepAddAlternative.create_from_scratch": "или создайте новое с нуля",
    "Itinerary.modal.TransportationMap.title": "Местоположение",
    "Itinerary.modal.TransportationMap.type_to_search": "Печатайте адрес для поиска",
    "Itinerary.modal.TransportationMap.click_on_map_or_drag": "Нажмите на карту или перетащите метку для изменения местаположения",
    "Itinerary.modal.TransportationMap.cancel": "Отмена",
    "Itinerary.modal.TransportationMap.update_location": "Обновить местоположение",
    "Itinerary.modal.UploadDocument.title": "Подгрузить файл",
    "Itinerary.modal.UploadDocument.close": "Закрыть",
    "Itinerary.TripActivity.optional_title": "Подзаголовок",
    "Itinerary.TripActivity.included_title": "Название включенной активности",
    "Itinerary.TripActivity.optional_description": "Описание",
    "Itinerary.TripActivity.included_description": "Описание включенной активности",
    "Itinerary.TripActivity.tooltip.move_to_another_section": "Переместить в другой раздел",
    "Itinerary.TripActivity.tooltip.show_on_map": "Показать на карте",
    "Itinerary.TripActivity.tooltip.set_on_map": "Настроить адрес/местоположение на карте",
    "Itinerary.TripActivity.tooltip.address": "Адрес",
    "Itinerary.TripActivity.tooltip.duration": "Продолжительность",
    "Itinerary.TripActivity.tooltip.language": "Язык",
    "Itinerary.TripActivity.tooltip.price": "Стоимость",
    "Itinerary.TripActivity.tooltip.price_type": "Вид цены",
    "Itinerary.TripActivity.tooltip.local_contact": "Контакт на месте",
    "Itinerary.TripActivity.tooltip.name": "Имя",
    "Itinerary.TripActivity.tooltip.phone": "Телефон",
    "Itinerary.TripActivity.tooltip.email": "Электронный адрес",
    "Itinerary.TripActivity.tooltip.start_time": "Время начала",
    "Itinerary.TripActivity.tooltip.caption_here": "Подпись к изображению",
    "Itinerary.TripActivity.tooltip.remove_activity": "Удалить активность",
    "Itinerary.TripActivity.button.add_images": "Добавить изображение",
    "Itinerary.TripActivity.button.add_activity": "Добавить активность",
    "Itinerary.TripActivity.price_types.free": "Бесплатно",
    "Itinerary.TripActivity.price_types.per_person": "На человека (p.p.)",
    "Itinerary.TripActivity.price_types.per_group": "На группу (p.g.)",
    "Itinerary.TripActivity.messages.delete_item_title": "Вы уверены?",
    "Itinerary.TripActivity.messages.delete_item_message": "Удалить активность?",
    "Itinerary.TripActivity.messages.delete_item_button": "Удалить",
    "Itinerary.TripCancelationPolicy.tooltip.move_to_another_section": "Переместить в другой раздел",
    "Itinerary.TripCancelationPolicy.tooltip.description": "Описание",
    "Itinerary.TripCancelationPolicy.tooltip.drag_to_reorder": "Перетащите для перемещения",
    "Itinerary.TripCancelationPolicy.tooltip.days_60_30": "60 - 30 дней",
    "Itinerary.TripCarRental.tooltip.change_rental_dates": "Изменить даты аренды",
    "Itinerary.TripCarRental.tooltip.company": "Компания",
    "Itinerary.TripCarRental.tooltip.pickup_location": "Место получения",
    "Itinerary.TripCarRental.tooltip.pickup_time": "Время получения",
    "Itinerary.TripCarRental.tooltip.phone": "Телефон",
    "Itinerary.TripCarRental.tooltip.dropoff_location": "Место возврата",
    "Itinerary.TripCarRental.tooltip.dropoff_time": "Время возврата",
    "Itinerary.TripCarRental.tooltip.reference_number": "Номер бронирование",
    "Itinerary.TripCarRental.tooltip.car_model": "Модель автомобиля",
    "Itinerary.TripCarRental.tooltip.details": "Подробности",
    "Itinerary.TripCarRental.tooltip.set_addres_on_map": "Настроить адрес/местоположение на карте",
    "Itinerary.TripClientInfo.tooltip.client_details": "Детали подписчика",
    "Itinerary.TripClientInfo.tooltip.guests": "Гости",
    "Itinerary.TripClientInfo.tooltip.number_guests": "Количество гостей",
    "Itinerary.TripClientInfo.tooltip.guest_name": "Имя гостя",
    "Itinerary.TripClientInfo.tooltip.guest_email": "Электронный адрес гостя",
    "Itinerary.TripCompanyInfo.tooltip.organizer_details": "Детали организатора",
    "Itinerary.TripCompanyInfo.tooltip.trip_manager": "Менеджер поездки",
    "Itinerary.TripCompanyInfo.tooltip.local_trip_manager": "Менеджер поездки на месте",
    "Itinerary.TripCompanyInfo.tooltip.name": "Имя",
    "Itinerary.TripCompanyInfo.tooltip.email": "Электронный адрес",
    "Itinerary.TripCompanyInfo.tooltip.phone": "Телефон",
    "Itinerary.TripCompanyInfo.tooltip.emergency_contacts": "Экстренные контакт",
    "Itinerary.TripCompanyInfo.tooltip.description": "Описание",
    "Itinerary.TripCompanyInfo.messages.manager_profile_pic": "Менеджеру необхдимо настроить свой профиль и (нажмите в верхнем правом углу)",
    "Itinerary.TripCompanyInfo.messages.failed_load_data": "Не получилось загрузить информацию",
    "Itinerary.TripCover.tooltip.set_cover": "Настройте фотографию обложки",
    "Itinerary.TripCover.tooltip.tour_name": "Название поездки",
    "Itinerary.TripCover.tooltip.places_to_visit": "Места/страны посещения",
    "Itinerary.TripDates.tooltip.trip_dates": "Даты поездки",
    "Itinerary.TripDescription.tooltip.type_something_here": "Начните печатать здесь...",
    "Itinerary.TripDestinationInfo.tooltip.places_to_visit": "”Места посещения”",
    "Itinerary.TripDestinationInfo.tooltip.type_something_here": "Начните печатать здесь...",
    "Itinerary.TripDestinationInfo.tooltip.emergency_information": "Информация экстренной помощи",
    "Itinerary.TripDestinationInfo.tooltip.name": "Имя",
    "Itinerary.TripDestinationInfo.tooltip.phone": "Телефон",
    "Itinerary.TripDestinationInfo.tooltip.description": "Описание",
    "Itinerary.TripGenericItem.tooltip.title": "Заголовок",
    "Itinerary.TripGenericItem.tooltip.details_placeholder": "Начните печатать здесь...",
    "Itinerary.TripGenericItem.tooltip.bullet_placeholder": "Начните печатать здесь...",
    "Itinerary.TripHighlights.tooltip.trip_highlights": "Самое интересное в поездке",
    "Itinerary.TripKnowBeforeGo.tooltip.pack_list": "Что взять с собой в поездку?",
    "Itinerary.TripKnowBeforeGo.tooltip.pack_item": "Опишите вещи здесь",
    "Itinerary.TripKnowBeforeGo.tooltip.important_to_know": "Важно знать до поездки",
    "Itinerary.TripKnowBeforeGo.tooltip.describe_item_here": "Опишите здесь",
    "Itinerary.TripMeal.tooltip.title": "Заголовок",
    "Itinerary.TripMeal.tooltip.name": "Название",
    "Itinerary.TripMeal.tooltip.google_rating": "Google рейтинг",
    "Itinerary.TripMeal.tooltip.description": "Описание",
    "Itinerary.TripMeal.tooltip.address": "Адрес",
    "Itinerary.TripMeal.tooltip.phone": "Телефон",
    "Itinerary.TripMeal.tooltip.open_web": "Открыть сайт",
    "Itinerary.TripMeal.tooltip.website": "Сайт",
    "Itinerary.TripMeal.tooltip.alternatives": "Альтернативы",
    "Itinerary.TripMeal.tooltip.all_alternatives": "Добавить альтернативу",
    "Itinerary.TripMeal.alternatives.set_as_main": "Установить как основную Опцию",
    "Itinerary.TripMeal.alternatives.title": "Заголовок",
    "Itinerary.TripMeal.alternatives.name": "Название",
    "Itinerary.TripNextSteps.tooltip.deposit": "Депозит",
    "Itinerary.TripNextSteps.tooltip.when_to_be_paid": "Оплата до",
    "Itinerary.TripNextSteps.tooltip.email": "Электронный адрес",
    "Itinerary.TripNextSteps.tooltip.phone": "Телефон",
    "Itinerary.TripPoi.tooltip.description_of_day": "Описание дня",
    "Itinerary.TripPoi.tooltip.description_of_place": "Описание места",
    "Itinerary.TripPlace.type.accommodation": "Проживание",
    "Itinerary.TripPlace.type.restaurant": "Ресторан",
    "Itinerary.TripPlace.type.cafe": "Кафе",
    "Itinerary.TripPlace.type.drink": "Бар",
    "Itinerary.TripPlace.type.sight": "Достопримечательность, музей",
    "Itinerary.TripPlace.type.meeting_point": "Место встречи",
    "Itinerary.TripPlace.type.starting_point": "Стартовая точка",
    "Itinerary.TripPlace.type.finish_point": "Конечная точка",
    "Itinerary.TripPlace.type.stop_along_route": "Остановка по ходу маршрута",
    "Itinerary.TripPlace.type.other": "Другое",
    "Itinerary.TripPrice.tooltip.price_per_person": "Стоимость на человека",
    "Itinerary.TripPrice.tooltip.price_per_group": "Стоимость на группу",
    "Itinerary.TripPrice.tooltip.per_person": "на человека",
    "Itinerary.TripPrice.tooltip.per_group": "на группу",
    "Itinerary.TripPrice.tooltip.adult": "Взрослый",
    "Itinerary.TripPrice.tooltip.child": "Ребенок",
    "Itinerary.TripPrice.tooltip.single_price": "Одноместное размещение",
    "Itinerary.TripPrice.tooltip.single_supplement": "Одноместное размещение",
    "Itinerary.TripPrice.tooltip.drag_to_reorder": "Перетащите для перемещения",
    "Itinerary.TripPrice.tooltip.num_persons_placeholder": "До __ человек",
    "Itinerary.TripPrice.tooltip.price_per_person_placeholder": "Стоимость",
    "Itinerary.TripSleep.tooltip.autocomplete_hotel": "Автоматическое заполнение информации по проживанию",
    "Itinerary.TripSleep.tooltip.change_dates": "Изменить даты проживания",
    "Itinerary.TripSleep.tooltip.hotel_name": "Название отеля",
    "Itinerary.TripSleep.tooltip.type": "Тип",
    "Itinerary.TripSleep.tooltip.select_hotel_type": "Выберите вид проживания или создайте новый вид",
    "Itinerary.TripSleep.tooltip.add_hotel_type": "Создать новый вид проживания",
    "Itinerary.TripSleep.tooltip.description": "Описание",
    "Itinerary.TripSleep.tooltip.address": "Адрес",
    "Itinerary.TripSleep.tooltip.phone": "Телефон",
    "Itinerary.TripSleep.tooltip.check_in": "Заезд",
    "Itinerary.TripSleep.tooltip.check_out": "Выезд",
    "Itinerary.TripSleep.tooltip.check_in_time": "Время заезда",
    "Itinerary.TripSleep.tooltip.check_out_time": "Время выезда",
    "Itinerary.TripSleep.tooltip.check_out_at": "Выезд в",
    "Itinerary.TripSleep.tooltip.meal_plan": "План питания",
    "Itinerary.TripSleep.tooltip.select_meal_plan": "Выберите формат питания",
    "Itinerary.TripSleep.tooltip.add_meal_plan": "Добавить новый формат питания",
    "Itinerary.TripSleep.tooltip.rooms": "Номера",
    "Itinerary.TripSleep.tooltip.room": "Номер",
    "Itinerary.TripSleep.tooltip.number_rooms": "Количество номеров",
    "Itinerary.TripSleep.tooltip.change_number_rooms": "Изменить количество номеров",
    "Itinerary.TripSleep.tooltip.select_room_type": "Выберите тип номера или создайте новый",
    "Itinerary.TripSleep.tooltip.add_room_type": "Добавить новый вид номера",
    "Itinerary.TripSleep.tooltip.alternatives": "Альтернативы",
    "Itinerary.TripSleep.tooltip.add_alternatives": "Добавить альтернативы",
    "Itinerary.TripSleep.tooltip.set_main_hotel": "Установить как основной отель",
    "Itinerary.TripSleep.tooltip.night": "Ночь",
    "Itinerary.TripSleep.tooltip.alternative_show_on_pdf": "Отображать в формате PDF",
    "Itinerary.TripSoundcloud.placeholder": "Вставьте код из секции “Share -> Embed”. Пример: <iframe src...>`",
    "Itinerary.TripTourName.tooltip.tour_name": "Название тура",
    "Itinerary.TripTourName.tooltip.short_description": "Короткое описание тура",
    "Itinerary.TripTourName.tooltip.reference": "Референс",
    "Itinerary.TripTransportation.tooltip.start_location": "Начало тура",
    "Itinerary.TripTransportation.tooltip.start_time": "Время начала",
    "Itinerary.TripTransportation.tooltip.phone": "Телефон",
    "Itinerary.TripTransportation.tooltip.duration": "Продолжительность",
    "Itinerary.TripTransportation.tooltip.end_location": "Конец тура",
    "Itinerary.TripTransportation.tooltip.end_time": "Время окончания",
    "Itinerary.TripTransportation.tooltip.distance": "Дистанция",
    "Itinerary.TripTransportation.tooltip.distance_units": "Километры/Милли",
    "Itinerary.TripTransportation.tooltip.set_address_on_map": "Указать адрес/местоположение на карте",
    "Itinerary.TripWhatsIncluded.table.date": "Дата",
    "Itinerary.TripWhatsIncluded.table.time": "Время",
    "Itinerary.TripWhatsIncluded.table.activity": "Активность",
    "Itinerary.TripWhatsIncluded.table.airline": "Авиакомпания",
    "Itinerary.TripWhatsIncluded.table.duration": "Продолжительность",
    "Itinerary.TripWhatsIncluded.table.type": "Вид",
    "Itinerary.TripWhatsIncluded.table.departure_location": "Отправление / местоположение",
    "Itinerary.TripWhatsIncluded.table.arrival": "Прибытие",
    "Itinerary.TripWhatsIncluded.table.price": "Стоимость",
    "Itinerary.TripWhatsIncluded.tooltip": {},
    "Itinerary.TripCarousel.title": "Заголовок",
    "Itinerary.TripCarousel.subtitle": "Подзаголовок",
    "Itinerary.TripCarousel.image": "Изображение",
    "Itinerary.TripCarousel.tooltip.drag_to_reorder": "Перетащите для перемещения",
    "Itinerary.TripAudio.upload": "Загрузить",
    "Itinerary.TripAudio.uploadSuccess": "Загрузка прошла успешно",
    "Itinerary.TripAudio.title": "Заголовок",
    "Itinerary.TripVideoFile.upload": "Загрузить",
    "Itinerary.TripVideoFile.uploadSuccess": "Загрузка прошла успешно",
    "Itinerary.TripVideoFile.title": "Заголовок",
    "v2.Itinerary.Flight.add_another_flight": "Добавить другой рейс",
    "v2.Itinerary.Flight.connection_time": "Время стыковки",
    "v2.Itinerary.Flight.airline": "Авиакомпания",
    "v2.Itinerary.Flight.start_typing_airline": "Начните вводить авиакомпанию",
    "v2.Itinerary.Flight.flight_number": "Номер рейса",
    "v2.Itinerary.Flight.class_desc": "класс",
    "v2.Itinerary.Flight.economy": "Эконом",
    "v2.Itinerary.Flight.duration": "продолжительность",
    "v2.Itinerary.Flight.departure": "ВЫЛЕТ ИЗ",
    "v2.Itinerary.Flight.type_airport_to_search": "Введите аэропорт для поиска",
    "v2.Itinerary.Flight.start_typing_airport": "Начните вводить аэропорт",
    "v2.Itinerary.Flight.time": "Время",
    "v2.Itinerary.Flight.arrival": "ПРИБЫТИЕ",
    "v2.Itinerary.defaults.CLIENT_INFO_ITEM_title": "Детали подписчика",
    "v2.Itinerary.defaults.ITEM_TYPE_TRIP_HIGHLIGHTS_title": "Вам очень понравится",
    "v2.Itinerary.defaults.ITEM_TYPE_TRIP_DESCRIPTION_title": "Описание поездки",
    "v2.Itinerary.defaults.ITEM_TYPE_TRIP_DATES_title": "Даты поездки",
    "v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_title": "Информация об организаторе",
    "v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_trip_manager": "Менеджер поездки",
    "v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_local_trip_manager": "Менеджер поездки на месте",
    "v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_emergency_contacts": "Экстренные контакт",
    "v2.Itinerary.defaults.ITEM_TYPE_DESTINATION_INFO_places_of_visit": "Места посещения",
    "v2.Itinerary.defaults.ITEM_TYPE_DESTINATION_INFO_emergency_info": "Информация экстренной помощи",
    "v2.Itinerary.defaults.ITEM_TYPE_KNOW_BEFORE_GO_packing_list_title": "Что взять с собой в поездку?",
    "v2.Itinerary.defaults.ITEM_TYPE_KNOW_BEFORE_GO_know_before_go_title": "Важно знать до поездки",
    "v2.Itinerary.defaults.ITEM_TYPE_POI_title": "Описание дня",
    "v2.Itinerary.defaults.ITEM_TYPE_ACTIVITY_title": "Активности / Сервисы",
    "v2.Itinerary.defaults.ITEM_TYPE_ACTIVITY_title_included": "(включенные)",
    "v2.Itinerary.defaults.ITEM_TYPE_ACTIVITY_title_optional": "(не включенные в стоимость)",
    "v2.Itinerary.defaults.ITEM_TYPE_ACTIVITY_title": "Стандартный элемент",
    "v2.Itinerary.defaults.ITEM_TYPE_FLIGHT_title": "Перелет",
    "v2.Itinerary.defaults.ITEM_TYPE_CAR_RENTAL_title": "Аренда автомобиля",
    "v2.Itinerary.defaults.ITEM_TYPE_MEAL_breakfast": "Завтрак",
    "v2.Itinerary.defaults.ITEM_TYPE_MEAL_lunch": "Обед",
    "v2.Itinerary.defaults.ITEM_TYPE_MEAL_dinner": "Ужин",
    "v2.Itinerary.defaults.ITEM_TYPE_PRICE_title": "Стоимость тура",
    "v2.Itinerary.defaults.ITEM_TYPE_PRICE_title_included": "включенные",
    "v2.Itinerary.defaults.ITEM_TYPE_PRICE_title_optional": "не включенные в стоимо",
    "v2.Itinerary.defaults.ITEM_TYPE_PRICE_num_persons_title": "Количество участников",
    "v2.Itinerary.defaults.ITEM_TYPE_PRICE_price_per_person_title": "Стоимость на человека",
    "v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_title": "Что включено?",
    "v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_flight_title": "Перелёты",
    "v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_hotel_others_title": "Отель / проживание",
    "v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_not_included_title": "Что не включено",
    "v2.Itinerary.defaults.ITEM_TYPE_NEXT_STEPS_title": "Следующие шаги - бронирование тура",
    "v2.Itinerary.defaults.ITEM_TYPE_CANCELATION_POLICY_title": "Условия аннуляции тура",
    "v2.Itinerary.defaults.ITEM_TYPE_CANCELATION_POLICY_days_title": "Количество дней до отправления",
    "v2.Itinerary.defaults.ITEM_TYPE_CANCELATION_POLICY_penalty_title": "Штраф за аннуляцию от общей стоимости",
    "v2.Itinerary.defaults.SECTION_TYPE_COVER_title": "Обложка",
    "v2.Itinerary.defaults.SECTION_TYPE_OTHER_tour_intro_title": "Описание тура",
    "v2.Itinerary.defaults.SECTION_TYPE_OTHER_prices_and_conditions_title": "Стоимость и условия",
    "v2.Itinerary.defaults.ITEM_NAME_GENERIC": "Общая запись",
    "v2.Itinerary.defaults.ITEM_NAME_COVER": "Обложка тура",
    "v2.Itinerary.defaults.ITEM_NAME_TOUR_NAME": "Название тура",
    "v2.Itinerary.defaults.ITEM_NAME_CLIENT_INFO": "Детали подписчика",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_HIGHLIGHTS": "Вам очень понравится",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_DESCRIPTION": "Описание поездки",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_DATES": "Даты поездки",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_SOUNDCLOUD": "Аудио запись (SoundCloud)",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_VIDEO": "Видео (youtube)",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_FAQ": "Часто задаваемые вопросы (FAQ)",
    "v2.Itinerary.defaults.ITEM_NAME_COMPANY_INFO": "Информация об организаторе",
    "v2.Itinerary.defaults.ITEM_NAME_DESTINATION_INFO": "Места посещения",
    "v2.Itinerary.defaults.ITEM_NAME_KNOW_BEFORE_GO": "Важно знать",
    "v2.Itinerary.defaults.ITEM_NAME_POI": "Описание дня",
    "v2.Itinerary.defaults.ITEM_NAME_ACTIVITY": "Активности / Сервисы",
    "v2.Itinerary.defaults.ITEM_NAME_TRANSPORTATION": "Транспортные услуги",
    "v2.Itinerary.defaults.ITEM_NAME_FLIGHT": "Перелёт",
    "v2.Itinerary.defaults.ITEM_NAME_CAR_RENTAL": "Аренда автомобиля",
    "v2.Itinerary.defaults.ITEM_NAME_SLEEP": "Проживание",
    "v2.Itinerary.defaults.ITEM_NAME_PLACE": "Место",
    "v2.Itinerary.defaults.ITEM_NAME_PRICE": "Стоимость тура",
    "v2.Itinerary.defaults.ITEM_NAME_PRICE_included": "включенные",
    "v2.Itinerary.defaults.ITEM_NAME_PRICE_optional": "не включенные в стоимо",
    "v2.Itinerary.defaults.ITEM_NAME_WHATS_INCLUDED": "Что включено?",
    "v2.Itinerary.defaults.ITEM_NAME_NEXT_STEPS": "Следующие шаги - бронирование тура",
    "v2.Itinerary.defaults.ITEM_NAME_CANCELATION_POLICY": "Условия аннуляции тура",
    "v2.Itinerary.defaults.ITEM_NAME_CAROUSEL": "Фотогалерея",
    "v2.Itinerary.defaults.ITEM_NAME_AUDIO": "Аудио файл",
    "v2.Itinerary.defaults.ITEM_NAME_VIDEO_FILE": "Видео файл",
    "v2.Itinerary.defaults.DAY": "День",
    "mainmenu.agency": "Компания",
    "mainmenu.configuration": "Конфигурация",
    "mainmenu.configuration_mobile": "Моб. прил.",
    "mainmenu.users": "Пользователи",
    "mainmenu.billing": "Платежи",
    "mainmenu.clients": "Подписчики",
    "mainmenu.itineraries": "Туры",
    "mainmenu.templates": "Шаблоны",
    "mainmenu.library": "Библиотека",
    "onboarding.welcome": "Добро пожаловать в Morego!",
    "onboarding.subtitle": "Мы очень рады, что вы решили присоединиться к Morego и готовы помочь при необходимости!",
    "onboarding.config_travel_agency": "Настройки информации об авторе",
    "onboarding.config_profile": "Настройте вашу контактную информацию и загрузите изображение профиля",
    "onboarding.create_users": "Пригласите больше пользователей в работу в системе",
    "onboarding.config_mobile_app": "Настройте мобильное приложение и запросите создание приложения",
    "onboarding.create_a_client": "Создать нового подписчика",
    "onboarding.create_itinerary": "Создайте свой первый тур",
    "dashboard.stats.total_for_this_month": "Всего за этот месяц",
    "dashboard.stats.last_month": "Прошлый месяц",
    "dashboard.stats.itineraries_created": "Количество новых туров",
    "dashboard.stats.itineraries_sold": "Количество готовых туров",
    "dashboard.stats.new_clients": "Новых подписчиков",
    "dashboard.stats.library_growth": "Прирост записей в библиотеке",
    "dashboard.listings.last_modified_its": "Недавно измененные туры",
    "dashboard.listings.last_modified_its_desc": "Туры, которые были недавно изменены",
    "dashboard.listings.about_to_start_its": "Туры, которые скоро начнутся",
    "dashboard.listings.about_to_start_its_desc": "Туры, которые начнутся в ближайший месяц"
}
