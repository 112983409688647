<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3 class="text-center">
                    <div class="table-cell">
                        <i class="fa fa-plane"></i>
                    </div>
                    <div class="table-cell" style="padding-left: 10px;">
                        <input type="text" class="name-input" v-model="item.name"/>
                    </div>
                </h3>
            </div>
        </div>
        <div v-for="(flight, index) in item.list" :key="flight.id"
                                :flight="flight"
                                :index="index"
        >
            <hr v-if="index > 0"/>
            <div class="card" v-if="index > 0">
                <div class="card-header">
                    {{$t("v2.Itinerary.Flight.connection_time")}}: <b>{{flight.connectionTime}}</b>
                </div>
            </div>
            <hr v-if="index > 0"/>
            <div class="row">
                <div class="col-12">
                    <div class="table-cell">
                        {{$t("v2.Itinerary.Flight.airline")}}: <b>{{flight.airline.name}}</b>
                    </div>
                </div>
                <div class="col-12">
                    <div class="table-cell">
                        {{$t("v2.Itinerary.Flight.flight_number")}}: <b>{{flight.airline.iata}}{{flight.flightNumber}}</b>
                    </div>
                </div>
                <div class="col-12">
                    <div class="table-cell">
                        {{$t("v2.Itinerary.Flight.class_desc")}}: <b>{{flight.flightClass}}</b>
                    </div>
                </div>
                <div class="col-12 mb-4">
                    <div class="table-cell">
                        {{$t("v2.Itinerary.Flight.duration")}}: <b>{{flight.duration}}</b>
                    </div>
                </div>
                <div class="col-6">
                    <h5>{{$t("v2.Itinerary.Flight.departure")}}</h5>
                    <hr/>
                    <div class="city-name">
                        {{flight.startLocation.city ? flight.startLocation.city : "&nbsp;"}}
                    </div>
                    <div>
                        {{getAirportLabel(flight.startLocation)}}
                    </div>
                    <div>
                        <div class="table-cell">
                            {{$t("v2.Itinerary.Flight.time")}}: <b>{{flight.startTime}}</b>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <h5>{{$t("v2.Itinerary.Flight.arrival")}}</h5>
                    <hr/>
                    <div class="city-name">
                        {{flight.endLocation.city ? flight.endLocation.city : "&nbsp;"}}
                    </div>
                    <div>
                        {{getAirportLabel(flight.endLocation)}}
                    </div>
                    <div>
                        <div class="table-cell">
                            {{$t("v2.Itinerary.Flight.time")}}: <b>{{flight.endTime}}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        components: {
        },
        mixins: [
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
            }
        },
        mounted() {
        },
        computed: {
        },
        methods: {
            getAirportLabel(airport) {
                if (airport.airportName)
                    return airport.airportName + " (" + (airport.iata || airport.icao) + ") - " + airport.city + ", " + airport.countryName;
                return "";
            },
        }
    }
</script>

<style scoped>
    .airline-name {
        font-size: 1.4em;
        font-weight: bold;
    }

    .city-name {
        font-size: 1.2em;
        font-weight: bold;
    }
</style>
