<template>
    <div class="modal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{name}} {{$t("Itinerary.modal.ItemMap.location")}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div ref="map" class="map-container"></div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-link" @click="close">{{$t("Itinerary.modal.ItemMap.close")}}</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            route: {
                type: Object,
                required: false,
            },
            point: {
                type: Object,
                required: false,
            },
        },
        data() {
            return {
                mapSetupDone: false,
            }
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function (e) {
                self.$emit('update:show', false);
            });
        },
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                    if (!this.mapSetupDone) {
                        // console.log("## SETUP MAP!");
                        this.setupMap();
                    } else {
                        // console.log("## SET MARKERS!");
                        this.clearMarkers();
                        this.loadDataOnMap();
                    }
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
            },
        },
        computed: {
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            setupMap() {
                const self = this;
                this.bounds = new google.maps.LatLngBounds();
                this.infowindow = new google.maps.InfoWindow();
                this.markers = [];
                this.paths = [];

                let center = {
                    lat: -34.397,
                    lng: 150.644,
                };
                this.map = new google.maps.Map(this.$refs.map, {
                    center: center,
                    zoom: 2,
                });

                this.mapSetupDone = true;
                this.loadDataOnMap();
            },
            clearMarkers() {
                if (this.markers) {
                    this.markers.forEach(marker => {
                        marker.setMap(null);
                    });
                    this.markers = [];
                }
                if (this.paths) {
                    this.paths.forEach(path => {
                        path.setMap(null);
                    });
                    this.paths = [];
                }
                this.bounds = new google.maps.LatLngBounds();
            },
            loadDataOnMap() {
                if (this.route && this.route.points) {
                    this.createWaypointMarker({lat: this.route.points[0].lat, lng: this.route.points[0].lng}, this.route.name);
                    let path = new google.maps.Polyline({
                        path: this.route.points,
                        geodesic: false,
                        strokeColor: '#0000FF',
                        strokeOpacity: 1.0,
                        strokeWeight: 2
                    });

                    const self = this;
                    this.route.points.forEach(point => {
                        self.bounds.extend(point);
                    });

                    path.setMap(this.map);
                    this.paths.push(path);
                }

                if (this.point) {
                    this.createWaypointMarker({lat: this.point.lat, lng: this.point.lng}, this.point.name);
                }
                this.fitBounds();
            },
            createWaypointMarker(position, content) {
                const self = this;
                let marker = new google.maps.Marker({
                    position:  position,
                    map: this.map,
                });
                marker.addListener('click', function () {
                    self.infowindow.setContent(content);
                    self.infowindow.open(self.map, marker);
                });

                this.bounds.extend(marker.position);

                this.markers.push(marker);
            },
            fitBounds() {
                if (!this.bounds.isEmpty()) {
                    const self = this;
                    this.map.fitBounds(this.bounds);
                    const listener = google.maps.event.addListener(this.map, "idle", function() {
                        if (self.map.getZoom() > 16) {
                            self.map.setZoom(16);
                        }
                        // console.log("### zoom => " + self.map.getZoom());
                        google.maps.event.removeListener(listener);
                    });
                } else {
                    // console.log("### bounds is empty, zoom => " + this.map.getZoom());
                    this.map.setCenter({
                        lat: 13.717,
                        lng: -25.816,
                    });
                    this.map.setZoom(2);
                }
            },

        },
    }
</script>

<style scoped>
   .map-container {
       height: 400px;
       width: 100%;
   }
</style>
