<template>
    <div class="row">
        <div v-if="subscriptionBad" class="col-sm-12 col-md-12">
            <div class="alert alert-danger" role="alert">
                {{$t("SubscriptionManager.warning.generic_lock")}}
                <a :href="subscriptionUrl"
                   class="btn btn-primary">
                    {{$t("root.manage_your_subscriptions")}}
                </a>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h1>{{$t("UserList.title")}}&nbsp;&nbsp;
                        <a v-if="subscriptionGood"  :href="userInviteUrl" class="btn btn-primary">
                            <span class="fa fa-plus-circle"></span> {{$t("UserList.button.invite_user")}}
                        </a>
                    </h1>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-hover">

                            <thead>
                            <tr>
                                <th>{{$t("UserList.table.name")}}</th>
                                <th>{{$t("UserList.table.email")}}</th>
                                <th>{{$t("UserList.table.type")}}</th>
                                <th></th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr v-for="user in users">
                                <td>{{ user.name }}</td>
                                <td>{{ user.email }}</td>
                                <td>
                                    <select v-bind:disabled="isCurrentUser(user.id)" class="form-control" v-model="user.securityRoleId" v-on:change="updateUserSecurityRole(user)" >
                                        <option v-for="role in securityRoles" v-bind:value="role.id" >
                                            {{ role.displayName }}
                                        </option>
                                    </select>
                                </td>
                                <!--<td>
                                    <select v-bind:disabled="isAdmin(user)" class="form-control" v-model="user.roleId" v-on:change="updateUserRole(user)">
                                        <option v-for="role in dataRoles" v-bind:value="role.id" >
                                            {{ role.name }}
                                        </option>
                                    </select>
                                </td>-->
                                <td>
                                    <button v-if="!isCurrentUser(user.id)" v-on:click.prevent="deleteUser(user)" type="button" class="btn btn-danger" data-toggle="tooltip" data-placement="left" :title='$t("UserList.messages.delete_user")'>
                                        <i class="fa fa-trash"></i> {{$t("error.general_delete")}}
                                    </button>
                                </td>
                            </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12" style="margin-top: 10px;">
            <div class="card">
                <div class="card-header">
                    <h1>{{$t("UserList.invites_title")}}</h1>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-hover" v-cloak>

                            <thead>
                            <tr>
                                <th>{{$t("UserList.table.email")}}</th>
                                <th>{{$t("UserList.table.sent_by")}}</th>
                                <th>{{$t("UserList.table.date")}}</th>
                                <th></th>
                            </tr>
                            </thead>

                            <tr v-if=" invites.length === 0 ">
                                <td colspan="4"><span class="label label-primary center-block">
                                    {{$t("UserList.messages.invites_table_empty")}}</span></td>
                            </tr>

                            <tr v-for="invite in invites">
                                <td>{{ invite.email }}</td>
                                <td>{{ invite.createUserName }}</td>
                                <td>{{ invite.date }}</td>
                                <td>
                                    <button v-on:click.prevent="deleteInvite(invite)" type="button" class="btn btn-danger" data-toggle="tooltip" data-placement="left" :title='$t("UserList.messages.delete_invite")'>
                                        <span class="fa fa-trash" ></span>
                                    </button>
                                </td>
                            </tr>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {handleAjaxError, showCustomDialog, showError, showSuccess} from "../../utils";

    export default {
        name: "UserList",
        components: {
        },
        // props: ["uuid"],
        data() {
            return {
                loggedUser: '',
                users: [],
                securityRoles: [],
                dataRoles: [],
                ADMIN_ROLE: 4,

                invites: [],
                subscriptionData: {
                    status: 'UNKNOWN',
                },
            }
        },
        mounted() {
            this.getSubscriptionStatus();
            this.loggedUser = currentUserId;
            this.loadUsersList();
            this.loadUserSecurityRoles();
            this.loadUserInvites();
        },
        computed: {
            subscriptionGood() {
                if (this.subscriptionData.status === 'UNKNOWN') {
                    return false;
                }
                if (this.subscriptionData.status === 'HAD_SUBSCRIPTION_BLOCKED') {
                    return false;
                }
                if (this.subscriptionData.status === 'TRIAL_FINISHED_BLOCKED') {
                    return false;
                }
                return true;
            },
            subscriptionBad() {
                if (this.subscriptionData.status === 'HAD_SUBSCRIPTION_BLOCKED') {
                    return true;
                }
                if (this.subscriptionData.status === 'TRIAL_FINISHED_BLOCKED') {
                    return true;
                }
                return false;
            },
            subscriptionUrl() {
                return jsRoutes.controllers.SubscriptionController.subscriptionManager().url;
            },
            userInviteUrl() {
                return jsRoutes.controllers.AdministrationController.userInvite().url;
            }
        },
        methods: {
            getSubscriptionStatus() {
                const self = this;
                jsRoutes.controllers.SubscriptionController.getSubscriptionStatus()
                    .ajax({
                        success: function (data) {
                            self.subscriptionData = data;
                        },
                        error: function (error) {
                            // TODO , do something with error ?  Maybe blank the Page !
                        }
                    });
            },
            isCurrentUser: function (id) {
                //console.log("isLogged " + id == this.loggedUser);
                return id === this.loggedUser;
            },
            isAdmin: function (user) {
                return user.securityRoleId === this.ADMIN_ROLE; //
                //return id == this.loggedUser;
            },
            updateUserSecurityRole: function (user) {
                let updateData = {};
                updateData.userId = user.id;
                updateData.securityRoleId = user.securityRoleId;

                const url = jsRoutes.controllers.AdministrationController.updateUserSecurityRole().url;
                this.$http.post(url, updateData).then(function (response) {
                    showSuccess("Success", "User type has been updated");
                }).catch(function (error) {
                    // handle error
                    this.loadUsersList();
                    handleAjaxError(data);
                });
            },
            deleteUser: function (user) {
                //console.log("deleteUser");
                const self = this;
                let msg = "Once removed, \"" + user.name + "\" will no longer be able to access this account!";
                showCustomDialog("Are you sure?", msg, "Delete", "btn-danger", function () {
                    self.doDeleteUser(user);
                });
            },

            doDeleteUser: function (user) {
                //console.log("Agora é que é a sério...");
                const url = jsRoutes.controllers.AdministrationController.deleteUser(user.id).url;
                const self = this;

                this.$http.get(url).then(function (response) {
                    // set data
                    self.loadUsersList();
                    showSuccess("Success", "The user was deleted");

                }).catch(function (error) {
                    // handle error
                    handleAjaxError(error);
                });
            },
            loadUsersList: function () {
                const self = this;
                const url = jsRoutes.controllers.AdministrationController.getUsersList().url;

                // GET request
                axios.get(url).then(function (response) {
                    // set data
                    self.users = response.data;
                }).catch(function (error) {
                    // handle error
                    handleAjaxError(error);
                });
            },
            loadUserSecurityRoles: function() {
                const self = this;
                const rolesUrl = jsRoutes.controllers.AdministrationController.getSecurityRoles().url;
                this.$http.get(rolesUrl).then(function (response) {
                    //console.log("#### data -> " + data);
                    // set data
                    self.securityRoles = response.data;

                }).catch(function (error) {
                    // handle error
                    handleAjaxError(error);
                });
            },

            loadUserInvites: function () {
                const self = this;
                const url = jsRoutes.controllers.AdministrationController.getInvitesList().url;

                this.$http.get(url).then(function (response) {
                    // console.log("invites -> " + JSON.stringify(data));
                    self.invites = response.data;

                }).catch(function (error) {
                    // handle error
                    handleAjaxError(error);
                });
            },
            deleteInvite: function (invite) {
                //console.log("deleteInvite");
                const self = this;
                const msg = "Are you sure you want to remove the invite to \"" + invite.email + "\"?";
                showCustomDialog("Are you sure?", msg, "Delete", "btn-danger", function () {
                    self.doDeleteInvite(invite);
                });
            },

            doDeleteInvite: function (invite) {
                const url = jsRoutes.controllers.AdministrationController.deleteInvite(invite.id).url;
                const self = this;

                this.$http.get(url).then(function (response) {
                    self.loadUserInvites();
                    showSuccess("Success", "The invite was deleted");
                }).catch(function (error) {
                    // handle error
                    // showError("Error", "Delete invite error");
                    handleAjaxError(error);
                });
            }


        }
    }
</script>

<style scoped>

</style>
