<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h1>{{$t("ClientList.title")}}&nbsp;&nbsp;
                        <button v-if="subscriptionGood" @click="openCreateClient" class="btn btn-primary">
                            <span class="fa fa-plus-circle"></span> {{$t("ClientList.button.create_client")}}
                        </button>
                        <a v-if="subscriptionGood" :href="importClientsUrl" class="btn btn-primary ml-2" v-tooltip='$t("ClientList.tooltip.import_clients")'>
                            <span class="fa fa-cloud-upload-alt"></span> {{$t("ClientList.button.import_clients")}}
                        </a>
                    </h1>
                </div>
                <div class="card-body">
                    <div v-if="subscriptionBad" class="row">
                        <div class="col-sm-12 col-md-12 mx-auto">
                            <div class="alert alert-danger" role="alert">
                                {{$t("SubscriptionManager.warning.generic_lock")}}
                                <a v-if="subscriptionData.isAdmin" :href="subscriptionUrl"
                                   class="btn btn-primary">
                                    {{$t("root.manage_your_subscriptions")}}
                                </a>
                            </div>
                        </div>
                    </div>

                    <vue-bootstrap-table
                            :columns="clientCols"
                            :values="clients"
                            :show-filter="true"
                            :show-column-picker="true"
                            :sortable="true"
                            :filter-case-sensitive="false"
                            :selectable="false"
                            defaultOrderColumn="name"
                            :defaultOrderDirection="true"
                    >
                        <template v-slot:oper="slotProps">
                            <button v-on:click.prevent="openEditClient(slotProps.value)"
                                    type="button"
                                    class="btn btn-primary"
                            >
                                <i class="fa fa-edit"></i> {{$t("ClientList.button.edit")}}
                            </button>
                            <button v-on:click.prevent="deleteClient(slotProps.value)"
                                    type="button"
                                    class="btn btn-danger ml-1">
                                <i class="fa fa-trash"></i> {{$t("ClientList.button.del")}}
                            </button>
                        </template>

                    </vue-bootstrap-table>
                    <!--<div class="table-responsive">
                        <table class="table table-bordered table-striped table-hover">

                            <thead>
                            <tr>
                                <th>{{$t("ClientList.table.name")}}</th>
                                <th>{{$t("ClientList.table.email")}}</th>
                                <th>{{$t("ClientList.table.phone")}}</th>
                                <th style="width:180px;"></th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr v-for="client in clients">
                                <td>{{ client.name }}</td>
                                <td>{{ client.email }}</td>
                                <td>{{ client.phone }}</td>
                                <td style="width:200px;">
                                    <button v-on:click.prevent="openEditClient(client)"
                                            type="button"
                                            class="btn btn-primary"
                                    >
                                        <i class="fa fa-edit"></i> {{$t("ClientList.button.edit")}}
                                    </button>
                                    <button v-on:click.prevent="deleteClient(client)"
                                            type="button"
                                            class="btn btn-danger ml-1">
                                        <i class="fa fa-trash"></i> {{$t("ClientList.button.del")}}
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>-->
                </div>
            </div>
        </div>
        <client-modal :show.sync="showClientModal" :currclient="currentClient" v-on:reload="loadClientList"></client-modal>
    </div>
</template>

<script>
    import axios from 'axios'
    import {handleAjaxError, showCustomDialog, showSuccess} from "../../utils";
    import ClientModal from './ClientModal.vue';
    import VueBootstrapTable from "vue2-bootstrap-table2";

    export default {
        components: {
            ClientModal,
            VueBootstrapTable,
        },
        // props: ["uuid"],
        data() {
            return {
                clientCols: [
                    {
                        title: this.$t("ClientList.table.name"),
                        name: "name"
                    },
                    {
                        title: this.$t("ClientList.table.email"),
                        name: "email"
                    },
                    {
                        title: this.$t("ClientList.table.phone"),
                        name: "phone"
                    },
                    {
                        title: this.$t("ClientList.table.actions"),
                        name: "oper",
                        filterable: false,
                        sortable: false,
                    },
                ],
                clients: [],

                showClientModal: false,
                currentClient: null,

                subscriptionData: {
                    status: 'UNKNOWN',
                },
            }
        },
        mounted() {
            this.getSubscriptionStatus();
            this.loadClientList();
        },
        computed: {
            subscriptionGood() {
                            if (this.subscriptionData.status === 'UNKNOWN') {
                                return false;
                            }
                            if (this.subscriptionData.status === 'HAD_SUBSCRIPTION_BLOCKED') {
                                return false;
                            }
                            if (this.subscriptionData.status === 'TRIAL_FINISHED_BLOCKED') {
                                return false;
                            }
                            return true;
                        },
            subscriptionBad() {
                if (this.subscriptionData.status === 'HAD_SUBSCRIPTION_BLOCKED') {
                    return true;
                }
                if (this.subscriptionData.status === 'TRIAL_FINISHED_BLOCKED') {
                    return true;
                }
                return false;
            },
            subscriptionUrl() {
                return jsRoutes.controllers.SubscriptionController.subscriptionManager().url;
            },
            importClientsUrl() {
                return jsRoutes.controllers.ClientController.importClients().url;
            }
        },
        methods: {
            getSubscriptionStatus() {
                const self = this;
                jsRoutes.controllers.SubscriptionController.getSubscriptionStatus()
                    .ajax({
                        success: function (data) {
                            self.subscriptionData = data;
                        },
                        error: function (error) {
                            // TODO , do something with error ?  Maybe blank the Page !
                        }
                    });
            },
           /*
            isAdmin: function (user) {
                return user.securityRoleId === this.ADMIN_ROLE; //
                //return id == this.loggedUser;
            },
            updateUserSecurityRole: function (user) {
                let updateData = {};
                updateData.userId = user.id;
                updateData.securityRoleId = user.securityRoleId;

                const url = jsRoutes.controllers.AdministrationController.updateUserSecurityRole().url;
                this.$http.post(url, updateData).then(function (response) {
                    showSuccess("Success", "User type has been updated");
                }).catch(function (error) {
                    // handle error
                    this.loadUsersList();
                    handleAjaxError(data);
                });
            },*/
            openCreateClient() {
                this.currentClient = null;
                this.showClientModal = true;
            },
            openEditClient(client) {
                this.currentClient = client;
                this.showClientModal = true;
            },
            deleteClient: function (client) {
                //console.log("deleteUser");
                const self = this;
                let msg = self.$t("ClientList.messages.delete_make_sure_1") + " \"" + client.name + "\" " + self.$t("ClientList.messages.delete_make_sure_2");
                showCustomDialog(
                    self.$t("ClientList.messages.delete_title"),
                    msg,
                    self.$t("error.general_delete"),
                    "btn-danger", function () {
                    self.doDeleteClient(client);
                });
            },

            doDeleteClient: function (client) {
                const self = this;
                const url = jsRoutes.controllers.ClientController.deleteClient(client.id).url;

                this.$http.delete(url).then(function (response) {
                    // set data
                    self.clients.splice(self.clients.findIndex(function(i){
                        return i.id === client.id;
                    }), 1);
                    showSuccess(
                        self.$t("success.general_title"),
                        self.$t("ClientList.messages.delete_title_success")
                    );

                }).catch(function (error) {
                    // handle error
                    handleAjaxError(error);
                });
            },
            loadClientList: function () {
                const self = this;
                const url = jsRoutes.controllers.ClientController.getClientList().url;

                // GET request
                axios.get(url).then(function (response) {
                    // set data
                    self.clients = response.data;
                }).catch(function (error) {
                    // handle error
                    handleAjaxError(error);
                });
            },
        }
    }
</script>

<style scoped>

</style>
