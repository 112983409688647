<template>
    <div class="row">
        <div class="col-sm-10 mx-auto">

            <div class="card">
                <div class="card-header">
                    <h1>{{$t("UserInvite.title")}}</h1>
                </div>
                <div class="card-body">

                    <form id="userInviteForm" v-on:submit.prevent="createUserInvite" novalidate>

                        <div class="form-group" :class="{ 'has-error': $v.inviteEmail.$error }">
                            <label for="inviteEmail" class="control-label" >{{$t("UserInvite.fields.email")}}:</label>
                            <input type="email" class="form-control" id="inviteEmail" name="inviteEmail"
                                   :class="{'is-invalid' : $v.inviteEmail.$error}"
                                   v-model.trim="$v.inviteEmail.$model">
                            <div class="invalid-feedback" v-show="$v.inviteEmail.$error && !$v.inviteEmail.required">{{$t("UserInvite.fields.email_required")}}</div>
                            <div class="invalid-feedback" v-show="$v.inviteEmail.$error && !$v.inviteEmail.email">{{$t("UserInvite.fields.email_invalid")}}</div>
                        </div>

                        <div class="form-group" :class="{ 'has-error': $v.role.$error }">
                            <label class="control-label">{{$t("UserInvite.fields.user_type")}}:</label>
                            <select class="form-control" name="roleSelect" id="roleSelect"
                                    :class="{'is-invalid' : $v.role.$error}"
                                    v-model="$v.role.$model">
                                <option v-for="role in usersRoles" :value="role.id">
                                    {{ role.displayName }}
                                </option>
                            </select>
                            <div class="invalid-feedback" v-show="$v.role.$error && !$v.role.required">{{$t("UserInvite.fields.user_type_required")}}</div>
                        </div>

                        <v-button type="submit" :loading="loading" class="btn btn-primary float-right"><i class="fa fa-paper-plane-o" aria-hidden="true"></i>&nbsp;{{$t("UserInvite.button.send_invite")}}</v-button>
                        <a :href="usersUrl" class="btn btn-link float-right" style="margin-right: 10px;">
                            {{$t("UserInvite.button.go_back")}}</a>

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {handleAjaxError, showCustomDialog, showError, showSuccess} from "../../utils";
    import { required, email, minLength } from 'vuelidate/lib/validators';
    import vButton from '../../components/VButton';

    export default {
        name: "UserInvite",
        components: {
            vButton,
        },
        // props: ["uuid"],
        data() {
            return {
                usersRoles: [],
                inviteEmail: null,
                role: null,

                loading: false,
            }
        },
        validations: {
            inviteEmail: {
                required,
                email
            },
            role: {
                required,
            },
        },
        mounted() {
            const self = this;
            const url = jsRoutes.controllers.AdministrationController.getSecurityRoles().url;

            this.$http.get(url).then(function (response) {
                //console.log("roles -> " + JSON.stringify(data));
                self.usersRoles = response.data;
                if (self.usersRoles.length > 0) {
                    self.role = self.usersRoles[0].id;
                }

            }).catch(function (response) {
                // handle error
                showError(
                    self.$t("error.general_title"),
                    self.$t("UserInvite.messages.failed_loading_roles")
                );
            });
        },
        computed: {
            usersUrl() {
                return jsRoutes.controllers.AdministrationController.users().url;
            }
        },
        methods: {
            createUserInvite: function () {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.log("### INVALID");
                    return;
                }
                this.doCreateInvite();
            },

            doCreateInvite: function () {
                const self = this;
                const url = jsRoutes.controllers.AdministrationController.createUserInvite().url;

                this.loading = true;
                const dataObj = {
                    inviteEmail: this.inviteEmail,
                    inviteRole: this.role
                };
                this.$http.post(url, dataObj)
                    .then(function (response) {
                        self.loading = false;
                        self.inviteEmail = null;
                        self.$v.$reset();
                        //console.log("roles -> " + JSON.stringify(data));
                        showSuccess(
                            self.$t("success.general_title"),
                            self.$t("UserInvite.messages.invite_sent")
                        );
                        //window.location.href = jsRoutes.controllers.AdministrationController.users().url;
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });

            }
        }
    }
</script>

<style scoped>

</style>