<template>
    <div class="modal" tabindex="-1" role="dialog" ref="uploadGpxModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t("Itinerary.modal.UploadGpx.title")}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-horizontal">
                        <div class="form-group">
                            <form :action="gpxUploadUrl" method="post" class="dropzone dz-clickable" ref="dropzonegpx">
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-link" @click="close">{{$t("Itinerary.modal.UploadGpx.close")}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Dropzone from 'dropzone'
    import "dropzone/dist/dropzone.css"
    import {showError} from "../../../utils";

    export default {
        props: {
            show: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                dropzoneSetup: false
            }
        },
        mounted() {
            let self = this;
            $(this.$refs.uploadDocModal).on('hidden.bs.modal', function (e) {
                self.close()
            });

        },
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.uploadGpxModal).modal('show');
                    // this.clearSearchData();
                    if (!this.dropzoneSetup) {
                        this.setupDropzone();
                    }
                } else {
                    $(this.$refs.uploadGpxModal).modal('hide');
                }
            },
        },
        computed: {
            gpxUploadUrl() {
                // itinId deve ser uma variavel disponibilizada pelo play na view
                return jsRoutes.controllers.ItineraryController.uploadGpxFile(itinId).url;
            }
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            setupDropzone: function () {
                const self = this;
                this.dropzone = new Dropzone(this.$refs.dropzonegpx, {
                    paramName: "file", // The name that will be used to transfer the file
                    acceptedFiles: ".gpx",
                    maxFilesize: 20, // MB
                    uploadMultiple: false,
                    //maxFiles: 1,
                    //addRemoveLinks: true,
                    /*accept: function (file, done) {
                     //se done() for chamado com argumento, o ficheiro n é submetido
                     done();
                     },*/
                    error: function (file, errorMessage, xhr) {//if the error was due to the XMLHttpRequest the xhr object as third
                        showError(
                            self.$t("error.general_title"),
                            self.$t("error.general_failed_upload_file", {file: file.name, msg: errorMessage }),
                        );
                    },
                    success: function (file, response) {
                        self.$emit('reload');
                    },
                    /*queuecomplete: function(){
                        if (self.success) {
                            showSuccess("Sucesso", "Os ficheiros foram carregados");
                            self.cleanData();
                        }
                        //self.cleanData();
                    },*/

                    //MSGS
                    dictDefaultMessage: self.$t("Itinerary.general.click_or_drag_to_upload"),
                    dictInvalidFileType: self.$t("Itinerary.modal.UploadGpx.dropzone_invalid_file_type"),
                    // dictRemoveFile: "Remover ficheiro",
                    // dictMaxFilesExceeded: "Número de ficheiros excedido"
                });
                this.dropzoneSetup = true;
            },
        }
    }
</script>

<style scoped>

</style>
