<template>
    <div class="row" style="margin-top: 10px;position:relative;">
        <div class="map-navigation" v-if="hasDaySections">
            <div class="btn-group">
                <button v-if="navType === 'DAY'"
                        @click="previousDay"
                        :disabled="currentDay <= 1"
                        class="btn btn-light btn-lg"
                >
                    <i class="fa fa-caret-left"></i>
                </button>
                <button class="btn btn-light btn-lg"
                        v-tooltip="navBtnTooltip"
                        @click="switchNavigation"
                >
                    {{navBtnLabel}}
                </button>
                <button v-if="navType === 'DAY'"
                        @click="nextDay"
                        :disabled="currentDay >= lastDayNum"
                        class="btn btn-light btn-lg"
                >
                    <i class="fa fa-caret-right"></i>
                </button>
            </div>
        </div>
        <div class="col-12" style="height:500px;">
            <div ref="map" class="map-container" style="height:500px;"></div>
        </div>
    </div>
</template>
<i18n>
</i18n>
<script>
    import {
        getDayNumForSection,
        getDaySections, getParentItemById,
        ITEM_TYPE_ACTIVITY,
        ITEM_TYPE_CAR_RENTAL, ITEM_TYPE_FLIGHT,
        ITEM_TYPE_MEAL,
        ITEM_TYPE_SLEEP, ITEM_TYPE_SLEEP_LAST, ITEM_TYPE_SLEEP_MID,
        ITEM_TYPE_TRANSPORTATION
    } from "./itinerary-templates";
    import {handleAjaxError} from "../../utils";

    export default {
        props: {
            show: {
                type: Boolean,
                required: true,
            }
        },
        data() {
            return {
                navType: "ALL", // ALL ou DAY
                hasDaySections: false,
                currentDay: 1,
                lastDayNum: 1,

                mapSetupDone: false,
                routeList: [],
                waypointList: [],
            }
        },
        watch: {
            show() {
                if (this.show) {
                    if (this.daySections) {
                        this.hasDaySections = true;
                        this.lastDayNum = this.daySections.length;
                    } else {
                        this.hasDaySections = false;
                        this.lastDayNum = 1;
                    }
                    if (!this.mapSetupDone) {
                        // console.log("## SETUP MAP!");
                        this.setupMap();
                    } else {
                        // console.log("## SET MARKERS!");
                        this.setMarkersOnMap();
                        this.loadGpxData();
                    }
                }
            },
        },
        computed: {
            itData() {
                return this.$store.getters.itData;
            },
            navBtnLabel() {
                if (this.navType === "ALL") {
                    return this.$t("Itinerary.builder.map.full_trip_btn");
                } else {
                    return this.$t("Itinerary.builder.map.day_trip_btn", { day: ""+this.currentDay });
                    //return "Day " + this.currentDay;
                }
            },
            navBtnTooltip() {
                if (this.navType === "ALL") {
                    return this.$t("Itinerary.builder.map.full_trip_tooltip");
                } else {
                    return this.$t("Itinerary.builder.map.day_trip_tooltip");
                }
            },
            daySections() {
                return getDaySections(this.itData.tripData.sections);
            },
        },
        mounted() {
        },
        methods: {
            switchNavigation() {
                if (this.navType === "ALL") {
                    this.currentDay = 1;
                    this.navType = "DAY";
                } else {
                    this.navType = "ALL";
                }
                this.setMarkersOnMap();
                this.loadGpxData();
            },
            previousDay() {
                this.currentDay--;
                this.setMarkersOnMap();
                this.loadGpxData();
            },
            nextDay() {
                this.currentDay++;
                this.setMarkersOnMap();
                this.loadGpxData();
            },
            setupMap() {
                this.bounds = new window.google.maps.LatLngBounds();
                this.infowindow = new window.google.maps.InfoWindow();
                this.markers = [];
                this.paths = [];

                let center = {
                    lat: -34.397,
                    lng: 150.644,
                };
                this.map = new window.google.maps.Map(this.$refs.map, {
                    center: center,
                    zoom: 2,
                });

                this.mapSetupDone = true;
                this.setMarkersOnMap();
                this.loadGpxData();
            },
            clearMarkers() {
                if (this.markers) {
                    this.markers.forEach(marker => {
                        marker.setMap(null);
                    });
                    this.markers = [];
                }
                if (this.paths) {
                    this.paths.forEach(path => {
                        path.setMap(null);
                    });
                    this.paths = [];
                }
                this.bounds = new window.google.maps.LatLngBounds();
            },
            loadGpxData() {
                const self = this;
                const day = this.navType === 'DAY' ? this.currentDay : null;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ItineraryController.loadMapGpxRoutes(this.itData.id, day).url;
                this.$http.get(url).then(function(response) {
                    self.routeList = response.data.routes;
                    self.waypointList = response.data.waypoints;
                    self.setGpxDataOnMap();
                }, function(error) {
                    handleAjaxError(error);
                });
            },
            setGpxDataOnMap() {
                const self = this;
                this.routeList.forEach(route => {
                    this.createWaypointMarker({lat: route.details.points[0].lat, lng: route.details.points[0].lng}, route.details.name);
                    let path = new window.google.maps.Polyline({
                        path: route.details.points,
                        geodesic: false,
                        strokeColor: '#0000FF',
                        strokeOpacity: 1.0,
                        strokeWeight: 2
                    });

                    route.details.points.forEach(point => {
                        self.bounds.extend(point);
                    });

                    path.setMap(self.map);
                    self.paths.push(path);
                });

                this.waypointList.forEach(waypoint => {
                    self.createWaypointMarker({lat: waypoint.details.lat, lng: waypoint.details.lng}, waypoint.details.name);
                });
                this.fitBounds();
            },
            setMarkersOnMap() {
                const self = this;

                this.clearMarkers();

                let flightFraction = 0.5;
                let transferFraction = 0.5;
                let dayIndex = -1;
                if (this.daySections) {
                    this.daySections.forEach(section => {
                        dayIndex = dayIndex + 1;
                        if (dayIndex > 6) // Reset to first day color
                            dayIndex = 0;

                        const sectionDayNum = getDayNumForSection(section, this.itData);
                        if (self.navType === 'ALL' || self.currentDay === sectionDayNum) {
                            section.items.forEach(item => {
                                if (item.type === ITEM_TYPE_MEAL) {
                                    if (item.coordinates &&
                                        item.coordinates.lat &&
                                        item.coordinates.lng
                                    ) {
                                        let itemContent = "";
                                        if (item.website) {
                                            itemContent += "<b>" + (item.title ? item.title + ": " : "") + "<a href='" + item.website + "' target='_blank'>" + item.name + "</a></b>";
                                        } else {
                                            itemContent += "<b>" + (item.title ? item.title + ": " : "") + item.name + "</b>";
                                        }
                                        itemContent += "<br/>" + item.address;
                                        self.createMarker(item.coordinates.lat, item.coordinates.lng, itemContent, dayIndex, item.type + '_' + item.subType);
                                    }
                                }
                                if (item.type === ITEM_TYPE_SLEEP) {
                                    if (item.coordinates &&
                                        item.coordinates.lat &&
                                        item.coordinates.lng
                                    ) {
                                        let itemContent = "";
                                        if (item.website) {
                                            itemContent += "<b><a href='" + item.website + "' target='_blank'>" + item.name + "</a></b>";
                                        } else {
                                            itemContent += "<b>" + item.name + "</b>";
                                        }
                                        itemContent += "<br/>" + item.address;
                                        self.createMarker(item.coordinates.lat, item.coordinates.lng, itemContent, dayIndex, item.type);
                                    }
                                } else if (item.type === ITEM_TYPE_SLEEP_MID) {
                                    if (this.navType === 'DAY') {
                                        const parentItem = getParentItemById(this.daySections, item.parentId);
                                        if (parentItem.coordinates &&
                                            parentItem.coordinates.lat &&
                                            parentItem.coordinates.lng
                                        ) {
                                            let itemContent = "";
                                            if (parentItem.website) {
                                                itemContent += "<b><a href='" + parentItem.website + "' target='_blank'>(" + self.$t("Itinerary.TripSleep.tooltip.night") + ") " + parentItem.name + "</a></b>";
                                            } else {
                                                itemContent += "<b>" + parentItem.name + "</b>";
                                            }
                                            itemContent += "<br/>" + parentItem.address;
                                            self.createMarker(parentItem.coordinates.lat, parentItem.coordinates.lng, itemContent, dayIndex, parentItem.type);
                                        }
                                    }
                                } else if (item.type === ITEM_TYPE_SLEEP_LAST) {
                                    if (this.navType === 'DAY') {
                                        const parentItem = getParentItemById(this.daySections, item.parentId);
                                        if (parentItem.coordinates &&
                                            parentItem.coordinates.lat &&
                                            parentItem.coordinates.lng
                                        ) {
                                            let itemContent = "";
                                            if (parentItem.website) {
                                                itemContent += "<b><a href='" + parentItem.website + "' target='_blank'>(" + self.$t("Itinerary.TripSleep.tooltip.check_out") + ") " + parentItem.name + "</a></b>";
                                            } else {
                                                itemContent += "<b>" + parentItem.name + "</b>";
                                            }
                                            itemContent += "<br/>" + parentItem.address;
                                            self.createMarker(parentItem.coordinates.lat, parentItem.coordinates.lng, itemContent, dayIndex, parentItem.type);
                                        }
                                    }
                                } else if (item.type === ITEM_TYPE_ACTIVITY) {
                                    if (item.list) {
                                        item.list.forEach(activity => {
                                            if (activity.coordinates &&
                                                activity.coordinates.lat &&
                                                activity.coordinates.lng
                                            ) {
                                                let itemContent = "<b>" + (activity.included ? "(" + self.$t("Itinerary.builder.detail.included") + ") " : "(" + self.$t("Itinerary.builder.detail.optional") + ") ") + activity.description + "</b>";
                                                if (item.address) {
                                                    itemContent += "<br/>" + activity.address;
                                                }
                                                self.createMarker(activity.coordinates.lat, activity.coordinates.lng, itemContent, dayIndex, item.type);

                                                /*let marker = new google.maps.Marker({
                                            position: new google.maps.LatLng(activity.coordinates.lat, activity.coordinates.lng),
                                            map: map
                                        });
                                        marker.addListener('click', function () {
                                            let itemContent = "<b>" + (item.included ? "(Included) " : "(Optional) ") + activity.description + "</b>";
                                            if (item.address) {
                                                itemContent += "<br/>" + activity.address;
                                            }
                                            infowindow.setContent(itemContent);
                                            infowindow.open(map, marker);
                                        });

                                        bounds.extend(marker.position);*/
                                            }
                                        });
                                    }
                                } else if (item.type === ITEM_TYPE_CAR_RENTAL) {
                                    if (item.startLocation &&
                                        item.startLocation.lat &&
                                        item.startLocation.lng &&
                                        item.startLocation.address
                                    ) {
                                        let itemContent = "<b>" + item.name +
                                            (item.companyName ? " - " + item.companyName : "") +
                                            " (Pickup)</b>";
                                        if (item.address) {
                                            itemContent += "<br/>" + item.address;
                                        }
                                        self.createMarker(item.startLocation.lat, item.startLocation.lng, itemContent, dayIndex, item.type + '_start');
                                    }
                                    /*if (item.endLocation &&
                                        item.endLocation.lat &&
                                        item.endLocation.lng &&
                                        item.endLocation.address
                                    ) {
                                        let itemContent = "<b>" + item.name +
                                            (item.companyName ? " - " + item.companyName : "") +
                                            " (Dropoff)</b>";
                                        if (item.address) {
                                            itemContent += "<br/>" + item.address;
                                        }
                                        self.createMarker(map, bounds, infowindow, item.endLocation.lat, item.endLocation.lng, itemContent, dayIndex, item.type + '_end');
                                    }*/
                                } else if (item.type === ITEM_TYPE_TRANSPORTATION) {
                                    /*if (item.startLocation &&
                                        item.startLocation.lat &&
                                        item.startLocation.lng &&
                                        item.startLocation.address
                                    ) {
                                        let itemContent = "<b>" + item.name +
                                            (item.companyName ? " - " + item.companyName : "") +
                                            " (Start)</b>";
                                        if (item.startLocation.address) {
                                            itemContent += "<br/>" + item.startLocation.address;
                                        }
                                        if (item.startTime) {
                                            itemContent += "<br/>Time: " + item.startTime;
                                        }
                                        self.createMarker(map, bounds, infowindow, item.startLocation.lat, item.startLocation.lng, itemContent, dayIndex, item.type + '_' + item.subType + '_start');
                                    }
                                    if (item.endLocation &&
                                        item.endLocation.lat &&
                                        item.endLocation.lng &&
                                        item.endLocation.address
                                    ) {
                                        let itemContent = "<b>" + item.name +
                                            (item.companyName ? " - " + item.companyName : "") +
                                            " (End)</b>";
                                        if (item.endLocation.address) {
                                            itemContent += "<br/>" + item.endLocation.address;
                                        }
                                        if (item.endTime) {
                                            itemContent += "<br/>Time: " + item.endTime;
                                        }
                                        self.createMarker(map, bounds, infowindow, item.endLocation.lat, item.endLocation.lng, itemContent, dayIndex, item.type + '_' + item.subType + '_end');
                                    }*/

                                    // rota com path => http://jsfiddle.net/bfer8e0b/4/
                                    if (item.startLocation &&
                                        item.startLocation.lat &&
                                        item.startLocation.lng &&
                                        item.endLocation &&
                                        item.endLocation.lat &&
                                        item.endLocation.lng
                                    ) {
                                        this.bounds.extend(new window.google.maps.LatLng(item.startLocation.lat, item.startLocation.lng));
                                        this.bounds.extend(new window.google.maps.LatLng(item.endLocation.lat, item.endLocation.lng));

                                        const iconName = item.type + '_' + item.subType + '_start';
                                        const inBetween = window.google.maps.geometry.spherical.interpolate(
                                            new window.google.maps.LatLng(item.startLocation.lat, item.startLocation.lng),
                                            new window.google.maps.LatLng(item.endLocation.lat, item.endLocation.lng),
                                            transferFraction);

                                        transferFraction += 0.01;

                                        let itemContent = "<b>" + item.name +
                                            (item.companyName ? " - " + item.companyName : "") +
                                            "</b>";
                                        if (item.startLocation.address) {
                                            itemContent += "<br/><b>From: </b>" + item.startLocation.address;
                                        }
                                        if (item.startTime) {
                                            itemContent += "<br/><b>Time: </b>" + item.startTime;
                                        }
                                        if (item.endLocation.address) {
                                            itemContent += "<br/><b>To: </b>" + item.endLocation.address;
                                        }
                                        if (item.endTime) {
                                            itemContent += "<br/><b>Time: </b>" + item.endTime;
                                        }
                                        if (item.duration) {
                                            itemContent += "<br/><b>Duration: </b> " + item.duration;
                                        }

                                        self.createMarker2(inBetween, itemContent, dayIndex, iconName);

                                        const pathCoords = [
                                            {lat: item.startLocation.lat, lng: item.startLocation.lng},
                                            {lat: item.endLocation.lat, lng: item.endLocation.lng}
                                        ];
                                        let path = new window.google.maps.Polyline({
                                            path: pathCoords,
                                            geodesic: false,
                                            strokeColor: '#FF0000',
                                            strokeOpacity: 1.0,
                                            strokeWeight: 2
                                        });

                                        path.setMap(this.map);
                                        this.paths.push(path);
                                    }
                                } else if (item.type === ITEM_TYPE_FLIGHT) {
                                    item.list.forEach(flight => {
                                        /*if (flight.startLocation &&
                                            flight.startLocation.lat &&
                                            flight.startLocation.lng
                                        ) {
                                            let itemContent = "<b>Flight start: " + flight.startLocation.airportName + ", " + flight.startLocation.city +" </b>";
                                            if (flight.startLocation.address) {
                                                itemContent += "<br/>" + flight.endLocation.address;
                                            }
                                            if (flight.startTime) {
                                                itemContent += "<br/>Departure: " + flight.startTime;
                                            }
                                            self.createMarker(map, bounds, infowindow, flight.startLocation.lat, flight.startLocation.lng, itemContent, dayIndex, item.type + '_start');
                                        }
                                        if (flight.endLocation &&
                                            flight.endLocation.lat &&
                                            flight.endLocation.lng
                                        ) {
                                            let itemContent = "<b>Flight end: " + flight.endLocation.airportName + ", " + flight.endLocation.city +" </b>";
                                            if (flight.endLocation.address) {
                                                itemContent += "<br/>" + flight.endLocation.address;
                                            }
                                            if (flight.endTime) {
                                                itemContent += "<br/>Arrival: " + flight.endTime;
                                            }
                                            self.createMarker(map, bounds, infowindow, flight.endLocation.lat, flight.endLocation.lng, itemContent, dayIndex, item.type + '_end');
                                        }*/
                                        // rota com path => http://jsfiddle.net/bfer8e0b/4/
                                        if (flight.startLocation &&
                                            flight.startLocation.lat &&
                                            flight.startLocation.lng &&
                                            flight.endLocation &&
                                            flight.endLocation.lat &&
                                            flight.endLocation.lng
                                        ) {
                                            this.bounds.extend(new window.google.maps.LatLng(flight.startLocation.lat, flight.startLocation.lng));
                                            this.bounds.extend(new window.google.maps.LatLng(flight.endLocation.lat, flight.endLocation.lng));

                                            const flightPlanCoordinates = [
                                                {lat: flight.startLocation.lat, lng: flight.startLocation.lng},
                                                {lat: flight.endLocation.lat, lng: flight.endLocation.lng}
                                            ];
                                            const iconName = item.type + '_start';
                                            const inBetween = window.google.maps.geometry.spherical.interpolate(
                                                new window.google.maps.LatLng(flight.startLocation.lat, flight.startLocation.lng),
                                                new window.google.maps.LatLng(flight.endLocation.lat, flight.endLocation.lng),
                                                flightFraction);

                                            flightFraction+= 0.01

                                            let itemContent = "<b>Flight start: " + flight.startLocation.airportName + ", " + flight.startLocation.city +" </b>";
                                            if (flight.startLocation.address) {
                                                itemContent += "<br/>" + flight.endLocation.address;
                                            }
                                            if (flight.startTime) {
                                                itemContent += "<br/><b>Departure: </b>" + flight.startTime;
                                            }
                                            itemContent += "<br/><b>Flight end: " + flight.endLocation.airportName + ", " + flight.endLocation.city +" </b>";
                                            if (flight.endLocation.address) {
                                                itemContent += "<br/>" + flight.endLocation.address;
                                            }
                                            if (flight.endTime) {
                                                itemContent += "<br/><b>Arrival: </b>" + flight.endTime;
                                            }
                                            if (flight.duration) {
                                                itemContent += "<br/><b>Duration: </b> " + flight.duration;
                                            }

                                            self.createMarker2(inBetween, itemContent, dayIndex, iconName);

                                            const flightPath = new window.google.maps.Polyline({
                                                path: flightPlanCoordinates,
                                                geodesic: true,
                                                strokeColor: '#FF0000',
                                                strokeOpacity: 1.0,
                                                strokeWeight: 2,
                                                icons: [{
                                                    icon: {
                                                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
                                                    },
                                                    offset: '50%'
                                                }],
                                            });

                                            flightPath.setMap(this.map);
                                            this.paths.push(flightPath);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }

                this.fitBounds();
            },
            fitBounds() {
                if (!this.bounds.isEmpty()) {
                    this.map.fitBounds(this.bounds);
                    const self = this;
                    const listener = window.google.maps.event.addListener(this.map, "idle", function() {
                        if (self.map.getZoom() > 16) {
                            self.map.setZoom(16);
                        }
                        // console.log("### zoom => " + self.map.getZoom());
                        window.google.maps.event.removeListener(listener);
                    });
                } else {
                    // console.log("### bounds is empty, zoom => " + this.map.getZoom());
                    this.map.setCenter({
                        lat: 13.717,
                        lng: -25.816,
                    });
                    this.map.setZoom(2);
                }
            },
            createMarker(lat, lng, content, dayIndex, iconName) {
                const self = this;
                let marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(lat, lng),
                    map: this.map,
                    // icon: '/assets/images/markers/'+dayIndex+'/'+iconName+'.png'
                    icon: '/assets/images/markers/6/'+iconName+'.png'
                });
                marker.addListener('click', function () {
                    self.infowindow.setContent(content);
                    self.infowindow.open(self.map, marker);
                });

                this.bounds.extend(marker.position);

                this.markers.push(marker);
            },
            createMarker2(position, content, dayIndex, iconName) {
                const self = this;
                let marker = new window.google.maps.Marker({
                    position:  position,
                    map: this.map,
                    // icon: '/assets/images/markers/'+dayIndex+'/'+iconName+'.png'
                    icon: '/assets/images/markers/6/'+iconName+'.png'
                });
                marker.addListener('click', function () {
                    self.infowindow.setContent(content);
                    self.infowindow.open(self.map, marker);
                });

                this.bounds.extend(marker.position);

                this.markers.push(marker);
            },
            createWaypointMarker(position, content) {
                const self = this;
                let marker = new window.google.maps.Marker({
                    position:  position,
                    map: this.map,
                });
                marker.addListener('click', function () {
                    self.infowindow.setContent(content);
                    self.infowindow.open(self.map, marker);
                });

                this.bounds.extend(marker.position);

                this.markers.push(marker);
            },
        }
    }
</script>

<style scoped>
    .map-navigation {
        position: absolute;
        top: 5px;
        left: calc(100vh - 200px);
        width: 200px;
        height: 50px;
        line-height: 50px;
        /*background-color: red;*/
        z-index: 1;
        border-radius: 5px;
        text-align: center;
    }
</style>
