<template>
    <div class="page-break-inside-avoid">
        <div class="row">
            <div class="col-12">
                <h3>
                    <div class="table-cell">
                        <i class="fa fa-car-alt"></i>
                    </div>
                    <div class="table-cell" style="padding-left: 10px;">
                        <input type="text" class="name-input" v-model="item.name"/>
                    </div>
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="table-cell company-field">
                    <input class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           v-model="item.companyName"
                           :placeholder='$t("Itinerary.TripCarRental.tooltip.company")'
                    />
                </div>
                <div class="table-cell location-field">
                    <div style="text-align: center">
                        {{itemStartDate}}
                    </div>
                    <input class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           placeholder="Pickup location"
                           v-model="item.startLocation.city"
                           v-tooltip='$t("Itinerary.TripCarRental.tooltip.pickup_location")'
                    />
                    <input class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           :placeholder='$t("Itinerary.TripCarRental.tooltip.pickup_time")'
                           v-model="item.startTime"
                           v-if="stringNotEmpty(item.startTime)"
                    />
                    <input class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           :placeholder='$t("Itinerary.TripCarRental.tooltip.phone")'
                           v-model="item.startLocation.phone"
                           v-if="stringNotEmpty(item.startLocation.phone)"
                    />
                </div>
                <div class="table-cell location-field" style="vertical-align:middle;text-align: center;width:200px;">
                    <i class="fa fa-arrow-right fa-2x"></i>
                </div>
                <div class="table-cell location-field">
                    <div style="text-align: center">
                        {{itemEndDate}}
                    </div>
                    <input class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           :placeholder='$t("Itinerary.TripCarRental.tooltip.dropoff_location")'
                           v-model="item.endLocation.city"
                           v-tooltip='$t("Itinerary.TripCarRental.tooltip.dropoff_location")'
                    />
                    <input class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           :placeholder='$t("Itinerary.TripCarRental.tooltip.dropoff_time")'
                           v-model="item.endTime"
                           v-if="stringNotEmpty(item.endTime)"
                    />
                    <input class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           :placeholder='$t("Itinerary.TripCarRental.tooltip.phone")'
                           v-model="item.endLocation.phone"
                           v-if="stringNotEmpty(item.endLocation.phone)"
                    />
                </div>
            </div>
            <div class="col-12" style="margin-top: 10px;display:table; position: relative; z-index: 1;">
                <div class="table-cell company-field" style="height: 150px;">
                    <picture-input
                            ref="pictureInput"
                            accept="image/jpeg,image/png"
                            size="10"
                            :hide-change-button="true"
                            :crop="false"
                            :prefill="imageUrl"
                            :prefill-options="{
                                mediaType: 'image/jpeg',
                            }"
                            :custom-strings="{
                                        upload: '<h1>Bummer!</h1>',
                                        drag: '<i class=\'fa fa-plus-circle\'></i><br/>Click or drag an image'
                                    }"
                    >
                    </picture-input>
                </div>
                <div class="table-cell" style="padding-left: 10px;">
                    <input class="mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripCarRental.tooltip.reference_number")'
                           v-model="item.referenceNumber"
                           v-if="stringNotEmpty(item.referenceNumber)"
                    />
                    <input class="mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripCarRental.tooltip.car_model")'
                           v-model="item.car"
                           v-if="stringNotEmpty(item.car)"
                    />
                    <div v-html="cleanupString(item.details)"></div>
<!--
                    <textarea-autosize
                            v-if="stringNotEmpty(item.details)"
                            style="margin-top: 5px;border:0;padding:0;color:black;"
                            class="form-control body-font-size"
                            :placeholder='$t("Itinerary.TripCarRental.tooltip.details")'
                            v-model="item.details"
                            :min-height="30"
                            :max-height="500"
                    ></textarea-autosize>
-->

                    <!--<textarea style="margin-top: 5px;"
                            class="form-control"
                            placeholder="Details"
                            v-model="item.details"
                            @blur="emitAutosave"
                    >

                    </textarea>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PictureInput from 'vue-picture-input'
    import axios from 'axios';
    import {stringUtilsMixin} from "../../item/item-mixins";
    import {formatMoment, getDateMomentForDayNum} from "../../../../date-utils";

    export default {
        components: {
            PictureInput,
        },
        mixins: [
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            itData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                // showButtons: false,
            }
        },
        computed: {
            itemStartDate() {
                const itData = this.itData;

                if (itData.startDate && itData.endDate) {
                    return formatMoment(
                        getDateMomentForDayNum(this.item.startDay, itData.startDate, itData.dateFormat),
                        itData.dateFormat
                    );
                } else {
                    return this.$t('v2.Itinerary.defaults.DAY') + " " + this.item.startDay;
                }
            },
            itemEndDate() {
                const itData = this.itData;

                if (itData.startDate && itData.endDate) {
                    return formatMoment(
                        getDateMomentForDayNum(this.item.endDay, itData.startDate, itData.dateFormat),
                        itData.dateFormat
                    );
                } else {
                    return this.$t('v2.Itinerary.defaults.DAY') + " " + this.item.endDay;
                }
            },
            imageUrl() {
                if (this.item.images[0].type !== null) {
                    if (this.item.images[0].type === "LOCAL") {
                        // var quality esta na view twirl
                        return jsRoutes.controllers.ItineraryController.imageDownload(this.item.images[0].id, quality).url
                    }
                } else {
                    return null;
                    //return "/assets/images/car-icon.png";
                }
            }
        },
        methods: {
        }
    }
</script>

<style scoped>
    .company-field {
        font-size:1.5em;
        width:200px;
        vertical-align: middle;
        border: 1px solid lightgray;
        border-radius: 5px;
    }

    .location-field {
        text-align: center;
        font-size:1.2em;
        vertical-align: middle;
        /*border: 1px solid lightgray;*/
        /*border-radius: 5px;*/
    }


</style>
