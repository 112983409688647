<template>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h2>
                            <div class="form-row">
                                <div class="col-auto">
                                    {{$t("Itinerary.builder.library.library")}} -
                                </div>
                                <div class="col">
                                    <input type="text"
                                           class="name-input"
                                           v-model="libraryName"
                                           :placeholder='$t("Itinerary.builder.library.title")'
                                           v-tooltip='$t("Itinerary.builder.library.click_to_change_name")'
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-auto">
                                    {{$t("Itinerary.builder.library.tags")}} -
                                </div>
                                <div class="col">
                                    <el-select
                                            style="width:100%;"
                                            v-model="item.tags"
                                            multiple
                                            default-first-option
                                            filterable
                                            allow-create
                                            :placeholder='$t("Itinerary.builder.library.tag_select_add")'
                                    >
                                        <el-option v-for="tag in tagList"
                                                   :key="tag.id"
                                                   :label="tag.name"
                                                   :value="tag.id"
                                        ></el-option>
                                    </el-select>
                                </div>
                            </div>
                            <!--<div class="d-flex align-items-start">
                                Library - &nbsp;&nbsp;
                                <input type="text"
                                       class="name-input"
                                       v-model="libraryName"
                                       placeholder="Title"
                                       v-tooltip="'Click to change item name'"
                                />
                            </div>-->
                                <!--<input type="text"
                                       class="form-control"
                                       v-model="libraryName"
                                       v-tooltip="'Click to change item name'"
                                />-->
                            <!--Library -
                            <input type="text"
                                   class="form-control"
                                   v-model="libraryName"
                                   v-tooltip="'Click to change item name'"
                            />-->
                        </h2>
                    </div>
                    <div class="card-body">
                        <div v-if="item.type !== 'DAY' && item.type !== 'OTHER'" class="row">
                            <div class="col-12">
                                <trip-generic-item
                                        v-if="item.type === 'GENERIC'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-generic-item>
                                <!--<trip-cover
                                        v-if="item.type === 'COVER'"
                                        :item.sync="item"
                                        :start-date="itData.startDate"
                                        :end-date="itData.endDate"
                                >
                                </trip-cover>-->
                                <trip-tour-name
                                        v-if="item.type === 'TOUR_NAME'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-tour-name>
                                <!--<trip-client-info
                                        v-if="item.type === 'CLIENT_INFO'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-client-info>-->
                                <trip-highlights
                                        v-if="item.type === 'TRIP_HIGHLIGHTS'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-highlights>
                                <trip-description
                                        v-if="item.type === 'TRIP_DESCRIPTION'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-description>
                                <trip-destination-info
                                        v-if="item.type === 'DESTINATION_INFO'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-destination-info>
                                <trip-poi
                                        v-if="item.type === 'POI'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-poi>
                                <trip-activity
                                        v-if="item.type === 'ACTIVITY'"
                                        :item.sync="item.data"
                                        :currency="currency"
                                        :libmode="true"
                                >
                                </trip-activity>
                                <trip-transportation
                                        v-if="item.type === 'TRANSPORTATION'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-transportation>
                                <trip-flight
                                        v-if="item.type === 'FLIGHT'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-flight>
                                <trip-car-rental
                                        v-if="item.type === 'CAR_RENTAL'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-car-rental>
                                <trip-meal
                                        v-if="item.type === 'MEAL'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-meal>
                                <trip-sleep
                                        v-if="item.type === 'SLEEP'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-sleep>
                                <trip-know-before-go
                                        v-if="item.type === 'KNOW_BEFORE_GO'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-know-before-go>
                                <trip-price
                                        v-if="item.type === 'PRICE'"
                                        :item.sync="item.data"
                                        :currency="currency"
                                        :trip-type="tripType"
                                        :libmode="true"
                                >
                                </trip-price>
                                <!--<trip-whats-included
                                        v-if="item.type === 'WHATS_INCLUDED'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-whats-included>-->
                                <trip-next-steps
                                        v-if="item.type === 'NEXT_STEPS'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-next-steps>
                                <trip-cancelation-policy
                                        v-if="item.type === 'CANCELATION_POLICY'"
                                        :item.sync="item.data"
                                        :libmode="true"
                                >
                                </trip-cancelation-policy>
                            </div>
                        </div>
                        <div v-else>
                            <div v-for="itemNug in item.data.items" class="row">
                                <div class="col-7">
                                    <trip-generic-item
                                            v-if="itemNug.type === 'GENERIC'"
                                            :item.sync="itemNug"
                                            :libmode="true"
                                    >
                                    </trip-generic-item>
                                    <!--<trip-cover
                                            v-if="item.type === 'COVER'"
                                            :item.sync="item"
                                            :start-date="itData.startDate"
                                            :end-date="itData.endDate"
                                    >
                                    </trip-cover>-->
                                    <trip-tour-name
                                            v-if="itemNug.type === 'TOUR_NAME'"
                                            :item.sync="itemNug"
                                            :libmode="true"
                                    >
                                    </trip-tour-name>
                                    <!--<trip-client-info
                                            v-if="item.type === 'CLIENT_INFO'"
                                            :item.sync="item.data"
                                            :libmode="true"
                                    >
                                    </trip-client-info>-->
                                    <trip-highlights
                                            v-if="itemNug.type === 'TRIP_HIGHLIGHTS'"
                                            :item.sync="itemNug"
                                            :libmode="true"
                                    >
                                    </trip-highlights>
                                    <trip-description
                                            v-if="itemNug.type === 'TRIP_DESCRIPTION'"
                                            :item.sync="itemNug"
                                            :libmode="true"
                                    >
                                    </trip-description>
                                    <trip-destination-info
                                            v-if="itemNug.type === 'DESTINATION_INFO'"
                                            :item.sync="itemNug"
                                            :libmode="true"
                                    >
                                    </trip-destination-info>
                                    <trip-poi
                                            v-if="itemNug.type === 'POI'"
                                            :item.sync="itemNug"
                                            :libmode="true"
                                    >
                                    </trip-poi>
                                    <trip-activity
                                            v-if="itemNug.type === 'ACTIVITY'"
                                            :item.sync="itemNug"
                                            :currency="currency"
                                            :libmode="true"
                                    >
                                    </trip-activity>
                                    <trip-transportation
                                            v-if="itemNug.type === 'TRANSPORTATION'"
                                            :item.sync="itemNug"
                                            :libmode="true"
                                    >
                                    </trip-transportation>
                                    <trip-flight
                                            v-if="itemNug.type === 'FLIGHT'"
                                            :item.sync="itemNug"
                                            :libmode="true"
                                    >
                                    </trip-flight>
                                    <trip-car-rental
                                            v-if="itemNug.type === 'CAR_RENTAL'"
                                            :item.sync="itemNug"
                                            :libmode="true"
                                    >
                                    </trip-car-rental>
                                    <trip-meal
                                            v-if="itemNug.type === 'MEAL'"
                                            :item.sync="itemNug"
                                            :libmode="true"
                                    >
                                    </trip-meal>
                                    <trip-sleep
                                            v-if="itemNug.type === 'SLEEP'"
                                            :sections=[]
                                            :item.sync="itemNug"
                                            :libmode="true"
                                    >
                                    </trip-sleep>
                                    <trip-know-before-go
                                            v-if="itemNug.type === 'KNOW_BEFORE_GO'"
                                            :item.sync="itemNug"
                                            :libmode="true"
                                    >
                                    </trip-know-before-go>
                                    <trip-price
                                            v-if="itemNug.type === 'PRICE'"
                                            :item.sync="itemNug"
                                            :currency="currency"
                                            :trip-type="tripType"
                                            :libmode="true"
                                    >
                                    </trip-price>
                                    <!--<trip-whats-included
                                            v-if="item.type === 'WHATS_INCLUDED'"
                                            :item.sync="item.data"
                                            :libmode="true"
                                    >
                                    </trip-whats-included>-->
                                    <trip-next-steps
                                            v-if="itemNug.type === 'NEXT_STEPS'"
                                            :item.sync="itemNug"
                                            :libmode="true"
                                    >
                                    </trip-next-steps>
                                    <trip-cancelation-policy
                                            v-if="itemNug.type === 'CANCELATION_POLICY'"
                                            :item.sync="itemNug"
                                            :libmode="true"
                                    >
                                    </trip-cancelation-policy>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-2">
                <div class="d-flex justify-content-end">
                    <a class="btn btn-link" :href="libraryMainUrl">{{$t("Itinerary.builder.library.cancel")}}</a>
                    <v-button  class="btn btn-primary" :loading="loading" @click.native="save">
                        {{$t("Itinerary.builder.library.save")}}
                    </v-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {copyData, handleAjaxError, showCustomDialog, showError, showSuccess} from "../../utils";
    import vButton from '../../components/VButton';
    import TripActivity from '../itinerary/item/TripActivity';
    import TripCarRental from '../itinerary/item/TripCarRental';
    import TripClientInfo from '../itinerary/item/TripClientInfo';
    import TripCompanyInfo from '../itinerary/item/TripCompanyInfo';
    import TripDescription from '../itinerary/item/TripDescription';
    import TripDestinationInfo from '../itinerary/item/TripDestinationInfo';
    import TripGenericItem from '../itinerary/item/TripGenericItem';
    import TripHighlights from '../itinerary/item/TripHighlights';
    import TripMeal from '../itinerary/item/TripMeal';
    import TripPoi from '../itinerary/item/TripPoi';
    import TripSleep from '../itinerary/item/TripSleep';
    import TripTourName from '../itinerary/item/TripTourName';
    import TripTransportation from '../itinerary/item/TripTransportation';
    import TripPrice from '../itinerary/item/TripPrice';
    import TripWhatsIncluded from '../itinerary/item/TripWhatsIncluded';
    import TripNextSteps from '../itinerary/item/TripNextSteps';
    import TripCancelationPolicy from '../itinerary/item/TripCancelationPolicy';
    import TripKnowBeforeGo from '../itinerary/item/TripKnowBeforeGo';
    import {getItemNameOrTitle, ITEM_TYPE_CAR_RENTAL, ITEM_TYPE_SLEEP} from "../itinerary/itinerary-templates";
    import TripFlight from "../itinerary/item/TripFlight";

    export default {
        components: {
            TripFlight,
            vButton,
            TripActivity,
            TripCarRental,
            TripClientInfo,
            TripCompanyInfo,
            TripDescription,
            TripDestinationInfo,
            TripGenericItem,
            TripHighlights,
            TripMeal,
            TripPoi,
            TripSleep,
            TripTourName,
            TripTransportation,
            TripPrice,
            TripWhatsIncluded,
            TripNextSteps,
            TripCancelationPolicy,
            TripKnowBeforeGo,
        },
        /*props: {
            show: {
                type: Boolean,
                required: true,
            },
            item: {
                type: Object,
                required: true,
            },
        },*/
        data() {
            return {
                libraryId: null,
                libraryName: null,
                item: {
                    type: "",
                    tags: [],
                    data: {},
                    extraData: null,
                },

                loading: false,

                tagList: [],
            }
        },
        mounted() {
            this.libraryId = itemId;
            this.loadTags();
            this.loadDetail();
        },
        computed: {
            libraryMainUrl() {
                return jsRoutes.controllers.LibraryController.libraryList().url;
            },
            currency() {
                return this.item.extraData && this.item.extraData.currencyCode ? this.item.extraData.currencyCode : null;
            },
            tripType() {
                return this.item.extraData && this.item.extraData.tripType ? this.item.extraData.tripType : null;
            },
        },
        watch: {
            /*show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                    this.item.data = {
                        id: null,
                        name: null,
                        type: '',
                    };
                    this.loaditem.data();
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
            }*/
        },
        methods: {
/*
            getItemName() {
                let result = this.item.name;
                if (!result) {
                    result = getItemNameOrTitle(this.item.data);
                }
                return result;
            },
*/
            loadDetail() {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.loadItemDetails(this.libraryId).url;
                this.$http.get(url).then(function(response) {
                    self.item = response.data;
                    self.libraryName = self.item.name;
                    if (!self.libraryName) {
                        self.libraryName = getItemNameOrTitle(self.item.data)
                    }
                }, function(error) {
                    handleAjaxError(error);
                });
            },
            save() {
                this.doSave();
            },
            doSave() {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.saveLibraryItem().url;

                let dataObj = copyData(this.item.data);
                dataObj.libraryId = this.libraryId;
                dataObj.libraryName = this.libraryName;
                dataObj.tags = this.item.tags;
                dataObj.extraData = this.item.extraData;

                if (dataObj.type === ITEM_TYPE_SLEEP) {
                    dataObj.startDate = null;
                    dataObj.endDate = null;
                    dataObj.rooms = 1;
                    dataObj.roomTypes = [];
                } else if (dataObj.type === ITEM_TYPE_CAR_RENTAL) {
                    dataObj.startDate = null;
                    dataObj.endDate = null;
                    dataObj.referenceNumber = null;
                }


                this.loading = true;
                this.$http.post(url, dataObj)
                    .then(function (response) {
                        self.loading = false;
                        //console.log("roles -> " + JSON.stringify(data));
                        showSuccess(
                            self.$t("success.general_title"),
                            self.$t("Itinerary.builder.library.item_update")
                        );
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },
            loadTags() {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.tagListSelect().url;
                this.$http.get(url)
                    .then(function (response) {
                        self.tagList = response.data;
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            }
        }
    }
</script>

<style scoped>

</style>
