<template>
    <div>
        <div class="row mt-2">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <form @submit.prevent="loadList" novalidate>
                            <div class="row">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="sectionName" class="control-label" >
                                            {{$t("Itinerary.builder.library.name_address")}}
                                        </label>
                                        <input type="text" class="form-control" id="sectionName" name="sectionName" v-model.trim="filters.name">
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label class="control-label" >{{$t("Itinerary.builder.library.tags")}}:</label>
                                        <el-select
                                                style="width:100%;"
                                                v-model="filters.tags"
                                                multiple
                                                default-first-option
                                                filterable
                                                :placeholder='$t("Itinerary.builder.library.tag_select")'
                                        >
                                            <el-option v-for="tag in tagList"
                                                       :key="tag.id"
                                                       :label="tag.name"
                                                       :value="tag.id"
                                            ></el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="col-2" v-if="type === null">
                                    <div class="form-group">
                                        <label class="control-label" >{{$t("Itinerary.builder.library.type")}}</label>
                                        <select v-model="filters.type"
                                                class="form-control"
                                                placeholder="Item Type">
                                            <option v-for="type in itemTypes"
                                                       :key="type.value"
                                                       :value="type.value"
                                            >
                                                {{type.label}}
                                            </option>
                                        </select>
                                        <!--<el-select style="display:block;"
                                                v-model="filters.type"
                                                placeholder="Item Type">
                                            <el-option v-for="type in itemTypes"
                                                       :key="type.value"
                                                       :label="type.label"
                                                       :value="type.value"
                                            ></el-option>
                                        </el-select>-->
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="form-group">
                                        <label class="control-label" for="itemStatus">{{$t("Itinerary.builder.library.status")}}</label>
                                        <select name="itemStatus" id="itemStatus" v-model="filters.status" class="form-control">
                                            <option value="1">{{$t("Itinerary.builder.library.active")}}</option>
                                            <option value="0">{{$t("Itinerary.builder.library.inactive")}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <v-button type="submit"
                                              class="btn btn-primary"
                                              style="position:absolute;bottom:0;margin-bottom: 1rem;"
                                              :loading="loading"
                                    >
                                        <i class="fa fa-search"></i>
                                    </v-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <vue-bootstrap-table
                                :columns="cols"
                                :values="list"
                                :show-filter="true"
                                :show-column-picker="true"
                                :sortable="true"
                                :filter-case-sensitive="false"
                                :selectable="false"
                                defaultOrderColumn="name"
                                :defaultOrderDirection="true"
                        >
                            <template v-slot:name="slotProps">
                                <a :href="itemUrl(slotProps.value)">
                                    {{ getItemName(slotProps.value) }}
                                </a>
                            </template>
                            <template v-slot:address="slotProps">
                                {{slotProps.value.data.address}}
                            </template>
                            <template v-slot:oper="slotProps">
                                <button v-if="slotProps.value.active"
                                        class="btn btn-sm btn-danger"
                                        v-tooltip='$t("Itinerary.builder.library.message_deactivate_wont_be_availabel")'
                                        @click="deactivateItem(slotProps.value)"
                                >
                                    <i class="fa fa-trash"></i>
                                </button>
                            </template>

                        </vue-bootstrap-table>

                        <!--<div class="table-responsive">
                            <table class="table table-striped">

                                <thead class="thead-light">
                                <tr>
                                    <th>{{$t("Itinerary.builder.library.type")}}</th>
                                    <th>{{$t("Itinerary.builder.library.name")}}</th>
                                    <th>{{$t("Itinerary.builder.library.address")}}</th>
                                    <th>{{$t("Itinerary.builder.library.created_at")}}</th>
                                    <th></th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr v-bind:key="index" v-for="(item) in list">
                                    <td>{{ item.typeDesc }}</td>
                                    <td>
                                        <a :href="itemUrl(item)">
                                            {{ getItemName(item) }}
                                        </a>
                                    </td>
                                    <td>{{ item.data.address }}</td>
                                    <td>{{ item.createDate }}</td>
                                    <td>
                                        <button v-if="item.active"
                                                class="btn btn-sm btn-danger"
                                                v-tooltip='$t("Itinerary.builder.library.message_deactivate_wont_be_availabel")'
                                                @click="deactivateItem(item)"
                                        >
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
        <!--<library-item-modal :item="selectedItem" :show.sync="showItemModal"></library-item-modal>-->
    </div>
</template>

<script>
    import axios from 'axios'
    import {copyData, handleAjaxError, /*showCustomDialog,*/ showSuccess} from "../../utils";
    import vButton from '../../components/VButton';
    import VueBootstrapTable from "vue2-bootstrap-table2";
    import {
        getItemNameOrTitle,
        /*ITEM_NAME_ACTIVITY,
        ITEM_NAME_CANCELATION_POLICY,
        ITEM_NAME_CAR_RENTAL,
        ITEM_NAME_CLIENT_INFO,
        ITEM_NAME_COMPANY_INFO,
        ITEM_NAME_DESTINATION_INFO,
        ITEM_NAME_FLIGHT,
        ITEM_NAME_GENERIC,
        ITEM_NAME_MEAL,
        ITEM_NAME_NEXT_STEPS,
        ITEM_NAME_POI,
        ITEM_NAME_PRICE,
        ITEM_NAME_SLEEP,
        ITEM_NAME_TOUR_NAME,
        ITEM_NAME_TRANSPORTATION,
        ITEM_NAME_TRIP_DESCRIPTION,
        ITEM_NAME_TRIP_HIGHLIGHTS,*/
        ITEM_TYPE_ACTIVITY,
        ITEM_TYPE_CANCELATION_POLICY,
        ITEM_TYPE_CAR_RENTAL,
        //ITEM_TYPE_CLIENT_INFO,
        //ITEM_TYPE_COMPANY_INFO,
        ITEM_TYPE_DESTINATION_INFO,
        ITEM_TYPE_FLIGHT,
        ITEM_TYPE_GENERIC,
        ITEM_TYPE_MEAL,
        ITEM_TYPE_NEXT_STEPS,
        ITEM_TYPE_POI,
        ITEM_TYPE_PRICE,
        ITEM_TYPE_SLEEP,
        ITEM_TYPE_TOUR_NAME,
        ITEM_TYPE_TRANSPORTATION,
        ITEM_TYPE_TRIP_DESCRIPTION,
        ITEM_TYPE_TRIP_HIGHLIGHTS,
        SECTION_TYPE_DAY,
        SECTION_TYPE_OTHER,

    } from "../itinerary/itinerary-templates";

    export default {
        components: {
            vButton,
            VueBootstrapTable,
        },
        props: {
            type: {
                type: String,
                required: false,
                default: function() { return null }
            },
        },
        data() {
            return {
                filters: {
                    name: null,
                    tags: null,
                    type: null,
                    status: 1
                },

                loading: false,
                cols: [
                    {
                        title: this.$t("Itinerary.builder.library.type"),
                        name: "typeDesc"
                    },
                    {
                        title: this.$t("Itinerary.builder.library.name"),
                        name: "name"
                    },
                    {
                        title: this.$t("Itinerary.builder.library.address"),
                        name: "address"
                    },
                    {
                        title: this.$t("Itinerary.builder.library.created_at"),
                        name: "createDate"
                    },
                    {
                        title: this.$t("Itinerary.builder.library.actions"),
                        name: "oper",
                        filterable: false,
                        sortable: false,
                    },
                ],
                list: [],

                selectedItem: {},
                // showItemModal: false,

                tagList: [],

                itemTypes: [
                    {
                        value: null,
                        label: "All",
                    },
                    {
                        value: ITEM_TYPE_ACTIVITY,
                        label: this.$t("v2.Itinerary.defaults.ITEM_NAME_ACTIVITY"),
                    },
                    {
                        value: ITEM_TYPE_CANCELATION_POLICY,
                        label: this.$t("v2.Itinerary.defaults.ITEM_NAME_CANCELATION_POLICY"),
                    },
                    {
                        value: ITEM_TYPE_CAR_RENTAL,
                        label: this.$t("v2.Itinerary.defaults.ITEM_NAME_CAR_RENTAL"),
                    },
/*
                    {
                        value: ITEM_TYPE_CLIENT_INFO,
                        label: ITEM_NAME_CLIENT_INFO,
                    },
                    {
                        value: ITEM_TYPE_COMPANY_INFO,
                        label: ITEM_NAME_COMPANY_INFO,
                    },
*/
                    {
                        value: ITEM_TYPE_TRIP_DESCRIPTION,
                        label: this.$t("v2.Itinerary.defaults.ITEM_NAME_TRIP_DESCRIPTION"),
                    },
                    {
                        value: ITEM_TYPE_DESTINATION_INFO,
                        label: this.$t("v2.Itinerary.defaults.ITEM_NAME_DESTINATION_INFO"),
                    },
                    {
                        value: ITEM_TYPE_GENERIC,
                        label: this.$t("v2.Itinerary.defaults.ITEM_NAME_GENERIC"),
                    },
                    {
                        value: ITEM_TYPE_TRIP_HIGHLIGHTS,
                        label: this.$t("v2.Itinerary.defaults.ITEM_NAME_TRIP_HIGHLIGHTS"),
                    },
/*
                    {
                        value: ITEM_TYPE_MEAL,
                        label: ITEM_NAME_MEAL,
                    },
*/
                    {
                        value: ITEM_TYPE_NEXT_STEPS,
                        label: this.$t("v2.Itinerary.defaults.ITEM_NAME_NEXT_STEPS"),
                    },
                    {
                        value: ITEM_TYPE_POI,
                        label: this.$t("v2.Itinerary.defaults.ITEM_NAME_POI"),
                    },
                    {
                        value: ITEM_TYPE_PRICE,
                        label: this.$t("v2.Itinerary.defaults.ITEM_NAME_PRICE"),
                    },
/*
                    {
                        value: ITEM_TYPE_SLEEP,
                        label: ITEM_NAME_SLEEP,
                    },
*/
                    {
                        value: ITEM_TYPE_TOUR_NAME,
                        label: this.$t("v2.Itinerary.defaults.ITEM_NAME_TOUR_NAME"),
                    },
                    {
                        value: ITEM_TYPE_TRANSPORTATION,
                        label: this.$t("v2.Itinerary.defaults.ITEM_NAME_TRANSPORTATION"),
                    },
                    {
                        value: ITEM_TYPE_FLIGHT,
                        label: this.$t("v2.Itinerary.defaults.ITEM_NAME_FLIGHT"),
                    },
                ],
            }
        },
        mounted() {
            this.loadTags();
            this.loadList();
        },
        watch: {
            /*type() {
                this.loadList();
            }*/
        },
        computed: {
        },
        methods: {
            loadTags() {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.tagListSelect().url;
                this.$http.get(url)
                    .then(function (response) {
                        self.tagList = response.data;
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },
            loadList: function () {
                const self = this;
                this.loading = true;
                const url = jsRoutes.controllers.LibraryController.searchItems().url;

                const dataObj = copyData(this.filters);
                if (this.type && (this.type === ITEM_TYPE_SLEEP || this.type === ITEM_TYPE_MEAL)) {
                    dataObj.type = this.type;
                }
                if (this.type && (this.type === SECTION_TYPE_OTHER || this.type === SECTION_TYPE_DAY)) {
                    dataObj.type = SECTION_TYPE_DAY;
                }

                // GET request
                axios.post(url, dataObj).then(function (response) {
                    self.loading = false;
                    // set data
                    self.list = response.data;
                }).catch(function (error) {
                    self.loading = false;
                    // handle error
                    handleAjaxError(error);
                });
            },
            itemUrl(item) {
                return jsRoutes.controllers.LibraryController.detailItem(item.id).url
            },
            /*openItemModal(item) {
                this.selectedItem = item;
                this.showItemModal = true;
            },*/
            deactivateItem(item) {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.deactivateItem().url;

                let dataObj = {
                    id: item.id
                };
                this.$http.post(url, dataObj)
                    .then(function (response) {
                        self.loadList();
                        //self.list.splice(index, 1);
                        showSuccess(
                            self.$t("Itinerary.builder.library.item_deactivated")
                        );
                    })
                    .catch(function (error) {
                        // self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },
            getItemName(item) {
                let result = item.name;
                if (!result) {
                    result = getItemNameOrTitle(item.data);
                }
                return result;
            }
        }
    }
</script>

<style scoped>

</style>
