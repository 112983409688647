<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-primary"
                        @click="showAutocomplete = true"
                        v-tooltip='$t("Itinerary.general.autocomplete_information")'
                >
                    <i class="fa fa-play-circle"></i>
                </button>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
                <button class="btn btn-danger"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <div class="table-cell">
                        <span class="material-symbols-outlined">{{ icon }}</span>
                    </div>
                    <div class="table-cell" style="width:100%;padding-left:10px;">
                         <el-select :disabled="!editable"
                               v-model="item.placeType"
                               :placeholder='$t("Itinerary.TripSleep.tooltip.type")'
                               filterable
                               default-first-option
                               style="color:black;font-size: 1rem;"
                               @blur="changeType"
                               @change="changeType"
                        >
                            <el-option v-for="tp in typeOptions || []"
                                    :key="tp.value"
                                    :label="$t(`Itinerary.TripPlace.type.${tp.value}`)"
                                    :value="tp.value"
                            ></el-option>
                        </el-select>
                    </div>
                </h3>
            </div>
            <div class="col-12">
                <h3>        
                    <input :readonly="!editable"
                            type="text" class="name-input"
                            v-model="item.title"
                            :placeholder='$t("Itinerary.TripMeal.tooltip.title")'
                            @change="emitAutosave"
                            @blur="emitAutosave"
                            v-if="item.placeType === 'other'"
                    />
                    <input :readonly="!editable"
                            type="text" class="name-input"
                            v-model="item.name"
                            :placeholder='$t("Itinerary.TripMeal.tooltip.name")'
                            @change="emitAutosave"
                            @blur="emitAutosave"
                    />
                </h3>
            </div>
        </div>
         <div class="row">
            <div class="col-12">
                <div class="table-cell">
                  
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12" v-if="item.googleRating !== undefined && item.googleRating !== null">
                <div class="table-cell">
                    {{$t("Itinerary.TripMeal.tooltip.google_rating")}}:
                </div>
                <div class="table-cell">
                    <star-rating v-model="item.googleRating"
                                 :show-rating="true"
                                 :star-size="15"
                                 :read-only="true"
                                 :increment="0.1"
                    ></star-rating>
                </div>
            </div>
            <div class="col-12" style="padding-top: 5px;">
                <textarea-autosize
                        v-if="editable"
                        class="form-control body-font-size"
                        :placeholder='$t("Itinerary.TripMeal.tooltip.description")'
                        v-model="item.description"
                        :min-height="30"
                        :max-height="500"
                        @blur.native="emitAutosave"
                ></textarea-autosize>
                <span v-else v-html="cleanupString(item.description)"></span>

                <!--<textarea class="form-control" placeholder="Description" v-model="item.description"></textarea>-->
            </div>
            <div class="col-12" style="padding-top: 5px;">
                <div v-if="editable" class="table-cell" v-tooltip='$t("Itinerary.general.show_on_map")'>
                    <a href="#" @click.prevent="showMapModal = true"><i class="fa fa-globe"></i></a>
                </div>
                <div v-else class="table-cell">
                    <i class="fa fa-globe"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;"
                     v-tooltip='$t("Itinerary.TripMeal.tooltip.address")'>
                    <input :readonly="!editable"
                           class="bullet-item mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripMeal.tooltip.address")'
                           v-model="item.address"
                           @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell" v-if="editable">
                    <button @click.prevent="showMapModal = true"
                            v-tooltip='$t("Itinerary.general.show_on_map")'
                            class="btn btn-primary btn-sm"
                    >
                        <i class="fa fa-map"></i>
                    </button>
                </div>
                <!--<i class="fa fa-address-book"></i> {{item.address}}-->
            </div>
            <div class="col-12" v-tooltip='$t("Itinerary.TripMeal.tooltip.phone")'>
                <div class="table-cell">
                    <i class="fa fa-phone"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    <input :readonly="!editable"
                           class="bullet-item mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripMeal.tooltip.phone")'
                           v-model="item.phone"
                           @blur="emitAutosave"
                    />
                </div>
                <!--Phone: {{item.phone}}-->
            </div>
            <div class="col-12" style="padding-top: 5px;">
                <div class="table-cell" v-tooltip='$t("Itinerary.TripMeal.tooltip.open_web")'>
                    <a :href="item.website" target="_blank"><i class="fa fa-mail-bulk"></i></a>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;" v-tooltip='$t("Itinerary.TripMeal.tooltip.website")'>
                    <input :readonly="!editable"
                           class="bullet-item mor-editable-field"
                           type="url"
                           :placeholder='$t("Itinerary.TripMeal.tooltip.website")'
                           v-model="item.website"
                           @blur="emitAutosave"
                    />
                </div>
                <!--Website: {{item.website}}-->
            </div>
        </div>
        <!--<div class="row" style="margin-top: 10px;">-->
        <draggable class="row" style="margin-top: 10px;" v-model="item.images" :options="{animation:150, disabled: !editable}">
            <div class="col-lg-6 col-md-6 col-sm-12" style="margin-top:10px; margin-bottom: 10px;"
                 v-for="image in item.images"
                 :key="image.id">
                <button v-if="editable" class="btn btn-danger img-delete-btn d-print-none" @click="deleteImage(image)">
                    <i class="fa fa-trash"></i>
                </button>
                <img class="img-fluid w-100" :src="getImageUrl(image)">
                <div class="mor-image-caption">
                    <input :readonly="!editable"
                           type="text"
                           v-model="image.caption"
                           class="mor-image-caption-field"
                           placeholder='$t("Itinerary.general.image_caption")'
                           @blur="updateImageCaption(image)"
                           @keyup.enter="updateImageCaption(image)"
                    />
                </div>
            </div>
        </draggable>
        <div v-if="editable" class="row d-print-none">
            <div class="col-12">
                <button style="margin-top: 10px;" class="btn btn-primary float-right" @click="showImageUpload = true">
                    {{$t("Itinerary.general.add_images")}}
                </button>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;" v-if="false">
            <div class="col-12">
                <h4>{{$t("Itinerary.TripMeal.tooltip.alternatives")}}</h4>
            </div>
            <div class="col-12" v-if="item.alternatives && item.alternatives.length > 0">
                <div v-for="(alt, index) in item.alternatives"
                     :key="alt.id"
                     class="card mb-2"
                >
                    <div class="card-header d-flex justify-content-between">
                        <a href="#" @click.prevent="showAlternative(alt)">{{alt.name}}</a>
                    </div>
                    <div class="card-body" v-if="selectedAlt && selectedAlt.id === alt.id">
                        <div class="col-12">
                            <trip-meal-alternative
                                    :item.sync="selectedAlt"
                                    :editable="editable"
                                    :libmode="libmode"
                                    @delete-item="removeAlternative(index)"
                                    @set-main="setAlternativeMain(alt, index)"
                            >
                            </trip-meal-alternative>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-print-none" style="margin-top: 10px;" v-if="false">
            <div class="col-12">
                <button style="margin-top: 10px;"
                        class="btn btn-primary float-right"
                        @click="showAddAlternative = true">
                    {{$t("Itinerary.TripMeal.tooltip.all_alternatives")}}
                </button>
            </div>
        </div>
        <image-upload-modal
                :show.sync="showImageUpload"
                :images.sync="item.images"
                :item-tags="item.tags"
        >
        </image-upload-modal>
        <places-autocomplete-modal :show.sync="showAutocomplete" @update-data="updateItemData($event)"></places-autocomplete-modal>
        <item-map-modal :show.sync="showMapModal" :item="item" @update-location="updateItemAddress($event)"></item-map-modal>
        <meal-add-alternative-modal
                :show.sync="showAddAlternative"
                :subtype="item.subType"
                @add="addAlternative($event)">
        </meal-add-alternative-modal>
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
        >
        </save-to-library-modal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import ImageUploadModal from '../modal/ImageUploadModal';
    import PlacesAutocompleteModal from '../modal/PlacesAutocompleteModal';
    import ItemMapModal from '../modal/ItemMapModal';
    import {copyData, handleAjaxError, showCustomDialog, showSuccess} from "../../../utils";
    import {autosaveMixin, imageUrlMixin, itemCommonPropsMixin, moveItemMixin, stringUtilsMixin} from "./item-mixins";
    import MealAddAlternativeModal from "../modal/MealAddAlternativeModal";
    import TripMealAlternative from "./TripMealAlternative";
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";
    import { getPlaceTypeOptions } from './place-utils';

    export default {
        components: {
            TripMealAlternative,
            MealAddAlternativeModal,
            draggable,
            ImageUploadModal,
            PlacesAutocompleteModal,
            ItemMapModal,
            SaveToLibraryModal,
        },
        mixins: [
            imageUrlMixin,
            autosaveMixin,
            moveItemMixin,
            stringUtilsMixin,
            itemCommonPropsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showSetNameModal: false,
                showImageUpload: false,
                showAutocomplete: false,
                showMapModal: false,
                showAddAlternative: false,
                selectedAlt: null,
                hiddenType: '',
            }
        },
        computed: {
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library")
                    ;
            },
            typeOptions() {
                const items = getPlaceTypeOptions();
                return items;
            },
            icon() {
                let item = (this.typeOptions || []).find(n => n.value === this.item.placeType);
                return item ? item.icon : 'share_location';
            }
        },
        methods: {
            changeType() {
                if (this.item.title && this.item.placeType !== 'other') {
                    this.hiddenType = this.item.title;
                    this.item.title = '';
                }
                else if (this.item.placeType === 'other' && this.hiddenType) {
                    this.item.title = this.hiddenType;
                    this.hiddenType = '';
                }
            },
            updateItemData(data) {
                this.item.name = data.name;
                this.item.address = data.address;
                this.item.phone = data.phone;
                this.item.website = data.website;
                this.item.coordinates = data.coordinates;
                this.item.googleId = data.googleId;
                this.item.googlePlacesId = data.googlePlacesId;
                this.item.googleRating = data.googleRating;
                this.item.images = data.images;
                this.item.tags = data.tags;
                this.$emit("update:item", this.item);
                this.emitAutosave();
            },
            updateItemAddress(data) {
                this.item.address = data.address;
                this.item.coordinates.lat = data.lat;
                this.item.coordinates.lng = data.lng;
                this.$emit("update:item", this.item);
                this.emitAutosave();
            },
            addAlternative(item) {
                if (!this.item.alternatives) {
                    this.item.alternatives = [];
                }
                this.item.alternatives.push(item);
                this.emitAutosave();
            },
            removeAlternative(index) {
                this.item.alternatives.splice(index, 1);
                this.$emit("update:item", this.item);
                this.emitAutosave();
            },
            showAlternative(alt) {
                if (this.selectedAlt && this.selectedAlt.id === alt.id) {
                    this.selectedAlt = null;
                } else {
                    this.selectedAlt = alt;
                }
            },
            setAlternativeMain(alt, index) {
                const self = this;
                // cria copia do main anterior
                const mainCopy = copyData(this.item);

                // copia dados do seleccionado para o main
                const altCopy = copyData(alt);
                this.item.libraryId = altCopy.libraryId;
                this.item.id = altCopy.id;
                this.item.name = altCopy.name;
                this.item.description = altCopy.description;
                this.item.address = altCopy.address;
                this.item.coordinates = altCopy.coordinates;
                this.item.phone = altCopy.phone;
                this.item.website = altCopy.website;
                this.item.googleId = altCopy.googleId;
                this.item.googlePlacesId = altCopy.googlePlacesId;
                this.item.images = altCopy.images;

                // remove o seleccionado das alternativas
                this.item.alternatives.splice(index, 1);

                // passa main anterior para as alternativas
                mainCopy.alternatives = null;
                mainCopy.type = null;
                mainCopy.time = null;
                this.item.alternatives.unshift(mainCopy);

                this.emitAutosave();
            }
        }
    }
</script>

<style scoped>

</style>
