<template>
    <div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12">
                <h3>
                    {{item.packingListTitle}}
                </h3>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12">
                <div class="col-12">
                    <div v-for="(packItem, index) in item.packingList" v-if="stringNotEmpty(packItem)">
                        <div class="bullet-left table-cell">
                            <i class="fa fa-circle"></i>
                        </div>
                        <div class="bullet-right table-cell">
                            <input
                                    class="bullet-item mor-editable-field"
                                    :placeholder='$t("Itinerary.TripKnowBeforeGo.tooltip.pack_item")'
                                    :ref="'packinput' + index"
                                    v-model="item.packingList[index]"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12">
                <h3>
                    {{item.knowBeforeGoTitle}}
                </h3>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12">
                <div class="col-12">
                    <div v-for="(knowItem, index) in item.knowBeforeGo" v-if="stringNotEmpty(knowItem)">
                        <div class="bullet-left table-cell">
                            <i class="fa fa-circle"></i>
                        </div>
                        <div class="bullet-right table-cell">
                            <input
                                    class="bullet-item mor-editable-field"
                                    :placeholder='$t("Itinerary.TripKnowBeforeGo.tooltip.describe_item_here")'
                                    :ref="'knowinput' + index"
                                    v-model="item.knowBeforeGo[index]"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {stringUtilsMixin} from "../../item/item-mixins";

    export default {
        components: {
        },
        mixins: [
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                // showButtons: false,
            }
        },
        mounted() {
            // this.setupMap();
        },
        methods: {
        }

    }
</script>

<style scoped>
</style>
