<template>
    <div class="modal" id="builderAddDaySectionModal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t("Itinerary.builder.add_day_section")}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="list-group">
                                    <button v-for="day in dayList" :key="day.dayNum"
                                            type="button"
                                            class="list-group-item list-group-item-action"
                                            @click="$emit('add', day.dayNum)"
                                    >
                                        {{day.title}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-link" @click="close">{{$t("root.cancel")}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vButton from '../../../components/VButton';
    import {formatMoment, parseMoment} from "../../../date-utils";
    import {getSectionForDayNum} from "../itinerary-templates";
    import $ from 'jquery'

    export default {
        components: {
            vButton,
        },
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            itData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {

                loading: false,

                dayList: [],
            }
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function () {
                self.$emit('update:show', false);
            })
        },
        computed: {
        },
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                    this.createDayList();
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
            }
        },
        methods: {
            createDayList() {
                const self = this;
                this.dayList = [];

                const sectionList = this.itData.tripData.sections;
                let iterMoment = null;
                if (this.itData.startDate) {
                    iterMoment = parseMoment(this.itData.startDate, this.itData.dateFormat);
                }

                let dayNum = 1;
                while (dayNum <= this.itData.numDays) {
                    let section;
                    if (sectionList) {
                        section = getSectionForDayNum(sectionList, dayNum)
                    }
                    if (!section) {
                        self.dayList.push({
                            dayNum: dayNum,
                            title: this.$t("v2.Itinerary.defaults.DAY") + " " + dayNum + (iterMoment ? ", " + formatMoment(iterMoment, self.itData.dateFormat) : "")
                        });
                    }
                    dayNum++;
                    if (iterMoment) {
                        iterMoment.add(1, 'days');
                    }
                }
            },
            close() {
                this.$emit('update:show', false);
            },
        }
    }
</script>

<style scoped>
</style>
