<template>
    <div class="modal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t("Itinerary.builder.gpx.set_day")}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="control-label mr-2">{{$t("Itinerary.builder.gpx.day_form")}} *:</label>
                        <select class="form-control" v-model="day">
                            <option v-for="day in dayList" :value="day.value" :key="day.value">{{day.label}}</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-link" @click="close">{{$t("root.cancel")}}</button>
                    <v-button class="btn btn-primary ml-2"
                              @click.native="saveData"
                              :loading="loading"
                    >
                        {{$t("root.save")}}
                    </v-button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {getDayNumForSection, getDaySections} from "../itinerary-templates";
    import {handleAjaxError, showSuccess} from "../../../utils";
    import vButton from '../../../components/VButton';
    import $ from 'jquery'

    export default {
        components: {
            vButton
        },
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            routes: {
                type: Array,
                required: false,
            },
            points: {
                type: Array,
                required: false,
            },
        },
        data() {
            return {
                day: "NONE",
                loading: false,
            }
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function () {
                self.$emit('update:show', false);
            });
        },
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                    this.day = "NONE";
                    if (this.routes && this.routes.length > 0) {
                        if (this.routes.length === 1) {
                            const route = this.routes[0];
                            if (route.allDays) {
                                this.day = "ALL";
                            } else if (route.day !== null) {
                                this.day = route.day;
                            }
                        }
                    } else if (this.points && this.points.length > 0) {
                        if (this.points.length === 1) {
                            const point = this.points[0];
                            if (point.allDays) {
                                this.day = "ALL";
                            } else if (point.day !== null) {
                                this.day = point.day;
                            }
                        }
                    }
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
            },
        },
        computed: {
            itData() {
                return this.$store.getters.itData;
            },
            dayList() {
                const self = this;
                let dayList = [
                    {label: this.$t("Itinerary.builder.gpx.none_label"), value: "NONE"},
                    {label: this.$t("Itinerary.builder.gpx.all_label"), value: "ALL"}
                ];
                if (this.show && this.itData.tripData && this.itData.tripData.sections) {
                    const daySections = getDaySections(this.itData.tripData.sections);
                    daySections.forEach(section => {
                        const day = getDayNumForSection(section, self.itData);
                        dayList.push({label: day, value: day});
                    });
                }
                return dayList;
            }
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            saveData() {
                const self = this;
                const dataObj = {
                    day: this.day === 'ALL' || this.day === 'NONE' ? null : this.day,
                    allDays: this.day === 'ALL',
                    routes: this.routes && this.routes.length > 0 ? this.routes.map(route => {return route.id}) : [],
                    waypoints: this.points && this.points.length > 0 ? this.points.map(point => {return point.id}) : [],
                };
                this.loading = true;

                // eslint-disable-next-line
                let url = jsRoutes.controllers.ItineraryController.updateGpxDataDay(dataObj).url;
                this.$http.post(url, dataObj).then(/*response*/() => {
                    self.loading = false;
                    showSuccess(
                        self.$t("success.general_title"),
                        self.$t("root.saved"),
                    );
                    self.close();
                    self.$emit("reload");
                }, error => {
                    self.loading = false;
                    handleAjaxError(error)
                })

            }
        },
    }
</script>

<style scoped>
</style>
