<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3 class="text-center">
                    <input type="text" class="name-input" v-model="item.title"/>
                </h3>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;" v-if="stringNotEmpty(item.description)">
            <div class="col-12" v-html="cleanupString(item.description)">
                <!--<textarea-autosize
                        style="border: 0; padding: 0;color:black;"
                        class="form-control font-14"
                        placeholder="Describe points of interest"
                        v-model="item.description"
                        :min-height="30"
                        :max-height="500"
                ></textarea-autosize>-->
            </div>
        </div>
        <div class="row" style="margin-top: 10px;" v-if="stringNotEmpty(item.descriptionPlace)">
            <div class="col-12 pl-5" style="color:grey;font-style: italic;" v-html="cleanupString(item.descriptionPlace)">
                <!--<textarea-autosize
                        style="border: 0; padding: 0;color:gray;"
                        class="form-control font-14"
                        placeholder="Describe points of interest"
                        v-model="item.descriptionPlace"
                        :min-height="30"
                        :max-height="500"
                ></textarea-autosize>-->
            </div>
        </div>
        <div class="row photos-row" v-if="item.images.length > 1">
            <div class="col-6 photos-col"
                 v-for="image in item.images.slice(0, 2)"
                 :key="image.id">
                <img class="img-fluid w-100" :src="getImageUrl(image)">
                <div class="mor-image-caption" v-if="stringNotEmpty(image.caption)">
                    <input type="text"
                           v-model="image.caption"
                           class="mor-image-caption-field"
                    />
                </div>
            </div>
        </div>
        <div class="row photos-row justify-content-center" v-if="item.images.length === 1">
            <div class="col-6 photos-col">
                <img class="img-fluid w-100" :src="getImageUrl(item.images[0])">
                <div class="mor-image-caption" v-if="stringNotEmpty(item.images[0].caption)">
                    <input type="text"
                           v-model="item.images[0].caption"
                           class="mor-image-caption-field"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {imageUrlMixin, stringUtilsMixin} from "../../item/item-mixins";

    export default {
        components: {
        },
        mixins: [
            imageUrlMixin,
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showImageUpload: false,
            }
        },
        watch: {
        }
    }
</script>

<style scoped>
</style>
