<style>

    /*.topstat-background {
        background-color: #f8f9fa;
    }*/

    .positive {
        /*color: #165665;*/
        color: green;
    }

    .negative {
        color: #e64d42;
    }

    .stat-number {
        font-size: 38px;
        font-weight: 700;
        line-height: 1.65857;
    }
</style>
<template>
    <div class="main-container">
        <div class="container-fluid">

            <!-- BILLING -->
												<!--
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
                    <br/>

                    <div v-show="subscriptionData.status === 'STRIPE_PROBLEM'" class="row">
                        <div class="col-sm-12 col-md-12 mx-auto">
                            <div class="alert alert-warning" role="alert">
                                {{$t("SubscriptionManager.warning.stripeproblem")}}
                                {{subscriptionData.stripeProblemDescription}}
                            </div>
                        </div>
                    </div>
                    <div v-show="subscriptionData.status === 'HAS_SUBSCRIPTION_IS_NOT_ACTIVE'" class="row">
                        <div class="col-sm-12 col-md-12 mx-auto">
                            <div class="alert alert-warning" role="alert">
                                {{$t("SubscriptionManager.warning.subscription_not_active")}}
                            
																												</div>
                        </div>
                    </div>
                    <div v-show="subscriptionData.status === 'HAD_SUBSCRIPTION_STILL_ENOUGHT_TIME'" class="row">
                        <div class="col-sm-12 col-md-12 mx-auto">
                            <div class="alert alert-warning" role="alert">
                                {{$t("SubscriptionManager.warning.canceled_but_still_time")}}
                                {{subscriptionData.endOfSubscriptionString}}
                            </div>
                        </div>
                    </div>
                    <div v-show="subscriptionData.status === 'HAD_SUBSCRIPTION_BLOCKED'" class="row">
                        <div class="col-sm-12 col-md-12 mx-auto">
                            <div class="alert alert-warning" role="alert">
                                {{$t("SubscriptionManager.warning.account_locked_nosubs")}}
                            </div>
                        </div>
                    </div>
                    <div v-show="subscriptionData.status === 'RUNNING_TRIAL'" class="row">
                        <div class="col-sm-12 col-md-12 mx-auto">
                            <div class="alert alert-warning" role="alert">
                                {{$t("SubscriptionManager.warning.trial_ongoing")}}
                                
																																{{subscriptionData.endOfSubscriptionString}}
                            </div>
                        </div>
                    </div>
                    <div v-show="subscriptionData.status === 'TRIAL_FINISHED_BLOCKED'" class="row">
                        <div class="col-sm-12 col-md-12 mx-auto">
                            <div class="alert alert-warning" role="alert">
                                {{$t("SubscriptionManager.warning.trial_finished")}}
                            </div>
                        </div>
                    </div>

                    <div v-if="processed && subscriptionData.status !== 'HAS_SUBSCRIPTION_IS_ACTIVE' && subscriptionData.isAdmin"
                         class="row mb-2">
                        <div class="col-sm-12 col-md-12 mx-auto">
                            <a :href="subscriptionUrl"
                               class="btn btn-primary">
                                {{$t("root.manage_your_subscriptions")}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
												-->

            <!-- START ONBOARDING SECTION -->
            <div v-if="isOnboarding" class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
                    <div class="card topstat-background">
                        <div class="card-body">
                            <h5 class="card-title"><i class="fa fa-lg fa-check-double"></i>&nbsp;{{$t("onboarding.welcome")}}</h5>
                            <h6 class="card-subtitle mb-2 text-muted">{{$t("onboarding.subtitle")}}</h6>
                            <br/>
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <ul class="list-group list-group-flush">
                                        <a v-if="onboardingData.type === 'admin'"
                                           :href="agencyConfigUrl"
                                           class="list-group-item">
                                            <i v-if="onboardingData.onboardingConfigTravel" class="fa fa-lg fa-check positive"></i>
                                            <i v-else class="fa fa-lg fa-times negative"></i>
                                            &nbsp;
                                            {{$t("onboarding.config_travel_agency")}}
                                        </a>
                                        <a :href="userProfileUrl"
                                           class="list-group-item">
                                            <i v-if="onboardingData.onboardingConfigProfile" class="fa fa-lg fa-check positive"></i>
                                            <i v-else class="fa fa-lg fa-times negative"></i>
                                            &nbsp;
                                            {{$t("onboarding.config_profile")}}
                                        </a>
                                        <a v-if="onboardingData.type === 'admin'"
                                           :href="usersUrl"
                                           class="list-group-item">
                                            <i v-if="onboardingData.onboardingCreateUsers" class="fa fa-lg fa-check positive"></i>
                                            <i v-else class="fa fa-lg fa-times negative"></i>
                                            &nbsp;
                                            {{$t("onboarding.create_users")}}
                                        </a>
																																								<!--
                                        <a v-if="onboardingData.type === 'admin'"
                                           :href="mobileUrl"
                                           class="list-group-item">
                                            <i v-if="onboardingData.onboardingConfigMobile" class="fa fa-lg fa-check positive"></i>
                                            <i v-else class="fa fa-lg fa-times negative"></i>
                                            &nbsp;
                                            {{$t("onboarding.config_mobile_app")}}
                                        </a>
																																								-->
                                        <a :href="clientsUrl"
                                           class="list-group-item">
                                            <i v-if="onboardingData.onboardingCreateClient" class="fa fa-lg fa-check positive"></i>
                                            <i v-else class="fa fa-lg fa-times negative"></i>
                                            &nbsp;
                                            {{$t("onboarding.create_a_client")}}
                                        </a>
                                        <a :href="itinListUrl"
                                           class="list-group-item">
                                            <i v-if="onboardingData.onboardingCreateItinerary" class="fa fa-lg fa-check positive"></i>
                                            <i v-else class="fa fa-lg fa-times negative"></i>
                                            &nbsp;
                                            {{$t("onboarding.create_itinerary")}}
                                        </a>
                                    </ul>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">

                                </div>
                            </div>
                            <br/>
                            <p class="card-subtitle mb-2 text-muted"></p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END ONBOARDING SECTION -->

            <br/>
            <!-- STARTING DASHBOARD BAR-->
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div class="card topstat-background">
                        <div class="card-body">
                            <h5 class="card-title">{{$t("dashboard.stats.itineraries_created")}}</h5>
                            <h6 class="card-subtitle mb-2 text-muted">{{$t("dashboard.stats.total_for_this_month")}}</h6>
                            <div class="card-text stat-number"
                                 :class="{ 'positive' : statistics.itiCreatedThisMonth > statistics.itiCreatedLastMonth , 'negative' : statistics.itiCreatedThisMonth < statistics.itiCreatedLastMonth } "
                            >
                                    {{statistics.itiCreatedThisMonth}}
                                <i v-if="statistics.itiCreatedThisMonth > statistics.itiCreatedLastMonth " class="fa fa-caret-up"></i>
                                <i v-if="statistics.itiCreatedThisMonth < statistics.itiCreatedLastMonth "class="fa fa-caret-down"></i>
                            </div>
                            <div class="card-subtitle mb-2 text-muted">{{$t("dashboard.stats.last_month")}}: {{statistics.itiCreatedLastMonth}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div class="card topstat-background">
                        <div class="card-body">
                            <h5 class="card-title">{{$t("dashboard.stats.itineraries_sold")}}</h5>
                            <h6 class="card-subtitle mb-2 text-muted">{{$t("dashboard.stats.total_for_this_month")}}</h6>
                            <div class="card-text stat-number"
                                 :class="{ 'positive' : statistics.itiSoldThisMonth > statistics.itiSoldLastMonth , 'negative' : statistics.itiSoldThisMonth < statistics.itiSoldLastMonth } "
                            >
                                {{statistics.itiSoldThisMonth}}
                                <i v-if="statistics.itiSoldThisMonth > statistics.itiSoldLastMonth " class="fa fa-caret-up"></i>
                                <i v-if="statistics.itiSoldThisMonth < statistics.itiSoldLastMonth " class="fa fa-caret-down"></i>
                            </div>
                            <p class="card-subtitle mb-2 text-muted">{{$t("dashboard.stats.last_month")}}: {{statistics.itiSoldLastMonth}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div class="card topstat-background">
                        <div class="card-body">
                            <h5 class="card-title">{{$t("dashboard.stats.new_clients")}}</h5>
                            <h6 class="card-subtitle mb-2 text-muted">{{$t("dashboard.stats.total_for_this_month")}}</h6>
                            <div class="card-text stat-number"
                                 :class="{ 'positive' : statistics.newClientThisMonth > statistics.newClientLastMonth , 'negative' : statistics.newClientThisMonth < statistics.newClientLastMonth } "
                            >
                                {{statistics.newClientThisMonth}}
                                <i v-if="statistics.newClientThisMonth > statistics.newClientLastMonth " class="fa fa-caret-up"></i>
                                <i v-if="statistics.newClientThisMonth < statistics.newClientLastMonth " class="fa fa-caret-down"></i>
                            </div>
                            <p class="card-subtitle mb-2 text-muted">{{$t("dashboard.stats.last_month")}}: {{statistics.newClientLastMonth}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div class="card topstat-background">
                        <div class="card-body">
                            <h5 class="card-title">{{$t("dashboard.stats.library_growth")}}</h5>
                            <h6 class="card-subtitle mb-2 text-muted">{{$t("dashboard.stats.total_for_this_month")}}</h6>
                            <div class="card-text stat-number"
                                 :class="{ 'positive' : statistics.newLibraryItemsThisMonth > statistics.newLibraryItemsLastMonth , 'negative' : statistics.newLibraryItemsThisMonth < statistics.newLibraryItemsLastMonth } "
                            >
                                {{statistics.newLibraryItemsThisMonth}}
                                <i v-if="statistics.newLibraryItemsThisMonth > statistics.newLibraryItemsLastMonth " class="fa fa-caret-up"></i>
                                <i v-if="statistics.newLibraryItemsThisMonth < statistics.newLibraryItemsLastMonth " class="fa fa-caret-down"></i>
                            </div>
                            <p class="card-subtitle mb-2 text-muted">{{$t("dashboard.stats.last_month")}}: {{statistics.newLibraryItemsLastMonth}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END DASHBOARD BAR-->

            <br/>

            <!-- STARTING ITINERARY LISTINGS -->
            <div class="row">
                <div class="col-sm-12 col-md-6 mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">{{$t("dashboard.listings.last_modified_its")}}</h5>
                            <h6 class="card-subtitle mb-2 text-muted">{{$t("dashboard.listings.last_modified_its_desc")}} </h6>
                            <p class="card-text">&nbsp;</p>
                            <ul class="list-group list-group-flush">
                                <a
                                        v-for="item in lastModifiedItineraries"
                                        :href="itinUrl(item.uid)"
                                        class="list-group-item list-group-item-action">
                                    {{item.name}} <span
                                        class="badge badge-primary float-right">{{item.updateDate}}</span>
                                </a>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-sm-12 col-md-6 mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">{{$t("dashboard.listings.about_to_start_its")}}</h5>
                            <h6 class="card-subtitle mb-2 text-muted">{{$t("dashboard.listings.about_to_start_its_desc")}}</h6>
                            <p class="card-text">&nbsp;</p>
                            <ul class="list-group list-group-flush">
                                <a
                                        v-for="item in aboutToStartItineraries"
                                        :href="itinUrl(item.uid)"
                                        class="list-group-item list-group-item-action">
                                    {{item.name}} <span
                                        class="badge badge-primary float-right">{{item.updateDate}}</span>
                                </a>
                            </ul>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- END ITINERARY LISTINGS -->
        </div>
    </div>
</template>

<script>

    var stripeHandler ;

    export default {
        name: "MainTemplate",
        components: {
        },
        // props: ["uuid"],
        data() {
            return {
                processed: false,
                subscriptionData: {},
                lastModifiedItineraries: [],
                // aboutToStartItineraries: [],
                statistics: {},
                onboardingData : {}
            }
        },
        mounted() {
            this.loadSubscriptionData();
            this.loadLastModifiedItineraries();
            // this.loadItinerariesAboutToStart();
            this.loadStatistics();
            this.loadOnboardingData();
            //this.initStripe();
        },
        computed: {
            isOnboarding () {
                if ( typeof this.onboardingData.isOnboarding !== 'undefined') {
                    return this.onboardingData.isOnboarding;
                }
                return false
            },
            agencyConfigUrl() {
                return jsRoutes.controllers.AdministrationController.agencyConfiguration().url;
            },
            userProfileUrl() {
                return jsRoutes.controllers.Account.userProfile().url;
            },
            usersUrl() {
                return jsRoutes.controllers.AdministrationController.users().url;
            },
            mobileUrl() {
                return jsRoutes.controllers.AdministrationController.mobileAppConfiguration().url;
            },
            clientsUrl() {
                return jsRoutes.controllers.ClientController.clients().url;
            },
            itinListUrl() {
                return jsRoutes.controllers.ItineraryController.itineraries().url;
            },
            subscriptionUrl() {
                return jsRoutes.controllers.SubscriptionController.subscriptionManager().url;
            },
        },
        methods: {
            itinUrl(uid) {
                return jsRoutes.controllers.ItineraryController.detailItinerary(uid).url;
            },
            loadSubscriptionData() {
                const self = this;
                jsRoutes.controllers.SubscriptionController.getSubscriptionStatus()
                    .ajax({
                        success: function (data) {
                            self.subscriptionData = data;
                            self.processed = true;
                        },
                        error: function (error) {
                            // TODO , do something with error ?  Maybe blank the Page !
                        }
                    });
            },
            loadLastModifiedItineraries () {
                const self = this;
                jsRoutes.controllers.Account.getLastModifiedItineraryList()
                    .ajax({
                        success: function (data) {
                            self.lastModifiedItineraries = data;
                        },
                        error: function (error) {
                            // TODO , do something with error ?  Maybe blank the Page !
                        }
                    });
            },
            // loadItinerariesAboutToStart () {
            //     const self = this;
            //     jsRoutes.controllers.Account.getItinerariesAboutToStartList()
            //         .ajax({
            //             success: function (data) {
            //                 self.aboutToStartItineraries = data;
            //             },
            //             error: function (error) {
            //                 // TODO , do something with error ?  Maybe blank the Page !
            //             }
            //         });
            // },
            loadStatistics () {
                const self = this;
                jsRoutes.controllers.Account.getStatisticsMonthly()
                    .ajax({
                        success: function (data) {
                            self.statistics = data;
                        },
                        error: function (error) {
                            // TODO , do something with error ?  Maybe blank the Page !
                        }
                    });
            },
            loadOnboardingData () {
                const self = this;
                jsRoutes.controllers.Account.getOnboardingData()
                    .ajax({
                        success: function (data) {
                            self.onboardingData = data;
                        },
                        error: function (error) {
                            // TODO , do something with error ?  Maybe blank the Page !
                        }
                    });
            },
            /*initStripe() {
                stripeHandler = StripeCheckout.configure({
                  key: 'pk_test_nEb5G6G9WrGS79p8MfZaT55X',
                  image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
                  locale: 'auto',
                  token: function(token) {
                    console.log("TOKEN ID DUDE !!! : " + token.id);
                  }
                })
            },
            openCardForm() {
                stripeHandler.open({
                    name: 'Demo Site',
                    description: '2 widgets',
                    currency: 'eur',
                    amount: 2000
                  });
            }*/
        }
    }
</script>

<style scoped>

</style>
