<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft">
                    <button class="btn btn-primary dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                            :key="section.id"
                            class="dropdown-item"
                            :class="{'disabled': section.id === currentSectionId}"
                            @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-danger"
                    @click="$emit('delete-item', item)"
                    v-tooltip='$t("Itinerary.general.remove_item")'
                >
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>

        <h3>{{ $t('v2.Itinerary.defaults.ITEM_NAME_AUDIO') }}</h3>
        <div class="row">
            <div class="col">
                <el-input
                    :placeholder="$t('Itinerary.TripAudio.title')"
                    :readonly="!editable"
                    v-model="item.title"
                    @blur="emitAutosave"
                />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <span v-if="(typeof item.fileId === 'number')">
                    {{ item.filename }}
                </span>
                <template v-else>
                    <input
                        :disabled="!editable"
                        ref="fileInput"
                        type="file"
                        accept="audio/*"
                    />
                    <button
                        class="btn btn-primary"
                        :disabled="!editable"
                        @click="upload"
                    >
                        {{ $t('Itinerary.TripAudio.upload') }}
                    </button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { showSuccess } from "../../../utils";
import {imageUrlMixin, autosaveMixin, moveItemMixin, itemCommonPropsMixin, stringUtilsMixin} from "./item-mixins";

export default {
    mixins: [
        autosaveMixin,
        imageUrlMixin,
        moveItemMixin,
        itemCommonPropsMixin,
        stringUtilsMixin,
    ],
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    methods: {
        upload() {
            const file = this.$refs.fileInput.files[0];

            if (file) {
                const form = new FormData();
                form.append('file', file);

                const url = jsRoutes.controllers.ItineraryController.uploadItineraryAudio(itinId).url;

                this.$http.post(url, form).then(response => {
                    showSuccess(this.$t('Itinerary.TripAudio.uploadSuccess'));
                    this.item.fileId = response.data.id;
                    this.item.filename = response.data.filename;
                });
            }
        }
    }
}
</script>

<style scoped>
.row + .row {
    margin-top: 1rem;
}
</style>
