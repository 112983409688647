<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3>
                    {{$t("Itinerary.TripCompanyInfo.tooltip.organizer_details")}}
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <img class="img-fluid w-100" :src="agencyImageUrl"/>
            </div>
            <div class="col-8">
                <div>
                    <div class="table-cell icon-left">
                        <i class="fa fa-building"></i>
                    </div>
                    <div class="table-cell pl-1">
                        {{agencyData.name}}
                    </div>
                </div>
                <div v-if="agencyData.website !== null && agencyData.website.length > 0">
                    <div class="table-cell icon-left">
                        <a :href="agencyData.website" target="_blank">
                            <i class="fa fa-globe"></i>
                        </a>
                    </div>
                    <div class="table-cell pl-1">
                        <a :href="agencyData.website" target="_blank">
                            {{agencyData.website}}
                        </a>
                    </div>
                </div>
                <div v-if="agencyData.phone !== null && agencyData.phone.length > 0">
                    <div class="table-cell icon-left">
                        <i class="fa fa-phone"></i>
                    </div>
                    <div class="table-cell pl-1">
                        {{agencyData.phone}}
                    </div>
                </div>
                <div>
                    <div class="table-cell icon-left">
                        <i class="fa fa-envelope"></i>
                    </div>
                    <div class="table-cell pl-1">
                        {{agencyFullAddress}}
                    </div>
                </div>
                <div v-if="agencyData.reg !== null && agencyData.reg.length > 0">
                    <div class="table-cell icon-left" style="font-size: 1.5rem;font-weight: bold;">
                        #
                    </div>
                    <div class="table-cell pl-1">
                        {{agencyData.reg}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-12">
                <h3>{{item.tripManagerTitle}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <img style="width:200px;height:200px;border-radius:50%;" :src="managerImageUrl"/>
            </div>
            <div class="col-8">
                <div v-if="itData.managerId">
                    <div class="table-cell icon-left">
                        <i class="fa fa-user"></i>
                    </div>
                    <div class="table-cell pl-1">
                        {{itData.managerName}}
                    </div>
                </div>
                <div v-if="itData.managerId">
                    <div class="table-cell icon-left">
                        <i class="fa fa-envelope"></i>
                    </div>
                    <div class="table-cell pl-1">
                        {{itData.managerEmail}}
                    </div>
                </div>
                <div v-if="itData.managerId">
                    <div class="table-cell icon-left">
                        <i class="fa fa-phone"></i>
                    </div>
                    <div class="table-cell pl-1">
                        {{itData.managerPhone}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;" v-if="stringNotEmpty(item.localTripManagerName) && itData.status === 'SOLD'">
            <div class="col-12">
                <h3>
                    {{item.localTripManagerTitle}}
                </h3>
            </div>
            <div class="col-4">
                <img style="width:200px;height:200px;border-radius:50%;" src="/assets/images/male-icon.jpg"/>
            </div>
            <div class="col-8">
                <div class="table-cell icon-left">
                    <i class="fa fa-user"></i>
                </div>
                <div class="table-cell pl-1">
                    {{item.localTripManagerName}}
                </div>
                <div>
                    <div class="table-cell icon-left">
                        <i class="fa fa-envelope"></i>
                    </div>
                    <div class="table-cell pl-1">
                        {{item.localTripManagerEmail}}
                    </div>
                </div>
                <div>
                    <div class="table-cell icon-left">
                        <i class="fa fa-phone"></i>
                    </div>
                    <div class="table-cell pl-1">
                        {{item.localTripManagerPhone}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;" v-if="hasEmergencyContacts && itData.status === 'SOLD'">
            <div class="col-12">
                <h3>
                    {{item.emergencyContactsTitle}}
                </h3>
            </div>
            <div class="col-12">
                <div v-for="(contact, index) in item.emergencyContacts"
                     class="d-flex justify-content-start align-items-center page-break-inside-avoid">
                    <div class="bullet-left">
                        <i class="fa fa-circle"></i>
                    </div>
                    <div style="padding-left: 5px;min-width:250px;width:250px;">
                        <input
                                class="bullet-item mor-editable-field"
                                :placeholder='$t("Itinerary.TripCompanyInfo.tooltip.name")'
                                :ref="'name' + index"
                                v-model="item.emergencyContacts[index].name"
                        />
                    </div>
                    <div style="padding-left: 5px;min-width:200px;width:200px;">
                        <input
                                class="bullet-item mor-editable-field"
                                :placeholder='$t("Itinerary.TripCompanyInfo.tooltip.phone")'
                                :ref="'phone' + index"
                                v-model="item.emergencyContacts[index].phone"
                        />
                    </div>
                    <div style="padding-left: 5px;width:100%;">
                        <input
                                class="bullet-item mor-editable-field"
                                :placeholder='$t("Itinerary.TripCompanyInfo.tooltip.description")'
                                :ref="'desc' + index"
                                v-model="item.emergencyContacts[index].description"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {showError, stringNotEmpty} from "../../../../utils";
    import {stringUtilsMixin} from "../../item/item-mixins";

    export default {
        components: {
        },
        mixins: [
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            itData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                agencyData: {
                    name: null,
                    email: null,
                    website: null,
                    phone: null,
                    address: null,
                    city: null,
                    postalCode: null,
                    country: null,
                    reg: null,
                    vat: null,
                    imageFilename: null,
                    imageFile: null,
                    removeImage: false,
                    isUserAdmin: false,
                    configured: false,
                },

                // managerList: [],

            }
        },
        mounted() {
            this.loadAgencyData();
            // this.loadManagerList();
        },
        watch: {
        },
        computed: {
            agencyImageUrl() {
                if (this.agencyData.imageFilename !== null) {
                    return jsRoutes.controllers.Application.agencyImage(this.agencyData.imageFilename).url
                } else {
                    return null;
                }
            },
            managerImageUrl() {
                if (this.itData.managerId !== null && this.itData.managerHasPhoto) {
                    return jsRoutes.controllers.Application.managerImage(this.itData.managerId).url
                }
                return "/assets/images/male-icon.jpg";
            },
            agencyFullAddress() {
                return this.agencyData.address + " - " + this.agencyData.postalCode + ", " + this.agencyData.country;
            },
            hasEmergencyContacts() {
                if (this.item.emergencyContacts.length === 0) {
                    return false;
                } else {
                    let result = false;
                    this.item.emergencyContacts.some(contact => {
                        if (stringNotEmpty(contact.name)) {
                            result = true;
                            return true;
                        }
                    });
                    return result;
                }
            }
        },
        methods: {
            loadAgencyData() {
                const self = this;
                const url = jsRoutes.controllers.ItineraryController.loadAgencyConfiguration(this.itData.id).url;

                this.$http.get(url).then(function (response) {
                    //console.log("roles -> " + JSON.stringify(data));
                    self.agencyData = response.data;

                }).catch(function (response) {
                    // handle error
                    showError("Error", "Failed loading company info");
                });
            },
        }
    }
</script>

<style scoped>
    .icon-left {
        width: 25px;
        text-align: center;
    }
</style>
