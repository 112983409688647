<template>
    <div class="card mt-3">
        <div class="card-header" v-if="!itData.readOnly">
									<p>{{$t("Itinerary.builder.gpx.tab_help")}}</p>
            <button class="btn btn-primary" @click="showUploadModal = true"><span class="fa fa-plus-circle"></span>&nbsp;
                {{$t("Itinerary.builder.gpx.upload_file")}}
            </button>
        </div>
        <div class="list-group">
            <div class="list-group-item">
                <h4>{{$t("Itinerary.builder.gpx.routes_heading")}}</h4>
                <vue-bootstrap-table
                        :columns="routeColumns"
                        :values="routeList"
                        :show-filter="true"
                        :show-column-picker="true"
                        :sortable="true"
                        :filter-case-sensitive="false"
                        :selectable="!itData.readOnly"
                        default-order-column="name"
                        :default-order-direction="true"
                        :paginated="true"
                        :page-size="20"
                >
                    <template v-slot:name="slotProps">
                        <a href="#" @click.stop.prevent="openRouteMap(slotProps.value)">{{slotProps.value.name}}</a>
                    </template>
                    <template v-slot:day="slotProps">
                        {{getRouteDay(slotProps.value)}}
                    </template>
                </vue-bootstrap-table>
                <div v-if="!itData.readOnly" class="d-flex justify-content-end">
                    <button class="btn btn-primary" @click="openRouteDayModal">{{$t("Itinerary.builder.gpx.set_day")}}</button>
                    <button class="btn btn-danger ml-2" @click="deleteRoutes">{{$t("Itinerary.builder.gpx.delete")}}</button>
                </div>
            </div>
            <div class="list-group-item">
                <h4>{{$t("Itinerary.builder.gpx.waypoints_heading")}}</h4>
                <vue-bootstrap-table
                        :columns="waypointColumns"
                        :values="waypointList"
                        :show-filter="true"
                        :show-column-picker="true"
                        :sortable="true"
                        :filter-case-sensitive="false"
                        :selectable="!itData.readOnly"
                        default-order-column="name"
                        :default-order-direction="true"
                        :paginated="true"
                        :page-size="20"
                >

                    <template v-slot:name="slotProps">
                        <a href="#" @click.stop.prevent="openWaypointMap(slotProps.value)">{{slotProps.value.name}}</a>
                    </template>
                    <template v-slot:day="slotProps">
                        {{getWaypointDay(slotProps.value)}}
                    </template>
                </vue-bootstrap-table>
                <div v-if="!itData.readOnly" class="d-flex justify-content-end">
                    <button class="btn btn-primary" @click="openWaypointDayModal">{{$t("Itinerary.builder.gpx.set_day")}}</button>
                    <button class="btn btn-danger ml-2" @click="deleteWaypoints">{{$t("Itinerary.builder.gpx.delete")}}</button>
                </div>
            </div>
        </div>
        <div class="card-body">
        </div>
        <gpx-day-select-modal :show.sync="showRouteDayModal" :routes="daySelectedRoutes" :points="daySelectedWaypoints" @reload="loadGpxData"></gpx-day-select-modal>
        <gpx-map-modal :show.sync="showMapModal" :name="selectedName" :route="selectedRoute" :point="selectedPoint"></gpx-map-modal>
        <upload-gpx-file-modal :show.sync="showUploadModal" v-on:reload="loadGpxData"></upload-gpx-file-modal>
    </div>
</template>

<script>

    import VueBootstrapTable from "vue2-bootstrap-table2";
    import UploadGpxFileModal from "./UploadGpxFileModal";
    import {handleAjaxError, showCustomDialog, showSuccess} from "../../../utils";
    import GpxMapModal from "../modal/GpxMapModal";
    import GpxDaySelectModal from "../modal/GpxDaySelectModal";

    export default {
        components: {
            GpxMapModal,
            GpxDaySelectModal,
            UploadGpxFileModal,
            VueBootstrapTable,
        },
        props: {
            show: {
                type: Boolean,
                required: true,
            }
        },
        data() {
            return {
                showMapModal: false,
                showUploadModal: false,

                showRouteDayModal: false,
                daySelectedRoutes: [],
                daySelectedWaypoints: [],

                dataLoaded: false,

                routeColumns: [
                    {
                        title: this.$t("Itinerary.builder.gpx.name_col"),
                        name: "name",
                    },
                    {
                        title: this.$t("Itinerary.builder.gpx.day_col"),
                        name: "day",
                    },
                    // {
                    //     title: "",
                    //     filterable: false,
                    //     sortable: false,
                    // },
                ],
                routeList: [],

                waypointColumns: [
                    {
                        title: this.$t("Itinerary.builder.gpx.name_col"),
                        name: "name",
                    },
                    {
                        title: this.$t("Itinerary.builder.gpx.day_col"),
                        name: "day",
                    },
                    // {
                    //     title: "",
                    //     filterable: false,
                    //     sortable: false,
                    // },
                ],
                waypointList: [],

                selectedName: "",
                selectedRoute: null,
                selectedPoint: null,
            }
        },
        watch: {
            show() {
                if (this.show) {
                    if (!this.dataLoaded) {
                        this.loadGpxData();
                    }
                }
            },
        },
        computed: {
            itData() {
                return this.$store.getters.itData;
            },
        },
        mounted() {
        },
        methods: {
            getRouteDay(route) {
                if (route.allDays) {
                    return this.$t("Itinerary.builder.gpx.all_label");
                } else if (route.day === null) {
                    return this.$t("Itinerary.builder.gpx.none_label");
                } else {
                    return route.day;
                }
            },
            getWaypointDay(point) {
                if (point.allDays) {
                    return this.$t("Itinerary.builder.gpx.all_label");
                } else if (point.day === null) {
                    return this.$t("Itinerary.builder.gpx.none_label");
                } else {
                    return point.day;
                }
            },
            loadGpxData() {
                const self = this;
                self.routeList = [];
                self.waypointList = [];
                const url = jsRoutes.controllers.ItineraryController.loadGpxRoutes(this.itData.id).url;
                this.$http.get(url).then(function(response) {
                    response.data.routes.forEach(route => {
                         self.routeList.push({
                             id: route.id,
                             day: route.day,
                             allDays: route.allDays,
                             name: route.details.name,
                             points: route.details.points
                         });
                    });
                    response.data.waypoints.forEach(waypoint => {
                         self.waypointList.push({
                             id: waypoint.id,
                             day: waypoint.day,
                             allDays: waypoint.allDays,
                             name: waypoint.details.name,
                             lat: waypoint.details.lat,
                             lng: waypoint.details.lng
                         });
                    });
                    // self.routeList = response.data.routes;
                    // self.waypointList = response.data.waypoints;
                    self.dataLoaded = true;
                }, function(error) {
                    handleAjaxError(error);
                });
            },
            openRouteMap(route) {
                this.selectedName = route.name;
                this.selectedRoute = route;
                this.selectedPoint = null;
                this.showMapModal = true
            },
            openWaypointMap(point) {
                this.selectedName = point.name;
                this.selectedRoute = null;
                this.selectedPoint = point;
                this.showMapModal = true
            },
            openRouteDayModal() {
                this.showRouteDayModal = true;
                this.daySelectedRoutes = this.routeList.filter(route => {return route.selected});
                this.daySelectedWaypoints = [];
            },
            openWaypointDayModal() {
                this.showRouteDayModal = true;
                this.daySelectedRoutes = [];
                this.daySelectedWaypoints = this.waypointList.filter(waypoint => {return waypoint.selected});
            },
            deleteRoutes() {
                let self = this;
                showCustomDialog(
                    self.$t("Itinerary.builder.gpx.routes_are_you_sure_title"),
                    self.$t("Itinerary.builder.gpx.routes_are_you_sure_msg"),
                    self.$t("Itinerary.builder.gpx.delete"),
                    'btn-danger', () => {
                        const dataObj = {
                            itinId: self.itData.id,
                            routes: self.routeList.filter(route => {return route.selected}).map(route => {return route.id}),
                            waypoints: [],
                        };
                        let url = jsRoutes.controllers.ItineraryController.deleteGpxData(dataObj).url;
                        self.$http.post(url, dataObj).then(response => {
                            showSuccess(
                                self.$t("success.general_title"),
                                self.$t("Itinerary.builder.gpx.routes_delete_success"),
                            );
                            self.loadGpxData()
                        }, error => {
                            handleAjaxError(error)
                        })
                    })
            },
            deleteWaypoints() {
                let self = this;
                showCustomDialog(
                    self.$t("Itinerary.builder.gpx.waypoints_are_you_sure_title"),
                    self.$t("Itinerary.builder.gpx.waypoints_are_you_sure_msg"),
                    self.$t("Itinerary.builder.gpx.delete"),
                    'btn-danger', () => {
                        const dataObj = {
                            itinId: self.itData.id,
                            waypoints: self.waypointList.filter(waypoint => {return waypoint.selected}).map(waypoint => {return waypoint.id}),
                            routes: [],
                        };
                        let url = jsRoutes.controllers.ItineraryController.deleteGpxData(dataObj).url;
                        self.$http.post(url, dataObj).then(response => {
                            showSuccess(
                                self.$t("success.general_title"),
                                self.$t("Itinerary.builder.gpx.waypoints_delete_success"),
                            );
                            self.loadGpxData()
                        }, error => {
                            handleAjaxError(error)
                        })
                    })
            },
        },
    }
</script>

<style scoped>
</style>
