<template>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h1>{{$t("Itinerary.builder.library.library")}}&nbsp;&nbsp;</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-12">

                <div v-if="subscriptionBad" class="row">
                    <div class="col-sm-12 col-md-12 mx-auto">
                        <div class="alert alert-danger" role="alert">
                            {{$t("SubscriptionManager.warning.generic_lock")}}
                            <a v-if="subscriptionData.isAdmin" :href="subscriptionUrl"
                               class="btn btn-primary">
                                {{$t("root.manage_your_subscriptions")}}
                            </a>
                        </div>
                    </div>
                </div>

                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link"
                           :class="{'active' : activeTab === 'sleep'}"
                           href="#"
                           @click.stop.prevent="setActiveTab('sleep')"
                        >{{$t("Itinerary.builder.library.accommodation")}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"
                           :class="{'active' : activeTab === 'meal'}"
                           href="#"
                           @click.stop.prevent="setActiveTab('meal')"
                        >{{$t("Itinerary.builder.library.meals")}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"
                           :class="{'active' : activeTab === 'content'}"
                           href="#"
                           @click.stop.prevent="setActiveTab('content')"
                        >{{$t("Itinerary.builder.library.content")}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"
                           :class="{'active' : activeTab === 'photos'}"
                           href="#"
                           @click.stop.prevent="setActiveTab('photos')"
                        >{{$t("Itinerary.builder.library.photos")}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"
                           :class="{'active' : activeTab === 'sections'}"
                           href="#"
                           @click.stop.prevent="setActiveTab('sections')"
                        >{{$t("Itinerary.builder.library.sections")}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <library-item-list v-show="activeTab === 'sleep'" key="sleep" type="SLEEP"></library-item-list>
        <library-item-list v-show="activeTab === 'meal'" key="meal" type="MEAL"></library-item-list>
        <library-item-list v-show="activeTab === 'content'" key="content"></library-item-list>
        <library-photo-list v-if="activeTab === 'photos'"></library-photo-list>
        <library-item-list v-show="activeTab === 'sections'" key="day" type="DAY"></library-item-list>
    </div>
</template>

<script>
    import LibraryItemList from "./LibraryItemList";
    import LibraryPhotoList from "./LibraryPhotoList";

    export default {
        components: {
            LibraryItemList,
            LibraryPhotoList,
        },
        data() {
            return {
                activeTab: "sleep",
                subscriptionData: {
                    status: 'UNKNOWN',
                },
            }
        },
        mounted() {
            this.getSubscriptionStatus();
        },
        computed: {
            subscriptionGood() {
                if (this.subscriptionData.status === 'UNKNOWN') {
                    return false;
                }
                if (this.subscriptionData.status === 'HAD_SUBSCRIPTION_BLOCKED') {
                    return false;
                }
                if (this.subscriptionData.status === 'TRIAL_FINISHED_BLOCKED') {
                    return false;
                }
                return true;
            },
            subscriptionBad() {
                if (this.subscriptionData.status === 'HAD_SUBSCRIPTION_BLOCKED') {
                    return true;
                }
                if (this.subscriptionData.status === 'TRIAL_FINISHED_BLOCKED') {
                    return true;
                }
                return false;
            },
            subscriptionUrl() {
                return jsRoutes.controllers.SubscriptionController.subscriptionManager().url;
            },
        },
        methods: {
            getSubscriptionStatus() {
                const self = this;
                jsRoutes.controllers.SubscriptionController.getSubscriptionStatus()
                    .ajax({
                        success: function (data) {
                            self.subscriptionData = data;
                        },
                        error: function (error) {
                            // TODO , do something with error ?  Maybe blank the Page !
                        }
                    });
            },
            setActiveTab(tab) {
                this.activeTab = tab;
            },
        }
    }
</script>

<style scoped>

</style>
