<template>
    <div class="col-12">
        <div class="form-group">
            <label class="control-label" >{{$t("Itinerary.builder.settings.itinerary_language")}}</label>
            <select :disabled="itData.readOnly"
                    class="form-control"
                    v-model="itData.languageCode"
            >
                <option v-for="lang in languageOptions" :value="lang.value" :key="lang.value">{{lang.label}}</option>
            </select>
        </div>
        <div class="form-group">
            <label class="control-label" >{{$t("Itinerary.builder.settings.duration")}}:</label>
                <div class="table-cell">
                    <input class="form-control" type="text" v-model="itData.duration"  :disabled="itData.readOnly">
                </div>
        </div>
        <div v-if="itData.template" class="form-group" :class="{ 'has-error': $v.$dirty && $v.numDaysTmp.$error}">
            <label class="control-label">{{$t("Itinerary.builder.settings.days")}} *:</label>
            <div class="input-group">
                <input type="number" class="form-control" id="numdays" name="numDays"
                       :class="{'is-invalid' : $v.numDaysTmp.$error}"
                       v-model.trim="$v.numDaysTmp.$model"
                       @change="changeNumDays"
                >
                <button class="btn btn btn-primary ml-1" :disabled="numDaysTmp <= 1" @click.stop.prevent="decreaseNumDays">
                    <i class="fa fa-minus"></i>
                </button>
                <button class="btn btn btn-primary ml-1" @click.stop.prevent="increaseNumDays">
                    <i class="fa fa-plus"></i>
                </button>
                <br/>
                <div class="invalid-feedback" v-show="$v.numDaysTmp.$error && !$v.numDaysTmp.required">
                    {{$t("Itinerary.builder.settings.field_required")}}
                </div>
                <div class="invalid-feedback" v-show="$v.numDaysTmp.$error && !$v.numDaysTmp.minValue">
                    {{$t("Itinerary.builder.settings.num_days_minvalue")}}
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label" >{{$t("Itinerary.builder.settings.countries")}}:</label>
            <div class="d-flex">
                <el-select :disabled="itData.readOnly"
                           v-model="itData.countries"
                           default-first-option
                           filterable
                           clearable
                           multiple
                           :placeholder='$t("Itinerary.builder.settings.countries_select")'
                           style="width:100%;color:black;font-size: 1rem;"
                >
                    <el-option v-for="country in countryList"
                               :key="country.id"
                               :label="country.name"
                               :value="country.id"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label" >{{$t("Itinerary.builder.settings.currency")}}:</label>
            <div class="d-flex">
                <el-select :disabled="itData.readOnly"
                           v-model="itData.currencyCode"
                           default-first-option
                           filterable
                           :placeholder='$t("Itinerary.builder.settings.currency_select")'
                           style="width:100%;color:black;font-size: 1rem;"
                >
                    <el-option v-for="curr in currencyList"
                               :key="curr.code"
                               :label="curr.label"
                               :value="curr.code"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label" >{{$t("Itinerary.builder.settings.cost")}}:</label>
            <div class="table-cell">
                <input class="form-control" type="number" v-model="itData.cost"  :disabled="itData.readOnly">
            </div>
       <div class="table-cell pl-2">
		EUR
       </div>
        </div>
        <div class="form-group">
            <label class="control-label" >{{$t("Itinerary.builder.settings.trip_type")}}:</label>
            <div class="d-flex">
                <select :disabled="itData.readOnly"
                        class="form-control"
                        v-model="itData.tripType"
                >
                    <option v-for="type in tripTypeList" :value="type.value" :key="type.value">{{type.label}}</option>
                </select>
                <!--<el-select v-model="itData.tripType"
                           filterable
                           default-first-option
                           style="width:100%;"
                >
                    <el-option v-for="type in tripTypeList"
                               :key="type.value"
                               :label="type.label"
                               :value="type.value"
                    >
                    </el-option>
                </el-select>-->
            </div>
        </div>
        <div class="form-group">
            <el-input
                :autosize="{ minRows: 4 }"
                type="textarea"
                v-model="itData.documentsPageHtml"
                placeholder="Documents page HTML"
                :rows="4"
                style="font-family: monospace; tab-size: 3;"
            />
        </div>
        <div v-if="!itData.readOnly" class="float-right" style="padding-top: 5px;">
            <v-button :loading="saveLoading" @click.native="updateSettings" class="btn btn-primary btn-lg">
                <i class="fa fa-save" aria-hidden="true"></i>&nbsp;
                {{$t("Itinerary.builder.detail.save")}} ({{$t("Itinerary.builder.detail.last_saved")}} {{formatedUpdateDate}})
            </v-button>
        </div>

        <client-modal :show.sync="showClientModal" v-on:reload="loadClientList"></client-modal>
    </div>
</template>

<script>
    import moment from 'moment'
    import {
        handleAjaxError,
        showCustomDialog,
        showItineraryDatesError
    } from "../../utils";
    import {required, minValue} from 'vuelidate/lib/validators';

    import vButton from '../../components/VButton';
    import ClientModal from '../client/ClientModal.vue';
    import { itineraryEventBus } from '../../event-bus';
    import {formatDate, formatMoment, getDateFormatOptions, parseMoment} from "../../date-utils";
    import {getLanguageOptions} from "../../language-utils";
    import { getItineraryProblemsForDates, updateSectionsDateFormat } from "./itinerary-templates";
    import {getTripTypeOptions} from "./itinerary-utils";
    import VRating from '../../components/VRating.vue';

    export default {
        name: "ItinerarySettings",
        components: {
    vButton,
    ClientModal,
    VRating
},
        props: {
            saveLoading: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                dateFormats: getDateFormatOptions(),
                languageOptions: getLanguageOptions(),

                numDaysTmp: null,
                oldDateFormat: null,

                dateRange: null,
                defaultDate: new Date(),
                pickerOptions: {
                    disabledDate(date) {
                        return moment(date).isBefore(moment());
                    }
                },

                clientList: [],
                countryList: [],
                currencyList: [],
                showClientModal: false,
                cost: 0,
                duration: '',
                loading: false,

            }
        },
        validations: {
            numDaysTmp: {
                required,
                minValue: minValue(1),
            },
        },
        mounted() {
            this.loadClientList();
            this.loadCountryList();
            this.loadCurrencies();
            const self = this;
            itineraryEventBus.$on('details-loaded', () => {
                self.oldDateFormat = self.itData.dateFormat;
                self.numDaysTmp = self.itData.numDays;
                if (self.itData.startDate && self.itData.endDate) {
                    self.dateRange = [
                        parseMoment(self.itData.startDate, self.itData.dateFormat),
                        parseMoment(self.itData.endDate, self.itData.dateFormat),
                    ];
                    self.defaultDate = parseMoment(self.itData.startDate, self.itData.dateFormat).toDate();

                    if (!self.numDaysTmp) {
                        const startMoment = parseMoment(this.itData.startDate, this.itData.dateFormat);
                        const endMoment = parseMoment(this.itData.endDate, this.itData.dateFormat);
                        self.numDaysTmp = endMoment.diff(startMoment, "days")+1;
                    }
                }
            });

        },
        beforeDestroy() {
            itineraryEventBus.$off('details-loaded');
        },
        watch: {
        },
        computed: {
            itData() {
                return this.$store.getters.itData;
            },
            tripTypeList() {
                const type = this.itData.tripType;
                const list = getTripTypeOptions(type);
                return list;
            },
            formatedUpdateDate() {
                return formatDate(new Date(this.itData.updateDate), this.itData.dateFormat + " HH:mm:ss")
            },
        },
        methods: {
            changeDateFormat() {
                // const self = this;
                const startMoment = parseMoment(this.itData.startDate, this.oldDateFormat);
                const endMoment = parseMoment(this.itData.endDate, this.oldDateFormat);
                this.itData.startDate = formatMoment(startMoment, this.itData.dateFormat);
                this.itData.endDate = formatMoment(endMoment, this.itData.dateFormat);

                this.itData.tripData.sections = updateSectionsDateFormat(this.itData.tripData.sections, this.itData.startDate, this.itData.dateFormat);

                this.oldDateFormat = this.itData.dateFormat;
            },
            decreaseNumDays() {
                if (this.numDaysTmp > 1) {
                    this.numDaysTmp--;
                    this.changeNumDays();
                }
            },
            increaseNumDays() {
                this.numDaysTmp++;
                this.changeNumDays();
            },
            changeNumDays() {
                const self = this;
                let problemList = [];

                if (this.numDaysTmp === null || this.numDaysTmp < 1) {
                    this.numDaysTmp = this.itData.numDays;
                }
                if (this.numDaysTmp < this.itData.numDays) {
                    problemList = getItineraryProblemsForDates(this.itData.tripData.sections, this.numDaysTmp);
                }

                // validate new dates against conflicts with dates outside the new interval
                if (problemList.length > 0) {
                    showItineraryDatesError(
                        self.$t("Itinerary.builder.settings.message_cant_change_trip_dates"),
                        self.$t("Itinerary.builder.settings.message_your_iti_will_have") + " " + this.numDaysTmp + " " +
                        self.$t("Itinerary.builder.settings.message_days_instead_of") + " " + this.itData.numDays
                        + ". "+
                        self.$t("Itinerary.builder.settings.message_days_will_be_left_out"),
                        problemList
                    );
                    this.numDaysTmp = this.itData.numDays;
                } else if (this.numDaysTmp < this.itData.numDays) {
                    showCustomDialog("Warning",
                        self.$t("Itinerary.builder.settings.message_your_iti_will_have") + " " + this.numDaysTmp + " "+
                        self.$t("Itinerary.builder.settings.message_days_instead_of") + " " + this.itData.numDays + ". "+
                        self.$t("Itinerary.builder.settings.message_details_may_lost")+ "<br/>"+
                        self.$t("Itinerary.builder.settings.message_continue")+ "?",
                        self.$t("Itinerary.builder.settings.message_continue"),
                        "btn-warning",
                        function() {
                            self.doChangeNumDays();
                        },
                        function() {
                            self.numDaysTmp = self.itData.numDays;
                        }
                    );
                } else {
                    this.doChangeNumDays();
                }
            },
            doChangeNumDays() {
                this.itData.numDays = this.numDaysTmp;

                // console.log("### UPDATE DATES! "  + this.itData.startDate + " - " + this.itData.endDate);
                itineraryEventBus.$emit("update-dates");
            },
            changeDates() {
                const self = this;
                const startMoment = parseMoment(this.itData.startDate, this.itData.dateFormat);
                const endMoment = parseMoment(this.itData.endDate, this.itData.dateFormat);
                const numDays = endMoment.diff(startMoment, "days")+1;
                console.log("### OLD DAYS => " + numDays);

                const startMomentTmp = moment(this.dateRange[0]);
                const endMomentTmp = moment(this.dateRange[1]);
                const numDaysTmp = endMomentTmp.diff(startMomentTmp, "days")+1;
                console.log("### NEW DAYS => " + numDaysTmp);

                let problemList = [];

                if (numDaysTmp < numDays) {
                    problemList = getItineraryProblemsForDates(this.itData.tripData.sections, numDaysTmp);
                }

                // validate new dates against conflicts with dates outside the new interval
                if (problemList.length > 0) {
                    showItineraryDatesError(
                        self.$t("Itinerary.builder.settings.message_cant_change_trip_dates"),
                        self.$t("Itinerary.builder.settings.message_your_iti_will_have") + " " + numDaysTmp + " " +
                        self.$t("Itinerary.builder.settings.message_days_instead_of") + " " + numDays
                        + ". "+
                        self.$t("Itinerary.builder.settings.message_days_will_be_left_out"),
                        problemList
                    );
                    this.dateRange = [
                        parseMoment(this.itData.startDate, this.itData.dateFormat),
                        parseMoment(this.itData.endDate, this.itData.dateFormat),
                    ]
                } else if (numDaysTmp < numDays) {
                    showCustomDialog("Warning",
                        self.$t("Itinerary.builder.settings.message_your_iti_will_have") + " " + numDaysTmp + " "+
                        self.$t("Itinerary.builder.settings.message_days_instead_of") + " " + numDays + ". "+
                        self.$t("Itinerary.builder.settings.message_details_may_lost")+ "<br/>"+
                        self.$t("Itinerary.builder.settings.message_continue")+ "?",
                        self.$t("Itinerary.builder.settings.message_continue"),
                        "btn-warning",
                        function() {
                            self.doChangeDates();
                        },
                        function() {
                            self.dateRange = [
                                parseMoment(self.itData.startDate, self.itData.dateFormat),
                                parseMoment(self.itData.endDate, self.itData.dateFormat),
                            ];
                        }
                    );
                } else {
                    this.doChangeDates();
                }
            },
            doChangeDates() {
                this.itData.startDate = formatDate(this.dateRange[0], this.itData.dateFormat);
                this.itData.endDate = formatDate(this.dateRange[1], this.itData.dateFormat);

                const startMomentTmp = moment(this.dateRange[0]);
                const endMomentTmp = moment(this.dateRange[1]);
                this.itData.numDays = endMomentTmp.diff(startMomentTmp, "days") + 1;

                // console.log("### UPDATE DATES! "  + this.itData.startDate + " - " + this.itData.endDate);
                itineraryEventBus.$emit("update-dates");
            },
            openCreateClient() {
                this.showClientModal = true;
            },
            loadClientList() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ItineraryController.clientListSelect().url;
                this.$http.get(url).then(function(response) {
                    self.clientList = response.data;
                }, function(error) {
                    handleAjaxError(error);
                });

            },
            loadCountryList() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.Application.countryListSelect().url;
                this.$http.get(url).then(function(response) {
                    self.countryList = response.data;
                }, function(response) {
                    handleAjaxError(response);
                });

            },
            loadCurrencies() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.Application.currencyListSelect().url;
                this.$http.get(url)
                    .then(function (response) {
                        self.currencyList = response.data;
                    })
                    .catch(function (error) {
                        // handle error
                        handleAjaxError(error);
                    });
            },
            updateSettings: function () {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.log("### INVALID");
                    return;
                }
                this.$emit('update-itinerary');
            },
            /*doUpdateSettings: function () {
                const self = this;

                const url = jsRoutes.controllers.ItineraryController.doEditItinerary().url;

                this.loading = true;
                this.$http.post(url, this.itData)
                    .then(function (response) {
                        // self.loading = false;
                        self.$v.$reset();
                        self.$emit("update:itData", self.itData);
                        showSuccess("Success", "Itinerary settings udpated");
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            }*/
        }
    }
</script>

<style scoped>

</style>
