export function getTripTypeOptions(type) {
    let result = [
        {
            value: "GUIDE",
            label: "Guide",
        },
        {
            value: "ITINERARY_IDEA",
            label: "Itinerary",
        },
        {
            value: "EXPERIENCE",
            label: "Experience",
        }
    ];
    // old types
    if (type === 'FIT') {
        result.push(
            {
                value: "FIT",
                label: "FIT",
            }
        );
    } else if (type === 'GROUP_TOUR') {
        result.push({
            value: "GROUP_TOUR",
            label: "Group Tour",
        })
    }
    return result;
}
