<!-- eslint-disable vue/no-template-key -->
<template>
    <span>
        <span :class="`vstar_rating${readonly ? '' : ' vstar_editable'}`" :data-uuid="vuuid">
            <template v-for="v in stars" :key="vuuid + v">
                <input
                    type="radio"
                    :id="vuuid + v"
                    :name="vuuid"
                    :value="v"
                    v-model="val"
                    :key="vuuid + v + 'inpt'"
                    :disabled="readonly ? 'disable' : undefined"
                >
                <label
                    :for="vuuid + v"
                    :key="vuuid + v + 'lbl'"
                    :data-char="char"
                    :class="labelClass"
                >{{ char }}</label>
            </template>
        </span>
    </span>
</template>

<script>
    import { v4 as uuidv4 } from 'uuid';
    export default {
        name: "VRating",
        props: {
            value: Number,
            count: Number,
            character: String,
            editable: Boolean 
        },
        computed: {
            vuuid() {
                return "str" + uuidv4();
            },
            cnt() {
                return this.count || 5;
            },
            stars() {
                return Array.from({ length: this.cnt }, (_, idx) => idx+1).reverse();
            },
            char() {
                return this.character || '☆';
            },
            labelClass() {
                    return this.character ? false : 'with_star';
            },
            readonly() {
                if (this.editable === false)
                    return true;
                return false;
            },
            val: {
                get() {
                    const val = this.value || 0;
                    return val > this.cnt ? this.cnt : val;
                },
                set(value) {
                    if (!this.readonly) {
                        const val = this.value === value ? 0 : value;
                        this.$emit('input', val);
                    }
                }
            }
        }
    }
</script>

<style scoped>
</style>
