<template>
    <div class="itinerary-print itinerary-print-margins">
        <div class="row" v-for="section in sectionList" :key="section.id">
            <div class="col-12">
                <h2 class="section-title" v-if="section.showPdfTitle">
                    {{section.title}}
                </h2>
                <!--<div class="card-body">-->
                <div>
                    <div class="trip-item"
                         v-for="item in section.items"
                         :key="item.id">
                        <trip-generic-item
                                v-if="showItem(item) && item.type === 'GENERIC'"
                                :item.sync="item"
                        >
                        </trip-generic-item>
                        <trip-cover-print
                                v-if="showItem(item) && item.type === 'COVER'"
                                :item.sync="item"
                                :itin-id="itData.id"
                                :start-date="itData.startDate"
                                :end-date="itData.endDate"
                        >
                        </trip-cover-print>
                        <trip-tour-name-print
                                v-if="showItem(item) && item.type === 'TOUR_NAME'"
                                :item.sync="item"
                        >
                        </trip-tour-name-print>
                        <trip-client-info
                                v-if="showItem(item) && item.type === 'CLIENT_INFO'"
                                :item.sync="item"
                        >
                        </trip-client-info>
                        <trip-highlights-print
                                v-if="showItem(item) && item.type === 'TRIP_HIGHLIGHTS'"
                                :item.sync="item"
                        >
                        </trip-highlights-print>
                        <trip-description
                                v-if="showItem(item) && item.type === 'TRIP_DESCRIPTION'"
                                :item.sync="item"
                        >
                        </trip-description>
                        <trip-dates-print
                                v-if="showItem(item) && item.type === 'TRIP_DATES'"
                                :start-date="itData.startDate"
                                :end-date="itData.endDate"
                                :item.sync="item"
                        >
                        </trip-dates-print>
                        <trip-company-info
                                v-if="showItem(item) && item.type === 'COMPANY_INFO'"
                                :it-data="itData"
                                :item="item"
                        >
                        </trip-company-info>
                        <trip-destination-info
                                v-if="showItem(item) && item.type === 'DESTINATION_INFO'"
                                :item.sync="item"
                                :status="itData.status"
                        >
                        </trip-destination-info>
                        <trip-poi
                                v-if="showItem(item) && item.type === 'POI'"
                                :item.sync="item"
                        >
                        </trip-poi>
                        <trip-activity
                                v-if="showItem(item) && item.type === 'ACTIVITY'"
                                :item.sync="item"
                                :it-data="itData"
                        >
                        </trip-activity>
                        <trip-transportation
                                v-if="showItem(item) && item.type === 'TRANSPORTATION'"
                                :item.sync="item"
                        >
                        </trip-transportation>
                        <trip-flight-print

                                v-if="showItem(item) && item.type === 'FLIGHT'"
                                :item.sync="item"
                        >
                        </trip-flight-print>
                        <trip-car-rental
                                v-if="showItem(item) && item.type === 'CAR_RENTAL'"
                                :item.sync="item"
                                :it-data="itData"
                        >
                        </trip-car-rental>
                        <trip-car-rental-mid
                                v-if="showItem(item) && item.type === 'CAR_RENTAL_MID'"
                                :item.sync="item"
                                :sections="sectionList"
                        >
                        </trip-car-rental-mid>
                        <trip-car-rental-last
                                v-if="showItem(item) && item.type === 'CAR_RENTAL_LAST'"
                                :item.sync="item"
                                :sections="sectionList"
                        >
                        </trip-car-rental-last>
                        <trip-meal
                                v-if="showItem(item) && item.type === 'MEAL'"
                                :item.sync="item"
                        >
                        </trip-meal>
                        <trip-sleep
                                v-if="showItem(item) && item.type === 'SLEEP'"
                                :item.sync="item"
                        >
                        </trip-sleep>
                        <trip-sleep-night
                                v-if="showItem(item) && item.type === 'SLEEP_MID'"
                                :item.sync="item"
                                :sections="sectionList"
                        >
                        </trip-sleep-night>
                        <trip-sleep-checkout
                                v-if="showItem(item) && item.type === 'SLEEP_LAST'"
                                :item.sync="item"
                                :sections="sectionList"
                        >
                        </trip-sleep-checkout>
                        <trip-know-before-go-print
                                v-if="showItem(item) && item.type === 'KNOW_BEFORE_GO'"
                                :item.sync="item"
                        >
                        </trip-know-before-go-print>
                        <trip-price
                                v-if="showItem(item) && item.type === 'PRICE'"
                                :item.sync="item"
                                :it-data="itData"
                        >
                        </trip-price>
                        <trip-whats-included
                                v-if="showItem(item) && item.type === 'WHATS_INCLUDED'"
                                :item.sync="item"
                                :it-data="itData"
                        >
                        </trip-whats-included>
                        <trip-next-steps
                                v-if="showItem(item) && item.type === 'NEXT_STEPS'"
                                :item.sync="item"
                        >
                        </trip-next-steps>
                        <trip-cancelation-policy
                                v-if="showItem(item) && item.type === 'CANCELATION_POLICY'"
                                :item.sync="item"
                        >
                        </trip-cancelation-policy>
                        <!-- SPACER -->
                        <div style="margin-bottom: 30px;" v-if="showItem(item)">
                            <hr/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import $ from 'jquery'
    import {handleAjaxError, stringNotEmpty} from "../../../utils";

    import TripCoverPrint from './item/TripCoverPrint';
    import TripActivity from './item/TripActivityPrint';
    import TripCarRental from './item/TripCarRentalPrint';
    import TripCarRentalMid from './item/TripCarRentalMidPrint';
    import TripCarRentalLast from './item/TripCarRentalLastPrint';
    import TripClientInfo from './item/TripClientInfoPrint';
    import TripCompanyInfo from './item/TripCompanyInfoPrint';
    import TripDescription from './item/TripDescriptionPrint';
    import TripDestinationInfo from './item/TripDestinationInfoPrint';
    import TripGenericItem from './item/TripGenericItemPrint';
    import TripHighlightsPrint from './item/TripHighlightsPrint';
    import TripMeal from './item/TripMealPrint';
    import TripPoi from './item/TripPoiPrint';
    import TripSleep from './item/TripSleepPrint';
    import TripSleepNight from './item/TripSleepNightPrint';
    import TripSleepCheckout from './item/TripSleepCheckoutPrint';
    import TripTourNamePrint from './item/TripTourNamePrint';
    import TripTransportation from './item/TripTransportationPrint';
    import TripFlightPrint from './item/TripFlightPrint';
    import TripPrice from './item/TripPricePrint';
    import TripWhatsIncluded from './item/TripWhatsIncludedPrint';
    import TripNextSteps from './item/TripNextStepsPrint';
    import TripCancelationPolicy from './item/TripCancelationPolicyPrint';
    import {
        ITEM_TYPE_ACTIVITY,
        ITEM_TYPE_CAR_RENTAL,
        ITEM_TYPE_CAR_RENTAL_LAST, ITEM_TYPE_CAR_RENTAL_MID,
        ITEM_TYPE_CLIENT_INFO,
        ITEM_TYPE_COMPANY_INFO,
        ITEM_TYPE_COVER, ITEM_TYPE_DESTINATION_INFO, ITEM_TYPE_FLIGHT,
        ITEM_TYPE_GENERIC, ITEM_TYPE_KNOW_BEFORE_GO,
        ITEM_TYPE_MEAL,
        ITEM_TYPE_NEXT_STEPS, ITEM_TYPE_POI, ITEM_TYPE_PRICE,
        ITEM_TYPE_SLEEP,
        ITEM_TYPE_SLEEP_LAST,
        ITEM_TYPE_SLEEP_MID,
        ITEM_TYPE_TOUR_NAME, ITEM_TYPE_TRANSPORTATION,
        ITEM_TYPE_TRIP_DATES,
        ITEM_TYPE_TRIP_DESCRIPTION,
        ITEM_TYPE_TRIP_HIGHLIGHTS,
        ITEM_TYPE_WHATS_INCLUDED,
        SECTION_TYPE_COVER,
    } from "../itinerary-templates";
    import TripKnowBeforeGoPrint from "./item/TripKnowBeforeGoPrint";
    import TripDatesPrint from "./item/TripDatesPrint";
    import {stringUtilsMixin} from "../item/item-mixins";

    export default {
        components: {
            TripDatesPrint,
            TripKnowBeforeGoPrint,
            TripCoverPrint,
            TripActivity,
            TripCarRental,
            TripCarRentalMid,
            TripCarRentalLast,
            TripClientInfo,
            TripCompanyInfo,
            TripDescription,
            TripDestinationInfo,
            TripGenericItem,
            TripHighlightsPrint,
            TripMeal,
            TripPoi,
            TripSleep,
            TripSleepNight,
            TripSleepCheckout,
            TripTourNamePrint,
            TripFlightPrint,
            TripTransportation,
            TripPrice,
            TripWhatsIncluded,
            TripNextSteps,
            TripCancelationPolicy,
        },
        mixins: [
            stringUtilsMixin,
        ],
        // props: ["uuid"],
        data() {
            return {
                id: null,

                itData: {
                    name: null,
                    startDate: null,
                    endDate: null,
                    clients: null,
                },
            }
        },
        mounted() {
            // eslint-disable-next-line
            this.id = itinId; // itinId vem da view twirl
            $("#wrapper").removeClass("toggled");
            this.loadDetail();
        },
        computed: {
            sectionList() {
                // exclui cover
                if (this.itData.tripData !== undefined && this.itData.tripData !== null) {
                    return this.itData.tripData.sections.filter(section => {
                        return section.type !== SECTION_TYPE_COVER;
                    });
                } else {
                    return [];
                    /*this.itData.tripData.sections = [];
                    return this.itData.sections;*/
                }
            },
        },
        methods: {
            loadDetail() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ItineraryController.loadItineraryDetails(this.id).url;
                axios.get(url).then(function(response) {
                    self.itData = response.data;
                }, function(error) {
                    handleAjaxError(error);
                });
            },
            showItem(item) {
                switch (item.type) {
                    case ITEM_TYPE_GENERIC:
                        return true;
                    case ITEM_TYPE_COVER:
                        return true;
                    case ITEM_TYPE_TOUR_NAME:
                        return true;
                    case ITEM_TYPE_CLIENT_INFO:
                        return true;
                    case ITEM_TYPE_TRIP_HIGHLIGHTS:
                        return true;
                    case ITEM_TYPE_TRIP_DESCRIPTION:
                        return true;
                    case ITEM_TYPE_TRIP_DATES:
                        return true;
                    case ITEM_TYPE_COMPANY_INFO:
                        return true;
                    case ITEM_TYPE_DESTINATION_INFO:
                        return true;
                    case ITEM_TYPE_KNOW_BEFORE_GO:
                        return true;
                    case ITEM_TYPE_POI:
                        return true;
                    case ITEM_TYPE_ACTIVITY:
                        return this.showTripActivityItem(item);
                    case ITEM_TYPE_TRANSPORTATION:
                        return true;
                    case ITEM_TYPE_FLIGHT:
                        return true;
                    case ITEM_TYPE_CAR_RENTAL:
                    case ITEM_TYPE_CAR_RENTAL_MID:
                    case ITEM_TYPE_CAR_RENTAL_LAST:
                        return true;
                    case ITEM_TYPE_MEAL:
                        return true;
                    case ITEM_TYPE_SLEEP:
                    case ITEM_TYPE_SLEEP_MID:
                    case ITEM_TYPE_SLEEP_LAST:
                        return true;
                    case ITEM_TYPE_PRICE:
                        return this.showTripPriceItem(item);
                    case ITEM_TYPE_WHATS_INCLUDED:
                        return true;
                    case ITEM_TYPE_NEXT_STEPS:
                        return true;
                    default:
                        return true;
                }
            },
            showTripPriceItem(item) {
                return stringNotEmpty(item.adultPrice) ||
                        stringNotEmpty(item.childPrice) ||
                        stringNotEmpty(item.childPrice) ||
                        stringNotEmpty(item.groupPrice) ||
                        stringNotEmpty(item.singleSupplement) ||
                        (item.tripType === "GROUP_TOUR" && item.tableLines && item.tableLines.length > 0) ||
                        item.images.length > 0;
            },
            showTripActivityItem(item) {
                let result = false;
                if (item.list) {
                    item.list.some(activity => {
                        if (stringNotEmpty(activity.description) ||
                            stringNotEmpty(activity.description2)) {
                            result = true;
                            return true;
                        }
                    });
                }
                return result;
            }
        }
    }
</script>

<style scoped>
    .section-title {
        margin-bottom:10px;
        padding-bottom:15px;
        border-bottom:2px solid black;
    }

/*
    .trip-item {
        page-break-after:auto;
        page-break-before:auto;
        page-break-inside:avoid;
    }
*/
</style>
