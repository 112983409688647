<template>
    <div class="modal" id="builderChangeManagerModal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t("Itinerary.modal.ChangeManager.title")}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="form-group">
                            <label class="control-label mr-2" >{{$t("Itinerary.modal.ChangeManager.manager")}} *:</label>
                            <select class="form-control" v-model="tmpManagerId">
                                <option v-for="manager in managerList" :key="manager.id" :value="manager.id">
                                    {{manager.name}}
                                </option>
                            </select>
                        </div>
                        <div class="float-right">
                            <button class="btn btn-link" @click="close">{{$t("root.cancel")}}</button>
                            <v-button :loading="saveLoading" class="btn btn-primary ml-2" @click="updateManager">{{$t("Itinerary.modal.ChangeManager.save")}}</v-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vButton from '../../../components/VButton';
    import {handleAjaxError, showError, showSuccess} from "../../../utils";

    export default {
        components: {
            vButton,
        },
        props: {
            show: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            const self = this;
            return {
                managerList: [],
                tmpManagerId: null,

                saveLoading: false,

            }
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function (e) {
                self.$emit('update:show', false);
            })
        },
        computed: {
            itData() {
                return this.$store.getters.itData;
            },
            selectedManager() {
                if (this.tmpManagerId !== null) {
                    let manager = this.managerList.find(item => {
                        return item.id === this.tmpManagerId;
                    });
                    if (manager) {
                        return manager;
                    }
                }
                return null;
            },
        },
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                    this.tmpManagerId = this.itData.managerId;
                    this.loadManagerList();
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
            }
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            loadManagerList() {
                const self = this;
                const url = jsRoutes.controllers.ItineraryController.getManagerListSelect().url;

                this.$http.get(url).then(function (response) {
                    //console.log("roles -> " + JSON.stringify(data));
                    self.managerList = response.data;

                }).catch(function (response) {
                    // handle error
                    showError(
                        self.$t("error.general_title"),
                        self.$t("Itinerary.modal.ChangeManager.load_manager_list_failed")
                    );
                });
            },
            updateManager() {
                if (this.itData.managerId !== this.tmpManagerId) {
                    const self = this;
                    const url = jsRoutes.controllers.ItineraryController.updateItineraryManager().url;

                    this.saveLoading = true;
                    const dataObj = {
                        id: this.itData.id,
                        managerId: this.tmpManagerId,
                        managerName: this.selectedManager.name
                    }
                    this.$http.post(url, dataObj)
                        .then(function (response) {
                            self.saveLoading = false;
                            // self.itData.managerId = self.tmpManagerId;
                            // self.itData.managerName = self.selectedManager.name;
                            self.$emit("reload");
                            self.close();
                            showSuccess(
                                self.$t("Itinerary.modal.ChangeManager.updated")
                            );
                        })
                        .catch(function (error) {
                            self.saveLoading = false;
                            // handle error
                            handleAjaxError(error);
                        });

                } else {
                    this.close();
                }
            },
        }
    }
</script>

<style scoped>
</style>
