import axios from 'axios';
import { itineraryEventBus } from '../../../event-bus';
import {getSectionForItemId} from "../itinerary-templates";
import {handleAjaxError, showSuccess, stringNotEmpty} from "../../../utils";

export let autosaveMixin = {
    methods: {
        emitAutosave() {
            itineraryEventBus.$emit("autosave");
        },
        emitForceSave() {
            itineraryEventBus.$emit("forcesave");
        },
    }
};

export let stringUtilsMixin = {
    methods: {
        stringNotEmpty(str) {
            return stringNotEmpty(str);
        },
        cleanupString(str) {
            return str ? str.replace(/(?:\r\n|\r|\n)/g, '<br>') : null;
        }
    }
};


export let moveItemMixin = {
    props: {
        sections: {
            type: Array,
            required: false,
            default: function () { return [] },
        },
    },
    computed: {
        currentSectionId() {
            if (this.item && this.sections) {
                const section = getSectionForItemId(this.sections, this.item.id);
                if (section)
                    return section.id;
            }
            return null;
        },
    },
    methods: {
        moveItem(newSectionId) {
            this.$emit("move-item", {
                item: this.item,
                newSectionId: newSectionId,
            });
        }
    }
};


export let itemCommonPropsMixin = {
    props: {
        libmode: {
            type: Boolean,
            required: false,
            default: false,
        },
        editable: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
    },
    methods: {
    }
};


export let imageUrlMixin = {
    methods: {
        getImageUrl(image) {
            let type = image.type ? image.type : image.details.type;
            if (type) {
                if (image.type === 'GOOGLE') {
                    return image.url;
                } else if (type === 'UNSPLASH') {
                    return image.data.urls.raw + "&w=800&h=600&fit=crop&crop=edges";
                } else if (type === 'LOCAL' || type === 'PIXABAY') {
                    // var quality esta na view twirl
                    // eslint-disable-next-line
                    if (typeof quality !== 'undefined') {
                        // eslint-disable-next-line
                        return jsRoutes.controllers.ItineraryController.imageDownload(image.id, quality).url;
                    } else{
                        // eslint-disable-next-line
                        return jsRoutes.controllers.ItineraryController.imageDownload(image.id).url;
                    }
                }
            } else {
                // var quality esta na view twirl
                // eslint-disable-next-line
                if (typeof quality !== 'undefined') {
                    // eslint-disable-next-line
                    return jsRoutes.controllers.ItineraryController.imageDownload(image.id, quality).url;
                } else{
                    // eslint-disable-next-line
                    return jsRoutes.controllers.ItineraryController.imageDownload(image.id).url;
                }
            }
        },
        deleteImage(image) {
            const self = this;
            const index = self.item.images.findIndex((i) => {
                return i.id === image.id;
            });
            self.item.images.splice(index, 1);
            self.$emit("update:item", self.item);
            itineraryEventBus.$emit("autosave");
            /*showCustomDialog(
                "Are you sure?",
                "Remove this image?",
                "Remove",
                "btn-danger",
                function() {
                    const index = self.item.images.findIndex((i) => {
                        return i.id === image.id;
                    });
                    self.item.images.splice(index, 1);
                    self.$emit("update:item", self.item);
                    itineraryEventBus.$emit("autosave");
                }
            );*/
        },
        updateImageCaption(image) {
            const self = this;
            // eslint-disable-next-line
            const url = jsRoutes.controllers.LibraryController.updateImageCaption().url;

            const dataObj = {
                id: image.id,
                caption: image.details ? image.details.caption : image.caption,
            }
            // GET request
            axios.post(url, dataObj).then(() => {
                //TODO verificar se caption nao foi ja actualizada por outro user, perguntar se quer fazer overwrite
                itineraryEventBus.$emit("autosave");
                showSuccess(
                    self.$t("success.general_title"),
                    self.$t("success.image_caption_updated")
                );
            }).catch(error => {
                // TODO mostrar erro ao user?
                // handle error
                handleAjaxError(error);
            });
        },
    },
};
