<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <button class="btn btn-primary"
                        v-tooltip='$t("Itinerary.TripSleep.tooltip.change_dates")'
                        @click="openChangeDates"
                >
                    <i class="fa fa-calendar-alt"></i>
                </button>
                <button class="btn btn-primary"
                        @click="showAutocomplete = true"
                        v-tooltip='$t("Itinerary.TripSleep.tooltip.autocomplete_hotel")'
                >
                    <i class="fa fa-play-circle"></i>
                </button>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
<!--
                <button class="btn btn-primary"
                        v-tooltip="saveToLibraryLabel"
                        @click="saveLibraryItem"
                >
                    <i class="fa fa-save"></i>
                </button>
-->
                <button class="btn btn-danger" @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
<!--
            <br/>
            <div class="text-right" style="margin-top: 5px;">
                <button class="btn btn-light drag-handle" v-tooltip="'Click and drag to move'">
                    <i class="fa fa-arrows-alt"></i>
                </button>
            </div>
-->
        </div>
        <div class="row">
            <div class="col-10">
                <h3>
                    <div class="table-cell">
                        <i class="fa fa-h-square"></i>
                    </div>
                    <div class="table-cell" style="width:100%;padding-left: 10px;">
                        <input :readonly="!editable"
                               type="text"
                               class="name-input"
                               v-model="item.name"
                               @blur="emitAutosave"
                               :placeholder='$t("Itinerary.TripSleep.tooltip.hotel_name")'/>
                    </div>
                </h3>
            </div>
        </div>
        <div class="row" v-if="showStars">
            <div class="col-12">
                <star-rating :read-only="!editable"
                             v-model="item.stars"
                             @rating-selected="emitAutosave"
                             :show-rating="false"
                             :star-size="20"
                ></star-rating>
            </div>
        </div>
        <div class="row">
            <div class="col-12" style="padding-top: 5px;">
                <div class="table-cell">
                    {{$t("Itinerary.TripSleep.tooltip.type")}}: &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div class="table-cell" style="width:100%;">
                    <multiselect
                            :disabled="!editable"
                            v-model="item.hotelType"
                            :options="hotelTypeOptions"
                            :placeholder='$t("Itinerary.TripSleep.tooltip.select_hotel_type")'
                            :tag-placeholder='$t("Itinerary.TripSleep.tooltip.add_hotel_type")'
                            :multiple="false"
                            :taggable="true"
                            :close-on-select="true"
                            @tag="addHotelTypeOption"
                            @close="saveItinerary"
                    ></multiselect>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12" v-if="item.googleRating">
                <div class="table-cell">
                    {{$t("Itinerary.general.google_rating")}}:
                </div>
                <div class="table-cell">
                    <star-rating v-model="item.googleRating"
                                 :show-rating="true"
                                 :star-size="15"
                                 :read-only="true"
                                 :increment="0.1"
                    ></star-rating>
                </div>
            </div>
            <div class="col-12" style="padding-top: 5px;">
                <textarea-autosize
                        v-if="editable"
                        class="form-control body-font-size"
                        :placeholder='$t("Itinerary.TripSleep.tooltip.description")'
                        v-model="item.description"
                        :min-height="30"
                        :max-height="500"
                        @blur.native="saveItinerary"
                ></textarea-autosize>
                <span v-else v-html="cleanupString(item.description)"></span>
                <!--<textarea class="form-control" placeholder="Description" v-model="item.description"  @blur="emitAutosave"></textarea>-->
            </div>
            <div class="col-12" style="padding-top: 5px;">
                <div v-if="editable" class="table-cell icon-left" v-tooltip='$t("Itinerary.general.show_on_map")'>
                    <a href="#" @click.prevent="showMapModal = true"><i class="fa fa-globe"></i></a>
                </div>
                <div v-else class="table-cell icon-left">
                    <i class="fa fa-globe"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;" v-tooltip="'Address'" >
                    <input :readonly="!editable"
                           class="bullet-item mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripSleep.tooltip.address")'
                           v-model="item.address"
                           @blur="emitAutosave"
                    />
                </div>
                <div v-if="editable" class="table-cell">
                    <button @click.prevent="showMapModal = true"
                            v-tooltip='$t("Itinerary.general.show_on_map")'
                            class="btn btn-primary btn-sm"
                    >
                        <i class="fa fa-map"></i>
                    </button>
                </div>
                <!--<i class="fa fa-address-book"></i> {{item.address}}-->
            </div>
            <div class="col-12" v-tooltip='$t("Itinerary.TripSleep.tooltip.phone")'>
                <div class="table-cell icon-left">
                    <i class="fa fa-phone"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    <input :readonly="!editable"
                           class="bullet-item mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripSleep.tooltip.phone")'
                           v-model="item.phone"
                           @blur="emitAutosave"
                    />
                </div>
                <!--Phone: {{item.phone}}-->
            </div>
            <div class="col-12" style="padding-top: 5px;">
                <div class="table-cell icon-left" v-tooltip='$t("Itinerary.general.open_website")'>
                    <a :href="item.website" target="_blank"><i class="fa fa-mail-bulk"></i></a>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;" v-tooltip='$t("Itinerary.general.website")'>
                    <input :readonly="!editable"
                           class="bullet-item mor-editable-field"
                           type="url"
                           :placeholder='$t("Itinerary.general.website")'
                           v-model="item.website"
                           @blur="emitAutosave"
                    />
                </div>
                <!--Website: {{item.website}}-->
            </div>
            <div class="col-12" v-tooltip='$t("Itinerary.TripSleep.tooltip.check_in")'>
                <div class="table-cell icon-left">
                    <i class="fa fa-sign-in-alt"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    <input :readonly="!editable"
                           class="bullet-item mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripSleep.tooltip.check_in_time")'
                           v-model="item.checkin"
                           @blur="emitAutosave"
                    />
                </div>
            </div>
            <div class="col-12" v-tooltip='$t("Itinerary.TripSleep.tooltip.check_out")'>
                <div class="table-cell icon-left">
                    <i class="fa fa-sign-out-alt"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    <input :readonly="!editable"
                           class="bullet-item mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripSleep.tooltip.check_out_time")'
                           v-model="item.checkout"
                           @blur="emitAutosave"
                    />
                </div>
            </div>
            <div class="col-12" style="padding-top: 5px;">
                <div class="table-cell" style="white-space: nowrap;">
                    {{$t("Itinerary.TripSleep.tooltip.meal_plan")}}: &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div class="table-cell" style="width:100%;">
                    <multiselect
                            :disabled="!editable"
                            v-model="item.mealPlan"
                            :options="mealPlanOptions"
                            :placeholder='$t("Itinerary.TripSleep.tooltip.select_meal_plan")'
                            :tag-placeholder='$t("Itinerary.TripSleep.tooltip.add_meal_plan")'
                            :multiple="false"
                            :taggable="true"
                            :close-on-select="true"
                            @tag="addMealPlanOption"
                            @close="saveItinerary"
                    ></multiselect>
                </div>
            </div>
            <div class="col-12" style="padding-top: 5px; padding-bottom: 5px;" v-if="!libmode && editable">
                <div class="table-cell">
                    {{$t("Itinerary.TripSleep.tooltip.rooms")}}:
                </div>
                <div class="table-cell pl-2">
                    <input :readonly="!editable"
                           class="bullet-item mor-editable-field"
                           v-tooltip='$t("Itinerary.TripSleep.tooltip.change_number_rooms")'
                           style="width:40px;"
                           type="number"
                           :placeholder='$t("Itinerary.TripSleep.tooltip.number_rooms")'
                           v-model="item.rooms"
                           @change="updateRoomsSize(true)"
                    />
                </div>
                <div v-if="editable" class="table-cell">
                    <button class="btn btn-sm btn-primary" @click="decreaseRooms" :disabled="item.rooms === 1">
                        <i class="fa fa-minus"></i>
                    </button>
                    <button class="btn btn-sm btn-primary ml-1" @click="increaseRooms">
                        <i class="fa fa-plus"></i>
                    </button>
                </div>
            </div>
            <div class="col-12" v-if="!libmode && editable">
                <div v-for="(name, index) in item.roomTypes">
                    <div class="table-cell" style="width:80px;">
                        {{$t("Itinerary.TripSleep.tooltip.room")}} {{index+1}}:
                    </div>
                    <div class="table-cell" style="width:300px;">
                        <multiselect
                                :disabled="!editable"
                                v-model="item.roomTypes[index]"
                                :id="index"
                                :options="roomOptions"
                                :placeholder='$t("Itinerary.TripSleep.tooltip.select_room_type")'
                                :tag-placeholder='$t("Itinerary.TripSleep.tooltip.add_room_type")'
                                :multiple="false"
                                :taggable="true"
                                :close-on-select="true"
                                @tag="addRoomOption"
                                @close="saveItinerary"
                        ></multiselect>

                        <!--<input
                                class="bullet-item mor-editable-field"
                                placeholder="Room type"
                                :ref="'input' + index"
                                v-model="item.roomTypes[index]"
                                @keydown.up.prevent="handleUp(name, index)"
                                @keydown.down.prevent="handleDown(name, index)"
                                @blur="emitAutosave"
                        />-->
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="row" style="margin-top: 10px;">-->
        <draggable class="row" style="margin-top: 10px;" v-model="item.images" :options="{animation:150, disabled: !editable}">
            <div class="col-lg-6 col-md-6 col-sm-12" style="margin-top:10px; margin-bottom: 10px;"
                 v-for="image in item.images"
                 :key="image.id"
            >
                <button v-if="editable"
                        class="btn btn-danger img-delete-btn d-print-none"
                        v-tooltip="'Remove Image'"
                        @click="deleteImage(image)"
                >
                    <i class="fa fa-trash"></i>
                </button>
                <img class="img-fluid w-100" :src="getImageUrl(image)">
                <div class="mor-image-caption">
                    <input :readonly="!editable"
                           type="text"
                           v-model="image.caption"
                           class="mor-image-caption-field"
                           :placeholder='$t("Itinerary.general.image_caption")'
                           @blur="updateImageCaption(image)"
                           @keyup.enter="updateImageCaption(image)"
                    />
                </div>
            </div>
        </draggable>
        <div v-if="editable" class="row d-print-none" style="margin-top: 10px;">
            <div class="col-12">
                <button style="margin-top: 10px;" class="btn btn-primary float-right" @click="showImageUpload = true">
                    {{$t("Itinerary.general.add_images")}}
                </button>
            </div>
        </div>
        <hr/>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12">
                <h4>{{$t("Itinerary.TripSleep.tooltip.alternatives")}}</h4>
            </div>
            <div class="col-12" v-if="item.alternatives && item.alternatives.length > 0">
                <div v-for="(alt, index) in item.alternatives"
                    :key="alt.id"
                    class="card mb-2"
                >
                    <div class="card-header d-flex justify-content-between">
                        <a href="#" @click.prevent="showAlternative(alt)">{{alt.name}}</a>
                        <el-switch
                                :disabled="!editable"
                                class="pr-2"
                                v-model="alt.showOnPdf"
                                :active-text='$t("Itinerary.TripSleep.tooltip.alternative_show_on_pdf")'
                                @change="emitAutosave"
                        ></el-switch>
                    </div>
                    <div class="card-body" v-if="selectedAlt && selectedAlt.id === alt.id">
                        <div class="col-12">
                            <trip-sleep-alternative
                                    :item.sync="selectedAlt"
                                    :editable="editable"
                                    @delete-item="removeAlternative(index)"
                                    @set-main="setAlternativeMain(alt, index)"
                            >
                            </trip-sleep-alternative>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="editable" class="row d-print-none" style="margin-top: 10px;">
            <div class="col-12">
                <button style="margin-top: 10px;"
                        class="btn btn-primary float-right"
                        @click="showAddAlternative = true">
                    {{$t("Itinerary.TripSleep.tooltip.add_alternatives")}}
                </button>
            </div>
        </div>
        <image-upload-modal
                :show.sync="showImageUpload"
                :images.sync="item.images"
                :item-tags="item.tags"
        >
        </image-upload-modal>
        <places-autocomplete-modal :show.sync="showAutocomplete" @update-data="updateItemDataFromPlaces($event)"></places-autocomplete-modal>
        <item-map-modal :show.sync="showMapModal" :item="item" @update-location="updateItemAddress($event)"></item-map-modal>
        <sleep-add-alternative-modal :show.sync="showAddAlternative" @add="addAlternative($event)"></sleep-add-alternative-modal>
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
        >
        </save-to-library-modal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import ImageUploadModal from '../modal/ImageUploadModal';
    import PlacesAutocompleteModal from '../modal/PlacesAutocompleteModal';
    import SleepAddAlternativeModal from '../modal/SleepAddAlternativeModal';
    import TripSleepAlternative from './TripSleepAlternative';
    import ItemMapModal from '../modal/ItemMapModal';
    import {handleAjaxError, showCustomDialog, showSuccess, copyData} from "../../../utils";
    import {autosaveMixin, imageUrlMixin, itemCommonPropsMixin, stringUtilsMixin} from "./item-mixins";
    import { itineraryEventBus } from '../../../event-bus';
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";

    export default {
        name: "TripSleep",
        components: {
            draggable,
            ImageUploadModal,
            PlacesAutocompleteModal,
            ItemMapModal,
            SleepAddAlternativeModal,
            TripSleepAlternative,
            SaveToLibraryModal,
        },
        mixins: [
            autosaveMixin,
            imageUrlMixin,
            stringUtilsMixin,
            itemCommonPropsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            sections: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                showSetNameModal: false,
                showImageUpload: false,
                showAutocomplete: false,
                showMapModal: false,
                showAddAlternative: false,

                roomOptions: [
                    "Single",
                    "Twin",
                    "Double",
                    "Suite",
                ],
                hotelTypeOptions: [
                    "Hotel",
                    "B&B",
                    "Hostel",
                    "Winery",
                    "Apartment",
                    "Private Villa",
                ],
                mealPlanOptions: [
                    "Self Catering (SC)",
                    "Breakfast",
                    "Half Board (HB)",
                    "Full Board (FB)",
                    "All Inclusive (AI)",
                ],

                selectedAlt: null,
            }
        },
        computed: {
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library")
                    ;
            },
            showStars() {
                return this.item.hotelType &&
                    (this.item.hotelType === "Hotel" || this.item.hotelType === "B&B");
            }
        },
        mounted() {
            if (this.item.roomTypes !== undefined) {
                const self = this;
                this.item.roomTypes.forEach((roomType) => {
                    if (roomType !== null &&
                        roomType !== "" &&
                        !self.roomOptions.includes(roomType)
                    ) {
                        self.roomOptions.push(roomType);
                    }
                });
            }
            if (this.item.hotelType !== undefined &&
                this.item.hotelType !== null &&
                this.item.hotelType !== ""
            ) {
                if (!this.hotelTypeOptions.includes(this.item.hotelType)) {
                    this.hotelTypeOptions.push(this.item.hotelType);
                }
            }
            if (this.item.mealPlan !== undefined &&
                this.item.mealPlan !== null &&
                this.item.mealPlan !== ""
            ) {
                if (!this.mealPlanOptions.includes(this.item.mealPlan)) {
                    this.mealPlanOptions.push(this.item.mealPlan);
                }
            }
            this.updateRoomsSize(false);
            const self = this;
            itineraryEventBus.$on("forceupdate", () => {
                self.$nextTick(() => {
                    self.updateRoomsSize(false);
                });
            });
            //TODO verificar para cada imagem sem caption, se caption foi actualizada na lib
        },
        beforeDestroy() {
            itineraryEventBus.$off("forceupdate");
        },
        methods: {
            openChangeDates() {
                itineraryEventBus.$emit("open-changedates-modal", this.item);
            },
            updateItemDataFromPlaces(data) {
                this.item.name = data.name;
                this.item.address = data.address;
                this.item.phone = data.phone;
                this.item.website = data.website;
                this.item.coordinates = data.coordinates;
                this.item.googleId = data.googleId;
                this.item.googlePlacesId = data.googlePlacesId;
                this.item.googleRating = data.googleRating;
                this.item.images = data.images;
                this.item.tags = data.tags;
                this.$emit("update:item", this.item);
                this.emitAutosave();
            },
            updateItemAddress(data) {
                this.item.address = data.address;
                this.item.coordinates.lat = data.lat;
                this.item.coordinates.lng = data.lng;
                this.$emit("update:item", this.item);
                this.emitAutosave();
            },
            /*saveLibraryItem() {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.saveLibraryItem().url;

                let dataObj = copyData(this.item);
                // clean the itinerary specific items
                dataObj.startDate = null;
                dataObj.endDate = null;
                dataObj.rooms = null;
                dataObj.roomType = null;
                dataObj.alternatives = [];
                dataObj.libraryName = dataObj.name;

                // this.loading = true;
                this.$http.post(url, dataObj)
                    .then(function (response) {
                        // self.loading = false;
                        self.$set(self.item, "libraryId", response.data.libraryId);
                        self.$emit("update:item", self.item);
                        self.emitAutosave();
                        showSuccess("Item saved to library");
                    })
                    .catch(function (error) {
                        // self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },*/
            decreaseRooms() {
                this.item.rooms--;
                this.updateRoomsSize(true);
            },
            increaseRooms() {
                this.item.rooms++;
                this.updateRoomsSize(true);
            },
            updateRoomsSize(autoSave) {
                if (autoSave) {
                    this.emitAutosave();
                }
                if (this.item.rooms <= 0) {
                    this.item.rooms = 1;
                }
                if (this.item.roomTypes === undefined) {
                    this.item.roomTypes = [];
                }
                if (this.item.roomTypes.length > this.item.rooms) {
                    while (this.item.roomTypes.length > this.item.rooms) {
                        this.item.roomTypes.pop();
                    }
                } else {
                    while (this.item.roomTypes.length < this.item.rooms) {
                        this.item.roomTypes.push(null);
                    }
                }
            },
            /*handleUp(bullet, index) {
                if (index > 0) {
                    this.$refs["input" + (index-1)][0].focus();
                }
            },
            handleDown(bullet, index) {
                if (index < this.item.roomTypes.length-1) {
                    this.$refs["input" + (index+1)][0].focus();
                }
            },*/
            addRoomOption(newOption, id) {
                this.roomOptions.push(newOption);
                this.item.roomTypes[id] = newOption;
                this.saveItinerary();
            },
            addHotelTypeOption(newOption) {
                this.hotelTypeOptions.push(newOption);
                this.item.hotelType = newOption;
                this.saveItinerary();
            },
            addMealPlanOption(newOption) {
                this.mealPlanOptions.push(newOption);
                this.item.mealPlan = newOption;
                this.saveItinerary();
            },
            saveItinerary() {
                console.log("### SLEEP SAVE ITINERARY");
                this.$nextTick(() => {
                    this.emitAutosave();
                })
            },
            addAlternative(item) {
                if (!this.item.alternatives) {
                    this.item.alternatives = [];
                }
                item.rooms = this.item.rooms;
                for ( let typeR in this.item.roomTypes) {
                    item.roomTypes.push( this.item.roomTypes[typeR] );
                }
                this.item.alternatives.push(item);
                this.emitAutosave();
            },
            removeAlternative(index) {
                this.item.alternatives.splice(index, 1);
                this.$emit("update:item", this.item);
                this.emitAutosave();
            },
            showAlternative(alt) {
                if (this.selectedAlt && this.selectedAlt.id === alt.id) {
                    this.selectedAlt = null;
                } else {
                    this.selectedAlt = alt;
                }
            },
            setAlternativeMain(alt, index) {
                const self = this;
                // cria copia do main anterior
                const mainCopy = copyData(this.item);

                // copia dados do seleccionado para o main
                const altCopy = copyData(alt);
                this.item.libraryId = altCopy.libraryId;
                this.item.id = altCopy.id;
                this.item.name = altCopy.name;
                this.item.description = altCopy.description;
                this.item.stars = altCopy.stars;
                this.item.address = altCopy.address;
                this.item.coordinates = altCopy.coordinates;
                this.item.phone = altCopy.phone;
                this.item.email = altCopy.email;
                this.item.website = altCopy.website;
                this.item.googleId = altCopy.googleId;
                this.item.googlePlacesId = altCopy.googlePlacesId;
                this.item.hotelType = altCopy.hotelType;
                this.item.mealPlan = altCopy.mealPlan;
                this.item.checkin = altCopy.checkin;
                this.item.checkout = altCopy.checkout;
                this.item.rooms = altCopy.rooms;
                this.item.roomTypes = altCopy.roomTypes;
                this.item.roomImages = altCopy.roomImages;
                this.item.images = altCopy.images;

                // remove o seleccionado das alternativas
                this.item.alternatives.splice(index, 1);

                // passa main anterior para as alternativas
                mainCopy.alternatives = null;
                mainCopy.type = null;
                mainCopy.startDate = null;
                mainCopy.endDate = null;
                this.item.alternatives.unshift(mainCopy);

                // actualiza parentId dos childs
                this.sections.forEach(section => {
                    section.items.forEach(item => {
                        if (item.parentId && item.parentId === mainCopy.id) {
                            item.parentId = self.item.id;
                        }
                    })
                });
                this.emitAutosave();
            }
        },
    }
</script>

<style scoped>
    .icon-left {
        width: 25px;
        text-align: center;
    }
</style>
