<template>

    <div class="card mt-3">
        <div class="card-header" v-if="!itData.readOnly">
									<p>{{$t("Itinerary.builder.documents.tab_help")}}</p>
            <button class="btn btn-primary" @click="showUploadModal">
                <span class="fa fa-plus-circle"></span>&nbsp;
                {{$t("Itinerary.builder.documents.upload_document")}}
            </button>
        </div>

        <div class="card-body">

            <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover">
                    <thead>
                    <tr>
                        <th>{{$t("Itinerary.builder.documents.filename")}}</th>
                        <th>{{$t("Itinerary.builder.documents.description")}}</th>
                        <th v-if="!itData.readOnly"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="doc in documentsList">
                        <td>
                            <input v-if="doc.edit" type="text" class="form-control"
                                   :class="{ 'is-invalid': editObject.filenameError }" v-model="editObject.filename"/>
                            <label v-else><a :href="documentDownloadUrl(doc)" class="btn btn-link">{{ doc.filename }}</a></label>
                        </td>
                        <td>
                            <input v-if="doc.edit" type="text" class="form-control" v-model="editObject.description"/>
                            <label v-else>{{ doc.description }}</label>
                        </td>
                        <td class="text-right" v-if="!itData.readOnly">

                            <button v-show="!doc.edit" type="button" class="btn btn-outline-primary btn-sm"
                                    style="margin-right: 5px;"
                                    v-on:click="editItem(doc)"><i class="fas fa-edit"></i></button>
                            <button v-show="!doc.edit" type="button" class="btn btn-outline-danger btn-sm"
                                    v-on:click="deleteDocument(doc)"><i class="fas fa-trash"></i></button>

                            <button v-show="doc.edit" type="button" class="btn btn-secondary btn-sm"
                                    v-on:click="cancelEdit(doc)"
                                    style="margin-right: 5px;"><i
                                    class="fas fa-times"></i></button>
                            <button v-show="doc.edit" type="button" class="btn btn-primary btn-sm"
                                    v-on:click="doEditItineraryDocument(doc)"><i
                                    class="fas fa-save"></i></button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

        <upload-document-modal :show.sync="showModal" :documents.sync="documentsList"></upload-document-modal>

    </div>
</template>

<script>
    import UploadDocumentModal from './modal/UploadDocumentModal'
    import {handleAjaxError, showSuccess, showError, showCustomDialog} from "../../utils";

    export default {
        name: "ItineraryDocuments",
        components: {UploadDocumentModal},
        data() {
            return {
                showModal: false,
                documentsList: [],
                editMode: false,

                editObject: {
                    filename: '',
                    description: '',
                    filenameError: false
                }
            }
        },
        mounted() {
            this.loadDocuments();
        },
        computed: {
            itData() {
                return this.$store.getters.itData;
            },
        },
        methods: {

            loadDocuments() {
                let self = this
                let url = jsRoutes.controllers.ItineraryController.listItineraryDocument(itinId).url;
                this.$http.get(url).then(response => {
                    self.documentsList = response.data.filter(({ type }) => type === 'DOCUMENT')
                }, error => {
                    handleAjaxError(error)
                })
            },

            showUploadModal() {
                this.showModal = true
            },
            hideUploadModal() {
                this.showModal = false
            },

            editItem(doc) {
                if (this.editMode) {
                    return
                }
                this.editObject = {
                    filename: doc.filename,
                    description: doc.description,
                    filenameError: false
                }
                doc.edit = true
                this.editMode = true
            },

            doEditItineraryDocument(doc) {
                let self = this
                let filename = this.editObject.filename
                if (filename === '') {
                    this.editObject.filenameError = true
                    showError(
                        self.$t("error.general_title"),
                        self.$t("Itinerary.builder.documents.filename_mandatory")
                    )
                    return
                }

                doc.filename = filename
                doc.description = this.editObject.description
                let url = jsRoutes.controllers.ItineraryController.editItineraryDocument().url;
                this.$http.post(url, doc).then(response => {
                    self.cancelEdit(doc)
                    showSuccess(
                        self.$t("success.general_title"),
                        self.$t("Itinerary.builder.documents.filename_edited")
                    );
                }, error => {
                    handleAjaxError(error)
                })
            },

            cancelEdit(doc) {
                doc.edit = false
                this.editMode = false
                this.editObject = {
                    filename: '',
                    description: '',
                    filenameError: false
                }
            },

            deleteDocument(doc) {
                let self = this
                showCustomDialog(
                    self.$t("Itinerary.builder.documents.are_you_sure"),
                    self.$t("Itinerary.builder.documents.are_you_sure_delete") + " " + doc.filename +'?',
                    self.$t("Itinerary.builder.documents.yes"),
                    'btn-danger', () => {
                    let url = jsRoutes.controllers.ItineraryController.deleteItineraryDocument(doc.id).url;
                    self.$http.get(url).then(response => {
                        showSuccess(
                            self.$t("success.general_title"),
                            self.$t("Itinerary.builder.documents.file_was_deleted")
                        );
                        self.loadDocuments()
                    }, error => {
                        handleAjaxError(error)
                    })
                })
            },

            documentDownloadUrl(doc){
                return jsRoutes.controllers.ItineraryController.downloadItineraryDocument(doc.id).url;
            }
        }
    }
</script>

<style scoped>

</style>
