<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <button class="btn btn-primary"
                        v-tooltip='$t("Itinerary.TripCarRental.tooltip.change_rental_dates")'
                        @click="openChangeDates"
                >
                    <i class="fa fa-calendar-alt"></i>
                </button>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
                <button class="btn btn-danger" @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'><i class="fa fa-trash"></i>
                </button>
                <br/>
            </div>
<!--
            <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                <i class="fa fa-arrows-alt"></i>
            </button>
-->
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <div class="table-cell">
                        <i class="fa fa-car-alt"></i>
                    </div>
                    <div class="table-cell" style="padding-left: 10px;">
                        <input :readonly="!editable" type="text" class="name-input" v-model="item.name" @blur="emitAutosave"/>
                    </div>
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="table-cell company-field">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           v-model="item.companyName"
                           v-tooltip='$t("Itinerary.TripCarRental.tooltip.company")'
                           :placeholder='$t("Itinerary.TripCarRental.tooltip.company")'
                           @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell location-field">
                    <div style="text-align: center;padding-right:35px;">
                        {{itemStartDate}}
                    </div>
                    <div class="d-flex align-items-center">
                        <input :readonly="!editable"
                               class="mor-editable-field"
                               style="text-align: center;"
                               type="text"
                               :placeholder='$t("Itinerary.TripCarRental.tooltip.pickup_location")'
                               v-model="item.startLocation.city"
                               v-tooltip='$t("Itinerary.TripCarRental.tooltip.pickup_location")'
                               @blur="emitAutosave"
                        />
                        <button v-if="editable" @click.prevent="openMapModal(item.startLocation)"
                                v-tooltip="mapBtnTooltip(item.startLocation)"
                                class="btn btn-primary btn-sm"
                                style="width:35px;"
                        >
                            <i class="fa fa-map"></i>
                        </button>
                    </div>
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           style="text-align: center;padding-right:35px;"
                           type="text"
                           :placeholder='$t("Itinerary.TripCarRental.tooltip.pickup_time")'
                           v-model="item.startTime"
                           @blur="emitAutosave"
                    />
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           style="text-align: center;padding-right:35px;"
                           type="text"
                           :placeholder='$t("Itinerary.TripCarRental.tooltip.phone")'
                           v-model="item.startLocation.phone"
                           @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell location-field" style="vertical-align:middle;text-align: center;width:100px;">
                    <i class="fa fa-arrow-right fa-2x"></i>
                </div>
                <div class="table-cell location-field">
                    <div style="text-align: center;padding-right:35px;">
                        {{itemEndDate}}
                    </div>
                    <div class="d-flex align-items-center">
                        <input :readonly="!editable"
                               class="mor-editable-field"
                               style="text-align: center;"
                               type="text"
                               :placeholder='$t("Itinerary.TripCarRental.tooltip.dropoff_location")'
                               v-model="item.endLocation.city"
                               v-tooltip='$t("Itinerary.TripCarRental.tooltip.dropoff_location")'
                               @blur="emitAutosave"
                        />
                        <button v-if="editable" @click.prevent="openMapModal(item.endLocation)"
                                v-tooltip="mapBtnTooltip(item.endLocation)"
                                class="btn btn-primary btn-sm"
                                style="width:35px;"
                        >
                            <i class="fa fa-map"></i>
                        </button>
                    </div>
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           style="text-align: center;padding-right:35px;"
                           type="text"
                           :placeholder='$t("Itinerary.TripCarRental.tooltip.dropoff_time")'
                           v-model="item.endTime"
                           @blur="emitAutosave"
                    />
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           style="text-align: center;padding-right:35px;"
                           type="text"
                           :placeholder='$t("Itinerary.TripCarRental.tooltip.phone")'
                           v-model="item.endLocation.phone"
                           @blur="emitAutosave"
                    />
                </div>
            </div>
            <div class="col-12" style="margin-top: 10px;display:table; position: relative; z-index: 1;">
                <div class="table-cell company-field" style="height: 150px;">
                    <picture-input
                            ref="pictureInput"
                            accept="image/jpeg,image/png"
                            size="10"
                            button-class="btn btn-primary btn-sm d-print-none"
                            remove-button-class="btn btn-danger btn-sm d-print-none"
                            :crop="false"
                            :prefill="imageUrl"
                            :prefill-options="{
                                mediaType: 'image/jpeg',
                            }"
                            :removable="true"
                            :custom-strings='customStrings'
                            @change="onPictureChange"
                            @remove="onPictureRemove"
                    >
                    </picture-input>
                </div>
                <div class="table-cell" style="padding-left: 10px;">
                    <!--TODO SOLD APENAS-->
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripCarRental.tooltip.reference_number")'
                           v-model="item.referenceNumber"
                           v-tooltip='$t("Itinerary.TripCarRental.tooltip.reference_number")'
                           @blur="emitAutosave"
                    />
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripCarRental.tooltip.car_model")'
                           v-model="item.car"
                           v-tooltip='$t("Itinerary.TripCarRental.tooltip.car_model")'
                           @blur="emitAutosave"
                    />
                    <textarea-autosize
                            v-if="editable"
                            style="margin-top: 5px;"
                            class="form-control body-font-size"
                            :placeholder='$t("Itinerary.TripCarRental.tooltip.details")'
                            v-model="item.details"
                            :min-height="30"
                            :max-height="500"
                            @blur.native="emitAutosave"
                    ></textarea-autosize>
                    <span v-else v-html="cleanupString(item.details)"></span>

                    <!--<textarea style="margin-top: 5px;"
                            class="form-control"
                            placeholder="Details"
                            v-model="item.details"
                            @blur="emitAutosave"
                    >

                    </textarea>-->
                </div>
            </div>
        </div>
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
        >
        </save-to-library-modal>
        <transportation-map-modal
                :show.sync="showMapModal"
                :location.sync="mapModalLocation"
        >
        </transportation-map-modal>
    </div>
</template>

<script>
    import PictureInput from 'vue-picture-input'
    import axios from 'axios';
    import {autosaveMixin, itemCommonPropsMixin, stringUtilsMixin} from "./item-mixins";
    import { itineraryEventBus } from '../../../event-bus';
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";
    import TransportationMapModal from "../modal/TransportationMapModal";
    import {handleAjaxError} from "../../../utils";
    import {formatMoment, getDateMomentForDayNum} from "../../../date-utils";

    export default {
        components: {
            TransportationMapModal,
            PictureInput,
            SaveToLibraryModal,
        },
        mixins: [
            autosaveMixin,
            stringUtilsMixin,
            itemCommonPropsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showSetNameModal: false,

                showMapModal: false,
                mapModalLocation: null,
            }
        },
        computed: {
            itemStartDate() {
                if (!this.libmode && this.$store) {
                    const itData = this.$store.getters.itData;

                    if (itData.startDate && itData.endDate) {
                        return formatMoment(
                            getDateMomentForDayNum(this.item.startDay, itData.startDate, itData.dateFormat),
                            itData.dateFormat
                        );
                    } else {
                        return this.$t('v2.Itinerary.defaults.DAY') + " " + this.item.startDay;
                    }
                } else {
                    return "";
                }
            },
            itemEndDate() {
                if (!this.libmode && this.$store) {
                    const itData = this.$store.getters.itData;

                    if (itData.startDate && itData.endDate) {
                        return formatMoment(
                            getDateMomentForDayNum(this.item.endDay, itData.startDate, itData.dateFormat),
                            itData.dateFormat
                        );
                    } else {
                        return this.$t('v2.Itinerary.defaults.DAY') + " " + this.item.endDay;
                    }
                } else {
                    return "";
                }
            },
            customStrings() {
                return {
                    upload: '<h1>Bummer!</h1>', //TODO lang file
                    drag: '<i class=\'fa fa-plus-circle\'></i><br/>' + this.$t("Itinerary.general.click_or_drag")
                };
            },
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library")
                    ;
            },
            imageUrl() {
                if (this.item.images[0].type !== null) {
                    if (this.item.images[0].type === "LOCAL") {
                        // var quality esta na view twirl
                        // eslint-disable-next-line
                        return jsRoutes.controllers.ItineraryController.imageDownload(this.item.images[0].id, quality).url
                    }
                } else {
                    return null;
                    //return "/assets/images/car-icon.png";
                }
            }
        },
        methods: {
            openMapModal(itemLocation) {
                this.mapModalLocation = itemLocation;
                this.showMapModal = true;
            },
            mapBtnTooltip(location) {
                if (location.lat !== null && location.lng !== null) {
                    return location.address + " ("+ this.$t("Itinerary.general.show_on_map") +")"
                } else {
                    return this.$t("Itinerary.TripCarRental.tooltip.set_addres_on_map");
                }
            },
            openChangeDates() {
                itineraryEventBus.$emit("open-changedates-modal", this.item);
            },
            onPictureChange() {
                if (this.$refs.pictureInput.image) {
                    const self = this;
                    // eslint-disable-next-line
                    const url = jsRoutes.controllers.ItineraryController.imageUpload().url;

                    const formData = new FormData();
                    formData.append("file", this.$refs.pictureInput.file);
                    axios({
                        method: 'post',
                        url: url,
                        data: formData,
                        config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                        .then(function (response) {
                            self.item.images[0].id = response.data.id;
                            self.item.images[0].type = "LOCAL";
                            self.emitAutosave();
                        })
                        .catch(function (error) {
                            handleAjaxError(error)
                            console.error("car rental picture change, something went wront.... => " + JSON.stringify(error.response));
                        });

                }
            },
            onPictureRemove() {
                console.log('Picture remove.');
                //TODO remover server side
                this.item.images[0].id = null;
                this.item.images[0].type = null;
                this.item.images[0].url = null;
                this.emitAutosave();
            },
        }
    }
</script>

<style scoped>
    .company-field {
        font-size:1.5em;
        width:200px;
        vertical-align: middle;
        border: 1px solid lightgray;
        border-radius: 5px;
    }

    .location-field {
        text-align: center;
        font-size:1.2em;
        vertical-align: middle;
        /*border: 1px solid lightgray;*/
        /*border-radius: 5px;*/
    }


</style>
