<template>
    <div class="trip-cover-print">
        <div class="row">
            <div class="col-12 text-center" style="padding:0;">
                <img class="img-fluid w-100" :src="getImageUrl(item.image)"/>
            </div>
        </div>
        <!--<div style="position:absolute;top:0;left:0;width:300px;">
            <img class="img-fluid" :src="agencyImageUrl"/>
        </div>-->
        <div class="row align-items-center">
            <div class="col-12 text-center">
                <br/>
                <br/>
                <br/>
                <h1>
                    {{item.title}}
                    <!--<input type="text" class="name-input text-center"
                           v-model="item.title"
                           placeholder="Tour name/title"
                           ref="title"
                           @keydown.down="$refs.places.focus()"
                           v-tooltip="'Click to edit'"
                           @blur="emitAutosave"

                    />-->
                </h1>
            </div>
            <div class="col-12 text-center">
                <h2>
                    {{startDate}} - {{endDate}}
                </h2>
            </div>
            <div class="col-12 text-center">
                <h2>
                    {{item.placesToVisit}}
                    <!--<input type="text" class="name-input text-center"
                           v-model="item.placesToVisit"
                           placeholder="Places/countries to visit"
                           ref="places"
                           @keydown.up="$refs.title.focus()"
                           v-tooltip="'Click to edit'"
                           @blur="emitAutosave"
                    />-->
                </h2>
            </div>
            <div class="col-12 text-center" style="margin-top:100px;">
                <a :href="agencyData.website" target="_blank">
                    <img class="img-fluid" style="max-height: 100px;" :src="agencyImageUrl"/>
                </a>
            </div>

            <div class="col-12 text-center">

                <a :href="agencyData.facebookLink" class="btn btn-link" v-if="agencyData.facebookLink" target="_blank">
                    <i class="fab fa-facebook-square fa-2x"></i>
                </a>

                <a :href="agencyData.youtubeLink" class="btn btn-link" v-if="agencyData.youtubeLink" target="_blank">
                    <i class="fab fa-youtube fa-2x"></i>
                </a>

                <a :href="agencyData.instagramLink" class="btn btn-link" v-if="agencyData.instagramLink" target="_blank">
                    <i class="fab fa-instagram fa-2x"></i>
                </a>

                <a :href="agencyData.twitterLink" class="btn btn-link" v-if="agencyData.twitterLink" target="_blank">
                    <i class="fab fa-twitter-square fa-2x"></i>
                </a>

            </div>

            <div class="col-12 text-center">
                <h4>{{agencyData.email}}</h4>
            </div>
            <div class="col-12 text-center">
                <h4>{{agencyData.phone}}</h4>
            </div>
        </div>
    </div>
</template>

<script>

    import {showError} from "../../../../utils";

    export default {
        components: {
        },
        mixins: [
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            startDate: {
                type: String,
                required: true,
            },
            endDate: {
                type: String,
                required: true,
            },
            itinId: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                agencyData: {
                    imageFilename: null,
                    imageFile: null,
                    email: null,
                    phone: null,
                    address: null,
                },

                showImageModal: false,
            }
        },
        mounted() {
            this.loadAgencyData();
        },
        computed: {
            agencyImageUrl() {
                if (this.agencyData.imageFilename !== null) {
                    return jsRoutes.controllers.Application.agencyImage(this.agencyData.imageFilename).url
                } else {
                    return null;
                }
            },
        },
        methods: {
            getImageUrl(image) {
                if (image.id && image.type) {
                    if (image.type === 'GOOGLE') {
                        return image.url;
                    } else if (image.type === 'UNSPLASH') {
                        return image.data.urls.raw + "&w=800&h=600&fit=crop&crop=edges";
                    } else if (image.type === 'LOCAL' || image.type === 'PIXABAY') {
                        // var quality esta na view twirl
                        return jsRoutes.controllers.ItineraryController.imageDownload(image.id, quality).url;
                    }
                } else {
                    return "/assets/images/default-cover.jpg";
                }
            },
            loadAgencyData() {
                const self = this;
                const url = jsRoutes.controllers.ItineraryController.loadAgencyConfiguration(this.itinId).url;

                this.$http.get(url).then(function (response) {
                    //console.log("roles -> " + JSON.stringify(data));
                    self.agencyData = response.data;

                }).catch(function (response) {
                    // handle error
                    showError("Error", "Failed loading company info");
                });
            },
        }
    }
</script>

<style scoped>
    .trip-cover-print {
        /*max-width: 794px;*/
        max-width: 1240px;
        /*height: 1123px;*/
        height: 1350px;
        /*height: 1754px;*/
        /*position: relative;*/
        /*page-break-after: always;*/
        /*border-bottom: 2px solid green;*/
        /*border: 2px solid green;*/
        /*margin-top: -37px;
        margin-bottom: -75px;
        margin-left: -26px;
        margin-right: -26px;*/
    }
</style>
