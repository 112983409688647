<template>
    <div>
        <div class="row">
            <div class="col-8">
                <h3>
                    <div class="table-cell">
                        <i class="fa fa-utensils"></i>
                    </div>
                    <div class="table-cell" style="width:100%;padding-left:10px;">
                        <input type="text" class="name-input" v-model="item.title" :placeholder='$t("Itinerary.TripMeal.tooltip.title")'/>
                        <input type="text" class="name-input" v-model="item.name" :placeholder='$t("Itinerary.TripMeal.tooltip.name")'/>
                    </div>
                </h3>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12" v-if="item.googleRating !== undefined && item.googleRating !== null">
                <div class="table-cell">
                    {{$t("Itinerary.TripMeal.tooltip.google_rating")}}:
                </div>
                <div class="table-cell">
                    <star-rating v-model="item.googleRating"
                                 :show-rating="true"
                                 :star-size="15"
                                 :read-only="true"
                                 :increment="0.1"
                    ></star-rating>
                </div>
            </div>
            <div class="col-12" style="padding-top: 5px;"
                 v-if="stringNotEmpty(item.description)"
                 v-html="cleanupString(item.description)"
            >
                <!--<textarea-autosize
                        class="form-control body-font-size"
                        style="border:0;padding:0;color:black;"
                        :placeholder='$t("Itinerary.TripMeal.tooltip.description")'
                        v-model="item.description"
                        :min-height="30"
                        :max-height="500"
                ></textarea-autosize>-->

                <!--<textarea class="form-control" placeholder="Description" v-model="item.description"></textarea>-->
            </div>
            <div class="col-12" style="padding-top: 5px;">
                <div class="table-cell" v-tooltip='$t("Itinerary.general.show_on_map")'>
                    <a href="#" @click.prevent="showMapModal = true"><i class="fa fa-globe"></i></a>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;" v-tooltip='$t("Itinerary.TripMeal.tooltip.address")'>
                    <input class="bullet-item mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripMeal.tooltip.address")'
                           v-model="item.address"
                    />
                </div>
            </div>
            <div class="col-12"  v-if="stringNotEmpty(item.phone)">
                <div class="table-cell">
                    <i class="fa fa-phone"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    <input class="bullet-item mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripMeal.tooltip.phone")'
                           v-model="item.phone"
                    />
                </div>
                <!--Phone: {{item.phone}}-->
            </div>
            <div class="col-12" style="padding-top: 5px;" v-if="stringNotEmpty(item.website)">
                <div class="table-cell" v-tooltip='$t("Itinerary.TripMeal.tooltip.open_web")'>
                    <a :href="item.website" target="_blank"><i class="fa fa-mail-bulk"></i></a>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;" v-tooltip='$t("Itinerary.TripMeal.tooltip.website")'>
                    <a :href="item.website" target="_blank">{{item.website}}</a>
                    <!--<input class="bullet-item mor-editable-field"
                           type="url"
                           :placeholder='$t("Itinerary.TripMeal.tooltip.website")'
                           v-model="item.website"
                    />-->
                </div>
                <!--Website: {{item.website}}-->
            </div>
        </div>
        <!--<div class="row" style="margin-top: 10px;">-->
        <div class="row photos-row" v-if="item.images.length > 1">
            <div class="col-6 photos-col"
                 v-for="image in item.images.slice(0,2)"
                 :key="image.id">
                <img class="img-fluid w-100" :src="getImageUrl(image)">
                <div class="mor-image-caption" v-if="stringNotEmpty(image.caption)">
                    <input type="text"
                           v-model="image.caption"
                           class="mor-image-caption-field"
                    />
                </div>
            </div>
        </div>
        <div class="row photos-row justify-content-center page-break-inside-avoid" v-if="item.images.length === 1">
            <div class="col-6 photos-col">
                <img class="img-fluid w-100" :src="getImageUrl(item.images[0])">
                <div class="mor-image-caption" v-if="stringNotEmpty(item.images[0].caption)">
                    <input type="text"
                           v-model="item.images[0].caption"
                           class="mor-image-caption-field"
                    />
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;" v-if="item.alternatives && item.alternatives.length > 0">
            <div class="col-12">
                <h4>{{$t("Itinerary.TripMeal.tooltip.alternatives")}}</h4>
            </div>
            <div class="col-12">
                <div v-for="(alt, index) in item.alternatives"
                     :key="alt.id"
                     class="card mb-2"
                >
                    <div class="card-header">
                        {{alt.name}}
                    </div>
                    <!--<div class="card-body" v-if="selectedAlt && selectedAlt.id === alt.id">
                        <div class="col-12">
                            <trip-meal-alternative
                                    :item.sync="selectedAlt"
                            >
                            </trip-meal-alternative>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {imageUrlMixin,stringUtilsMixin} from "../../item/item-mixins";
    import TripMealAlternative from "../../item/TripMealAlternative";

    export default {
        components: {
            TripMealAlternative,
        },
        mixins: [
            imageUrlMixin,
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
            }
        },
        computed: {
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>
