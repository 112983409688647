<template>
    <div class="row">
        <div class="col-10 mx-auto">
            <div class="card">
                <div class="card-header">
                    <h1>Signup a company</h1>
                </div>
                <div class="card-body">
                    <form v-on:submit.prevent="createAccount" id="signupForm" novalidate>
                        <div class="form-group"  v-bind:class="{ 'has-error': $v.signupData.organization.$error}">
                            <label for="organization" class="control-label">{{$t("root.organization")}} *</label>
                            <input class="form-control" id="organization" :placeholder='$t("root.organization")'
                                   :class="{'is-invalid' : $v.signupData.organization.$error}"
                                   v-model="$v.signupData.organization.$model"/>
                            <span class="invalid-feedback" v-show="$v.signupData.organization.$error && !$v.signupData.organization.required">
                                {{$t("root.required")}}</span>
                            <span class="invalid-feedback" v-show="$v.signupData.organization.$error && !$v.signupData.organization.minLength">
                                {{$t("root.organization_size")}}</span>
                            <span class="invalid-feedback" v-show="$v.signupData.organization.$error && !$v.signupData.organization.maxLength">
                                {{$t("root.organization_max")}}</span>

                        </div>

                        <div class="form-group" :class="{ 'has-error': $v.signupData.firstName.$error }">
                            <label for="firstName" class="control-label">{{$t("root.firstname")}} *</label>
                            <input class="form-control" type="text" name="firstName" id="firstName" placeholder="First Name"
                                   :class="{'is-invalid' : $v.signupData.firstName.$error}"
                                   v-model.trim="$v.signupData.firstName.$model"/>
                            <span class="invalid-feedback" v-show="$v.signupData.firstName.$error && !$v.signupData.firstName.required">
                                {{$t("root.required")}}</span>
                            <span class="invalid-feedback" v-show="$v.signupData.firstName.$error && !$v.signupData.firstName.maxLength">
                                {{$t("root.required")}}</span>
                        </div>

                        <div class="form-group" :class="{ 'has-error': $v.signupData.lastName.$error }">
                            <label for="lastName" class="control-label">{{$t("root.lastname")}} *</label>
                            <input class="form-control" type="text" name="lastName" id="lastName" placeholder="Last Name"
                                   :class="{'is-invalid' : $v.signupData.lastName.$error}"
                                   v-model.trim="$v.signupData.lastName.$model"/>
                            <span class="invalid-feedback" v-show="$v.signupData.lastName.$error && !$v.signupData.lastName.required">
                                {{$t("root.required")}}</span>
                            <span class="invalid-feedback" v-show="$v.signupData.lastName.$error && !$v.signupData.lastName.maxLength">
                                {{$t("root.required")}}</span>
                        </div>

                        <div class="form-group" :class="{ 'has-error': $v.signupData.email.$error }">
                            <label for="email" class="control-label">{{$t("root.email")}} *</label>
                            <input class="form-control" type="email" name="email" id="email" placeholder="Email address"
                                   :class="{'is-invalid' : $v.signupData.email.$error}"
                                   v-model="$v.signupData.email.$model"/>
                            <span class="invalid-feedback" v-show="$v.signupData.email.$error && !$v.signupData.email.required">
                                {{$t("root.required")}}</span>
                            <span class="invalid-feedback" v-show="$v.signupData.email.$error && !$v.signupData.email.email">
                                {{$t("root.email_valid")}}</span>
                        </div>

                        <div class="form-group">
                            <label class="control-label" >Language</label>
                            <select class="form-control" v-model="signupData.languageCode">
                                <option v-for="lang in languageOptions" :value="lang.value">{{lang.label}}</option>
                            </select>
                        </div>

                        <div class="form-group" :class="{ 'has-error': $v.signupData.trialDays.$error }">
                            <label for="days" class="control-label">Trial days *</label>
                            <input class="form-control" type="number" name="days" id="days" placeholder="Number of trial days"
                                   :class="{'is-invalid' : $v.signupData.trialDays.$error}"
                                   v-model.trim="$v.signupData.trialDays.$model"/>
                            <span class="invalid-feedback" v-show="$v.signupData.trialDays.$error && !$v.signupData.trialDays.required">
                                {{$t("root.required")}}</span>
                            <span class="invalid-feedback" v-show="$v.signupData.trialDays.$error && !$v.signupData.trialDays.numeric">
                                Numeric values only</span>
                            <span class="invalid-feedback" v-show="$v.signupData.trialDays.$error && !$v.signupData.trialDays.minValue">
                                values between 1 and 30</span>
                            <span class="invalid-feedback" v-show="$v.signupData.trialDays.$error && !$v.signupData.trialDays.maxValue">
                                values between 1 and 30</span>
                        </div>

                        <!--<div class="form-group" :class="{ 'has-error': $v.signupData.password.$error }">
                            <label for="password" class="control-label">{{$t("root.choose_password")}} *</label>
                            <input class="form-control" type="password" id="password"
                                   :class="{'is-invalid' : $v.signupData.password.$error}"
                                   v-model="$v.signupData.password.$model"/>
                            <span class="invalid-feedback" v-show="$v.signupData.password.$error && !$v.signupData.password.required">
                                {{$t("root.required")}}</span>
                            <span class="invalid-feedback" v-show="$v.signupData.password.$error && !$v.signupData.password.minLength">
                                {{$t("root.choose_password_size")}}</span>
                            <span class="invalid-feedback" v-show="$v.signupData.password.$error && !$v.signupData.password.maxLength">
                                {{$t("root.choose_password_max")}}</span>
                        </div>
                        <div class="form-group" :class="{ 'has-error': $v.signupData.repeatPassword.$error }">
                            <label for="repeatPassword" class="control-label">{{$t("root.confirm_password")}} *</label>
                            <input class="form-control" type="password" id="repeatPassword"
                                   :class="{'is-invalid' : $v.signupData.repeatPassword.$error}"
                                   v-model="$v.signupData.repeatPassword.$model"/>
                            <span class="invalid-feedback" v-show="$v.signupData.repeatPassword.$error && !$v.signupData.repeatPassword.required">
                                {{$t("root.required")}}</span>
                            <span class="invalid-feedback" v-show="$v.signupData.repeatPassword.$error && !$v.signupData.repeatPassword.sameAsPassword">
                                {{$t("root.confirm_password_match")}}</span>
                        </div>-->

                        <div v-if="showError" class="alert alert-danger" v-html="errorMsg"></div>

                        <div class="float-right">
                            <a class="btn btn-link" :href="indexUrl">{{$t("root.cancel")}}</a>
                            <v-button class="btn btn-primary" :loading="loading" :text="loadingText">
                                {{$t("root.create_account")}}
                            </v-button>
                        </div>
                        <br/><br/>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import {required, email, minLength, maxLength, minValue, maxValue, numeric} from "vuelidate/lib/validators";
    import {showSuccessDialog} from "../../utils";
    import VButton from "../../components/VButton";
    import {getLanguageOptions} from "../../language-utils";

    export default {
        components: {
            VButton,
        },
        data() {
            return {
                signupData: {
                    organization: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    trialDays: 5,
                    languageCode: 'en',
                },

                languageOptions: getLanguageOptions(),

                showError: false,
                errorMsg: "",

                loading: false,
                loadingText: "Processing...",
            }
        },
        validations: {
            signupData: {
                organization: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(255)
                },
                firstName: {
                    required,
                    maxLength: maxLength(255)
                },
                lastName: {
                    required,
                    maxLength: maxLength(255)
                },
                email: {
                    required,
                    email,
                },
                trialDays: {
                    required,
                    numeric,
                    minValue: minValue(1),
                    maxValue: maxValue(30)
                }
            }
        },
        computed: {
            indexUrl() {
                return jsRoutes.controllers.Application.index().url;
            }
        },
        methods: {
            createAccount: function () {
                // console.log("### CREATE ACCOUNT");
                this.showError = false;
                this.errorMsg = "";
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.log("### INVALID");
                    return;
                }
                this.doCreateAccount();
            },

            doCreateAccount: function () {
                const self = this;
                this.loading = true;
                const url = jsRoutes.controllers.PlatformAdministrationController.doSignupCompany().url;
                axios.post(url, this.signupData)
                    .then(function (response) {
                        self.loading = false;
                        //window.location.replace(jsRoutes.controllers.Application.index().url);
                        // window.location.href = jsRoutes.controllers.Application.login().url;
                        showSuccessDialog("Success", "Account created, credentials sent by email.", () => {
                            window.location.reload();
                        });
                    })
                    .catch(function (error) {
                        self.loading = false;
                        self.showError = true;
                        self.errorMsg = error.response;
                        if (error.response.status === 500) {
                            self.errorMsg = self.$t("root.something_weng_wrong");
                        } else {
                            self.errorMsg = error.response.data;
                        }
                        //showError("ERROR", response);
                    });
            }
        },

        mounted: function () {
        }

    }
</script>

<style scoped>

</style>