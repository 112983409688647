<template>
    <div class="modal" id="builderSetItemLibrayNameModal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Load Section From Library</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-9">
                            <div class="form-group">
                                <label for="itemName" class="control-label">
                                    {{$t("Itinerary.builder.library.name_address")}}
                                </label>
                                <input type="text" class="form-control" id="itemName" name="itemName"
                                       v-model.trim="filters.name">
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="form-group">
                                <label class="control-label">{{$t("Itinerary.builder.library.tags")}}:</label>
                                <el-select
                                        style="width:100%;"
                                        v-model="filters.tags"
                                        multiple
                                        default-first-option
                                        filterable
                                        :placeholder='$t("Itinerary.builder.library.tag_select")'
                                >
                                    <el-option v-for="tag in tagList"
                                               :key="tag.id"
                                               :label="tag.name"
                                               :value="tag.id"
                                    ></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="col-1">
                            <button   class="btn btn-primary"
                                      style="position:absolute;bottom:0;margin-bottom: 1rem;"
                                      v-on:click="loadList()"

                            >
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-12">
                        <vue-bootstrap-table
                                :columns="cols"
                                :values="list"
                                :show-filter="true"
                                :show-column-picker="true"
                                :sortable="true"
                                :filter-case-sensitive="false"
                                :selectable="false"
                                defaultOrderColumn="name"
                                :defaultOrderDirection="true"
                        >
                            <template v-slot:name="slotProps">
                                <a href="#" v-on:click="handleSelection(slotProps.value)">
                                    {{ getItemName(slotProps.value) }}
                                </a>
                            </template>
                        </vue-bootstrap-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import {handleAjaxError, showSuccess, copyData} from "../../../utils";
    import { required, maxLength } from 'vuelidate/lib/validators';
    import {autosaveMixin} from "../item/item-mixins";
    import VueBootstrapTable from "vue2-bootstrap-table2";

    import {getItemNameOrTitle, ITEM_TYPE_CAR_RENTAL, ITEM_TYPE_SLEEP, SECTION_TYPE_DAY} from "../itinerary-templates";

    export default {
        components: {
            VueBootstrapTable,
        },
        mixins: [
            autosaveMixin,
        ],
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            item: {
                type: Object,
                required: true,
            },
            extraData: {
                type: Object,
                required: false,
                default: function () { return null },
            },
        },
        data() {
            return {
                filters: {
                    name: null,
                    tags: null,
                    type: null,
                    status: 1
                },
                list: [],
                tagList: [],
                cols: [
                    {
                        title: this.$t("Itinerary.builder.library.type"),
                        name: "typeDesc"
                    },
                    {
                        title: this.$t("Itinerary.builder.library.name"),
                        name: "name"
                    },
                    {
                        title: this.$t("Itinerary.builder.library.created_at"),
                        name: "createDate"
                    },
                ],
                name: null,
                loading: false,
            }
        },
        validations: {
            name: {
                required,
                maxLength: maxLength(255)
            },
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function (e) {
                self.$emit('update:show', false);
            })
            this.loadTags();
            this.loadList();
        },
        computed: {},
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
                this.$v.$reset();
            }
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            loadTags() {
                const self = this;
                const url = jsRoutes.controllers.LibraryController.tagListSelect().url;
                this.$http.get(url)
                    .then(function (response) {
                        self.tagList = response.data;
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },
            loadList: function () {
                const self = this;
                this.loading = true;
                const url = jsRoutes.controllers.LibraryController.searchItems().url;

                const dataObj = copyData(this.filters);
                dataObj.type = SECTION_TYPE_DAY;

                // GET request
                axios.post(url, dataObj).then(function (response) {
                    self.loading = false;
                    // set data
                    self.list = response.data;
                }).catch(function (error) {
                    self.loading = false;
                    // handle error
                    handleAjaxError(error);
                });
            },
            handleSelection(item) {
                this.$emit('add-section', item);
                this.close();
            },
            getItemName(item) {
                let result = item.name;
                if (!result) {
                    result = getItemNameOrTitle(item.data);
                }
                return result;
            },
        }
    }
</script>

<style scoped>
</style>
