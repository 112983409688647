<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <div class="table-cell">
                        <i class="fa fa-h-square"></i> ({{$t("Itinerary.TripSleep.tooltip.check_out")}})
                    </div>
                    <div class="table-cell" style="padding-left: 10px;">
                        {{parentItem ? parentItem.name : "" }}
                    </div>
                </h3>
            </div>
            <div class="col-12" v-if="parentItem && stringNotEmpty(parentItem.checkout)">
                {{$t("Itinerary.TripSleep.tooltip.check_out_at")}}: {{parentItem ? parentItem.checkout : ""}}
            </div>
        </div>
    </div>
</template>

<script>
    import {getParentItemById} from "../../itinerary-templates";
    import {stringUtilsMixin} from "../../item/item-mixins";

    export default {
        components: {
        },
        mixins: [
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            sections: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
            }
        },
        computed: {
            parentItem() {
                return getParentItemById(this.sections, this.item.parentId);
            }
        },
        methods: {
            /*getImageUrl(image) {
                // var quality esta na view twirl
                return jsRoutes.controllers.ItineraryController.imageDownload(image, quality).url;
            },*/
        }
    }
</script>

<style scoped>
</style>
