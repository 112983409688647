<template>
    <div>
        <select v-model="selected" class="form-control">
          <option v-bind:key="index" v-for="(lang, index) in languageOptions" :value="lang.value"> {{lang.label}}</option>
        </select>
    </div>
</template>
<script>
    import {getLanguageOptions} from "../../language-utils";
    import {i18n} from "../../main"
    import VueCookies from 'vue-cookies'

    export default {
        components: {
        },
        data () {
            return {
                selected: i18n.locale,
                languageOptions: getLanguageOptions()
            }
        },
        watch: {
            selected () {
                i18n.locale = this.selected;
                VueCookies.set('userlang', this.selected, -1);
                VueCookies.set('PLAY_LANG', this.selected, -1);
                // VueCookies.set('PLAY_LANG', this.selected, -1);
            }
        },
        mounted () {
        },
        beforeDestroy() {
        },
        computed: {
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>
