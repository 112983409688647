<template>
    <div class="row">
        <div class="col-sm-10 mx-auto">
            <div class="card">
                <div class="card-header">
                    <h1>{{$t("SubscriptionManager.title")}}</h1>
                </div>
                <div class="card-body" v-if="loading">
                    <i class="fa fa-3x fa-spin fa-spinner"></i>
                </div>
                <div class="card-body" v-else>
                    <div v-show="subscriptionData.problem" class="row">
                        <div class="col-sm-12 col-md-12 mx-auto">
                            <div class="alert alert-warning" role="alert">
                                {{subscriptionData.problemDescription}}
                            </div>
                        </div>
                    </div>
                    <div v-show="!subscriptionData.trialActive && !subscriptionData.subscriptionActive && (!subscriptionData.hasSubscription && !subscriptionData.hadSubscription)" class="row">
                        <div class="col-sm-12 col-md-12 mx-auto">
                            <div class="alert alert-danger" role="alert">
                                <h3>{{$t("SubscriptionManager.fields.important")}}</h3>

                                <p style="font-weight: bold">{{$t("SubscriptionManager.fields.trial_period_ended")}}
                                    {{subscriptionData.endOfSubscriptionString}}</p>

                                <p>{{$t("SubscriptionManager.fields.suspended_account_nostripe")}}</p>
                            </div>
                        </div>
                    </div>
                    <div v-show="!subscriptionData.trialActive && !subscriptionData.subscriptionActive && (subscriptionData.hasSubscription || subscriptionData.hadSubscription)" class="row">
                        <div class="col-sm-12 col-md-12 mx-auto">
                            <div class="alert alert-danger" role="alert">
                                <h3>{{$t("SubscriptionManager.fields.important")}}</h3>

                                <p style="font-weight: bold">{{$t("SubscriptionManager.fields.subscription_ended")}}
                                    {{subscriptionData.endOfSubscriptionString}}</p>

                                <p>{{$t("SubscriptionManager.fields.suspended_account_updatemethod")}}</p>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div class="row">
                        <div class="col">
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a class="nav-link" :class="{active: tabToShow==='sub'}" href="#"
                                       v-on:click="tabToShow='sub'">
                                        {{$t("SubscriptionManager.tab.subscription")}}
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" :class="{active: tabToShow==='inv'}" href="#"
                                       v-on:click="tabToShow='inv'">
                                        {{$t("SubscriptionManager.tab.invoices")}}
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" :class="{active: tabToShow==='card'}" href="#"
                                       v-on:click="tabToShow='card'">
                                        {{$t("SubscriptionManager.tab.payment_methods")}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <br/>
                    <!-- SUBSCRIPTION TAB -->
                    <div class="row" v-show="tabToShow==='sub'">
                        <div class="col-sm-12 col-md-12 mx-auto">
                            <h2>{{$t("SubscriptionManager.subscriptionTitle")}}</h2>

                            <div v-show="isTrial && !subscriptionData.hadSubscription" class="row">
                                <div class="col-sm-12 col-md-12 mx-auto">
                                    <div class="alert alert-warning" role="alert">
                                        {{$t("SubscriptionManager.fields.trial_period_ends")}}
                                        {{subscriptionData.endOfSubscriptionString}}
                                    </div>
                                </div>
                            </div>
                            <div v-show="isTrial && subscriptionData.hadSubscription" class="row">
                                <div class="col-sm-12 col-md-12 mx-auto">
                                    <div class="alert alert-warning" role="alert">
                                        {{$t("SubscriptionManager.fields.last_subscription_ends")}}
                                        {{subscriptionData.endOfSubscriptionString}}
                                    </div>
                                </div>
                            </div>

                            <div v-show="subscriptionData.hasSubscription" class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="alert alert-success" role="alert">
                                        {{$t("SubscriptionManager.fields.subscription_renew")}}
                                        {{subscriptionData.endOfSubscriptionString}}
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-end">
                                    <v-button :loading="cancelLoading" class="btn btn-danger" v-on:click="cancelSubscription">
                                        {{$t("SubscriptionManager.button.cancel_subscription")}}
                                    </v-button>
                                </div>
                            </div>

                            <div v-show="subscriptionData.hasSubscription  &&  subscriptionData.stripeProblem"
                                 class="row">
                                <div class="col-sm-12 col-md-12 mx-auto">
                                    <div class="alert alert-danger" role="alert">
                                        {{subscriptionData.stripeProblemDescription}}
                                        <div v-show="stripeToken==null" class="row">
                                            <div class="col-sm-3 col-md-3 mx-auto"></div>
                                            <div class="col-sm-6 col-md-6 mx-auto">
                                                <br/>
                                                <div id="card-element2"></div>
                                                <br/>
                                            </div>
                                            <div class="col-sm-3 col-md-3 mx-auto"></div>
                                        </div>
                                        <div v-show="stripeToken==null" class="row float-right">
                                            <p class="float-right">
                                                <a href="https://www.stripe.com" target="_blank">
                                                    <img class="img-fluid" src='/assets/images/powered_by_stripe.png'/>
                                                </a>
                                            </p>
<!--
                                            <p class="float-right"
                                               style="font-weight: lighter; font-style: italic; font-size: small">
                                                Powered by
                                                Stripe</p>
-->
                                        </div>
                                        <div v-show="stripeToken!=null" class="row">
                                            <div class="col-sm-12 col-md-12 mx-auto">
                                                {{stripeToken!=null?stripeToken.card.brand:''}} :
                                                {{$t("SubscriptionManager.fields.ending_with")}}
                                                {{stripeToken!=null?stripeToken.card.last4:''}}<br/>
                                                {{$t("SubscriptionManager.fields.valid_until")}} :
                                                {{stripeToken!=null?stripeToken.card.exp_year:''}}/{{stripeToken!=null?stripeToken.card.exp_month:''}}<br/>
                                            </div>
                                        </div>
                                        <div class="row" v-show="cardError.isError">
                                            <div class="col-sm-12 col-md-12 mx-auto">
                                                <div class="alert alert-danger" role="alert">
                                                    {{cardError.errorMessage}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-show="stripeToken==null">
                                            <div class="col-sm-12 col-md-12 mx-auto">
                                                <button class="btn btn-primary" v-on:click="updateCardAndToken">
                                                    {{$t("SubscriptionManager.button.update_card")}}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-show="isSubscriptionNotActive" class="row">
                                <div class="col-sm-12 col-md-12 mx-auto">
                                    <div class="alert alert-danger" role="alert">
                                        {{$t("SubscriptionManager.fields.subscription_ended")}}
                                        {{subscriptionData.endOfSubscriptionString}}
                                    </div>
                                </div>
                            </div>

                            <!-- NEW SUBSCRIPTION PROCESS -->
                            <div v-show="!subscriptionData.hasSubscription" class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <label for="currency" class="control-label">
                                            {{$t("AgencyConfiguration.fields.currency_select")}}
                                        </label>
                                        <select id="currency" class="form-control" v-model="curSelected">
                                            <option value="EUR">EUR - Euro (€)</option>
                                            <option value="USD">USD - Dollars ($)</option>
                                            <option value="GBP">GBP - Pounds (£)</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="control-label">
                                            {{$t("SubscriptionManager.fields.payment_options")}}
<!--                                            <img class="img-responsive pull-right" src="http://i76.imgup.net/accepted_c22e0.png">-->
                                            <br/>
                                            <small>{{$t("SubscriptionManager.fields.card_details")}}</small>
                                        </label>
                                        <div id="card-element" class="form-control"></div>
                                        <div class="row mt-1">
                                            <div class="col-12 d-flex justify-content-end">
                                                <a href="https://www.stripe.com" target="_blank">
                                                    <img class="img-fluid" src='/assets/images/powered_by_stripe.png'/>
                                                </a>
                                                <!--<p class="text-right" style="font-weight: lighter; font-style: italic; font-size: small">
                                                    Powered by <a href="https://www.stripe.com" target="_blank">Stripe</a>
                                                </p>-->
                                            </div>
                                        </div>
                                    </div>
                                    <!--<div v-show="stripeToken != null" class="row">
                                        <div class="col-sm-12 col-md-12 mx-auto">
                                            {{stripeToken!=null?stripeToken.card.brand:''}} :
                                            {{$t("SubscriptionManager.fields.ending_with")}}
                                            {{stripeToken!=null?stripeToken.card.last4:''}}<br/>
                                            {{$t("SubscriptionManager.fields.valid_until")}} :
                                            {{stripeToken!=null?stripeToken.card.exp_year:''}}/{{stripeToken!=null?stripeToken.card.exp_month:''}}<br/>
                                        </div>
                                    </div>-->
                                    <div class="row" v-show="cardError.isError">
                                        <div class="col-sm-12 col-md-12 mx-auto">
                                            <div class="alert alert-danger" role="alert">
                                                {{cardError.errorMessage}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="card">
                                        <div class="list-group list-group-flush">
                                            <li class="list-group-item">
                                                <b>{{$t("SubscriptionManager.monthlyPlan")}}</b>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="d-flex justify-content-between">
                                                    <span>{{$t("SubscriptionManager.planAmount")}}</span><span>{{monthlyFee}}</span>
                                                </div>
                                                <!--<div class="d-flex justify-content-between">
                                                    <span>IVA (23%)</span><span>€23</span>
                                                </div>
                                                <div class="d-flex justify-content-between">
                                                    <span>TOTAL</span><span>€123</span>
                                                </div>TODO para confirmar-->
                                                <!-- TODO lang file -->
                                            </li>
                                        </div>
                                        <!--<div class="card-body">
                                        </div>-->
                                    </div>
                                </div>
                                <div class="col-12">
                                    <v-button :loading="payLoading"
                                              class="btn btn-primary float-right"
                                              @click="validateCardAndToken">
                                        {{$t("SubscriptionManager.button.use_this_card")}}
                                    </v-button>
                                </div>
                            </div>
                            <!--<div v-show="!subscriptionData.hasSubscription && subscriptionStep >= 1" class="row" >
                                <div  class="col-sm-12 col-md-12 mx-auto" >
                                    <h3>{{$t("SubscriptionManager.confirmationTitle")}}</h3>
                                    <div  class="col-sm-6 col-md-6 mx-auto" >
                                        <button class="btn btn-primary" v-on:click="createSubscription"> {{$t("SubscriptionManager.button.i_am_sure")}} </button>
                                        <a href=""
                                           onclick="location.href=jsRoutes.controllers.Application.index().url;return false;"
                                           class="btn btn-warning" > {{$t("error.general_cancel")}} </a>
                                    </div>
                                </div>
                            </div>-->
                        </div>
                    </div>

                    <!-- IVOINCES TAB -->
                    <div class="row" v-show="tabToShow==='inv'">
                        <div class="col-sm-12 col-md-12 mx-auto">
                            <h2>{{$t("SubscriptionManager.invoicesTitle")}}</h2>
                            <ul class="list-group">
                                <li v-if="loadingInvoices"
                                    class="list-group-item d-flex justify-content-between align-items-center">
                                    {{$t("SubscriptionManager.loadingInvoices")}}
                                </li>
                                <li v-if="!loadingInvoices" v-for="item in invoices"
                                    class="list-group-item d-flex justify-content-between align-items-center">
                                    <div>
                                        <span style="font-weight: bold">{{$t("SubscriptionManager.invoice.date")}}: </span><span
                                            style="font-style: italic"> {{item.invoiceDate}} </span><br/>
                                        <span style="font-weight: bold">{{$t("SubscriptionManager.invoice.value")}}: </span><span
                                            style="font-style: italic">{{item.amountDue}} </span>
                                    </div>
                                    <!--<button class="btn btn-primary" :href="item.pdfurl">{{$t("SubscriptionManager.button.download")}}</button>-->
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- PAYMENT TAB-->
                    <div class="row" v-show="tabToShow==='card'">
                        <div class="col-sm-12 col-md-12 mx-auto">
                            <h2>{{$t("SubscriptionManager.cardsTitle")}}</h2>
                            <ul class="list-group">
                                <li v-if="loadingCards"
                                    class="list-group-item d-flex justify-content-between align-items-center">
                                    {{$t("SubscriptionManager.loadingCards")}}
                                </li>
                                <li v-if="!loadingCards"
                                    v-for="(item, index) in cards"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                    :class="{'list-group-item-success':index==0}"
                                >
                                    <div>
                                        <span style="font-weight: bold">{{item.brand}}: </span><span
                                            style="font-style: italic">**** **** **** {{item.last4}}</span><br/>
                                        <span style="font-weight: bold">{{$t("SubscriptionManager.fields.expire_card")}}: </span><span
                                            style="font-style: italic">{{item.exp_year}}/{{item.exp_month}} </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .curSelected {
        background-color: #1a8d9d;
    }
</style>

<script>
    import axios from 'axios';
    import {getAjaxErrorMsg, handleAjaxError} from "../../utils";
    import VButton from "../../components/VButton";

    let stripeHandler ;
    let card;

    export default {
        name: "SubscriptionManager",
        components: {
            VButton
        },
        // props: ["uuid"],
        data() {
            return {
                loading: true,

                tabToShow: 'sub',
                stripeKey: '',
                stripeToken: null,
                cardError : {
                    isError : false,
                    errorMessage: ''
                },
                subscriptionStep:0,
                processed: false,
                curSelected: 'EUR',

                subscriptionData: {
                    problem: false,
                    showIva: false,
                },
                subscriptionPlans: {},
                loadingInvoices: true,
                invoices: [],
                loadingCards: true,
                cards: [],

                payLoading: false,
                cancelLoading: false
            }
        },
        mounted() {
            this.loadData();
            this.getListOfInvoices();
            this.getListOfCards();
        },
        watch: {
            // processed() {
            //     this.loadAvailableSubscription();
            // }
        },
        computed: {
            isTrial() {
                return this.processed && this.subscriptionData.trialActive;
            },
            isSubscriptionNotActive(){
                return this.processed && this.subscriptionData.hasSubscription && !this.subscriptionData.subscriptionActive ;
            },
            monthlyFee() {
                if (this.curSelected === 'EUR') {
                    return "€100"
                } else if (this.curSelected === 'USD') {
                    return "$125"
                } else if (this.curSelected === 'GBP') {
                    return "£90"
                }
                return ""
            }
        },
        methods: {
            getListOfCards() {
                let self = this;
                this.loadingCards = true
                jsRoutes.controllers.SubscriptionController.getCardsList()
                    .ajax({
                        success: function (data) {
                            // console.log('RECEIVED LIST OF CARDS: ' + JSON.stringify(data));
                            self.cards = data;
                            self.loadingCards = false
                        },
                        error: function (error) {
                            // TODO , do something with error ?  Maybe blank the Page !
                        }
                    });
            },
            getListOfInvoices() {
                let self = this;
                this.loadingInvoices = true
                jsRoutes.controllers.SubscriptionController.getInvoiceList()
                    .ajax({
                        success: function (data) {
                            //console.log('RECEIVED LIST OF INVOICES: ' + JSON.stringify(data));
                            self.invoices = data.invoiceItemJson;
                            self.loadingInvoices = false
                        },
                        error: function (error) {
                            // TODO , do something with error ?  Maybe blank the Page !
                        }
                    });
            },
            loadData() {
                const self = this;
                this.loading = true;
                jsRoutes.controllers.SubscriptionController.getSubscriptionStatus()
                    .ajax({
                        success: function (data) {
                            self.subscriptionData = data;
                            self.curSelected = data.defaultCurrency;
                            self.processed = true;
                            self.loading = false;
                            self.loadAvailableSubscription();
                        },
                        error: function (error) {
                            self.loading = false;
                            handleAjaxError(error)
                            // TODO , do something with error ?  Maybe blank the Page !
                        }
                    });
            },
            loadAvailableSubscription() {
                const self = this;
                jsRoutes.controllers.SubscriptionController.getAvailabeSubscriptionsList()
                    .ajax({
                        success: function (data) {
                            self.stripeKey = data.publishable;
                            self.subscriptionPlans = data;
                            if (!self.subscriptionData.hasSubscription) {
                                self.setupCardForm()
                            }

                            if (self.subscriptionData.stripeProblem) {
                                stripeHandler = Stripe(self.stripeKey);
                                const elements = stripeHandler.elements();

                                card = elements.create('card');
                                card.mount('#card-element2');
                            }
                        },
                        error: function (error) {
                            handleAjaxError(error)
                            // TODO , do something with error ?  Maybe blank the Page !
                        }
                    });
            },
            setupCardForm() {
                // this.subscriptionStep = 1;
                stripeHandler = Stripe(this.stripeKey);
                const elements = stripeHandler.elements();

                card = elements.create('card');
                card.mount('#card-element');
            },
            validateCardAndToken() {
                const self = this;
                this.payLoading = true;
                let promise = stripeHandler.createToken(card);
                promise.then(function(result) {
                    if (result.token != null) {
                        //console.log("TOKE IS : " + JSON.stringify(result));
                        self.cardError.isError = false;
                        self.cardError.errorMessage = '';
                        self.stripeToken = result.token;
                        self.createSubscription()
                        // self.subscriptionStep = 1;
                    } else {
                        self.payLoading = false;
                        //error
                        console.log("ERROR IS : " + JSON.stringify(result));
                        self.cardError.isError = true;
                        self.cardError.errorMessage = result.error.message;
                        self.stripeToken = null;
                    }
                });
            },
            updateCardAndToken() {
                const self = this;
                var promise = stripeHandler.createToken(card);
                promise.then(function(result) {
                    if (result.token != null) {
                        //console.log("TOKE IS : " + JSON.stringify(result));
                        self.cardError.isError = false;
                        self.cardError.errorMessage = '';
                        self.stripeToken = result.token;

                        // DO STUFF HERE
                        jsRoutes.controllers.SubscriptionController.updateCard()
                        .ajax({
                            data: JSON.stringify(
                                {
                                    token: self.stripeToken
                                }
                            ),
                            contentType: "application/json; charset=utf-8",
                            success: function (data) {
                                self.loadData();
                            },
                            error: function (error) {
                            }
                        });


                    } else {
                        //error
                        console.log("ERROR IS : " + JSON.stringify(result));
                        self.cardError.isError = true;
                        self.cardError.errorMessage = result.error.message;
                        self.stripeToken = null;
                    }
                });
            },
            createSubscription() {
                const self = this;
                let plan = '';
                if (this.curSelected === 'EUR')
                    plan = this.subscriptionPlans.planEuro;
                if (this.curSelected === 'USD')
                    plan = this.subscriptionPlans.planDollar;
                if (this.curSelected === 'GBP')
                    plan = this.subscriptionPlans.plangbp;

                let url = jsRoutes.controllers.SubscriptionController.createSubscription().url;
                const dataObj = {
                    productId: self.subscriptionPlans.subscriptionId,
                    planId: plan,
                    option: self.curSelected,
                    token: self.stripeToken
                };

                axios.post(url, dataObj)
                    .then(response => {
                        self.payLoading = false;
                        self.loadData();
                    })
                    .catch(error => {
                        self.payLoading = false;
                        self.cardError.isError = true;
                        self.cardError.errorMessage = getAjaxErrorMsg(error);
                    })
            },
            cancelSubscription(){
                const self = this;
                self.cancelLoading = true;
                let url = jsRoutes.controllers.SubscriptionController.cancelSubscription().url;
                axios.post(url)
                    .then(() => {
                        self.cancelLoading = false;
                        self.loadData();
                    })
                    .catch(error => {
                        self.cancelLoading = false;
                        handleAjaxError(error)
                    });
            }

        }
    }
</script>
