<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft" v-if="droppable">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{ section.title }}
                        </button>
                    </div>
                </div>
                <button class="btn btn-danger"
                        v-if="droppable"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'
                >
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    Frequently Asked Questions
                </h3>
            </div>
        </div>
        <draggable class="row" style="margin-top: 10px;"
                   v-model="item.questions"
                   :options="{animation:150, disabled: !editable}"
                   @change="emitAutosave"
        >
            <div class="col-12" style="margin-top:10px; margin-bottom: 10px;"
                 v-for="quest, idx in item.questions"
                 :key="quest.id"
            >   
                <hr v-if="idx > 0" />
                <div class="col-11">
                    <input
                        :readonly="!editable"
                        type="text"
                        placeholder="Question"
                        v-model="quest.question"
                        class="form-control"
                        @blur="emitAutosave"
                    />
                </div>
                <div style="float: right;" v-if="editable">
                        <span class="text-danger" @click="dropQuestion(quest)" style="cursor: pointer;">
                            <i class="fa fa-times"></i>
                        </span>
                </div>
                <div class="col-11" style="margin-top: 0.2em">
                    <textarea
                        :readonly="!editable"
                        class="form-control"
                        placeholder="Answer"
                        v-model="quest.answer"
                        @blur="emitAutosave"
                    />
                </div>
            </div>
        </draggable>
        <div v-if="editable" class="row d-print-none" style="margin-top: 10px;">
            <div class="col-12">
                <button style="margin-top: 10px;" class="btn btn-primary" @click="addQuestion()">
                    {{$t("Itinerary.general.add_line")}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import {autosaveMixin, itemCommonPropsMixin, moveItemMixin, stringUtilsMixin} from "./item-mixins";
    import { itineraryEventBus } from '../../../event-bus';
    import { TRIP_FAQ_ITEM_VALUE } from '../itinerary-templates';



    export default {
        components: {
            draggable
        },
        mixins: [
            autosaveMixin,
            moveItemMixin,
            itemCommonPropsMixin,
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            droppable: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {}
        },
        methods: {
            addQuestion() {
                this.item.questions.push(TRIP_FAQ_ITEM_VALUE());
                this.emitAutosave();
            },
            dropQuestion(quest) {
                const newItems = this.item.questions.filter(item => item.id !== quest.id);
                this.item.questions.splice(0);
                this.item.questions.push(...newItems);
                this.emitAutosave();
            }
        }
    }
</script>
