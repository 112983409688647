<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3 class="text-center">
                    <input type="text" class="name-input" v-model="item.title"/>
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="stringNotEmpty(item.deposit.value)">
                {{$t("Itinerary.TripNextSteps.tooltip.deposit")}} {{item.deposit.value}} % {{item.deposit.when}}
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="stringNotEmpty(item.email)">
                <div class="table-cell">
                    {{$t("Itinerary.TripNextSteps.tooltip.email")}}:
                </div>
                <div class="table-cell bullet-right" style="padding-left:5px;">
                    <input class="mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripNextSteps.tooltip.email")'
                           v-model="item.email"
                    />
                </div>
            </div>
            <div class="col-12" v-if="stringNotEmpty(item.phone)">
                <div class="table-cell">
                    {{$t("Itinerary.TripNextSteps.tooltip.phone")}}:
                </div>
                <div class="table-cell bullet-right" style="padding-left:5px;">
                    <input class="mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripNextSteps.tooltip.phone")'
                           v-model="item.phone"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {stringUtilsMixin} from "../../item/item-mixins";

    export default {
        components: {
        },
        mixins: [
            stringUtilsMixin
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                // showButtons: false,
            }
        },
        methods: {
        }
    }
</script>

<style scoped>
</style>
