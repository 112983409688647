<template>
    <div id="sidebar-wrapper">
        <ul class="sidebar-nav">
            <li class="sidebar-brand">
                <h3>
                    <a :href='indexUrl'>
                        <img src="/assets/images/menu_logo.jpg" style="width: 125px;">
                    </a>
                </h3>
            </li>
            <li v-if="showAgencyMenu">
                <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                    <i class="fa fa-cogs"></i> {{$t("mainmenu.agency")}}
                </a>
                <ul class="collapse list-unstyled" id="homeSubmenu">
                    <li :class="{ 'active': selectedMenu=='config' }">
                        <a :href="configUrl">{{$t("mainmenu.configuration")}}</a>
                    </li>
                    <li :class="{ 'active': selectedMenu=='mobileconfig' }" style="display: none;">
                        <a :href="mobileConfigUrl">{{$t("mainmenu.configuration_mobile")}}</a>
                    </li>
                    <li :class="{ 'active': selectedMenu=='users' }">
                        <a :href="usersUrl">{{$t("mainmenu.users")}}</a>
                    </li>
                    <li :class="{ 'active': selectedMenu=='billing' }" style="display: none;">
                        <a :href="billingUrl">{{$t("mainmenu.billing")}}</a>
                    </li>
                </ul>
            </li>
            <li :class="{ 'active': selectedMenu=='clients' }">
                <a :href="clientsUrl"><i class="fa fa-user"></i> {{$t("mainmenu.clients")}}</a>
            </li>
            <li :class="{ 'active': selectedMenu=='itineraries' }">
                <a :href="tripsUrl"><i class="fa fa-map"></i> {{$t("mainmenu.itineraries")}}</a>
            </li>
            <li :class="{ 'active': selectedMenu=='templates' }">
                <a :href="templatesUrl"><i class="fa fa-map"></i> {{$t("mainmenu.templates")}}</a>
            </li>
            <li :class="{ 'active': selectedMenu=='library' }">
                <a :href="libraryUrl"><i class="fa fa-book"></i> {{$t("mainmenu.library")}}</a>
            </li>
        </ul>
    </div>
</template>
<script>

    import {getLanguageOptions} from "../../language-utils";
    import {i18n} from "../../main"

    export default {
        components: {
        },
        data () {
            return {
                selectedMenu: '',
                selected: i18n.locale,
                languageOptions: getLanguageOptions(),

                showAgencyMenu: false
            }
        },
        watch: {
        },
        mounted () {
            // ambas estas vars devem estar no main.scala.html
            if (typeof agmenu !== 'undefined') {
                this.showAgencyMenu = agmenu === 1;
            }
            if (typeof activeMenu !== 'undefined') {
                this.selectedMenu = activeMenu;
            }
        },
        beforeDestroy() {
        },
        computed: {
            indexUrl() {
                return jsRoutes.controllers.Application.index().url;
            },
            configUrl() {
                return jsRoutes.controllers.AdministrationController.agencyConfiguration().url;
            },
            mobileConfigUrl() {
                return jsRoutes.controllers.AdministrationController.mobileAppConfiguration().url;
            },
            usersUrl() {
                return jsRoutes.controllers.AdministrationController.users().url;
            },
            billingUrl() {
                return jsRoutes.controllers.SubscriptionController.subscriptionManager().url;
            },
            clientsUrl() {
                return jsRoutes.controllers.ClientController.clients().url;
            },
            tripsUrl() {
                return jsRoutes.controllers.ItineraryController.itineraries().url;
            },
            templatesUrl() {
                return jsRoutes.controllers.ItineraryController.templates().url;
            },
            libraryUrl() {
                return jsRoutes.controllers.LibraryController.libraryList().url;
            },
        },
        methods: {
        }
    }
</script>
