<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="dropdown dropleft">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-danger"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
<!--
            <br/>
            <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                <i class="fa fa-arrows-alt"></i>
            </button>
-->
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <input :readonly="!editable"
                           type="text"
                           class="name-input"
                           :placeholder='$t("Itinerary.TripCompanyInfo.tooltip.organizer_details")'
                           v-model="item.title"
                           @blur="emitAutosave"/>
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <img class="img-fluid" :src="agencyImageUrl"/>
            </div>
            <div class="col-8">
                <div>
                    <div class="table-cell icon-left">
                        <i class="fa fa-building"></i>
                    </div>
                    <div class="table-cell pl-1">
                        {{agencyData.name}}
                    </div>
                </div>
                <div v-if="agencyData.website !== null && agencyData.website.length > 0">
                    <div class="table-cell icon-left">
                        <a :href="agencyData.website" target="_blank">
                            <i class="fa fa-globe"></i>
                        </a>
                    </div>
                    <div class="table-cell pl-1">
                        <a :href="agencyData.website" target="_blank">
                            {{agencyData.website}}
                        </a>
                    </div>
                </div>
                <div v-if="agencyData.phone !== null && agencyData.phone.length > 0">
                    <div class="table-cell icon-left">
                        <i class="fa fa-phone"></i>
                    </div>
                    <div class="table-cell pl-1">
                        {{agencyData.phone}}
                    </div>
                </div>
                <div>
                    <div class="table-cell icon-left">
                        <i class="fa fa-envelope"></i>
                    </div>
                    <div class="table-cell pl-1">
                        {{agencyFullAddress}}
                    </div>
                </div>
                <div v-if="agencyData.reg !== null && agencyData.reg.length > 0">
                    <div class="table-cell icon-left" style="font-size: 1.5rem;font-weight: bold;">
                        #
                    </div>
                    <div class="table-cell pl-1">
                        {{agencyData.reg}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-12">
                <h3>
                    <input :readonly="!editable"
                           type="text"
                           class="name-input"
                           :placeholder='$t("Itinerary.TripCompanyInfo.tooltip.trip_manager")'
                           v-model="item.tripManagerTitle"
                           @blur="emitAutosave"/>
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <img style="width:200px;height:200px;border-radius:50%;" :src="managerImageUrl" v-tooltip="managerPhotoTooltip"/>
            </div>
            <div class="col-8">
                <div v-if="selectedManager">
                    <div class="table-cell icon-left">
                        <i class="fa fa-user"></i>
                    </div>
                    <div class="table-cell pl-1">
                        <select :disabled="!editable" class="form-control" v-model="tmpManagerId" @change="updateManager">
                            <option v-for="manager in managerList" :key="manager.id" :value="manager.id">
                                {{manager.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div v-if="selectedManager">
                    <div class="table-cell icon-left">
                        <i class="fa fa-envelope"></i>
                    </div>
                    <div class="table-cell pl-1">
                        {{selectedManager.email}}
                    </div>
                </div>
                <div v-if="selectedManager">
                    <div class="table-cell icon-left">
                        <i class="fa fa-phone"></i>
                    </div>
                    <div class="table-cell pl-1">
                        {{selectedManager.phone}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;" v-if="status === 'SOLD'">
            <div class="col-12">
                <h3>
                    <input :readonly="!editable"
                           type="text"
                           class="name-input"
                           :placeholder='$t("Itinerary.TripCompanyInfo.tooltip.local_trip_manager")'
                           v-model="item.localTripManagerTitle"
                           @blur="emitAutosave"/>
                </h3>
            </div>
            <div class="col-4">
                <img style="width:200px;height:200px;border-radius:50%;" src="/assets/images/male-icon.jpg"/>
            </div>
            <div class="col-8">
                <div class="table-cell icon-left">
                    <i class="fa fa-user"></i>
                </div>
                <div class="table-cell pl-1">
                    <input :readonly="!editable"
                           type="text" class="name-input"
                           v-model="item.localTripManagerName"
                           :placeholder='$t("Itinerary.TripCompanyInfo.tooltip.name")'
                           ref="localname"
                           @keydown.down="$refs.localemail.focus()"
                           @blur="emitAutosave"
                    />
                </div>
                <div>
                    <div class="table-cell icon-left">
                        <i class="fa fa-envelope"></i>
                    </div>
                    <div class="table-cell pl-1">
                        <input :readonly="!editable"
                               type="text" class="name-input"
                               v-model="item.localTripManagerEmail"
                               :placeholder='$t("Itinerary.TripCompanyInfo.tooltip.email")'
                               ref="localemail"
                               @keydown.up="$refs.localname.focus()"
                               @keydown.down="$refs.localphone.focus()"
                               @blur="emitAutosave"
                        />
                    </div>
                </div>
                <div>
                    <div class="table-cell icon-left">
                        <i class="fa fa-phone"></i>
                    </div>
                    <div class="table-cell pl-1">
                        <input :readonly="!editable"
                               type="text" class="name-input"
                               v-model="item.localTripManagerPhone"
                               :placeholder='$t("Itinerary.TripCompanyInfo.tooltip.phone")'
                               ref="localphone"
                               @keydown.up="$refs.localemail.focus()"
                               @blur="emitAutosave"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;" v-if="status === 'SOLD'">
            <div class="col-12">
                <h3>
                    <input :readonly="!editable"
                           type="text"
                           class="name-input"
                           :placeholder='$t("Itinerary.TripCompanyInfo.tooltip.emergency_contacts")'
                           v-model="item.emergencyContactsTitle"
                           @blur="emitAutosave"/>
                </h3>
            </div>
            <div class="col-12">
                <div v-for="(contact, index) in item.emergencyContacts"
                     class="d-flex justify-content-start align-items-center">
                    <div class="bullet-left">
                        <i class="fa fa-circle"></i>
                    </div>
                    <div style="padding-left: 5px;min-width:250px;width:250px;">
                        <input  :readonly="!editable"
                                class="bullet-item mor-editable-field"
                                :placeholder='$t("Itinerary.TripCompanyInfo.tooltip.name")'
                                :ref="'name' + index"
                                v-model="item.emergencyContacts[index].name"
                                @keydown.up.prevent="handleUp(index)"
                                @keydown.down.prevent="handleDown(index)"
                                @keydown.backspace="handleBackspace(contact, index, $event)"
                                @keydown.46="handleBackspace(contact, index, $event)"
                                @keydown.enter.prevent="handleEnter(index)"
                                @blur="emitAutosave"
                        />
                    </div>
                    <div style="padding-left: 5px;min-width:200px;width:200px;">
                        <input  :readonly="!editable"
                                class="bullet-item mor-editable-field"
                                :placeholder='$t("Itinerary.TripCompanyInfo.tooltip.phone")'
                                :ref="'phone' + index"
                                v-model="item.emergencyContacts[index].phone"
                                @keydown.up.prevent="handleUp(index)"
                                @keydown.down.prevent="handleDown(index)"
                                @keydown.backspace="handleBackspace(contact, index, $event)"
                                @keydown.46="handleBackspace(contact, index, $event)"
                                @keydown.enter.prevent="handleEnter(index)"
                                @blur="emitAutosave"
                        />
                    </div>
                    <div style="padding-left: 5px;width:100%;">
                        <input  :readonly="!editable"
                                class="bullet-item mor-editable-field"
                                :placeholder='$t("Itinerary.TripCompanyInfo.tooltip.description")'
                                :ref="'desc' + index"
                                v-model="item.emergencyContacts[index].description"
                                @keydown.up.prevent="handleUp(index)"
                                @keydown.down.prevent="handleDown(index)"
                                @keydown.backspace="handleBackspace(contact, index, $event)"
                                @keydown.46="handleBackspace(contact, index, $event)"
                                @keydown.enter.prevent="handleEnter(index)"
                                @blur="emitAutosave"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {showError} from "../../../utils";
    import {autosaveMixin, itemCommonPropsMixin, moveItemMixin, stringUtilsMixin} from "./item-mixins";

    export default {
        components: {
        },
        mixins: [
            autosaveMixin,
            moveItemMixin,
            itemCommonPropsMixin,
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            managerId: {
                type: Number,
                required: true,
            },
            managerName: {
                type: String,
                required: true,
            },
            status: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                agencyData: {
                    name: null,
                    email: null,
                    website: null,
                    phone: null,
                    address: null,
                    city: null,
                    postalCode: null,
                    country: null,
                    vat: null,
                    reg: null,
                    imageFilename: null,
                    imageFile: null,
                    removeImage: false,
                    isUserAdmin: false,
                    configured: false,
                },

                managerList: [],
                tmpManagerId: null,

            }
        },
        mounted() {
            this.loadAgencyData();
            this.loadManagerList();
            this.tmpManagerId = this.managerId;
        },
        watch: {
            managerId() {
                this.tmpManagerId = this.managerId;
            }
        },
        computed: {
            agencyImageUrl() {
                if (this.agencyData.imageFilename !== null) {
                    return jsRoutes.controllers.Application.agencyImage(this.agencyData.imageFilename).url
                } else {
                    return null;
                }
            },
            selectedManager() {
                if (this.tmpManagerId !== null) {
                    let manager = this.managerList.find(item => {
                        return item.id === this.tmpManagerId;
                    });
                    if (manager) {
                        return manager;
                    }
                }
                return null;
            },
            managerPhotoTooltip() {
                if (this.managerHasPhoto) {
                    return null;
                } else {
                    return this.$t("Itinerary.TripCompanyInfo.messages.manager_profile_pic")
                }
            },
            managerHasPhoto() {
                const manager = this.selectedManager;
                const result = manager && manager.hasPhoto;
                // console.log("### managerHasPhoto => " + result);
                return result;
            },
            managerImageUrl() {
                if (this.managerHasPhoto) {
                    return jsRoutes.controllers.Application.managerImage(this.managerId).url
                }
                return "/assets/images/male-icon.jpg";
            },
            agencyFullAddress() {
                return this.agencyData.address + " - " + this.agencyData.postalCode + ", " + this.agencyData.country;
            }
        },
        methods: {
            loadAgencyData() {
                const self = this;
                const url = jsRoutes.controllers.AdministrationController.getAgencyConfiguration().url;

                this.$http.get(url).then(function (response) {
                    //console.log("roles -> " + JSON.stringify(data));
                    self.agencyData = response.data;

                }).catch(function (response) {
                    // handle error
                    showError(
                        self.$t("error.general_title"),
                        self.$t("Itinerary.TripCompanyInfo.messages.failed_load_data")
                    );
                });
            },
            loadManagerList() {
                const self = this;
                const url = jsRoutes.controllers.ItineraryController.getManagerListSelect().url;

                this.$http.get(url).then(function (response) {
                    //console.log("roles -> " + JSON.stringify(data));
                    self.managerList = response.data;

                }).catch(function (response) {
                    // handle error
                    showError(
                        self.$t("error.general_title"),
                        self.$t("Itinerary.TripCompanyInfo.messages.failed_load_data")
                    );
                });
            },
            updateManager() {
                // this.managerId = this.tmpManagerId;
                // this.managerName = this.selectedManager.name;
                this.$emit("update:managerId", this.tmpManagerId);
                this.$emit("update:managerName", this.selectedManager.name);
                this.emitForceSave();
            },
            handleUp(index) {
                if (index > 0) {
                    this.emitAutosave();
                    this.$refs["name" + (index-1)][0].focus();
                }
            },
            handleDown(index) {
                if (index < this.item.emergencyContacts.length-1) {
                    this.emitAutosave();
                    this.$refs["name" + (index+1)][0].focus();
                }
            },
            handleBackspace(contact, index, event) {
                if ((!contact.name || contact.name.length === 0) &&
                    this.item.emergencyContacts.length > 1) {
                    // console.log("### DELETE " + index);
                    this.item.emergencyContacts.splice(index, 1);
                    this.emitAutosave();
                    if (index > 0) {
                        // console.log("### FOCUS " + (index-1));

                        this.$refs["name" + (index-1)][0].focus();
                    }
                    event.preventDefault();
                }
            },
            handleEnter(index) {
                this.emitAutosave();
                // console.log("### ENTER " + index);
                this.item.emergencyContacts.splice(index+1, 0, {
                    name: null,
                    phone: null,
                    description: null
                });
                this.$nextTick(() => {
                    this.$refs["name" + (index+1)][0].focus();
                });
            }

        }
    }
</script>

<style scoped>
    .icon-left {
        width: 25px;
        min-width: 25px;
        text-align: center;
    }
</style>
