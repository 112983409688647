<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <input type="text" class="name-input" v-model="item.title"/>
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div v-for="(bullet, index) in item.highlights" v-if="stringNotEmpty(bullet)">
                    <i class="fa fa-circle" style="font-size: 0.5em;"></i>
                    <span
                            class="bullet-item"
                    >
                        {{bullet}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {stringUtilsMixin} from "../../item/item-mixins";

    export default {
        components: {
        },
        mixins: [
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                // showButtons: false,
            }
        },
        methods: {
        }
    }
</script>

<style scoped>
</style>
