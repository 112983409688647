<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft"  v-if="droppable">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                           :key="section.id"
                           class="dropdown-item"
                           :class="{'disabled': section.id === currentSectionId}"
                           @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
                <button class="btn btn-danger"
                        v-if="droppable"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
<!--
            <br/>
            <div class="text-right" style="margin-top: 5px;">
                <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                    <i class="fa fa-arrows-alt"></i>
                </button>
            </div>
-->
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <input :readonly="!editable"
                           type="text" class="name-input"
                           v-model="item.name"
                           :placeholder='$t("Itinerary.TripTourName.tooltip.tour_name")'
                           ref="name"
                           @keydown.down="$refs.description.focus()"
                           @blur="emitAutosave"

                    />
<!--                    <span v-else>{{item.name ? item.name : $t("Itinerary.TripTourName.tooltip.tour_name")}}</span>-->
                <!--<input type="text" class="name-input" v-model="activeSection.name"/>-->
                </h3>
            </div>
        </div>
        <div class="row" v-show="false">
            <div class="col-12" >
                <input :readonly="!editable"
                       type="text" class="name-input"
                       v-model="item.description"
                       :placeholder='$t("Itinerary.TripTourName.tooltip.short_description")'
                       ref="description"
                       @keydown.up="$refs.name.focus()"
                       @keydown.down="$refs.refnumber.focus()"
                       @blur="emitAutosave"
                />
            </div>
            <div class="col-12">
                <input :readonly="!editable"
                       type="text" class="name-input"
                       v-model="item.refNum"
                       :placeholder='$t("Itinerary.TripTourName.tooltip.reference")'
                       ref="refnumber"
                       @keydown.up="$refs.description.focus()"
                       @blur="emitAutosave"
                />
<!--                <span v-else>{{item.refNum}}</span>-->
            </div>
        </div>
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
        >
        </save-to-library-modal>
    </div>
</template>

<script>
    import {autosaveMixin, itemCommonPropsMixin, moveItemMixin} from "./item-mixins";
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";

    export default {
        components: {
            SaveToLibraryModal,
        },
        mixins: [
            autosaveMixin,
            moveItemMixin,
            itemCommonPropsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        computed: {
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library")
                    ;
            },
        },
        data() {
            return {
                showSetNameModal: false,
            }
        },
        methods: {

        }
    }
</script>

<style scoped>
</style>
