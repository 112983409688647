<template>
    <div class="row">
        <div class="col-8 mx-auto">

            <div class="card">
                <div class="card-header">
                    <h1>{{$t("Itinerary.builder.templatecreate.title")}}</h1>
                </div>
                <div class="card-body">
                    <div class="col-12">
                        <form v-on:submit.prevent="createItinerary" novalidate>
                            <div class="form-group" :class="{ 'has-error': $v.itData.name.$error }">
                                <label for="name" class="control-label" >{{$t("Itinerary.builder.templatecreate.name")}} *:</label>
                                <input type="text" class="form-control" id="name" name="name"
                                       :class="{'is-invalid' : $v.itData.name.$error}"
                                       v-model.trim="$v.itData.name.$model">
                                <div class="invalid-feedback" v-show="$v.itData.name.$error && !$v.itData.name.required">
                                    {{$t("Itinerary.builder.templatecreate.field_required")}}
                                </div>
                                <div class="invalid-feedback" v-show="$v.itData.name.$error && !$v.itData.name.maxLength">
                                    {{$t("Itinerary.builder.templatecreate.name_size")}}
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label" >{{$t("Itinerary.builder.templatecreate.date_format")}}</label>
                                <select class="form-control" v-model="itData.dateFormat">
                                    <option v-for="dateFormat in dateFormats" :value="dateFormat.value" :key="dateFormat.value">{{dateFormat.label}}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="control-label" >{{$t("Itinerary.builder.templatecreate.itinerary_language")}}</label>
                                <select class="form-control" v-model="itData.languageCode">
                                    <option v-for="lang in languageOptions" :value="lang.value" :key="lang.value">{{lang.label}}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="control-label" >
                                    {{$t("Itinerary.builder.templatecreate.use_itinerary_template")}}
                                </label>
                                <div class="d-flex">
                                    <el-select v-model="templateId"
                                               default-first-option
                                               filterable
                                               clearable
                                               :placeholder='$t("Itinerary.builder.templatecreate.select_itinerary")'
                                               style="width:100%;color:black;font-size: 1rem;"
                                               @change="loadItineraryDetails"
                                               @blur="loadItineraryDetails"
                                               @clear="loadItineraryDetails"
                                    >
                                        <el-option v-for="itin in itinList"
                                                   :key="itin.id"
                                                   :label="getItinLabel(itin)"
                                                   :value="itin.id"
                                        >
                                        </el-option>
                                    </el-select>
                                    <a v-if="templateId"
                                       :href="itinUrl"
                                       target="_blank"
                                       class="btn btn-primary ml-2"
                                       v-tooltip='$t("Itinerary.builder.templatecreate.open_in_new_tab_tooltip")'
                                    >
                                        <span class="fa fa-external-link-alt"></span>
                                    </a>
                                </div>
                                <!--<select class="form-control" v-model="templateId">
                                    <option v-for="itin in itinList" :value="itin.id">{{itin.name}}</option>
                                </select>-->
                            </div>
                            <div v-show="showTemplateDaysPicker"
                                 class="form-group"
                                 :class="{ 'has-error': templateError }"
                            >
                                <label class="control-label">
                                    {{$t("Itinerary.builder.templatecreate.select_days_keep_template_warning", { numDays: itData.numDays })}}
                                </label>
                                <el-select v-model="useTemplateDays"
                                           default-first-option
                                           filterable
                                           clearable
                                           multiple
                                           style="width:100%;color:black;font-size: 1rem;"
                                           @change="checkTemplateDates"
                                           @blur="checkTemplateDates"
                                           @clear="checkTemplateDates"
                                >
                                    <el-option v-for="(day, index) in templateDayList"
                                               :key="day.id"
                                               :label="getDayLabel(index)"
                                               :value="day.id"
                                    >
                                    </el-option>
                                </el-select>
                                <input type="hidden" class="form-control"
                                       :class="{'is-invalid' : templateError}"/>
                                <div class="invalid-feedback" v-show="templateError">
                                    {{$t("Itinerary.builder.templatecreate.select_days_error", { numDays: itinNumDays })}}
                                </div>

                            </div>
                            <div class="form-group">
                                <label class="control-label" >{{$t("Itinerary.builder.settings.duration")}}:</label>
                                    <div class="table-cell">
                                        <input class="form-control" type="text" v-model="itData.duration">
                                    </div>
                            </div>
                            <div class="form-group" :class="{ 'has-error': $v.$dirty && $v.itData.numDays.$error}">
                                <label class="control-label">{{$t("Itinerary.builder.templatecreate.days")}} *:</label>
                                <div class="input-group">
                                    <input type="number" class="form-control" id="numdays" name="numDays"
                                           :class="{'is-invalid' : $v.itData.numDays.$error}"
                                           v-model.trim="$v.itData.numDays.$model">
                                    <button class="btn btn btn-primary ml-1" :disabled="itData.numDays <= 1" @click.stop.prevent="itData.numDays--">
                                        <i class="fa fa-minus"></i>
                                    </button>
                                    <button class="btn btn btn-primary ml-1" @click.stop.prevent="itData.numDays++">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                    <br/>
                                    <div class="invalid-feedback" v-show="$v.itData.numDays.$error && !$v.itData.numDays.required">
                                        {{$t("Itinerary.builder.templatecreate.field_required")}}
                                    </div>
                                    <div class="invalid-feedback" v-show="$v.itData.numDays.$error && !$v.itData.numDays.minValue">
                                        {{$t("Itinerary.builder.templatecreate.num_days_minvalue")}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label" >{{$t("Itinerary.builder.templatecreate.countries")}}:</label>
                                <div class="d-flex">
                                    <el-select v-model="itData.countries"
                                               default-first-option
                                               filterable
                                               clearable
                                               multiple
                                               :placeholder='$t("Itinerary.builder.templatecreate.select_countries")'
                                               style="width:100%;color:black;font-size: 1rem;"
                                    >
                                        <el-option v-for="country in countryList"
                                                   :key="country.id"
                                                   :label="country.name"
                                                   :value="country.id"
                                        >
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label" >{{$t("Itinerary.builder.templatecreate.currency")}}:</label>
                                <div class="d-flex">
                                    <el-select v-model="itData.currencyCode"
                                               filterable
                                               default-first-option
                                               :placeholder='$t("Itinerary.builder.templatecreate.currency_select")'
                                               style="width:100%;color:black;font-size: 1rem;"
                                    >
                                        <el-option v-for="curr in currencyList"
                                                   :key="curr.code"
                                                   :label="curr.label"
                                                   :value="curr.code"
                                        >
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label" >{{$t("Itinerary.builder.settings.cost")}}:</label>
                                    <div class="table-cell">
                                        <input class="form-control" type="number" v-model.number="itData.cost">
                                    </div>
                                    <div class="table-cell pl-2">EUR</div>
                            </div>
                            <div class="form-group">
                                <label class="control-label" >{{$t("Itinerary.builder.settings.trip_type")}}:</label>
                                <div class="d-flex">
                                    <select class="form-control"
                                            v-model="itData.tripType"
                                    >
                                        <option v-for="type in tripTypeList" :value="type.value" :key="type.value">{{type.label}}</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="templateErrorMsg" class="alert alert-danger" v-html="templateErrorMsg">
                            </div>
                            <v-button type="submit"
                                      :disabled="createDisabled"
                                      :loading="loading"
                                      class="btn btn-primary float-right"><i class="fa fa-save" aria-hidden="true"></i>&nbsp;
                                {{$t("Itinerary.builder.templatecreate.create")}}
                            </v-button>
                            <a :href="itinerariesUrl" class="btn btn-link float-right" style="margin-right: 10px;">
                                {{$t("Itinerary.builder.templatecreate.cancel")}}</a>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {itinerary_i18n} from "../../main"

    import {handleAjaxError, showError} from "../../utils";
    import { required, maxLength, minValue } from 'vuelidate/lib/validators';

    import vButton from '../../components/VButton';
    import ClientModal from '../client/ClientModal.vue';
    import {
        EMPTY_ITINERARY_TRIP_DATA, getDaySections,
        refactorSectionsWithNewDates, SECTION_TYPE_DAY, upgradeItineraryToVersion2,
    } from "./itinerary-templates";
    import {getDateFormatOptions, parseMoment} from "../../date-utils";
    import {getLanguageOptions} from "../../language-utils";
    import {getTripTypeOptions} from "./itinerary-utils";

    export default {
        components: {
            vButton,
            ClientModal,
        },
        data() {
            return {
                dateFormats: getDateFormatOptions(),
                languageOptions: getLanguageOptions(),

                itData: {
                    name: null,
                    numDays: 1,
                    dateFormat: "dd-MM-yyyy",
                    countries: null,
                    currencyCode: "EUR",
                    languageCode: "en",
                    tripType: "ITINERARY_IDEA",
                    cost: 0,
                    duration: ''
                },

                copyFromId: null,
                templateId: null,
                templateData: null,
                showTemplateDaysPicker: false,
                useTemplateDays: [],
                // templateDayList: [],

                clientList: [],
                countryList: [],
                currencyList: [],
                itinList: [],

                showClientModal: false,

                loading: false,

                templateError: false,
                templateErrorMsg: null,
                // templateWarningMsg: null,

                createDisabled: false,
            }
        },
        validations: {
            itData: {
                name: {
                    required,
                    maxLength: maxLength(255)
                },
                numDays: {
                    required,
                    minValue: minValue(1),
                },
            }
        },
        mounted() {
            // eslint-disable-next-line
            this.itData.dateFormat = dateFormat; // dateFormat esta declarado no itineraryCreate.scala.html
            if (typeof copyFrom !== 'undefined') {
                this.copyFromId = copyFrom; // copyFrom esta declarado no itineraryCreate.scala.html
            }
            this.loadClientList();
            this.loadItinerariesList();
            this.loadCountryList();
            this.loadCurrencies();
            this.loadAgencyData();
        },
        watch: {
            "itData.numDays"() {
                this.checkTemplateDates();
            }
        },
        computed: {
            tripTypeList() {
                const type = this.itData.tripType;
                const list = getTripTypeOptions(type);
                return list;
            },
            itinerariesUrl() {
                // eslint-disable-next-line
                return jsRoutes.controllers.ItineraryController.itineraries().url;
            },
            itinUrl() {
                const self = this;
                let itin = this.itinList.find(itin => {
                    return itin.id === self.templateId;
                });
                if (itin) {
                    // eslint-disable-next-line
                    return jsRoutes.controllers.ItineraryController.detailItinerary(itin.uid).url;
                } else {
                    return "#"; // this shouldn't happen
                }
            },
            templateDayList() {
                if (this.templateData)
                    return getDaySections(this.templateData.tripData.sections);
                else
                    return [];
            },
        },
        methods: {
            getDayLabel(index) {
                return this.$t("v2.Itinerary.defaults.DAY") + " " + (index + 1);
            },
            getItinLabel(itin) {
                if (itin.numDays) {
                    let label = itin.name + " (" + itin.numDays + " " + this.$t("Itinerary.general.days");
                    if (itin.startDate && itin.endDate) {
                        label += ", " + itin.startDate + " - " + itin.endDate + ")"
                    } else {
                        label += ")"
                    }
                    return label;
                } else if (itin.startDate && itin.endDate) {
                    const startMoment = parseMoment(itin.startDate, itin.dateFormat);
                    const endMoment = parseMoment(itin.endDate, itin.dateFormat);
                    const numDays = endMoment.diff(startMoment, "days") + 1;
                    return itin.name + " (" + numDays + " " + this.$t("Itinerary.general.days") + ", " + itin.startDate + " - " + itin.endDate + ")";
                } else {
                    return itin.name;
                }
            },
            setDates() {
                this.checkTemplateDates();
            },
            loadItineraryDetails() {
                const self = this;
                if (this.templateId) {
                    this.createDisabled = true;
                    // eslint-disable-next-line
                    const url = jsRoutes.controllers.ItineraryController.loadItineraryDetails(this.templateId).url;
                    this.$http.get(url).then(function (response) {
                        if (!response.data.tripData.version) {
                            self.templateData = upgradeItineraryToVersion2(response.data);
                        } else {
                            self.templateData = response.data;
                        }
                        self.itData.name = self.templateData.name;
                        self.itData.numDays = self.templateData.numDays;
                        self.itData.countries = self.templateData.countries;
                        self.itData.tripType = self.templateData.tripType;
                        self.itData.dateFormat = self.templateData.dateFormat;
                        self.itData.currencyCode = self.templateData.currencyCode;
                        self.itData.languageCode = self.templateData.languageCode;

                        self.createDisabled = false;
                        self.useTemplateDays = [];
                        self.templateDayList.forEach(section => {
                            self.useTemplateDays.push(section.id);
                        });
                        self.checkTemplateDates();
                    }, function (error) {
                        self.createDisabled = false;
                        handleAjaxError(error);
                    });
                } else {
                    this.templateData = null;
                    this.checkTemplateDates();
                }
            },
            checkTemplateDates() {
                // console.log("### checkTemplateDates")
                this.createDisabled = true;
                this.templateError = false;
                // this.templateWarningMsg = null;
                this.templateErrorMsg = null;
                if (this.itData.numDays && this.templateId) {
                    const numDays = this.useTemplateDays.length;

                    if (this.itData.numDays < numDays) {
                        this.showTemplateDaysPicker = true;
                        this.templateError = true;
                    } else {
                        this.showTemplateDaysPicker = false;
                        this.templateError = false;
                    }
                } else if (this.templateId === null || this.templateId === "") {
                    this.showTemplateDaysPicker = false;
                }
                this.createDisabled = false;
            },
            openCreateClient() {
                this.showClientModal = true;
            },
            loadAgencyData() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.AdministrationController.getAgencyConfiguration().url;

                this.$http.get(url).then(function (response) {
                    //console.log("roles -> " + JSON.stringify(data));
                    self.itData.currencyCode = response.data.currencyCode;

                }).catch(function () {
                    // handle error
                    showError(
                        self.$t("error.general_title") ,
                        self.$t("error.general_failed_loading_data")
                    );
                });
            },
            loadClientList() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ItineraryController.clientListSelect().url;
                this.$http.get(url).then(function(response) {
                    self.clientList = response.data;
                }, function(error) {
                    handleAjaxError(error);
                });

            },
            loadCountryList() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.Application.countryListSelect().url;
                this.$http.get(url)
                    .then(function(response) {
                        self.countryList = response.data;
                    }).catch(function (error) {
                        handleAjaxError(error);
                    });

            },
            loadCurrencies() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.Application.currencyListSelect().url;
                this.$http.get(url)
                    .then(function (response) {
                        self.currencyList = response.data;
                    })
                    .catch(function (error) {
                        // handle error
                        handleAjaxError(error);
                    });
            },
            loadItinerariesList() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ItineraryController.getItineraryListSelect().url;
                this.$http.get(url)
                    .then(function(response) {
                        self.itinList = response.data;
                        if (self.copyFromId !== null) {
                            self.templateId = self.copyFromId;
                            self.loadItineraryDetails();
                        }
                    }).catch(function (error) {
                        handleAjaxError(error);
                    });

            },
            createItinerary: function () {
                this.$v.$touch();
                if (this.$v.$invalid || this.templateError || this.createDisabled) {
                    this.templateErrorMsg = this.$t("Itinerary.general.invalid_fields_error");
                    return;
                }
                this.doCreateItinerary();
            },
            doCreateItinerary: function () {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ItineraryController.doCreateTemplate().url;
                // Setting Itineraty Locale for Creating new Items
                itinerary_i18n.locale = self.itData.languageCode;

                this.itData.tripData = EMPTY_ITINERARY_TRIP_DATA();
                if (this.templateId) {
                    // this.itData.tripData.sections = updateSectionsDateFormat(this.itData.tripData.sections, self.oldDateFormat, self.itData.dateFormat);
                    let templateSections = this.templateData.tripData.sections.filter(section => {
                        return section.type !== SECTION_TYPE_DAY || self.useTemplateDays.includes(section.id);
                    });
                    this.itData.tripData.sections = refactorSectionsWithNewDates(
                        templateSections,
                        null,
                        null,
                        this.itData.dateFormat,
                        this.itData.numDays
                    );
                }

                this.itData.template = false;
                this.loading = true;
                this.$http.post(url, this.itData)
                    .then(function (response) {
                        // self.loading = false;
                        self.$v.$reset();
                        // console.log("response -> " + JSON.stringify(response.data));
                        // eslint-disable-next-line
                        window.location.href = jsRoutes.controllers.ItineraryController.detailTemplate(response.data.uid).url;
                    })
                    .catch(function (error) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(error);
                    });

            }
        }
    }
</script>

<style scoped>

</style>
