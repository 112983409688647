<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
                <button class="btn btn-danger"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
<!--
            <br/>
            <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                <i class="fa fa-arrows-alt"></i>
            </button>
-->
        </div>
        <div class="row">
            <div class="col-12">
                <h3 class="text-center">
                    <div class="table-cell">
                        <i class="fa" :class="subTypeIcon"></i>
                    </div>
                    <div class="table-cell" style="padding-left: 10px;">
                        <input :readonly="!editable" type="text" class="name-input" v-model="item.name" @blur="emitAutosave"/>
                    </div>
                </h3>
            </div>
        </div>
        <div class="row">
            <!--<div class="col-12">
                <div class="table-cell" style="padding-left: 10px;">
                    {{item.subType}} TODO select?
                </div>
            </div>-->
            <div class="col-12">
                <div class="table-cell airline-field">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           v-model="item.companyName"
                           v-tooltip="companyNamePlaceholder"
                           :placeholder="companyNamePlaceholder"
                           @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell location-field">
                    <div class="d-flex align-items-center">
                        <input :readonly="!editable"
                               class="mor-editable-field"
                               style="text-align: center;"
                               type="text"
                               :placeholder='$t("Itinerary.TripTransportation.tooltip.start_location")'
                               v-model="item.startLocation.city"
                               v-tooltip='$t("Itinerary.TripTransportation.tooltip.start_location")'
                               @blur="emitAutosave"
                        />
                        <button @click.prevent="openMapModal(item.startLocation)"
                                v-tooltip="mapBtnTooltip(item.startLocation)"
                                class="btn btn-primary btn-sm"
                                style="width:35px;"
                        >
                            <i class="fa fa-map"></i>
                        </button>
                    </div>
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           style="text-align: center;padding-right:35px;"
                           type="text"
                           :placeholder='$t("Itinerary.TripTransportation.tooltip.start_time")'
                           v-model="item.startTime"
                           @blur="emitAutosave"
                    />
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           style="text-align: center;padding-right:35px;"
                           type="text"
                           :placeholder='$t("Itinerary.TripTransportation.tooltip.phone")'
                           v-model="item.startLocation.phone"
                           @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell location-field">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           style="text-align: center"
                           type="text"
                           :placeholder='$t("Itinerary.TripTransportation.tooltip.duration")'
                           v-model="item.duration"
                           v-tooltip='$t("Itinerary.TripTransportation.tooltip.duration")'
                           @blur="emitAutosave"
                    />
                    <br/>
                    <i class="fa fa-arrow-right fa-2x"></i>
                </div>
                <div class="table-cell" style="vertical-align: middle;font-size:1.2em;">
                    <div class="d-flex align-items-center">
                        <input :readonly="!editable"
                               class="mor-editable-field"
                               style="text-align: center;"
                               type="text"
                               :placeholder='$t("Itinerary.TripTransportation.tooltip.end_location")'
                               v-model="item.endLocation.city"
                               v-tooltip='$t("Itinerary.TripTransportation.tooltip.end_location")'
                               @blur="emitAutosave"
                        />
                        <button v-if="editable" @click.prevent="openMapModal(item.endLocation)"
                                v-tooltip="mapBtnTooltip(item.endLocation)"
                                class="btn btn-primary btn-sm"
                                style="width:35px;"
                        >
                            <i class="fa fa-map"></i>
                        </button>
                    </div>
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           style="text-align: center;padding-right:35px;"
                           type="text"
                           :placeholder='$t("Itinerary.TripTransportation.tooltip.end_time")'
                           v-model="item.endTime"
                           @blur="emitAutosave"
                    />
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           style="text-align: center;padding-right:35px;"
                           type="text"
                           :placeholder='$t("Itinerary.TripTransportation.tooltip.phone")'
                           v-model="item.endLocation.phone"
                           @blur="emitAutosave"
                    />
                </div>
            </div>
            <div class="col-12" style="margin-top:10px;">
                <div class="table-cell">
                    <!--<i class="fa fa-map"></i>-->
                    {{$t("Itinerary.TripTransportation.tooltip.distance")}}:
                </div>
                <div class="table-cell" style="padding-left: 10px; width:100px;">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           type="number"
                           v-model="item.distance"
                           :placeholder='$t("Itinerary.TripTransportation.tooltip.distance")'
                           @change="emitAutosave"
                    />
                </div>
                <div class="table-cell" style="padding-left: 10px;">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           type="text"
                           v-model="item.distanceUnits"
                           :placeholder='$t("Itinerary.TripTransportation.tooltip.distance_units")'
                           @blur="emitAutosave"
                    />
                </div>
            </div>
        </div>
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
        >
        </save-to-library-modal>
        <transportation-map-modal
                :show.sync="showMapModal"
                :location.sync="mapModalLocation"
        >
        </transportation-map-modal>
    </div>
</template>

<script>

    import {TRANSPORTATION_FLIGHT, TRANSPORTATION_TRAIN, TRANSPORTATION_TRANSFER} from "../itinerary-templates";
    import {autosaveMixin, itemCommonPropsMixin, moveItemMixin} from "./item-mixins";
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";
    import TransportationMapModal from "../modal/TransportationMapModal";

    export default {
        components: {
            TransportationMapModal,
            SaveToLibraryModal
        },
        mixins: [
            autosaveMixin,
            moveItemMixin,
            itemCommonPropsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showSetNameModal: false,

                showMapModal: false,
                mapModalLocation: null,
            }
        },
        computed: {
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library")
                    ;
            },
            subTypeIcon() {
                if (this.item.subType === TRANSPORTATION_FLIGHT) {
                    return 'fa-plane';
                } else if (this.item.subType === TRANSPORTATION_TRAIN) {
                    return 'fa-train';
                } else /*if (this.item.subType === TRANSPORTATION_TRANSFER)*/ {
                    return 'fa-shuttle-van';
                }
            },
            companyNamePlaceholder() {
                if (this.item.subType === TRANSPORTATION_FLIGHT) {
                    return 'Airline';
                } else {
                    return 'Company';
                }
            }
        },
        methods: {
            openMapModal(itemLocation) {
                this.mapModalLocation = itemLocation;
                this.showMapModal = true;
            },
            mapBtnTooltip(location) {
                if (location.lat && location.lng && location.address) {
                    return location.address + " ("+  this.$t("Itinerary.general.show_on_map") +")"
                } else {
                    return this.$t("Itinerary.TripTransportation.tooltip.set_address_on_map");
                }
            },
        }
    }
</script>

<style scoped>
    .airline-field {
        font-size:1.5em;
        width:200px;
        vertical-align: middle;
        border: 1px solid lightgray;
        border-radius: 5px;
    }

    .location-field {
        text-align: center;
        font-size:1.2em;
        vertical-align: middle;
        /*border: 1px solid lightgray;*/
        /*border-radius: 5px;*/
    }

</style>
