<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-primary"
                        @click="showSetNameModal = true"
                        v-tooltip="saveToLibraryLabel"
                >
                    <i class="fa fa-save"></i>
                </button>
                <button class="btn btn-danger"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
<!--
            <br/>
            <button class="btn btn-light drag-handle" style="margin-top: 5px;" v-tooltip="'Click and drag to move'">
                <i class="fa fa-arrows-alt"></i>
            </button>
-->
        </div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <input :readonly="!editable" type="text" class="name-input" v-model="item.title" @blur="emitAutosave"/>
                </h3>
            </div>
        </div>
        <div class="row" v-if="localTripType !== 'GROUP_TOUR'">
            <div class="col-12">
                <div class="table-cell">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripPrice.tooltip.price_per_person")'
                           v-model="item.adultPrice"
                           @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell">
                    {{itData.currencyCode}} {{$t("Itinerary.TripPrice.tooltip.per_person")}} ({{$t("Itinerary.TripPrice.tooltip.adult")}})
                </div>
            </div>
            <div class="col-12">
                <div class="table-cell">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripPrice.tooltip.price_per_person")'
                           v-model="item.childPrice"
                           @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell">
                    {{itData.currencyCode}} {{$t("Itinerary.TripPrice.tooltip.per_person")}} ({{$t("Itinerary.TripPrice.tooltip.child")}})
                </div>
            </div>
            <div class="col-12">
                <div class="table-cell">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripPrice.tooltip.price_per_group")'
                           v-model="item.groupPrice"
                           @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell">
                    {{itData.currencyCode}} {{$t("Itinerary.TripPrice.tooltip.per_group")}}
                </div>
            </div>
            <div class="col-12">
                <div class="table-cell">
                    <input :readonly="!editable"
                           class="mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripPrice.tooltip.single_price")'
                           v-model="item.singleSupplement"
                           @blur="emitAutosave"
                    />
                </div>
                <div class="table-cell">
                    {{itData.currencyCode}} {{$t("Itinerary.TripPrice.tooltip.single_supplement")}}
                </div>
            </div>
        </div>
        <div class="row" v-if="localTripType === 'GROUP_TOUR'">
            <div class="col-12">
                <table class="table" style="margin-top: 10px;">
                    <thead class="thead-light">
                    <tr>
                        <th v-if="editable" style="width:30px;">
                            &nbsp;
                        </th>
                        <th>
                            <input :readonly="!editable"
                                   type="text"
                                   class="name-input"
                                   v-model="item.colNumPersonsTitle"
                                   @blur="emitAutosave"
                                   v-tooltip='$t("Itinerary.general.click_to_edit")'
                            />
                        </th>
                        <th>
                            <input :readonly="!editable"
                                   type="text"
                                   class="name-input"
                                   v-model="item.colPricePerPersonTitle"
                                   @blur="emitAutosave"
                                   v-tooltip='$t("Itinerary.general.click_to_edit")'
                            />
                        </th>
                        <th v-if="editable" style="width:30px;">
                            &nbsp;
                        </th>
                    </tr>
                    </thead>
                    <!--<tbody>-->
                    <draggable :element="'tbody'" v-model="item.tableLines"
                               :options="{animation:150, disabled: !editable}"
                               @update="emitAutosave"
                    >
                        <tr v-for="(line, index) in item.tableLines">
                            <td v-if="editable">
                                <button class="btn btn-sm btn-default" v-tooltip='$t("Itinerary.TripPrice.tooltip.drag_to_reorder")'>
                                    <i class="fa fa-bars"></i>
                                </button>
                            </td>
                            <td>
                                <input :readonly="!editable"
                                       type="text"
                                       class="name-input"
                                       :placeholder='$t("Itinerary.TripPrice.tooltip.num_persons_placeholder")'
                                       v-model="line.numPersons"
                                       @blur="emitAutosave"
                                       v-tooltip='$t("Itinerary.general.click_to_edit")'
                                />
                            </td>
                            <td>
                                <input :readonly="!editable"
                                       type="text"
                                       class="name-input"
                                       :placeholder='$t("Itinerary.TripPrice.tooltip.price_per_person_placeholder")'
                                       v-model="line.price"
                                       @blur="emitAutosave"
                                       v-tooltip='$t("Itinerary.general.click_to_edit")'
                                />
                            </td>
                            <td v-if="editable">
                                <button class="btn btn-sm btn-danger"
                                        @click="deleteLine(index)"
                                        v-tooltip='$t("Itinerary.general.remove_line")'
                                >
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </draggable>
                    <!--</tbody>-->
                </table>
            </div>
            <div v-if="editable" class="col-12 text-right">
                <button class="btn btn-sm btn-primary" @click="addTableLine">
                    {{$t("Itinerary.general.add_line")}}
                </button>
            </div>
        </div>
        <draggable class="row" style="margin-top: 10px;" v-model="item.images" :options="{animation:150, disabled: !editable}">
            <div class="col-lg-6 col-md-6 col-sm-12" style="margin-top:10px; margin-bottom: 10px;"
                 v-for="image in item.images"
                 :key="image.id"
            >
                <button v-if="editable" class="btn btn-danger img-delete-btn d-print-none" @click="deleteImage(image)">
                    <i class="fa fa-trash"></i>
                </button>
                <img class="img-fluid w-100" :src="getImageUrl(image)">
                <div class="mor-image-caption">
                    <input :readonly="!editable"
                           type="text"
                           v-model="image.caption"
                           class="mor-image-caption-field"
                           :placeholder='$t("Itinerary.general.image_caption")'
                           @blur="updateImageCaption(image)"
                           @keyup.enter="updateImageCaption(image)"
                    />
                </div>
            </div>
        </draggable>
        <div v-if="editable" class="row d-print-none" style="margin-top: 10px;">
            <div class="col-12">
                <button style="margin-top: 10px;" class="btn btn-primary float-right" @click="showImageUpload = true">
                    {{$t("Itinerary.general.add_images")}}
                </button>
            </div>
        </div>
        <image-upload-modal
                :show.sync="showImageUpload"
                :images.sync="item.images"
                :item-tags="item.tags"
        >
        </image-upload-modal>
        <save-to-library-modal
                :show.sync="showSetNameModal"
                :item.sync="item"
                :extra-data="extraData"
        >
        </save-to-library-modal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import ImageUploadModal from '../modal/ImageUploadModal';
    import {showCustomDialog} from "../../../utils";
    import {autosaveMixin, imageUrlMixin, itemCommonPropsMixin, moveItemMixin} from "./item-mixins";
    import SaveToLibraryModal from "../modal/SaveToLibraryModal";

    export default {
        components: {
            draggable,
            ImageUploadModal,
            SaveToLibraryModal,
        },
        mixins: [
            autosaveMixin,
            imageUrlMixin,
            moveItemMixin,
            itemCommonPropsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            tripType: {
                type: String,
                required: false,
                default: function () { return "ITINERARY_IDEA" },
            },
            currency: {
                type: String,
                required: false,
                default: function () { return "EUR" },
            },
        },
        data() {
            return {
                showImageUpload: false,
                showSetNameModal: false,
                localTripType: "ITINERARY_IDEA", // var local copiada da prop, para poder ser alterada
                localCurrency: "EUR", // var local copiada da prop, para poder ser alterada
                // showButtons: false,
                extraData: {},
            }
        },
        watch: {
            "item.images"() {
                this.emitAutosave();
            },
            "tripType"() {
                this.localTripType = this.tripType;
            },
            "currency"() {
                this.localCurrency = this.currency;
            },
            "showSetNameModal"() {
                if (this.showSetNameModal) {
                    this.extraData = {
                        tripType: this.localTripType,
                        currencyCode: this.itData.currencyCode,
                    };
                }
            },
        },
        computed: {
            itData() {
                if (!this.libmode && this.$store) {
                    return this.$store.getters.itData;
                } else {
                    return {
                        currencyCode: this.localCurrency,
                        tripType: this.localTripType,
                    }
                }
            },
            saveToLibraryLabel() {
                return this.item.libraryId !== undefined && this.item.libraryId !== null ?
                    this.$t("Itinerary.general.update_to_library") :
                    this.$t("Itinerary.general.save_to_library")
                    ;
            },
        },
        mounted() {
            this.localTripType = this.tripType;
            this.localCurrency = this.currency;
        },
        methods: {
            deleteLine(index) {
                this.item.tableLines.splice(index, 1);
                this.emitAutosave();
            },
            addTableLine() {
                this.item.tableLines.push(
                    {days: null, penalty: null}
                );
                this.emitAutosave();
            }
            /*handleUp(bullet, index) {
                if (index > 0) {
                    this.$refs["input" + (index-1)][0].focus();
                }
            },
            handleDown(bullet, index) {
                if (index < this.item.highlights.length-1) {
                    this.$refs["input" + (index+1)][0].focus();
                }
            },
            handleBackspace(bullet, index, event) {
                if (bullet.length === 0 && this.item.highlights.length > 1) {
                    // console.log("### DELETE " + index);
                    this.emitAutosave();
                    this.item.highlights.splice(index, 1);
                    if (index > 0) {
                        // console.log("### FOCUS " + (index-1));

                        this.$refs["input" + (index-1)][0].focus();
                    }
                    event.preventDefault();
                }
            },
            handleEnter(index) {
                // console.log("### ENTER " + index);
                this.emitAutosave();
                this.item.highlights.splice(index+1, 0, "");
                this.$nextTick(() => {
                    this.$refs["input" + (index+1)][0].focus();
                });
            }*/
        }
    }
</script>

<style scoped>
</style>
