import { render, staticRenderFns } from "./LibraryItemList.vue?vue&type=template&id=6e873210&scoped=true&"
import script from "./LibraryItemList.vue?vue&type=script&lang=js&"
export * from "./LibraryItemList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e873210",
  null
  
)

export default component.exports