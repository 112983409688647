<template>
    <div class="page-break-inside-avoid">
        <div class="row">
            <div class="col-12">
                <h3 class="text-center">
                    <div class="table-cell">
                        <i class="fa" :class="subTypeIcon"></i>
                    </div>
                    <div class="table-cell" style="padding-left: 10px;">
                        <input type="text" class="name-input" v-model="item.name"/>
                    </div>
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="table-cell airline-field">
                    <input class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           v-model="item.companyName"
                    />
                </div>
                <div class="table-cell location-field">
                    <input class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           :placeholder='$t("Itinerary.TripTransportation.tooltip.start_location")'
                           v-model="item.startLocation.city"
                    />
                    <input v-if="stringNotEmpty(item.startTime)"
                           class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           :placeholder='$t("Itinerary.TripTransportation.tooltip.start_time")'
                           v-model="item.startTime"
                    />
                    <input v-if="stringNotEmpty(item.startLocation.phone)"
                           class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           :placeholder='$t("Itinerary.TripTransportation.tooltip.phone")'
                           v-model="item.startLocation.phone"
                    />
                </div>
                <div class="table-cell location-field">
                    <input class="mor-editable-field"
                           style="text-align: center"
                           type="text"
                           v-model="item.duration"
                    />
                    <br/>
                    <i class="fa fa-arrow-right fa-2x"></i>
                </div>
                <div class="table-cell" style="vertical-align: middle;font-size:1.2em;">
                    <input class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           :placeholder='$t("Itinerary.TripTransportation.tooltip.end_location")'
                           v-model="item.endLocation.city"
                    />
                    <input v-if="stringNotEmpty(item.endTime)"
                           class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           :placeholder='$t("Itinerary.TripTransportation.tooltip.end_time")'
                           v-model="item.endTime"
                    />
                    <input v-if="stringNotEmpty(item.endLocation.phone)"
                           class="mor-editable-field"
                           style="text-align: center;"
                           type="text"
                           v-model="item.endLocation.phone"
                    />
                </div>
            </div>
            <div class="col-12" style="margin-top:10px;" v-if="stringNotEmpty(item.distance)">
                <div class="table-cell">
                    <!--<i class="fa fa-map"></i>-->
                    {{$t("Itinerary.TripTransportation.tooltip.distance")}}:
                </div>
                <div class="table-cell" style="padding-left: 10px; width:100px;">
                    {{item.distance}} {{item.distanceUnits}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {TRANSPORTATION_FLIGHT, TRANSPORTATION_TRAIN} from "../../itinerary-templates";
    import {stringUtilsMixin} from "../../item/item-mixins";

    export default {
        components: {
        },
        mixins: [
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                // showButtons: false,
            }
        },
        computed: {
            subTypeIcon() {
                if (this.item.subType === TRANSPORTATION_FLIGHT) {
                    return 'fa-plane';
                } else if (this.item.subType === TRANSPORTATION_TRAIN) {
                    return 'fa-train';
                } else /*if (this.item.subType === TRANSPORTATION_TRANSFER)*/ {
                    return 'fa-shuttle-van';
                }
            },
            companyNamePlaceholder() {
                if (this.item.subType === TRANSPORTATION_FLIGHT) {
                    return 'Airline';
                } else {
                    return 'Company';
                }
            }
        }
    }
</script>

<style scoped>
    .airline-field {
        font-size:1.5em;
        width:200px;
        vertical-align: middle;
        border: 1px solid lightgray;
        border-radius: 5px;
    }

    .location-field {
        text-align: center;
        font-size:1.2em;
        vertical-align: middle;
        /*border: 1px solid lightgray;*/
        /*border-radius: 5px;*/
    }

</style>
