<template>
    <div>
        <div class="row">
            <div class="col-10">
                <h3>
                    <div class="table-cell">
                        <i class="fa fa-h-square"></i>
                    </div>
                    <div class="table-cell" style="width:100%;padding-left: 10px;">
                        {{item.name}}
                    </div>
                </h3>
            </div>
        </div>
        <div class="row" v-if="showStars">
            <div class="col-12">
                <star-rating v-model="item.stars" :read-only="true" :show-rating="false" :star-size="20"></star-rating>
            </div>
        </div>
        <div class="row">
            <div class="col-12" style="padding-top: 5px;" v-if="stringNotEmpty(item.hotelType)">
                <div class="table-cell">
                    {{$t("Itinerary.TripSleep.tooltip.type")}}: &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div class="table-cell" style="width:100%;">
                    {{item.hotelType}}
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-12" v-if="item.googleRating !== undefined && item.googleRating !== null">
                <div class="table-cell">
                    {{$t("Itinerary.general.google_rating")}}:
                </div>
                <div class="table-cell">
                    <star-rating v-model="item.googleRating"
                                 :show-rating="true"
                                 :star-size="15"
                                 :read-only="true"
                                 :increment="0.1"
                    ></star-rating>
                </div>
            </div>
            <div class="col-12" style="padding-top: 5px;"
                 v-if="stringNotEmpty(item.description)"
                 v-html="cleanupString(item.description)"
            >
                <!--<textarea-autosize
                        class="form-control body-font-size"
                        :placeholder='$t("Itinerary.TripSleep.tooltip.description")'
                        v-model="item.description"
                        :min-height="30"
                        :max-height="500"
                        @blur.native="saveItinerary"
                ></textarea-autosize>-->
                <!--<textarea class="form-control" placeholder="Description" v-model="item.description"  @blur="emitAutosave"></textarea>-->
            </div>
            <div class="col-12" style="padding-top: 5px;" v-if="stringNotEmpty(item.address)">
                <div class="table-cell">
                    <i class="fa fa-globe"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    {{item.address}}
                </div>
            </div>
            <div class="col-12" v-if="stringNotEmpty(item.phone)">
                <div class="table-cell icon-left">
                    <i class="fa fa-phone"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    {{item.phone}}
                </div>
            </div>
            <div class="col-12" style="padding-top: 5px;" v-if="stringNotEmpty(item.website)">
                <div class="table-cell">
                    <a :href="item.website" target="_blank"><i class="fa fa-mail-bulk"></i></a>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    <a :href="item.website">{{item.website}}</a>
                </div>
            </div>
            <div class="col-12" v-if="stringNotEmpty(item.checkin)">
                <div class="table-cell icon-left">
                    <i class="fa fa-sign-in-alt"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    <input class="bullet-item mor-editable-field"
                           type="text"
                           :placeholder='$t("Itinerary.TripSleep.tooltip.check_in_time")'
                           v-model="item.checkin"
                           @blur="emitAutosave"
                    />
                </div>
            </div>
            <div class="col-12" v-if="stringNotEmpty(item.checkout)">
                <div class="table-cell icon-left">
                    <i class="fa fa-sign-out-alt"></i>
                </div>
                <div class="table-cell bullet-right" style="padding-left: 10px;">
                    {{item.checkout}}
                </div>
            </div>
            <div class="col-12" style="padding-top: 5px;" v-if="stringNotEmpty(item.mealPlan)">
                <div class="table-cell" style="white-space: nowrap;">
                    {{$t("Itinerary.TripSleep.tooltip.meal_plan")}}: &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div class="table-cell" style="width:100%;">
                    {{item.mealPlan}}
                </div>
            </div>
            <div class="col-12" style="padding-top: 5px; padding-bottom: 5px;" v-if="item.rooms">
                <div class="table-cell">
                    {{$t("Itinerary.TripSleep.tooltip.rooms")}}:
                </div>
                <div class="bullet-right table-cell">
                    {{item.rooms}}
                </div>
            </div>
            <div class="col-12">
                <div v-for="(name, index) in item.roomTypes" v-if="stringNotEmpty(name)">
                    <div class="table-cell" style="width:80px;">
                        {{$t("Itinerary.TripSleep.tooltip.room")}} {{index+1}}: {{name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row photos-alternative-row">
            <div class="col-6 photos-col"
                 v-for="image in item.images.slice(0,2)"
                 :key="image.id"
            >
                <img class="img-fluid w-100" :src="getImageUrl(image)">
                <div class="mor-image-caption" v-if="stringNotEmpty(image.caption)">
                    <input type="text"
                           v-model="image.caption"
                           class="mor-image-caption-field"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {imageUrlMixin, stringUtilsMixin} from "../../item/item-mixins";

    export default {
        components: {
        },
        mixins: [
            imageUrlMixin,
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                roomOptions: [
                    "Single",
                    "Twin",
                    "Double",
                    "Suite",
                ],
                hotelTypeOptions: [
                    "Hotel",
                    "B&B",
                    "Hostel",
                    "Winery",
                    "Apartment",
                    "Private Villa",
                ],
                mealPlanOptions: [
                    "Self Catering (SC)",
                    "Breakfast",
                    "Half Board (HB)",
                    "Full Board (FB)",
                    "All Inclusive (AI)",
                ],

            }
        },
        computed: {
            showStars() {
                return this.item.hotelType &&
                    (this.item.hotelType === "Hotel" || this.item.hotelType === "B&B");
            }
        },
        mounted() {
        },
        beforeDestroy() {
        },
        methods: {
        },
    }
</script>

<style scoped>
    .icon-left {
        width: 25px;
        text-align: center;
    }
</style>
