<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3>
                    <div class="table-cell">
                        <i class="fa fa-h-square"></i> ({{$t("Itinerary.TripSleep.tooltip.night")}})
                    </div>
                    <div class="table-cell" style="padding-left: 10px;">
                        {{parentItem.name}}
                    </div>
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
    import {getParentItemById} from "../../itinerary-templates";

    export default {
        components: {
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
            sections: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
            }
        },
        computed: {
            parentItem() {
                return getParentItemById(this.sections, this.item.parentId);
            }
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>
