import { render, staticRenderFns } from "./MobileAppConfiguration.vue?vue&type=template&id=6c2b66ff&scoped=true&"
import script from "./MobileAppConfiguration.vue?vue&type=script&lang=js&"
export * from "./MobileAppConfiguration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c2b66ff",
  null
  
)

export default component.exports