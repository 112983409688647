<template>
    <div>
        <div v-if="!libmode && editable" class="d-print-none trip-item-button-bar">
            <div class="btn-group" role="group">
                <div class="btn-group dropleft">
                    <button class="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            v-tooltip='$t("Itinerary.general.move_to_other_section")'
                    >
                        <i class="fa fa-angle-double-left"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button v-for="section in sections"
                                :key="section.id"
                                class="dropdown-item"
                                :class="{'disabled': section.id === currentSectionId}"
                                @click="moveItem(section.id)"
                        >
                            {{section.title}}
                        </button>
                    </div>
                </div>
                <button class="btn btn-danger"
                        @click="$emit('delete-item', item)"
                        v-tooltip='$t("Itinerary.general.remove_item")'>
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <h3>
                            {{ $t('Itinerary.general.link') }}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="icon-left table-cell has-tooltip">
                <i class="fa fa-font"></i>
                </div>
                <div class="table-cell pl-2">
                    <input
                        :readonly="!editable"
                        type="text"
                        class="name-input"
                        :placeholder="$t('Itinerary.general.label')"
                        v-model="item.title"
                        @blur="emitAutosave"
                    />
                </div>
            </div>
            <div class="col-12">
                <div class="icon-left table-cell has-tooltip">
                <i class="fa fa-link"></i>
                </div>
                <div class="table-cell pl-2">
                    <input
                        :readonly="!editable"
                        type="text"
                        class="name-input"
                        :placeholder="$t('Itinerary.general.link')"
                        v-model="item.url"
                        @blur="emitAutosave"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {autosaveMixin, itemCommonPropsMixin, moveItemMixin, stringUtilsMixin} from "./item-mixins";
    import { itineraryEventBus } from '../../../event-bus';

    export default {
        components: {
        },
        mixins: [
            autosaveMixin,
            moveItemMixin,
            itemCommonPropsMixin,
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
            }
        }
    }
</script>
