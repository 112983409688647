<template>
    <div class="row">
        <div class="col-sm-10 mx-auto">

            <div class="card">
                <div class="card-header">
                    <h1>{{$t("MobileAppsConfiguration.title")}}</h1>
                </div>
                <div class="card-body">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <v-button v-on:click="requestBuild"
                                      :disabled="buildDisabled"
                                      :loading="requestLoading"

                                      class="btn btn-primary float-right"
                            >
                                <i class="fa fa-sync-alt" aria-hidden="true"></i>&nbsp;{{$t("MobileAppsConfiguration.button.request_new_build")}}
                            </v-button>
                            <div v-if="buildDisabled" class="alert alert-warning float-left">
                                {{buildButtonTooltip}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped table-hover">

                                <thead>
                                <tr>
                                    <th>{{$t("MobileAppsConfiguration.table.request_date")}}</th>
                                    <th>{{$t("MobileAppsConfiguration.table.status")}}</th>
                                    <th>{{$t("MobileAppsConfiguration.table.feedback")}}</th>
                                    <th style="width:180px;"></th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr v-for="b in builds">
                                    <td>{{ b.requestDate }}</td>
                                    <td>{{ b.requestStatus }}</td>
                                    <td>{{ b.requestResponse }}</td>
                                    <td style="width:200px;">
                                        <button v-if="b.requestStatus==='PENDING'" v-on:click.prevent="cancelBuild(b)"
                                                type="button"
                                                class="btn btn-danger ml-1">
                                            <i class="fa fa-trash"></i> {{$t("MobileAppsConfiguration.button.cancel_build")}}
                                        </button>
                                    </td>
                                </tr>
                                <tr v-if="builds.length === 0">
                                    <td colspan="4">
                                        {{$t("MobileAppsConfiguration.table.no_build_request")}}.
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-2">
                <div class="card-header">
                    <h1>{{$t("MobileAppsConfiguration.title2")}}</h1>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <form v-on:submit.prevent="saveConfig" novalidate>
                                <div class="form-group">
                                    <label for="agencyName" class="control-label" >{{$t("MobileAppsConfiguration.fields.agencyName")}}</label>
                                    <input type="text" class="form-control" id="agencyName" name="agencyName"
                                           v-model.trim="config.companyName" disabled>
                                </div>

                                <div class="form-group" :class="{ 'has-error': $v.config.aboutField.$error }">
                                    <label for="about" class="control-label" >{{$t("MobileAppsConfiguration.fields.about_agency")}} *</label>
                                    <textarea type="text" class="form-control" id="about" name="about"
                                           :class="{'is-invalid' : $v.config.aboutField.$error}"
                                              v-model.trim="$v.config.aboutField.$model"></textarea>
                                    <!--<small class="form-text text-muted">
                                        TODO helper text
                                    </small>-->
                                    <div class="invalid-feedback" v-show="$v.config.aboutField.$error && !$v.config.aboutField.required">{{$t("MobileAppsConfiguration.fields.about_agency_required")}}</div>
                                </div>

                                <div class="form-group" :class="{ 'has-error': $v.config.primaryColor.$error }">
                                    <label for="color" class="control-label" >{{$t("MobileAppsConfiguration.fields.primary_color")}}</label>
                                    <input type="color" class="form-control" id="color" name="color"
                                           :class="{'is-invalid' : $v.config.primaryColor.$error}"
                                              v-model.trim="$v.config.primaryColor.$model">
                                    <div class="invalid-feedback" v-show="$v.config.primaryColor.$error && !$v.config.primaryColor.required">{{$t("MobileAppsConfiguration.fields.primary_color_required")}}</div>
                                </div>

                                <div class="form-group" :class="{ 'has-error': $v.config.backgroundColor.$error }">
                                    <label for="backgroundColor" class="control-label" >{{$t("MobileAppsConfiguration.fields.background_color")}}</label>
                                    <input type="color" class="form-control" id="backgroundColor" name="backgroundColor"
                                           :class="{'is-invalid' : $v.config.backgroundColor.$error}"
                                              v-model.trim="$v.config.backgroundColor.$model">
                                    <div class="invalid-feedback" v-show="$v.config.backgroundColor.$error && !$v.config.backgroundColor.required">{{$t("MobileAppsConfiguration.fields.background_color_required")}}</div>
                                </div>

                                <v-button type="submit" :loading="loading" class="btn btn-primary float-right">
                                    <i class="fa fa-save" aria-hidden="true"></i>&nbsp;{{$t("MobileAppsConfiguration.button.update_configuration")}}
                                </v-button>
                                <a :href="indexUrl" class="btn btn-link float-right" style="margin-right: 10px;">
                                    {{$t("MobileAppsConfiguration.button.cancel")}}</a>

                            </form>
                        </div>
                        <div class="col-sm-3 col-xs-6">
                            <label class="control-label">{{$t("MobileAppsConfiguration.fields.mobile_logo")}}</label>
                            <!--<picture-input
                                    ref="pictureInput"
                                    margin="16"
                                    accept="image/jpeg,image/png"
                                    size="10"
                                    button-class="btn btn-primary"
                                    remove-button-class="btn btn-danger"
                                    :crop="false"
                                    :prefill="imageUrl"
                                    :removable="true"
                                    :custom-strings='customStrings'
                                    @change="onPictureChange"
                                    @remove="onPictureRemove"
                            >
                            </picture-input>-->
                            <img v-if="config.logoSysfilename && !logoShowCropper"
                                 :src="logoImageUrl"
                                 style="min-width:200px;width:200px;height:200px;"
                            />
                            <img v-if="!config.logoSysfilename"
                                 src="/assets/images/no-image.png"
                                 style="min-width:200px;width:200px;height:200px;"
                            />
                            <div class="ml-2">
                                {{$t("root.upload_new_image")}}
                                <br/>
                                <button class="btn btn-primary" @click="logoChooseFile">{{$t("root.choose_file")}}</button>
                                <input type="file" class="d-none" ref="logoFileInput" v-on:change.prevent="logoSetImage"/>
                                <hr/>
                                <v-button v-if="config.logoSysfilename"
                                          class="btn btn-danger"
                                          :loading="logoLoadingRemove"
                                          @click.native="logoRemoveImage"
                                >
                                    {{$t("root.remove_image")}}
                                </v-button>
                            </div>
                            <image-crop-modal :show.sync="logoShowCropper"
                                              :img-src="logoImgSrc"
                                              :view-mode="0"
                                              v-on:cropped="logoSetCropped">
                            </image-crop-modal>
                        </div>
                        <div class="col-sm-3 col-xs-6">
                            <label class="control-label">{{$t("MobileAppsConfiguration.fields.mobile_icon")}}</label>
                            <!--<picture-input
                                    ref="pictureInputIcon"
                                    margin="16"
                                    accept="image/jpeg,image/png"
                                    size="10"
                                    button-class="btn btn-primary"
                                    remove-button-class="btn btn-danger"
                                    :crop="false"
                                    :prefill="imageUrlIcon"
                                    :removable="true"
                                    :custom-strings='customStrings'
                                    @change="onPictureIconChange"
                                    @remove="onPictureIconRemove"
                            >
                            </picture-input>-->
                            <img v-if="config.iconSysfilename && !iconShowCropper"
                                 :src="iconImageUrl"
                                 style="min-width:200px;width:200px;height:200px;"
                            />
                            <img v-if="!config.iconSysfilename"
                                 src="/assets/images/no-image.png"
                                 style="min-width:200px;width:200px;height:200px;"
                            />
                            <div class="ml-2">
                                {{$t("root.upload_new_image")}}
                                <br/>
                                <button class="btn btn-primary" @click="iconChooseFile">{{$t("root.choose_file")}}</button>
                                <input type="file" class="d-none" ref="iconFileInput" v-on:change.prevent="iconSetImage"/>
                                <hr/>
                                <v-button v-if="config.iconSysfilename"
                                          class="btn btn-danger"
                                          :loading="iconLoadingRemove"
                                          @click.native="iconRemoveImage"
                                >
                                    {{$t("root.remove_image")}}
                                </v-button>
                            </div>
                            <image-crop-modal :show.sync="iconShowCropper"
                                              :img-src="iconImgSrc"
                                              :view-mode="0"
                                              v-on:cropped="iconSetCropped">
                            </image-crop-modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import {handleAjaxError, showCustomDialog, showError, showSuccess, stringNotEmpty} from "../../utils";
    import { required, email, maxLength, numeric, url } from 'vuelidate/lib/validators';
    import vButton from '../../components/VButton';
    import PictureInput from 'vue-picture-input'
    import ImageCropModal from "../../components/ImageCropModal";

    export default {
        components: {
            ImageCropModal,
            vButton,
            PictureInput,
        },
        // props: ["uuid"],
        data() {
            return {
                config: {
                    aboutField: null,
                    primaryColor: "#027be3",
                    backgroundColor: "#e9e9e9",
                    logoSysfilename: null,
                    iconSysfilename: null,
                },
                builds: [],

                requestLoading: false,

                loading: false,

                logoImageUrl: null,
                logoShowCropper: false,
                logoImgSrc: '',
                logoImgCropped: '',
                logoLoading: false,
                logoLoadingRemove: false,

                iconImageUrl: null,
                iconShowCropper: false,
                iconImgSrc: '',
                iconImgCropped: '',
                iconLoading: false,
                iconLoadingRemove: false,
            }
        },
        validations: {
            config: {
                aboutField: {
                    required,
                    maxLength: maxLength(100),
                },
                primaryColor: {
                    required,
                    maxLength: maxLength(9),
                },
                backgroundColor:{
                    required,
                    maxLength: maxLength(9),
                },
            }
        },
        mounted() {
            this.loadData();
        },
        computed: {
            customStrings() {
                return {
                    upload: '<h1>Bummer!</h1>',
                    drag: '<i class=\'fa fa-plus-circle\'></i><br/>' + this.$t('MobileAppsConfiguration.fields.imagesHelp')
                }
            },
            indexUrl() {
                return jsRoutes.controllers.Application.index().url;
            },
            buildDisabled() {
                return !(
                    stringNotEmpty(this.config.aboutField) &&
                    stringNotEmpty(this.config.primaryColor) &&
                    stringNotEmpty(this.config.backgroundColor) &&
                    this.config.logoSysfilename &&
                    this.config.iconSysfilename
                );
            },
            buildButtonTooltip() {
                return this.buildDisabled ? "To Request new builds, the Mobile App Configuration, including the images, needs to be filled" : null;
            },
            /*logoImageUrl() {
                if (this.config.logoSysfilename) {
                    return jsRoutes.controllers.Application.mobileImage(this.config.logoSysfilename).url + '?sys=' + new Date().getTime()
                } else {
                    return null;
                }
            },
            iconImageUrl() {
                if (this.config.iconSysfilename) {
                    return jsRoutes.controllers.Application.mobileImage(this.config.iconSysfilename).url + '?sys=' + new Date().getTime()
                } else {
                    return null;
                }
            }*/
        },
        methods: {
            loadData() {
                const self = this;

                jsRoutes.controllers.AdministrationController.getMobileAppConfiguration().ajax({
                    success : function(data) {
                        self.config = data;
                        if (!self.config.primaryColor) {
                            self.config.primaryColor = "#027be3";
                        }
                        if (!self.config.backgroundColor) {
                            self.config.backgroundColor = "#e9e9e9";
                        }
                        self.setLogoImageUrl();
                        self.setIconImageUrl();
                    },
                    error : function(error) {
                        /*showErrorMessage("There was a problem getting the email");
                        $.unblockUI();*/
                    }
                });
                this.loadBuildList();

            },
            loadBuildList() {
                const self = this;

                jsRoutes.controllers.AdministrationController.getBuildsList().ajax({
                    success : function(data) {
                        self.builds = data;
                    },
                    error : function(error) {
                        /*showErrorMessage("There was a problem getting the email");
                        $.unblockUI();*/
                    }
                });
            },
            requestBuild() {
                const self = this;
                showCustomDialog(
                    self.$t("MobileAppsConfiguration.messages.mobile_request_title"),
                    self.$t("MobileAppsConfiguration.messages.mobile_request_question"),
                    self.$t("MobileAppsConfiguration.messages.mobile_request_button"), "btn-primary", function () {
                        self.requestLoading= true;
                        jsRoutes.controllers.AdministrationController.requestNewMobileBuild().ajax({
                            success : function(data) {
                                self.requestLoading = false;
                                self.loadBuildList();
                            },
                            error : function(error) {
                                self.requestLoading = false;
                                handleAjaxError(error);
                            }
                        });

                 });
            },
            cancelBuild(build) {
                const self = this;
                showCustomDialog(
                    self.$t("MobileAppsConfiguration.messages.cancel_mobile_request_title"),
                    self.$t("MobileAppsConfiguration.messages.cancel_mobile_request_question"),
                    self.$t("MobileAppsConfiguration.messages.cancel_mobile_request_button"), "btn-danger", function () {

                        jsRoutes.controllers.AdministrationController.cancelBuildRequest(build.idmobileBuild).ajax({
                            success : function(data) {
                                self.loadBuildList();
                            },
                            error : function(error) {
                                self.loadBuildList();
                                handleAjaxError(error);

                            }
                        });

                 });
            },
            /*onPictureChange() {
                if (this.$refs.pictureInput.image) {
                    console.log('Picture loaded.');
                    this.config.logoSysfilename = this.$refs.pictureInput.file;
                }
            },
            onPictureIconChange() {
                if (this.$refs.pictureInput.image) {
                    console.log('Picture loaded.');
                    this.config.iconSysfilename = this.$refs.pictureInputIcon.file;
                }
            },
            onPictureRemove() {
                console.log('Picture remove.');
                this.config.removeImage = true;
            },
            onPictureIconRemove() {
                console.log('Picture remove.');
                this.config.removeImageIcon = true;
            },*/
            setLogoImageUrl() {
                if (this.config.logoSysfilename) {
                    // eslint-disable-next-line
                    this.logoImageUrl = jsRoutes.controllers.Application.mobileImage(this.config.logoSysfilename).url + '?sys=' + new Date().getTime();
                }
            },
            setIconImageUrl() {
                if (this.config.iconSysfilename) {
                    // eslint-disable-next-line
                    this.iconImageUrl = jsRoutes.controllers.Application.mobileImage(this.config.iconSysfilename).url + '?sys=' + new Date().getTime();
                }
            },
            logoRemoveImage() {
                const self = this;
                self.logoLoadingRemove = true;
                // eslint-disable-next-line
                let url = jsRoutes.controllers.AdministrationController.removeMobileImage().url
                const dataObj = {
                    icon: false,
                };
                this.$http.post(url, dataObj).then(response => {
                    self.logoLoadingRemove = false;
                    self.config.logoSysfilename = null;
                    self.setLogoImageUrl();
                    showSuccess(
                        self.$t("success.general_title"),
                        self.$t("root.image_removed")
                    )
                }, error => {
                    self.logoLoadingRemove = false;
                    handleAjaxError(error)
                })

            },

            logoChooseFile() {
                this.$refs.logoFileInput.click()
            },

            logoSetImage(e) {
                // console.log('going to setImage...')
                const self = this;
                const file = e.target.files[0]
                if (!file.type.includes('image/')) {
                    showError(
                        self.$t("error.general_title"),
                        self.$t("root.only_images_allowed")
                    )
                    return
                }
                if (typeof FileReader === 'function') {
                    const reader = new FileReader()
                    reader.onload = (event) => {
                        self.logoShowCropper = true;
                        self.logoImgSrc = event.target.result
                    }
                    reader.readAsDataURL(file)
                } else {
                    showError(
                        self.$t("root.api_not_supported")
                    )
                }
            },

            logoSetCropped(e) {
                let self = this
                this.logoImgCropped = e
                this.logoShowCropper = false
                // eslint-disable-next-line
                let url = jsRoutes.controllers.AdministrationController.uploadMobileImage().url;

                const dataObj = {
                    data: this.logoImgCropped,
                    icon: false,
                };
                this.$http.post(url, dataObj).then(response => {
                    showSuccess(
                        self.$t("success.general_title"),
                        self.$t("root.file_uploaded")
                    )
                    self.config.logoSysfilename = response.data;
                    self.$refs.logoFileInput.value = "";
                    self.setLogoImageUrl();
                }, error => {
                    handleAjaxError(error)
                });
            },

            iconRemoveImage() {
                const self = this;
                self.iconLoadingRemove = true;
                // eslint-disable-next-line
                let url = jsRoutes.controllers.AdministrationController.removeMobileImage().url
                const dataObj = {
                    icon: true,
                };
                this.$http.post(url, dataObj).then(response => {
                    self.iconLoadingRemove = false;
                    self.config.iconSysfilename = null;
                    self.setIconImageUrl();
                    showSuccess(
                        self.$t("success.general_title"),
                        self.$t("root.image_removed")
                    )
                }, error => {
                    self.iconLoadingRemove = false;
                    handleAjaxError(error)
                })

            },

            iconChooseFile() {
                this.$refs.iconFileInput.click()
            },

            iconSetImage(e) {
                // console.log('going to setImage...')
                const self = this;
                const file = e.target.files[0]
                if (!file.type.includes('image/')) {
                    showError(
                        self.$t("error.general_title"),
                        self.$t("root.only_images_allowed")
                    );
                    return
                }
                if (typeof FileReader === 'function') {
                    const reader = new FileReader()
                    reader.onload = (event) => {
                        self.iconShowCropper = true;
                        self.iconImgSrc = event.target.result
                    };
                    reader.readAsDataURL(file)
                } else {
                    showError(
                        self.$t("root.api_not_supported")
                    )
                }
            },

            iconSetCropped(e) {
                let self = this
                this.iconImgCropped = e
                this.iconShowCropper = false
                // eslint-disable-next-line
                let url = jsRoutes.controllers.AdministrationController.uploadMobileImage().url;

                const dataObj = {
                    data: this.iconImgCropped,
                    icon: true,
                };
                this.$http.post(url, dataObj).then(response => {
                    showSuccess(
                        self.$t("success.general_title"),
                        self.$t("root.file_uploaded")
                    )
                    self.config.iconSysfilename = response.data;
                    self.$refs.iconFileInput.value = "";
                    self.setIconImageUrl();
                }, error => {
                    handleAjaxError(error)
                });
            },

            saveConfig() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.log("### INVALID");
                    return;
                }
                this.doSaveConfig();
            },
            doSaveConfig: function () {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.AdministrationController.updateMobileConfiguration().url;

                const formData = new FormData();
                formData.append("about", this.config.aboutField);
                formData.append("primaryColor", this.config.primaryColor);
                formData.append("backgroundColor", this.config.backgroundColor);
                // formData.append("imageFile", this.config.logoSysfilename);
                // formData.append("imageFileIcon", this.config.iconSysfilename);
                // formData.append("removeImage", this.config.removeImage);
                // formData.append("removeImageIcon", this.config.removeImageIcon);

                this.loading = true;
                axios({
                    method: 'post',
                    url: url,
                    data: formData,
                    config: { headers: {'Content-Type': 'multipart/form-data' }}
                })
                    .then(function (/*response*/) {
                        self.loading = false;
                        self.$v.$reset();
                        showSuccess(
                            self.$t("success.general_title"),
                            self.$t("MobileAppsConfiguration.messages.configuration_updated")
                        );
                        self.loadData();
                    })
                    .catch(function (error) {
                        self.loading = false;
                        handleAjaxError(error);
                    });
            }
        }
    }
</script>

<style scoped>

</style>
