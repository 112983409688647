<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3 v-html="stringNotEmpty(item.placesOfVisit) ? cleanupString(item.placesOfVisit) : $t('Itinerary.TripDestinationInfo.tooltip.places_to_visit')">
                </h3>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;" v-if="stringNotEmpty(item.description)">
            <div class="col-12" v-html="cleanupString(item.description)">
                <!--<textarea-autosize
                        style="border: 0; padding: 0;color:black;"
                        class="form-control body-font-size"
                        placeholder="Type something here..."
                        v-model="item.description"
                        :min-height="30"
                        :max-height="500"
                ></textarea-autosize>-->
            </div>
        </div>
        <div class="row" style="margin-top: 20px;" v-if="hasEmergencyContacts && status === 'SOLD'">
            <div class="col-12">
                <h3>
                    <input type="text"
                           class="name-input"
                           v-model="item.emergencyContactsTitle"
                           />
                </h3>
            </div>
            <div class="col-12">
                <div v-for="(contact, index) in item.emergencyContacts"
                     class="d-flex justify-content-start align-items-center">
                    <div class="bullet-left">
                        <i class="fa fa-circle"></i>
                    </div>
                    <div style="padding-left: 5px;min-width:250px;width:250px;">
                        <input
                                class="bullet-item mor-editable-field"
                                :ref="'name' + index"
                                v-model="item.emergencyContacts[index].name"
                        />
                    </div>
                    <div style="padding-left: 5px;min-width:200px;width:200px;">
                        <input
                                class="bullet-item mor-editable-field"
                                :ref="'phone' + index"
                                v-model="item.emergencyContacts[index].phone"
                        />
                    </div>
                    <div style="padding-left: 5px;width:100%;">
                        <input
                                class="bullet-item mor-editable-field"
                                :ref="'desc' + index"
                                v-model="item.emergencyContacts[index].description"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {stringUtilsMixin} from "../../item/item-mixins";
    import {stringNotEmpty} from "../../../../utils";

    export default {
        components: {
        },
        mixins: [
            stringUtilsMixin,
        ],
        props: {
            item: {
                type: Object,
                required: true,
            },
            status: {
                type: String,
                required: false,
                default: function() { return "SOLD" },
            },
        },
        data() {
            return {
                // showButtons: false,
            }
        },
        mounted() {
            // this.setupMap();
        },
        methods: {
            hasEmergencyContacts() {
                if (this.item.emergencyContacts.length === 0) {
                    return false;
                } else {
                    let result = false;
                    this.item.emergencyContacts.some(contact => {
                        if (stringNotEmpty(contact.name)) {
                            result = true;
                            return true;
                        }
                    });
                    return result;
                }
            }
        }

    }
</script>

<style scoped>
</style>
