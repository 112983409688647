<template>
    <div class="modal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t("Itinerary.modal.PlacesAutocomplete.autocomplete_info")}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <input ref="autocomplete"
                           :placeholder='$t("Itinerary.modal.PlacesAutocomplete.type_to_search")'
                           class="form-control"
                           onfocus="value = ''"
                           type="text"/>
                    <div ref="map" class="map-container"></div>

                    <div ref="infowindowcontent" class="infowindow-content">
                        <img v-if="placeIcon" :src="placeIcon" width="16" height="16" class="mr-2">
                        <a v-if="placeWebsite" :href="placeWebsite" target="_blank">{{placeName}}</a>
                        <span v-else class="title">{{placeName}}</span>
                        <br>
                        <span>{{placeAddress}}</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-link" @click="close">{{$t("Itinerary.modal.PlacesAutocomplete.cancel")}}</button>
                    <v-button class="btn btn-primary" :loading="loading" style="margin-left: 5px;" @click.native="update">
                        {{$t("Itinerary.modal.PlacesAutocomplete.update_info")}}
                    </v-button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {
        generateUUID,
        getAdminAreaNameFromGoogleAdr,
        getCountryNameFromGoogleAdr, getLocalityNameFromGoogleAdr,
        handleAjaxError,
        showError
    } from "../../../utils";
    import axios from 'axios'
    import VButton from "../../../components/VButton";

    export default {
        components: {
            VButton,
        },
        props: {
            show: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                mapSetupDone: false,

                place: null,

                loading: false,
            }
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function (e) {
                self.$emit('update:show', false);
            });

        },
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                    if (!this.setupDone) {
                        this.setupMap();
                    } else {
                        this.$refs.autocomplete.value = "";
                    }
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
            }
        },
        computed: {
            itData() {
                return this.$store.getters.itData;
            },
            placeIcon() {
                if (this.place && this.place.icon) {
                    return this.place.icon;
                }
                return null;
            },
            placeAddress() {
                if (this.place && this.place.formatted_address) {
                    return this.place.formatted_address;
                }
                return "";
            },
            placeName() {
                if (this.place) {
                    return this.place.name;
                }
                return "";
            },
            placeWebsite() {
                if (this.place) {
                    return this.place.website;
                }
                return "";
            },
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            setupMap() {
                const self = this;
                let center = {
                    lat: 38.661,
                    lng: 4.065,
                };
                let map = new google.maps.Map(this.$refs.map, {
                    center: center,
                    zoom: 3,
                });

                let infowindow = new google.maps.InfoWindow();
                let infowindowContent = this.$refs.infowindowcontent;
                infowindow.setContent(infowindowContent);
                let marker = new google.maps.Marker({
                    map: map,
                    anchorPoint: new google.maps.Point(0, -29)
                });
                marker.addListener('click', function() {
                    infowindow.open(map, marker);
                });

                // Create the autocomplete object and associate it with the UI input control.
                // Restrict the search to the default country, and to place type "cities".
                let autocomplete = new google.maps.places.Autocomplete(
                    (this.$refs.autocomplete),
                    {
                        types: ['establishment'],
                        fields: [
                            "address_components",
                            "formatted_address",
                            "international_phone_number",
                            "geometry",
                            "icon",
                            "website",
                            "rating",
                            "id",
                            "place_id",
                            "url", // este nunca e' devolvido...tem de se usar o PlacesService.getDetails
                            "name",
                            "photos",
                        ]
                    }
                );
                autocomplete.addListener('place_changed', () => {
                    infowindow.close();
                    marker.setVisible(false);

                    self.place = autocomplete.getPlace();
                    // If the place has a geometry, then present it on a map.
                    if (self.place.geometry) {
                        if (self.place.geometry.viewport) {
                            map.fitBounds(self.place.geometry.viewport);
                        } else {
                            map.setCenter(self.place.geometry.location);
                            map.setZoom(17);  // Why 17? Because it looks good.
                        }
                        marker.setPosition(self.place.geometry.location);
                        marker.setVisible(true);
                        infowindow.open(map, marker);
                    }
                });

                this.mapSetupDone = true;
            },
            update() {
                const self = this;
                if (this.place) {
                    let data = {
                        name: this.place.name,
                        address: this.place.formatted_address ? this.place.formatted_address : "",
                        phone: this.place.international_phone_number ? this.place.international_phone_number : "",
                        website: this.place.website,
                        coordinates: {
                            lat: this.place.geometry.location.lat(),
                            lng: this.place.geometry.location.lng(),
                        },
                        googleId: this.place.id,
                        googlePlacesId: this.place.place_id,
                        googleRating: this.place.rating,
                        images: [],
                    };

                    let tags = [].concat(this.itData.countries);

                    const country = getCountryNameFromGoogleAdr(this.place.address_components);
                    const adminArea = getAdminAreaNameFromGoogleAdr(this.place.address_components);
                    const locality = getLocalityNameFromGoogleAdr(this.place.address_components);

                    if (country && !tags.includes(country))
                        tags.push(country);

                    if (adminArea && !tags.includes(adminArea))
                        tags.push(adminArea);

                    if (locality && !tags.includes(locality))
                        tags.push(locality);

                    if (!tags.includes(this.place.name))
                        tags.push(this.place.name);

                    data.tags = tags;

                    if (this.place.photos && this.place.photos.length > 0) {
                        let imgUrls = [];
                        this.place.photos.forEach((photo) => {
                            imgUrls.push(photo.getUrl());
                        });

                        const saveImagesObj = {
                            caption: this.place.name,
                            tags: tags,
                            urls: imgUrls,
                        };
                        const url = jsRoutes.controllers.LibraryController.googleImagesSave().url;

                        this.loading = true;
                        this.$http.post(url, saveImagesObj)
                            .then(function (response) {
                                response.data.forEach(img => {
                                    data.images.push({
                                        id: img.id,
                                        type: "LOCAL",
                                        caption: data.name,
                                    });
                                });

                                self.loading = false;
                                self.$emit('update-data', data);
                                self.close();
                            })
                            .catch(function (error) {
                                self.loading = false;
                                // handle error
                                handleAjaxError(error);
                            });
                    } else {
                        self.$emit('update-data', data);
                        self.close();
                    }
                }
            },

        }
    }
</script>

<style scoped>
    .map-container {
        height: 400px;
        width: 100%;
    }

    .infowindow-content .title {
        font-weight: bold;
    }

    .infowindow-content {
        display: none;
    }

    .map-container .infowindow-content {
        display: inline;
    }
</style>
