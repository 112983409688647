
// TIPOS DE SECCOES

import {itinerary_i18n} from "../../main";

import {generateUUID} from "../../utils";
import {
    formatMoment,
    formatToWeekDay,
    getDateMomentForDayNum,
    parseMoment,
    SECTION_DATE_FORMAT
} from "../../date-utils";

export const ITINERARY_CURRENT_VERSION = "2.0";

export const SECTION_TYPE_COVER = "COVER";
export const SECTION_TYPE_OTHER = "OTHER";
export const SECTION_TYPE_DAY = "DAY";

export const ITEM_TYPE_GENERIC = 'GENERIC';
export const ITEM_TYPE_COVER = 'COVER';
export const ITEM_TYPE_TOUR_NAME = 'TOUR_NAME';
export const ITEM_TYPE_CLIENT_INFO = 'CLIENT_INFO';
export const ITEM_TYPE_TRIP_HIGHLIGHTS = 'TRIP_HIGHLIGHTS';
export const ITEM_TYPE_TRIP_RATINGS = 'TRIP_RATINGS';
export const ITEM_TYPE_TRIP_FAQ = 'TRIP_FAQ';
export const ITEM_TYPE_TRIP_URL = 'TRIP_URL';
export const ITEM_TYPE_TRIP_VIDEO = 'TRIP_VIDEO';
export const ITEM_TYPE_TRIP_SOUNDCLOUD = 'TRIP_SOUNDCLOUD';
export const ITEM_TYPE_TRIP_DATES = 'TRIP_DATES';
export const ITEM_TYPE_TRIP_DESCRIPTION = 'TRIP_DESCRIPTION';
export const ITEM_TYPE_COMPANY_INFO = 'COMPANY_INFO';
export const ITEM_TYPE_DESTINATION_INFO = 'DESTINATION_INFO';
export const ITEM_TYPE_KNOW_BEFORE_GO = 'KNOW_BEFORE_GO';
export const ITEM_TYPE_POI = 'POI';
export const ITEM_TYPE_ACTIVITY = 'ACTIVITY';
export const ITEM_TYPE_TRANSPORTATION = 'TRANSPORTATION';
export const ITEM_TYPE_FLIGHT = 'FLIGHT';
export const ITEM_TYPE_CAR_RENTAL = 'CAR_RENTAL';
export const ITEM_TYPE_CAR_RENTAL_MID = 'CAR_RENTAL_MID';
export const ITEM_TYPE_CAR_RENTAL_LAST = 'CAR_RENTAL_LAST';
export const ITEM_TYPE_MEAL = 'MEAL';
export const ITEM_TYPE_PLACE= 'PLACE';
export const ITEM_TYPE_SLEEP = 'SLEEP';
export const ITEM_TYPE_SLEEP_MID = 'SLEEP_MID';
export const ITEM_TYPE_SLEEP_LAST = 'SLEEP_LAST';
export const ITEM_TYPE_PRICE = 'PRICE';
export const ITEM_TYPE_WHATS_INCLUDED = 'WHATS_INCLUDED';
export const ITEM_TYPE_NEXT_STEPS = 'NEXT_STEPS';
export const ITEM_TYPE_CANCELATION_POLICY = 'CANCELATION_POLICY';
export const ITEM_TYPE_CAROUSEL = 'CAROUSEL';
export const ITEM_TYPE_AUDIO = 'AUDIO';
export const ITEM_TYPE_VIDEO_FILE = 'VIDEO_FILE';

export const TRANSPORTATION_FLIGHT = 'FLIGHT';
export const TRANSPORTATION_TRAIN = 'TRAIN';
export const TRANSPORTATION_TRANSFER = 'TRANSFER';


/************ TRIP ITEMS ***************/

export function COVER_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_COVER,
        title: null,
        placesToVisit: null, // one line description
        image: {},
        mobileImage: {}
    };
}


export function GENERIC_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_GENERIC,
        title: "",
        details: "", // text area
        bullets: [
            ""
        ], // bullet list
    };
}


export function TOUR_NAME_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_TOUR_NAME,
        name: null,
        description: null, // one line description
        refNum: null, // number
    };
}


export function CLIENT_INFO_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_CLIENT_INFO,
        title: itinerary_i18n.t("v2.Itinerary.defaults.CLIENT_INFO_ITEM_title"),
        clients: [
            /*{
                name: null,
                email: null,
                phone: null,
            }*/
        ],
        pax: 1, // number
    };
}


export function TRIP_HIGHLIGHTS_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_TRIP_HIGHLIGHTS,
        title: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_TRIP_HIGHLIGHTS_title"),
        highlights: [ // bullets
            "",
            ""
        ],
    };
}
export function TRIP_RATINGS_ITEM_VALUE(name) {
    return { id: generateUUID(), value: 0, name: (name || 'manual'), manual: '' }
}

export function TRIP_RATINGS_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_TRIP_RATINGS,
        title: 'Rating',
        ratings: [ // bullets
            { id: generateUUID(), value: 0, name: 'child_friendly', manual: '' },
            { id: generateUUID(), value: 0, name: 'instagrammable', manual: '' }
        ],
    };
}


export function TRIP_FAQ_ITEM_VALUE() {
    return { id: generateUUID(), question: '', answer: '' }
}

export function TRIP_FAQ_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_TRIP_FAQ,
        title: 'FAQ',
        questions: [
            TRIP_FAQ_ITEM_VALUE()
        ],
    };
}

export function TRIP_URL_VALUE() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_TRIP_URL,
        url: '',
        title: ''
    }
}


export function TRIP_VIDEO_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_TRIP_VIDEO,
        url: ''
    }
}

export function TRIP_SOUNDCLOUD_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_TRIP_SOUNDCLOUD,
        url: ''
    }
}


export function TRIP_DESCRIPTION_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_TRIP_DESCRIPTION,
        title: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_TRIP_DESCRIPTION_title"),
        // dates
        description: null, // text area
        images: [],
    };
}


export function TRIP_DATES_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_TRIP_DATES,
        title: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_TRIP_DATES_title"),
    };
}


export function COMPANY_INFO_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_COMPANY_INFO,
        title: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_title"),
        tripManagerTitle: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_trip_manager"),
        description: null, // text area
        localTripManagerTitle: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_local_trip_manager"), // text
        localTripManagerName: null, // text
        localTripManagerEmail: null, // text
        localTripManagerPhone: null, // text
        emergencyContactsTitle: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_emergency_contacts"),
        emergencyContacts: [
            {
                name: null,
                phone: null,
                description: null,
            }
        ]
    };
}


export function DESTINATION_INFO_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_DESTINATION_INFO,
        placesOfVisit: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_DESTINATION_INFO_places_of_visit"), // text, one line
        description: null, // text area
        pois: [], // points of interest
        emergencyContactsTitle: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_DESTINATION_INFO_emergency_info"),
        emergencyContacts: [
            {
                name: null,
                phone: null,
                description: null,
            }
        ]
    };
}


export function KNOW_BEFORE_GO_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_KNOW_BEFORE_GO,
        packingListTitle: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_KNOW_BEFORE_GO_packing_list_title"),
        packingList: [ // bullets
            "",
            ""
        ],
        //  important to know before you go
        knowBeforeGoTitle: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_KNOW_BEFORE_GO_know_before_go_title"),
        knowBeforeGo: [ // bullets
            "",
            ""
        ]
    };
}


export function POI_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_POI,
        title: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_POI_title"),
        description: null, // text area
        descriptionPlace: null, // text area, texto a cinza
        images: []
    };
}


export function ACTIVITY_ITEM(included) {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_ACTIVITY,
        title: '',
        included: included, // true included, false optional
        list: [
            INDIVIDUAL_ACTIVITY(),
        ],
    };
}

export function INDIVIDUAL_ACTIVITY() {
    return {
        id: generateUUID(),
        contacts: [
            {
                name: null,
                phone: null,
                email: null
            }
        ],
        address: null,
        coordinates: {
            lat: null,
            lng: null,
        }, // obj ?
        startTime: null,
        description: null, // text
        description2: null, // text // DONE
        language: null, // text
        duration: "", // text
        priceType: "PER_PERSON", // FREE, PER_PERSON, PER_GROUP
        price: null, // double
        images: []
    };
}

export function TRANSPORTATION_ITEM(subtype) {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_TRANSPORTATION,
        subType: subtype, // FLIGHT, TRAIN, TRANSFER, BOAT, HELI...
        name: subtype.charAt(0) + subtype.toLowerCase().slice(1),
        startDate: null, // date //TODO remover?
        startTime: null, // time
        endDate: null, // date
        endTime: null, // time
        companyName: null, // airline/company name
        startLocation: {
            city: null,
            address: null,
            iata: null, // airport code
            icao: null, // airport code
            lat: null,
            lng: null,
            phone: null,
            countryName: null,
        },
        endLocation: {
            city: null,
            address: null,
            iata: null, // airport code
            icao: null, // airport code
            lat: null,
            lng: null,
            phone: null,
            countryName: null,
        },
        duration: null, // text
        distance: null, // number
        distanceUnits: "km",
        images: []
    };
}


export function FLIGHT_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_FLIGHT,
        name: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_FLIGHT_title"),
        list: [
            INDIVIDUAL_FLIGHT(),
        ],
    };
}


export function INDIVIDUAL_FLIGHT() {
    return {
        id: generateUUID(),
        connectionTime: null, // time between previous flight and this one, ignored on first flight
        startDate: null, // date //TODO remover?
        startTime: null, // time
        endDate: null, // date //TODO remover?
        endTime: null, // time
        airline: { // airline
            iata: null,
            icao: null,
            name: null,
            phoneNumber: null,
        },
        flightNumber: null, // flight number
        flightClass: null, // economy, business, etc
        reference: null, // ref code
        startLocation: {
            airportName: null,
            city: null,
            address: null,
            iata: null,
            icao: null,
            phone: null,
            lat: null,
            lng: null,
        },
        endLocation: {
            airportName: null,
            city: null,
            address: null,
            iata: null,
            icao: null,
            phone: null,
            lat: null,
            lng: null,
        },
        duration: null, // text
        distance: null, // number
        distanceUnits: "km",
        images: []
    };
}


export function CAR_RENTAL_ITEM() {
    return {
        id: generateUUID(),
        //parentId: parentId, //DONE removido
        type: ITEM_TYPE_CAR_RENTAL,
        name: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_CAR_RENTAL_title"),
        startDay: null,
        endDay: null,
        // startDate: null, // date //TODO remover
        startTime: null, // time
        // endDate: null, // date //TODO remover
        endTime: null, // time
        car: null, // text
        /*carPhoto: {
            id: null,
            type: null,
            url: null,
        },*/
        details: null, // text
        companyName: null, // company name
        startLocation: {
            city: null,
            address: null,
            lat: null,
            lng: null,
            phone: null,
        },
        /*startCoordinates: {
            lat: null,
            lng: null,
        }, // obj*/
        endLocation: {
            city: null,
            address: null,
            lat: null,
            lng: null,
            phone: null,
        },
        referenceNumber: null,
        images: [
            { // car photo
                id: null,
                type: null,
                url: null,
            }
        ]
    };
}


export function CAR_RENTAL_MID_ITEM(parentId) {
    return {
        id: generateUUID(),
        parentId: parentId,
        type: ITEM_TYPE_CAR_RENTAL_MID,
    };
}


export function CAR_RENTAL_LAST_ITEM(parentId) {
    return {
        id: generateUUID(),
        parentId: parentId,
        type: ITEM_TYPE_CAR_RENTAL_LAST,
    };
}


export function getMealTypeName(subType) {
    switch (subType) {
        case 'B':
            return itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_MEAL_breakfast");
        case 'L':
            return itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_MEAL_lunch");
        case 'D':
            return itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_MEAL_dinner");
    }
}


export function MEAL_ITEM(subType) {
    return {
        type: ITEM_TYPE_MEAL,
        subType: subType, // B, L, D
        title: getMealTypeName(subType),

        id: generateUUID(),
        name: '',
        description: null, // text
        time: null, // time TODO NOVO
        address: null, // text
        coordinates: {
            lat: null,
            lng: null,
        }, // obj ?
        phone: null, // text
        website: null, // url
        googleId: null,
        googlePlacesId: null,
        googleRating: 0,
        images: [],   // image do google places => {type: 'GOOGLE', url: ''}
        showOnPdf: false, // apenas para builder, define se alternativa aparece expandida no pdf ou nao
        alternatives: [] // array de objects igual
    };
}


export function SLEEP_ITEM() {
    return {
        //parentId: null,
        type: ITEM_TYPE_SLEEP,
        startDay: null, // TODO novo
        endDay: null,  // TODO novo

        id: generateUUID(),
        name: "",
        description: null, // text
        stars: 0, // 1-5
        address: null, // text
        coordinates: {
            lat: null,
            lng: null,
        },
        phone: null, // text
        email: null, // text
        website: null, // url
        googleId: null,
        googlePlacesId: null,
        googleRating: 0,
        hotelType: null, // text (B&B, villa, winery, etc)
        mealPlan: null, // text
        checkin: "", // text
        checkout: "", // text
        rooms: 1, // num de rooms
        roomTypes: [], // array de strings com o type de room
        roomImages: [], //TODO RETIRAR?
        images: [],   // image do google places => {type: 'GOOGLE', url: ''}
        showOnPdf: false, // apenas para builder, define se alternativa aparece expandida no pdf ou nao
        alternatives: [] // array de objects igual
    };
}


export function PLACE_ITEM() {
    return {
        type: ITEM_TYPE_PLACE,
        placeType: 'accommodation',
        startDay: null, // TODO novo
        endDay: null,  // TODO novo
        id: generateUUID(),
        name: "",
        description: null, // text
        stars: 0, // 1-5
        address: null, // text
        coordinates: {
            lat: null,
            lng: null,
        },
        phone: null, // text
        email: null, // text
        website: null, // url
        googleId: null,
        googlePlacesId: null,
        googleRating: 0,
        hotelType: null, // text (B&B, villa, winery, etc)
        mealPlan: null, // text
        checkin: "", // text
        checkout: "", // text
        rooms: 1, // num de rooms
        roomTypes: [], // array de strings com o type de room
        roomImages: [], //TODO RETIRAR?
        images: [],   // image do google places => {type: 'GOOGLE', url: ''}
        showOnPdf: false, // apenas para builder, define se alternativa aparece expandida no pdf ou nao
        alternatives: [] // array de objects igual
    };
}


export function SLEEP_MID_ITEM(parentId) {
    return {
        id: generateUUID(),
        parentId: parentId,
        type: ITEM_TYPE_SLEEP_MID,
    };
}


export function SLEEP_LAST_ITEM(parentId) {
    return {
        id: generateUUID(),
        parentId: parentId,
        type: ITEM_TYPE_SLEEP_LAST,
    };
}


export function TOUR_PRICE_ITEM(included) {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_PRICE,
        title: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_PRICE_title") + " " + (included ? "("+itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_PRICE_title_included")+")" : "("+itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_PRICE_title_optional")+")"),
        included: included, // true included, false optional
        adultPrice: "", // text
        childPrice: "", // text
        groupPrice: "", // text
        singleSupplement: "", // text
        images: [],
        colNumPersonsTitle: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_PRICE_num_persons_title"),
        colPricePerPersonTitle: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_PRICE_price_per_person_title"),
        tableLines: [
            {numPersons: null, price: null},
            {numPersons: null, price: null},
            {numPersons: null, price: null},
        ],
    };
}


export function WHATS_INCLUDED_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_WHATS_INCLUDED,
        includedTitle: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_title"),
        includedBullets: [
            "",
            ""
        ],
        flightsTitle: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_flight_title"),
        //hotelFlightsTitle: "Hotel / Others",
        hotelOthersTitle: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_hotel_others_title"),
        notIncludedTitle: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_not_included_title"),
        notIncludedBullets: [
            "",
            ""
        ]
    };
}


export function NEXT_STEPS_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_NEXT_STEPS,
        title: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_NEXT_STEPS_title"),
        deposit: {
            value: "", // number, text?
            when: "", // text
        },
        email: null,
        phone: null,
    };
}


export function CANCELATION_POLICY_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_CANCELATION_POLICY,
        title: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_CANCELATION_POLICY_title"),
        description: "",
        colDaysTitle: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_CANCELATION_POLICY_days_title"),
        colPenaltyTitle: itinerary_i18n.t("v2.Itinerary.defaults.ITEM_TYPE_CANCELATION_POLICY_penalty_title"),
        tableLines: [
            {days: null, penalty: null},
            {days: null, penalty: null},
            {days: null, penalty: null},
        ]
    };
}

export function CAROUSEL_ITEM_SLIDE(image) {
    return {
        id: generateUUID(),
        title: '',
        subtitle: '',
        image
    };
}

export function CAROUSEL_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_CAROUSEL,
        slides: []
    };
}

export function AUDIO_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_AUDIO,
    }
}

export function VIDEO_FILE_ITEM() {
    return {
        id: generateUUID(),
        type: ITEM_TYPE_VIDEO_FILE,
    }
}


/************ TRIP DEFAULT SECTIONS *************/

export function TOUR_COVER_SECTION() {
    return {
        id: generateUUID(),
        title: itinerary_i18n.t("v2.Itinerary.defaults.SECTION_TYPE_COVER_title"),
        showPdfTitle: false,
        type: SECTION_TYPE_COVER,
        items: [
            COVER_ITEM(),
        ]
    };
}


export function TOUR_INTRO_SECTION() {
    return {
        id: generateUUID(),
        title: itinerary_i18n.t("v2.Itinerary.defaults.SECTION_TYPE_OTHER_tour_intro_title"),
        showPdfTitle: false,
        type: SECTION_TYPE_OTHER,
        items: [
            TOUR_NAME_ITEM(),
            TRIP_VIDEO_ITEM(),
            TRIP_DESCRIPTION_ITEM(),
            TRIP_RATINGS_ITEM(),
            DESTINATION_INFO_ITEM(),
            TRIP_HIGHLIGHTS_ITEM(),
            TRIP_FAQ_ITEM()
        ]
    };
}


export function DAY_SECTION(dayNum, moment, dateFormat) {
    // const date = formatMoment(moment, SECTION_DATE_FORMAT);
    return {
        id: generateUUID(),
        title: getSectionDayTitle(dayNum, moment, dateFormat), // preenchido quando criado
        showPdfTitle: true,
	isCustomTabName: false,
	customTabName: '',
        type: SECTION_TYPE_DAY,
        dayNum: dayNum,
        items:
        [
            POI_ITEM(),
            ACTIVITY_ITEM(false),
        ],
    }
}

export function PRICES_CONDITIONS_SECTION() {
    return {
        id: generateUUID(),
        title: itinerary_i18n.t("v2.Itinerary.defaults.SECTION_TYPE_OTHER_prices_and_conditions_title"), // preenchido quando criado
        showPdfTitle: true,
        type: SECTION_TYPE_OTHER,
        items: [
            KNOW_BEFORE_GO_ITEM(),
            TOUR_PRICE_ITEM(true),
            WHATS_INCLUDED_ITEM(),
            TOUR_PRICE_ITEM(false),
            NEXT_STEPS_ITEM(),
            CANCELATION_POLICY_ITEM(),
        ],
    };
}


export function EMPTY_ITINERARY_TRIP_DATA() {
    return {
        version: ITINERARY_CURRENT_VERSION,
        showInterestButtons: false,
        sections: [],
    }
}


/*** METODOS UTILITARIOS ***/


/**
 * Devolve nome ou titulo do item. Caso esteja vazio devolve o default conforme o tipo
 *
 * @param item
 * @returns {string}
 */
export function getItemNameOrTitle(item) {
    let result = "";
    if (item.title)
        result = item.title;
    if (item.name)
        result = item.name;

    if (!result) {
        switch (item.type) {
            case ITEM_TYPE_GENERIC:
                result = item.title;
                if (!item.title) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_GENERIC");
                }
                break;
            case ITEM_TYPE_COVER:
                result = item.title;
                if (!item.title) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_COVER");
                }
                break;
            case ITEM_TYPE_TOUR_NAME:
                result = item.name;
                if (!item.name) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_TOUR_NAME");
                }
                break;
            case ITEM_TYPE_CLIENT_INFO:
                result = item.title;
                if (!item.title) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_CLIENT_INFO");
                }
                break;
            case ITEM_TYPE_TRIP_HIGHLIGHTS:
                result = item.title;
                if (!item.title) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_TRIP_HIGHLIGHTS");
                }
                break;
            case ITEM_TYPE_TRIP_RATINGS:
                result = item.title;
                break;
            case ITEM_TYPE_TRIP_FAQ:
                result = itinerary_i18n.t('v2.Itinerary.defaults.ITEM_NAME_TRIP_FAQ');
                break;
            case ITEM_TYPE_TRIP_URL:
                result = itinerary_i18n.t('Itinerary.general.link');
                break;
            case ITEM_TYPE_TRIP_VIDEO:
                result = itinerary_i18n.t('Itinerary.general.link');
                break;
            case ITEM_TYPE_TRIP_SOUNDCLOUD:
                result = itinerary_i18n.t('v2.Itinerary.defaults.ITEM_NAME_TRIP_SOUNDCLOUD');
                break;
            case ITEM_TYPE_TRIP_DESCRIPTION:
                result = item.title;
                if (!item.title) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_TRIP_DESCRIPTION");
                }
                break;
            case ITEM_TYPE_TRIP_DATES:
                result = item.title;
                if (!item.title) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_TRIP_DATES");
                }
                break;
            case ITEM_TYPE_COMPANY_INFO:
                result = item.title;
                if (!item.title) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_COMPANY_INFO");
                }
                break;
            case ITEM_TYPE_DESTINATION_INFO:
                result = item.placesOfVisit;
                if (!item.placesOfVisit) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_DESTINATION_INFO");
                }
                break;
            case ITEM_TYPE_KNOW_BEFORE_GO:
                result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_KNOW_BEFORE_GO");
                break;
            case ITEM_TYPE_POI:
                result = item.title;
                if (!item.title) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_POI");
                }
                break;
            case ITEM_TYPE_ACTIVITY:
                result = item.title;
                if (!item.title) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_ACTIVITY");
                }
                break;
            case ITEM_TYPE_TRANSPORTATION:
                result = item.name;
                if (!item.name && item.subType && item.subType.length > 1) {
                    result = item.subtype.charAt(0) + item.subtype.toLowerCase().slice(1);
                }
                break;
            case ITEM_TYPE_FLIGHT:
                result = item.name;
                if (!item.name) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_FLIGHT");
                }
                break;
            case ITEM_TYPE_CAR_RENTAL:
            case ITEM_TYPE_CAR_RENTAL_MID:
            case ITEM_TYPE_CAR_RENTAL_LAST:
                result = item.name;
                if (!item.name) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_CAR_RENTAL"); //TODO isto precisa de ser melhorado para os MID e LAST
                }
                break;
            case ITEM_TYPE_MEAL:
                result = item.title + " - " + item.name;
                if (!result) {
                    result = getMealTypeName(item.subType);
                }
                break;
            case ITEM_TYPE_SLEEP:
            case ITEM_TYPE_SLEEP_MID:
            case ITEM_TYPE_SLEEP_LAST:
                result = item.name;
                if (!item.name) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_SLEEP"); //TODO isto precisa de ser melhorado para os MID e LAST
                }
                break;
            case ITEM_TYPE_PLACE:
                    result = item.title;
                    if (!result) {
                        result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_PLACE");
                    }
                    break;
            case ITEM_TYPE_PRICE:
                result = item.title;
                if (!result) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_PRICE") + (item.included ? " ("+itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_PRICE_included")+")" : " ("+itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_PRICE_optional")+")");
                }
                break;
            case ITEM_TYPE_WHATS_INCLUDED:
                result = item.includedTitle;
                if (!result) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_WHATS_INCLUDED");
                }
                break;
            case ITEM_TYPE_NEXT_STEPS:
                result = item.title;
                if (!result) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_NEXT_STEPS");
                }
                break;
            case ITEM_TYPE_CANCELATION_POLICY:
                result = item.title;
                if (!result) {
                    result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_CANCELATION_POLICY");
                }
                break;
            case ITEM_TYPE_CAROUSEL:
                result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_CAROUSEL");
                break;
            case ITEM_TYPE_AUDIO:
                result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_AUDIO");
                break;
            case ITEM_TYPE_VIDEO_FILE:
                result = itinerary_i18n.t("v2.Itinerary.defaults.ITEM_NAME_VIDEO_FILE");
                break;
        }
    }
    return result;
}

/*
export function getSectionForDate(sections, date) {
    const dateStr = formatDate(date, SECTION_DATE_FORMAT);
    return sections.find((section) => {
        return section.type === SECTION_TYPE_DAY && section.date === dateStr;
    });
}
*/

export function getSectionForDayNum(sections, dayNum) {
    return sections.find((section) => {
        return section.type === SECTION_TYPE_DAY && section.dayNum === dayNum;
    });
}

/*
export function getSectionForMoment(sections, moment) {
    const dateStr = formatMoment(moment, SECTION_DATE_FORMAT);
    return sections.find((section) => {
        return section.type === SECTION_TYPE_DAY && section.date === dateStr;
    });
}
*/

export function getDaySections(sections) {
    return sections.filter((section) => {
        return section.type === SECTION_TYPE_DAY;
    });
}

/**
 * Devolve o numero do dia para a data
 *
 * @param date data a obter o numero do dia
 * @param startDate data de inicio do itinerario
 * @param dateFormat formato das datas do itinerario
 */
export function getDayNumForDate(date, startDate, dateFormat) {
    let sectionDateMoment = parseMoment(date, SECTION_DATE_FORMAT);
    let startMoment = parseMoment(startDate, dateFormat);

    return sectionDateMoment.diff(startMoment, 'days') +1;
}


/**
 * Devolve o numero do dia para a seccao
 *
 * @param section seccao a obter o numero do dia
 * @param itData
 */
export function getDayNumForSection(section, itData) {
    if (section.type !== SECTION_TYPE_DAY) {
        return null;
    }
    if (section.dayNum) {
        return section.dayNum;
    } else  if (itData.startDate && itData.dateFormat && section.date) {
        return getDayNumForDate(section.date, itData.startDate, itData.dateFormat);
    }
    return null;
}


/**
 * Generates the day section title
 *
 * @param dayIndex
 * @param moment
 * @param dateFormat
 * @returns {string}
 */
export function getSectionDayTitle(dayIndex, moment, dateFormat) {
    // if (moment && dateFormat) {
    //    const weekDay = formatToWeekDay(moment);
    //    return itinerary_i18n.t("v2.Itinerary.defaults.DAY") + " " + dayIndex;  + ", " + formatMoment(moment, dateFormat) + ", " + weekDay;
    // } else {
        return itinerary_i18n.t("v2.Itinerary.defaults.DAY") + " " + dayIndex;
    //}
}


/**
 * Devolve a data da seccao (do tipo dia) que contenha o item com o itemId indicado
 * @param sections
 * @param itemId
 * @returns {*}
 */
export function getSectionDateForItemId(sections, startDate, dateFormat, itemId) {
    let section = sections.find(section => {
        if (section.type === SECTION_TYPE_DAY) {
            let itemTmp = section.items.find((item) => {
                return item.id === itemId;
            });
            if (itemTmp !== undefined) {
                return true;
            }
        }
        return false;
    });
    if (section !== undefined) {
        if (section.dayNum) {
            return formatMoment(getDateMomentForDayNum(section.dayNum, startDate, dateFormat), dateFormat)
        } else {
            return section.date ? section.date : "";
        }
    } else {
        return "";
    }
}

export function getSectionDayNumForItemId(sections, itemId) {
    let section = sections.find(section => {
        if (section.type === SECTION_TYPE_DAY) {
            let itemTmp = section.items.find((item) => {
                return item.id === itemId;
            });
            if (itemTmp !== undefined) {
                return true;
            }
        }
        return false;
    });
    if (section !== undefined) {
        if (section.dayNum) {
            return section.dayNum
        } else {
            return section.date ? section.date : "";
        }
    } else {
        return "";
    }
}


/**
 * Devolve o primeiro item do tipo SLEEP que encontrar
 * @param sections
 * @param type
 * @returns {*}
 */
export function getFirstItemByType(sections, type) {
    let itemTmp = null;
    sections.some(section => {
        itemTmp = section.items.find((item) => {
            return item.type === type;
        });
        if (itemTmp) {
            return true;
        }
        return false;
    });
    return itemTmp;
}

/**
 * Devolve a seccao que contenha o item com o itemId indicado
 * @param sections
 * @param itemId
 * @returns {*}
 */
export function getSectionForItemId(sections, itemId) {
    let section = sections.find(section => {
        let itemTmp = section.items.find((item) => {
            return item.id === itemId;
        });
        if (itemTmp !== undefined) {
            return true;
        }
        return false;
    });
    if (section !== undefined) {
        return section;
    } else {
        return null;
    }
}

/**
 * Devolve a data da seccao (do tipo dia) que contenha a activity com o activityId indicado
 * @param sections
 * @param activityId
 * @returns {*}
 */
export function getSectionDateForActivityId(sections, startDate, dateFormat, activityId) {
    let section = sections.find(section => {
        if (section.type === SECTION_TYPE_DAY) {
            let itemTmp = section.items.find((item) => {
                if (item.list !== undefined) {
                    let activity = item.list.find(activity => {
                        return activity.id === activityId;
                    });

                    if (activity !== undefined) {
                        return true;
                    }
                }
                return false;
            });
            if (itemTmp !== undefined) {
                return true;
            }
        }
        return false;
    });
    if (section !== undefined) {
        if (section.dayNum) {
            return formatMoment(getDateMomentForDayNum(section.dayNum, startDate, dateFormat), dateFormat)
        } else {
            return section.date ? section.date : "";
        }
    } else {
        return "";
    }
}

export function getSectionDayNumForActivityId(sections, activityId) {
    let section = sections.find(section => {
        if (section.type === SECTION_TYPE_DAY) {
            let itemTmp = section.items.find((item) => {
                if (item.list !== undefined) {
                    let activity = item.list.find(activity => {
                        return activity.id === activityId;
                    });

                    if (activity !== undefined) {
                        return true;
                    }
                }
                return false;
            });
            if (itemTmp !== undefined) {
                return true;
            }
        }
        return false;
    });
    if (section !== undefined) {
        if (section.dayNum) {
            return section.dayNum
        } else {
            return section.date ? section.date : "";
        }
    } else {
        return "";
    }
}

/**
 * Devolve a data da seccao (do tipo dia) que contenha a activity com o activityId indicado
 * @param sections
 * @param activityId
 * @returns {*}
 */
export function getSectionDateForIndividualFlightId(sections, startDate, dateFormat, flightId) {
    let section = sections.find(section => {
        if (section.type === SECTION_TYPE_DAY) {
            let itemTmp = section.items.find((item) => {
                if (item.list !== undefined) {
                    let flight = item.list.find(flight => {
                        return flight.id === flightId;
                    });

                    if (flight !== undefined) {
                        return true;
                    }
                }
                return false;
            });
            if (itemTmp !== undefined) {
                return true;
            }
        }
        return false;
    });
    if (section !== undefined) {
        if (section.dayNum) {
            return formatMoment(getDateMomentForDayNum(section.dayNum, startDate, dateFormat), dateFormat)
        } else {
            return section.date ? section.date : "";
        }
    } else {
        return "";
    }
}

export function getSectionDayNumForFlightId(sections, flightId) {
    let section = sections.find(section => {
        if (section.type === SECTION_TYPE_DAY) {
            let itemTmp = section.items.find((item) => {
                if (item.list !== undefined) {
                    let flight = item.list.find(flight => {
                        return flight.id === flightId;
                    });

                    if (flight !== undefined) {
                        return true;
                    }
                }
                return false;
            });
            if (itemTmp !== undefined) {
                return true;
            }
        }
        return false;
    });
    if (section !== undefined) {
        if (section.dayNum) {
            return section.dayNum
        } else {
            return section.date ? section.date : "";
        }
    } else {
        return "";
    }
}

export function getParentItemById(sections, id) {
    let result = null;
    sections.find((section) => {
        let itemTmp = section.items.find(item => {
            return item.id === id;
        });
        if (itemTmp !== undefined) {
            result = itemTmp;
            return true;
        }
    });
    return result;
}


export function getItemByParentIdAndTypeFromSection(section, parentId, type) {
    let itemTmp = section.items.find(item => {
        return item.parentId && item.parentId === parentId && item.type === type;
    });
    if (itemTmp !== undefined) {
        return itemTmp;
    } else {
        return null;
    }
}


/**
 * Devolve seccao onde esta item
 * cujo parent seja igual ao parentId e tipo seja igual ao fornecido
 *
 * @param parentId
 * @param itemType
 * @returns {*}
 */
export function getSectionWithItemForParentIdAndType(sections, parentId, itemType) {
    let result = null;
    sections.slice().reverse().find((section) => {
        let itemTmp = section.items.find(item => {
            return item.parentId &&
                item.parentId === parentId &&
                item.type === itemType;
        });
        if (itemTmp !== undefined) {
            result = section;
            return true;
        }
    });
    return result;
}


export function getItineraryProblemsForDates(sections, newNumDays) {
    let problemList = [];

    const daySections = getDaySections(sections);
    const outSections = daySections.slice(newNumDays);
    outSections.forEach(section => {
        if (section.type === SECTION_TYPE_DAY) {
            //const sectionDate = parseMoment(section.date);
            //if (!sectionDate.isBetween(startDateTmp, endDateTmp, null, "[]")) {
            const problemObj = {
                date: section.title,
                list: [],
            };

            // the section is out of the new dates, check items for hotel, etc
            const hotelList = section.items.filter(item => {
                return item.type === ITEM_TYPE_SLEEP ||
                    item.type === ITEM_TYPE_SLEEP_MID ||
                    item.type === ITEM_TYPE_SLEEP_LAST;
            });
            if (hotelList.length > 0) {
                hotelList.forEach(hotel => {
                    let hotelName = hotel.name;
                    if (hotel.type === ITEM_TYPE_SLEEP_MID ||
                        hotel.type === ITEM_TYPE_SLEEP_LAST) {
                        hotelName = getParentItemById(sections, hotel.parentId).name;
                    }
                    problemObj.list.push(hotelName);
                });
            }

            const transportList = section.items.filter(item => {
                return item.type === ITEM_TYPE_TRANSPORTATION;
            });
            if (transportList.length > 0) {
                transportList.forEach(transport => {
                    problemObj.list.push(transport.name);
                });
            }

            const flightList = section.items.filter(item => {
                return item.type === ITEM_TYPE_FLIGHT;
            });
            if (flightList.length > 0) {
                flightList.forEach(flight => {
                    problemObj.list.push(flight.name);
                });
            }

            const rentalList = section.items.filter(item => {
                return item.type === ITEM_TYPE_CAR_RENTAL;
            });
            if (rentalList.length > 0) {
                rentalList.forEach(rental => {
                    let rentalName = rental.name;
                    if (rental.type === ITEM_TYPE_CAR_RENTAL_MID ||
                        rental.type === ITEM_TYPE_CAR_RENTAL_LAST
                    ) {
                        rentalName = getParentItemById(sections, rental.parentId).name;
                    }
                    problemObj.list.push(rentalName);
                });
            }


            if (problemObj.list.length > 0) {
                problemList.push(problemObj);
            }
            //}
        }
    });

    return problemList;
}


/**
 * Converte os titulos das seccoes do formato antigo para o novo
 *
 * @param sections
 * @param startDateStr
 * @param dateFormat
 * @returns {*}
 */
export function updateSectionsDateFormat(sections, startDateStr, dateFormat) {
    let dayNum = 1;
    sections.forEach(section => {
        if (section.type === SECTION_TYPE_DAY) {
            const sectionMoment = getDateMomentForDayNum(dayNum, startDateStr, dateFormat);
            section.title = getSectionDayTitle(dayNum, sectionMoment, dateFormat);
        }
        dayNum++;
    });
    return sections;
}


/**
 * Changes and existing section list and adds or removes new days according to the new start and end dates.
 * Also updates the existing sections with the new dates
 *
 * @param sectionList existing section list to refactor
 * @param startDate new start date
 * @param endDate new end date
 * @param dateFormat start and end dates format
 * @returns {Array} a section list with the new dates
 */
export function refactorSectionsWithNewDates(sectionList, startDate, endDate, dateFormat, numDays) {
    let startMoment = null;
    let endMoment = null;
    if (startDate) {
        startMoment = parseMoment(startDate, dateFormat);
    }
    if (endMoment) {
        endMoment = parseMoment(endDate, dateFormat);
    }

    // console.log("### refactorSectionsWithNewDates START => " + startDate);
    // console.log("### refactorSectionsWithNewDates END => " + endDate);

    let newSectionList = [];

    // index da primeira secção de dia
    let daySectionIndex = sectionList.findIndex(section => {
        return section.type === SECTION_TYPE_DAY;
    });

    let idxTmp = 0;
    // copia as seccoes antes das de dia para o novo array
    while(idxTmp < daySectionIndex) {
        newSectionList.push(sectionList[idxTmp]);
        idxTmp++;
    }

    let firstSectionsLastIndex = newSectionList.length + 1;

    let dayNum = 1;

    if (startMoment && endMoment) {
        // copia seccoes dos dias
        while (startMoment.isSameOrBefore(endMoment)) {
            let section;
            if (idxTmp < sectionList.length) {
                section = sectionList[idxTmp];
                if (section.type === SECTION_TYPE_DAY) {
                    // ja existe seccao para o dia x, vamos so actualizar o titulo
                    section.title = getSectionDayTitle(dayNum, startMoment, dateFormat);
                    // section.date = formatMoment(startMoment, SECTION_DATE_FORMAT);
                    newSectionList.push(section);
                } else {
                    // nao existe seccao para o dia x, vamos criar nova
                    newSectionList.push(DAY_SECTION(dayNum, startMoment, dateFormat));
                }
            } else {
                // nao existe seccao para o dia x, vamos criar nova
                newSectionList.push(DAY_SECTION(dayNum, startMoment, dateFormat));
            }
            dayNum++;
            idxTmp++;

            startMoment.add(1, 'days');
        }
    } else if (numDays) {
        // copia seccoes dos dias
        while (dayNum <= numDays) {
            let section;
            if (idxTmp < sectionList.length) {
                section = sectionList[idxTmp];
                if (section.type === SECTION_TYPE_DAY) {
                    // ja existe seccao para o dia x, vamos so actualizar o titulo
                    section.title = getSectionDayTitle(dayNum);
                    // section.date = formatMoment(startMoment, SECTION_DATE_FORMAT);
                    newSectionList.push(section);
                } else {
                    // nao existe seccao para o dia x, vamos criar nova
                    newSectionList.push(DAY_SECTION(dayNum));
                }
            } else {
                // nao existe seccao para o dia x, vamos criar nova
                newSectionList.push(DAY_SECTION(dayNum));
            }
            dayNum++;
            idxTmp++;
        }
    }

    // copia seccoes originais depois dos dias
    for (let i = firstSectionsLastIndex; i < sectionList.length; i++) {
        if (sectionList[i].type !== SECTION_TYPE_DAY) {
            newSectionList.push(sectionList[i]);
        }
    }

    // percorre lista de items que tenham parentId, mas cujo parent nao exista. caso nao exista, remove item
    newSectionList.forEach(section => {
        let toRemove = [];
        section.items.forEach((item, index) => {
            if (item.parentId) {
                const parent = getParentItemById(newSectionList, item.parentId);
                if (parent === null) {
                    toRemove.push(index);
                }
            }
        });
        toRemove.reverse().forEach(idx => {
            section.items.splice(idx, 1)
        })
    });

    // actualiza datas de items
    newSectionList.forEach(section => {
        section.items.forEach(item => {
            if (item.type === ITEM_TYPE_SLEEP) {
                let endSection = getSectionWithItemForParentIdAndType(newSectionList, item.id, ITEM_TYPE_SLEEP_LAST);
                if (endSection === null) {
                    // item LAST estava num dia que nao foi seleccionado, procura o proximo item MID
                    endSection = getSectionWithItemForParentIdAndType(newSectionList, item.id, ITEM_TYPE_CAR_RENTAL_MID);
                    if (endSection !== null) {
                        let itemTmp = getItemByParentIdAndTypeFromSection(endSection, item.id, ITEM_TYPE_CAR_RENTAL_MID);
                        if (itemTmp !== null) {
                            itemTmp.type = ITEM_TYPE_CAR_RENTAL_LAST;
                        }
                    }
                }
            } else if (item.type === ITEM_TYPE_CAR_RENTAL) {
                let endSection = getSectionWithItemForParentIdAndType(newSectionList, item.id, ITEM_TYPE_CAR_RENTAL_LAST);
                if (endSection === null) {
                    // item LAST estava num dia que nao foi seleccionado, procura o proximo item MID
                    endSection = getSectionWithItemForParentIdAndType(newSectionList, item.id, ITEM_TYPE_CAR_RENTAL_MID);
                    if (endSection !== null) {
                        let itemTmp = getItemByParentIdAndTypeFromSection(endSection, item.id, ITEM_TYPE_CAR_RENTAL_MID);
                        if (itemTmp !== null) {
                            itemTmp.type = ITEM_TYPE_CAR_RENTAL_LAST;
                        }
                    }
                }
            }
        });
    });

    // Fix Hotel Days if needed
    newSectionList.forEach(section => {
        section.items.forEach(item => {
            if(item.type === ITEM_TYPE_SLEEP) {
                item.startDay = section.dayNum;
                let endSection = getSectionWithItemForParentIdAndType(newSectionList, item.id, ITEM_TYPE_SLEEP_LAST);
                if (endSection) {
                    item.endDay = endSection.dayNum;
                }
            }
        });
    });

    return newSectionList;
}


/**
 * faz conversao de itData para versao 2.0
 *
 * @param itData
 * @returns {*}
 */
export function upgradeItineraryToVersion2(itData) {
    const startMoment = parseMoment(itData.startDate, itData.dateFormat);
    const endMoment = parseMoment(itData.endDate, itData.dateFormat);

    itData.numDays = endMoment.diff(startMoment, "days") + 1;

    // percorre todas seccoes
    itData.tripData.sections.forEach(section => {
        if (section.type === SECTION_TYPE_DAY) {
            // para cada seccao, tira data e mete dia
            section.dayNum = getDayNumForDate(section.date, itData.startDate, itData.dateFormat);
            //delete section.date;

            // percorre todos os items
            section.items.forEach(item => {
                if (item.type === ITEM_TYPE_SLEEP ||
                    item.type === ITEM_TYPE_CAR_RENTAL
                ) {
                    // sleep que tenham datas, mete dias
                    // rental que tenham datas, mete dias
                    item.startDay = getDayNumForDate(item.startDate, itData.startDate, itData.dateFormat);
                    item.endDay = getDayNumForDate(item.endDay, itData.startDate, itData.dateFormat);

                    //delete item.endDate;
                }
            });
        }
    });
    itData.tripData.version = "2.0";

    return itData;
}
