import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueCookies from 'vue-cookies'

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://22a1c0c548794bb4accfac0b86f131d1@sentry.io/1381033',
        integrations: [
            new Integrations.Vue({
                Vue,
                attachProps: true,
            }),
        ],
    });
} else {
//     You may remove this once you are sure everything works fine
    console.log('Not configuring Sentry, environment is', process.env.NODE_ENV);
}

// import router from './router'
import './icons';
import axios from 'axios';
import toastr from 'toastr';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'toastr/toastr.scss';

toastr.options = {
    "closeButton": true,
    "debug": false,
    "progressBar": true,
    "preventDuplicates": false,
    "positionClass": "toast-top-right",
    "showDuration": "400",
    "hideDuration": "1000",
    "timeOut": "7000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css';

import '@fortawesome/fontawesome-free/css/all.min.css';

Vue.use(VueCookies)
Vue.use(VueI18n);

let userSelectedLang = VueCookies.get('userlang');

import enUS from './i18n/en-us/en-us.js'
import ru from './i18n/ru/ru.js'
import pt from './i18n/pt/pt.js'

const messages = {
  en: enUS,
  ru: ru,
  pt: pt
};

export function calculateLang() {
    if(userSelectedLang)
        return userSelectedLang;
    if (navigator.language.startsWith('ru')) {
        return 'ru';
    }
    if (navigator.language.startsWith('pt')) {
        return 'pt';
    }
    return 'en';
}

import moment from 'moment';

if (calculateLang() === 'ru') {
    moment.locale('ru');
} else if (calculateLang() === 'pt') {
    moment.locale('pt');
} else {
    moment.locale('en');
}

// Create VueI18n instance with options
// this instance is for Display Only!
// for printing we will have another instance,
// and the reason is that printing could be in a different
// language than the interface being presented.
export const i18n = new VueI18n({
  locale: calculateLang(), // set locale
  fallbackLocale: 'en',
  messages, // set locale messages
})

export const itinerary_i18n = new VueI18n({
  locale: calculateLang(), // set locale
  fallbackLocale: 'en',
  messages, // set locale messages
})

// Create a Vue instance with `i18n` option
// new Vue({ i18n }).$mount('#app')


import $ from 'jquery'
window.$ = window.jQuery = $;
//window._ =  require('lodash');
window.toastr = toastr;
// window.trackEvent = trackEvent;

// Vue.config.productionTip = false;
// Vue.prototype.$http = axios;
import Dropzone from 'dropzone'
Dropzone.autoDiscover = false;

import './assets/css/main.css';

Vue.prototype.$http = axios;

import Vue2Filters from 'vue2-filters';
Vue.use(Vue2Filters);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

/*
import vButton from './components/VButton';
Vue.component('vbutton', vButton);

import bSelect from './components/BSelect';
Vue.component('b-select', bSelect);
*/

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect);
import "vue-multiselect/dist/vue-multiselect.min.css";

import VTooltip from 'v-tooltip';
Vue.use(VTooltip);

import StarRating from 'vue-star-rating'
Vue.component('star-rating', StarRating);

import VueTextareaAutosize from 'vue-textarea-autosize'
Vue.use(VueTextareaAutosize);

// import ElementUI from 'element-ui';
import { Select, Option, Switch, DatePicker, Slider, TimeSelect, Input } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
// Vue.use(ElementUI, {lang});

// configure language
locale.use(lang)

Vue.use(Select);
Vue.use(Option);
Vue.use(Switch);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(Slider);
Vue.use(Input);

import './chat.js'

import MainMenuWidget from './views/widgets/MainMenuWidget.vue'
if(document.getElementById("mainMenuPlacer")) {
    new Vue({
        i18n,
        render: h => h(MainMenuWidget)
    }).$mount('#mainMenuPlacer')
}

import ChatBarWidget from './views/widgets/ChatMessagesBarWidget.vue'
if(document.getElementById("chatMessageWidgetContainer")) {
    new Vue({
        i18n,
        render: h => h(ChatBarWidget)
    }).$mount('#chatMessageWidgetContainer')
}

import LanguageChangerWidget from './views/widgets/LanguageChangerWidget.vue'
if(document.getElementById("languageChangerWidgetContainer")) {
    new Vue({
        i18n,
        render: h => h(LanguageChangerWidget)
    }).$mount('#languageChangerWidgetContainer')
}

import Join from './views/Join.vue'
if(document.getElementById("joinContainer")) {
    new Vue({
        i18n,
        render: h => h(Join)
    }).$mount('#joinContainer')
}

/*
import Signup from './views/Signup.vue'
// console.log("#### SIGNUP CONTAINER? " + document.getElementById("signupContainer"));
if(document.getElementById("signupContainer")) {
    new Vue({
        i18n,
        render: h => h(Signup)
    }).$mount('#signupContainer')
}
*/

import PlatformAdministration from './views/platformadministration/PlatformAdministration.vue'
// console.log("#### SIGNUP CONTAINER? " + document.getElementById("signupContainer"));
if(document.getElementById("platformAdministrationContainer")) {
    new Vue({
        i18n,
        render: h => h(PlatformAdministration)
    }).$mount('#platformAdministrationContainer')
}

import SignupCompany from './views/platformadministration/SignupCompany.vue'
// console.log("#### SIGNUP CONTAINER? " + document.getElementById("signupContainer"));
if(document.getElementById("signupCompanyContainer")) {
    new Vue({
        i18n,
        render: h => h(SignupCompany)
    }).$mount('#signupCompanyContainer')
}

import Homepage from './views/Homepage'
if(document.getElementById("homepageContainer")) {
    new Vue({
        i18n,
        render: h => h(Homepage)
    }).$mount('#homepageContainer')
}


import SubscriptionManager from './views/admin/SubscriptionManager.vue';
if(document.getElementById("subscriptionManager")) {
    new Vue({
        i18n,
        render: h => h(SubscriptionManager)
    }).$mount('#subscriptionManager')
}

import UserList from './views/admin/UserList.vue'
if(document.getElementById("usersListContainer")) {
    new Vue({
        i18n,
        render: h => h(UserList)
    }).$mount('#usersListContainer')
}

import UserInvite from './views/admin/UserInvite.vue'
if(document.getElementById("userInviteContainer")) {
    new Vue({
        i18n,
        render: h => h(UserInvite)
    }).$mount('#userInviteContainer')
}

import AgencyConfiguration from './views/admin/AgencyConfiguration.vue'
if(document.getElementById("agencyConfigContainer")) {
    new Vue({
        i18n,
        render: h => h(AgencyConfiguration)
    }).$mount('#agencyConfigContainer')
}

import MobileAppConfiguration from './views/admin/MobileAppConfiguration.vue'
if(document.getElementById("mobileAppConfigContainer")) {
    new Vue({
        i18n,
        render: h => h(MobileAppConfiguration)
    }).$mount('#mobileAppConfigContainer')
}

import ClientList from './views/client/ClientList.vue'
if(document.getElementById("clientListContainer")) {
    new Vue({
        i18n,
        render: h => h(ClientList)
    }).$mount('#clientListContainer')
}

import ClientImport from './views/client/ClientImport.vue'
if(document.getElementById("clientImportContainer")) {
    new Vue({
        i18n,
        render: h => h(ClientImport)
    }).$mount('#clientImportContainer')
}

import ItineraryList from './views/itinerary/ItineraryList.vue'
if(document.getElementById("itineraryListContainer")) {
    new Vue({
        i18n,
        render: h => h(ItineraryList)
    }).$mount('#itineraryListContainer')
}

import ItineraryCreate from './views/itinerary/ItineraryCreate.vue'
if(document.getElementById("itineraryCreateContainer")) {
    new Vue({
        i18n,
        render: h => h(ItineraryCreate)
    }).$mount('#itineraryCreateContainer')
}

import ItineraryDetail from './views/itinerary/ItineraryDetail.vue'
import store from './views/itinerary/itinerary-store';
if(document.getElementById("itineraryDetailContainer")) {
    new Vue({
        i18n,
        render: h => h(ItineraryDetail),
        store,
    }).$mount('#itineraryDetailContainer');
}

import ItineraryDetailPrint from './views/itinerary/print/ItineraryDetailPrint.vue'
if(document.getElementById("itineraryDetailPrintContainer")) {
    new Vue({
        i18n,
        render: h => h(ItineraryDetailPrint)
    }).$mount('#itineraryDetailPrintContainer')
}

import ItineraryDetailCoverPrint from './views/itinerary/print/ItineraryDetailCoverPrint.vue'
if(document.getElementById("itineraryDetailCoverPrintContainer")) {
    new Vue({
        i18n,
        render: h => h(ItineraryDetailCoverPrint)
    }).$mount('#itineraryDetailCoverPrintContainer')
}


// ################## TEMPLATES ####################

import TemplateList from './views/itinerary/TemplateList.vue'
if(document.getElementById("templateListContainer")) {
    new Vue({
        i18n,
        render: h => h(TemplateList)
    }).$mount('#templateListContainer')
}

import TemplateCreate from './views/itinerary/TemplateCreate.vue'
if(document.getElementById("templateCreateContainer")) {
     new Vue({
         i18n,
         render: h => h(TemplateCreate)
     }).$mount('#templateCreateContainer')
}


// ################## LIBRARY ####################
import LibraryMain from './views/library/LibraryMain'
if(document.getElementById("libraryMainContainer")) {
    new Vue({
        i18n,
        render: h => h(LibraryMain)
    }).$mount('#libraryMainContainer')
}

import LibraryItemDetail from './views/library/LibraryItemDetail'
if(document.getElementById("libraryItemDetailContainer")) {
    new Vue({
        i18n,
        render: h => h(LibraryItemDetail)
    }).$mount('#libraryItemDetailContainer')
}

import UserProfile from './views/UserProfile'
if(document.getElementById("userProfileContainer")) {
    new Vue({
        i18n,
        render: h => h(UserProfile)
    }).$mount('#userProfileContainer')
}

import MigrateItineraries from './views/superadmin/MigrateItineraries'
if(document.getElementById("migrateItinerariesContainer")) {
    new Vue({
        i18n,
        render: h => h(MigrateItineraries)
    }).$mount('#migrateItinerariesContainer')
}

/*import Test from './views/itinerary/Test.vue'
if(document.getElementById("testContainer")) {
    new Vue({
        // router,
        // store,
        render: h => h(Test)
    }).$mount('#testContainer')
}*/
